import { Dto } from "@hlcr/core/api/RemoteResource";
import { HttpMethod } from "@hlcr/core/enum/HttpMethod";

import { CriteriaGradingCatalogDto } from "components/Solution/CriteriaGradingCatalogDto";
import { RemoteResource } from "models/RemoteResource";
import { DynamicRequestAction, DynamicRequestActionWithUrlParameter, RemoteResourceActionType } from "redux/actions";

const BASE_URL = "/api";

interface FetchCriteriaGradingCatalogPayload extends Dto {
	solutionId: number;
}

export const fetchCriteriaGradingCatalog: DynamicRequestAction<FetchCriteriaGradingCatalogPayload> = (payload) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/teacher/solutions/${payload?.solutionId}/criteria/`,
	resource: RemoteResource.GRADING_CRITERIA_CATALOG,
	method: HttpMethod.GET,
});

export const saveCriteriaGradingCatalog: DynamicRequestActionWithUrlParameter<CriteriaGradingCatalogDto, number> = (solutionId, criteriaCatalogDto, onBefore, onSuccess, onFailure) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/teacher/solutions/${solutionId}/criteria/`,
	method: HttpMethod.POST,
	payload: criteriaCatalogDto,
	onBefore: onBefore,
	onSuccess: onSuccess,
	onFailure: onFailure,
	successNotification: "Created successfully",
});

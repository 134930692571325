import { useState } from "react";

/**
 * useSessionState tries to emulate the behaviour of the useState hook but enhances it by persisting its value to the sessionStorage of the browser.
 * Theoretically this allows to share state between different independent components. As this is not tested comprehensively, using it that way is at your own risk.
 *
 * Because of the nature of the sessionStorage, values need to be persisted as strings. UsePersistedState uses JSON.stringify and JSON.parse to write to and read from the sessionStorage.
 * You can supply a normalize function if you need to transform your state after it is read from the sessionStorage.
 *
 * @param key Preferably a unique key under which the value is persisted in the browsers localStorage. There are no checks of the keys uniqueness. It is your task to ensure this.
 * @param defaultValue Default value for when there has not been persisted any state to the browser yet.
 * @param normalize A function the value is applied to when it is read from the localStorage.
 * @returns {[*, function(*=): void]}
 */
function useSessionState<T>(key: string, defaultValue?: T, normalize?: (value: any) => any) {
	if(!key || key.length === 0){
		throw new Error(`Illegal key for persisted state: '${key}'`);
	}

	const getSessionState = () => {
		const item = sessionStorage.getItem(`${key}`);
		const persistedValue = item !== null ? JSON.parse(item) : item;
		return persistedValue === null ? defaultValue : normalize && typeof normalize === "function" ? normalize(persistedValue) : persistedValue;
	};

	const setSessionState = (value: any) => {
		setHookValue(value);
		return sessionStorage.setItem(`${key}`, JSON.stringify(value));
	};

	const [ hookValue, setHookValue ] = useState(getSessionState());
	return [ hookValue, setSessionState ];
}
export default useSessionState;

// FlagType
import { EnumBase, EnumDetail } from "@hlcr/app/enum/EnumBase";

import { AssetCategory } from "models/Asset";
import { Level } from "shared/event/model/Level";

interface MultipleChoiceOption {
	id: string;
	content: string;
	correct?: boolean;
}

export interface Question {
	type: QuestionType;
	content: string;
	id: string;
	multipleChoiceOptions?: MultipleChoiceOption[];
	required?: boolean;
	touched?: boolean;
	answer?: QuestionAnswer;
	solution?: QuestionAnswer;
}

export interface MultipleChoiceOptionsAnswer {
	selectedOptionUuid: string;
}

export interface QuestionAnswer {
	id?: number;
	questionUuid: string;
	type: QuestionType;

	multipleChoiceOptionAnswers?: MultipleChoiceOptionsAnswer[];
	content?: string;
	correctAnswer?: boolean;
}

export interface Quiz {
	id: string;
	questions: Question[];
	readonly: boolean;
}

export interface QuizQuestionGradingInstruction {
	id: string;
	type: QuestionType;
	multipleChoiceOptions?: MultipleChoiceOption[];
	shortAnswers?: string[],
	longAnswer?: string,
	quizSolutionId?: number,
}


// QuestionType
export enum QuestionType {
	MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
	SINGLE_CHOICE = "SINGLE_CHOICE",
	SHORT_ANSWER = "SHORT_ANSWER",
	LONG_ANSWER = "LONG_ANSWER"
}

interface QuestionTypeEnum extends EnumBase {
}

export const QUESTION_TYPES: EnumDetail<QuestionType, QuestionTypeEnum> = {
	MULTIPLE_CHOICE: { title: "question.type.multiple_choice" },
	SINGLE_CHOICE: { title: "question.type.single_choice" },
	SHORT_ANSWER: { title: "question.type.short_answer" },
	LONG_ANSWER: { title: "question.type.long_answer" },
};

export interface QuizSummaryDto {
	id: number;
	content?: string;
	title?: string;
	level?: Level;
	categories?: AssetCategory[];
	maxPoints: number;
	trainingMode: boolean;
}

import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";

import { primaryColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import customSelectStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/customSelectStyle";
import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";


class CustomSearchSelect extends React.Component {
	render() {
		const { value, list, onSelect, displayField, placeholder, additionalClasses, classes, isDisabled, isOptionDisabled } = this.props;
		return (
			<Select
				getOptionLabel={option => option[displayField]}
				onChange={onSelect}
				options={list}
				value={value}
				onBlurResetsInput={false}
				onSelectResetsInput={false}
				clearable={true}
				labelKey={displayField}
				disabled={false}
				className={cx(classes.selectSearchBox, additionalClasses)}
				styles={styles}
				theme={themeStyle}
				menuContainerStyle={{ zIndex: 5 }}
				rtl={false}
				searchable={true}
				placeholder={placeholder}
				openOnFocus={true}
				isDisabled={isDisabled}
				isOptionDisabled={isOptionDisabled}
			/>
		);
	}
}

const themeStyle = theme => ({
	...theme,
	borderRadius: "1px",
	colors: {
		...theme.colors,
		primary: primaryColor
	}
});

const styles = {
	control: styles => ({
		...styles,
		[DARK_MODE_SELECTOR]: {
			color: COLOR.LIGHT_TEXT_1,
			backgroundColor: COLOR.DARK_1,
			borderColor: "transparent"
		}
	}),
	option: (styles, { isFocused, isDisabled }) => ({
		...styles,
		backgroundColor: isFocused ? primaryColor : "initial",
		color: isFocused ? "#fff" : "initial",
		opacity: isDisabled ? 0.5 : 1,
		[DARK_MODE_SELECTOR]: {
			color: COLOR.LIGHT_TEXT_1,
			backgroundColor: isFocused ? primaryColor : COLOR.DARK_1
		}
	}),
	placeholder: styles => ({
		...styles,
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 }
	}),
	singleValue: styles => ({
		...styles,
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 }
	}),
	menu: styles => ({
		...styles,
		[DARK_MODE_SELECTOR]: { backgroundColor: COLOR.DARK_1 }
	})
};

CustomSearchSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	value: PropTypes.object,
	list: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSelect: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	displayField: PropTypes.string.isRequired,
	additionalClasses: PropTypes.string,
	isOptionDisabled: PropTypes.func,
	isDisabled: PropTypes.bool
};

export default withStyles(customSelectStyle)(CustomSearchSelect);

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";

import infoCardStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/infoCardStyle";

function InfoCard({
	classes,
	title,
	icon: Icon,
	description,
	footer,
	small,
	iconColor
}) {
	return (
		<Card className={classes.card}>
			<CardHeader
				classes={{
					root: classes.cardHeader + " " + classes[iconColor + "CardHeader"],
					avatar: classes.cardAvatar
				}}
				avatar={<Icon className={classes.cardIcon} />}
			/>
			<CardContent className={classes.cardContent}>
				<Typography component="p" className={classes.cardCategory}>
					{title}
				</Typography>
				<Typography variant="h5" component="h2" className={classes.cardTitle}>
					{description}{" "}
					{small !== undefined ? (
						<small className={classes.cardTitleSmall}>{small}</small>
					) : null}
				</Typography>
			</CardContent>
			{footer && (
				<CardActions className={classes.cardActions}>
					<div className={classes.cardStats}>{footer}</div>
				</CardActions>
			)}
		</Card>
	);
}

InfoCard.defaultProps = { iconColor: "purple" };

InfoCard.propTypes = {
	classes: PropTypes.object.isRequired,
	icon: PropTypes.object.isRequired,
	iconColor: PropTypes.oneOf([
		                           "orange",
		                           "green",
		                           "red",
		                           "blue",
		                           "purple",
	                           ]),
	title: PropTypes.node,
	description: PropTypes.node,
	small: PropTypes.node,
	footer: PropTypes.node
};

export default withStyles(infoCardStyle)(InfoCard);

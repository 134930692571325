import { useIntl } from "@hlcr/ui/Intl";
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, StandardProps } from "@material-ui/core";
import { PropsWithChildren } from "react";

export enum HlcrDialogActionType {
	CancelAction = "CancelAction",
	SaveAction = "SaveAction",

}

export interface HlcrDialogAction {
	title: string;
	color?: ButtonProps["color"];
	onClick?: ButtonProps["onClick"];
}

type HlcrDialogActions =
	{ [key in HlcrDialogActionType]?: HlcrDialogAction; }
	& { [key: string]: HlcrDialogAction };

type DialogActionOrFunction = HlcrDialogAction | ((event: {}) => void);

const DEFAULT_DIALOG_ACTIONS: { [key in HlcrDialogActionType]: HlcrDialogAction } = {
	[HlcrDialogActionType.CancelAction]: {
		title: "common.labels.cancel",
		color: "default",
	},
	[HlcrDialogActionType.SaveAction]: {
		title: "common.labels.save",
		color: "primary",
	},
};


// TODO: move to shared components
interface HlcrDialogProps extends StandardProps<DialogProps, never, "open" | "onClose"> {
	open?: boolean;
	cancelAction?: DialogActionOrFunction;
	saveAction?: DialogActionOrFunction;
	title?: string;
	actions?: HlcrDialogActions;
}

export const HlcrDialog = (
	{
		title,
		children,
		open = true,
		fullWidth = true,
		maxWidth = "md",
		cancelAction,
		saveAction,
		actions = {},
		...dialogProps
	}: PropsWithChildren<HlcrDialogProps>,
) => {
	const intl = useIntl();

	if (!actions?.[HlcrDialogActionType.CancelAction]) {
		if (cancelAction) {
			if (typeof cancelAction === "function") {
				actions = { ...actions, [HlcrDialogActionType.CancelAction]: { ...DEFAULT_DIALOG_ACTIONS.CancelAction, onClick: cancelAction } };
			} else if (cancelAction) {
				actions = { ...actions, [HlcrDialogActionType.CancelAction]: { ...DEFAULT_DIALOG_ACTIONS.CancelAction, ...cancelAction } };
			}
		} else {
			console.warn("Dialog without closing functionality");
		}
	}

	if (!actions?.[HlcrDialogActionType.SaveAction] && saveAction) {
		if (typeof saveAction === "function") {
			actions = { ...actions, [HlcrDialogActionType.SaveAction]: { ...DEFAULT_DIALOG_ACTIONS.SaveAction!, onClick: saveAction }! };
		} else {
			actions = { ...actions, [HlcrDialogActionType.SaveAction]: { ...DEFAULT_DIALOG_ACTIONS.SaveAction!, ...saveAction }! };
		}
	}

	return <Dialog
		{...dialogProps}
		open={open}
		onClose={actions?.CancelAction?.onClick}
		fullWidth={fullWidth}
		maxWidth={maxWidth}
	>
		{title && <DialogTitle>{intl.fm(title)}</DialogTitle>}
		<DialogContent>
			{children}
		</DialogContent>
		<DialogActions>
			{
				Object.entries(actions).map(([ key, action ]: [ key: string, action: HlcrDialogAction ]) => (
					<Button key={key} onClick={action.onClick} color={action.color}>{intl.fm(action.title)}</Button>
				))
			}
		</DialogActions>
	</Dialog>;
};

import { COLOR_INFO, COLOR_SUCCESS } from "@hlcr/mui/theme/hacking-lab.theme";
import { createStyles, makeStyles } from "@material-ui/core";


export const uploadStyle = createStyles({
	root: {
		width: "100%",
		height: "100%",
		padding: 12,
		border: "2px dashed #ccc",
		background: `repeating-linear-gradient(
			45deg,
			rgba(0, 0, 0, .04),
			rgba(0, 0, 0, .04) 10px,
			rgba(255, 255, 255, .1) 10px,
			rgba(255, 255, 255, .1) 20px
		)`,
	},
	accept: { borderColor: COLOR_SUCCESS },
	zoneContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	backGroundImage: {
		borderColor: COLOR_INFO,
		backgroundColor: "rgba(0, 172, 193, .1)",
		border: "2px dashed",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	},
	zoneText: {
		display: "flex",
		alignItems: "center",
		fontWeight: 700,
	},
	zoneIcon: {
		height: 50,
		width: 50,
	},
	attachmentIcon: { marginRight: 6 },
});

export const useUploadStyles = makeStyles(uploadStyle);

import { initState } from "./apiInitState";
import { ActionType } from "actions/ActionType";


export default (state = initState, action = null) => {
	switch (action.type) {
		case ActionType.API_REQUEST:
			if (!action.resource) {
				return state;
			}
			return {
				...state,
				resources: {
					...state.resources,
					[action.resource]: {
						...state.resources[action.resource],
						pending: true
					}
				}
			};
		case ActionType.API_RESULT:
			return {
				...state,
				resources: {
					...state.resources,
					[action.resource]: {
						data: mergeArray(
							state.resources[action.resource].data,
							action.data,
							action.updatePropForArray,
							action.updatePropForObj,
							action.updateField,
							action.setFieldOnObject
						),
						pending: false
					}
				}
			};
		case ActionType.API_REMOVE:
			return {
				...state,
				resources: {
					...state.resources,
					[action.resource]: {
						data: removeFromArray(
							state.resources[action.resource].data,
							action.entity,
							action.updatePropForObj
						),
						pending: false
					}
				}
			};
		case ActionType.ADD_NOTIFICATION:
			return {
				...state,
				notifications: [
					...state.notifications,
					{
						id: action.id,
						messageType: action.messageType,
						message: action.message
					}
				]
			};
		case ActionType.REMOVE_NOTIFICATION:
			const actionId = action.id;
			return {
				...state,
				notifications: [ ...state.notifications.filter(e => e.id !== actionId) ]
			};
		default:
			return state;
	}
};

const mergeArray = (
	resourceData,
	update,
	updatePropForArray,
	updatePropForObj,
	updateField,
	setFieldOnObject
) => {
	if (update === undefined || update === null) return resourceData;

	if (!Array.isArray(update)) {
		if (!updatePropForObj && !update.id || !Array.isArray(resourceData)) {
			return update;
		}

		if (!updateField) {
			const updateId = getId(update, updatePropForObj);
			return [
				...resourceData.filter(
					data => getId(data, updatePropForObj) !== updateId
				),
				setFieldOnObject
					? Object.assign(update, { [setFieldOnObject.key]: setFieldOnObject.value })
					: update
			];
		} else {
			if (update.length === 0) return resourceData;

			return updateFieldInObject(
				resourceData,
				update,
				updatePropForObj,
				updateField
			);
		}
	} else {
		return updateFieldsInArray(
			resourceData,
			update,
			updatePropForArray,
			updatePropForObj,
			setFieldOnObject
		);
	}
};

const updateFieldsInArray = (
	resourceData,
	update,
	updatePropForArray,
	updatePropForObj,
	setFieldOnObject
) => {
	if (updatePropForObj) {
		return [
			...resourceData.filter(
				data =>
					!update.find(
						updateData =>
							getId(data, updatePropForObj) ===
							getId(updateData, updatePropForObj)
					)
			),
			...addFieldsOnObjects(update, setFieldOnObject)
		];
	}

	if (updatePropForArray) {
		const { key, value } = updatePropForArray;
		return [
			...resourceData.filter(data =>
				typeof key === "function" ? key(data) !== value : data[key] !== value
			),
			...addFieldsOnObjects(update, setFieldOnObject)
		];
	}

	return [ ...addFieldsOnObjects(update, setFieldOnObject) ];
};

const addFieldsOnObjects = (update, setFieldOnObject) => {
	return setFieldOnObject
		? update.map(value =>
			Object.assign(
				value,
				setFieldOnObject &&
						typeof setFieldOnObject.key !== "function" && { [setFieldOnObject.key]: setFieldOnObject.value }
			)
		  )
		: update;
};

const updateFieldInObject = (
	resourceData,
	update,
	updatePropForObj,
	updateField
) => {
	const updateEntityId = updateField.entityId;
	const updateEntity = resourceData.find(
		data => getId(data, updatePropForObj) === updateEntityId
	);
	if (updateEntity && Array.isArray(updateEntity[updateField.key])) {
		return [
			...resourceData.filter(
				data => getId(data, updatePropForObj) !== updateEntityId
			),
			Object.assign({}, updateEntity, { [updateField.key]: mergeArray(updateEntity[updateField.key], update) })
		];
	}
	if (updateEntityId && updateEntity)
		return [
			...resourceData.filter(
				data => getId(data, updatePropForObj) !== updateEntityId
			),
			Object.assign({}, updateEntity, { [updateField.key]: update })
		];
	else {
		return resourceData;
	}
};

const removeFromArray = (resourceData, entity, updatePropForObj) => {
	if (!entity || !getId(entity, updatePropForObj)) {
		return resourceData;
	}
	return [
		...resourceData.filter(
			data => getId(data, updatePropForObj) !== getId(entity, updatePropForObj)
		)
	];
};

const getId = (data, updatePropForObj) => {
	const entityId = updatePropForObj
		? updatePropForObj.idSelector(data)
		: data.id;
	!entityId &&
		process.env.NODE_ENV !== "production" &&
		console.error("[getId]: Missing Id of updateEntity", data, updatePropForObj);
	return entityId;
};

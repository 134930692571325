import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { setDarkModeClass } from "helper/darkMode";
import { RootState } from "reducers";

// we need to re-define theme colors as the main and secondary color in our app is different to MUI standard
const commonThemeOptions = { primary: { main: "#ab47bc" }, secondary: { main: "#00acc1" } };

const lightTheme = createTheme({ palette: { ...commonThemeOptions } });
const darkTheme = createTheme({ palette: { type: "dark", ...commonThemeOptions } });

export const ThemeProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const darkMode = useSelector<RootState, boolean>((globalState: RootState) => globalState.ui.darkMode);

	useEffect(() => {
		setDarkModeClass(darkMode);
	}, [ darkMode ]);

	const theme = darkMode ? darkTheme : lightTheme;

	return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

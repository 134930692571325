import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";

import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import NoData from "components/NoData/NoData";
import StaticTokenModal from "components/StaticTokenModal/StaticTokenModal";
import EnhancedTable from "components/Table/EnhancedTable";
import { formatFullDate } from "helper/dateCalc";
import { withIntl } from "@hlcr/ui/Intl";
import ContentCopyIcon from "@material-ui/icons/FileCopyOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { addNotification, removeNotification } from "actions/api";
import guid from "helper/guid";
import { connect } from "react-redux";
import { MessageType } from "models/Message";

class StaticTokenList extends React.Component {
	state = {
		isModalOpen: false,
		modal: {
			eventId: 0,
			token: "",
			maxRedemptions: 0,
		},
	};

	render() {
		const {
			accessTokens,
			events,
			updateToken,
			showEventName = false,
			classes,
			isLoading,
			intl,
		} = this.props;

		const { modal, isModalOpen } = this.state;

		const tableRenderer = [];

		if (showEventName) {
			tableRenderer.push(
				...[
					{
						id: "image",
						title: intl.fm("staticTokenTable.redemptions"),
						colStyle: { width: 130 },
						renderCell: token => (
							<Paper elevation={2} square style={{ display: "inline-block" }}>
								<img
									src={`/api/images/events/${token.eventId}`}
									onError={e => {
										e.target.src = "/No_image_3x4.svg";
									}}
									className={classes.thumb}
									alt={token.name}
								/>
							</Paper>
						),
					},
					{
						id: "name",
						align: "left",
						renderCell: (token) => {
							if (events) {
								const matchingEvent = events.find(e => e.id === token.eventId);
								let html = <div>
									<span className={classes.eventTitle}>{matchingEvent.name}</span>
									{matchingEvent.exam ? `(${intl.fm("event.exam.title")})` : ""}
								</div>;
								if (matchingEvent.parentName) {
									html += <div className={classes.parentTitle}>{matchingEvent.parentName}</div>;
								}
								return html;
							}
						},
					},
				],
			);
		} else {
			tableRenderer.push(
				{
					id: "createDate",
					title: intl.fm("staticTokenTable.createDate"),
					renderCell: token => token.createDate ? (formatFullDate(token.createDate)) : ("n/a"),
				},
			);
		}

		tableRenderer.push(
			...[
				{
					id: "tokenInfo",
					title: "Used Tokens",
					renderCell: token => <div>{token.redemptions}/{token.maxRedemptions}</div>,
				},
				{
					id: "tokenValue",
					title: intl.fm("staticTokenTable.tokenValue"),
					colStyle: { width: 150 },
					renderCell: token => (
						<>
							<div style={{ display: "flex" }}>
								<Button
									style={{ marginRight: 10 }}
									color="info"
									customClass={classes.editButton}
									onClick={() => {
										const snackId = guid();
										navigator.clipboard.writeText(token.token);
										this.props.dispatch(
											addNotification(snackId, intl.fm("staticTokenTable.copySuccess"), MessageType.SUCCESS),
										);
										window.setTimeout(
											() => this.props.dispatch(removeNotification(snackId)),
											2000,
										);
									}}
								>
									<ContentCopyIcon className={classes.icon} />
									<span className={classes.text}>
									{intl.fm("staticTokenTable.copy")}
								</span>
								</Button>
								<Button
									color="info"
									customClass={classes.editButton}
									onClick={() =>
										this.setState({
											              isModalOpen: true,
											              modal: {
												              eventId: token.eventId,
												              tokenId: token.id,
												              token: token.token,
												              maxRedemptions: token.maxRedemptions,
											              },
										              })
									}
								>
									<VisibilityIcon className={classes.icon} />
									<span className={classes.text}>
									{intl.fm("staticTokenTable.edit")}
								</span>
								</Button>
							</div>
						</>
					),
				},
			],
		);

		const tableTitle = (
			<span>
				{intl.fm("staticTokenTable.title")}
				{isLoading && <CircularProgress size={18} />}
			</span>
		);

		return (
			<>
				<GridContainer>
					<ItemGrid xs={12}>
						<IconCard
							faIcon="key"
							iconColor="purple"
							title={tableTitle}
							content={
								<EnhancedTable
									isLoading={isLoading}
									tableData={accessTokens}
									tableRenderer={tableRenderer}
									hover
									emptyContent={<NoData />}
								/>
							}
						/>
					</ItemGrid>
				</GridContainer>
				<StaticTokenModal
					isOpen={isModalOpen}
					eventId={modal.eventId}
					tokenId={modal.tokenId}
					token={modal.token}
					maxRedemptions={modal.maxRedemptions}
					updateToken={updateToken}
					handleClose={this.closeModal}
					intl={intl}
				/>
			</>
		);
	}

	closeModal = () => this.setState({ isModalOpen: false });
}

StaticTokenList.proptTypes = {
	accessTokens: PropTypes.arrayOf(PropTypes.object).isRequired,
	updateToken: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	showEventName: PropTypes.bool,
};

const style = {
	thumb: {
		height: 72,
		width: 110,
		objectFit: "cover",
	},
	editButton: {
		margin: "5px 0",
		padding: "5px",
		whiteSpace: "normal",
	},
	icon: {
		verticalAlign: "middle",
		width: "17px",
		height: "17px",
		top: "-1px",
		position: "relative",
	},
	text: { marginLeft: 5 },
};

export default compose(connect(), withStyles(style), withIntl)(StaticTokenList);

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faArrowRight,
	faCheck,
	faClipboard,
	faClone,
	faDesktop,
	faExchangeAlt,
	faExternalLinkAlt,
	faKey,
	faLightbulb,
	faPencilAlt,
	faPlay,
	faRedoAlt,
	faSearch,
	faServer,
	faStop,
	faUser,
	faUserTie
} from "@fortawesome/free-solid-svg-icons";

library.add(
	faAngleDoubleRight,
	faAngleDoubleLeft,
	faServer,
	faPlay,
	faStop,
	faRedoAlt,
	faExchangeAlt,
	faUser,
	faUserTie,
	faDesktop,
	faPencilAlt,
	faLightbulb,
	faArrowRight,
	faClone,
	faExternalLinkAlt,
	faCheck,
	faClipboard,
	faKey,
	faSearch
);

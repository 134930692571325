export enum HelpRole {
	MANAGER = "manager",
	ADMIN = "admin",
}

export enum HelpAction {
	PAGE_EVENT_MANAGEMENT = "pageEventManagement",
	PAGE_EVENT_DETAILS = "pageEventDetails",
	PAGE_TEAM_MANAGEMENT = "pageTeamManagement",
	PAGE_TEAM_DETAILS = "pageTeamDetails",
	PAGE_CLASS_MANAGEMENT = "pageClassManagement",
	PAGE_CLASS_DETAILS = "pageClassDetails",
	ROOT = "#",
}

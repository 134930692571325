import { ActionType } from "actions/ActionType";

const BASE_URL = "/api";

const fetchBackendVersion = onSuccess => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/about`,
	method: "get",
	onSuccess: (_, result) => {
		if (onSuccess) {
			onSuccess(result);
		}
	},
	onFailure: (_, result) => {}
});

export default { fetchBackendVersion };

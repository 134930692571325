import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

let FloatingActions = ({ children, classes }) => (
	<div className={classes.container}>{children}</div>
);

FloatingActions.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.object.isRequired
};

const style = {
	container: {
		position: "fixed",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		bottom: 15,
		right: 15,
		zIndex: 10
	}
};

FloatingActions = withStyles(style)(FloatingActions);

export default FloatingActions;

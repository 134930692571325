import { HttpMethod } from "@hlcr/core/enum/HttpMethod";

import { RemoteResource } from "models/RemoteResource";
import { DynamicRequestAction, RemoteResourceActionType } from "redux/actions";

const BASE_URL = "/api/maintenance";

export const fetchMaintenanceInfoBanner: DynamicRequestAction = () => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/info-banner`,
	resource: RemoteResource.MAINTENANCE_INFO_BANNER,
	method: HttpMethod.GET,
});

import { CircularProgress, Mark, Slider } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";

interface EventManagerSummaryProps {
	marks: Array<Mark & { quantity: number }>;
	stateInitializers: Array<() => { [key: string]: any }>;
	loadingSelector: (state: RootState) => boolean;
}

export const EventManagerSummary = ({ marks, stateInitializers, loadingSelector }: EventManagerSummaryProps) => {
	const dispatch = useDispatch();
	const isLoading = useSelector(loadingSelector);

	useEffect(() => {
		for (const stateInitializer of stateInitializers) {
			dispatch(stateInitializer());
		}
	}, []);


	const labelFormatter = (index: number) => `${marks[index].quantity}`;

	return isLoading
		? <CircularProgress size={18} />
		: <div style={{ marginTop: 48, marginLeft: 64, marginRight: 64 }}>
				<Slider
					onChange={() => {}}
					max={marks.length - 1}
					track={false}
					value={[ ...marks.keys() ]}
					marks={marks}
					valueLabelDisplay="on"
					valueLabelFormat={labelFormatter} // see https://github.com/mui-org/material-ui/issues/21559#issuecomment-871605031
				/>
			</div>
	;
};

import { SvgIcon } from "@material-ui/core";
import { RedirectProps, RouteProps } from "react-router";

import { EnumBase } from "enum/EnumBase";
import { HackingLabRole } from "model/HackingLabRole";
import { HelpAction, HelpRole } from "model/Help";

export enum HackingLabRouteType {
	COMPONENT_ROUTE,
	REDIRECT_ROUTE,
	COLLAPSIBLE_ROUTE
}

// TODO: extend EnumWithIcon
interface HackingLabBaseRoute {
	type: HackingLabRouteType;
	name?: () => JSX.Element | string;
	needsRole?: HackingLabRole;
	checkAccessPolicy?: boolean;
	hasSidebarLink?: boolean;
	needsAnyRole?: HackingLabRole[];
	icon?: typeof SvgIcon;
	iconColor?: string;
	faIcon?: string;
	mini?: string;
}

type HackingLabComponentRouteBase = HackingLabBaseRoute & Pick<RouteProps, "path" | "component">;

interface HackingLabComponentRoute extends HackingLabComponentRouteBase {
	type: HackingLabRouteType.COMPONENT_ROUTE,
	breadcrumb?: Function | string;
	help?: {
		role: HelpRole;
		action: HelpAction;
	};
}

type HackingLabRedirectRouteBase = HackingLabBaseRoute & Pick<RedirectProps, "to" | "from">

interface HackingLabRedirectRoute extends HackingLabRedirectRouteBase {
	type: HackingLabRouteType.REDIRECT_ROUTE,
	roleMapping?: { [key in HackingLabRole]: RedirectProps["to"] };
}

interface HackingLabCollapsibleRoute extends HackingLabBaseRoute {
	type: HackingLabRouteType.COLLAPSIBLE_ROUTE,
	routes: HackingLabRoute[];
}

export type HackingLabRoute = (HackingLabComponentRoute | HackingLabRedirectRoute | HackingLabCollapsibleRoute) & EnumBase;

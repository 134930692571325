import { coexaminationEnabled, Event } from "models/Event";
import { Grading } from "models/Grading";
import { SolutionState } from "models/SolutionState";

export function computeSolutionStatiForCoexamination(solutions: any, event: Event, gradings: Grading[], teacherId: number) {
	let newSolutions = solutions;
	if (coexaminationEnabled(event)) {
		const clonedSolutions = [ ...solutions ];
		const coexaminationMap = generateSolutionExaminatorMapping(gradings);
		newSolutions = clonedSolutions.map((solution) => {
			if (needsCoexamination(solution.id, event, coexaminationMap)) {
				if (alreadyExaminedByTeacher(solution.id, teacherId, coexaminationMap)) {
					return { ...solution, state: SolutionState.NEEDS_COEXAMINATION };
				}
				return { ...solution, state: SolutionState.NEEDS_EXAMINATION };
			}
			return solution;
		});
	}
	return newSolutions;
}

export function filterCoexaminationStatiIfNotCoexamination(event: Event, key: SolutionState) {
	return coexaminationEnabled(event) || (key !== SolutionState.NEEDS_COEXAMINATION && key !== SolutionState.NEEDS_EXAMINATION);
}

export function generateSolutionExaminatorMapping(gradings: Grading[]) {
	const solutionExaminatorMap: Map<number, number[]> = new Map();
	for (const grading of gradings) {
		addValueForKey(solutionExaminatorMap, grading.solutionId, grading.teacherId);
	}
	return solutionExaminatorMap;
}

function addValueForKey(map: Map<number, number[]>, key: number, value: number) {
	if (!map.has(key)) {
		map.set(key, []);
	}
	map.get(key)?.push(value);
}

export function needsCoexamination(solutionId: number, event: Event, solutionExaminatorMap: Map<number, number[]>) {
	return coexaminationEnabled(event)
		&& new Set(solutionExaminatorMap.get(solutionId)).size < event.examiners;
}

export function alreadyExaminedByTeacher(solutionId: number, teacherId: number, solutionExaminatorMap: Map<number, number[]>) {
	const solutionComments = solutionExaminatorMap.get(solutionId);
	return solutionComments && solutionComments.includes(teacherId);
}

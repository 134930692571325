import { HackingLabRoute, HackingLabRouteType } from "@hlcr/app/enum/EnumRoute";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import InsertChartIcon from "@material-ui/icons/InsertChart";

import ClassesReport from "views/Report/ClassesReport";
import ClassReport, { ClassReportBreadCrumb } from "views/Report/ClassReport";
import ReportChallengeSolution from "views/Report/ReportChallengeSolution";
import ReportQuizSolution from "views/Report/ReportQuizSolution";
import TeamReport, { TeamReportBreadCrumb } from "views/Report/TeamReport";
import UserReport, { UserReportBreadCrumb } from "views/Report/UserReport";
import { TeacherQuizBreadCrumb } from "views/Teacher/TeacherQuiz";
import { TeacherSolutionBreadCrumb } from "views/Teacher/TeacherSolution";
import OwnUserReport from "views/User/OwnUserReport";

import * as intl from "./localization";


export const reportRoutes: HackingLabRoute[] = [
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/ownreport",
		name: intl.Report,
		icon: InsertChartIcon,
		component: OwnUserReport,
		hasSidebarLink: true,
		needsRole: HackingLabRole.COMPOSITE_USER,
		breadcrumb: intl.Report,
		help: { role: "user", action: "pageOwnReport" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/report/classes",
		name: intl.ClassesReport,
		component: ClassesReport,
		hasSidebarLink: true,
		icon: InsertChartIcon,
		needsRole: HackingLabRole.COMPOSITE_REPORTS,
		breadcrumb: intl.ClassesReport,
		help: { role: "auditor", action: "pageClassesReport" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/report/classes/:classId",
		name: intl.ClassReport,
		component: ClassReport,
		needsRole: HackingLabRole.COMPOSITE_REPORTS,
		breadcrumb: ClassReportBreadCrumb,
		help: { role: "auditor", action: "pageClassReport" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/report/classes/:classId/teams/:teamId",
		name: intl.UsersReport,
		component: TeamReport,
		needsRole: HackingLabRole.COMPOSITE_REPORTS,
		breadcrumb: TeamReportBreadCrumb,
		help: { role: "auditor", action: "pageTeamReport" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/report/classes/:classId/teams/:teamId/users/:userId",
		name: intl.UserReport,
		component: UserReport,
		needsRole: HackingLabRole.COMPOSITE_REPORTS,
		breadcrumb: UserReportBreadCrumb,
		help: { role: "auditor", action: "pageUserReport" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/report/classes/:classId/teams/:teamId/users/:userId/challenge/:solutionId",
		component: ReportChallengeSolution,
		needsRole: HackingLabRole.COMPOSITE_REPORTS,
		breadcrumb: TeacherSolutionBreadCrumb,
		help: { role: "auditor", action: "pageUserReport" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/report/classes/:classId/teams/:teamId/users/:userId/quiz/:solutionId",
		component: ReportQuizSolution,
		needsRole: HackingLabRole.COMPOSITE_REPORTS,
		breadcrumb: TeacherQuizBreadCrumb,
		help: { role: "auditor", action: "pageUserReport" },
	},
];

import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";

import { checkHasRole } from "auth/authUtils";
import CustomInput from "components/CustomInput/CustomInput";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import { checkDatePassed } from "helper/dateCalc";
import { withIntl } from "@hlcr/ui/Intl";
import { UserEventState, UserEventStateSelectList } from "variables/constants";


const EventFilterBar = ({ query, onQueryChange, selection, onSelectionChange, includeArchived = true, intl, classes, children }) => {
	const isEventAdmin = checkHasRole(HackingLabRole.EVENT_ADMIN);
	const eventStateList = isEventAdmin && includeArchived ? UserEventStateSelectList : UserEventStateSelectList.filter(value => value.id !== UserEventState.ARCHIVED);
	return (
		<div className={classes.searchBar}>
			<CustomInput
				searchIcon
				inputProps={{
					type: "inputSearch",
					value: query,
					onChange: onQueryChange,
					placeholder: intl.fm("manager.eventOverview.searchPlaceholder")
				}}
				classes={{ formControl: classes.filterControl }}
			/>
			{children}
			<div style={{ display: "inline-flex" }}>
				<div className={classes.switchBox}>
					<CustomMultiSelect
						values={eventStateList}
						idField="id"
						displayField="text"
						fullWidth
						selection={selection}
						onSelect={onSelectionChange}
					/>
				</div>
			</div>
		</div>
	);
};

EventFilterBar.propTypes = {
	query: PropTypes.string.isRequired,
	onQueryChange: PropTypes.func.isRequired,
	selection: PropTypes.arrayOf(PropTypes.string).isRequired,
	onSelectionChange: PropTypes.func.isRequired,
	includeArchived: PropTypes.bool,
	intl: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	children: PropTypes.node
};

const style = {
	searchBar: {
		display: "flex",
		flexWrap: "wrap-reverse",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: "2em"
	},
	filterControl: {
		margin: "10px 0",
		padding: 0
	},
	passedLabel: {
		fontSize: "1em",
		cursor: "pointer"
	},
	switchBox: { padding: "0 0 0 20px" }
};

export const createQueryFilter = (query, selection) => event =>
	(selection.includes(UserEventState.RUNNING) || (!checkDatePassed(event.startTime) || (event.endTime && checkDatePassed(event.endTime)))) &&
	(selection.includes(UserEventState.UPCOMING) || checkDatePassed(event.startTime)) &&
	(selection.includes(UserEventState.PAST) || event.endTime === undefined || (!checkDatePassed(event.endTime) || event.archived)) &&
	(selection.includes(UserEventState.ARCHIVED) || !event.archived) &&
	(query.length === 0 || event.name.toLowerCase().includes(query.toLowerCase()));

export default compose(
	withIntl,
	withStyles(style)
)(EventFilterBar);

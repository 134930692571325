import de from "./messages/de";
import en from "./messages/en";

export function getLocaleId() {
	// in case we implement multilanguage: navigator.language === 'de-DE' ? 'de' : navigator.language;
	return "en";
}

export function getTranslation() {
	switch (getLocaleId().split("-")[0]) {
		case "de":
			return de;
		case "en":
			return en;
		default:
			return en;
	}
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";

import customInputStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/customInputStyle";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

const labelClasses = (classes, inputProps, success, error) =>
	cx(classes.labelRoot, {
		[classes.labelRootError]: error,
		[classes.labelRootSuccess]: success && !error,
		[classes.labelWithAdornment]:
		inputProps && inputProps.endAdornment !== undefined,
	});
const underlineClasses = (classes, inputProps, success, error, white) =>
	cx({
		[classes.underlineError]: error,
		[classes.underlineSuccess]: success && !error,
		[classes.underline]: true,
		[classes.whiteUnderline]: white,
	});
const formControlClasses = (classes, formControlProps) =>
	cx(classes.formControl, { [formControlProps && formControlProps.className]: formControlProps });
const successClasses = (classes, labelText, inputProps) =>
	cx(classes.feedback, classes.labelRootSuccess, {
		[classes.feedbackNoLabel]: labelText === undefined,
		[classes.feedbackAdorment]:
		inputProps && inputProps.endAdornment !== undefined,
	});
const errorClasses = (classes, labelText, inputProps) =>
	cx(classes.feedback, classes.labelRootError, {
		[classes.feedbackNoLabel]: labelText === undefined,
		[classes.feedbackAdorment]:
		inputProps && inputProps.endAdornment !== undefined,
	});
const inputClasses = (classes, white, inputProps) =>
	cx(classes.input, {
		[classes.whiteInput]: white,
		[inputProps.inputClasses]: inputProps.inputClasses,
	});

function CustomInput(
	{
		classes,
		formControlProps,
		labelText,
		id,
		labelProps,
		inputProps,
		error,
		white,
		inputRootCustomClasses,
		success,
		helpText,
		searchIcon,
	},
) {
	const inputRootClasses = cx(classes.inputRoot, {
		[inputRootCustomClasses]: inputRootCustomClasses !== undefined,
		[classes.inputSearchMode]: searchIcon,
	});
	const helpTextClasses = cx({
		[classes.labelRootError]: error,
		[classes.labelRootSuccess]: success && !error,
	});

	if (inputProps) {
		delete inputProps.inputClasses;
		delete inputProps.startAdornment;
		// XXX: Why do we prevent adornments at all?
		// delete inputProps.endAdornment;
	}

	return (
		<FormControl
			{...formControlProps}
			className={formControlClasses(classes, formControlProps)}
			aria-describedby={id + "-text"}
		>
			{labelText !== undefined && (
				<InputLabel
					className={labelClasses(classes, inputProps, success, error)}
					htmlFor={id}
					{...labelProps}
				>
					{labelText}
				</InputLabel>
			)}
			<div className={classes.inputGroup}>
				{searchIcon && (
					<FontAwesomeIcon icon="search" className={classes.searchIcon} />
				)}
				<Input
					classes={{
						input: inputClasses(classes, white, inputProps),
						root: inputRootClasses,
						disabled: classes.disabled,
						underline: underlineClasses(
							classes,
							inputProps,
							success,
							error,
							white,
						),
					}}
					id={id}
					{...inputProps}
				/>
			</div>
			{error && (
				<Clear className={errorClasses(classes, labelText, inputProps)} />
			)}
			{success && !error && (
				<Check className={successClasses(classes, labelText, inputProps)} />
			)}
			{helpText !== undefined ? (
				<FormHelperText className={helpTextClasses} id={id + "-text"}>
					{helpText}
				</FormHelperText>
			) : null}
		</FormControl>
	);
}

CustomInput.propTypes = {
	classes: PropTypes.object.isRequired,
	inputProps: PropTypes.object.isRequired,
	labelProps: PropTypes.object,
	labelText: PropTypes.node,
	id: PropTypes.string,
	formControlProps: PropTypes.object,
	inputRootCustomClasses: PropTypes.string,
	error: PropTypes.bool,
	success: PropTypes.bool,
	white: PropTypes.bool,
	helpText: PropTypes.node,
	searchIcon: PropTypes.bool,
};

CustomInput.defaultProps = { inputProps: {} };

export default withStyles(customInputStyle)(CustomInput);

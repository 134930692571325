import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import React from "react";

const style = { grid: { padding: "0 15px !important" } };

function ItemGrid({ classes, children, className, ...rest }) {
	return (
		<Grid item {...rest} className={cx(classes.grid, className)}>
			{children}
		</Grid>
	);
}

export default withStyles(style)(ItemGrid);

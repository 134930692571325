export default {
	cardGridContainer: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-around"
	},
	cardContainer: {
		display: "inline-block",
		margin: "0 12px",
		"@media (max-width: 900px)": { margin: "0 6px" }
	}
};

export interface VirtualMachine {
	uuid: string;
	displayName: string;
	state: keyof typeof PowerState;
	revertToSnapshot: boolean;
	userProperties: { [key: string]: any };
}

export const PowerState = {
	POWERED_OFF: "POWERED_OFF",
	POWERED_ON: "POWERED_ON",
	SUSPENDED: "SUSPENDED",
};

import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import { formatFullDate, printDuration } from "helper/dateCalc";
import { formatMessage, withIntl } from "@hlcr/ui/Intl";
import {ASSET_TYPES} from "models/Asset"

const StartExam = ({ event, onStartEvent, intl }) => (
	<>
		<GridContainer>
			<ItemGrid xs={12} sm={12} md={6} lg={4}>
				<IconCard
					icon={HourglassFullIcon}
					title={printDuration(event.timeLimitInSeconds, intl)}
					content={
						<>
							<p>{intl.fm("event.exam.introduction")}</p>
							<p>
								{event.timeLimitInSeconds
									? formatMessage("event.exam.startExamDuration", null, {
										duration: (
											<b>{printDuration(event.timeLimitInSeconds, intl)}</b>
										),
										endDate: <b>{formatFullDate(event.endTime, intl)}</b>
									  })
									: formatMessage("event.exam.startExamNoDuration", null, { endDate: <b>{formatFullDate(event.endTime, intl)}</b> })}
							</p>
						</>
					}
				/>
			</ItemGrid>
			<ItemGrid xs={12} sm={12} md={6} lg={4}>
				<IconCard
					icon={ASSET_TYPES.QUIZ.icon}
					title={event.name}
					content={<p>{event.description}</p>}
				/>
			</ItemGrid>
		</GridContainer>
		<IconCard
			fluidWidth
			icon={PlayArrowIcon}
			iconColor="blue"
			title={intl.fm("event.exam.title")}
			content={
				<>
					{/* <div>{intl.fm('event.exam.readyMessage')}</div> */}
					<Button onClick={onStartEvent} color="infoNoBackground">
						{intl.fm("event.exam.startExam")}
					</Button>
					<Link to="/events">
						<Button color="defaultNoBackground">
							{intl.fm("common.labels.cancel")}
						</Button>
					</Link>
				</>
			}
		/>
	</>
);

StartExam.propTypes = {
	event: PropTypes.object,
	onStartEvent: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired
};

export default withIntl(StartExam);

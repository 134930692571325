import { ONE_HUNDRED_PERCENT, percent, percentage, roundToDecimals } from "@hlcr/core/numeric";
import { accessibilitySuccessColor, accessibilityWarningColor, grayColor, roseColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { useSolutionStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/solutionStyle";
import { useIntl } from "@hlcr/ui/Intl";
import { makeStyles, Slider, TextField } from "@material-ui/core";

const MIN_PERCENTAGE = 0;
const MAX_PERCENTAGE = ONE_HUNDRED_PERCENT;

interface PointSliderProps {
	value: number;
	maxPoints: number;
	flagPoints: number;
	writeupWeight: number;
	onChange: (value: number) => void;
	disabled: boolean;
}

const PointSlider = ({ value, maxPoints, flagPoints, writeupWeight, onChange, disabled }: PointSliderProps) => {
	const classes = useSolutionStyles();
	const localClasses = useStyles(getColor(disabled, value));
	const { handleChange, handlePointsChange } = getChangeHandlers(onChange, maxPoints, writeupWeight);
	const writeupPoints = roundToDecimals(percent(value * writeupWeight) * maxPoints, 0);

	return (
		<div className={classes.pointSliderFormBox}>
			<TextField type="number" label="Points" variant="outlined" size="small" value={writeupPoints} onChange={handlePointsChange} className={classes.pointsInputField}
			           disabled={disabled} />
			<div className={classes.pointSliderBox}>
				<FlagAndWriteupPointsLabel writeupWeight={writeupWeight} flagPoints={flagPoints} writeupPoints={writeupPoints} />
				<TotalPointsLabel flagPoints={flagPoints} writeupPoints={writeupPoints} maxPoints={maxPoints} />
				<Slider
					className={classes.slider}
					value={value}
					min={MIN_PERCENTAGE}
					max={MAX_PERCENTAGE}
					getAriaValueText={(value: number) => `${value}%`}
					onChange={handleChange}
					aria-labelledby="discrete-slider-always"
					step={1}
					marks={getSliderMarkings(value)}
					valueLabelDisplay="off"
					classes={{ ...localClasses }}
					disabled={disabled}
				/>
			</div>
		</div>
	);
};

const FlagAndWriteupPointsLabel = (
	{ writeupWeight, flagPoints, writeupPoints }:
	{writeupWeight: number, flagPoints: number, writeupPoints: number}) => {
	const classes = useSolutionStyles();
	const intl = useIntl();
	return (
		<div className={classes.points}>
			{writeupWeight < 1 && (
				<>
					{flagPoints ? <span>{intl.fm("teacher.solution.grading.flagPoints", undefined, { flagPoints })} + </span> : null}
					{intl.fm("teacher.solution.grading.writeupPoints", undefined, { writeupPoints })}
				</>
			)}
		</div>
	);
};

const TotalPointsLabel = ({ flagPoints, writeupPoints, maxPoints }: {flagPoints: number, writeupPoints: number, maxPoints: number}) => {
	const classes = useSolutionStyles();
	const intl = useIntl();
	return (
		<div className={classes.labels}>
			<div>
				{intl.fm("teacher.solution.grading.totalLabel")}
				<strong>{flagPoints + writeupPoints}</strong>
				{intl.fm("teacher.solution.grading.ofMaxPoints", undefined, { maxPoints })}
			</div>
		</div>
	);
};

function convertPointsToPercentage(points: number, maxPoints: number, writeupWeight: number) {
	return percentage(points / (maxPoints - maxPoints * (1 - writeupWeight)));
}

function getColor(disabled: boolean, value: number) {
	if (disabled) {
		return grayColor;
	} else if (value === MIN_PERCENTAGE) {
		return roseColor;
	} else if (value === MAX_PERCENTAGE) {
		return accessibilitySuccessColor;
	} else {
		return accessibilityWarningColor;
	}
}

function getSliderMarkings(value: number) {
	const marks = [ { value, label: value + "%" } ];
	value >= 5 && marks.push({ value: 0, label: "0%" });
	(value <= 20 || value >= 30) && marks.push({ value: 25, label: "25%" });
	(value <= 45 || value >= 55) && marks.push({ value: 50, label: "50%" });
	(value <= 70 || value >= 80) && marks.push({ value: 75, label: "75%" });
	value <= 95 && marks.push({ value: 100, label: "100%" });
	return marks;
}

function getChangeHandlers(onChange: (value: number) => void, maxPoints: number, writeupWeight: number) {
	const handleChange = (event: React.ChangeEvent<{}>, value: number | number[]) => onChange(value as number);
	const handlePointsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(convertPointsToPercentage(Number(event.target.value), maxPoints, writeupWeight));
	return { handleChange, handlePointsChange };
}

const useStyles = (color: string) => makeStyles({
	root: { height: 16 },
	thumb: {
		height: 20,
		width: 20,
		marginTop: -8,
		marginLeft: -12,
		backgroundColor: "#fff",
		border: "2px solid",
	},
	track: {
		height: 4,
		borderRadius: 4,
		color,
	},
	rail: {
		height: 4,
		borderRadius: 4,
		color,
	},
})();

export default PointSlider;

import { withStyles } from "@material-ui/core/styles";
import MailIcon from "@material-ui/icons/Mail";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import userAPI from "actions/profile";
import Snackbar from "components/Snackbar/Snackbar";

const MAIL_HINT_DISMISSED_KEY = "mailHintDismissed";

class MailHint extends React.Component {
	timeout = null;

	constructor(props) {
		super(props);
		this.state = {
			dismissed:
				props.emailNotification ||
				localStorage.getItem(MAIL_HINT_DISMISSED_KEY) === "true" ||
				false,
			message: ""
		};
	}

	componentDidMount() {
		if (!this.props.emailNotification) return;

		this.checkEmailVerification();
	}

	componentDidUpdate(prevProps) {
		const { emailNotification } = this.props;

		if (!prevProps.emailNotification && emailNotification)
			this.checkEmailVerification();
	}

	componentWillUnmount() {
		if (this.timeout) clearTimeout(this.timeout);
	}

	render() {
		const { classes, emailNotification } = this.props;
		const { dismissed, message } = this.state;

		if (!emailNotification) return null;

		return (
			<Snackbar
				place="bl"
				color="primary"
				icon={MailIcon}
				message={
					<div className={classes.message}>
						Please verify your email.&nbsp;
						<span onClick={this.resendMail} className={classes.link}>
							Click Here
						</span>{" "}
						to resend eMail verification.{" "}
						<span
							onClick={this.dismissHint}
							className={cx(classes.link, classes.dismiss)}
						>
							DISMISS
						</span>
						<b> {message}</b>
					</div>
				}
				closeNotification={this.dismissHint}
				open={!dismissed}
				close
			/>
		);
	}

	checkEmailVerification = () => {
		this.timeout = setTimeout(() => {
			this.props.fetchUserInfo(result => {
				if (result) {
					localStorage.setItem(MAIL_HINT_DISMISSED_KEY, result.emailVerified);
					this.setState({ dismissed: result.emailVerified });
				}
			});
		}, 1000);
	};

	resendMail = () => {
		this.props.resendMailVerification(result => {
			if (result) {
				this.setState({ message: "Mail sent." });
			} else {
				this.setState({ message: "Failed." });
			}
		});
	};

	dismissHint = () => {
		localStorage.setItem(MAIL_HINT_DISMISSED_KEY, true);
		this.setState({ dismissed: true });
	};
}

MailHint.propTypes = {
	emailNotification: PropTypes.bool.isRequired,
	fetchUserInfo: PropTypes.func.isRequired,
	resendMailVerification: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired
};

const style = {
	message: {
		fontSize: "1.1em",
		lineHeight: "1.4em"
	},
	link: {
		cursor: "pointer",
		color: "#fff",
		borderBottom: "1px dotted #fff",
		fontWeight: 500,
		"&:hover,&:active,&:focus": {
			color: "#fff",
			background: "rgba(255, 255, 255, .2)"
		}
	},
	dismiss: {
		fontSize: "0.7em",
		opacity: 0.8
	}
};

const mapStateToProps = state => ({ emailNotification: state.branding.emailNotification });

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchUserInfo: userAPI.fetchUserInfo,
			resendMailVerification: userAPI.resendMailVerification
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(style)
)(MailHint);

import { withStyles } from "@material-ui/core/styles";
import GroupIcon from "@material-ui/icons/Group";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import reportApi from "actions/report";
import reportStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/reportStyle";
import InfoCard from "components/Cards/InfoCard";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import { DownloadButton, ReportLink, ReportTableCard, SelectCard } from "components/Report/ReportComponents";
import { calcPercentage, printPercentage } from "helper/pointPrint";
import { withIntl } from "@hlcr/ui/Intl";
import { getSolutionSuccessStateByGrade } from "models/SolutionState";

class ClassReport extends React.Component {
	componentDidMount() {
		const { fetchClasses, fetchAllTeams } = this.props;
		fetchClasses();
		fetchAllTeams();
	}

	render() {
		const { classReport, teamReports } = this.props;
		return (
			<GridContainer>
				<ItemGrid xs={12} sm={12} md={6} lg={4}>
					<ClassSelectCard onSelectClass={this.onSelectClass} {...this.props} />
				</ItemGrid>
				<ItemGrid xs={12} sm={6} md={6} lg={3}>
					{classReport && <NrOfMembers {...this.props} />}
				</ItemGrid>
				<ItemGrid xs={12} sm={6} md={6} lg={3}>
					{classReport && <ClassStatus {...this.props} />}
				</ItemGrid>

				<ItemGrid xs={12} sm={12} md={12} lg={12}>
					{teamReports && <TeamTableCard {...this.props} />}
				</ItemGrid>
			</GridContainer>
		);
	}

	onSelectClass = (classId) => {
		const { history } = this.props;
		if (classId !== null) {
			history.push(`/report/classes/${classId}`);
		}
	};
}

const TeamTableCard = props => {
	const { teamReports, classReport, intl, classes, classId } = props;
	const tableRenderer = [
		{
			id: "name",
			title: intl.fm("report.table.teams.header.name"),
			renderCell: teamReport => teamReport.teamName,
			renderCsv: teamReport => teamReport.teamName,
			sort: true
		},
		{
			id: "class",
			title: intl.fm("report.table.teams.header.class"),
			renderCell: teamReport => teamReport.className,
			renderCsv: teamReport => teamReport.className,
			sort: true
		},
		{
			id: "members",
			title: intl.fm("report.table.teams.header.members"),
			renderCell: teamReport => teamReport.userCount,
			renderCsv: teamReport => teamReport.userCount,
			sort: true
		},
		{
			id: "events",
			title: intl.fm("report.table.teams.header.events"),
			renderCell: teamReport => teamReport.eventCount,
			renderCsv: teamReport => teamReport.eventCount,
			sort: true
		},
		{
			id: "units",
			title: intl.fm("report.table.teams.header.units"),
			renderCell: teamReport => teamReport.unitCount,
			renderCsv: teamReport => teamReport.unitCount,
			sort: true
		},
		{
			id: "status",
			title: intl.fm("report.table.teams.header.status"),
			renderCell: teamReport =>
				printPercentage(
					teamReport.mandatoryFullPointSolutionCount,
					teamReport.mandatoryUserChallengeCount
				),
			renderCsv: teamReport =>
				printPercentage(
					teamReport.mandatoryFullPointSolutionCount,
					teamReport.mandatoryUserChallengeCount
				),
			sort: (a, b) =>
				b.mandatoryUserChallengeCount - a.mandatoryUserChallengeCount
		},
		{
			id: "action",
			title: intl.fm("report.table.teams.header.action"),
			renderCell: teamReport => (
				<ReportLink
					label={intl.fm("report.table.users")}
					to={`/report/classes/${teamReport.classId}/teams/${teamReport.teamId}`}
				/>
			)
		}
	];

	const csvTitle = classReport ? `Class ${classReport.className} Teams Report, ${moment().format("DD.MM.YYYY HH:mm")}` : "";
	const csvFilename = classReport ? `Class_${classReport.className}_${moment().format("DD.MM.YYYY_HH.mm")}.csv` : "";

	return (
		<ReportTableCard
			tableData={teamReports}
			title={
				<div>
					{intl.fm("report.table.teams")}
					{classId !== 0 && classReport && (
						<span className={classes.titleButton}>
							<ReportLink
								label={intl.fm("report.table.classes.teamsFromClass", null, { className: classReport.className })}
								to={`/report/classes/${classId}/teams/0`}
								noBackground
							/>
						</span>
					)}
					<DownloadButton
						tableData={teamReports}
						csvTitle={csvTitle}
						csvFilename={csvFilename}
						tableRenderer={tableRenderer}
					/>
				</div>
			}
			color="purple"
			cardIcon={GroupIcon}
			tableRenderer={tableRenderer}
		/>
	);
};

const NrOfMembers = ({ classReport, intl }) => (
	<InfoCard
		icon={GroupIcon}
		iconColor="purple"
		title={intl.fm("report.card.title.members")}
		description={classReport.userCount}
		footer={`${classReport.teacherCount} ${intl.fm(
			"report.card.description.teachers"
		)}`}
	/>
);

const ClassSelectCard = ({ onSelectClass, classReport, classReportOptions, intl }) => {
	const nrOfTeams = classReport ? classReport.teamCount : 0;
	return (
		<SelectCard
			icon={GroupIcon}
			color="purple"
			title={intl.fm("report.card.title.class")}
			onSelect={onSelectClass}
			value={classReport}
			selects={classReportOptions}
			idField="classId"
			displayField="className"
			footer={`${nrOfTeams} ${intl.fm("report.card.title.teams")}`}
		/>
	);
};

const ClassStatus = ({ classReport, intl }) => {
	const pointsValue = calcPercentage(classReport.mandatoryFullPointSolutionCount, classReport.mandatoryUserChallengeCount);
	const state = getSolutionSuccessStateByGrade(pointsValue);
	return (
		<InfoCard
			icon={state.icon}
			iconColor={state.iconColor}
			title={intl.fm("report.card.title.status")}
			description={printPercentage(classReport.mandatoryFullPointSolutionCount, classReport.mandatoryUserChallengeCount, "\u00A0")}
		/>
	);
};

const mapStateToProps = (state, ownProps) => {
	const classId = Number(ownProps.match.params.classId);
	const { reportClasses, reportTeams } = state.api.resources;

	const allClassesReport = reportClasses.data.length
		? reportClasses.data.reduce(
			(r1, r2) => ({
				classId: 0,
				className: "All Classes",
				teamCount: r1.teamCount + r2.teamCount,
				userCount: r1.userCount + r2.userCount,
				teacherCount: r1.teacherCount + r2.teacherCount,
				mandatoryChallengeCount:
					r1.mandatoryChallengeCount + r2.mandatoryChallengeCount,
				mandatoryUserChallengeCount:
					r1.mandatoryUserChallengeCount + r2.mandatoryUserChallengeCount,
				mandatoryFullPointSolutionCount:
					r1.mandatoryFullPointSolutionCount +
					r2.mandatoryFullPointSolutionCount
			}),
			{
				teamCount: 0,
				userCount: 0,
				teacherCount: 0,
				mandatoryChallengeCount: 0,
				mandatoryUserChallengeCount: 0,
				mandatoryFullPointSolutionCount: 0
			}
		) : {
			classId: 0,
			className: "No Classes"
		};

	return {
		classId,
		classReport: classId ? reportClasses.data.find(classReport => classReport.classId === classId) : allClassesReport,
		classReportOptions: [
			allClassesReport,
			...reportClasses.data
		],
		teamReports: classId ? reportTeams.data.filter(t => t.classId === classId) || [] : reportTeams.data
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchClasses: reportApi.fetchClasses,
			fetchAllTeams: reportApi.fetchAllTeams
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(reportStyle),
	withIntl
)(ClassReport);

let ClassReportBreadCrumb = ({ classReport }) => (
	<span>{classReport ? classReport.className : "All Classes"}</span>
);

const mapStateToPropsBreadCrumbs = (state, ownProps) => {
	const classId = Number(ownProps.match.params.classId);
	return { classReport: state.api.resources.reportClasses.data.find(classReport => classReport.classId === classId) };
};

ClassReportBreadCrumb = connect(mapStateToPropsBreadCrumbs)(
	ClassReportBreadCrumb
);
export { ClassReportBreadCrumb };

import { HackingLabRole, HackingLabToken } from "@hlcr/app/model/HackingLabRole";
import { AUTHENTICATION_COOKIE_NAME, handleAuthError } from "@hlcr/ui/auth/keycloak";
import jwtDecode from "jwt-decode";

export const checkHasRole = (requiresRole?: HackingLabRole | HackingLabRole[], token = getToken()): boolean => {
	if (!requiresRole) {
		return true;
	}

	if (!token?.resource_access?.ccs?.roles || !Array.isArray(token?.resource_access?.ccs?.roles)) {
		return false;
	}

	if (!Array.isArray(requiresRole)) {
		return token.resource_access.ccs.roles.some(role => role === requiresRole);
	} else {
		return token.resource_access.ccs.roles.some(role => requiresRole.some(requiredRole => requiredRole === role));
	}
};

export const checkHasAnyRole = (requiresAnyRole?: HackingLabRole[], token = getToken()): boolean => {
	if (!requiresAnyRole) {
		return true;
	}

	return requiresAnyRole.some(role => checkHasRole(role));
};

export const getEmail = () => {
	const token = getTokenNoFail();
	return token && token.email ? token.email : "unknown";
};

export const getCurrentUsername = () => {
	const token = getTokenNoFail();
	return token && token.preferred_username
		? token.preferred_username
		: "unknown";
};

export const getToken = () => {
	const tokenEncoded = getCookieValue(AUTHENTICATION_COOKIE_NAME);
	try {
		return jwtDecode<HackingLabToken>(tokenEncoded);
	} catch (e) {
		console.error("getToken_JWT Decode Error");
		handleAuthError();
	}
};

export const getTokenNoFail = () => {
	const tokenEncoded = getCookieValue(AUTHENTICATION_COOKIE_NAME);
	try {
		return jwtDecode<HackingLabToken>(tokenEncoded);
	} catch (e) {
		return undefined;
	}
};

export function getCookieValue(a: string) {
	const b = document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`);
	return b ? b.pop() || "" : "";
}

export function doLogout() {
	if (window.keycloak && window.keycloak.logout) {
		window.history.pushState({}, "", "/");
		window.keycloak.logout({ redirectUri: window.location.origin });
	}
	document.cookie = AUTHENTICATION_COOKIE_NAME + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
}

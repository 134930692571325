import { MultiSelectWithSelectAll } from "@hlcr/mui";
import { InputRequirementType } from "@hlcr/mui/Input/InputRequirementType";
import { IntlWithFm } from "@hlcr/ui/Intl";
import { Button } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import React from "react";

import styles from "grading/bulkActionModal.module.scss";
import { EventParticipant, OnChangeHandler } from "grading/BulkActionModalTypes";

export const BulkActionModalHeader = (
	{
		intl,
		eventParticipantTeams,
		selectedTeamIds,
		onParticipantTeamChange,
		eventParticipantUsers,
		selectedUserIds,
		onParticipantUserChange,
		exportEvent,
	}:
	{
		intl: IntlWithFm,
		eventParticipantTeams: EventParticipant[],
		selectedTeamIds: number[],
		onParticipantTeamChange: OnChangeHandler,
		eventParticipantUsers: EventParticipant[],
		selectedUserIds: number[],
		onParticipantUserChange: OnChangeHandler,
		exportEvent: () => void },
) => (
	<div className={styles.selectContainer}>
		<MultiSelectWithSelectAll
			label={intl.fm("teacher.grading.bulk.modal.select.teams")}
			data={eventParticipantTeams}
			required={InputRequirementType.REQUIRED}
			inputProps={{ value: selectedTeamIds, onChange: onParticipantTeamChange }}
		/>
		<span className={styles.spacer}></span>
		<MultiSelectWithSelectAll
			label={intl.fm("teacher.grading.bulk.modal.select.users")}
			data={eventParticipantUsers}
			required={InputRequirementType.REQUIRED}
			inputProps={{ value: selectedUserIds, onChange: onParticipantUserChange }}
		/>
		<Button className={styles.solutionDownloadButton}
		        variant="outlined"
		        color="secondary"
		        endIcon={<GetApp />}
		        onClick={exportEvent}>
			{intl.fm("teacher.table.solutions")}
		</Button>
	</div>
);

import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import eventsApi from "actions/events";
import { Button } from "@hlcr/mui/Button";
import WarningDialog from "components/ModalWindow/WarningDialog";
import Timer from "components/Timer/Timer";
import {
	checkDatePassed,
	checkDatePendent,
	getDateDiff,
	printDuration
} from "helper/dateCalc";
import { withIntl } from "@hlcr/ui/Intl";

const styles = {
	red: { color: "red" },
	endButton: { margin: 0 },
	text: { fontSize: "1rem" }
};

class ExamInfo extends React.Component {
	state = { finishWarningOpen: false };

	cancelFinish = () => {
		this.setState({ finishWarningOpen: false });
	};

	confirmFinish = () => {
		this.setState({ finishWarningOpen: true });
	};

	finish = () => {
		const { event, finishExamen } = this.props;
		finishExamen(event.id);
		this.setState({ finishWarningOpen: false });
	};
	componentDidMount() {
		const { event } = this.props;
		if (event && event.timeLimitedEndDate) {
			const endMoment = moment(event.timeLimitedEndDate);
			const diff = endMoment.diff(moment());
			if (diff > 0) {
				this.timeout = setTimeout(
					() => this.setState({ time: Date.now() }),
					diff + 1000
				);
			}
		}
	}
	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	examRunning = () => {
		const { event, showButton, intl, classes } = this.props;
		const { finishWarningOpen } = this.state;
		return (
			<div className={classes.text}>
				{showButton && (
					<>
						<Button
							onClick={this.confirmFinish}
							color="dangerNoBackground"
							customClass={classes.endButton}
						>
							{intl.fm("event.exam.finish")}
						</Button>
						<WarningDialog
							onConfirm={this.finish}
							onClose={this.cancelFinish}
							open={finishWarningOpen}
							title={intl.fm("event.exam.finish")}
							message={intl.fm("event.exam.finishWarning")}
						/>
					</>
				)}
				{intl.fm("event.exam.running")}{" "}
				<span className={classes.red}>
					<Timer endDate={event.timeLimitedEndDate} maxDurationEntries={2} />
				</span>
			</div>
		);
	};
	examFinished = () => {
		const { event, intl } = this.props;
		const duration = getDateDiff(
			event.timeLimitedStartDate,
			event.timeLimitedEndDate
		);
		return (
			<>
				{intl.fm("event.exam.ended")}
				{duration && `. ${intl.fm("event.exam.timeTaken")}: `}
				{duration && printDuration(duration, intl, 2)}
			</>
		);
	};

	render() {
		const { event, isPreview, intl } = this.props;

		const isExamAtAll = event && event.exam;
		const isExamWithTimeLimit = isExamAtAll && !!event.timeLimitInSeconds;
		const isRunningExam =
			!isPreview && isExamAtAll && !!event.timeLimitedEndDate;

		if (isRunningExam)
			return (
				<>
					{checkDatePassed(event.timeLimitedEndDate) && this.examFinished()}
					{checkDatePendent(event.timeLimitedEndDate) && this.examRunning()}
				</>
			);
		if (isExamWithTimeLimit)
			return `${intl.fm("event.exam.title")}: ${printDuration(
				event.timeLimitInSeconds,
				intl
			)}`;
		if (isExamAtAll)
			return `${intl.fm("event.exam.title")}: ${intl.fm(
				"event.exam.noTimeLimit"
			)}`;
		else return null;
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators({ finishExamen: eventsApi.finishExamen }, dispatch);

export default compose(
	connect(
		undefined,
		mapDispatchToProps
	),
	withStyles(styles),
	withIntl
)(ExamInfo);

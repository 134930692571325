import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import React from "react";

import IconCard from "components/Cards/IconCard";
import Table from "components/Table/Table";

import { formatDateOnly } from "helper/dateCalc";
import { withIntl } from "@hlcr/ui/Intl";

const InfoSection = ({ footer, profile, intl }) => {
	const tableRenderer = [
		{ id: "title", renderCell: row => row.title },
		{ id: "value", renderCell: row => row.value }
	];

	const tableData = [
		{
			title: intl.fm("user.label.username"),
			value: profile.username
		},
		{
			title: intl.fm("user.label.email"),
			value: profile.email
		},
		{
			title: intl.fm("user.label.gender"),
			value: profile.gender
		},
		{
			title: intl.fm("user.label.birthday"),
			value: formatDateOnly(profile.birthday)
		},
		{
			title: intl.fm("user.label.nationality"),
			value: profile.nationality
		},
		{
			title: intl.fm("user.label.organization"),
			value: profile.organization
		},
		{
			title: intl.fm("user.label.skills"),
			value: profile.skills
		}
	];

	return (
		<IconCard
			title={intl.fm("user.profile.info")}
			icon={AccountCircleIcon}
			content={
				<Table tableRenderer={tableRenderer} tableData={tableData} hover />
			}
			footer={footer}
		/>
	);
};
export default withIntl(InfoSection);

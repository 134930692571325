import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import rankingApi from "actions/ranking";
import rankingStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/rankingStyle";
import IconCard from "components/Cards/IconCard.jsx";
import EventRankingChart from "components/Chart/EventRankingChart";
import { Button } from "@hlcr/mui/Button";
import NoData from "components/NoData/NoData";
import EventRankingTable from "components/Table/EventRankingTable";
import { withIntl } from "@hlcr/ui/Intl";
import { EventSelect } from "components/CustomSelect/EventSelect";

class EventRankingCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = { showChart: true };
	}

	render() {
		const {
			events,
			eventUnits,
			selectedEvent,
			useEvent,
			rankingData,
			isTeam,
			handler,
			icon,
			color,
			intl,
			classes
		} = this.props;
		const { showChart } = this.state;

		return (
			<IconCard
				icon={icon}
				iconColor={color}
				title={
					<div>
						{isTeam
							? intl.fm("ranking.teams.title")
							: intl.fm("ranking.users.title")}
						{!useEvent && Array.isArray(events) && events.length > 0 && selectedEvent && (
							<EventSelect
								selectedEvent={selectedEvent}
								events={events}
								onEventChange={handler}
								title={intl.fm("ranking.event")}
								label={intl.fm("ranking.event")}
								required={true}
							/>
						)}
						<div className={classes.floatField}>
							{rankingData.length > 0 && (
								<Button
									customClass={classes.enterButton}
									color="infoNoBackground"
									size="sm"
									onClick={() => {
										this.setState({ showChart: !showChart });
									}}
								>
									{!showChart
										? intl.fm("ranking.chart.show")
										: intl.fm("ranking.chart.hide")}
								</Button>
							)}
						</div>
					</div>
				}
				content={
					selectedEvent && rankingData.length > 0 ? (
						<div>
							{showChart && (
								<EventRankingChart
									isTeam={isTeam}
									selectedEvent={selectedEvent}
									chartData={rankingData}
									{...this.props}
								/>
							)}
							<EventRankingTable
								isTeam={isTeam}
								selectedEvent={selectedEvent}
								solvableEventUnits={eventUnits?.filter(u => u.level)}
								tableData={rankingData}
								{...this.props}
							/>
						</div>
					) : (
						<div>
							<NoData text={intl.fm("ranking.noData")} />
						</div>
					)
				}
			/>
		);
	}
}

EventRankingCard.propTypes = {
	isDataAvailable: PropTypes.bool,
	isTeam: PropTypes.bool,
	icon: PropTypes.func.isRequired,
	color: PropTypes.string.isRequired,
	useEvent: PropTypes.object
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchEventUsersRanking: rankingApi.fetchEventUsersRanking,
			fetchEventTeamsRanking: rankingApi.fetchEventTeamsRanking,
		},
		dispatch
	);

export default compose(
	connect(
		undefined,
		mapDispatchToProps
	),
	withIntl,
	withStyles(rankingStyle)
)(EventRankingCard);

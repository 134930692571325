export const reportInitState = {
	reportClasses: {
		pending: false,
		data: []
	},
	reportTeams: {
		pending: false,
		data: []
	},
	reportUsers: {
		pending: false,
		data: []
	},
	reportChallenges: {
		pending: false,
		data: []
	},
	reportQuizzes: {
		pending: false,
		data: []
	},
	reportTheories: {
		pending: false,
		data: []
	},
	reportSolutions: {
		pending: false,
		data: []
	},
	reportGradingInstruction: {
		pending: false,
		data: []
	},
	reportSolutionsComments: {
		pending: false,
		data: []
	},
	reportQuizSolutions: {
		pending: false,
		data: []
	},
	reportQuizAnswers: {
		pending: false,
		data: []
	},
	reportQuizGradingInstructions: {
		pending: false,
		data: []
	},
	reportQuizComments: {
		pending: false,
		data: []
	},
	reportSolutionComments: {
		pending: false,
		data: []
	}
};

import Keycloak from "keycloak-js";
import moment, { unix } from "moment";

declare global {
	interface Window {
		keycloak: any;
		willRedirect: boolean;
	}
}

export const AUTHENTICATION_COOKIE_NAME = "Authentication";

export const init = (onSuccess: { (): void; (): void; }, fakeCookie?: string) => {
	const keycloak = Keycloak("/config/keycloak.json");
	window.keycloak = keycloak;

	if (fakeCookie) {
		document.cookie = `${AUTHENTICATION_COOKIE_NAME}=${fakeCookie};Path=/;`;

		keycloak.init({ checkLoginIframe: false });
		onSuccess();
		return;
	}

	keycloak
		.init({ onLoad: "login-required", checkLoginIframe: false })
		.then(authenticated => {
			if (!authenticated) {
				handleAuthError();
				return;
			}

			setCookie();
			scheduleNextRefresh();
			onSuccess();
		})
		.catch((e) => handleAuthError());

	const setCookie = () => {
		document.cookie = `${AUTHENTICATION_COOKIE_NAME}=${keycloak.token};Path=/;`;
	};

	const scheduleNextRefresh = () => {
		const expiration = keycloak.tokenParsed?.exp;

		if (expiration === undefined) {
			handleAuthError();
			return;
		}

		const expirationDate = unix(expiration);
		let secondsUntilInvalid = expirationDate.diff(moment(), "seconds");
		secondsUntilInvalid = Math.max(5, secondsUntilInvalid);

		window.setTimeout(() => {
			keycloak
				.updateToken(secondsUntilInvalid)
				.then(() => {
					setCookie();
					scheduleNextRefresh();
				})
				.catch(() => handleAuthError());
		}, (secondsUntilInvalid - 30) * 1000);
	};
};

export const handleAuthError = () => {
	if (window.willRedirect) return;
	window.willRedirect = true;
	document.cookie = `${AUTHENTICATION_COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;Path=/;`;
	doLogout();
};

export function doLogout() {
	if (window.keycloak && window.keycloak.logout) {
		window.history.pushState({}, "", "/");
		window.keycloak.logout({ redirectUri: window.location.origin });
	}
	document.cookie = AUTHENTICATION_COOKIE_NAME + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
}

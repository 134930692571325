import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RestoreIcon from "@material-ui/icons/Restore";
import ViewIcon from "@material-ui/icons/Visibility";
import cx from "classnames";
import * as React from "react";

import AddExistingEventModal from "components/AddExistingEventModal/AddExistingEventModal";
import { Button } from "@hlcr/mui/Button";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import WarningDialog from "components/ModalWindow/WarningDialog";
import TenaciousWarning, { TenaciousAction } from "components/ModalWindow/TenaciousWarning";
import NewEventModal from "components/NewEventModal/NewEventModal";
import { formatFullDate } from "helper/dateCalc";
import { EventType } from "models/EventType";

import ManagerTableCard, { ShowHelp } from "./ManagerTableCard";

const ManagerSubEvents = ({
	curriculumEvents, archived, archivedCurriculumEvents, event, eventId, intl, classes, moveCurriculumEvent, updateManagerCurriculumEvent, deleteManagerCurriculumEvent,
	fetchManagerCurriculumEvents, history, restoreManagerEvent
}) => {
	const [ pendingIds, setPendingIds ] = React.useState([]);
	const [ deleteEntity, setDeleteEntity ] = React.useState(undefined);
	const [ isNewEventModalOpen, setNewEventModalOpen ] = React.useState(false);
	const [ isExistingEventModalOpen, setExistingEventModalOpen ] = React.useState(false);
	const [ restoreEntity, setRestoreEntity ] = React.useState(undefined);

	const move = (curriculumEvent, direction) => {
		const curriculumEventId = curriculumEvent.id;
		setPendingEvent(curriculumEventId);
		moveCurriculumEvent(eventId, curriculumEventId, direction, () =>
			resetPendingEvent(curriculumEventId)
		);
	};

	const moveDown = curriculumEvent => move(curriculumEvent, 1);

	const moveUp = curriculumEvent => move(curriculumEvent, -1);

	const setPendingEvent = id => {
		setPendingIds([ ...pendingIds, id ]);
	};

	const resetPendingEvent = id => {
		setPendingIds(pendingIds.filter(pendingId => pendingId !== id));
	};

	const disabledUnit = event => pendingIds.includes(event.id);

	const handleDeletion = event => {
		setDeleteEntity(undefined);
		setPendingEvent(event.id);
		deleteManagerCurriculumEvent(event, () => {
			resetPendingEvent(event.id);
			fetchManagerCurriculumEvents(event.parent);
		});
	};

	const handleRestore = event => () => {
		setRestoreEntity(undefined);
		setPendingEvent(event.id);
		restoreManagerEvent(event, () => {
			resetPendingEvent(event.id);
			fetchManagerCurriculumEvents(event.parent);
		});
	};

	const editCurriculumEvent = curriculumEvent => {
		history.push(`/manager/events/${event.id}/curriculumevents/${curriculumEvent.id}`);
	};

	const switchCurriculumEventPublished = curriculumEvent => {
		updateManagerCurriculumEvent(
			{
				...curriculumEvent,
				published: !curriculumEvent.published
			},
			undefined,
			() => setPendingEvent(curriculumEvent.id),
			() => resetPendingEvent(curriculumEvent.id),
			false
		);
	};

	const onEventAddedSuccess = () => {
		setNewEventModalOpen(false);
		setExistingEventModalOpen(false);
		fetchManagerCurriculumEvents(eventId);
	};

	const tableRenderer = (curriculumEvents, intl, classes) => {
		const sortOrders = curriculumEvents.map(event => event.sortOrder);
		const minSortOrder = Math.min(...sortOrders);
		const maxSortOrder = Math.max(...sortOrders);
		return [
			{
				id: "name",
				title: intl.fm("manager.eventDetails.curriculumEvents.table.name"),
				renderCell: curriculumEvent => <span>{curriculumEvent.name + (curriculumEvent.archived ? ` (${intl.fm("common.labels.archived")})` : "")}</span>
			},
			{
				id: "startTime",
				title: intl.fm("manager.eventDetails.curriculumEvents.table.startTime"),
				renderCell: curriculumEvent => (
					<span>{formatFullDate(curriculumEvent.startTime)}</span>
				)
			},
			{
				id: "endTime",
				title: intl.fm("manager.eventDetails.curriculumEvents.table.endTime"),
				renderCell: curriculumEvent => (
					<span>{formatFullDate(curriculumEvent.endTime)}</span>
				)
			},
			{
				id: "published",
				title: intl.fm("manager.eventDetails.units.table.published"),
				renderCell: curriculumEvent => (
					<CustomSwitch
						checked={curriculumEvent.published}
						disabled={disabledUnit(curriculumEvent)}
						onChange={() => !curriculumEvent.archived && switchCurriculumEventPublished(curriculumEvent)}
					/>
				)
			},
			{
				id: "actions",
				title: intl.fm("manager.eventDetails.units.table.actions"),
				renderCell: curriculumEvent => (
					<div>
						{!curriculumEvent.archived && curriculumEvent.sortOrder !== minSortOrder ? (
							<Button
								color="info"
								customClass={classes.editButton}
								disabled={disabledUnit(curriculumEvent)}
								onClick={() => moveUp(curriculumEvent)}
							>
								<ArrowUpwardIcon className={classes.icon} />
							</Button>
						) : (
							<div className={classes.sortOrderSpacing} />
						)}
						{!curriculumEvent.archived && curriculumEvent.sortOrder !== maxSortOrder && (
							<Button
								color="info"
								customClass={classes.editButton}
								disabled={disabledUnit(curriculumEvent)}
								onClick={() => moveDown(curriculumEvent)}
							>
								<ArrowDownwardIcon className={classes.icon} />
							</Button>
						)}
						{!curriculumEvent.archived && <Button
							color="danger"
							customClass={cx(classes.editButton, classes.floatRight)}
							disabled={disabledUnit(curriculumEvent)}
							onClick={() => setDeleteEntity(curriculumEvent)}
						>
							<DeleteIcon className={classes.icon} />
						</Button>}
						{!curriculumEvent.archived && <Button
							color="info"
							customClass={cx(classes.editButton, classes.floatRight)}
							disabled={disabledUnit(curriculumEvent)}
							onClick={() => editCurriculumEvent(curriculumEvent)}
						>
							<EditIcon className={classes.icon} />
						</Button>}
						{curriculumEvent.archived && <Button
							color="info"
							customClass={cx(classes.editButton, classes.floatRight)}
							disabled={disabledUnit(curriculumEvent)}
							onClick={() => setRestoreEntity(curriculumEvent)}
						>
							<RestoreIcon className={classes.icon} />
						</Button>}
						{curriculumEvent.archived && <Button
							color="info"
							customClass={cx(classes.editButton, classes.floatRight)}
							disabled={disabledUnit(curriculumEvent)}
							onClick={() => editCurriculumEvent(curriculumEvent)}
						>
							<ViewIcon className={classes.icon} />
						</Button>}
					</div>
				)
			}
		];
	};

	return (
		<>
			<ManagerTableCard
				tableData={archived ? archivedCurriculumEvents : curriculumEvents}
				title={
					<div className={classes.titleBox}>
						<span>
							{intl.fm(archived ? "manager.eventDetails.curriculumEvents.archived.title" : "manager.eventDetails.curriculumEvents.title")}
							<ShowHelp
								userRole="manager"
								action="manageCurriculumEvents"
								className={classes.helpIcon}
							/>
						</span>
						{!archived && <div className={classes.titleBox}>
							<Button
								size="sm"
								color="infoNoBackground"
								customClass={classes.subEventButton}
								onClick={() => setNewEventModalOpen(true)}
							>
								<AddIcon fontSize="small" />
								<span className={classes.subEventButtonText}>New</span>
							</Button>
							<Button
								size="sm"
								color="infoNoBackground"
								customClass={classes.subEventButton}
								onClick={() => setExistingEventModalOpen(true)}
							>
								<AddIcon fontSize="small" />
								<span className={classes.subEventButtonText}>Existing</span>
							</Button>
							<TenaciousWarning
								action={TenaciousAction.DELETE}
								onConfirm={handleDeletion}
								onClose={() => setDeleteEntity(undefined)}
								entity={deleteEntity}
								entityIdentifier={entity => `${entity.name}`}
								messageLocalizationId="tenacious.warning.message.event.delete"
							/>
						</div>}
						{archived && <WarningDialog
							title={intl.fm("common.restoreConfirm.title")}
							message={intl.fm("common.restoreConfirm.message", null, { entityName: restoreEntity ? restoreEntity.name : "" })}
							onConfirm={handleRestore(restoreEntity)}
							onClose={() => setRestoreEntity(undefined)}
							entity={restoreEntity}
							entityName={intl.fm("event.entityName")}
						/>}
					</div>
				}
				disabledRow={event => event.archived}
				specialHighlightingStyles={true}
				tableRenderer={tableRenderer(archived ? archivedCurriculumEvents : curriculumEvents, intl, classes)}
				cardIcon={AssignmentIcon}
				color="purple"
			/>
			{!archived && <NewEventModal
				eventType={EventType.CURRICULUM_EVENT}
				parentId={eventId}
				isOpen={isNewEventModalOpen}
				handleClose={() => setNewEventModalOpen(false)}
				onSuccess={onEventAddedSuccess}
			/>}
			{!archived && <AddExistingEventModal
				isOpen={isExistingEventModalOpen}
				handleClose={() => setExistingEventModalOpen(false)}
				onAdded={onEventAddedSuccess}
			/>}
		</>
	);
};
export default ManagerSubEvents;

import { containerFluid, CSS_HOVER_FOCUS_PSEUDO_CLASSES, dangerColor, defaultBoxShadow, defaultFont, infoColor, primaryColor, successColor, warningColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";

const headerStyle = {
	appBar: {
		backgroundColor: "transparent",
		boxShadow: "none",
		borderBottom: "0",
		marginBottom: "0",
		position: "absolute",
		width: "100%",
		paddingTop: "10px",
		zIndex: "1029",
		color: "#555555",
		border: "0",
		borderRadius: "3px",
		padding: "10px 0",
		transition: "all 150ms ease 0s",
		minHeight: "50px",
		display: "block",
		"@media (max-width: 600px)": { padding: 0 },
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 }
	},
	container: {
		...containerFluid,
		minHeight: "50px",
		"@media (max-width: 600px)": { padding: 0 }
	},
	breadcrumbsContainer: {
		flex: 1,
		display: "flex",
		overflow: "hidden"
	},
	title: {
		...defaultFont,
		lineHeight: "30px",
		fontSize: "18px",
		borderRadius: "3px",
		textTransform: "none",
		color: "inherit",
		[CSS_HOVER_FOCUS_PSEUDO_CLASSES]: { background: "transparent" }
	},
	breadcrumbs: {
		...defaultFont,
		lineHeight: "30px",
		fontSize: "18px",
		padding: "8px 16px 8px 8px",
		textTransform: "none",
		color: "inherit",
		"@media (max-width: 600px)": {
			fontSize: "1.2em",
			padding: 0
		},
		"@media (max-width: 500px)": { fontSize: "1.1em" },
		"@media (max-width: 450px)": { fontSize: ".9em" }
	},
	breadcrumbLink: {
		color: "inherit",
		[CSS_HOVER_FOCUS_PSEUDO_CLASSES]: { color: primaryColor }
	},
	primary: {
		backgroundColor: primaryColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	info: {
		backgroundColor: infoColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	success: {
		backgroundColor: successColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	warning: {
		backgroundColor: warningColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	danger: {
		backgroundColor: dangerColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	sidebarMinimize: {
		float: "left",
		padding: "0 0 0 15px",
		display: "block",
		color: "#555555"
	},
	sidebarMinimizeRTL: { padding: "0 15px 0 0 !important" },
	sidebarMiniIcon: {
		width: "20px",
		height: "17px"
	}
};

export default headerStyle;

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "@hlcr/mui/Button";
import Tooltip from "components/CustomTooltip/CustomTooltip";
import { TWEET_HASHTAGS, TWEET_URL, TWEET_VIA } from "variables/sharing";

const TweetIntent = ({ text, classes }) => {
	const escapedText = text.replace(" ", "%20");

	return (
		<Tooltip title="Share" placement="top">
			<a
				href={`https://twitter.com/intent/tweet?text=${escapedText}%20${TWEET_VIA}&hashtags=${TWEET_HASHTAGS}&url=${TWEET_URL}`}
				target="_blank"
				rel="noopener noreferrer"
			>
				<Button
					justIcon
					color="infoNoBackground"
					customClass={classes.socialShareButton}
				>
					<i className="fas fa-share-alt" />
				</Button>
			</a>
		</Tooltip>
	);
};

const style = {
	socialShareButton: {
		margin: 0,
		padding: 5
	}
};

TweetIntent.propTypes = {
	text: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired
};

export default withStyles(style)(TweetIntent);

import { grayColor, roseColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";

const eventUnitsStyle = {
	right: { textAlign: "right" },
	center: { textAlign: "center" },
	actionButton: {
		margin: "0 0 0 5px",
		padding: "5px"
	},
	icon: {
		verticalAlign: "middle",
		width: "17px",
		height: "17px",
		top: "-1px",
		position: "relative"
	},
	thumbPaper: {
		display: "inline-block",
		borderRadius: 3
	},
	thumb: {
		height: 72,
		width: 110,
		objectFit: "cover",
		borderRadius: 3,
	},
	blurred: {
		filter: "blur(10px)",
		transition: "filter 0.3s",
	},
	categoryImage: {
		width: 36,
		height: 36,
		marginRight: 6
	},
	categoryText: { marginRight: 6 },
	tooltip: {
		fontSize: "1.2em",
		letterSpacing: ".05em"
	},
	typeIcon: { color: grayColor },
	tableIconContainer: { display: "inline-flex" },
	sortOrder: {
		fontWeight: 600,
		opacity: 0.4,
		fontSize: "1.2em"
	},
	useFlagIcon: { marginRight: 6 },
	panelWithInfo: { display: "flex" },
	mainTable: {
		flexGrow: 50,
		alignSelf: "stretch"
	},
	titleCardContainer: {
		display: "flex",
		justifyContent: "space-between",
		flexWrap: "wrap"
	},
	cardActions: { display: "flex" },
	showSolvedLabel: {
		display: "flex",
		alignItems: "center",
		fontSize: 12,
		cursor: "pointer"
	},
	filterField: {
		marginTop: -15,
		marginRight: -15
	},
	filterButton: {
		margin: 0,
		paddingLeft: 0
	},
	filterButtonIcon: {
		verticalAlign: "middle",
		top: "-1px",
		position: "relative",
	},
	uuid: {
		fontSize: "11px",
		color: "grey",
	},
	startEndTime: {
		fontSize: "11px",
		color: "black",
	},
	eventTitle: {
		fontSize: "1.2em",
		color: "inherit",
	},
	solvingProgress: {
		flexDirection: "column",
		display: "flex",
		alignItems: "center",
	},
	solvingProgressBar: {
		marginTop: "10px",
		width: "100%",
	},
	solvingProgressBarBuffer: {
		backgroundColor: "lightgrey",
	},
	solvingProgressBarDashed: {
		backgroundImage: "none",
	},
	horizontal: {
		display: "flex",
		alignItems: "center",
	},
	expiredLabel: {
		display: "inline-block",
		fontSize: ".6em",
		textTransform: "uppercase",
		borderRadius: 3,
		color: "#fff",
		background: roseColor,
		padding: "1px 10px",
		marginRight: "10px",
	},
	unitPoints: {
		fontSize: "0.9em",
		opacity: 0.8
	}
};

export default eventUnitsStyle;

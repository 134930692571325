import { Accordion } from "@hlcr/mui";
import { useIntl } from "@hlcr/ui/Intl";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { formatFullLongDate } from "helper/dateCalc";

export interface UserFlag {
	timestamp: string,
	flag: string
}

export const UserFlagsBox = ({ flags }: { flags: UserFlag[] }) => {
	const intl = useIntl();
	const classes = useStyles();

	return flags && flags.length > 0 ? (
		<Accordion sections={[
			{
				title: intl.fm("solution.gradinginstruction.userFlag"),
				content: (<List>
					{flags.map((flag, index) =>
						<ListItem key={index} className={classes.listItem}>
							<ListItemText
								classes={{
									primary: classes.listItemTextPrimary,
									secondary: classes.listItemTextSecondary,
								}}
								primary={flag.flag}
								secondary={formatFullLongDate(flag.timestamp, intl.fm("common.labels.unknown"))} />
						</ListItem>,
					)}
				</List>),
			},
		]} />
	) : <></>;
};


const useStyles = makeStyles({
	flagBox: { width: "100%" },
	listItem: { padding: 0 },
	listItemTextPrimary: { fontSize: "1em", fontWeight: 500 },
	listItemTextSecondary: { fontSize: "12px" },
});

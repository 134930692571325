import { MessageType } from "models/Message";
import { ActionType } from "actions/ActionType";

export const saveApiResult = (
	resource,
	data,
	updatePropForArray,
	updatePropForObj,
	updateField,
	setFieldOnObject
) => ({
	type: ActionType.API_RESULT,
	resource,
	data,
	updatePropForArray,
	updatePropForObj,
	updateField,
	setFieldOnObject
});

export const removeApiSuccess = (resource, entity, updatePropForObj) => ({
	type: ActionType.API_REMOVE,
	resource,
	entity,
	updatePropForObj
});

export const addNotification = (
	id,
	message,
	messageType = MessageType.ERROR
) => ({
	type: ActionType.ADD_NOTIFICATION,
	id,
	message,
	messageType
});

export const removeNotification = id => ({
	type: ActionType.REMOVE_NOTIFICATION,
	id
});

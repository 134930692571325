import { ProcessedFile } from "@hlcr/mui/Upload";
import { useIntl } from "@hlcr/ui/Intl";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import ModalWindow from "components/ModalWindow/ModalWindow";
import { exportEvent, onParticipantChange, submitModal } from "grading/bulkActionModal.functions";
import { useEventParticipantTeams, useEventParticipantUsers, useSelectedTeamIds, useSelectedUserIds } from "grading/bulkActionModal.hooks";
import { BulkActionModalButtonBar } from "grading/BulkActionModalButtonBar";
import { BulkActionModalHeader } from "grading/BulkActionModalHeader";
import { EventParticipantWitSolutionState } from "grading/BulkActionModalTypes";
import { WriteUpForm } from "grading/WriteUpForm/WriteUpForm";
import { TeacherEventUnit } from "shared/event/model/TeacherEventUnit";

export const BulkFeedbackModal = (
	{ unit, onClose }:
	{ unit: TeacherEventUnit, onClose: () => void }) => {
	const eventParticipantUsers: EventParticipantWitSolutionState[] = useEventParticipantUsers(unit);
	const eventParticipantTeams: EventParticipantWitSolutionState[] = useEventParticipantTeams(unit);

	return	<WrappedBulkFeedbackModal
		unit={unit}
		onClose={onClose}
		eventParticipantTeams={eventParticipantTeams}
		eventParticipantUsers={eventParticipantUsers} />;
};

export const WrappedBulkFeedbackModal = (
	{
		unit,
		onClose,
		eventParticipantUsers,
		eventParticipantTeams,
	}:
	{
		unit: TeacherEventUnit,
		onClose: () => void,
		eventParticipantUsers: EventParticipantWitSolutionState[],
		eventParticipantTeams: EventParticipantWitSolutionState[],
	}) => {

	const intl = useIntl();
	const dispatch = useDispatch();

	const [ submitting, setSubmitting ] = useState(false);
	const [ selectedUserIds, setSelectedUserIds ] = useSelectedUserIds(eventParticipantUsers);
	const [ selectedTeamIds, setSelectedTeamIds ] = useSelectedTeamIds(eventParticipantTeams);

	const [ writeUpText, setWriteUpText ] = useState<string>("");
	const [ writeUpFile, setWriteUpFile ] = useState<ProcessedFile>();

	const clearModal = () => {
		setSelectedUserIds([]);
		setSelectedTeamIds([]);
		setWriteUpText("");
		setWriteUpFile(undefined);
	};

	const handleSubmit = submitModal({
		comment: writeUpText,
		grade: undefined,
		userIds: selectedUserIds,
		teamIds: selectedTeamIds,
		attachment: writeUpFile ? {
			name: writeUpFile?.name,
			content: writeUpFile?.data,
		} : undefined,
	}, setSubmitting, dispatch, unit, onClose);

	return (
		<ModalWindow
			title={intl.fm("teacher.feedback.bulk.modal.title", undefined, { unit: unit?.title })}
			open={true}
			onClose={onClose}
			classes={{}}
			fullWidth
			maxWidth="md"
			actionSection={(
				<BulkActionModalButtonBar
					intl={intl}
					clearModal={clearModal}
					selectedUserIds={selectedUserIds}
					selectedTeamIds={selectedTeamIds}
					submitModal={handleSubmit}
					isSubmitting={submitting} />
			)}
		>
			<BulkActionModalHeader
				intl={intl}
				eventParticipantTeams={eventParticipantTeams.map(p => ({ id: p.id, name: `${p.name}  (${p.solutionState}) points: ${p.points}` }))}
				selectedTeamIds={selectedTeamIds}
				onParticipantTeamChange={onParticipantChange(setSelectedTeamIds)}
				eventParticipantUsers={eventParticipantUsers.map(p => ({ id: p.id, name: `${p.name} (${p.solutionState}) points: ${p.points}` }))}
				selectedUserIds={selectedUserIds}
				onParticipantUserChange={onParticipantChange(setSelectedUserIds)}
				exportEvent={exportEvent(unit)} />
			<WriteUpForm
				isTeacher={true}
				isSubmitting={submitting}
				writeUpText={writeUpText}
				writeUpFile={writeUpFile}
				onWriteUpChange={(text: string) => setWriteUpText(text)}
				onFileChange={(file?: ProcessedFile) => setWriteUpFile(file)}
				hasError={false} />
		</ModalWindow>
	);
};

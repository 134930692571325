import { StyledMarkdown } from "@hlcr/ui";
import { useIntl } from "@hlcr/ui/Intl";
import { createStyles, makeStyles } from "@material-ui/core";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import teacherApi from "actions/teacher";
import CustomInput from "components/CustomInput/CustomInput";
import ValidationInput from "components/CustomInput/ValidationInput";
import { checkShortAnswer, getInputValidationState } from "helper/quiz";
import { QuestionAnswer, QuestionType } from "models/Quizzes";
import { RootState } from "reducers";

import { AnswerBaseProps } from "./AnswerBaseProps";
import AnswerGrader from "./AnswerGrader";

interface LongAnswerQuestionProps extends AnswerBaseProps {
}

export const LongAnswerQuestion = ({ question, disabled, gradingInstruction, isPreview, onChange, answer, questionState }: LongAnswerQuestionProps) => {
	const intl = useIntl();
	const classes = useStyles();
	const dispatch = useDispatch();

	const darkMode = useSelector((state: RootState) => state.ui.darkMode);

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		const updatedAnswer: QuestionAnswer = {
			...answer,
			type: QuestionType.LONG_ANSWER,
			questionUuid: question.id,
			content: value,
		};

		onChange(updatedAnswer);
	};

	const gradeLongAnswer = (correct: boolean) => {
		if (answer?.id !== undefined && gradingInstruction?.quizSolutionId !== undefined) {
			dispatch(teacherApi.gradeQuizAnswer(answer.id, gradingInstruction.quizSolutionId, correct));
		}
	};

	if (gradingInstruction) {
		return (
			<div>
				{!isPreview && <ValidationInput
					disabled={disabled}
					label={intl.fm("quiz.longAnswer.label")}
					value={answer?.content}
					fullWidth
					initState={getInputValidationState(questionState, isPreview)}
					onChange={handleOnChange}
					inputProps={{
						multiline: true,
						rows: 15,
					}}
					validations={{ custom: (value: string) => checkShortAnswer(gradingInstruction, value) }}
					formControlProps={{ classes: { root: classes.textField } }}
				/>}
				<br />
				<div className={classes.gradingSection}>
					<div>
						<div className={classes.gradingInstructionTitle}>
							{intl.fm("quiz.longAnswer.gradingInstruction")}
						</div>
						<StyledMarkdown source={gradingInstruction.longAnswer || ""} darkMode={darkMode} />
					</div>
					{!isPreview && <AnswerGrader questionState={questionState} setRight={() => gradeLongAnswer(true)} setWrong={() => gradeLongAnswer(false)} />}
				</div>
			</div>
		);
	}


	return (
		<CustomInput
			labelText={intl.fm("quiz.longAnswer.label")}
			inputProps={{
				value: answer?.content || "",
				onChange: handleOnChange,
				multiline: true,
				rows: 15,
				disabled,
			}}
			formControlProps={{ variant: "filled", fullWidth: true, classes: { root: classes.textField } }}
		/>
	);


};

const useStyles = makeStyles(
	() => createStyles(
		({
			textField: { backgroundColor: "rgba(120,120,120,0.1)", paddingLeft: 12, paddingRight: 12, paddingTop: 32 },
			gradingSection: {
				display: "flex",
				flexWrap: "wrap-reverse",
				alignItems: "flex-start",
				justifyContent: "space-between",
			},
			gradingInstructionTitle: { fontWeight: 600 },
		}),
	),
);

import { Sortable } from "@hlcr/app/model/Sortable";

import { EventType } from "models/EventType";
import { SolvableEventUnit } from "models/EventUnit";
import { BaseEvent } from "shared/event/model/BaseEvent";
import { UserEvent } from "shared/event/model/UserEvent";

export interface UserCurriculumEvent extends UserEvent, Sortable {
	parent: number;
	parentName: string;
	sortOrder: number;
	unitsCount: number;
	/**
	 * counts whether a unit has been viewed or not
	 */
	startedUnitsCount: number;
	/**
	 * counts if the unit has a submitted solution by the participant
	 */
	unitsWithSolutionCount: number;
	units: SolvableEventUnit[];
}

export const isUserCurriculumEvent = (event: BaseEvent) => {
	return event.type === EventType.CURRICULUM_EVENT;
};

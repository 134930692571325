import { Dto } from "@hlcr/core/api/RemoteResource";
import { SwitchProps } from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select/Select";
import { AutocompleteProps } from "@material-ui/lab";

export const onValueChange = <T extends Dto>(field: keyof T, currentState: T, setStateMethod: ((value: T) => void)) =>
	(value: unknown) =>
		setStateMethod(
			{
				...currentState,
				[field]: value,
			},
		);

export const onSelectChange = <T extends Dto>(field: keyof T, currentState: T, setStateMethod: (value: T) => void): SelectProps["onChange"] =>
	(event, child) =>
		onValueChange(field, currentState, setStateMethod)(event.target.value === "" ? undefined : event.target.value);

export const onSwitchChange = <T extends Dto>(field: keyof T, currentState: T, setStateMethod: (value: T) => void): SwitchProps["onChange"] =>
	(event, checked) =>
		onValueChange(field, currentState, setStateMethod)(checked);

export const onAutoCompleteChange = <T extends Dto>(field: keyof T, currentState: T, setStateMethod: (value: T) => void): AutocompleteProps<any, any, any, any>["onChange"] =>
	(event, value) =>
		onValueChange(field, currentState, setStateMethod)(value);

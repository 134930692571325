import { EnumDetail } from "@hlcr/app/enum/EnumBase";
import { HackingLabRoute, HackingLabRouteType } from "@hlcr/app/enum/EnumRoute";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import { HelpAction, HelpRole } from "@hlcr/app/model/Help";
import { formatMessage } from "@hlcr/ui/Intl";
import EventIcon from "@material-ui/icons/Event";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import SchoolIcon from "@material-ui/icons/School";
import StorageIcon from "@material-ui/icons/Storage";

import ManagerClasses from "views/Manager/Class/ManagerClasses";
// import { EventManager } from "views/Manager/EventManager";
import { EventManager } from "views/Manager/EventManager";
import ManagerClassDetails, { ManagerClassDetailsBreadCrumb } from "views/Manager/ManagerClassDetails";
import ManagerEventDetails, { ManagerEventDetailsBreadCrumb } from "views/Manager/ManagerEventDetails";
import ManagerEvents from "views/Manager/ManagerEvents";
import ManagerUsers from "views/Manager/ManagerUsers";
import ManagerVms from "views/Manager/ManagerVMs";
import ManagerTeamDetails, { ManagerTeamDetailsBreadCrumb } from "views/Manager/Teams/ManagerTeamDetails";
import ManagerTeams from "views/Manager/Teams/ManagerTeams";


export enum EventManagerRoutes {
	ROOT = "ROOT",
	EVENTS = "EVENTS",
	EVENTS_DETAIL = "EVENTS_DETAIL",
	EVENTS_DETAIL_CURRICULA = "EVENTS_DETAIL_CURRICULA",
	TEAMS = "TEAMS",
	TEAMS_DETAIL = "TEAMS_DETAIL",
	CLASSES = "CLASSES",
	CLASSES_DETAIL = "CLASSES_DETAIL",
	VIRTUAL_MACHINES = "VIRTUAL_MACHINES",
	USERS = "USERS",
}

export const EVENT_MANAGER_ROUTES: EnumDetail<EventManagerRoutes, HackingLabRoute> = {
	[EventManagerRoutes.ROOT]: {
		title: "navigation.entries.manager",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.manager"),
		path: "/manager",
		iconColor: "purple",
		faIcon: "user-tie",
		component: EventManager,
		hasSidebarLink: true,
		needsRole: HackingLabRole.COMPOSITE_MANAGER,
		breadcrumb: () => formatMessage("navigation.entries.manager"),
		help: {
			role: HelpRole.MANAGER,
			action: HelpAction.PAGE_EVENT_MANAGEMENT,
		},
	},
	[EventManagerRoutes.CLASSES]: {
		title: "navigation.entries.classesManagement",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.classesManagement"),
		path: "/manager/classes",
		icon: SchoolIcon,
		iconColor: "purple",
		mini: "CM",
		component: ManagerClasses,
		needsRole: HackingLabRole.COMPOSITE_MANAGER,
		breadcrumb: () => formatMessage("navigation.entries.classesManagement"),
		help: {
			role: HelpRole.MANAGER,
			action: HelpAction.PAGE_CLASS_MANAGEMENT,
		},
	},
	[EventManagerRoutes.CLASSES_DETAIL]: {
		title: "navigation.entries.classesManagementDetail",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.classesManagementDetail"),
		path: "/manager/classes/:classId",
		component: ManagerClassDetails,
		needsRole: HackingLabRole.COMPOSITE_MANAGER,
		breadcrumb: ManagerClassDetailsBreadCrumb,
		help: {
			role: HelpRole.MANAGER,
			action: HelpAction.PAGE_CLASS_DETAILS,
		},
	},
	[EventManagerRoutes.EVENTS]: {
		title: "navigation.entries.eventManagement",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.eventManagement"),
		icon: EventIcon,
		path: "/manager/events",
		component: ManagerEvents,
		mini: "EM",
		needsRole: HackingLabRole.COMPOSITE_MANAGER,
		breadcrumb: () => formatMessage("navigation.entries.eventManagement"),
		help: {
			role: HelpRole.MANAGER,
			action: HelpAction.PAGE_EVENT_MANAGEMENT,
		},
	},
	[EventManagerRoutes.EVENTS_DETAIL]: {
		title: "manager.eventDetails.breadCrumb",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("manager.eventDetails.breadCrumb"),
		path: "/manager/events/:eventId",
		component: ManagerEventDetails,
		needsRole: HackingLabRole.COMPOSITE_MANAGER,
		breadcrumb: ManagerEventDetailsBreadCrumb,
		help: {
			role: HelpRole.MANAGER,
			action: HelpAction.PAGE_EVENT_DETAILS,
		},
	},
	[EventManagerRoutes.EVENTS_DETAIL_CURRICULA]: {
		title: "manager.eventDetails.breadCrumb",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("manager.eventDetails.breadCrumb"),
		path: "/manager/events/:parentId/curriculumevents/:eventId",
		component: ManagerEventDetails,
		needsRole: HackingLabRole.COMPOSITE_MANAGER,
		breadcrumb: ManagerEventDetailsBreadCrumb,
		help: {
			role: HelpRole.MANAGER,
			action: HelpAction.PAGE_EVENT_DETAILS,
		},
	},
	[EventManagerRoutes.TEAMS]: {
		title: "navigation.entries.teamsManagement",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.teamsManagement"),
		path: "/manager/teams",
		icon: GroupIcon,
		iconColor: "purple",
		mini: "TM",
		component: ManagerTeams,
		needsRole: HackingLabRole.COMPOSITE_MANAGER,
		breadcrumb: () => formatMessage("navigation.entries.teamsManagement"),
		help: {
			role: HelpRole.MANAGER,
			action: HelpAction.PAGE_TEAM_MANAGEMENT,
		},
	},
	[EventManagerRoutes.TEAMS_DETAIL]: {
		title: "navigation.entries.teamsDetail",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.teamsDetail"),
		path: "/manager/teams/:teamId",
		component: ManagerTeamDetails,
		needsRole: HackingLabRole.COMPOSITE_MANAGER,
		breadcrumb: ManagerTeamDetailsBreadCrumb,
		help: {
			role: HelpRole.MANAGER,
			action: HelpAction.PAGE_TEAM_DETAILS,
		},
	},
	[EventManagerRoutes.USERS]: {
		title: "navigation.entries.usersManagement",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.usersManagement"),
		path: "/manager/users",
		icon: PersonIcon,
		iconColor: "purple",
		mini: "UM",
		component: ManagerUsers,
		needsRole: HackingLabRole.COMPOSITE_MANAGER,
		breadcrumb: () => formatMessage("navigation.entries.usersManagement"),
	},
	[EventManagerRoutes.VIRTUAL_MACHINES]: {
		title: "navigation.entries.vmsManagement",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		name: () => formatMessage("navigation.entries.vmsManagement"),
		path: "/manager/vms",
		icon: StorageIcon,
		iconColor: "purple",
		mini: "VM",
		component: ManagerVms,
		needsRole: HackingLabRole.COMPOSITE_MANAGER,
		breadcrumb: () => formatMessage("navigation.entries.vmsManagement"),
	},
};

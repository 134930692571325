// material-ui components
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";


import tableStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/tableStyle";

import Tooltip from "../CustomTooltip/CustomTooltip";
import CustomTableCell from "./CustomTableCell";

class CustomTable extends React.Component {
	totalRow(row, key, tableRowClasses) {
		const { classes, tableHead, hover } = this.props;
		return (
			<TableRow key={key} hover={hover} className={tableRowClasses}>
				<TableCell className={classes.tableCell} colSpan={row.colspan} />
				<TableCell className={cx(classes.tableCell, classes.tableCellTotal)}>
					Total
				</TableCell>
				<TableCell className={cx(classes.tableCell, classes.tableCellAmount)}>
					{row.amount}
				</TableCell>
				{tableHead.length - (row.colspan - 0 + 2) > 0 ? (
					<TableCell
						className={classes.tableCell}
						colSpan={tableHead.length - (row.colspan - 0 + 2)}
					/>
				) : null}
			</TableRow>
		);
	}
	purchaseRow(row, key, tableRowClasses) {
		const { classes, hover } = this.props;
		return (
			<TableRow key={key} hover={hover} className={tableRowClasses}>
				<TableCell className={classes.tableCell} colSpan={row.colspan} />
				<TableCell
					className={cx(classes.tableCell, classes.right)}
					colSpan={row.col.colspan}
				>
					{row.col.text}
				</TableCell>
			</TableRow>
		);
	}

	getToolTipPlacement(columnKey) {
		const { tableRenderer } = this.props;
		switch (columnKey) {
			case 0:
				return "right";
			case tableRenderer.length - 1:
				return "left";
			default:
				return "bottom";
		}
	}

	rowWithRenderer(row, key, tableRowClasses) {
		const { classes, tableRenderer, hover, rowTo } = this.props;
		return (
			<TableRow
				key={key}
				hover={hover}
				className={cx(classes.tableRow, tableRowClasses)}
			>
				{tableRenderer.map((columnRenderer, columnKey) => (
					<CustomTableCell
						className={this.calculateCellClasses(columnKey)}
						onClick={columnRenderer.onClick && columnRenderer.onClick(row)}
						key={columnKey}
						to={rowTo && rowTo(row)}
					>
						{!columnRenderer.toolTip ? (
							columnRenderer.renderCell(row)
						) : (
							<Tooltip
								title={columnRenderer.toolTip(row)}
								placement={this.getToolTipPlacement(columnKey)}
							>
								{columnRenderer.renderCell(row)}
							</Tooltip>
						)}
					</CustomTableCell>
				))}
			</TableRow>
		);
	}

	tableRow(rowIn, key) {
		const { classes, tableRenderer, hover, striped } = this.props;
		let rowColor = "";
		let rowColored = false;
		let row = rowIn;
		if (row.color !== undefined) {
			rowColor = row.color;
			rowColored = true;
			row = row.data;
		}
		const tableRowClasses = cx({
			[classes.tableRowHover]: hover,
			[classes[rowColor + "Row"]]: rowColored,
			[classes.tableStripedRow]: striped && key % 2 === 0
		});

		if (row.total) {
			return this.totalRow(row, key, tableRowClasses);
		}

		if (row.purchase) {
			return this.purchaseRow(row, key, tableRowClasses);
		}

		if (tableRenderer) {
			return this.rowWithRenderer(row, key, tableRowClasses);
		}

		return (
			<TableRow
				key={key}
				hover={hover}
				className={cx(classes.tableRow, tableRowClasses)}
			>
				{row.map((prop, cellKey) => {
					return (
						<TableCell
							className={this.calculateCellClasses(cellKey)}
							key={cellKey}
						>
							{prop}
						</TableCell>
					);
				})}
			</TableRow>
		);
	}

	calculateCellClasses(rowIndex) {
		const {
			coloredColls,
			colorsColls,
			customClassesForCells,
			customCellClasses,
			classes
		} = this.props;
		return cx(classes.tableCell, {
			[classes[colorsColls[coloredColls.indexOf(rowIndex)]]]:
				coloredColls.indexOf(rowIndex) !== -1,
			[customCellClasses[customClassesForCells.indexOf(rowIndex)]]:
				customClassesForCells.indexOf(rowIndex) !== -1
		});
	}

	render() {
		const {
			classes,
			tableHead,
			tableData,
			tableRenderer,
			tableHeaderColor,
			customHeadCellClasses,
			customHeadClassesForCells
		} = this.props;

		const tableHeadList =
			tableRenderer && tableRenderer.some(dr => !!dr.title)
				? tableRenderer.map(dr => dr.title)
				: tableHead;

		return (
			<div className={classes.tableResponsive}>
				<Table className={classes.table}>
					{tableHeadList !== undefined ? (
						<TableHead className={classes[tableHeaderColor]}>
							<TableRow className={classes.tableRow}>
								{tableHeadList.map((head, key) => {
									const tableCellClasses = cx(
										classes.tableHeadCell,
										classes.tableCell,
										classes.tableHeadFontSize,
										{
											[customHeadCellClasses[
												customHeadClassesForCells.indexOf(key)
											]]: customHeadClassesForCells.indexOf(key) !== -1
										}
									);
									return (
										<TableCell className={tableCellClasses} key={key}>
											{head}
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>
					) : null}
					<TableBody>
						{tableData.map((row, key) => this.tableRow(row, key))}
					</TableBody>
				</Table>
			</div>
		);
	}
}

CustomTable.defaultProps = {
	tableHeaderColor: "gray",
	hover: false,
	colorsColls: [],
	coloredColls: [],
	striped: false,
	customCellClasses: [],
	customClassesForCells: [],
	customHeadCellClasses: [],
	customHeadClassesForCells: []
};

CustomTable.propTypes = {
	classes: PropTypes.object.isRequired,
	tableHeaderColor: PropTypes.oneOf([
		"warning",
		"primary",
		"danger",
		"success",
		"info",
		"rose",
		"gray"
	]),
	tableHead: PropTypes.arrayOf(PropTypes.string),
	// Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
	tableData: PropTypes.array,
	tableRenderer: PropTypes.arrayOf(PropTypes.object),
	hover: PropTypes.bool,
	coloredColls: PropTypes.arrayOf(PropTypes.number),
	// Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
	colorsColls: PropTypes.array,
	customCellClasses: PropTypes.arrayOf(PropTypes.string),
	customClassesForCells: PropTypes.arrayOf(PropTypes.number),
	customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
	customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
	striped: PropTypes.bool,
	rowToolTip: PropTypes.func,
	rowTo: PropTypes.func
};

export default withStyles(tableStyle)(CustomTable);

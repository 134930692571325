import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import eventsApi from "actions/events";
import eventCardGridStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/eventCardGridStyle";
import EventFilterBar, { createQueryFilter } from "components/EventFilterBar/EventFilterBar";
import { EventList } from "shared/event/components/EventList/EventList";
import { getEndTime } from "helper/dateCalc";
import { createMemoize } from "helper/memoize";
import { eventDateSorter } from "helper/sorting";
import usePersistedState from "helper/usePersistedState";
import { withIntl } from "@hlcr/ui/Intl";
import { EventType } from "models/EventType";
import { EventState, UserEventState } from "variables/constants";
import { getEventState } from "variables/constantsHelpers";
import JoinEventModal from "views/Events/JoinEventModal";
import { EventAccessTokenModal } from "eventAccessToken/EventAccessTokenModal";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import useQuery from "helper/useQuery";

const Events = ({ fetchEvents, fetchEventJoinInfo, postEventRegistration, pending, intl, events }) => {
	const history = useHistory();
	const location = useLocation();

	const persistFor = "UserEvents";
	const prefix = `${persistFor}::Filter`;

	const filterQueryParameter = "filter";
	const filterQuery = useQuery().get(filterQueryParameter) ?? "";
	const [ filter, setFilter ] = React.useState(filterQuery); // this is the filter entered the search input field
	const [ filterTimeoutHandle, setFilterTimeoutHandle ] = React.useState(null); // this is the handle to kill waiting url changes on filter change

	const [ showJoinDialog, setShowJoinDialog ] = useState(false);
	const [ token, setToken ] = useState("");
	const [ showRedeemDialog, setShowRedeemDialog ] = useState(false);
	const [ eventFilterSelection, setEventFilterSelection ] = usePersistedState(`${prefix}::EventState`, [ UserEventState.RUNNING, UserEventState.UPCOMING ]);
	const [ enteredToken, setEnteredToken ] = useState("");

	// if we come via url /events/redeem/:token => we show directly the redeem dialog
	const { tokenParam } = useParams();
	useEffect(() => {
		if (location.pathname.includes("events/redeem")) {
			setShowRedeemDialog(true);
		}
	}, [ location.pathname ]);

	useEffect(() => {
		if (tokenParam) {
			setEnteredToken(tokenParam);
			setShowRedeemDialog(true);
		}
	}, [ tokenParam ]);

	useEffect(() => {
		// because it is quite slow to replace history, we only do it if the filter has not changed for a certain amount of time
		if (filter !== filterQuery) {
			if (filterTimeoutHandle) {
				clearTimeout(filterTimeoutHandle);
			}
			setFilterTimeoutHandle(setTimeout(() => {
				if (filter) {
					history.replace(`/events?${filterQueryParameter}=${filter}`);
				} else {
					history.replace(`/events`);
				}
			}, 1000));
		}
	}, [ filter ]);

	useEffect(() => {
		setFilter(filterQuery);
	}, [ filterQuery ]);

	useEffect(() => {
		fetchEvents();
	}, [ fetchEvents ]);

	const register = eventId => {
		postEventRegistration(eventId, event => {
			const shouldRedirectToEvent = chkEvent => chkEvent && chkEvent.id && chkEvent.registered && getEventState(chkEvent.startTime, getEndTime(chkEvent)) === EventState.RUNNING;
			if (shouldRedirectToEvent(event)) {
				history.push(`/events/${eventId}`);
			}
		});
	};

	const onCloseJoinDialog = (hasJoined, eventTitle) => {
		setToken("");
		setShowJoinDialog(false);

		if (hasJoined && eventTitle) {
			history.push(`/events?${filterQueryParameter}=${encodeURIComponent(eventTitle)}`);
		} else {
			history.push("/events");
		}
	};

	const onCloseRedeemDialog = (eventTitle, filterDirectlyOnClose) => {
		setShowRedeemDialog(false);

		if (eventTitle && filterDirectlyOnClose) {
			history.push(`/events?${filterQueryParameter}=${encodeURIComponent(eventTitle)}`);
		}
		if (!eventTitle) {
			history.push("/events");
		}
	};

	const redeemToken = (token, callback, callbackFailure) => {
		fetchEventJoinInfo(token, (eventJoinResult) => {
			setToken(token);
			setShowJoinDialog(true);
			if (callback && typeof callback === "function") {
				callback(eventJoinResult);
			}
		}, callbackFailure);
	};

	return (
		<div>
			<EventFilterBar
				query={filter}
				onQueryChange={event => setFilter(event.target.value)}
				selection={eventFilterSelection}
				onSelectionChange={setEventFilterSelection}
				includeArchived={false}
			>
				<Button
					onClick={() => history.push("/events/redeem")}
					color="primary"
					variant="contained"
					style={{ borderWidth: 2 }}
				>{intl.fm("events.redeem.accessToken")}</Button>
			</EventFilterBar>
			{events.length > 0 && (
				<EventList
					events={events.filter(createQueryFilter(filter, eventFilterSelection))}
					register={register}
					pending={pending}
					intl={intl}
				/>
			)}
			<JoinEventModal open={showJoinDialog} onClose={onCloseJoinDialog} token={token} />
			{showRedeemDialog &&
			 <EventAccessTokenModal
				 token={enteredToken}
				 onClose={onCloseRedeemDialog}
				 redeemToken={redeemToken}
			 />
			}
		</div>
	);
};

Events.propTypes = {
	events: PropTypes.array.isRequired,
	pending: PropTypes.bool.isRequired,
	fetchEventJoinInfo: PropTypes.func.isRequired,
	postEventRegistration: PropTypes.func.isRequired,
	fetchEvents: PropTypes.func.isRequired,
};

const filterCurriculumAndStandaloneEvents = createMemoize(
	events => events.filter(event => event.type !== EventType.CURRICULUM_EVENT).sort(eventDateSorter),
);

const mapStateToProps = state => ({
	events: filterCurriculumAndStandaloneEvents(state.api.resources.events.data),
	pending: state.api.resources.events.pending,
	match: undefined,
	location: undefined,
});

const mapDispatchToProps = dispatch => bindActionCreators({
	                                                          fetchEvents: eventsApi.fetchEvents,
	                                                          postEventRegistration: eventsApi.postEventRegistration,
	                                                          fetchEventJoinInfo: eventsApi.fetchEventJoinInfo,
                                                          }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps), withIntl, withStyles(eventCardGridStyle))(Events);

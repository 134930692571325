export const managerInitState = {
	managerCurriculumEvents: {
		pending: false,
		data: []
	},
	managerEvents: {
		pending: false,
		data: []
	},
	managerUnits: {
		pending: false,
		data: []
	},
	managerEventUnits: {
		pending: false,
		data: []
	},
	managerTeams: {
		pending: false,
		data: []
	},
	managerTeamMembers: {
		pending: false,
		data: []
	},
	managerEventTeams: {
		pending: false,
		data: []
	},
	managerClasses: {
		pending: false,
		data: []
	},
	managerClassTeachers: {
		pending: false,
		data: []
	},
	managerUsers: {
		pending: false,
		data: []
	},
	managerStudents: {
		pending: false,
		data: []
	},
	managerTeachers: {
		pending: false,
		data: []
	},
	managerEventUsers: {
		pending: false,
		data: []
	},
	managerVms: {
		pending: false,
		data: []
	},
	managerProfiles: {
		pending: false,
		data: []
	},
	accessTokens: {
		pending: false,
		data: []
	}
};

import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EventIcon from "@material-ui/icons/Event";
import GroupIcon from "@material-ui/icons/Group";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import reportApi from "actions/report";
import reportStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/reportStyle";
import InfoCard from "components/Cards/InfoCard";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import { DownloadButton, ReportLink, ReportTableCard, SelectCard } from "components/Report/ReportComponents";
import { calcPercentage, printPercentage } from "helper/pointPrint";
import { withIntl } from "@hlcr/ui/Intl";
import { getSolutionSuccessStateByGrade } from "models/SolutionState";

class TeamReport extends React.Component {
	componentDidMount() {
		const { classId, fetchClassUsers, fetchTeams, fetchClasses } = this.props;
		fetchClassUsers(classId);
		fetchTeams(classId);
		fetchClasses();
	}

	onSelectTeam = teamId => {
		const { history, classId } = this.props;
		history.push(`/report/classes/${classId}/teams/${teamId}`);
	};

	render() {
		const { teamReport } = this.props;
		return (
			<GridContainer>
				<ItemGrid xs={12} sm={12} md={6} lg={4}>
					<TeamSelectCard onSelectTeam={this.onSelectTeam} {...this.props} />
				</ItemGrid>
				<ItemGrid xs={12} sm={6} md={6} lg={3}>
					{teamReport && <NumberOfEventsInfo {...this.props} />}
				</ItemGrid>
				<ItemGrid xs={12} sm={6} md={6} lg={3}>
					{teamReport && <TeamStatus {...this.props} />}
				</ItemGrid>

				<ItemGrid xs={12} sm={12} md={12} lg={12}>
					<UsersTableCard {...this.props} />
				</ItemGrid>
			</GridContainer>
		);
	}
}

const UsersTableCard = props => {
	const { userSummariesReports, teamReport, intl, classes, teamId } = props;

	const tableRenderer = [
		{
			id: "name",
			title: intl.fm("report.table.users.header.name"),
			renderCell: userReport => userReport.username,
			renderCsv: userReport => userReport.username,
			sort: true
		},
		{
			id: "team",
			title: intl.fm("report.table.users.header.team"),
			renderCell: userReport => userReport.teamName,
			renderCsv: userReport => userReport.teamName,
			sort: true
		},
		{
			id: "leader",
			title: intl.fm("report.table.users.header.leader"),
			renderCell: userReport =>
				userReport.leader ? <CheckIcon/> : <CloseIcon/>,
			renderCsv: userReport => (userReport.leader ? "yes" : "no"),
			sort: (a, b) => (a.leader === b.leader ? 0 : a.leader < b.leader ? 1 : -1)
		},
		{
			id: "solutions",
			title: intl.fm("report.table.users.header.solutions"),
			renderCell: userReport => userReport.mandatoryFullPointSolutionCount,
			renderCsv: userReport => userReport.mandatoryFullPointSolutionCount,
			sort: (a, b) =>
				b.mandatoryFullPointSolutionCount - a.mandatoryFullPointSolutionCount
		},
		{
			id: "status",
			title: intl.fm("report.table.users.header.status"),
			renderCell: userReport =>
				printPercentage(
					userReport.mandatoryFullPointSolutionCount,
					userReport.mandatoryUserChallengeCount
				),
			renderCsv: userReport =>
				printPercentage(
					userReport.mandatoryFullPointSolutionCount,
					userReport.mandatoryUserChallengeCount
				),
			sort: (a, b) =>
				b.mandatoryUserChallengeCount - a.mandatoryUserChallengeCount
		},
		{
			id: "action",
			title: intl.fm("report.table.teams.header.action"),
			renderCell: userReport => (
				<ReportLink
					label="Solutions"
					to={`/report/classes/${
						teamReport ? teamReport.classId : "undefined"
					}/teams/${userReport.teamId}/users/${userReport.userId}`}
				/>
			)
		}
	];

	const csvTitle = teamReport
		? `Team ${teamReport.teamName} Users Report, ${moment().format(
			"DD.MM.YYYY HH:mm"
		)}`
		: "";
	const csvFilename = teamReport
		? `Team_${teamReport.teamName}_${moment().format("DD.MM.YYYY_HH.mm")}.csv`
		: "";

	return (
		<ReportTableCard
			tableData={userSummariesReports}
			title={
				<div>
					{intl.fm("report.table.users")}
					{teamId !== 0 && teamReport && (
						<span className={classes.titleButton}>
							<ReportLink
								label={`Solutions From All ${teamReport.teamName} Users`}
								to={`/report/classes/${teamReport.classId}/teams/${teamReport.teamId}/users/0`}
								noBackground
							/>
						</span>
					)}
					<DownloadButton
						tableData={userSummariesReports}
						csvTitle={csvTitle}
						csvFilename={csvFilename}
						tableRenderer={tableRenderer}
					/>
				</div>
			}
			color="purple"
			cardIcon={GroupIcon}
			tableRenderer={tableRenderer}
		/>
	);
};

const TeamSelectCard = ({
	onSelectTeam,
	teamReport,
	teamReportOptions,
	intl
}) => (
	<SelectCard
		icon={GroupIcon}
		color="purple"
		title={intl.fm("report.card.title.team")}
		onSelect={onSelectTeam}
		value={teamReport}
		selects={teamReportOptions}
		idField="teamId"
		displayField="teamName"
		footer={`${teamReport ? teamReport.userCount : 0} ${intl.fm(
			"report.card.title.members"
		)}`}
	/>
);

const TeamStatus = ({ teamReport, intl }) => {
	const pointsValue = calcPercentage(
		teamReport.mandatoryFullPointSolutionCount,
		teamReport.mandatoryUserChallengeCount
	);
	const state = getSolutionSuccessStateByGrade(pointsValue);

	return (
		<InfoCard
			icon={state.icon}
			iconColor={state.iconColor}
			title={intl.fm("report.card.title.status")}
			description={printPercentage(
				teamReport.mandatoryFullPointSolutionCount,
				teamReport.mandatoryUserChallengeCount,
				"\u00A0"
			)}
		/>
	);
};

const NumberOfEventsInfo = ({ teamReport, intl }) => (
	<InfoCard
		icon={EventIcon}
		iconColor="purple"
		title={intl.fm("report.card.title.events")}
		description={teamReport.eventCount}
		footer={`${teamReport.mandatoryEventCount || 0} ${intl.fm(
			"report.card.events.mandatory"
		)}`}
	/>
);

const mapStateToProps = (state, ownProps) => {
	const teamId = Number(ownProps.match.params.teamId);
	const classId = Number(ownProps.match.params.classId);

	const { reportTeams, reportUsers } = state.api.resources;
	const allTeamsReport = reportTeams.data.length
		? reportTeams.data.reduce(
			(r1, r2) => ({
				teamId: 0,
				classId: r1.classId,
				teamName: "All Teams",
				userCount: r1.userCount + r2.userCount,
				eventCount: r1.eventCount + r2.eventCount,
				unitCount: r1.unitCount + r2.unitCount,
				mandatoryEventCount: r1.mandatoryEventCount + r2.mandatoryEventCount,
				mandatoryChallengeCount: r1.mandatoryChallengeCount + r2.mandatoryChallengeCount,
				mandatoryUserChallengeCount: r1.mandatoryUserChallengeCount + r2.mandatoryUserChallengeCount,
				mandatoryFullPointSolutionCount: r1.mandatoryFullPointSolutionCount + r2.mandatoryFullPointSolutionCount
			}),
			{
				userCount: 0,
				eventCount: 0,
				unitCount: 0,
				mandatoryEventCount: 0,
				mandatoryChallengeCount: 0,
				mandatoryUserChallengeCount: 0,
				mandatoryFullPointSolutionCount: 0
			}
		)
		: {
			teamId: 0,
			teamName: "No Teams"
		};

	return {
		teamId,
		classId,
		teamReport: teamId
			? reportTeams.data.find(teamReport => teamReport.teamId === teamId)
			: allTeamsReport,
		teamReportOptions: [
			allTeamsReport,
			...reportTeams.data.filter(teamReport => teamReport.classId === classId)
		],
		userSummariesReports: teamId
			? reportUsers.data.filter(userReport => userReport.teamId === teamId)
			: reportUsers.data.filter(userReport => userReport.classId === classId)
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchClassUsers: reportApi.fetchClassUsers,
			fetchTeams: reportApi.fetchTeams,
			fetchClasses: reportApi.fetchClasses
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(reportStyle),
	withIntl
)(TeamReport);

let TeamReportBreadCrumb = ({ teamReport }) => (
	<span>{teamReport ? teamReport.teamName : "All Teams"}</span>
);

const mapStateToPropsBreadCrumbs = (state, ownProps) => {
	const teamId = Number(ownProps.match.params.teamId);
	return {
		teamReport: state.api.resources.reportTeams.data.find(
			teamReport => teamReport.teamId === teamId
		)
	};
};

TeamReportBreadCrumb = connect(mapStateToPropsBreadCrumbs)(TeamReportBreadCrumb);
export { TeamReportBreadCrumb };

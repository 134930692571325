import { Button } from "@hlcr/mui/Button";
import { useIntl } from "@hlcr/ui/Intl";
import React from "react";

import ModalWindow from "components/ModalWindow/ModalWindow";
import styles from "dialog/confirmationDialog.module.scss";

export interface ConfirmationDialogProps {
	message: string;
	onClose: (hasClickedYes: boolean) => void;
	yesButtonLabel?: string;
	noButtonLabel?: string;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
	const intl = useIntl();

	return (
		<ModalWindow
			title={""}
			open={true}
			onClose={() => props.onClose(false)}
			fullWidth
			maxWidth="xs"
			actionSection={(
				<div className={styles.actionSection}>
					<Button
						onClick={() => props.onClose(false)}
						color="gray"
					>
						{props.noButtonLabel ?? intl.fm("common.labels.no")}
					</Button>
					<Button
						onClick={() => props.onClose(true)}
						color="info"
					>
						{props.yesButtonLabel ?? intl.fm("common.labels.yes")}
					</Button>
				</div>
			)}
		>
			<p>{props.message}</p>
		</ModalWindow>
	);
};




import { Dto } from "@hlcr/core/api/RemoteResource";
import { UUID } from "@hlcr/core/types";

export interface CriteriaGradingCatalogDto extends Dto<UUID> {
	criteriaGradingDescriptorsCatalogId: UUID;
	name: String;
	criteriaGradingCatalogItems: CriteriaGradingCatalogItemDto[];
}

export interface CriteriaGradingCatalogItemDto extends Dto<UUID> {
	gradingCatalogItemDescriptorsId: UUID;
	name: String;
	description: String;
	comment: String;
	acronym: String;
	points: number;
	fulfilled?: boolean;
}

export function getEmptyCriteriaList(catalog: CriteriaGradingCatalogItemDto[] | undefined | null) {
	return catalog
		? catalog.map((item) => getEmptyCriteriaGradingCatalogItem(item))
		: null;
}

function getEmptyCriteriaGradingCatalogItem(item: CriteriaGradingCatalogItemDto): CriteriaGradingCatalogItemDto {
	return {
		gradingCatalogItemDescriptorsId: item.gradingCatalogItemDescriptorsId,
		name: item.name,
		description: item.description,
		comment: "",
		acronym: item.acronym,
		points: item.points,
		fulfilled: undefined,
	};
}

const resourcesStyle = {
	resourceIcon: { display: "block" },
	resourceButton: {
		padding: 0,
		margin: 0
	},
	resourceProperties: {
		fontSize: ".8em",
		color: "#999",
		textTransform: "uppercase"
	},
	invisible: { display: "none" },
	resourceSpinner: { marginLeft: 6 },
	resourceLink: {
		color: "#000",
		"&:hover": { color: "#666" }
	},
	resourceActionLinks: { width: 60 }
};

export default resourcesStyle;

import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import eventsApi from "actions/events";
import rankingApi from "actions/ranking";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import { withIntl } from "@hlcr/ui/Intl";
import EventRankingCard from "views/Ranking/EventRankingCard";

import { createMemoize } from "helper/memoize";

class RankingPage extends React.Component {

	componentDidMount() {
		const { parentId, eventId, fetchEvent } = this.props;
		fetchEvent(eventId);
		if (parentId) fetchEvent(parentId);

		this.fetchTeamRanking(eventId);
		this.fetchUserRanking(eventId);
	}

	fetchTeamRanking(eventId) {
		const { fetchEventTeamsRanking, fetchEventUnits } = this.props;
		fetchEventTeamsRanking(eventId);
		fetchEventUnits(eventId);
	}

	fetchUserRanking(eventId) {
		const { fetchEventUsersRanking, fetchEventUnits } = this.props;
		fetchEventUsersRanking(eventId);
		fetchEventUnits(eventId);
	}

	render() {
		const { event, teamRankingData, userRankingData, eventUnits } = this.props;
		return (
			<div>
				<GridContainer>
					{teamRankingData.length > 0 && (
						<ItemGrid xs={12} sm={12} md={12} lg={12}>
							<EventRankingCard
								isTeam={true}
								icon={GroupIcon}
								color="purple"
								useEvent={event}
								selectedEvent={event}
								rankingData={teamRankingData}
								eventUnits={eventUnits}
							/>
						</ItemGrid>
					)}
					{userRankingData.length > 0 && (
						<ItemGrid xs={12} sm={12} md={12} lg={12}>
							<EventRankingCard
								isTeam={false}
								icon={PersonIcon}
								color="purple"
								useEvent={event}
								selectedEvent={event}
								rankingData={userRankingData}
								eventUnits={eventUnits}
							/>
						</ItemGrid>
					)}
				</GridContainer>
			</div>
		);
	}
}

RankingPage.propTypes = {
	eventId: PropTypes.number,
	parentId: PropTypes.number.isRequired,
	event: PropTypes.object,
	fetchEvent: PropTypes.func.isRequired,
	fetchEventUnits: PropTypes.func.isRequired,
};

const getEvent = createMemoize(
	(events, eventId) => events.find(event => event.id === eventId),
);

const getEventUnits = createMemoize(
	(eventUnits, eventId) => eventUnits.filter(eu => eu.eventId === eventId)
);


const filterTeamRankingData = createMemoize(
	(rankingTeams, eventId) => rankingTeams.filter(rank => rank.eventId === eventId),
);

const filterUserRankingData = createMemoize(
	(rankingUsers, eventId) => rankingUsers.filter(rank => rank.eventId === eventId),
);

const mapStateToProps = (state, ownProps) => {
	const eventId = Number(ownProps.match.params.eventId);
	const parentId = Number(ownProps.match.params.parentId);
	return {
		eventId,
		parentId,
		event: getEvent(state.api.resources.events.data, eventId),
		eventUnits: getEventUnits(state.api.resources.eventUnits.data, eventId),
		teamRankingData: filterTeamRankingData(
			state.api.resources.rankingTeams.data,
			eventId,
		),
		userRankingData: filterUserRankingData(
			state.api.resources.rankingUsers.data,
			eventId,
		),
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchEvent: eventsApi.fetchEvent,
			fetchEventUnits: eventsApi.fetchEventUnits,
			fetchEventUsersRanking: rankingApi.fetchEventUsersRanking,
			fetchEventTeamsRanking: rankingApi.fetchEventTeamsRanking,
		},
		dispatch,
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withIntl,
)(RankingPage);

const noDataStyle = {
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		color: "#999",
		fontSize: 18
	},
	icon: {
		width: 48,
		height: 48
	},
	text: { textTransform: "uppercase" }
};

export default noDataStyle;

import { grayColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";

const levelLabelStyle = {
	root: {
		display: "inline-block",
		borderRadius: 100,
		background: grayColor,
		padding: "4px 8px",
		lineHeight: "1em",
		color: "#fff"
	}
};

export default levelLabelStyle;

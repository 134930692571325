import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import React, { useRef, useState } from "react";

import { Button } from "@hlcr/mui/Button";
import CustomInput from "components/CustomInput/CustomInput";
import { withIntl } from "@hlcr/ui/Intl";

export default withIntl(withStyles({
	flexCenter: { display: "flex", justifyContent: "center" },
	enterButton: {
		margin: 0,
		paddingLeft: 0,
		paddingRight: 0
	},
	redeemIcon: { marginRight: 10 },
	redeemButton: { margin: "0 0 0 10px" },
	tokenInput: {
		paddingTop: 0,
		margin: 0,
		width: 300,
		"@media (max-width: 900px)": { width: "auto" }
	},
	sliding: {
		display: "inline-block",
		overflow: "hidden",
		width: 0,
		height: 0,
		opacity: 0,
		transition: "opacity 350ms ease"
	},
	slidingOpen: {
		overflow: "visible",
		height: 35,
		width: "auto",
		opacity: 1,
		transition: "opacity 350ms ease"
	}
})(({ intl, classes, redeemToken, displayText }) => {
	const textInputRef = useRef(null);
	const [ token, setToken ] = useState("");
	const [ tokenInputVisible, setTokenInputVisible ] = useState(false);
	const [ redemptionLoading, setRedemptionLoading ] = useState(false);

	const showInput = () => {
		setTokenInputVisible(true);
		if(textInputRef?.current){
			textInputRef.current.focus();
		}
	};

	const handleTokenEnter = event => {
		if (event?.key === "Enter" && token.length > 0) {
			handleSend();
		}
	};

	const handleSend = () => {
		setRedemptionLoading(true);
		redeemToken(token, () => {
			setToken("");
			setRedemptionLoading(false);
			setTokenInputVisible(false);
		}, () => setRedemptionLoading(false));
	};

	const enterGroupClasses = tokenInputVisible ? cx(classes.sliding) : cx(classes.sliding, classes.slidingOpen);
	const inputGroupClasses = tokenInputVisible ? cx(classes.sliding, classes.slidingOpen) : cx(classes.sliding);
	return (
		<div className={classes.flexCenter}>
			<div className={enterGroupClasses}>
				<Button customClass={classes.enterButton} color="infoNoBackground" onClick={() => showInput()}>
					<FontAwesomeIcon className={classes.redeemIcon} icon="key" size="sm" />
					{displayText ? displayText : intl.fm("events.redeem.accessToken")}
				</Button>
			</div>
			<div className={inputGroupClasses}>
				<CustomInput
					inputProps={{
						inputRef: textInputRef,
						placeholder: intl.fm("common.labels.token"),
						onChange: e => setToken(e.target.value),
						onKeyDown: handleTokenEnter,
						value: token,
						disabled: redemptionLoading
					}}
					formControlProps={{ className: classes.tokenInput }}
				/>
				<Button color="primary" customClass={classes.redeemButton} size="sm" onClick={handleSend} disabled={!token || redemptionLoading}>
					{intl.fm("common.labels.redeem")}
				</Button>
			</div>
		</div>
	);
}));

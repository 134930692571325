import { withStyles } from "@material-ui/core/styles";
import FileDownloadIcon from "@material-ui/icons/CloudDownload";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import cx from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";

import reportStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/reportStyle";
import IconCard from "components/Cards/IconCard";
import InfoCard from "components/Cards/InfoCard";
import { Button } from "@hlcr/mui/Button";
import NoData from "components/NoData/NoData";
import EnhancedTable from "components/Table/EnhancedTable";
import csvExport from "helper/csvExport";
// FileDownload needs to be changed (not cloud...)
import { withIntl } from "@hlcr/ui/Intl";
import { ObjectSelect } from "components/CustomSelect/ObjectSelect";

let ReportTableCard = props => {
	const { title, cardIcon, color } = props;
	return (
		<IconCard
			title={title}
			icon={cardIcon}
			iconColor={color}
			content={<ReportTable {...props} />}
		/>
	);
};

ReportTableCard = compose(withStyles(reportStyle), withIntl)(ReportTableCard);

let DownloadButton = ({ csvFilename, csvTitle, tableData, tableRenderer, classes }) => {
	const onDownload = () => {
		csvExport(csvFilename, csvTitle, tableData, tableRenderer);
	};
	return (
		<Button
			color="info"
			onClick={onDownload}
			customClass={cx(classes.editButton, classes.filterField)}
		>
			<FileDownloadIcon className={classes.icon} />
		</Button>
	);
};
DownloadButton = withStyles(reportStyle)(DownloadButton);

export { ReportTableCard, DownloadButton };

const ReportTable = ({ tableRenderer, tableData, intl }) => {
	const isDataAvailable = Array.isArray(tableData) && tableData.length;
	return (
		<EnhancedTable
			tableRenderer={isDataAvailable ? tableRenderer : []}
			tableData={tableData}
			hover
			emptyContent={<NoData text={intl.fm("report.table.noData")} />}
		/>
	);
};

let ReportLink = ({ to, classes, label, noBackground }) => (
	<Link className={classes.reportLink} to={to}>
		<Button color={noBackground ? "infoNoBackground" : "info"} customClass={classes.editButton}>
			<EyeIcon className={classes.icon} />
			{label && <div className={classes.label}>{label}</div>}
		</Button>
	</Link>
);
ReportLink = withStyles(reportStyle)(ReportLink);
export { ReportLink };

let ReportButton = ({ onClick, classes, label, noBackground, customClass }) => (
	<Button onClick={onClick} color={noBackground ? "infoNoBackground" : "info"} customClass={customClass ? cx(classes.editButton, customClass) : classes.editButton}>
		<EyeIcon className={classes.icon} />
		{label && <div className={classes.label}>{label}</div>}
	</Button>
);
ReportButton = withStyles(reportStyle)(ReportButton);
export { ReportButton };

let SelectCard = ({ onSelect, value, selects, idField, displayField, title, icon, color, footer = "" }) => {
	return (
		<InfoCard
			icon={icon}
			iconColor={color}
			title={title}
			description={
				Array.isArray(selects) && selects.length > 0 ? (
					<ObjectSelect
						selectedObject={value}
						objects={selects}
						onObjectSelect={onSelect}
						title={title}
						displayField={displayField}
						idField={idField}
						required={true}
					/>
				) : (
					value && value[displayField]
				)
			}
			footer={footer}
		/>
	);
};

SelectCard = withStyles(reportStyle)(SelectCard);
export { SelectCard };

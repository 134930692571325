import { dangerColor, defaultFont, primaryColor, successColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";

const customInputStyle = {
	feedback: {
		position: "absolute",
		top: "23px",
		right: "0",
		zIndex: "0",
		display: "block",
		width: "24px",
		height: "24px",
		textAlign: "center",
		pointerEvents: "none"
	},
	labelWithAdornment: { top: "3px" },
	feedbackAdorment: { right: "22px" },
	disabled: { "&:before": { borderColor: "transparent !important" } },
	underline: {
		"&:hover:not($disabled):before,&:before": {
			borderColor: "#D2D2D2 !important",
			borderWidth: "1px !important"
		},
		"&:after": { borderColor: primaryColor }
	},
	underlineError: { "&:after": { borderColor: dangerColor } },
	underlineSuccess: { "&:after": { borderColor: successColor } },
	labelRoot: {
		...defaultFont,
		color: "#AAAAAA !important",
		fontWeight: "400",
		fontSize: "14px",
		lineHeight: "1.42857",
		top: "10px",
		"& + $underline": { marginTop: "0px" }
	},
	labelRootError: { color: dangerColor + " !important" },
	labelRootSuccess: { color: successColor + " !important" },
	formControl: {
		margin: "0 0 17px 0",
		paddingTop: "27px",
		position: "relative",
		verticalAlign: "unset",
		"& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": { color: "#495057" }
	},
	whiteUnderline: {
		"&:hover:not($disabled):before,&:before": { backgroundColor: "#FFFFFF" },
		"&:after": { backgroundColor: "#FFFFFF" }
	},
	input: {
		textOverflow: "ellipsis ellipsis",
		"&,&::placeholder": {
			fontSize: "14px",
			fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
			fontWeight: "400",
			lineHeight: "1.42857",
			opacity: "1"
		},
		"&::placeholder": { color: "#aaa" },
		[DARK_MODE_SELECTOR]: { "&::placeholder": { color: "#666" } }
	},
	whiteInput: {
		"&,&::placeholder": {
			color: "#FFFFFF",
			opacity: "1"
		}
	},
	inputRoot: {
		width: "100%",
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 }
	},
	inputGroup: {
		position: "relative",
		display: "flex",
		alignItems: "center"
	},
	searchIcon: {
		opacity: 0.6,
		position: "absolute"
	},
	inputSearchMode: { paddingLeft: 20 }
};

export default customInputStyle;

import { Button } from "@hlcr/mui/Button";
import { withIntl } from "@hlcr/ui/Intl";
import { withStyles } from "@material-ui/core/styles";

import modalWindowStyles from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/modalWindowStyles";
import ModalWindow from "components/ModalWindow/ModalWindow";
import * as PropTypes from "prop-types";
import { compose } from "redux";

const WarningDialog = (
	{
		onConfirm,
		onClose,
		open,
		entity,
		entityName,
		title,
		message,
		classes,
		intl,
	},
) => {
	const isOpen = open || !!entity;

	return (
		<ModalWindow
			onClose={onClose}
			title={title ? title : intl.fm("common.deleteConfirm.title")}
			actionSection={
				<div className={classes.actions}>
					<Button onClick={onClose} color="defaultNoBackground">
						{intl.fm("common.labels.cancel")}
					</Button>
					<Button
						onClick={onConfirm}
						color="dangerNoBackground"
					>
						{intl.fm("common.labels.confirm")}
					</Button>
				</div>
			}
			open={isOpen}
		>
			{message
				? message
				: intl.fm("common.deleteConfirm.message", null, { entityName })}
		</ModalWindow>
	);
};

WarningDialog.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	open: PropTypes.bool,
	entity: PropTypes.object,
	entityName: PropTypes.node,
	title: PropTypes.node,
	message: PropTypes.node,
};

export default compose(
	withStyles(modalWindowStyles),
	withIntl,
)(WarningDialog);

import globalStyles from "@hlcr/mui/theme/global.module.scss";
import { ProcessedFile, Upload } from "@hlcr/mui/Upload";
import { useIntl } from "@hlcr/ui/Intl";
import { Paper, TextField, Typography } from "@material-ui/core";
import "rc-slider/assets/index.css";

import styles from "grading/WriteUpForm/writeUpForm.module.scss";

export interface WriteUpFormProps {
	isTeacher: boolean;
	isSubmitting :boolean;
	writeUpFile? :ProcessedFile;
	writeUpText :string;
	onWriteUpChange :(writeUp :string) => void;
	onFileChange :(file? :ProcessedFile) => void;
	hasError :boolean;
}

export const WriteUpForm = (props :WriteUpFormProps) => {

	const intl = useIntl();

	return (
		<Paper
			elevation={0}
			variant="outlined"
			className={styles.frame}>

			<Typography
				variant="body1"
				gutterBottom
				className={styles.frameLabel}>

				{props.isTeacher ? intl.fm("teacher.solution.feedback.title") : intl.fm("grading.mode.writeup")}
			</Typography>
			<TextField
				variant="filled"
				error={props.hasError}
				label={(props.isTeacher ? intl.fm("teacher.solution.feedbackInstr") : intl.fm("solution.history.solutionInstr"))}
				helperText={intl.fm("solution.history.maxCommentLength", undefined, { usedCharCount: props.writeUpText?.length ?? 0 })}
				onChange={(event: any) => {
					props.onWriteUpChange(event.currentTarget.value);
				}}
				disabled={props.isSubmitting}
				value={props.writeUpText}
				multiline
				minRows={10}
				fullWidth
				margin="dense"
				inputProps={{
					className: globalStyles.monoSpaced,
					maxLength: 2000,
				}}
			/>
			<Upload
				type="file"
				file={props.writeUpFile}
				disabled={props.isSubmitting}
				handleProcessedFile={(file :ProcessedFile | null) => props.onFileChange(file ?? undefined)}
			/>
		</Paper>
	);
};

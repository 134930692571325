const teacherSolutionStyle = {
	topInfoCards: {
		display: "flex",
		marginTop: 16,
		"&>div": { margin: "5px" }
	},
	panelHeightFiller: {
		display: "block",
		overflow: "visible"
	},
	panelWithInfo: { display: "flex" },
	solutionHistory: {
		flexGrow: 50,
		alignSelf: "stretch",
		maxWidth: "100%"
	},
	historyMarkdownLabel: {
		fontSize: ".9em",
		marginLeft: "auto",
		marginTop: -15,
		width: "fit-content"
	},
	userFlagListItem: { padding: 0 },
	userFlagListItemTextPrimary: { fontSize: "1em", fontWeight: 500 },
	userFlagListItemTextSecondary: { fontSize: "12px", }
};

export default teacherSolutionStyle;

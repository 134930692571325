import { withStyles } from "@material-ui/core/styles";
import React from "react";

import { formStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamCreateStyles";
import { Button } from "@hlcr/mui/Button";
import ValidationInput from "components/CustomInput/ValidationInput";
import ModalWindow from "components/ModalWindow/ModalWindow";
import { ASSET_TYPES } from "models/Asset";

class ProposalModal extends React.Component {
	state = {
		selectedUnit: undefined,
		email: "",
		phone: "",
		comment: ""
	};

	static getDerivedStateFromProps(props, state) {
		if (
			props.selectedUnit &&
			(!state.selectedUnit ||
				ProposalModal.isNotEqual(props.selectedUnit, state.selectedUnit))
		) {
			return {
				selectedUnit: props.selectedUnit,
				email: props.email,
				phone: "",
				comment: ""
			};
		}
		return null;
	}

	static isNotEqual(unitA, unitB) {
		return !(unitA.id === unitB.id && unitA.eventId === unitB.eventId);
	}

	resetForm = () =>
		this.setState({ comment: "", email: this.props.email, phone: "" });

	onChange = field => value => this.setState(state => ({ [field]: value }));

	getResult = () => {
		const { email, phone, comment, selectedUnit } = this.state;
		const { eventId, id, type } = selectedUnit;
		return {
			eventId,
			unitType: type,
			unitId: id,
			body: {
				description: comment,
				contactMailAddress: email,
				contactPhoneNumber: phone
			}
		};
	};

	submit = result => () =>
		this.setState({ selectedUnit: undefined }, () =>
			this.props.onSubmit(result)
		);

	render() {
		const {
			isModalOpen,
			closeModal,
			intl,
			selectedUnit,
			minLength
		} = this.props;
		const { email, phone, comment } = this.state;

		const disabled = comment.length < minLength;

		return selectedUnit ? (
			<ModalWindow
				open={isModalOpen}
				onClose={closeModal}
				title={ `${intl.fm(ASSET_TYPES[selectedUnit.type].title)}: ${selectedUnit.title}` }
				helpLink={undefined}
				fullWidth
			>
				<ValidationInput
					label={intl.fm("user.label.email")}
					onChange={this.onChange("email")}
					validations={{}}
					value={email}
					fullWidth
				/>
				<ValidationInput
					label={intl.fm("user.label.phone")}
					onChange={this.onChange("phone")}
					validations={{}}
					value={phone}
					fullWidth
				/>
				<ValidationInput
					inputProps={{
						multiline: true,
						rows: 3,
						rowsMax: 6
					}}
					label={intl.fm("teacher.proposal.inputLabel")}
					onChange={this.onChange("comment")}
					validations={{ required: true, minLength: minLength }}
					value={comment}
					fullWidth
				/>

				<div>
					<Button onClick={this.resetForm} color="defaultNoBackground">
						{intl.fm("common.labels.resetForm")}
					</Button>
					<Button
						disabled={disabled}
						onClick={this.submit(this.getResult())}
						color="infoNoBackground"
					>
						{intl.fm("common.labels.submit")}
					</Button>
				</div>
			</ModalWindow>
		) : null;
	}
}
ProposalModal = withStyles(formStyles)(ProposalModal);
export default ProposalModal;

import React from "react";

import EnhancedChart from "components/Chart/EnhancedChart";
import NoData from "components/NoData/NoData";

class EventRankingChart extends React.Component {
	render() {
		const { chartData, isTeam, intl } = this.props;

		return <RankingChart chartData={chartData} intl={intl} isTeam={isTeam} />;
	}
}

const RankingChart = ({ chartData, intl, isTeam }) => {
	const isDataAvailable = Array.isArray(chartData) && chartData.length > 0;
	return (
		<EnhancedChart
			isDataAvailable={isDataAvailable}
			chartData={chartData}
			hover
			emptyContent={<NoData text={intl.fm("ranking.chart.noData")} />}
			isTeam={isTeam}
		/>
	);
};

export default EventRankingChart;

import { combineReducers } from "redux";

import { remoteResourceReducer } from "redux/remoteResourceReducer";

import api from "./apiReducer";
import branding from "./brandingReducer";
import ui from "./uiReducer";

const reducer = combineReducers({
	api,
	branding,
	ui,
	remoteResourceReducer,
});

export default reducer;

export type RootState = ReturnType<typeof reducer>;

import { EASE_TRANSITION, primaryBoxShadow, primaryColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";

const customSelectStyle = {
	select: {
		padding: "12px 24px 7px 0",
		fontSize: ".75rem",
		fontWeight: "400",
		lineHeight: "1.42857",
		textDecoration: "none",
		textTransform: "uppercase",
		color: "#3C4858",
		letterSpacing: "0",
		"&:focus": { backgroundColor: "transparent" },
		"&[aria-owns] + input + svg": { transform: "rotate(180deg)" },
		"& + input + svg": { transition: EASE_TRANSITION },
		transition: "color 0.1s ease-out",
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 }
	},
	selectFormControl: {
		margin: "8px 1px 17px 0px",
		"& > div": {
			"&:before": {
				borderBottomWidth: "1px !important",
				borderBottomColor: "#D2D2D2 !important"
			},
			"&:after": { borderBottomColor: primaryColor + "!important" }
		},
		minWidth: 170
	},
	selectLabel: {
		fontSize: "12px",
		textTransform: "uppercase",
		color: "#AAAAAA !important",
		top: "5px"
	},
	selectMenu: {
		"& > div": {
			overflow: "hidden",
			"& > ul": {
				border: "0",
				padding: "5px 0",
				margin: "0",
				boxShadow: "none",
				minWidth: "100%",
				borderRadius: "4px",
				boxSizing: "border-box",
				display: "block",
				fontSize: "14px",
				textAlign: "left",
				listStyle: "none",
				backgroundColor: "#fff",
				backgroundClip: "padding-box",
				[DARK_MODE_SELECTOR]: { backgroundColor: COLOR.DARK_2 }
			}
		},
		"& $selectPaper $selectMenuItemSelectedMultiple": { backgroundColor: "inherit" },
		"& > div + div": { maxHeight: "266px !important" }
	},
	selectMenuItem: {
		fontSize: "13px",
		padding: "10px 20px",
		margin: "2px 5px",
		borderRadius: "2px",
		transition: "all 150ms linear",
		display: "block",
		clear: "both",
		fontWeight: "400",
		height: "auto",
		whiteSpace: "nowrap",
		color: "#333",
		paddingRight: "30px",
		"&:hover": {
			backgroundColor: primaryColor,
			color: "#FFFFFF",
			...primaryBoxShadow
		},
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 }
	},
	multiSelectMenuItem: { display: "flex" },
	selectMenuItemSelected: {
		backgroundColor: primaryColor + "!important",
		color: "#FFFFFF"
	},
	selectMenuItemSelectedMultiple: {
		backgroundColor: "transparent !important",
		"&:hover": {
			backgroundColor: primaryColor + "!important",
			color: "#FFFFFF",
			...primaryBoxShadow,
			"&:after": { color: "#FFFFFF" }
		},
		"&:after": {
			top: "16px",
			right: "12px",
			width: "12px",
			height: "5px",
			transform: "rotate(-45deg)",
			opacity: "1",
			color: "#3c4858",
			position: "absolute",
			content: "''",
			transition: "opacity 90ms cubic-bezier(0,0,.2,.1)"
		}
	},
	selectPaper: {
		boxSizing: "borderBox",
		borderRadius: "4px",
		padding: "0",
		minWidth: "100%",
		display: "block",
		border: "0",
		boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
		backgroundClip: "padding-box",
		margin: "2px 0 0",
		fontSize: "14px",
		textAlign: "left",
		listStyle: "none",
		backgroundColor: "transparent",
		maxHeight: "266px"
	},
	selectSearchBox: {
		minWidth: "250px",
		[DARK_MODE_SELECTOR]: { backgroundColor: COLOR.DARK_1 }
	}
};

export default customSelectStyle;

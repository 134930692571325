import { AssetType } from "models/Asset";
import { ActionType } from "actions/ActionType";

const BASE_URL = "/api/reports";

const fetchReportBase = type => ({
	type: ActionType.API_REQUEST,
	method: "get",
	setFieldOnObject: {
		key: "type",
		value: type,
	},
});

export const fetchChallengeReportBase = {
	...fetchReportBase(AssetType.CHALLENGE),
	resource: "reportChallenges",
};
export const fetchQuizReportBase = {
	...fetchReportBase(AssetType.QUIZ),
	resource: "reportQuizzes",
};
export const fetchTheoriesReportBase = {
	...fetchReportBase(AssetType.THEORY),
	resource: "reportTheories",
};

const fetchOwnUserChallengesReport = () => ({
	...fetchChallengeReportBase,
	url: `${BASE_URL}/user/challenges/`,
});

const fetchOwnUserQuizReport = () => ({
	...fetchQuizReportBase,
	url: `${BASE_URL}/user/quizzes/`,
});

const fetchOwnUserTheoriesReport = () => ({
	...fetchTheoriesReportBase,
	url: `${BASE_URL}/user/theories/`,
});

const fetchUserChallengeReportsForTeam = teamId => ({
	...fetchChallengeReportBase,
	url: `${BASE_URL}/teams/${teamId}/challenges/`,
});

const fetchUserQuizReportsForTeam = teamId => ({
	...fetchQuizReportBase,
	url: `${BASE_URL}/teams/${teamId}/quizzes/`,
});

const fetchUserTheoriesReportsForTeam = teamId => ({
	...fetchTheoriesReportBase,
	url: `${BASE_URL}/teams/${teamId}/theories/`,
});

const fetchClasses = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/`,
	method: "get",
	resource: "reportClasses",
});

const fetchTeams = classId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/${classId}`,
	method: "get",
	resource: "reportTeams",
	updatePropForArray: {
		key: "classId",
		value: classId,
	},
});

const fetchAllTeams = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/`,
	method: "get",
	resource: "reportTeams",
});

const fetchTeamUsers = teamId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}`,
	method: "get",
	resource: "reportUsers",
});

const fetchClassUsers = classId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/${classId}/users/`,
	method: "get",
	resource: "reportUsers",
});

const fetchSolution = solutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/solutions/${solutionId}`,
	method: "get",
	resource: "reportSolutions",
	onSuccess: (dispatch, solution) => {
		solution.id && dispatch(fetchSolutionComments(solution.id));
		if (solution.challenge && solution.challenge.id)
			dispatch(fetchGradingInstructions(solution.challenge.id));
	},
});

const fetchSolutionComments = solutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/solutions/${solutionId}/comments/`,
	method: "get",
	resource: "reportSolutionComments",
	updatePropForArray: {
		key: "solutionId",
		value: solutionId,
	},
});

const fetchGradingInstructions = challengeId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/challenges/${challengeId}/gradingInstruction`,
	method: "get",
	resource: "reportGradingInstruction",
});

const fetchQuizSolution = quizSolutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizSolutions/${quizSolutionId}`,
	method: "get",
	resource: "reportQuizSolutions",
});

const fetchQuizAnswers = quizSolutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizSolutions/${quizSolutionId}/answers/`,
	method: "get",
	resource: "reportQuizAnswers",
	setFieldOnObject: {
		key: "quizSolutionId",
		value: quizSolutionId,
	},
});

const fetchQuizGradingInstructions = quizSolutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizSolutions/${quizSolutionId}/gradingInstructions/`,
	method: "get",
	resource: "reportQuizGradingInstructions",
	setFieldOnObject: {
		key: "quizSolutionId",
		value: quizSolutionId,
	},
});

const fetchQuizComments = quizSolutionId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/quizSolutions/${quizSolutionId}/comments/`,
	method: "get",
	resource: "reportQuizComments",
	setFieldOnObject: {
		key: "quizSolutionId",
		value: quizSolutionId,
	},
});

export default {
	fetchOwnUserChallengesReport,
	fetchOwnUserQuizReport,
	fetchOwnUserTheoriesReport,
	fetchUserChallengeReportsForTeam,
	fetchUserQuizReportsForTeam,
	fetchUserTheoriesReportsForTeam,
	fetchClasses,
	fetchTeams,
	fetchAllTeams,
	fetchTeamUsers,
	fetchClassUsers,
	fetchSolution,
	fetchGradingInstructions,
	fetchQuizSolution,
	fetchQuizAnswers,
	fetchQuizGradingInstructions,
	fetchQuizComments,
};

import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import managerApi from "actions/manager";
import { formStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamCreateStyles";
import { filterInputStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamOverviewStyle";
import IconCard from "components/Cards/IconCard";
import TableIconButton from "components/CustomButtons/TableIconButton";
import CustomInput from "components/CustomInput/CustomInput";
import ModalWindow from "components/ModalWindow/ModalWindow";
import NoData from "components/NoData/NoData";
import EnhancedTable from "components/Table/EnhancedTable";
import applyFilter from "helper/applyFilter";
import { withIntl } from "@hlcr/ui/Intl";
import { ProfileEditor } from "userProfile/components/ProfileEditor";


const Actions = ({ intl, user, onEdit, isDisabled }) => (
	<React.Fragment>
		<TableIconButton
			onClick={() => {
				onEdit(user);
			}}
			disabled={isDisabled}
			title={intl.fm("user.profile.edit")}
			color="info"
			faIcon="pencil-alt"
		/>
	</React.Fragment>
);

const createTableRenderer = (intl, onEdit, isDisabled) => [
	{
		id: "username",
		title: intl.fm("user.label.username"),
		renderCell: user => user.username,
		sort: true
	},
	{
		id: "email",
		title: intl.fm("user.label.email"),
		renderCell: user => user.email,
		sort: true
	},
	{
		id: "actions",
		title: intl.fm("common.labels.actions"),
		renderCell: user => (
			<Actions
				intl={intl}
				user={user}
				onEdit={onEdit}
				isDisabled={isDisabled}
			/>
		)
	}
];

const UsersTable = ({ users, onEdit, intl, isDisabled }) => {
	return (
		<EnhancedTable
			tableRenderer={createTableRenderer(intl, onEdit, isDisabled)}
			tableData={users}
			hover
			emptyContent={<NoData text={intl.fm("user.noUsers")} />}
			paginate={50}
		/>
	);
};

UsersTable.defaultProps = { users: [] };

const FilterInput = ({
	onFilterInputChange,
	placeholder,
	formControlClassName
}) => (
	<CustomInput
		inputProps={{
			type: "inputSearch",
			onChange: onFilterInputChange,
			placeholder
		}}
		formControlProps={{ className: formControlClassName }}
	/>
);

class ManagerUsers extends React.Component {
	state = {
		filterQuery: "",
		isModalOpen: false,
		selectedUser: null
	};

	componentDidMount() {
		const { fetchManagerTeachers, fetchManagerStudents } = this.props;
		fetchManagerTeachers();
		fetchManagerStudents();
	}

	closeModal = () => {
		this.setState({ isModalOpen: false, selectedUser: null });
	};

	onFilterInputChange = event => {
		const { filterQuery } = this.state;
		const { value } = event.currentTarget;

		if (filterQuery !== value)
			this.setState({ filterQuery: value });
	};

	onOpenEdit = user => {
		this.props.fetchUserProfile(user.userIdentifier);
		this.setState({
			isModalOpen: true,
			selectedUser: user
		});
	};

	onSubmitProfile = profile => {
		this.props.updateUserProfile(
			this.state.selectedUser.userIdentifier,
			profile
		);
		this.setState({ isModalOpen: false });
	};

	render() {
		const { users, profiles, classes, intl } = this.props;
		const { filterQuery, selectedUser, isModalOpen } = this.state;
		const filteredUsers = applyFilter(users, filterQuery, [
			"username",
			"email"
		]);
		const profile =
			selectedUser &&
			profiles.find(p => p.userIdentifier === selectedUser.userIdentifier);

		return (
			<div>
				<IconCard
					faIcon="user"
					iconColor="purple"
					title={
						<div>
							{intl.fm("user.label.users")}
							<FilterInput
								onFilterInputChange={this.onFilterInputChange}
								placeholder={intl.fm("common.labels.filter")}
								formControlClassName={classes.formControl}
							/>
						</div>
					}
					content={
						<UsersTable
							onEdit={this.onOpenEdit}
							users={filteredUsers}
							intl={intl}
						/>
					}
				/>
				<ProfileModal
					profile={profile}
					isModalOpen={isModalOpen && !!profile}
					onClose={this.closeModal}
					onSubmitProfile={this.onSubmitProfile}
					intl={intl}
				/>
			</div>
		);
	}
}

let ProfileModal = props => {
	const { profile, isModalOpen, onClose, onSubmitProfile, intl } = props;
	return (
		<ModalWindow
			open={isModalOpen}
			onClose={onClose}
			title={`${intl.fm("user.profile.edit")}: ${
				profile ? `${profile.username} (${profile.email})` : ""
			}`}
			fullWidth
		>
			{profile ? (
				<ProfileEditor
					userProfile={profile}
					onSave={onSubmitProfile}
					noCard
				/>
			) : (
				<NoData />
			)}
		</ModalWindow>
	);
};

ProfileModal = withStyles(formStyles)(ProfileModal);

const getUniqueIdArray = (array, uniqueField) => {
	const unique = {};
	const distinct = [];
	for (const i in array) {
		if (typeof unique[array[i][uniqueField]] === "undefined") {
			distinct.push(array[i]);
		}
		unique[array[i][uniqueField]] = 0;
	}
	return distinct;
};

const mapStateToProps = state => {
	const { managerTeachers, managerStudents } = state.api.resources;
	let users = managerTeachers.data.concat(managerStudents.data);
	users = getUniqueIdArray(users, "userIdentifier");

	return {
		profiles: state.api.resources.managerProfiles.data,
		users: users.sort((a, b) => a.username > b.username),
		isLoading: state.api.resources.managerProfiles.pending,
		match: undefined,
		location: undefined
	};
};

const mapDispatchToProps = {
	fetchManagerTeachers: managerApi.fetchManagerTeachers,
	fetchManagerStudents: managerApi.fetchManagerStudents,
	fetchUserProfile: managerApi.fetchUserProfile,
	updateUserProfile: managerApi.updateUserProfile
};

export default compose(
	withIntl,
	withStyles(filterInputStyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(ManagerUsers);

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import reportApi from "../../actions/report";
import teacherSolutionStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teacherSolutionStyle";
import { withIntl } from "@hlcr/ui/Intl";
import { TeacherSolutionRaw } from "../Teacher/TeacherSolution";
import React from "react";

const mapStateToProps = (state, ownProps) => {
	const solutionId = Number(ownProps.match.params.solutionId);
	const solution = state.api.resources.reportSolutions.data.find(
		sol => sol.id === solutionId
	);

	return {
		solutionId,
		solution,
		disabled: true,
		solutionComments:
			state.api.resources.reportSolutionComments.data.filter(
				comment => comment.solutionId === solutionId
			) || [],
		gradingInstruction: state.api.resources.reportGradingInstruction.data.find(
			grading =>
				solution && solution.challenge && solution.challenge.id === grading.id
		),
		isLoadingSolution: state.api.resources.reportSolutions.pending
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchTeacherSolution: reportApi.fetchSolution,
		},
		dispatch,
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(teacherSolutionStyle),
	withIntl
)(TeacherSolutionRaw);

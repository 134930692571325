import moment from "moment";
import React from "react";

import { printDuration } from "helper/dateCalc";
import { withIntl } from "@hlcr/ui/Intl";

const timerrefresh = 1000;
class Timer extends React.Component {
	state = { endMoment: null };

	componentDidMount() {
		this.interval = setInterval(this.refresh, timerrefresh);
	}
	componentWillUnmount() {
		clearInterval(this.interval);
	}

	static getDerivedStateFromProps(nextProps) {
		if (nextProps.endDate) {
			return { endMoment: moment(nextProps.endDate) };
		}
		return { endMoment: null };
	}

	refresh = () => {
		const { endMoment } = this.state;
		if (endMoment) this.forceUpdate();
	};

	render() {
		const { intl, maxDurationEntries } = this.props;
		const { endMoment } = this.state;
		if (endMoment) {
			const secondsLeft = endMoment.diff(moment()) / 1000;
			return (
				<React.Fragment>
					{" "}
					{printDuration(secondsLeft, intl, maxDurationEntries)}
				</React.Fragment>
			);
		} else return null;
	}
}

export default withIntl(Timer);

import { useIntl } from "@hlcr/ui/Intl";
import { createStyles, makeStyles } from "@material-ui/core";
import { ChangeEvent } from "react";

import CustomInput from "components/CustomInput/CustomInput";
import ValidationInput from "components/CustomInput/ValidationInput";
import { AnswerBaseProps } from "components/Quiz/AnswerBaseProps";
import { checkShortAnswer, getInputValidationState } from "helper/quiz";
import { QuestionAnswer, QuestionType } from "models/Quizzes";


interface ShortAnswerQuestionProps extends AnswerBaseProps {
}

export const ShortAnswerQuestion = ({ question, disabled, gradingInstruction, isPreview, onChange, answer, questionState }: ShortAnswerQuestionProps) => {
	const classes = useStyles();
	const intl = useIntl();

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		const updatedAnswer: QuestionAnswer = {
			...answer,
			type: QuestionType.SHORT_ANSWER,
			questionUuid: question.id,
			content: value,
		};

		onChange(updatedAnswer);
	};

	if (gradingInstruction)
		return (
			<div>
				{!isPreview && <ValidationInput
					disabled={disabled}
					label={intl.fm("quiz.shortAnswer.label")}
					value={answer?.content}
					fullWidth
					initState={getInputValidationState(questionState, isPreview)}
					onChange={handleOnChange}
					validations={{ custom: (value: string) => checkShortAnswer(gradingInstruction, value) }}
					formControlProps={{ classes: { root: classes.textField } }}
				/>}
				<br />
				<div className={classes.possibleAnswersTitle}>
					{intl.fm("quiz.shortAnswer.possibleAnswers")}
				</div>
				<div>
					{gradingInstruction.shortAnswers && gradingInstruction.shortAnswers.join(", ")}
				</div>
			</div>
		);

	return (
		<CustomInput
			labelText={intl.fm("quiz.shortAnswer.label")}
			inputProps={{
				value: answer?.content,
				onChange: handleOnChange,
				disabled,
			}}
			formControlProps={{ classes: { root: classes.textField } }}
		/>
	);
};

const useStyles = makeStyles(
	() => createStyles(
		({
			textField: { width: 400 },
			possibleAnswersTitle: { fontWeight: 600 },
		}),
	),
);

export const formStyles = {
	actions: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	},
	label: {
		cursor: "pointer",
		paddingLeft: "0",
		color: "#AAAAAA",
		fontSize: "10.5px",
		lineHeight: "1.428571429",
		fontWeight: "400",
		display: "inline-flex"
	}
};

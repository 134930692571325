import { DARK_MODE_CLASS } from "@hlcr/mui/theme/hacking-lab.theme";

export { COLOR, DARK_MODE_CLASS, DARK_MODE_SELECTOR } from "@hlcr/mui/theme/hacking-lab.theme" ;

const isClassSet = body => body.className.includes(DARK_MODE_CLASS);

export const setDarkModeClass = darkMode => {
	const body = document.getElementsByTagName("body")[0];
	if (!body) return;

	if (darkMode && !isClassSet(body))
		body.className += ` ${DARK_MODE_CLASS}`.trim();

	if (!darkMode && isClassSet(body))
		body.className = body.className.replace(DARK_MODE_CLASS, "").trim();
};

import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { Button } from "@hlcr/mui/Button";
import ValidationInput from "components/CustomInput/ValidationInput";
import { Upload } from "@hlcr/mui/Upload";
import ModalWindow from "components/ModalWindow/ModalWindow";

import managerApi from "../../actions/manager";
import { formStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamCreateStyles";
import { getDateInputValue } from "helper/dateCalc";
import { getImageData } from "helper/manager";
import { endDateIsGreaterValidation } from "helper/validations";
import { withIntl } from "@hlcr/ui/Intl";
import { fetchHelp } from "variables/helpPage";

const initialForm = {
	name: "",
	description: "",
	startTime: "",
	endTime: ""
};

class ManagerCreateClassModal extends Component {
	state = {
		form: initialForm,
		image: undefined,
		validation: {
			name: false,
			startTime: false,
			endTime: true,
			description: true
		}
	};

	_handleFormReset = () => {
		this.setState({
			form: initialForm,
			image: undefined,
			validation: {
				name: false,
				startTime: false,
				endTime: true,
				description: true
			}
		});
	};

	updateField = fieldName => (value, valid) => {
		this.setState(state => ({
			form: { ...state.form, [fieldName]: value },
			validation: { ...state.validation, [fieldName]: valid }
		}));
	};

	_handleImageChange = file => {
		this.setState({ image: file });
	};

	_redirectToEditPage = classId => {
		const { history } = this.props;
		history.push(`/manager/classes/${classId}`);
	};

	_handleSubmit = () => {
		const { addClass } = this.props;
		const {
			form: { name, description, startTime, endTime },
			image
		} = this.state;

		const managerClass = {
			id: 0,
			name,
			description,
			startTime,
			endTime
		};
		const imageBytes = getImageData(image);
		addClass(managerClass, imageBytes, this._redirectToEditPage);
	};

	_handleClose = () => {
		const { onClose } = this.props;
		this._handleFormReset();
		onClose();
	};

	render() {
		const { open, intl, classes, helpUrl } = this.props;
		const { form, image, validation } = this.state;

		const isFullValid = Object.values(validation).reduce(
			(res, value) => res && value,
			true
		);

		const startTime = getDateInputValue(form.startTime);
		const endTime = getDateInputValue(form.endTime);

		return (
			<ModalWindow
				open={open}
				onClose={this._handleClose}
				title={intl.fm("manager.classes.create")}
				helpLink={fetchHelp(helpUrl, "manager", "createClass")}
				actionSection={
					<div className={classes.actions}>
						<Button onClick={this._handleFormReset} color="defaultNoBackground">
							{intl.fm("common.labels.clearForm")}
						</Button>
						<Button
							onClick={this._handleSubmit}
							disabled={!isFullValid}
							color="infoNoBackground"
						>
							{intl.fm("common.labels.submit")}
						</Button>
					</div>
				}
				fullWidth
				disableOverflow
			>
				<form>
					<ValidationInput
						label={intl.fm("class.field.name")}
						value={form.name}
						onChange={this.updateField("name")}
						inputClasses={classes.inputOverflow}
						validations={{ required: true, minLength: 1, maxLength: 100 }}
						fullWidth
					/>
					<ValidationInput
						type="date"
						value={startTime}
						onChange={this.updateField("startTime")}
						label={intl.fm("common.labels.chooseBeginDate")}
						validations={{ required: true }}
					/>
					<ValidationInput
						type="date"
						value={endTime}
						onChange={this.updateField("endTime")}
						label={intl.fm("common.labels.chooseEndDate")}
						validations={{
							custom: endDateIsGreaterValidation(
								form.startTime,
								validation.startTime
							)
						}}
					/>
					<ValidationInput
						type="text"
						value={form.description}
						onChange={this.updateField("description")}
						label={intl.fm("class.field.description")}
						validations={{ maxLength: 500 }}
						inputProps={{
							multiline: true,
							rows: 4
						}}
						fullWidth
					/>
					<Upload type="image" handleProcessedFile={this._handleImageChange} file={image} />
				</form>
			</ModalWindow>
		);
	}
}

const mapStateToProps = state => ({ helpUrl: state.branding.helpUrl });

export default compose(
	connect(mapStateToProps),
	withStyles(formStyles),
	withRouter,
	withIntl,
	connect(
		undefined,
		{ addClass: managerApi.addClass }
	)
)(ManagerCreateClassModal);

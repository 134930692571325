import get from "lodash/get";

import requiredParam from "../helper/requiredParam";

const applyFilter = (
	filterable = requiredParam("filterable"),
	filter = "",
	paths = requiredParam("paths")
) => {
	if (!filter || !filterable.length) return filterable;

	return filterable.filter(obj => {
		return paths.some(path => {
			const value = get(obj, path, "");
			if (typeof value !== "string" && typeof value !== "number")
				throw new Error(
					`Provided path must resolve in a string or number,
          instead resolved to type ${typeof value}`
				);
			return value
				.toString()
				.toUpperCase()
				.includes(filter.toUpperCase());
		});
	});
};

export default applyFilter;

import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import teacherApi from "actions/teacher";
import { filterInputStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamOverviewStyle";
import IconCard from "components/Cards/IconCard";
import CustomInput from "components/CustomInput/CustomInput";
import NoData from "components/NoData/NoData";
import { ReportButton, ReportLink } from "components/Report/ReportComponents";
import EnhancedTable from "components/Table/EnhancedTable";
import applyFilter from "helper/applyFilter";
import { getDateDiff, printDuration } from "helper/dateCalc";
import { printPercentage } from "helper/pointPrint";
import { withIntl } from "@hlcr/ui/Intl";

const participantsTableStyle = {
	textOverFlow: {
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		position: "absolute",
		left: 8,
		right: 8,
		top: "50%",
		transform: "translateY(-50%)"
	}
};

let ParticipantsTable = ({ participants, eventId, intl, classes }) => {
	const tableRenderer = [
		{
			id: "username",
			title: intl.fm("user.label.username"),
			renderCell: member => member.profile.username,
			colStyle: { width: 200 },
			sort: true
		},
		{
			id: "skills",
			title: intl.fm("user.label.skills"),
			renderCell: member => member.profile.skills,
			colStyle: { width: "50%" },
			sort: true
		},
		{
			id: "teacherNotes",
			title: intl.fm("participant.teacherNotes"),
			renderCell: member => (
				<p className={classes.textOverFlow}>{member.teacherNotes}</p>
			),
			colStyle: { width: "50%" },
			sort: (a, b) => (a.teacherNotes < b.teacherNotes ? -1 : 1)
		},
		{
			id: "action",
			title: intl.fm("common.labels.action"),
			renderCell: member => (
				<React.Fragment>
					<ReportLink
						label={intl.fm("navigation.entries.user.profile")}
						to={`/teacher/events/${eventId}/participants/${member.profile.id}`}
					/>
				</React.Fragment>
			),
			colStyle: { width: 150 }
		}
	];

	return (
		<EnhancedTable
			tableRenderer={tableRenderer}
			tableData={participants}
			hover
			emptyContent={<NoData text={intl.fm("user.noUsers")} />}
			paginate={50}
		/>
	);
};

ParticipantsTable = withStyles(participantsTableStyle)(ParticipantsTable);

const ProgressTable = ({ progressEntries, eventId, intl }) => {
	const tableRenderer = [
		{
			id: "username",
			title: intl.fm("user.label.username"),
			renderCell: progress => progress.username,
			sort: true
		},
		{
			id: "submitted",
			title: intl.fm("teacher.participants.submitted"),
			renderCell: progress =>
				`${progress.submittedUnitCount} / ${progress.totalUnitCount}`,
			sort: (a, b) => (a.submittedUnitCount < b.submittedUnitCount ? 1 : -1),
			align: "right"
		},
		{
			id: "totalPoints",
			title: intl.fm("teacher.participants.totalPoints"),
			renderCell: progress =>
				printPercentage(progress.points, progress.maxPoints),
			sort: (a, b) => b.points - a.points,
			align: "right"
		},
		{
			id: "examDuration",
			title: intl.fm("teacher.participants.examDuration"),
			renderCell: progress =>
				printDuration(
					getDateDiff(
						progress.timeLimitedStartDate,
						progress.timeLimitedEndDate
					),
					intl
				),
			sort: (a, b) =>
				getDateDiff(a.timeLimitedStartDate, b.timeLimitedEndDate) <
				getDateDiff(b.timeLimitedStartDate, b.timeLimitedEndDate)
					? 1
					: -1
		}
	];

	return (
		<EnhancedTable
			tableRenderer={tableRenderer}
			tableData={progressEntries}
			hover
			emptyContent={<NoData text={intl.fm("user.noUsers")} />}
			paginate={50}
		/>
	);
};

const FilterInput = ({
	onFilterInputChange,
	placeholder,
	formControlClassName
}) => (
	<CustomInput
		inputProps={{
			type: "inputSearch",
			onChange: onFilterInputChange,
			placeholder
		}}
		formControlProps={{ className: formControlClassName }}
	/>
);

const styles = {
	...filterInputStyles,
	titleButton: { marginLeft: 12 }
};

class EventParticipants extends React.Component {
	state = {
		filterQuery: "",
		progress: true
	};

	componentDidMount() {
		const {
			eventId,
			fetchEventParticipants,
			fetchParticipantProgress,
			fetchTeacherEvent
		} = this.props;
		fetchEventParticipants(eventId);
		fetchTeacherEvent(eventId);
		fetchParticipantProgress(eventId);
	}

	onFilterInputChange = event => {
		const { filterQuery } = this.state;
		const { value } = event.currentTarget;

		if (filterQuery !== value)
			this.setState({ filterQuery: value });
	};

	onSwitchView = () => this.setState(state => ({ progress: !state.progress }));

	render() {
		const { participants, progressEntries, eventId, classes, intl } = this.props;
		const { filterQuery, progress } = this.state;
		const filteredData = progress
			? applyFilter(progressEntries, filterQuery, [ "username" ])
			: applyFilter(participants, filterQuery, [
				"profile.username",
				"profile.email"
			  ]);

		return (
			<div>
				<IconCard
					faIcon="user"
					iconColor="purple"
					title={
						<div>
							{intl.fm("user.label.users")}
							<span className={classes.titleButton}>
								<ReportButton
									label={
										progress
											? intl.fm("teacher.participants.userProfile")
											: intl.fm("teacher.participants.userProgress")
									}
									onClick={this.onSwitchView}
									noBackground
								/>
							</span>
							<FilterInput
								onFilterInputChange={this.onFilterInputChange}
								placeholder={intl.fm("common.labels.filter")}
								formControlClassName={classes.formControl}
							/>
						</div>
					}
					content={
						progress ? (
							<ProgressTable
								eventId={eventId}
								progressEntries={filteredData}
								intl={intl}
							/>
						) : (
							<ParticipantsTable
								eventId={eventId}
								participants={filteredData}
								intl={intl}
							/>
						)
					}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const eventId = Number(ownProps.match.params.eventId);
	return {
		eventId,
		participants: state.api.resources.teacherEventParticipants.data.filter(
			p => p.eventId === eventId
		),
		progressEntries: state.api.resources.teacherEventProgress.data.filter(
			p => p.eventId === eventId
		),
		isLoading: state.api.resources.teacherEventParticipants.pending
	};
};

const mapDispatchToProps = {
	fetchEventParticipants: teacherApi.fetchEventParticipants,
	fetchParticipantProgress: teacherApi.fetchParticipantProgress,
	fetchTeacherEvent: teacherApi.fetchTeacherEvent
};

export default compose(
	withIntl,
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(EventParticipants);

import { HackingLabRoute, HackingLabRouteType } from "@hlcr/app/enum/EnumRoute";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import SchoolIcon from "@material-ui/icons/School";

import { EventDetailsRouteParams } from "routes/eventRoutes";
import EventParticipants from "views/Teacher/EventParticipants";
import ParticipantProfile from "views/Teacher/ParticipantProfile";
import Teacher from "views/Teacher/Teacher";
import TeacherChallengePreview, { TeacherChallengePreviewBreadCrumb } from "views/Teacher/TeacherChallengePreview";
import TeacherEvent, { TeacherEventBreadCrumb } from "views/Teacher/TeacherEvent";
import { TeacherEventDetails } from "views/Teacher/TeacherEventDetails";
import { TeacherQuiz, TeacherQuizBreadCrumb } from "views/Teacher/TeacherQuiz";
import TeacherQuizPreview, { TeacherQuizPreviewBreadCrumb } from "views/Teacher/TeacherQuizPreview";
import { TeacherSolution, TeacherSolutionBreadCrumb } from "views/Teacher/TeacherSolution";
import TheacherTheoryPreview, { TeacherTheoryPreviewBreadCrumb } from "views/Teacher/TeacherTheoryPreview";

import * as intl from "./localization";

export interface TeacherEventDetailsRouteParams extends EventDetailsRouteParams {
}

export const teacherRoutes: HackingLabRoute[] = [
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teacher",
		name: intl.Teacher,
		icon: SchoolIcon,
		component: Teacher,
		hasSidebarLink: true,
		needsRole: HackingLabRole.COMPOSITE_TEACHER,
		breadcrumb: "Teacher",
		help: { role: "teacher", action: "pageEvents" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teacher/events/:eventId",
		name: intl.Teacher + " Event Details",
		component: TeacherEvent,
		needsRole: HackingLabRole.COMPOSITE_TEACHER,
		breadcrumb: TeacherEventBreadCrumb,
		help: { role: "teacher", action: "pageEventDetails" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teacher/events/:eventId/participants",
		component: EventParticipants,
		needsRole: HackingLabRole.COMPOSITE_TEACHER,
		breadcrumb: intl.Participants,
		help: { role: "teacher", action: "pageEventParticipants" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teacher/events/:eventId/participants/:userId",
		component: ParticipantProfile,
		needsRole: HackingLabRole.COMPOSITE_TEACHER,
		breadcrumb: intl.Profile,
		help: { role: "teacher", action: "pageEventParticipants" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teacher/events/:eventId/units",
		component: TeacherEventDetails,
		needsRole: HackingLabRole.COMPOSITE_TEACHER,
		breadcrumb: intl.Preview,
		help: { role: "user", action: "pageEvents" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teacher/events/:eventId/units/:unitId/theory",
		component: TheacherTheoryPreview,
		needsRole: HackingLabRole.COMPOSITE_TEACHER,
		breadcrumb: TeacherTheoryPreviewBreadCrumb,
		help: { role: "user", action: "pageTheory" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teacher/events/:eventId/units/:unitId/challenge",
		component: TeacherChallengePreview,
		needsRole: HackingLabRole.COMPOSITE_TEACHER,
		breadcrumb: TeacherChallengePreviewBreadCrumb,
		help: { role: "user", action: "pageChallenge" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teacher/events/:eventId/units/:unitId/quiz",
		component: TeacherQuizPreview,
		needsRole: HackingLabRole.COMPOSITE_TEACHER,
		breadcrumb: TeacherQuizPreviewBreadCrumb,
		help: { role: "user", action: "pageQuiz" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teacher/events/:eventId/solutions/:solutionId",
		name: intl.Teacher + " Solution",
		component: TeacherSolution,
		needsRole: HackingLabRole.COMPOSITE_TEACHER,
		breadcrumb: TeacherSolutionBreadCrumb,
		help: { role: "teacher", action: "pageChallengeSolution" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teacher/events/:eventId/units/:unitId/solutions",
		name: intl.Teacher + " Solution",
		component: TeacherSolution,
		needsRole: HackingLabRole.COMPOSITE_TEACHER,
		breadcrumb: TeacherSolutionBreadCrumb,
		help: { role: "teacher", action: "pageChallengeSolution" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/teacher/events/:eventId/quizSolutions/:solutionId",
		name: intl.Teacher + " QuizSolution",
		component: TeacherQuiz,
		needsRole: HackingLabRole.COMPOSITE_TEACHER,
		breadcrumb: TeacherQuizBreadCrumb,
		help: { role: "teacher", action: "pageQuizSolution" },
	},
];

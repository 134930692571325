import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators, compose } from "redux";

import eventsApi from "actions/events";
import teacherApi from "actions/teacher";
import eventUnitsStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/eventUnitsStyle";
import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import NoData from "components/NoData/NoData";
import { checkDatePendent, formatFullDate, printDuration } from "helper/dateCalc";
import { formatMessage, withIntl } from "@hlcr/ui/Intl";
import StartExam from "views/Events/StartExam";
import UnitList from "views/Events/UnitList";
import { ASSET_TYPES } from "models/Asset";

class EventUnits extends React.Component {
	state = {
		isModalOpen: false,
		selectedUnit: undefined
	};

	render() {
		const { event, isLoading, isPreview } = this.props;

		if (!event) return isLoading ? <LoadingSpinner /> : <NoData />;

		if (!isPreview && checkDatePendent(event.startTime))
			return <EventPendent {...this.props} />;

		if (!isPreview && event.exam && !event.timeLimitedStartDate)
			return <StartExam onStartEvent={this.onStartEvent} event={event} />;

		return (
			<UnitList
				{...this.props}
				{...this.state}
				closeModal={this.closeModal}
				openModal={this.openModal}
				onSubmitProposal={this.onSubmitProposal}
			/>
		);
	}

	onStartEvent = () => {
		const { startEvent, eventId } = this.props;
		startEvent(eventId);
	};

	closeModal = () => this.setState({ isModalOpen: false });

	openModal = unit => () =>
		this.setState({ isModalOpen: true, selectedUnit: unit });

	onSubmitProposal = issue => {
		const { intl, postTeacherSuggestion } = this.props;
		const msg = intl.fm("teacher.proposal.msg");

		this.setState({ isModalOpen: false }, () => {
			postTeacherSuggestion(issue, msg);
		});
	};
}

const EventPendent = ({ event, intl }) => (
	<IconCard
		icon={ASSET_TYPES.QUIZ.icon}
		color="red"
		title={`${
			event.exam ? intl.fm("event.exam.title") : intl.fm("event.entityName")
		} ${intl.fm("event.notStartedYet")}`}
		content={
			<div>
				{formatMessage("event.notStartedYetText", null, { startDate: <b>{formatFullDate(event.startTime, intl)}</b> })}{" "}
				{event.exam && event.timeLimitInSeconds
					? formatMessage("event.exam.startExamDuration", null, {
						duration: <b>{printDuration(event.timeLimitInSeconds, intl)}</b>,
						endDate: <b>{formatFullDate(event.endTime, intl)}</b>
					  })
					: event.exam &&
					  formatMessage("event.exam.startExamNoDuration", null, { endDate: <b>{formatFullDate(event.endTime, intl)}</b> })}
				<div>
					<Link to="/events">
						<Button color="defaultNoBackground">
							{intl.fm("common.labels.cancel")}
						</Button>
					</Link>
				</div>
			</div>
		}
	/>
);

EventUnits.propTypes = {
	isPreview: PropTypes.bool,
	eventId: PropTypes.number.isRequired,
	parentId: PropTypes.number,
	event: PropTypes.object,
	units: PropTypes.arrayOf(PropTypes.object).isRequired,
	isLoading: PropTypes.bool.isRequired,
	startEvent: PropTypes.func.isRequired,
	postTeacherSuggestion: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			startEvent: eventsApi.startEvent,
			postTeacherSuggestion: teacherApi.postTeacherSuggestion
		},
		dispatch
	);

export default compose(
	connect(
		undefined,
		mapDispatchToProps
	),
	withStyles(eventUnitsStyle),
	withIntl
)(EventUnits);

import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TeamIcon from "@material-ui/icons/Group";
import ViewIcon from "@material-ui/icons/Visibility";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { createSelector } from "reselect";

import managerApi from "actions/manager";
import { actionStyles, filterInputStyles, floatingActionButtonStyles, tableSpecialHighligtingStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamOverviewStyle";
import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import IconButton from "components/CustomButtons/IconButton";
import CustomInput from "components/CustomInput/CustomInput";
import WarningDialog from "components/ModalWindow/WarningDialog";
import NoData from "components/NoData/NoData";
import EnhancedTable from "components/Table/EnhancedTable";
import applyFilter from "helper/applyFilter";
import { withIntl } from "@hlcr/ui/Intl";
import ManagerCreateTeamModal from "views/Manager/Teams/ManagerCreateTeamModal";

const enhanceActions = compose(withStyles(actionStyles));

const Actions = enhanceActions(({ classes, team, removeTeam }) => {
	const disabledDelete = team.memberCount > 0 || team.archived;
	const classDelete = disabledDelete ? classes.buttonDisabled : classes.button;

	return (
		<React.Fragment>
			<Link to={`/manager/teams/${team.id}`}>
				<Button color="info" customClass={classes.button}>
					{team.archived ? (<ViewIcon className={classes.icon} />) : (<EditIcon className={classes.icon} />)}
				</Button>
			</Link>
			{!team.archived && (
				<Button onClick={() => removeTeam(team)} disabled={disabledDelete} color="danger" customClass={classDelete}>
					<DeleteIcon className={classes.icon} />
				</Button>
			)}
		</React.Fragment>
	);
});

const createTableRenderer = (removeTeam, intl) => [
	{
		id: "name",
		title: intl.fm("team.label.name"),
		renderCell: team => team.name + (team.archived ? ` (${intl.fm("common.labels.archived")})` : ""),
		sort: true
	},
	{
		id: "description",
		title: intl.fm("team.label.description"),
		renderCell: team => team.description,
		sort: true
	},
	{
		id: "class",
		title: intl.fm("team.label.class"),
		renderCell: team => team.teamClass && team.teamClass.name + (team.teamClass.archived ? ` (${intl.fm("common.labels.archived")})` : ""),
		sort: true
	},
	{
		id: "membercount",
		title: intl.fm("team.label.membercount"),
		renderCell: team => team.memberCount,
		sort: true,
		align: "right"
	},
	{
		id: "leader",
		title: intl.fm("team.label.leader"),
		renderCell: team => team.leader,
		sort: true
	},
	{
		id: "actions",
		title: intl.fm("team.label.actions"),
		renderCell: team => <Actions team={team} removeTeam={removeTeam} />
	}
];

const enhanceTable = compose(withStyles(tableSpecialHighligtingStyles));
const TeamTable = enhanceTable(({ classes, teams, removeTeam, intl }) => (
	<EnhancedTable
		classes={classes}
		tableRenderer={createTableRenderer(removeTeam, intl)}
		tableData={teams}
		disabledRow={team => team.archived}
		hover
		emptyContent={<NoData text={intl.fm("team.noResources")} />}
	/>
)
);

TeamTable.defaultProps = { teams: [] };

const TeamFilterInput = ({ onFilterInputChange, placeholder, formControlClassName }) => (
	<CustomInput inputProps={{ type: "inputSearch", onChange: onFilterInputChange, placeholder }} formControlProps={{ className: formControlClassName }} />
);

const CreateTeamButton = withStyles(floatingActionButtonStyles)(
	({ onCreateTeam, classes }) => (
		<IconButton onClick={onCreateTeam} color="primary" aria-label="add" customClass={classes.button}>
			<AddIcon className={classes.iconPosition} />
		</IconButton>
	)
);

class ManagerTeams extends React.Component {
	state = {
		filterQuery: "",
		showCreateDialog: false,
		deleteOpen: false,
		deleteTeam: undefined
	};

	componentDidMount() {
		this.props.fetchManagerTeamsWithMembers();
		this.props.fetchManagerClasses();
	}

	onCancelTeamCreation = () => {
		this.setState({ showCreateDialog: false });
	};

	onCreateTeam = () => {
		this.setState({ showCreateDialog: true });
	};

	onFilterInputChange = event => {
		const { filterQuery } = this.state;
		const { value } = event.currentTarget;

		if (filterQuery !== value) {
			this.setState({ filterQuery: value });
		}
	};

	cancelDelete = () => {
		this.setState({ deleteOpen: false });
	};

	confirmDelete = deleteTeam => {
		this.setState({ deleteOpen: true, deleteTeam });
	};

	delete = () => {
		const { removeTeam } = this.props;
		const { deleteTeam } = this.state;
		this.setState({ deleteOpen: false, deleteTeam: undefined });
		if (deleteTeam) removeTeam(deleteTeam, undefined, true);
	};

	render() {
		const { teams, classes, intl } = this.props;
		const { filterQuery, showCreateDialog } = this.state;

		const filteredTeams = applyFilter(teams, filterQuery, [ "name", "description", "memberCount", "leader" ]);
		return (
			<div>
				<IconCard
					icon={TeamIcon}
					iconColor="purple"
					title={
						<div>
							{intl.fm("team.titles.overview")}
							<TeamFilterInput onFilterInputChange={this.onFilterInputChange} placeholder={intl.fm("common.labels.filter")} formControlClassName={classes.formControl} />
						</div>
					}
					content={<TeamTable teams={filteredTeams} removeTeam={this.confirmDelete} intl={intl} />}
				/>
				<CreateTeamButton onCreateTeam={this.onCreateTeam} />
				<ManagerCreateTeamModal open={showCreateDialog} onClose={this.onCancelTeamCreation} />
				<WarningDialog onConfirm={this.delete} onClose={this.cancelDelete} open={this.state.deleteOpen} entityName={intl.fm("team.entityName")} />
			</div>
		);
	}
}

const enrichWithMemberInfos = (teams, members, managerClasses) => {
	return teams
		.sort((t1, t2) => (t1.name + "").localeCompare(t2.name))
		.map(team => {
			const teamMembers = members.filter(member => member.teamId === team.id);
			const memberCount = teamMembers.length;
			const leader = teamMembers.find(member => member.leader);
			const teamClass = managerClasses.find(cl => cl.id === team.classId);
			return {
				...team,
				memberCount,
				leader: leader && leader.user.username,
				teamClass
			};
		});
};

const teamsSelector = createSelector(
	state => state.api.resources.managerTeams.data,
	state => state.api.resources.managerTeamMembers.data,
	state => state.api.resources.managerClasses.data,
	enrichWithMemberInfos
);

const mapStateToProps = state => {
	return {
		teams: teamsSelector(state),
		isLoading: state.api.resources.managerTeams.pending,
		match: undefined,
		location: undefined
	};
};

const mapDispatchToProps = {
	fetchManagerTeamsWithMembers: managerApi.fetchManagerTeamsWithMembers,
	fetchManagerClasses: managerApi.fetchManagerClasses,
	removeTeam: managerApi.removeTeam
};

export default compose(
	withIntl,
	withStyles(filterInputStyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(ManagerTeams);

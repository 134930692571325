import { inboxInitState } from "messaging/globalState";
import { profileInitState } from "reducers/profileState";

import { eventInitState } from "./eventState";
import { managerInitState } from "./managerState";
import { rankingInitState } from "./rankingState";
import { reportInitState } from "./reportState";
import { teacherInitState } from "./teacherState";
import { teamInitState } from "./teamState";

interface Notification {
	id: number;
	messageType: string;
	message: string;
}

export const initState = {
	notifications: [] as Notification[],
	resources: {
		...eventInitState,
		...teacherInitState,
		...profileInitState,
		...reportInitState,
		...managerInitState,
		...rankingInitState,
		...teamInitState,
		...inboxInitState,
	},
};

import * as ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";

import CertHint from "components/CertHint/CertHint";
import MailHint from "components/MailHint/MailHint";
import SnackbarSpawner from "components/Snackbar/SnackbarSpawner";
import { ThemeProvider } from "components/ThemeProvider/ThemeProvider";
import { getLocaleId, getTranslation } from "localization/actions";
import apiMiddleware from "middleware/api";
import brandingMiddleware from "middleware/branding";
import themeMiddleware from "middleware/theme";
import { isProductionEnvironment } from "ProcessEnvironment";
import reducer from "reducers";
import { remoteResourceMiddleware } from "redux/remoteResourceMiddleware";
import { indexRoutes } from "routes/indexRoutes";

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}


const composeEnhancers = (!isProductionEnvironment() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const enhancers = composeEnhancers(applyMiddleware(apiMiddleware, brandingMiddleware, themeMiddleware, remoteResourceMiddleware));

export const createApp = () => {
	ReactDOM.render(
		<Provider store={createStore(reducer, enhancers)}>
			<ThemeProvider>
				<IntlProvider messages={getTranslation()} locale={getLocaleId()} defaultLocale="en">
					<SnackbarSpawner />
					<Router>
						<Switch>
							{indexRoutes.map((prop, key) => (
								<Route path={prop.path} component={prop.component} key={key} />
							))}
						</Switch>
					</Router>
					<CertHint />
					<MailHint />
				</IntlProvider>
			</ThemeProvider>
		</Provider>,
		document.getElementById("root"),
	);
};

import { EnumDetail } from "@hlcr/app/enum/EnumBase";
import { Dto } from "@hlcr/core/api/RemoteResource";
import { Country } from "@hlcr/mui/CountrySelect/data/countries";

export enum Gender {
	MALE = "MALE",
	FEMALE = "FEMALE",
	DIVERSE = "DIVERSE",
}

export const GENDERS: EnumDetail<Gender> = {
	[Gender.MALE]: { title: "gender.male" },
	[Gender.FEMALE]: { title: "gender.female" },
	[Gender.DIVERSE]: { title: "gender.diverse" },
};

export interface UserSummary extends Dto {
	id: number;
	username: string;
	email: string;
	firstName?: string;
	lastName?: string;
}

export interface SolutionSummary extends Dto {
	id: number;
	state: string;
	points: number;
}

export interface UserSolutionSummary extends Dto {
	user: UserSummary;
	solution: SolutionSummary
}

export interface TeamSolutionSummary extends Dto {
	teamId: number;
	teamName: string;
	solution: SolutionSummary
}

export interface User extends UserSummary {
	userIdentifier: string;
}

export interface UserProfile extends User {
	receiveEmailNotifications?: boolean;
	organization?: string;
	nationality?: Country;
	gender?: Gender;
	bankAccount?: string;
	paypal?: string;
	birthday?: Date;
	mobilePhoneNumber?: string;
	skills?: string;
	userLevel?: string;
	points?: number;
}

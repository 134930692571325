import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GroupIcon from "@material-ui/icons/Group";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teamLeaderApi from "actions/teamleader";
import teamsApi from "actions/teams";
import { actionStyles, floatingActionButtonStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamOverviewStyle";
import { checkHasRole } from "auth/authUtils";
import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import IconButton from "components/CustomButtons/IconButton";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import WarningDialog from "components/ModalWindow/WarningDialog";
import NoData from "components/NoData/NoData";
import { ReportButton, ReportLink } from "components/Report/ReportComponents";
import EnhancedCollapsibleTable, { COLLAPSE_CELL } from "components/Table/EnhancedCollapsibleTable";
import TokenInput from "components/TokenInput/TokenInput";
import { withIntl } from "@hlcr/ui/Intl";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import EditTeamModal from "views/Teams/EditTeamModal";
import JoinTeamModal from "views/Teams/JoinTeamModal";
import TokenModal from "views/Teams/TokenModal";

const UserTeams = ({ classes, intl, teams, isLoading, fetchTeams, fetchTeamJoinInfo, removeTeam, removeTeamMember, promoteToLeader }) => {
	const [ showCreateDialog, setShowCreateDialog ] = React.useState(false);
	const [ showTokenDialog, setShowTokenDialog ] = React.useState(false);
	const [ showJoinDialog, setShowJoinDialog ] = React.useState(false);
	const [ teamMemberToDelete, setTeamMemberToDelete ] = React.useState(undefined);
	const [ teamMemberToPromote, setTeamMemberToPromote ] = React.useState(undefined);
	const [ teamToDelete, setTeamToDelete ] = React.useState(undefined);
	const [ teamToEdit, setTeamToEdit ] = React.useState(undefined);
	const [ teamOfToken, setTeamOfToken ] = React.useState(-1);
	const [ joinToken, setJoinToken ] = React.useState("");

	React.useEffect(() => {
		fetchTeams();
	}, [ fetchTeams ]);

	const onCloseTeamCreation = () => {
		setTeamToEdit(undefined);
		setShowCreateDialog(false);
	};

	const onCloseTokenDialog = () => {
		setShowTokenDialog(false);
		setTeamOfToken(-1);
	};

	const onOpenTokenDialog = (event, teamId) => {
		event.stopPropagation();
		setShowTokenDialog(true);
		setTeamOfToken(teamId);
	};

	const onRemoveTeam = () => {
		removeTeam(teamToDelete.id);
		setTeamToDelete(undefined);
	};

	const onRemoveTeamMember = () => {
		removeTeamMember(teamMemberToDelete.teamId, teamMemberToDelete.user.userIdentifier);
		setTeamMemberToDelete(undefined);
	};

	const onPromoteLeader = () => {
		promoteToLeader(teamMemberToPromote.teamId, teamMemberToPromote.user.userIdentifier);
		setTeamMemberToPromote(undefined);
	};

	const redeemToken = (token, callback, callbackFailure) => {
		fetchTeamJoinInfo(token, () => {
			setShowJoinDialog(true);
			setJoinToken(token);
			callback();
		}, callbackFailure);
	};

	const onCloseJoinDialog = () => {
		setShowJoinDialog(false);
		setJoinToken("");
	};

	const enhanceTeams = teams => teams.slice(0).map(team => Object.assign({}, team, { teamLeader: team.members.find(member => member.leader === true) }));

	const tableRenderer = [
		{
			id: COLLAPSE_CELL,
			renderCell: () => (<></>),
			colStyle: { width: 50 }
		},
		{
			id: "name",
			title: intl.fm("team.label.name"),
			renderCell: team => team.name,
			sort: (a, b) => (a.name < b.name ? 1 : -1)
		},
		{
			id: "description",
			title: intl.fm("team.label.description"),
			renderCell: team => team.description,
			sort: (a, b) => (a.description < b.description ? 1 : -1)
		},
		{
			id: "members",
			title: intl.fm("team.label.membercount"),
			renderCell: team => team.members.length,
			sort: (a, b) => (a.members.length < b.members.length ? 1 : -1)
		},
		{
			id: "leader",
			title: intl.fm("team.label.leader"),
			renderCell: team => team.teamLeader?.user.username,
			sort: (a, b) => (a.teamLeader?.user.username < b.teamLeader?.user.username ? 1 : -1)
		},
		{
			id: "actions",
			renderCell: team => team.currentUserIsTeamLeader && checkHasRole(HackingLabRole.TEAMLEADER) && checkHasRole(HackingLabRole.USER_TEAMS) ? <div style={{ textAlign: "right" }}>
				{checkHasRole(HackingLabRole.USER_TEAMS) && <ReportButton label={intl.fm("common.labels.token")} onClick={event => onOpenTokenDialog(event, team.id)} customClass={classes.button} />}
				{checkHasRole(HackingLabRole.TEAMLEADER_UPDATE_TEAM) && <Button color="info" customClass={classes.button} onClick={event => {
					event.stopPropagation();
					setTeamToEdit(team);
					setShowCreateDialog(true);
				}}>
					<EditIcon className={classes.icon} />
				</Button>}
				{checkHasRole(HackingLabRole.TEAMLEADER_DELETE_TEAM) && <Button color="danger" customClass={classes.button} onClick={event => {
					event.stopPropagation();
					setTeamToDelete(team);
				}}>
					<DeleteIcon className={classes.icon} />
				</Button>}
			</div> : "",
			sort: (a, b) => (a.currentUserIsTeamLeader < b.currentUserIsTeamLeader ? 1 : -1),
			colStyle: { width: 175 }
		}
	];

	const collapsedTableRenderer = [
		{
			id: COLLAPSE_CELL,
			renderCell: () => (<></>),
			colStyle: { width: 50 }
		},
		{
			id: "name",
			title: intl.fm("user.label.username"),
			renderCell: member => member.user.username,
			sort: (a, b) => (a.user.username < b.user.username ? 1 : -1)
		},
		{
			id: "leader",
			title: intl.fm("team.label.leader"),
			renderCell: member => (member.leader ? "\u2714" : ""),
			sort: (a, b) => (a.leader < b.leader ? 1 : -1)
		},
		{
			id: "actions",
			renderCell: member => member.currentUserIsTeamLeader ? <div style={{ textAlign: "right" }}>
				{checkHasRole(HackingLabRole.TEAMLEADER_TEAM_MEMBER_PROFILE) && <ReportLink to={`/teams/${member.teamId}/users/${member.user.userIdentifier}`} />}
				{checkHasRole(HackingLabRole.TEAMLEADER_REPORT) && <ReportLink label={intl.fm("report.table.users.header.solutions")} to={`/teams/report/${member.teamId}/users/${member.user.id}`} />}
				{checkHasRole(HackingLabRole.USER_TEAMS) && <>
					<Button
						color="info"
						disabled={member.leader}
						customClass={member.leader ? classes.buttonDisabled : classes.button}
						onClick={() => setTeamMemberToPromote(member)}
					>
						{intl.fm("team.members.promoteLeader")}
					</Button>
					{checkHasRole(HackingLabRole.TEAMLEADER_DELETE_TEAM_MEMBER) &&
					 <Button color="danger" disabled={member.leader} customClass={member.leader ? classes.buttonDisabled : classes.button} onClick={() => setTeamMemberToDelete(member)}>
						 <DeleteIcon className={classes.icon} />
					 </Button>}
				</>}
			</div> : "",
			sort: (a, b) => (a.currentUserIsTeamLeader < b.currentUserIsTeamLeader ? 1 : -1),
			colStyle: { width: 300 }
		}
	];

	if (!teams) {
		return isLoading ? <LoadingSpinner /> : <NoData />;
	} else {
		return (
			<>
				{checkHasRole(HackingLabRole.USER_TEAMS) && <TokenInput displayText={intl.fm("team.redeemMemberToken")} redeemToken={redeemToken} />}
				<GridContainer>
					<ItemGrid xs={12}>
						<IconCard
							icon={GroupIcon}
							iconColor="purple"
							title={intl.fm("team.titles.overview")}
							content={
								<EnhancedCollapsibleTable
									isLoading={isLoading}
									tableData={enhanceTeams(teams)}
									tableRenderer={tableRenderer}
									collapsedTableRenderer={collapsedTableRenderer}
									toggleCollapseOnRow={true}
									hideCollapsedTableHeader={false}
									hover
									hasCollapseProperties={row => row["members"]}
									extractCollapseProperties={row => row.members
										.map(member => Object.assign({}, member, { currentUserIsTeamLeader: row.currentUserIsTeamLeader, teamId: row.id }))
										.sort((a, b) => a.user.username.localeCompare(b.user.username))
									}
									persistSortFor={"UserTeams"}
									emptyContent={<NoData />}
								/>
							}
						/>
					</ItemGrid>
				</GridContainer>

				<WarningDialog
					onConfirm={onRemoveTeamMember}
					onClose={() => setTeamMemberToDelete(undefined)}
					open={teamMemberToDelete}
					entityName={intl.fm("team.label.member")}
					message={teamMemberToDelete && teamMemberToDelete.user.username
						? intl.fm("common.deleteConfirm.message_something", null, { something: `${teamMemberToDelete.user.username}` })
						: undefined
					}
				/>
				<WarningDialog
					onConfirm={onPromoteLeader}
					onClose={() => setTeamMemberToPromote(undefined)}
					open={teamMemberToPromote}
					title={intl.fm("common.confirm.title")}
					message={intl.fm("team.members.promoteLeader.warning")}
				/>
				<WarningDialog
					onConfirm={onRemoveTeam}
					onClose={() => setTeamToDelete(undefined)}
					open={teamToDelete} entityName={intl.fm("team.entityName")}
					message={teamToDelete && teamToDelete.name ? intl.fm("common.deleteConfirm.message_something", null, { something: `team ${teamToDelete.name}` }) : undefined}
				/>
				<JoinTeamModal open={showJoinDialog} onClose={onCloseJoinDialog} token={joinToken} />
				{checkHasRole(HackingLabRole.TEAMLEADER) && <TokenModal open={showTokenDialog} onClose={onCloseTokenDialog} teamId={teamOfToken} />}
				{checkHasRole(HackingLabRole.TEAMLEADER_CREATE_TEAM) && checkHasRole(HackingLabRole.TEAMLEADER_UPDATE_TEAM) && <CreateTeamButton onCreateTeam={() => setShowCreateDialog(true)} />}
				{checkHasRole(HackingLabRole.TEAMLEADER_UPDATE_TEAM) && <EditTeamModal open={showCreateDialog} onClose={onCloseTeamCreation} team={teamToEdit} />}
			</>
		);
	}
};
const CreateTeamButton = withStyles(floatingActionButtonStyles)(
	({ onCreateTeam, classes }) => (
		<IconButton onClick={onCreateTeam} color="primary" aria-label="add" customClass={classes.button}>
			<AddIcon className={classes.iconPosition} />
		</IconButton>
	));

export default compose(connect(
	state => ({
		teams: state.api.resources.userTeams.data,
		isLoading: state.api.resources.userTeams.pending
	}),
	dispatch => bindActionCreators({
		fetchTeams: teamsApi.fetchTeams,
		fetchTeamJoinInfo: teamsApi.fetchTeamJoinInfo,
		removeTeam: teamLeaderApi.removeTeam,
		removeTeamMember: teamLeaderApi.removeTeamMember,
		promoteToLeader: teamLeaderApi.promoteToLeader
	}, dispatch)
), withIntl, withStyles(actionStyles))(UserTeams);

import { RemoteResourceState } from "@hlcr/core/api/RemoteResource";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchEventParticipantsTeams, fetchEventParticipantsUsers, fetchEventUnitParticipantsTeamsWithOpenSolutions, fetchEventUnitParticipantsUsersWithOpenSolutions } from "grading/bulkActionModal.action";
import { EventParticipant, EventParticipantWitSolutionState } from "grading/BulkActionModalTypes";
import { GradingMode } from "models/EventUnit";
import { RemoteResource } from "models/RemoteResource";
import { TeamSolutionSummary, UserSolutionSummary } from "models/User";
import { RootState } from "reducers";
import { clearRemoteResource } from "redux/actions";
import { TeacherEventUnit } from "shared/event/model/TeacherEventUnit";

export const useEventParticipantUsersToBeGraded = (unit: TeacherEventUnit) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (unit?.eventId && unit?.grading == GradingMode.MANUAL) {
			dispatch(clearRemoteResource(RemoteResource.EVENT_PARTICIPANTS_USERS));
			dispatch(fetchEventParticipantsUsers({ eventId: unit?.eventId }));
		} else if (unit?.id) {
			dispatch(clearRemoteResource(RemoteResource.EVENT_UNIT_PARTICIPANTS_USERS_WITH_OPEN_SOLUTIONS));
			dispatch(fetchEventUnitParticipantsUsersWithOpenSolutions({ eventUnitId: unit?.id }));
		}
	}, [ unit?.id, unit?.eventId ]);

	const eventParticipantUsersState = useSelector<RootState, RemoteResourceState<UserSolutionSummary[]>>((state) => {
		return unit.grading == GradingMode.MANUAL
			? state.remoteResourceReducer.remoteResources[RemoteResource.EVENT_PARTICIPANTS_USERS]
			: state.remoteResourceReducer.remoteResources[RemoteResource.EVENT_UNIT_PARTICIPANTS_USERS_WITH_OPEN_SOLUTIONS];
	});

	return eventParticipantUsersState?.data?.map((user) => userSolutionSummaryToEventParticipant(user)) ?? [];
};

export const useEventParticipantUsers = (unit: TeacherEventUnit) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (unit?.eventId) {
			dispatch(clearRemoteResource(RemoteResource.EVENT_PARTICIPANTS_USERS));
			dispatch(fetchEventParticipantsUsers({ eventId: unit?.eventId }));
		}
	}, [ unit?.eventId ]);

	const eventParticipantUsersState = useSelector<RootState, RemoteResourceState<UserSolutionSummary[]>>(state => {
		return state.remoteResourceReducer.remoteResources[RemoteResource.EVENT_PARTICIPANTS_USERS];
	});
	return eventParticipantUsersState?.data?.map((user) => userSolutionSummaryToEventParticipant(user)) ?? [];
};

export const useEventParticipantTeams = (unit: TeacherEventUnit) => {
	const dispatch = useDispatch();
	useEffect(() => {
		if (unit?.eventId) {
			dispatch(clearRemoteResource(RemoteResource.EVENT_PARTICIPANTS_TEAMS));
			dispatch(fetchEventParticipantsTeams({ eventId: unit?.eventId }));
		}
	}, [ unit?.eventId ]);

	const eventParticipantTeamsState = useSelector<RootState, RemoteResourceState<TeamSolutionSummary[]>>(state => {
		return state.remoteResourceReducer.remoteResources[RemoteResource.EVENT_PARTICIPANTS_TEAMS];
	});
	return eventParticipantTeamsState?.data?.map((team) => teamToEventParticipant(team)) ?? [];
};

export const useEventParticipantTeamsToBeGraded = (unit: TeacherEventUnit) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (unit?.eventId && unit?.grading == GradingMode.MANUAL) {
			dispatch(clearRemoteResource(RemoteResource.EVENT_PARTICIPANTS_TEAMS));
			dispatch(fetchEventParticipantsTeams({ eventId: unit?.eventId }));
		} else if (unit?.id) {
			dispatch(clearRemoteResource(RemoteResource.EVENT_UNIT_PARTICIPANTS_TEAMS_WITH_OPEN_SOLUTIONS));
			dispatch(fetchEventUnitParticipantsTeamsWithOpenSolutions({ eventUnitId: unit?.id }));
		}
	}, [ unit?.id, unit?.eventId  ]);

	const eventParticipantsTeamsState = useSelector<RootState, RemoteResourceState<TeamSolutionSummary[]>>(state => {
		return unit.grading == GradingMode.MANUAL
			? state.remoteResourceReducer.remoteResources[RemoteResource.EVENT_PARTICIPANTS_TEAMS]
			: state.remoteResourceReducer.remoteResources[RemoteResource.EVENT_UNIT_PARTICIPANTS_TEAMS_WITH_OPEN_SOLUTIONS];
	});

	return eventParticipantsTeamsState?.data?.map((team) => teamToEventParticipant(team)) ?? [];
};

export const useSelectedUserIds = (userParticipants: EventParticipant[]): [number[], React.Dispatch<React.SetStateAction<number[]>>] => {
	const [ selectedUserIds, setSelectedUserIds ] = useState<number[]>([]);

	useEffect(() => {
		setSelectedUserIds(userParticipants.map(p => p.id));
	}, [ userParticipants ]);

	return [ selectedUserIds, setSelectedUserIds ];
};

export const useSelectedTeamIds = (eventParticipantTeams: EventParticipant[]): [number[], React.Dispatch<React.SetStateAction<number[]>>] => {
	const [ selectedTeamIds, setSelectedTeamIds ] = useState<number[]>([]);

	useEffect(() => {
		setSelectedTeamIds(eventParticipantTeams.map(p => p.id));
	}, [ eventParticipantTeams ]);

	return [ selectedTeamIds, setSelectedTeamIds ];
};

const userSolutionSummaryToEventParticipant = (userSolutionSummary: UserSolutionSummary): EventParticipantWitSolutionState => {
	const eventParicipant: EventParticipantWitSolutionState = { id: userSolutionSummary.user.id, name: userSolutionSummary.user.username, solutionState: "", points: "" };
	if (userSolutionSummary.user.firstName || userSolutionSummary.user.lastName) {
		eventParicipant.name = `${userSolutionSummary.user.username} (${userSolutionSummary.user.firstName} ${userSolutionSummary.user.lastName})`;
	}
	if (userSolutionSummary.solution) {
		eventParicipant.solutionState = userSolutionSummary.solution.state;
		eventParicipant.points = String(userSolutionSummary.solution.points);
	}
	return eventParicipant;
};

const teamToEventParticipant = (team: TeamSolutionSummary): EventParticipantWitSolutionState => {
	const eventParicipant: EventParticipantWitSolutionState = { id: team.teamId, name: team.teamName, solutionState: "", points: "" };

	if (team.solution) {
		eventParicipant.solutionState = team.solution.state;
		eventParicipant.points = String(team.solution.points);
	}
	return eventParicipant;

};

import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teamleaderApi from "actions/teamleader";
import reportStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/reportStyle";
import { withIntl } from "@hlcr/ui/Intl";
import { EventReport } from "views/Report/EventReport";

class TeamUserReport extends React.Component {
	componentDidMount() {
		const {
			userId,
			teamId,
			fetchChallengeReportsByUser,
			fetchQuizReportsByUser,
			fetchReportTeamUsers,
			fetchTheoryReportsByUser
		} = this.props;

		fetchChallengeReportsByUser(teamId, userId);
		fetchQuizReportsByUser(teamId, userId);
		fetchTheoryReportsByUser(teamId, userId);
		fetchReportTeamUsers(teamId);
	}

	render() {
		const {
			userId,
			teamId,
			challengeReports,
			userReportOptions,
			userReport,
			username,
			intl,
			classes,
			routeTo
		} = this.props;

		const settingsKey = `TeamUserReport::${teamId}`;

		return (
			<EventReport
				disableActions
				settingsKey={settingsKey}
				userId={userId}
				teamId={teamId}
				challengeReports={challengeReports}
				userReportOptions={userReportOptions}
				userReport={userReport}
				username={username}
				routeTo={routeTo}
				onSelectUser={this.onSelectUser}
				intl={intl}
				classes={classes}
			/>
		);
	}

	onSelectUser = event => {
		const {
			history,
			teamId,
			fetchChallengeReportsByUser,
			fetchQuizReportsByUser,
			fetchTheoryReportsByUser
		} = this.props;

		const userId = event.target.value;
		this.setState({ selectedEvent: undefined });

		fetchChallengeReportsByUser(teamId, userId);
		fetchQuizReportsByUser(teamId, userId);
		fetchTheoryReportsByUser(teamId, userId);
		history.push(`/teams/report/${teamId}/users/${userId}`);
	};
}

const mapStateToProps = (state, ownProps) => {
	const userId = Number(ownProps.match.params.userId);
	const teamId = Number(ownProps.match.params.teamId);

	const routeTo = ownProps.history.push;

	const {
		reportUsers,
		reportChallenges,
		reportQuizzes,
		reportTheories
	} = state.api.resources;

	const allreports = [
		...reportChallenges.data,
		...reportQuizzes.data,
		...reportTheories.data
	];

	const userReportOptions = [ ...reportUsers.data.filter(ur => ur.teamId === teamId) ];

	const challengeReports = allreports.filter(
		challengeReport => challengeReport.userId === userId
	);

	const userReport = reportUsers.data.find(ur => ur.userId === userId);
	const username = userReport ? userReport.username : "unknown";

	return {
		challengeReports,
		userReportOptions,
		userReport,
		username,
		userId,
		teamId,
		routeTo
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchChallengeReportsByUser: teamleaderApi.fetchChallengeReportsByUser,
			fetchQuizReportsByUser: teamleaderApi.fetchQuizReportsByUser,
			fetchTheoryReportsByUser: teamleaderApi.fetchTheoryReportsByUser,
			fetchReportTeamUsers: teamleaderApi.fetchReportTeamUsers
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(reportStyle),
	withIntl
)(TeamUserReport);

import { useIntl } from "@hlcr/ui/Intl";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

import { COUNTRIES, Country, CountryEnum } from "./data/countries";

const countryToFlag = (country: CountryEnum) => {
	return <img
		loading={"lazy"}
		width={20}
		src={`https://flagcdn.com/w20/${country.key}.png`}
		srcSet={`https://flagcdn.com/w40/${country.key}.png 2x`}
		alt={country.icon}
	/>;
};

const useStyles = makeStyles({
	option: {
		fontSize: 15,
		"& > span": {
			marginRight: 10,
			fontSize: 18,
		},
	},
});

interface CountrySelectProps {
	label: string;
	value?: Country;
	required?: boolean;
	onChange?: any;
	validations?: any;
	validateOnLoad?: boolean;
}

const countryFilterOptions = createFilterOptions(({ stringify: option => `${COUNTRIES[option as Country]?.title} ${COUNTRIES[option as Country]?.key}` }));

export const CountrySelect = ({ label, value, required, onChange }: CountrySelectProps) => {
	const classes = useStyles();
	const intl = useIntl();

	return <Autocomplete
		onChange={onChange}
		value={value}
		id={"country-select"}
		classes={{ option: classes.option }}
		options={Object.keys(Country)}
		autoHighlight={true}
		disableClearable={required}
		filterOptions={countryFilterOptions}
		renderOption={option => (
			<>
				<span>{countryToFlag(COUNTRIES[option as Country])}</span>
				{intl.fm(`country.${COUNTRIES[option as Country]?.key}`, COUNTRIES[option as Country].title)}
			</>
		)}
		renderInput={(params) => (
			// TODO: use a custom validation input to allow better validation
			<TextField
				{...params}
				required={required}
				label={label}
				error={required && !value}
				inputProps={{
					...params.inputProps,
					autoComplete: "new-password",
				}}
			/>
		)}
	/>;
};

import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import React, { Component } from "react";
import { compose } from "redux";

import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import NoData from "components/NoData/NoData";
import { withIntl } from "@hlcr/ui/Intl";
import ProfileInfo from "views/User/ProfileInfo";


const notesEditorStyles = { descriptionBox: { margin: "0 8px" } };

class NotesEditor extends Component {
	state = { notes: "" };
	static getDerivedStateFromProps(props, state) {
		if (
			(props.entity && !state.entity) ||
			props.entity.profile.id !== state.entity.profile.id
		) {
			return { entity: props.entity, notes: props.entity[props.notesField] };
		}
		return null;
	}
	resetForm = () =>
		this.setState({ notes: this.props.entity[this.props.notesField] });

	onChange = event => this.setState({ notes: event.target.value });

	submit = () => {
		const { onSubmit } = this.props;
		const { notes } = this.state;
		onSubmit(notes);
	};

	actions = (
		<div>
			<Button onClick={this.resetForm} color="defaultNoBackground">
				{this.props.intl.fm("common.labels.resetForm")}
			</Button>
			<Button onClick={this.submit} color="infoNoBackground">
				{this.props.intl.fm("common.labels.save")}
			</Button>
		</div>
	);

	render() {
		const { notesTitle, intl, classes } = this.props;
		const { notes } = this.state;

		const inputFields = (
			<div className={classes.descriptionBox}>
				<CustomInput
					type="text"
					label={notesTitle ? notesTitle : intl.fm("teamMember.notes")}
					formControlProps={{ fullWidth: true }}
					inputProps={{
						multiline: true,
						rows: 5,
						value: notes,
						onChange: this.onChange
					}}
				/>
			</div>
		);

		return (
			<IconCard
				title={notesTitle ? notesTitle : intl.fm("teamMember.teamNotes")}
				icon={InfoIcon}
				content={inputFields}
				footer={this.actions}
			/>
		);
	}
}
NotesEditor = withStyles(notesEditorStyles)(NotesEditor);

class ProfileNotesEditor extends Component {
	onSave = notes => {
		const { onSubmit } = this.props;
		onSubmit(notes);
	};
	render() {
		const { member, notesField, notesTitle, intl } = this.props;
		return (
			<GridContainer>
				<ItemGrid xs={12} sm={12} md={6} lg={6}>
					{member ? <ProfileInfo profile={member.profile} /> : <NoData />}
				</ItemGrid>
				<ItemGrid xs={12} sm={12} md={6} lg={6}>
					{member && (
						<NotesEditor
							notesField={notesField}
							entity={member}
							onSubmit={this.onSave}
							notesTitle={notesTitle}
							intl={intl}
						/>
					)}
				</ItemGrid>
			</GridContainer>
		);
	}
}

export default compose(withIntl)(ProfileNotesEditor);

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import teacherApi from "actions/teacher";
import { createMemoize } from "helper/memoize";
import Theory from "views/Events/Theory";

const getTheory = createMemoize((theories, theoryId) =>
	theories.find(event => event.id === theoryId)
);

const getEvent = createMemoize((events, eventId) =>
	events.find(event => event.id === eventId)
);

const mapStateToProps = (state, ownProps) => {
	const theoryId = Number(ownProps.match.params.unitId);
	const eventId = Number(ownProps.match.params.eventId);

	return {
		isPreview: true,
		theoryId,
		theory: getTheory(state.api.resources.teacherTheories.data, theoryId),
		event: getEvent(state.api.resources.teacherEvents.data, eventId),
		isLoading:
			state.api.resources.teacherEvents.pending ||
			state.api.resources.teacherTheories.pending
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators({ fetchTheory: teacherApi.fetchTeacherTheory }, dispatch);

let TheacherTheoryPreview = connect(
	mapStateToProps,
	mapDispatchToProps
)(Theory);

export default TheacherTheoryPreview;

let TeacherTheoryPreviewBreadCrumb = ({ theory }) => (
	<span>{theory ? theory.title : "Theory"}</span>
);

const mapStateToPropsBreadCrumb = (state, ownProps) => {
	const theoryId = Number(ownProps.match.params.unitId);

	return {
		theory: state.api.resources.teacherTheories.data.find(
			t => t.id === theoryId
		)
	};
};

TeacherTheoryPreviewBreadCrumb = connect(mapStateToPropsBreadCrumb)(
	TeacherTheoryPreviewBreadCrumb
);

export { TeacherTheoryPreviewBreadCrumb };

import { INIT, INVALID, VALID } from "components/CustomInput/ValidationInput";
import { QuestionType } from "models/Quizzes";
import { QuestionAnswerState } from "models/SolutionState";

export const getQuestionState = (questionAnswer, gradingInstruction) => {
	if (gradingInstruction && questionAnswer && gradingInstruction.type === QuestionType.LONG_ANSWER)
		switch (questionAnswer.correctAnswer) {
			case true:
				return QuestionAnswerState.RIGHT;
			case false:
				return QuestionAnswerState.WRONG;
			default:
				return questionAnswer.content ? QuestionAnswerState.UNGRADED : QuestionAnswerState.UNSOLVED;
		}

	if (!questionAnswer || (!gradingInstruction && !hasAnyAnswer(questionAnswer)))
		return QuestionAnswerState.UNSOLVED;

	if (!gradingInstruction) return QuestionAnswerState.SOLVED;

	switch (gradingInstruction.type) {
		case QuestionType.MULTIPLE_CHOICE:
			return getCorrectState(checkMCAnswer(gradingInstruction, questionAnswer));
		case QuestionType.SINGLE_CHOICE:
			return getCorrectState(checkSCAnswer(gradingInstruction, questionAnswer));
		case QuestionType.SHORT_ANSWER:
			return getCorrectState(checkShortAnswer(gradingInstruction, questionAnswer.content));
		default:
			return QuestionAnswerState.UNSOLVED;
	}
};

const checkSCAnswer = (gradingInstruction, questionAnswer) => {
	const correctOption = gradingInstruction.multipleChoiceOptions.find(gia => gia.correct);
	return (
		questionAnswer.multipleChoiceOptionAnswers.length === 1 &&
		questionAnswer.multipleChoiceOptionAnswers[0].selectedOptionUuid ===
		correctOption.id
	);
};

const checkMCAnswer = (gradingInstruction, questionAnswer) => {
	const correctOptions = gradingInstruction.multipleChoiceOptions.filter(gia => gia.correct);
	return (
		questionAnswer.multipleChoiceOptionAnswers.length === correctOptions.length &&
		correctOptions.every(co => questionAnswer.multipleChoiceOptionAnswers.some(mca => mca.selectedOptionUuid === co.id))
	);
};

export const checkShortAnswer = (gradingInstruction, content) => {
	const lowerCaseAnswer = content ? content.toLowerCase() : "";
	return gradingInstruction.shortAnswers.some(gia => gia.toLowerCase() === lowerCaseAnswer);
};

export const isOptionRight = (option, gradingInstruction) => {
	const solution = gradingInstruction.multipleChoiceOptions.find(gia => gia.id === option.id);
	return solution.correct;
};

export const getCorrectState = isRight => isRight ? QuestionAnswerState.RIGHT : QuestionAnswerState.WRONG;

export const hasAnyAnswer = questionAnswer =>
	(questionAnswer.content && questionAnswer.content.length > 0) || (questionAnswer.multipleChoiceOptionAnswers && questionAnswer.multipleChoiceOptionAnswers.length > 0);

export const getInputValidationState = (questionState, isPreview) => [ QuestionAnswerState.UNSOLVED, QuestionAnswerState.UNGRADED ].includes(questionState)
	? INIT
	: (
		(isPreview || QuestionAnswerState.RIGHT === questionState)
			? VALID
			: INVALID
	);

import { RemoteResourceStatus } from "@hlcr/core/api/RemoteResource";



export const profileInitState = {
	personalProfile: {
		status: RemoteResourceStatus.INITIALIZED,
		data: undefined,
	},
	teamleaderTeams: {
		pending: false,
		data: [],
	},
	teamleaderTeamMembers: {
		pending: false,
		data: [],
	},
};

import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import EventIcon from "@material-ui/icons/Event";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";

import InfoCard from "components/Cards/InfoCard";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import { SelectCard } from "components/Report/ReportComponents";
import { isNumber } from "helper/numbers";
import { SolutionState } from "models/SolutionState";

import EventTableCard from "./EventTableCard";

export class EventReport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			prevChallengeReports: props.challengeReports,
			events: [],
			selectedEvent: undefined,
			summarized: getStoredSummarized(props.settingsKey)
		};
	}

	static getDerivedStateFromProps(
		{ challengeReports, settingsKey, intl },
		{ prevChallengeReports, selectedEvent }
	) {
		if (challengeReports === prevChallengeReports) return null;
		if (challengeReports.length === 0) return null;

		const events = getDistinctEvents(challengeReports, intl);

		const storedEvent = getStoredSelectedEvent(events, settingsKey);
		if (selectedEvent === undefined || storedEvent) {
			return { events, selectedEvent: storedEvent || events[0] };
		}

		return { events };
	}

	render() {
		const {
			userId,
			onSelectUser,
			username,
			isOwnReport,
			classes,
			intl
		} = this.props;
		const { selectedEvent, events, summarized } = this.state;

		return (
			<GridContainer>
				<ItemGrid xs={12} sm={12} md={6} lg={3}>
					{isNumber(userId) ? (
						<UserSelectCard
							{...this.props}
							onSelectUser={onSelectUser || this.onSelectUser}
						/>
					) : (
						<UserStatus {...this.props} />
					)}
				</ItemGrid>
				<ItemGrid xs={12} sm={6} md={6} lg={3}>
					{events && <EventsInfo {...this.props} events={events} />}
				</ItemGrid>
				<ItemGrid xs={12} sm={6} md={6} lg={3}>
					<ChallengesStatus {...this.props} />
				</ItemGrid>
				<ItemGrid xs={12} sm={6} md={6} lg={3}>
					<SolutionsStatus {...this.props} />
				</ItemGrid>

				<ItemGrid xs={12} sm={12} md={12} lg={12}>
					<EventTableCard
						isOwnReport={isOwnReport}
						challengeReports={this.filterChallengeReportByEvent()}
						events={events}
						selectedEvent={selectedEvent}
						onSelectEvent={this.onSelectEvent}
						onSummarize={this.onSummarize}
						summarized={summarized}
						username={username}
						classes={classes}
						intl={intl}
					/>
				</ItemGrid>
			</GridContainer>
		);
	}

	filterChallengeReportByEvent = () => {
		const { selectedEvent } = this.state;
		const { challengeReports } = this.props;

		if (selectedEvent && selectedEvent.eventId)
			return challengeReports.filter(cr => cr.eventId === selectedEvent.eventId);

		return challengeReports;
	};

	onSelectUser = userId => {
		const { routeTo, classId, teamId } = this.props;
		this.setState({ selectedEvent: undefined });
		routeTo(`/report/classes/${classId}/teams/${teamId}/users/${userId}`);
	};

	onSelectEvent = eventId => {
		const selectedEvent = this.state.events.find(e => e.eventId === eventId);
		this.storeSelectedEvent(eventId);
		this.setState({ selectedEvent });
	};

	onSummarize = () =>
		this.setState(state => {
			const summarized = !state.summarized;
			this.storeSummarized(summarized);
			return { summarized };
		});

	storeSelectedEvent = eventId => {
		const { settingsKey } = this.props;
		if (!settingsKey) return;

		sessionStorage.setItem(getSelectedEventStorageKey(settingsKey), eventId);
	};

	storeSummarized = summarized => {
		const { settingsKey } = this.props;
		if (!settingsKey) return;

		sessionStorage.setItem(
			getSummarizedStorageKey(settingsKey),
			summarized ? 1 : 0
		);
	};
}

const getStoredSelectedEvent = (events, settingsKey) => {
	if (!settingsKey) return;

	const eventId = Number(
		sessionStorage.getItem(getSelectedEventStorageKey(settingsKey))
	);

	return events.find(event => event.eventId === eventId);
};

const getStoredSummarized = settingsKey =>
	!!Number(sessionStorage.getItem(getSummarizedStorageKey(settingsKey)));

const getSelectedEventStorageKey = settingsKey =>
	`${settingsKey}::selectedEventId`;

const getSummarizedStorageKey = settingsKey => `${settingsKey}::summarized`;

const getDistinctEvents = (challengeReports, intl) => {
	const events = challengeReports.reduce((result, cr) => {
		if (!result.find(crRes => crRes.eventId === cr.eventId))
			result.push({
				eventId: cr.eventId,
				eventName: cr.eventName,
				mandatory: cr.mandatory
			});
		return result;
	}, []);

	return [
		{ eventId: 0, eventName: intl.fm("report.table.allEvents") },
		...events
	];
};

const ChallengesStatus = ({ challengeReports, intl }) => (
	<InfoCard
		icon={AssignmentIcon}
		iconColor="purple"
		title={intl.fm("report.card.title.challenges")}
		description={challengeReports.length}
	/>
);
const SolutionsStatus = ({ challengeReports, intl }) => (
	<InfoCard
		icon={AssignmentTurnedInIcon}
		iconColor="purple"
		title={intl.fm("report.card.title.solutions")}
		description={
			challengeReports.filter(
				cr => cr.solutionState && cr.solutionState !== SolutionState.INITIALISED
			).length
		}
	/>
);

const UserStatus = ({ username, intl }) => (
	<InfoCard
		icon={PersonIcon}
		iconColor="purple"
		title={intl.fm("report.card.title.user")}
		description={username}
	/>
);

const UserSelectCard = ({
	onSelectUser,
	userReport,
	userReportOptions,
	intl
}) => (
	<SelectCard
		icon={PersonIcon}
		color="purple"
		title={intl.fm("report.card.title.user")}
		onSelect={onSelectUser}
		value={userReport}
		selects={userReportOptions}
		idField="userId"
		displayField="username"
	/>
);

const EventsInfo = ({ events, intl }) => {
	// we subtract -1 because there is a dummy event called "all events" with id 0 (which isn't there when no event registered => we need the Math.max)
	const runningEvents = Math.max(events.length - 1, 0);

	const mandatoryEvents = events.filter(event => event.mandatory).length;

	return (
		<InfoCard
			icon={EventIcon}
			iconColor="purple"
			title={intl.fm("report.card.title.events")}
			description={runningEvents}
			footer={`${mandatoryEvents} ${intl.fm("report.card.events.mandatory")}`}
		/>
	);
};

import { makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useState } from "react";

import { useMaintenanceInfoBanner } from "components/MaintenanceInfoBanner/maintenanceBannerInfo.hooks";
import { MaintenanceInfoBannerDto } from "components/MaintenanceInfoBanner/MaintenanceInfoBannerDto";

const useStyles = makeStyles(() => ({ banner: { marginBottom: "10px" } }));

export const MaintenanceInfoBanner = () => {
	const maintenanceInfoBanner = useMaintenanceInfoBanner();
	const [ closedBannerId, setClosedBannerId] = useState<string>(getBannerStateFromLocalState());
	return maintenanceInfoBanner?.id && closedBannerId !== maintenanceInfoBanner?.id
		? <MaintenanceInfoBannerBox
				maintenanceInfoBanner={maintenanceInfoBanner}
				toggleBanner={() => setBannerState(maintenanceInfoBanner?.id, setClosedBannerId)}
			/>
		: <></>;
};

const MaintenanceInfoBannerBox = ({ maintenanceInfoBanner, toggleBanner }: { maintenanceInfoBanner: MaintenanceInfoBannerDto, toggleBanner: () => void}) => {
	const classes = useStyles();
	return (
		<Alert
			severity="info"
			onClose={() => toggleBanner()}
			className={classes.banner}
		>
			<AlertTitle>Maintenance Information</AlertTitle>
			<BannerBoxContent maintenanceInfoBanner={maintenanceInfoBanner} />
		</Alert>
	);
};

const BannerBoxContent = ({ maintenanceInfoBanner }: { maintenanceInfoBanner: MaintenanceInfoBannerDto }) => {
	return (
		<>
			{ maintenanceInfoBanner.firstTextPart }
			<b>{ maintenanceInfoBanner.boldTextPart }</b>
			{ maintenanceInfoBanner.lastTextPart }
			{ maintenanceInfoBanner.contact && <ContactDetails contact={maintenanceInfoBanner.contact} />}
		</>
	);
};

const ContactDetails = ({ contact }: { contact: string }) => {
	return <Link href={`mailto:${contact}`}>{contact}</Link>;
};


function setBannerState(bannerId: string | undefined, setClosed: (id: string) => void){
	if (!bannerId) {
		return;
	}
	setClosed(bannerId);
	setBannerStateInLocalState(bannerId);
}

function getBannerStateFromLocalState(): string {
	return localStorage.getItem("maintenanceInfoBannerClosed") ?? "";
}

function setBannerStateInLocalState(maintenanceInfoId: string) {
	localStorage.setItem("maintenanceInfoBannerClosed", maintenanceInfoId);
}

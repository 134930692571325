import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import eventsApi from "actions/events";
import radioGroupStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/radioGroupStyle";
import { Button } from "@hlcr/mui/Button";
import ModalWindow from "components/ModalWindow/ModalWindow";
import { withIntl } from "@hlcr/ui/Intl";
import { EventJoinType } from "variables/constants";
import { fetchHelp } from "variables/helpPage";

const JoinEventModal = ({ open, onClose, classes, intl, helpUrl, joinEvent, token, joinInfo }) => {
	const [ infos, setInfos ] = useState([]);
	const [ selection, setSelection ] = useState("0");

	useEffect(() => {
		if (joinInfo && Array.isArray(joinInfo) && joinInfo.length > 0) {
			const infos = [];
			joinInfo.sort((a, b) => {
				if (a.type === EventJoinType.USER_JOIN && b.type !== EventJoinType.USER_JOIN) {
					return -1;
				}
				if (b.type === EventJoinType.USER_JOIN && a.type !== EventJoinType.USER_JOIN) {
					return 1;
				}
				return a.name.localeCompare(b.name);
			}).forEach((info, index) => {
				infos[`${index}`] = { value: `${index}`, ...info };
			});
			setInfos(infos);
		}
	}, [ joinInfo ]);

	const onAfterJoin = (hasJoined, eventTitle) => () => {
		onClose(hasJoined, eventTitle);
	};

	const handleJoin = (info) => {
		if (info) {
			joinEvent(token, info, onAfterJoin(true, info.eventName), onAfterJoin(false, info.eventName), intl.fm("events.redeem.modal.success"));
		}
	};

	const formControlLabel = info => <FormControlLabel key={info.value} value={info.value} control={<Radio classes={{ root: classes.radio }} />} label={info.name} classes={{ root: classes.label }} />;

	if (open && infos && infos.length > 0) {
		const first = _.head(infos);
		const rest = _.tail(infos);

		if(first.type === EventJoinType.USER_JOIN && rest.length === 0){
			// User can only join as him self and not as a team (no reason for this modal)
			handleJoin(first);
			onClose(true);
			return null;
		}

		return open && infos && infos.length > 0 && (
			<ModalWindow
				open={open}
				onClose={() => onClose(false)}
				title={intl.fm("event.join.selectModal.title")}
				helpLink={fetchHelp(helpUrl, "student", "joinEvent")}
				actionSection={
					<div className={classes.actions}>
						<Button onClick={() => onClose(false)} color="defaultNoBackground">
							{intl.fm("common.labels.cancel")}
						</Button>
						<Button onClick={() => handleJoin(infos[selection])} color="infoNoBackground">
							{intl.fm("common.labels.join")}
						</Button>
					</div>
				}
				fullWidth
			>
				<FormControl component="fieldset">
					<RadioGroup aria-label="gender" name="gender1" value={selection} onChange={e => setSelection(e.target.value)}>
						{first.type === EventJoinType.USER_JOIN ? (
							<>
								{intl.fm("event.join.selectModal.joinUser")}
								{formControlLabel(first)}
								{intl.fm("event.join.selectModal.joinTeam")}
								{rest.map(info => formControlLabel(info))}
							</>
						) : (
							<>
								{intl.fm("event.join.selectModal.joinTeam")}
								{infos.map(info => formControlLabel(info))}
							</>
						)}
					</RadioGroup>
				</FormControl>
			</ModalWindow>
		);
	}
	return null;
};
export default compose(
	withStyles({
		actions: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between"
		},
		...radioGroupStyle
	}),
	withIntl,
	connect(
		state => ({
			helpUrl: state.branding.helpUrl,
			joinInfo: state.api.resources.eventJoin.data
		}),
		dispatch =>
			bindActionCreators({ joinEvent: eventsApi.joinEvent }, dispatch)
	)
)(JoinEventModal);

import { EnumBase, EnumDetail } from "@hlcr/app/enum/EnumBase";
import { IntlWithFm } from "@hlcr/ui/Intl";


export interface Sender {
	id: string;
	username: string;
	email: string;
	firstName: string;
	lastName: string;
}

export enum MessageType {
	NEW_SOLUTION = "NEW_SOLUTION",
	NEW_GRADING = "NEW_GRADING",
	DEFAULT = "DEFAULT"
}

interface MessageTypeEnum extends EnumBase {
	messageSubject: string;
	messageBody: string;
}

export const MESSAGE_TYPES: EnumDetail<MessageType, MessageTypeEnum> = {
	NEW_SOLUTION: {
		title: "message.type.new_solution",
		messageSubject: "user.inbox.newSolutionSubject",
		messageBody: "user.inbox.newSolutionBody",
	},
	NEW_GRADING: {
		title: "message.type.new_solution",
		messageSubject: "user.inbox.newGradingSubject",
		messageBody: "user.inbox.newGradingBody",
	},
	DEFAULT: {
		title: "message.type.new_solution",
		messageSubject: "",
		messageBody: "",
	},
};


export interface MessageReceived {
	id: string;
	receivedDateTime: string;
	isRead: boolean;
	solutionState: any;
	importance: number;
	sender: Sender;
	subject: string;
	messageBody: string;
	messageType: MessageType
	links: MessageLink[];
}

export type MessageLink = LinkedEvent | LinkedUnit | LinkedSolutionComment;

export interface LinkedEvent {
	title: string;
	eventId: number;
	teacher: boolean;
}

export interface LinkedUnit extends LinkedEvent {
	unitId: number;
}

export function isLinkedUnit(link: MessageLink): link is LinkedUnit {
	return (link as LinkedUnit).unitId !== undefined;
}

export interface LinkedSolutionComment extends LinkedUnit {
	solutionId: number;
	solutionCommentId: number;
}

export function isLinkedSolutionComment(link: MessageLink): link is LinkedSolutionComment {
	return (link as LinkedSolutionComment).solutionCommentId !== undefined && (link as LinkedSolutionComment).solutionId !== undefined;
}

export interface MessageSent {
	id: string,
	sendDateTime: Date,
	deliverAfterDateTime: Date,
	sender: string,
	importance: "HIGH" | "NORMAL" | "LOW",
	subject: string,
	messageBody: string,
	eventUnit?: string,
	eventIds: number[],
	assetUuids: string[]
}


export const getSubjectOfMessage = (message: MessageReceived, intl: IntlWithFm) => {
	if (MessageType.DEFAULT === message.messageType) {
		return message.subject;
	}

	return `${intl.fm(MESSAGE_TYPES[message.messageType].messageSubject)}`;
};

import DeleteIcon from "@material-ui/icons/Delete";
import GroupIcon from "@material-ui/icons/Group";
import * as React from "react";

import { Button } from "@hlcr/mui/Button";
import CustomSearchSelect from "components/CustomSelect/CustomSearchSelect";
import WarningDialog from "components/ModalWindow/WarningDialog";

import ManagerTableCard from "./ManagerTableCard";

export default class ManagerClassTeams extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pendingIds: [],
			deleteEntity: undefined
		};
	}

	onSelectTeam = team => {
		const { updateTeam, classId } = this.props;
		updateTeam({ ...team, classId });
	};

	cancelDelete = () => {
		this.setState({ deleteEntity: undefined });
	};

	confirmDelete = team => () => {
		this.setState({ deleteEntity: team });
	};

	onRemove = team => () => {
		this.setState({ deleteEntity: undefined });
		this.props.updateTeam(
			{ ...team, classId: null },
			undefined,
			() => this.resetPendingTeam(team.id),
			() => this.resetPendingTeam(team.id),
			() => this.setPendingTeam(team.id)
		);
	};

	setPendingTeam = id => {
		this.setState(state => ({
			pendingIds: [
				...state.pendingIds,
				id 
			]
		}));
	};

	resetPendingTeam = id => {
		this.setState(state => ({ pendingIds: state.pendingIds.filter(val => val !== id) }));
	};

	disabledTeam = team => this.state.pendingIds.includes(team.id);

	render() {
		const { teams, classId, intl, classes, isArchived } = this.props;

		const tableRenderer = [
			{
				id: "name",
				title: intl.fm("manager.classes.teams.table.name"),
				renderCell: team => team.name,
				sort: true
			},
			{
				id: "description",
				title: intl.fm("manager.classes.teams.table.description"),
				renderCell: team => team.description,
				sort: true
			}
		];

		if (!isArchived) {
			tableRenderer.push({
				id: "actions",
				title: intl.fm("manager.classes.teams.table.actions"),
				renderCell: team => (
					<div>
						<Button
							color="danger"
							customClass={classes.editButton}
							disabled={this.disabledTeam(team)}
							onClick={this.confirmDelete(team)}
						>
							<DeleteIcon className={classes.icon} />
						</Button>
					</div>
				)
			});
		}

		const filteredTeamsSelect = teams
			.filter(team => !team.classId)
			.sort((t1, t2) => t1.name > t2.name);

		const classTeams = teams
			.filter(team => team.classId === classId)
			.sort((t1, t2) => t1.name > t2.name);

		return (
			<ManagerTableCard
				tableData={classTeams}
				title={
					<div className={classes.titleBox}>
						<span>{intl.fm("manager.classes.teams.title")}</span>
						{!isArchived ? (
							<CustomSearchSelect
								list={filteredTeamsSelect}
								value={null}
								onSelect={this.onSelectTeam}
								displayField="name"
								placeholder={intl.fm("manager.classes.teams.add")}
							/>
						) : null}
						<WarningDialog
							onConfirm={this.onRemove(this.state.deleteEntity)}
							onClose={this.cancelDelete}
							entity={this.state.deleteEntity}
							entityName={intl.fm("team.entityName")}
						/>
					</div>
				}
				tableRenderer={tableRenderer}
				cardIcon={GroupIcon}
				color="purple"
			/>
		);
	}
}

import moment from "moment";

import { EventState } from "./constants";

export const getEventState = (start, end) => {
	const now = moment();
	const startMoment = moment(start);
	if (!start || !startMoment.isValid()) return EventState.PENDENT;
	if (now.diff(startMoment) < 0) return EventState.PENDENT;
	if (!end || now.diff(moment(end)) < 0) return EventState.RUNNING;
	return EventState.FINISHED;
};

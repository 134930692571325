import { addNotification, removeApiSuccess, removeNotification, saveApiResult } from "actions/api";
import { getCookieValue } from "auth/authUtils";
import guid from "helper/guid";
import { MessageType } from "models/Message";
import { ActionType } from "actions/ActionType";

document.cookie = `CSRF-TOKEN=${guid()};path=/;`;

export default middleware => next => async action => {
	const nextAction = next(action);

	if (action.type === ActionType.API_REQUEST) {
		if (action.onBefore) {
			action.onBefore(middleware.dispatch);
		}

		let result,
			response = {};
		try {
			response = await doFetch(action);
			result = await getResult(response);
			checkRemove(response, action, middleware);
		} catch (e) {
		} finally {
			saveResult(response, action, middleware, result);
		}
		finishHandleResponse(response, action, middleware, result);
	}

	return nextAction;
};

const doFetch = async action => {
	const init = {
		method: action.method,
		body: action.nonJsonBody
			? action.nonJsonBody
			: JSON.stringify(action.body || undefined),
		headers: createHeaders(),
		credentials: "same-origin"
	};
	return await fetch(`${action.url}`, init);
};

const getResult = async response => {
	return response.status !== 204 && response.status !== 201
		? await response.json()
		: null;
};

const checkRemove = (response, action, middleware) => {
	if (
		response.status === 204 &&
		action.resource &&
		action.entity &&
		action.method === "delete"
	) {
		middleware.dispatch(
			removeApiSuccess(action.resource, action.entity, action.updatePropForObj)
		);
	}
};

const saveResult = (response, action, middleware, result) => {
	if (action.resource) {
		middleware.dispatch(
			saveApiResult(
				action.resource,
				response.ok ? result : undefined,
				action.updatePropForArray,
				action.updatePropForObj,
				action.updateField,
				action.setFieldOnObject
			)
		);
	}
};

const finishHandleResponse = (response, action, middleware, result) => {
	if (response.ok) {
		if (action.onSuccess) action.onSuccess(middleware.dispatch, result);
		createSuccessMessage(
			middleware.dispatch,
			action.successNotification,
			action.method
		);
	} else {
		if (action.onFailure) action.onFailure(middleware.dispatch, result);

		const snackId = guid();

		if (!action.suppressNotification) {
			middleware.dispatch(
				addNotification(snackId, buildMessage(response, result))
			);
			window.setTimeout(
				() => middleware.dispatch(removeNotification(snackId)),
				6000
			);
		}
	}
};

const buildMessage = (response, result) => {
	if (response.status === 403)
		return (
			"Request Forbidden" +
			(result && result.message ? `: ${result.message}` : "")
		);

	if (response.status === 500)
		return "Oops, something went wrong on our side!";

	if (result && result.message) return result.message;
	else
		return `${response.statusText || "API error"}`;
};

const createHeaders = () => {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");
	headers.append("X-CSRF-TOKEN", getCookieValue("CSRF-TOKEN"));
	return headers;
};

const getGenericSuccessMessage = method => {
	switch (method) {
		case "get":
			return "Received successfully";
		case "post":
			return "Created successfully";
		case "delete":
			return "Deleted successfully";
		case "put":
			return "Updated successfully";
		default:
			return "Request successful";
	}
};

export const createSuccessMessage = (dispatch, successNotification, method) => {
	if (successNotification) {
		const snackId = guid();
		dispatch(
			addNotification(
				snackId,
				typeof successNotification === "string"
					? successNotification
					: getGenericSuccessMessage(method),
				MessageType.SUCCESS
			)
		);
		window.setTimeout(() => dispatch(removeNotification(snackId)), 4000);
	}
};

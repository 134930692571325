import { Accordion as ExpansionPanel, AccordionDetails as ExpansionPanelDetails, AccordionSummary as ExpansionPanelSummary, AccordionActions } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import cx from "classnames";
import * as React from "react";

import { useAccordionStyles } from "./style";


interface AccordionProps {
	sections: Array<{
		title: React.ReactNode;
		content: React.ReactNode;
		onClick?: () => void;
		actions?: React.ReactNode;
	}>;
	initiallyActiveSectionIndex?: number;
	overrideActiveSectionIndex?: number;
	contentBorder?: boolean;
	dense?: boolean;
	onChange?: (activeSectionIndex: number) => void;
}

export const Accordion: React.FunctionComponent<AccordionProps> = (
	{
		initiallyActiveSectionIndex = -1,
		overrideActiveSectionIndex,
		sections,
		dense,
		contentBorder,
		onChange,
	}: AccordionProps,
) => {
	const classes = useAccordionStyles();

	const [ activeIndex, setActiveIndex ] = React.useState(initiallyActiveSectionIndex);
	const activeSectionIndex = overrideActiveSectionIndex || activeIndex;

	const handleChange = (sectionIndex: number) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
		const updatedActiveSectionIndex = expanded ? sectionIndex : -1;
		setActiveIndex(updatedActiveSectionIndex);
		onChange?.(updatedActiveSectionIndex);
	};
	const handleClick = (onClick?: () => void) => (event: React.MouseEvent<HTMLDivElement>) => {
		if (typeof onClick === "function") {
			event.stopPropagation();
			onClick();
		}
	};

	return (
		<div className={dense ? classes.rootDense : classes.root}>
			{sections.map((prop, key) => {
				return (
					<ExpansionPanel
						onClick={handleClick(prop.onClick)}
						expanded={activeSectionIndex === key}
						onChange={handleChange(key)}
						key={key}
						classes={{
							root: classes.expansionPanel,
							expanded: classes.expansionPanelExpanded,
						}}
					>
						<ExpansionPanelSummary
							expandIcon={<ExpandMore />}
							classes={{
								root: prop.onClick ? cx(classes.expansionPanelSummary, classes.expansionPanelContentAvailable) : classes.expansionPanelSummary,
								expanded: classes.expansionPanelSummaryExpaned,
								content: classes.expansionPanelSummaryContent,
								expandIcon: classes.expansionPanelSummaryExpandIcon,
							}}
						>
							<h4 className={classes.title}>{prop.title}</h4>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails
							className={cx(dense ? classes.expansionPanelDetailsDense : classes.expansionPanelDetails, { [classes.expansionPanelDetailsBorderBottom]: contentBorder })}>
							{prop.content}
						</ExpansionPanelDetails>
						{prop.actions !== undefined
							? (<AccordionActions className={cx(classes.expansionPanelActions, { [classes.expansionPanelActionsBorderBottom]: contentBorder })}>
									{prop.actions}
								</AccordionActions>)
							: null}
					</ExpansionPanel>
				);
			})}
		</div>
	);
};

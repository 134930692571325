import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import eventsApi from "actions/events";
import { createMemoize } from "helper/memoize";
import Theory from "views/Events/Theory";

const getTheory = createMemoize((theories, theoryId) =>
	theories.find(event => event.id === theoryId)
);

const getEvent = createMemoize((events, eventId) =>
	events.find(event => event.id === eventId)
);

const mapStateToProps = (state, ownProps) => {
	const theoryId = Number(ownProps.match.params.unitId);
	const eventId = Number(ownProps.match.params.eventId);

	return {
		theoryId,
		theory: getTheory(state.api.resources.theories.data, theoryId),
		event: getEvent(state.api.resources.events.data, eventId),
		isLoading:
			state.api.resources.events.pending || state.api.resources.theories.pending
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators({ fetchTheory: eventsApi.fetchTheory }, dispatch);

let UserTheory = connect(
	mapStateToProps,
	mapDispatchToProps
)(Theory);

export default UserTheory;

let UserTheoryBreadCrumb = ({ theory }) => (
	<span>{theory ? theory.title : "Theory"}</span>
);

const mapStateToPropsBreadCrumb = (state, ownProps) => {
	const theoryId = Number(ownProps.match.params.unitId);

	return { theory: state.api.resources.theories.data.find(t => t.id === theoryId) };
};

UserTheoryBreadCrumb = connect(mapStateToPropsBreadCrumb)(UserTheoryBreadCrumb);

export { UserTheoryBreadCrumb };

import { withStyles } from "@material-ui/core/styles";
import moment from "moment/moment";
import PropTypes from "prop-types";
import React from "react";
import createPlotlyComponent from "react-plotly.js/factory";
import { connect } from "react-redux";
import { compose } from "redux";

import { card, dangerColor, defaultFont, grayColor, infoColor, primaryColor, roseColor, successColor, warningColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import enhancedChartStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/enhancedChartStyle";
import { COLOR } from "helper/darkMode";

class EnhancedChart extends React.Component {
	render() {
		const {
			isTeam,
			chartData,
			isDataAvailable,
			emptyContent,
			darkMode
		} = this.props;

		let Plotly = require("plotly.js/lib/core");
		const Plot = createPlotlyComponent(Plotly);

		function cumulativesum(arr) {
			let result = arr.concat();
			for (let i = 0; i < arr.length; i++) {
				result[i] = arr.slice(0, i + 1).reduce(function(p, i) {
					return p + i;
				});
			}
			return result;
		}

		function colorpicker(i) {
			let colors = [
				primaryColor,
				"#ffa534",
				"#87cb16",
				warningColor,
				dangerColor,
				successColor,
				infoColor,
				roseColor,
				grayColor,
				card.color
			];
			if (i >= colors.length) {
				return colors[i % colors.length];
			}
			return colors[i];
		}

		const chartDataSliced = chartData.slice(0, 10);

		const teams = Object.keys(chartDataSliced);
		let traces = [];
		for (let i = 0; i < teams.length; i++) {
			let team_score = [];
			let times = [];
			for (let j = 0; j < chartDataSliced[teams[i]]["challenges"].length; j++) {
				if (chartDataSliced[teams[i]]["challenges"][j].points > 0) {
					team_score.push(chartDataSliced[teams[i]]["challenges"][j].points);
					let date = moment(
						chartDataSliced[teams[i]]["challenges"][j].lastUserSolutionTime
					);
					times.push(date.toDate());
				}
			}

			let scoreAndTimeList = [];
			for (let j = 0; j < times.length; j++) {
				scoreAndTimeList.push({ time: times[j], team_score: team_score[j] });
			}

			scoreAndTimeList.sort((a, b) =>
				moment(a.time).isBefore(b.time) ? -1 : 1
			);

			for (let k = 0; k < scoreAndTimeList.length; k++) {
				times[k] = scoreAndTimeList[k].time;
				team_score[k] = scoreAndTimeList[k].team_score;
			}

			/**
			 * Preparation for implementing a Button Switch to TurnOn/Off chartLineToEnd
			 * in higher component if wished
			 **/
			let chartLineToEnd = true;
			if (chartLineToEnd) {
				times.push(moment().toDate());
				team_score.push(0);
			}

			team_score = cumulativesum(team_score);

			const userOrTeamName = isTeam ? "teamName" : "username";

			let trace = {
				x: times,
				y: team_score,
				mode: "lines+markers",
				name: chartDataSliced[teams[i]][userOrTeamName],
				marker: {
					color: colorpicker(i),
					size: 6
				},
				line: {
					color: colorpicker(i),
					width: 3
				}
			};

			traces.push(trace);
		}

		traces.sort(function(a, b) {
			var scorediff = b["y"][b["y"].length - 1] - a["y"][a["y"].length - 1];
			if (!scorediff) {
				return a["x"][a["x"].length - 1] - b["x"][b["x"].length - 1];
			}
			return scorediff;
		});

		const layout = {
			title: "Chart",
			font: { family: defaultFont.fontFamily, size: 14, color: card.color },
			autosize: true,
			paper_bgcolor: card.background,
			plot_bgcolor: card.background,
			hovermode: "closest",
			xaxis: {
				showgrid: false,
				showspikes: true
			},
			yaxis: {
				showgrid: false,
				showspikes: true
			},
			legend: {
				orientation: "h",
				y: -0.25
			}
		};

		if (darkMode) {
			layout.font.color = COLOR.LIGHT_TEXT_1;
			layout.paper_bgcolor = COLOR.DARK_2;
			layout.plot_bgcolor = COLOR.DARK_2;
		}

		const style = { width: "100%", height: "100%" };

		const config = {
			displayModeBar: false,
			displaylogo: false
		};

		if (isDataAvailable) {
			return (
				<div>
					<Plot
						data={traces}
						layout={layout}
						config={config}
						useResizeHandler={true}
						style={style}
					/>
				</div>
			);
		} else {
			return <div>{emptyContent}</div>;
		}
	}
}

EnhancedChart.propTypes = {
	isTeam: PropTypes.bool,
	chartData: PropTypes.array,
	emptyContent: PropTypes.node
};

const mapStateToProps = state => ({ darkMode: state.ui.darkMode });

export default compose(
	withStyles(enhancedChartStyle),
	connect(mapStateToProps)
)(EnhancedChart);

// ##############################
// // // IconButton styles
// #############################

import { buttonStyle } from "@hlcr/mui/Button/style";

const { simple, ...other } = buttonStyle;
const iconButtonStyle = {
	...other,
	button: {
		height: "42px",
		minWidth: "42px",
		width: "42px",
		borderRadius: "50%",
		fontSize: "20px",
		padding: "11px 11px",
		lineHeight: "1em",
		margin: "auto",
		overflow: "hidden",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		userSelect: "none",
		backgroundImage: "none",
	},
	simple: {
		...simple,
		color: "#FFFFFF",
		background: "transparent",
		boxShadow: "none"
	},
};

export default iconButtonStyle;

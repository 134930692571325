import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teamleaderApi from "actions/teamleader";
import ProfileNotesEditor from "views/Teams/ProfileNotesEditor";

class TeamMemberProfile extends Component {
	componentDidMount() {
		const { fetchTeamMemberProfile, teamId, userIdentifier } = this.props;
		fetchTeamMemberProfile(teamId, userIdentifier);
	}

	onSave = notes => {
		const { saveTeamMemberNotes, teamId, userIdentifier } = this.props;
		saveTeamMemberNotes(teamId, userIdentifier, notes);
	};

	render() {
		const { member } = this.props;
		return (
			<ProfileNotesEditor
				onSubmit={this.onSave}
				notesField="teamLeaderNotes"
				member={member}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const teamId = Number(ownProps.match.params.teamId);
	const userIdentifier = ownProps.match.params.userId;
	const member = state.api.resources.teamleaderTeamMembers.data.find(tm => tm.teamId === teamId && tm.profile.userIdentifier === userIdentifier);
	return { teamId, userIdentifier, member };
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchTeamMemberProfile: teamleaderApi.fetchTeamMemberProfile,
			saveTeamMemberNotes: teamleaderApi.saveTeamMemberNotes
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(TeamMemberProfile);

import timelineStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/solutionTimelineStyle.jsx";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import TimeLineListItem from "components/Timeline/TimeLineListItem";

function SolutionTimeline({ classes, stories, simple, addAction }) {
	const timelineClass = cx({
		[classes.timeline]: true,
		[classes.timelineSimple]: simple,
	});

	if (addAction)
		stories.splice(0, 0, {
			badgeIcon: AddIcon,
			onClick: addAction,
			isDial: true,
		});

	return (
		<ul className={timelineClass}>
			{stories.map((comment, key) => (
				<TimeLineListItem
					comment={comment}
					simple={simple}
					addAction={addAction}
					key={key}
				/>
			))}
		</ul>
	);
}

SolutionTimeline.propTypes = {
	classes: PropTypes.object.isRequired,
	stories: PropTypes.arrayOf(PropTypes.object).isRequired,
	simple: PropTypes.bool,
};

export default withStyles(timelineStyle)(SolutionTimeline);

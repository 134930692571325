import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import { useIntl } from "@hlcr/ui/Intl";
import { Button, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { Link } from "react-router-dom";

import IconCard from "components/Cards/IconCard";
import { EVENT_MANAGER_ROUTES } from "eventManager/eventManager.routes";
import { MessageComposer } from "messaging/components/MessageComposer";

import { ClassSummary } from "./Class/ClassSummary";
import { EventSummary } from "./Events/EventSummary";
import { TeamSummary } from "./Teams/TeamSummary";
import { UserSummary } from "./Users/UserSummary";
import { VirtualMachineSummary } from "./VirtualMachines/VirtualMachineSummary";


interface EventManagerProps {
}

export const EventManager = () => {
	const intl = useIntl();
	const classes = useStyles();
	return (<>
		<Grid container={true} spacing={3}>
			<Grid item={true} xs={12}>
				<IconCard
					icon={EVENT_MANAGER_ROUTES.EVENTS.icon}
					iconColor={EVENT_MANAGER_ROUTES.EVENTS.iconColor}
					title={EVENT_MANAGER_ROUTES.EVENTS.name()}
					titleLink={EVENT_MANAGER_ROUTES.EVENTS.path}
					content={(<EventSummary />)}
					footer={<Link to={EVENT_MANAGER_ROUTES.EVENTS.path}><Button fullWidth={true}>{`${intl.fm("common.labels.goto")}`}&nbsp;<ArrowForward /></Button></Link>}
				/>
			</Grid>
			<Grid item={true} xs={12} md={6}>
				<IconCard
					icon={EVENT_MANAGER_ROUTES.USERS.icon}
					iconColor={EVENT_MANAGER_ROUTES.USERS.iconColor}
					title={EVENT_MANAGER_ROUTES.USERS.name()}
					titleLink={EVENT_MANAGER_ROUTES.USERS.path}
					content={<UserSummary />}
					footer={<Link to={EVENT_MANAGER_ROUTES.USERS.path}><Button fullWidth={true}>{`${intl.fm("common.labels.goto")}`}&nbsp;<ArrowForward /></Button></Link>}
				/>
			</Grid>
			<Grid item={true} xs={12} md={6}>
				<IconCard
					icon={EVENT_MANAGER_ROUTES.TEAMS.icon}
					iconColor={EVENT_MANAGER_ROUTES.TEAMS.iconColor}
					title={EVENT_MANAGER_ROUTES.TEAMS.name()}
					titleLink={EVENT_MANAGER_ROUTES.TEAMS.path}
					content={(<TeamSummary />)}
					footer={<Link to={EVENT_MANAGER_ROUTES.TEAMS.path}><Button fullWidth={true}>{`${intl.fm("common.labels.goto")}`}&nbsp;<ArrowForward /></Button></Link>}
				/>
			</Grid>
			<Grid item={true} xs={12} md={6}>
				<IconCard
					icon={EVENT_MANAGER_ROUTES.CLASSES.icon}
					iconColor={EVENT_MANAGER_ROUTES.CLASSES.iconColor}
					title={EVENT_MANAGER_ROUTES.CLASSES.name()}
					titleLink={EVENT_MANAGER_ROUTES.CLASSES.path}
					content={<ClassSummary />}
					footer={<Link to={EVENT_MANAGER_ROUTES.CLASSES.path}><Button fullWidth={true}>{`${intl.fm("common.labels.goto")}`}&nbsp;<ArrowForward /></Button></Link>}
				/>
			</Grid>
			<Grid item={true} xs={12} md={6}>
				<IconCard
					icon={EVENT_MANAGER_ROUTES.VIRTUAL_MACHINES.icon}
					iconColor={EVENT_MANAGER_ROUTES.VIRTUAL_MACHINES.iconColor}
					title={EVENT_MANAGER_ROUTES.VIRTUAL_MACHINES.name()}
					titleLink={EVENT_MANAGER_ROUTES.VIRTUAL_MACHINES.path}
					content={<VirtualMachineSummary />}
					footer={
						<Link to={EVENT_MANAGER_ROUTES.VIRTUAL_MACHINES.path}>
							<Button fullWidth={true}>{`${intl.fm("common.labels.goto")}`}&nbsp;<ArrowForward /></Button>
						</Link>
					}
				/>
			</Grid>
		</Grid>
		<MessageComposer asRole={HackingLabRole.COMPOSITE_MANAGER} />
	</>);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		fab: {
			position: "absolute",
			bottom: theme.spacing(5),
			right: theme.spacing(2),
		},
	}),
);

import { COLOR, COLOR_ROSE, DARK_MODE_SELECTOR } from "@hlcr/mui/theme/hacking-lab.theme";
import { createStyles, makeStyles } from "@material-ui/core";


const backgroundColor = "#f9f9f9";
const border = "1px solid #eee";

const expansionPanelDetails = {
	background: "#fff",
	border,
	borderTop: "none",
	borderBottomLeftRadius: "3px",
	borderBottomRightRadius: "3px",
	[DARK_MODE_SELECTOR]: {
		backgroundColor: COLOR.DARK_2,
		color: COLOR.LIGHT_TEXT_1,
	},
};

export const accordionStyle = createStyles({
	root: {
		flexGrow: 1,
		marginBottom: "20px",
	},
	rootDense: { flexGrow: 1 },
	expansionPanel: {
		boxShadow: "none",
		"&:before": { display: "none !important" },
	},
	expansionPanelExpanded: { margin: "0" },
	expansionPanelSummary: {
		border,
		backgroundColor,
		minHeight: "auto !important",
		padding: "10px 5px",
		borderRadius: "3px",
		color: "#3C4858",
		"&:hover": {
			color: COLOR_ROSE,
			backgroundColor: "#f3f3f3",
		},
		[DARK_MODE_SELECTOR]: {
			backgroundColor: COLOR.DARK_1,
			color: COLOR.LIGHT_TEXT_1,
		},
	},
	expansionPanelSummaryExpaned: {
		borderBottom: "none",
		// borderBottomLeftRadius: 0,
		// borderBottomRightRadius: 0,
		color: COLOR_ROSE,
		margin: 0,
	},
	expansionPanelSummaryContent: { margin: "0 !important" },
	expansionPanelSummaryExpandIcon: {
		color: "inherit",
		height: 16,
		width: 16,
		top: "auto",
		bottom: 0,
		marginRight: "5px",
	},
	title: {
		fontSize: "15px",
		fontWeight: "bolder",
		marginTop: "0",
		marginBottom: "0",
		color: "inherit",
	},
	expansionPanelDetails: {
		...expansionPanelDetails,
		padding: "15px 10px",
	},
	expansionPanelDetailsDense: {
		...expansionPanelDetails,
		padding: "0",
	},
	expansionPanelDetailsBorderBottom: { borderBottom: "1px solid #ddd" },
	expansionPanelContentAvailable: { backgroundColor: `${COLOR_ROSE}10` },
	expansionPanelActions: {
		padding: "5px 10px",
		backgroundColor: backgroundColor,
		border: border,
		borderTop: "none",
		borderBottomLeftRadius: "3px",
		borderBottomRightRadius: "3px",
	},
	expansionPanelActionsBorderBottom: { borderBottom: "1px solid #ddd" },
});

export const useAccordionStyles = makeStyles(accordionStyle);

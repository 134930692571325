import { Dto } from "@hlcr/core/api/RemoteResource";
import { HttpMethod } from "@hlcr/core/enum/HttpMethod";

import { RemoteResource } from "models/RemoteResource";
import { DynamicRequestAction, DynamicRequestActionWithUrlParameter, RemoteResourceActionType } from "redux/actions";

const BASE_URL = "/api/teacher";

interface FetchEventDataPayload extends Dto {
	eventId: number;
}

interface FetchEventUnitDataPayload extends Dto {
	eventUnitId: number;
}

export interface BulkSolutionCommentSubmissionDto extends Dto { // extends Dto is not really needed, but we need to satisfy the interfaces...
	grade?: number;
	comment: string;
	attachment?: {
		name: string;
		content: string;
	};

	userIds: number[];
	teamIds: number[];
}

export const fetchEventParticipantsUsers: DynamicRequestAction<FetchEventDataPayload> = (payload) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/events/${payload?.eventId}/participants/users/`,
	resource: RemoteResource.EVENT_PARTICIPANTS_USERS,
	method: HttpMethod.GET,
});

export const fetchEventParticipantsTeams: DynamicRequestAction<FetchEventDataPayload> = (payload) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/events/${payload?.eventId}/participants/teams/`,
	resource: RemoteResource.EVENT_PARTICIPANTS_TEAMS,
	method: HttpMethod.GET,
});

export const fetchEventUnitParticipantsUsersWithOpenSolutions: DynamicRequestAction<FetchEventUnitDataPayload> = (payload) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/events/units/${payload?.eventUnitId}/participants/users/`,
	resource: RemoteResource.EVENT_UNIT_PARTICIPANTS_USERS_WITH_OPEN_SOLUTIONS,
	method: HttpMethod.GET,
});

export const fetchEventUnitParticipantsTeamsWithOpenSolutions: DynamicRequestAction<FetchEventUnitDataPayload> = (payload) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/events/units/${payload?.eventUnitId}/participants/teams/`,
	resource: RemoteResource.EVENT_UNIT_PARTICIPANTS_TEAMS_WITH_OPEN_SOLUTIONS,
	method: HttpMethod.GET,
});

// unfortunately we need to specify any as type, as we don't want all the information in payload submitted as request body...
export const postTeacherSolutionCommentBulk: DynamicRequestActionWithUrlParameter<BulkSolutionCommentSubmissionDto, number> = (eventUnitId, submissionDto, onBefore, onSuccess, onFailure) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/solutions/units/${eventUnitId}/bulk_comment/`,
	method: HttpMethod.POST,
	payload: submissionDto,
	onBefore: onBefore,
	onSuccess: onSuccess,
	onFailure: onFailure,
	successNotification: "Created successfully",
});

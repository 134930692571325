const teacherEventStyle = {
	topInfoCards: {
		display: "flex",
		marginTop: 16,
		"&>div": { margin: "5px" }
	},
	infoCardFooter: {
		display: "flex",
		justifyContent: "space-between"
	},
	openSolutionsFooter: {
		display: "flex",
		alignItems: "center"
	},
	autoReloadLabel: {
		cursor: "pointer",
		lineHeight: "23px"
	},
	cardStatsIcon: {
		position: "relative",
		margin: "0 6px",
		top: "4px",
		width: "16px",
		height: "16px"
	},
	filterField: {
		float: "right",
		marginTop: "-22px"
	},
	filterFieldObj: {
		marginBottom: 0,
		marginTop: 0
	},
	filterFieldInput: { paddingTop: 21 },
	filterBox: {
		display: "flex",
		flexDirection: "column"
	},
	editButton: {
		margin: "5px 2px",
		padding: "5px",
		whiteSpace: "normal"
	},
	icon: {
		verticalAlign: "middle",
		width: "17px",
		height: "17px",
		top: "-1px",
		position: "relative"
	}
};

export default teacherEventStyle;

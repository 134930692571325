import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withIntl } from "@hlcr/ui/Intl";
import { Badge } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpIcon from "@material-ui/icons/Help";
import ChatIcon from "@material-ui/icons/Chat";
import InboxIcon from "@material-ui/icons/Inbox";
import SettingsIcon from "@material-ui/icons/Settings";
import cx from "classnames";
import PerfectScrollbar from "perfect-scrollbar";
import PropTypes, { arrayOf, bool, object, oneOf, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators, compose } from "redux";

import sidebarStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/sidebarStyle";
import { doLogout, getCurrentUsername } from "auth/authUtils";
import { isTouchDevice } from "helper/browser";
import HeaderLinks from "layouts/HeaderLinks";
import inboxApi from "messaging/actions";
import { checkUserProfileRequirementPolicyViolation } from "helper/policy";
import { RemoteResource } from "models/RemoteResource";

class SidebarWrapper extends React.Component {
	ps;

	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1 || !isTouchDevice()) {
			this.ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
			if (this.ps) this.ps.scrollbarYRail.style.backgroundColor = "unset";
		}
	}

	componentDidUpdate() {
		if (this.ps) this.ps.update();
	}

	componentWillUnmount() {
		if (this.ps) this.ps.destroy();
	}

	render() {
		const { className, user, headerLinks, links } = this.props;
		return (
			<div className={className} ref="sidebarWrapper">
				{user}
				{headerLinks}
				{links}
			</div>
		);
	}
}

class Sidebar extends React.Component {
	FETCH_MESSAGES_POLLING_INTERVALL = 60 * 1000;

	componentDidMount() {
		setInterval(() => {
			this.props.fetchUnreadMessages();
		}, this.FETCH_MESSAGES_POLLING_INTERVALL);

		this.props.fetchUnreadMessages();
	}

	constructor(props) {
		super(props);
		this.state = {
			openAvatar: false,
			openManagerMenu: this.activeRoute("/manager"),
			miniActive: true,
		};

		this.activeRoute.bind(this);
	}

	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname.startsWith(routeName);
	}

	openCollapse(collapse) {
		const st = {};
		st[collapse] = !this.state[collapse];
		this.setState(st);
	}

	allowMinified() {
		const { width } = this.props;
		switch (width) {
			case "xs":
			case "sm":
				return false;
			case "md":
			case "lg":
			case "xl":
			default:
				return true;
		}
	}

	userInfo(userWrapperClass, itemText, collapseItemText, caret, collapseItemMini) {
		const { classes, color, intl, realm, ssoRealm, unreadMessageCount, tenantAccessPolicy, personalProfile } = this.props;

		let tenantAccessPolicyViolated = false;
		if (tenantAccessPolicy?.userProfileRequirements && personalProfile) {
			tenantAccessPolicyViolated = checkUserProfileRequirementPolicyViolation(tenantAccessPolicy.userProfileRequirements, personalProfile);
		}

		const iconUnreadBadge = !this.state.openAvatar ? unreadMessageCount || 0 : null;
		const showIconWarningBatch = !this.state.openAvatar && tenantAccessPolicyViolated;
		const isSignedInViaSSO = window.keycloak?.tokenParsed?.identity_provider === ssoRealm; // identity_provider is a custom set attribute in keycloal

		return (
			<div className={userWrapperClass}>
				<div className={classes.photo}>
					<Badge badgeContent={"!"} overlap={"circular"} color={"secondary"} anchorOrigin={{ vertical: "top", horizontal: "right" }} invisible={!showIconWarningBatch}>
						<Badge badgeContent={iconUnreadBadge} overlap={"circular"} color="primary" max={99} anchorOrigin={{ vertical: "top", horizontal: "left" }}>
							<AccountCircleIcon className={classes.avatarIcon} />
						</Badge>
					</Badge>
				</div>
				<List className={classes.list}>
					<ListItem className={cx(classes.item, classes.userItem)}>
						<NavLink to={"#"} className={cx(classes.itemLink, classes.userCollapseButton)} onClick={() => this.openCollapse("openAvatar")}>
							<ListItemText
								primary={<span className={classes.username}>{getCurrentUsername()}</span>}
								secondary={<b className={cx(caret, classes.userCaret, { [classes.caretActive]: this.state.openAvatar })} />}
								disableTypography={true}
								className={cx(itemText, classes.userItemText)}
							/>
						</NavLink>
						<Collapse in={this.state.openAvatar}>
							<List className={cx(classes.list, classes.collapseList)}>
								<ListItem className={classes.collapseItem}>
									<NavLink to={"/inbox"} className={cx(classes.collapseItemLink, { [classes[color]]: this.activeRoute("inbox") })}>
										<span className={collapseItemMini}>
											<Badge badgeContent={unreadMessageCount || 0} color="primary" max={99} anchorOrigin={{ vertical: "top", horizontal: "left" }}>
												{/* <MailIcon />*/}
												<InboxIcon />
											</Badge>
										</span>
										<ListItemText primary={intl.fm("navigation.entries.user.inbox")} disableTypography={true} className={collapseItemText} />
									</NavLink>
								</ListItem>
								<ListItem className={classes.collapseItem}>
									<a href={`${window.keycloak.authServerUrl}/realms/${isSignedInViaSSO ? ssoRealm : realm}/account/`} target="_blank" rel="noopener noreferrer" className={cx(classes.itemLink, classes.userCollapseLinks)}>
										<span className={collapseItemMini}>
											<SettingsIcon />
										</span>
										<ListItemText primary={intl.fm("navigation.entries.user.account")} disableTypography={true} className={collapseItemText} />
									</a>
								</ListItem>
								<ListItem className={classes.collapseItem}>
									<NavLink to={"/profile"} className={cx(classes.collapseItemLink, { [classes[color]]: this.activeRoute("profile") })}>
										<span className={collapseItemMini}>
											<Badge badgeContent={"!"} overlap={"circular"} color={"secondary"} anchorOrigin={{ vertical: "top", horizontal: "left" }} invisible={!tenantAccessPolicyViolated}>
												<AccountBoxIcon />
											</Badge>
										</span>
										<ListItemText primary={intl.fm("navigation.entries.user.profile")} disableTypography={true} className={collapseItemText} />
									</NavLink>
								</ListItem>
								<ListItem className={classes.collapseItem}>
									<div className={cx(classes.itemLink, classes.userCollapseLinks)} onClick={doLogout}>
										<span className={collapseItemMini}>
											<ExitToAppIcon />
										</span>
										<ListItemText primary={intl.fm("navigation.entries.user.logout")} disableTypography={true} className={collapseItemText} />
									</div>
								</ListItem>
							</List>
						</Collapse>
					</ListItem>
				</List>
			</div>
		);
	}

	getRouteIcon(route) {
		const { classes } = this.props;
		if (route.icon) {
			return <route.icon />;
		}
		if (route.faIcon) {
			return <FontAwesomeIcon className={classes.sideBarfaIcon} icon={route.faIcon} />;
		}
		if (route.mini) {
			return <span className={classes.miniText}>{route.mini}</span>;
		}
		return null;
	}

	subRoutesLinks = (color, collapseItemMini, collapseItemText) => (subRoute, subRouteKey) => {
		const { classes } = this.props;
		if (subRoute.redirect || !subRoute.name || !subRoute.mini) {
			return null;
		}
		const subNavLinkClasses = cx(classes.collapseItemLink, { [classes[color]]: this.activeRoute(subRoute.path) });
		const subRouteName = typeof subRoute.name === "function" ? subRoute.name() : subRoute.name;
		return (
			<ListItem key={subRouteKey} className={classes.collapseItem} style={{ paddingLeft: 8 }}>
				<NavLink to={subRoute.path} className={subNavLinkClasses}>
					<span className={collapseItemMini}>{subRoute.mini}</span>
					<ListItemText primary={subRouteName} disableTypography={true} className={collapseItemText} />
				</NavLink>
			</ListItem>
		);
	};

	collapseRoutesLinks(itemIcon, collapseItemText, caret, collapseItemMini, itemText, route, key) {
		const { color, classes } = this.props;
		const navLinkClassesCollapse = cx(classes.itemLink, { [classes.collapseActive]: this.activeRoute(route.path) });

		const routeName = typeof route.name === "function" ? route.name() : route.name;
		return (
			<ListItem key={key} className={classes.item}>
				<NavLink to={"#"} className={navLinkClassesCollapse} onClick={() => this.openCollapse(route.state)}>
					<ListItemIcon className={itemIcon}>{this.getRouteIcon(route)}</ListItemIcon>
					<ListItemText primary={routeName} secondary={<b className={cx(caret, { [classes.caretActive]: this.state[route.state] })} />} disableTypography={true} className={itemText} />
				</NavLink>
				<Collapse in={this.state[route.state]}>
					<List className={cx(classes.list, classes.collapseList)}>{route.routes.map(this.subRoutesLinks(color, collapseItemMini, collapseItemText))}</List>
				</Collapse>
			</ListItem>
		);
	}

	linksNavigation(routes, itemIcon, collapseItemText, caret, collapseItemMini, itemText) {
		const { classes, color, intl, helpUrl, showGlobalChat } = this.props;

		return (
			<List className={classes.list}>
				{routes.map((route, key) => {
					if (route.collapse) {
						return this.collapseRoutesLinks(itemIcon, collapseItemText, caret, collapseItemMini, itemText, route, key);
					}
					const navLinkClasses = cx(classes.itemLink, { [classes[color]]: this.activeRoute(route.path) });
					const routeName = typeof route.name === "function" ? route.name() : route.name;
					return (
						<ListItem key={key} className={classes.item}>
							<NavLink to={route.path} className={navLinkClasses}>
								<ListItemIcon className={itemIcon}>{this.getRouteIcon(route)}</ListItemIcon>
								<ListItemText primary={routeName} disableTypography={true} className={itemText} />
							</NavLink>
						</ListItem>
					);
				})}

				{showGlobalChat && (
					<ListItem className={classes.item}>
						<a href={"https://chat.hacking-lab.com/#/room/#global:hacking-lab.com"} target="_blank" rel="noopener noreferrer" className={classes.itemLink}>
							<ListItemIcon className={itemIcon}>
								<ChatIcon />
							</ListItemIcon>
							<ListItemText primary={intl.fm("navigation.entries.global-chat")} disableTypography={true} className={itemText} />
						</a>
					</ListItem>
				)}

				<ListItem className={classes.item}>
					<a href={helpUrl} target="_blank" rel="noopener noreferrer" className={classes.itemLink}>
						<ListItemIcon className={itemIcon}>
							<HelpIcon />
						</ListItemIcon>
						<ListItemText primary={intl.fm("navigation.entries.help")} disableTypography={true} className={itemText} />
					</a>
				</ListItem>
			</List>
		);
	}

	getBrandTop(isMiniActive, logoClasses, logoNormal) {
		const { classes, logo, logoMini, logoText } = this.props;
		return (
			<div className={logoClasses}>
				<div className={cx({ [classes.imgContainer]: !isMiniActive })}>
					<img src={isMiniActive ? logoMini : logo} alt="logo" className={cx(classes.img, { [classes.imgMini]: isMiniActive })} />
				</div>
				{logoText && <div className={logoNormal}>{logoText}</div>}
			</div>
		);
	}

	getBrandBottom(isMiniActive, sidebarMinimize, logoClasses, logoNormal) {
		const { classes, miniActive, logoBottom, logoMiniBottom, logoTextBottom, intl } = this.props;
		return (
			<div className={logoClasses}>
				<div className={cx({ [classes.imgContainer]: !isMiniActive })}>
					<img src={isMiniActive ? logoMiniBottom : logoBottom} alt="logo" className={cx(classes.img, { [classes.imgMini]: isMiniActive })} />
				</div>
				{logoTextBottom && <div className={logoNormal}>{logoTextBottom}</div>}
				{this.allowMinified() && (
					<div className={sidebarMinimize} onClick={this.props.sidebarMinimize}>
						{miniActive ? (
							<div className={classes.itemText}>
								<FontAwesomeIcon className={cx(classes.itemIcon, classes.sideBarfaIcon)} icon="angle-double-right" />
								&nbsp;
							</div>
						) : (
							<div className={classes.itemText}>
								<FontAwesomeIcon className={cx(classes.itemIcon, classes.sideBarfaIcon)} icon="angle-double-left" />
								{intl.fm("common.labels.collapseSidebar")}
							</div>
						)}
					</div>
				)}
			</div>
		);
	}

	render() {
		const { classes, image, routes, bgColor, rtlActive, miniActive } = this.props;
		const stateMiniActive = this.state.miniActive;
		const isMiniActive = this.allowMinified() && miniActive && stateMiniActive;

		const itemText = cx(classes.itemText, {
			[classes.itemTextMini]: isMiniActive,
			[classes.itemTextMiniRTL]: rtlActive && isMiniActive,
			[classes.itemTextRTL]: rtlActive,
		});

		const collapseItemText = cx(classes.collapseItemText, {
			[classes.collapseItemTextMini]: isMiniActive,
			[classes.collapseItemTextMiniRTL]: rtlActive && isMiniActive,
			[classes.collapseItemTextRTL]: rtlActive,
		});

		const userWrapperClass = cx(classes.user, { [classes.whiteAfter]: bgColor === "white" });

		const caret = cx(classes.caret, { [classes.caretRTL]: rtlActive });

		const collapseItemMini = cx(classes.collapseItemMini, { [classes.collapseItemMiniRTL]: rtlActive });

		const itemIcon = cx(classes.itemIcon, { [classes.itemIconRTL]: rtlActive });

		const user = this.userInfo(userWrapperClass, itemText, collapseItemText, caret, collapseItemMini);
		const links = this.linksNavigation(routes, itemIcon, collapseItemText, caret, collapseItemMini, itemText);

		const logoNormal = cx(classes.logoNormal, {
			[classes.logoNormalSidebarMini]: isMiniActive,
			[classes.logoNormalSidebarMiniRTL]: rtlActive && isMiniActive,
			[classes.logoNormalRTL]: rtlActive,
		});

		const logoClasses = cx(classes.logo, { [classes.whiteAfter]: bgColor === "white" });

		const sidebarMinimize = cx(classes.sidebarMinimize, classes.itemLink, { [classes.sidebarMinimizeRTL]: rtlActive });

		const brand = this.getBrandTop(isMiniActive, logoClasses, logoNormal);

		const brandBottom = this.getBrandBottom(isMiniActive, sidebarMinimize, logoClasses, logoNormal);

		const drawerPaper = cx(classes.drawerPaper, {
			[classes.drawerPaperMini]: isMiniActive,
			[classes.drawerPaperRTL]: rtlActive,
		});

		const sidebarWrapper = cx(classes.sidebarWrapper, {
			[classes.drawerPaperMini]: isMiniActive,
			[classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1 || !isTouchDevice(),
		});

		return (
			<div ref="mainPanel">
				<Hidden mdUp>
					<Drawer
						variant="temporary"
						anchor={rtlActive ? "right" : "left"}
						open={this.props.open}
						classes={{ paper: cx(drawerPaper, classes[bgColor + "Background"]) }}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							// Better open performance on mobile.
							keepMounted: true,
						}}
					>
						{brand}
						<SidebarWrapper className={sidebarWrapper} user={user} headerLinks={<HeaderLinks rtlActive={rtlActive} />} links={links} />
						{image !== undefined ? <div className={classes.background} style={{ backgroundImage: `url(${image})` }} /> : null}
						{brandBottom}
					</Drawer>
				</Hidden>
				<Hidden smDown>
					<Drawer anchor={rtlActive ? "right" : "left"} variant="permanent" open classes={{ paper: cx(drawerPaper, classes[bgColor + "Background"]) }}>
						{brand}
						<SidebarWrapper className={sidebarWrapper} user={user} links={links} />
						{image !== undefined ? <div className={classes.background} style={{ backgroundImage: `url(${image})` }} /> : null}
						{brandBottom}
					</Drawer>
				</Hidden>
			</div>
		);
	}
}

Sidebar.defaultProps = { bgColor: "blue" };

Sidebar.propTypes = {
	classes: object.isRequired,
	bgColor: oneOf(["white", "black", "blue"]),
	rtlActive: bool,
	color: oneOf(["white", "red", "orange", "green", "blue", "purple", "rose"]),
	logo: string,
	logoMini: string,
	logoText: string,
	logoBottom: string.isRequired,
	logoMiniBottom: string.isRequired,
	logoTextBottom: string,
	image: string,
	routes: arrayOf(object),
	realm: string.isRequired,
	ssoRealm: string.isRequired,
	location: object,
	fetchTeacherEvent: PropTypes.func,
	fetchUnreadMessages: PropTypes.func,
	unreadMessageCount: PropTypes.number,
	tenantAccessPolicy: object,
	personalProfile: object,
};

const mapStateToProps = (state) => ({
	helpUrl: state.branding.helpUrl,
	unreadMessageCount: state.api.resources.unreadMessageCount.data,
	tenantAccessPolicy: state.remoteResourceReducer.remoteResources[RemoteResource.TENANT_ACCESS_POLICY].data,
	personalProfile: state.remoteResourceReducer.remoteResources[RemoteResource.USER_PROFILE].data,
	showGlobalChat: state.branding.showGlobalChat,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			fetchUnreadMessages: inboxApi.fetchUnreadMessages,
		},
		dispatch
	);

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(sidebarStyle), withIntl, withWidth())(Sidebar);

import { defaultFont, primaryColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";

const radioGroupStyle = {
	radio: { color: primaryColor },
	label: {
		...defaultFont,
		color: "rgba(0, 0, 0, 0.87)",
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 }
	},
	switchBase: {
		color: primaryColor,
		"&$checked": {
			color: primaryColor,
			"& + $colorBar": { backgroundColor: primaryColor }
		}
	},
	colorBar: {},
	checked: {},
	icon: {
		border: "1px solid",
		borderColor: primaryColor,
		boxShadow: "0 1px 3px 1px rgba(0, 0, 0, 0.4)",
		color: "#FFFFFF !important",
		transform: "translateX(-4px)!important"
	},
	iconChecked: {
		borderColor: primaryColor,
		transform: "translateX(4px)!important"
	}
};
export default radioGroupStyle;

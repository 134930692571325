import { useSelector } from "react-redux";

import managerApi from "actions/manager";
import { Class } from "models/Class";
import { RootState } from "reducers";
import { EventManagerSummary } from "views/Manager/EventManagerSummary";


interface ClassSummaryProps {
}

export const ClassSummary = (props: ClassSummaryProps) => {
	const loadingSelector = (state: RootState) => state.api.resources.managerClasses.pending;
	const classes: Class[] = useSelector((state: RootState) => state.api.resources.managerClasses.data);

	// @formatter:off
	const marks = [
		{ value: 0, label: "Archived", quantity: classes.filter((c) => !!c.archived).length },
		{ value: 1, label: "Current", quantity: classes.filter((c) => !c.archived).length },
	];
	// @formatter:on

	return <EventManagerSummary
		marks={marks}
		stateInitializers={[ managerApi.fetchManagerClasses ]}
		loadingSelector={loadingSelector}
	/>;
};

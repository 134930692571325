import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FolderIcon from "@material-ui/icons/FolderOpen";
import SchoolIcon from "@material-ui/icons/School";
import GavelIcon from "@material-ui/icons/Gavel";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";

import Tooltip from "components/CustomTooltip/CustomTooltip";
import { EventType } from "models/EventType";

import commonEventCardStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/commonEventCardStyle";
import { Button } from "@hlcr/mui/Button";
import { useIntl } from "@hlcr/ui/Intl";

class CommonEventCard extends React.Component {
	state = { descriptionShown: false };

	switchDescription = () => {
		this.setState(prevState => ({ descriptionShown: !prevState.descriptionShown }));
	};

	render() {
		const {
			event,
			termAgreement,
			image,
			archived,
			subTitle,
			contentText,
			footer,
			showFooter,
			classes,
			onTermsBowActionClick,
		} = this.props;
		const { descriptionShown } = this.state;

		const overlayActions = this.props.overlayActions
			? this.props.overlayActions.filter(action => !action.hidden)
			: [];
		const actions = this.props.actions
			? this.props.actions.filter(action => !action.hidden)
			: [];

		return (
			<Card className={classes.card}>
				<CardHeader
					className={classes.cardHeader}
					subheader={
						<EventCardHeader
							classes={classes}
							overlayActions={overlayActions}
							image={image}
							event={event}
							termAgreement={termAgreement}
							archived={archived}
							onTermsBowActionClick={onTermsBowActionClick}
						/>
					}
				/>
				<CardContent className={classes.cardContent}>
					<Typography variant="h6" component="h4" className={classes.cardTitle}>
						{event.name}
					</Typography>
					<Typography component="p" className={classes.cardCategory}>
						{subTitle}
					</Typography>
					<Typography component="p" className={classes.contentText}>
						{contentText}
					</Typography>
				</CardContent>
				<div>
					<Collapse in={showFooter}>
						<div className={classes.cardActions}>{footer}</div>
					</Collapse>
					<Collapse in={!showFooter}>
						<div className={classes.cardActions}>
							<div className={classes.cardStats}>
								{actions.filter(action => action.cardStat).map((action, index) => {
									let classesActionItem = classes.cardActionItem;
									let actionItem;
									if (action.icon)
										actionItem = (
											<div
												key={index}
												className={classesActionItem}
											>
												<div className={classes.cardStatContent}>
													<action.icon className={classes.cardStatsIcon} />
													{action.cardStat}
												</div>
											</div>
										);
									else if (action.faIcon)
										actionItem = (
											<div
												key={index}
												className={classesActionItem}
											>
												<div className={classes.cardStatContent}>
													<FontAwesomeIcon
														icon={action.faIcon}
														className={classes.cardStatsIcon}
													/>
													{action.cardStat}
												</div>
											</div>
										);
									else
										actionItem = (
											<div
												key={index}
												className={classes.cardActionItem}
											>
												<div className={classes.cardStatContent}>
													{action.cardStat}
												</div>
											</div>
										);
									return actionItem;
								})}
							</div>
							{actions.filter(action => !action.cardStat).map((action, index) => {
								let classesActionItem = classes.cardActionItem;
								let actionItem;
								if (action.to && action.icon)
									actionItem = (
										<Link
											key={index}
											to={action.to}
											className={classesActionItem}
										>
											<Button
												color="infoNoBackground"
												customClass={classes.cardActionButton}
											>
												<action.icon className={classes.buttonIcon} />
											</Button>
										</Link>
									);
								else if (action.to)
									actionItem = (
										<Link
											key={index}
											to={action.to}
											className={classesActionItem}
										>
											<Button
												color="infoNoBackground"
												customClass={classes.cardActionButton}
											>
												{action.label}
											</Button>
										</Link>
									);
								else if (action.onClick)
									actionItem = (
										<Button
											key={index}
											onClick={() => {
												if (action.onClick && event.id) action.onClick(event.id);
											}}
											color="infoNoBackground"
											customClass={cx(
												classesActionItem,
												classes.cardActionButton,
											)}
										>
											{action.label}
										</Button>
									);
								else if (action.component)
									actionItem = (
										<span key={index} className={classesActionItem}>
											{action.component}
										</span>
									);
								else
									actionItem = (
										<Button
											key={index}
											color="infoNoBackground"
											disabled
											customClass={cx(
												classes.cardActionItem,
												classes.cardActionButton,
											)}
										>
											{action.label}
										</Button>
									);
								if (action.tooltip) {
									return <Tooltip key={index} placement={"bottom"} title={action.tooltip}>{actionItem}</Tooltip>;
								} else {
									return actionItem;
								}
							})}
							<Button
								color="defaultNoBackground"
								disabled={!event.description}
								onClick={this.switchDescription}
								customClass={cx(
									classes.cardActionItem,
									classes.cardCollapseButton,
								)}
							>
								{descriptionShown ? <ExpandLessIcon /> : <ExpandMoreIcon />}
							</Button>
						</div>
					</Collapse>
					<Collapse in={descriptionShown && !showFooter}>
						<Typography component="div" className={classes.eventDescription}>
							{event.description}
						</Typography>
					</Collapse>
				</div>
			</Card>
		);
	}

	static defaultProps = { disabled: false };
}

const EventCardHeader = ({
	                         classes,
	                         overlayActions,
	                         image,
	                         event,
	                         termAgreement,
	                         archived,
	                         onTermsBowActionClick,
                         }) => {

	const termsIsAccepted = termAgreement?.accepted || !termAgreement && event.termAccepted;
	const intl = useIntl();

	const [backgroundImage, setBackgroundImage] = useState(image + "?size=VERY_SMALL");
	const highResImage = image + "?size=LARGE";

	useEffect(() => {
		const img = new Image();
		img.src = highResImage
		img.onload = () => {
			setBackgroundImage(highResImage);
		};
		return () => {
			img.onload = null;
		};
	}, [highResImage]);

	return (
		<div
			className={classes.cardSubheader}
			style={{ backgroundImage: `url('${backgroundImage}')` }}
		>
			{event.type === EventType.CURRICULUM ? (
				<Tooltip /* title="Curriculum"*/ placement="top-start">
					<div>
						<div className={classes.curriculumBow} />
						<SchoolIcon className={classes.curriculumBowIcon} />
					</div>
				</Tooltip>
			) : event.exam ? (
				<Tooltip /* title="Exam"*/ placement="top-start">
					<div>
						<div className={classes.examBow} />
						<AssignmentLateIcon className={classes.curriculumBowIcon} />
					</div>
				</Tooltip>
			) : null}
			{archived && (
				<Tooltip /* title="Archived"*/ placement="top-end">
					<div>
						<div className={classes.archivedBow} />
						<FolderIcon className={classes.archivedBowIcon} />
					</div>
				</Tooltip>
			)}
			{!archived && event.termId && (
				<Tooltip placement="top-end">
					<div onClick={onTermsBowActionClick} className={classes.termBowHover}>
						<div className={termsIsAccepted ? classes.termBowAccepted : classes.termBowNotAccepted} />
						<GavelIcon className={classes.termBowIcon} />
					</div>
				</Tooltip>
			)}
			{event.teaching && (
				<div className={classes.teachingBow}>
					<b>{intl.fm("teacher.entityName")}</b>
				</div>
			)}
			{overlayActions.length === 1 && (
				<OneActionOverlay
					classes={classes}
					action={overlayActions[0]}
					event={event}
				/>
			)}

			{overlayActions.length > 1 && (
				<div className={classes.cardOverlay}>
					{overlayActions.map((action, index) =>
						                    !action.onClick && action.to && action.icon ? (
							                    <div
								                    key={index}
								                    className={cx({ [classes.cardOverlayTextMargin]: index > 0 })}
							                    >
								                    <Link
									                    key={index}
									                    to={action.to || ""}
									                    className={classes.linkRadius}
								                    >
									                    <div className={cx(classes.cardOverlayText)}>
										                    <ActionIcon action={action} classes={classes} />
									                    </div>
								                    </Link>
							                    </div>
						                    ) : (
							                    <div
								                    key={index}
								                    className={cx(classes.cardOverlayText, { [classes.cardOverlayTextMargin]: index > 0 })}
								                    onClick={() =>
									                    action.onClick && event.id && action.onClick(event.id)
								                    }
							                    >
								                    <ActionIcon action={action} classes={classes} />
							                    </div>
						                    ),
					)}
				</div>
			)}
		</div>
	);
};

const OverlayWithActionWrapper = ({ action, children, event, classes }) =>
	!action.onClick && action.to && action.icon ? (
		<Link to={action.to || ""}>
			<div className={classes.cardOverlay}>{children}</div>
		</Link>
	) : (
		<div
			className={cx(classes.cardOverlay, classes.clickable)}
			onClick={() => action.onClick && event.id && action.onClick(event.id)}
		>
			{children}
		</div>
	);

const OneActionOverlay = ({ classes, action, event }) => (
	<OverlayWithActionWrapper classes={classes} action={action} event={event}>
		<div className={classes.cardOverlayText}>
			<ActionIcon action={action} classes={classes} />
		</div>
	</OverlayWithActionWrapper>
);

const ActionIcon = ({ action, classes }) =>
	action.icon ? (
		<action.icon className={classes.overlayLinkIcon} />
	) : action.faIcon ? (
		<FontAwesomeIcon icon={action.faIcon} className={classes.overlayLinkIcon} />
	) : undefined;

CommonEventCard.propTypes = {
	event: PropTypes.object.isRequired,
	termAgreement: PropTypes.object,
	classes: PropTypes.object.isRequired,
	image: PropTypes.string.isRequired,
	archived: PropTypes.bool,
	actions: PropTypes.arrayOf(PropTypes.object),
	subTitle: PropTypes.node,
	subSubTitle: PropTypes.node,
	contentText: PropTypes.node,
	overlayActions: PropTypes.arrayOf(PropTypes.object),
	footer: PropTypes.node,
	showFooter: PropTypes.bool,
	onTermsBowActionClick: PropTypes.func,
};

export default compose(withStyles(commonEventCardStyle))(CommonEventCard);

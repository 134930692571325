export const ONE_HUNDRED_PERCENT = 100;

export const percentage = (input: any, options?: { fallback?: number, min?: number, max?: number, precision?: number}): number => {
	const opts = { fallback: 0, min: 0, max: 100, precision: 1, ...options };
	const value = Math.round(Number.parseFloat(input) * ONE_HUNDRED_PERCENT / opts.precision) * opts.precision;

	if (isNaN(value)) {
		return opts.fallback;
	}

	return Math.min(opts.max, Math.max(opts.min, value));
};

export const percent = (input: any, options?: { fallback?: number, min?: number, max?: number, precision?: number}): number => {
	const opts = { fallback: 0, min: 0, max: 1, precision: 0.01, ...options };
	const value = Math.round(Number.parseFloat(input) / ONE_HUNDRED_PERCENT / opts.precision) * opts.precision;

	if (isNaN(value)) {
		return opts.fallback;
	}

	return Math.min(opts.max, Math.max(opts.min, value));
};

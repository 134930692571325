import { percentage } from "@hlcr/core/numeric";
import { useIntl } from "@hlcr/ui/Intl";
import { makeStyles } from "@material-ui/core";

import { formatFullDate } from "helper/dateCalc";
import { ASSET_TYPES, AssetType } from "models/Asset";
import { EVENT_UNIT_MODES, EventUnit, EventUnitChallenge, EventUnitMode, GRADING_MODES, GradingMode, SolvableEventUnit } from "models/EventUnit";


interface EventUnitSummaryProps {
	unit: EventUnit;
}

export const EventUnitSummary = ({ unit }: EventUnitSummaryProps) => {
	const intl = useIntl();
	const classes = useStyles();

	let summary = "";
	if (AssetType.THEORY === unit.type) {
		summary = intl.fm(ASSET_TYPES.THEORY.title);
	} else {
		const solvableEventUnit = unit as SolvableEventUnit;

		summary = `${solvableEventUnit.maxPoints} ${intl.fm("manager.eventDetails.units.table.maxPoints")}`;
		summary += `, ${intl.fm(EVENT_UNIT_MODES[solvableEventUnit.mode].title)}`;

		if (solvableEventUnit.mode === EventUnitMode.STEPS_MODE) {
			summary += ` (${intl.fm("manager.eventDetails.units.overview.stepTitlesPenalty")}: ${percentage(solvableEventUnit.stepTitlesPenalty, { fallback: 0 })}%, `;
			summary += `${intl.fm("manager.eventDetails.units.overview.fullPenaltyMaximumGrade")}: ${percentage(solvableEventUnit.fullPenaltyMaxGrade, { fallback: 0 })}%)`;
		}

		if (solvableEventUnit.type === AssetType.CHALLENGE) {
			if (solvableEventUnit.mode === EventUnitMode.TRAINING_MODE) {
				summary += ` (${intl.fm("manager.eventDetails.units.overview.maxGrade")}: ${percentage(solvableEventUnit.fullPenaltyMaxGrade, { fallback: 0 })}%)`;
			}

			const eventUnitChallenge = solvableEventUnit as EventUnitChallenge;
			summary += `, ${intl.fm(GRADING_MODES[eventUnitChallenge.grading].title)}`;
			if (eventUnitChallenge.grading === GradingMode.WRITEUP_FLAG) {
				summary += ` (${intl.fm("manager.eventDetails.units.table.flagWeight")}: ${eventUnitChallenge.flagWeight ? percentage(eventUnitChallenge.flagWeight) : 0}%)`;
			}
		}
	}


	let dates: string = "";
	if (unit.startTime) {
		dates = `${intl.fm("manager.eventDetails.curriculumEvents.table.startTime")} ${formatFullDate(unit.startTime)}`;
		if (unit.endTime) {
			dates += " - ";
		}
	}
	if (unit.endTime) {
		dates += `${intl.fm("manager.eventDetails.curriculumEvents.table.endTime")} ${formatFullDate(unit.endTime)}`;
	}

	return (
		<div>
			{summary}
			<br />
			<span className={classes.dates}>{dates}</span>
		</div>
	);
};

const useStyles = makeStyles({
	dates: {
		fontSize: "11px",
		color: "gray",
	},
});

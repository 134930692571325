import { RemoteResourceStateData } from "@hlcr/core/api/RemoteResource";
import { HttpMethod } from "@hlcr/core/enum/HttpMethod";
import { Dispatch } from "react";
import { Action } from "redux";

import { RemoteResource } from "models/RemoteResource";

export enum RemoteResourceActionType {
	REMOTE_RESOURCE_REQUEST = "REMOTE_RESOURCE_REQUEST",
	REMOTE_RESOURCE_RESULT = "REMOTE_RESOURCE_RESULT",
	REMOTE_RESOURCE_REMOVE = "REMOTE_RESOURCE_REMOVE",
	REMOTE_RESOURCE_CLEAR = "REMOTE_RESOURCE_CLEAR",
}

interface RemoteResourceBaseAction extends Action<RemoteResourceActionType> {
	resource?: RemoteResource;
}

export interface RemoteResourceRequestAction<T extends RemoteResourceStateData = RemoteResourceStateData> extends RemoteResourceBaseAction {
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST;
	method: HttpMethod;
	url: string;
	payload?: T;
	onBefore?: (dispatch: Dispatch<any>) => void;
	onSuccess?: (dispatch: Dispatch<any>, result?: T) => void;
	onFailure?: (dispatch: Dispatch<any>) => void;
	suppressErrorNotification?: boolean;
	successNotification?: string | boolean;
}

export type DynamicRequestAction<T = RemoteResourceStateData> = (payload?: T, onBefore?: RemoteResourceRequestAction<T>["onBefore"], onSuccess?: RemoteResourceRequestAction<T>["onSuccess"], onFailure?: RemoteResourceRequestAction<T>["onFailure"]) => RemoteResourceRequestAction<T>;
export type DynamicRequestActionWithUrlParameter<T extends RemoteResourceStateData, K> = (urlParameter: K, payload?: T, onBefore?: RemoteResourceRequestAction<T>["onBefore"], onSuccess?: RemoteResourceRequestAction<T>["onSuccess"], onFailure?: RemoteResourceRequestAction<T>["onFailure"]) => RemoteResourceRequestAction<T>;

interface RemoteResourceDeleteAction extends RemoteResourceBaseAction {
	type: RemoteResourceActionType.REMOTE_RESOURCE_REMOVE;
	payload?: RemoteResourceStateData;
}

interface RemoteResourceClearAction extends RemoteResourceBaseAction {
	type: RemoteResourceActionType.REMOTE_RESOURCE_CLEAR;
	payload?: RemoteResourceStateData;
}

export interface RemoteResourceResultAction extends RemoteResourceBaseAction {
	type: RemoteResourceActionType.REMOTE_RESOURCE_RESULT;
	payload?: RemoteResourceStateData;
}

export type RemoteResourceAction = RemoteResourceRequestAction | RemoteResourceDeleteAction | RemoteResourceResultAction | RemoteResourceClearAction;


export const saveRemoteResource = (
	resource: RemoteResource,
	data?: RemoteResourceStateData,
): RemoteResourceResultAction => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_RESULT,
	resource,
	payload: data,
});

export const removeRemoteResource = (
	resource: RemoteResource,
	data: RemoteResourceStateData,
): RemoteResourceDeleteAction => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REMOVE,
	resource,
	payload: data,
});

export const clearRemoteResource = (
	resource: RemoteResource,
): RemoteResourceClearAction => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_CLEAR,
	resource,
});

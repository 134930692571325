import { StyledMarkdown } from "@hlcr/ui";
import { createStyles, makeStyles } from "@material-ui/core";
import { ChangeEvent } from "react";
import { useSelector } from "react-redux";

import CustomRadio from "components/CustomSwitch/CustomRadio";
import { AnswerBaseProps } from "components/Quiz/AnswerBaseProps";
import { isOptionRight } from "helper/quiz";
import { QuestionAnswer, QuestionType } from "models/Quizzes";
import { QUESTION_ANSWER_STATES } from "models/SolutionState";
import { RootState } from "reducers";

interface SingleChoiceQuestionProps extends AnswerBaseProps {
}

export const SingleChoiceQuestion = ({ question, disabled, gradingInstruction, isPreview, onChange, answer }: SingleChoiceQuestionProps) => {
	const classes = useStyles();
	const darkMode = useSelector((state: RootState) => state.ui.darkMode);

	const handleOnRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		const updatedAnswer: QuestionAnswer = {
			...answer,
			type: QuestionType.SINGLE_CHOICE,
			questionUuid: question.id,
			multipleChoiceOptionAnswers: [ { selectedOptionUuid: value } ],
		};

		onChange(updatedAnswer);
	};

	let selectedOptionUuid = answer?.multipleChoiceOptionAnswers?.[0]?.selectedOptionUuid;
	let options = [ ...(question.multipleChoiceOptions || []) ];

	if (options.length === 0 || !gradingInstruction) {
		options = options.map(option => ({ ...option, labelStyle: classes.label }));
	} else {
		if (isPreview) {
			const correctOption = gradingInstruction.multipleChoiceOptions?.find(option => option.correct === true);
			selectedOptionUuid = correctOption?.id;
		}

		options = options.map(option => {
			const isOptionCorrect = isOptionRight(option, gradingInstruction);
			return {
				...option,
				...(
					isOptionCorrect
						? { customRadioClasses: { root: classes.rightRoot, disabled: classes.rightDisabled } }
						: { customRadioClasses: { root: classes.wrongRoot, disabled: classes.wrongDisabled } }
				),
				labelStyle: classes.label,
				elementSuffix: isOptionCorrect ? "✓" : "✗",
			};
		});
	}

	return (
		<div>
			{question.multipleChoiceOptions && (
				<CustomRadio
					disabled={disabled}
					value={selectedOptionUuid}
					onChange={handleOnRadioChange}
					options={options}
					valueGetter={o => o.id}
					labelGetter={o => <StyledMarkdown source={o.content} darkMode={darkMode} />}
				/>
			)}
		</div>
	);
};

const useStyles = makeStyles(
	() => createStyles(
		({
			wrongRoot: { color: QUESTION_ANSWER_STATES.WRONG.color + " !important" },
			wrongDisabled: { color: QUESTION_ANSWER_STATES.WRONG.color + " !important", opacity: 0.7 },
			rightRoot: { color: QUESTION_ANSWER_STATES.RIGHT.color + " !important" },
			rightDisabled: { color: QUESTION_ANSWER_STATES.RIGHT.color + " !important", opacity: 0.7 },
			label: { "& > div > p": { margin: 0 } },
		}),
	),
);

import { RemoteResourceState, RemoteResourceStatus } from "@hlcr/core/api/RemoteResource";
import { HttpStatusCode } from "@hlcr/core/enum/HttpStatusCode";
import { Button } from "@hlcr/mui/Button";
import { useIntl } from "@hlcr/ui/Intl";
import { TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalWindow from "components/ModalWindow/ModalWindow";
import { fetchPreJoinInfo } from "eventAccessToken/eventAccessTokenModal.action";
import styles from "eventAccessToken/eventAccessTokenModal.module.scss";
import { EventJoin } from "eventAccessToken/models/EventJoin";
import { EventPreJoin } from "eventAccessToken/models/EventPreJoin";
import { ApiResultFailure } from "models/ApiResultFailure";
import { RemoteResource } from "models/RemoteResource";
import { RootState } from "reducers";
import { createSuccessMessage } from "redux/remoteResourceMiddleware";

export interface EventAccessTokenModalProps {
	token: string;
	onClose: (eventTitle?: string, filterDirectlyOnClose?: boolean) => void;
	redeemToken: (token: string, callback: any, callbackFailure: any) => void;
}

export const EventAccessTokenModal = (props: EventAccessTokenModalProps) => {

	const intl = useIntl();
	const dispatch = useDispatch();

	const [ submitting, setSubmitting ] = useState(false);
	const [ tokenInput, setTokenInput ] = useState<string>("");
	const [ tokenInputDirty, setTokenInputDirty ] = useState<boolean>(false);
	const eventPreJoinState = useSelector<RootState, RemoteResourceState<EventPreJoin[]>>((state: RootState) => state.remoteResourceReducer.remoteResources[RemoteResource.EVENT_PRE_JOIN_INFO]);
	const foundEventInState = eventPreJoinState.data?.find((x) => x.accessToken === props.token);

	const isTokenInUrl = !!props.token; // if we have no token in the url (/events/redeem), we show an input field to enter a token
	const isCodeInvalid = !foundEventInState && eventPreJoinState.status === RemoteResourceStatus.LOADED;
	const hasUserAlreadyJoined = eventPreJoinState.status === RemoteResourceStatus.LOADED && foundEventInState && foundEventInState?.hasUserAlreadyJoined;

	// if the user has already joined, we show the event
	useEffect(() => {
		if (hasUserAlreadyJoined) {
			createSuccessMessage(dispatch, undefined, intl.fm("events.redeem.modal.alreadyJoined"));
			props.onClose(foundEventInState?.name, true);
		}
	}, [ hasUserAlreadyJoined ]);


	useEffect(() => {
		// we only need to fetch the event info from the API if there is a token, and we do not have the info in the state yet
		if (isTokenInUrl && (eventPreJoinState.status === RemoteResourceStatus.INITIALIZED || !foundEventInState)) {
			dispatch(fetchPreJoinInfo(props.token));
		}
	}, [ props.token ]);

	const submitModal = () => {
		setSubmitting(true);
		props.redeemToken(isTokenInUrl ? props.token : tokenInput, (eventJoinResult: EventJoin[]) => {
			setSubmitting(false);
			props.onClose(eventJoinResult[0]?.eventName);
		}, (errorResult: ApiResultFailure) => {
			setSubmitting(false);
			// we handle the case where the user is already registered similar to the case where the token is valid
			if (errorResult?.status === HttpStatusCode.UNPROCESSABLE_ENTITY && errorResult?.message.startsWith("Already registered for event")) {
				props.onClose(errorResult?.message.split("\"")[1]); // The failure message is: [Already registered for event "Event Name"], so we extract the event name from the message
			}
		});
	};
	return (
		<ModalWindow
			title={intl.fm("events.redeem.modal.title", undefined, { code: (props.token ? "\"" + props.token + "\"" : "") })}
			open={!isTokenInUrl || eventPreJoinState.status === RemoteResourceStatus.LOADED}
			onClose={() => props.onClose()}
			classes={{}}
			fullWidth
			maxWidth="md"
			actionSection={(
				<div>
					<Button
						onClick={() => ((isTokenInUrl && isCodeInvalid) ? props.onClose() : submitModal())}
						color="primary"
						disabled={(!isTokenInUrl && tokenInput?.length === 0)}
					>
						{submitting ? (
							<CircularProgress size={15} />
						) : (
							(isTokenInUrl && isCodeInvalid) ? intl.fm("common.labels.cancel") : intl.fm("common.labels.redeem")
						)}
					</Button>
				</div>
			)}
		>
			{
				isTokenInUrl &&
				<>
					{!!foundEventInState &&
						<div className={styles.content}>
							<div className={styles.imagePlaceholder}>
								<img alt={intl.fm("events.redeem.modal.eventImage.alt")} src={`/api/images/events/token/${props.token}`} className={styles.eventImage} />
							</div>
							<div className={styles.eventText}>
								<h1>{foundEventInState?.name}</h1>
								<p>{foundEventInState?.description}</p>
							</div>
						</div>
					}
					{isCodeInvalid &&
						<div>
							<h2>{intl.fm("events.redeem.modal.message.invalid")}</h2>
							<p>{intl.fm("events.redeem.modal.message.support")}<a href="mailto:support@hacking-lab.com">support@hacking-lab.com</a></p>
						</div>
					}
				</>
			}
			{
				!isTokenInUrl &&
				<div className={styles.tokenInput}>
					<p className={styles.label}>{intl.fm("events.redeem.modal.input.explanation")}</p>
					<TextField autoFocus
					           required
					           label={intl.fm("events.redeem.modal.input.label")}
					           variant="outlined"
					           onChange={event => {
						           setTokenInput(event.target.value);
						           setTokenInputDirty(true);
					           }}
					           error={tokenInputDirty && tokenInput?.length === 0} />
				</div>
			}
		</ModalWindow>
	);
};




import { withStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { tableSpecialHighligtingStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamOverviewStyle";
import IconCard from "components/Cards/IconCard";
import NoData from "components/NoData/NoData";
import EnhancedTable from "components/Table/EnhancedTable";

import managerStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/manager/managerStyle";
import { withIntl } from "@hlcr/ui/Intl";
import { fetchHelp } from "variables/helpPage";

let ManagerTableCard = props => {
	const { title, cardIcon, cardFaIcon, color, specialHighlightingStyles = false } = props;
	const { classes, ...propsWithoutClasses } = props;

	return (
		<IconCard
			title={<div>{title}</div>}
			icon={cardIcon}
			faIcon={cardFaIcon}
			iconColor={color}
			content={specialHighlightingStyles ? <SpecialHighlightedManagerTable {...propsWithoutClasses} /> : <ManagerTable {...propsWithoutClasses} />}
		/>
	);
};

const mapStateToProps = state => ({ helpUrl: state.branding.helpUrl });

ManagerTableCard.propTypes = {
	classes: PropTypes.object.isRequired,
	tableData: PropTypes.array,
	paginate: PropTypes.number,
	title: PropTypes.node.isRequired,
	tableRenderer: PropTypes.arrayOf(PropTypes.object).isRequired,
	cardIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	color: PropTypes.string.isRequired,
	intl: PropTypes.object.isRequired,
	specialHighlightingStyles: PropTypes.bool
};

ManagerTableCard = compose(
	connect(mapStateToProps),
	withStyles(managerStyle),
	withIntl
)(ManagerTableCard);

export default ManagerTableCard;

const ManagerTable = ({ classes, tableRenderer, tableData, paginate, intl, disabledRow }) => {
	const isDataAvailable = Array.isArray(tableData) && tableData.length;
	return (
		<EnhancedTable
			classes={classes}
			tableRenderer={isDataAvailable ? tableRenderer : []}
			tableData={tableData}
			hover
			emptyContent={<NoData text={intl.fm("manager.table.noData")} />}
			paginate={paginate}
			disabledRow={disabledRow}
		/>
	);
};
const enhanceTable = compose(withStyles(tableSpecialHighligtingStyles));
const SpecialHighlightedManagerTable = enhanceTable(ManagerTable);

export const ShowHelp = props => {
	const { userRole, action, className, helpUrl } = props;

	return (
		<a href={fetchHelp(helpUrl, userRole, action)} target="_blank" rel="noopener noreferrer">
			<HelpIcon className={className} />
		</a>
	);
};

import { Dto } from "@hlcr/core/api/RemoteResource";


export enum ProfileInformationRequirement {
	MANDATORY = "MANDATORY",
	OPTIONAL = "OPTIONAL",
	HIDDEN = "HIDDEN",
}

export enum UserProfileInformation {
	NATIONALITY = "NATIONALITY",
	GENDER = "GENDER",
	BIRTHDATE = "BIRTHDATE",
	AFFILIATION = "AFFILIATION",
	FIRSTNAME = "FIRSTNAME",
	LASTNAME = "LASTNAME",
	SKILLS = "SKILLS",
	BANK_ACCOUNT = "BANK_ACCOUNT",
	PAYPAL = "PAYPAL",
	MOBILE_PHONE_NUMBER = "MOBILE_PHONE_NUMBER",
}

// export type UserProfileInformation = keyof Pick<UserProfile, "organization" | "birthday" | "firstName" | "gender" | "lastName" | "nationality" | "bankAccount" | "paypal" | "mobilePhoneNumber" | "skills">;

export type UserProfileRequirements = { [key in UserProfileInformation]: ProfileInformationRequirement }


export interface TenantAccessPolicy extends Dto {
	userProfileRequirements: UserProfileRequirements;
}

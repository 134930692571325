import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import TeamIcon from "@material-ui/icons/Group";
import InfoIcon from "@material-ui/icons/Info";
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createSelector } from "reselect";

import managerApi from "actions/manager";
import managerStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/manager/managerStyle";
import { actionStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamDetailsStyle";
import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import ValidationInput from "components/CustomInput/ValidationInput";
import CustomSearchSelect from "components/CustomSelect/CustomSearchSelect";
import { Upload } from "@hlcr/mui/Upload";
import WarningDialog from "components/ModalWindow/WarningDialog";
import NoData from "components/NoData/NoData";
import EnhancedTable from "components/Table/EnhancedTable";
import { getImageData } from "helper/manager";
import { withIntl } from "@hlcr/ui/Intl";

const enhanceActions = compose(withStyles(actionStyles));

const Actions = enhanceActions(({ classes, team, teamMember, removeTeamMember }) => (
	<Button onClick={() => removeTeamMember(team.id, teamMember.user.userIdentifier)} color="danger" customClass={classes.button}>
		<DeleteIcon className={classes.icon} />
	</Button>
));

const createTableRenderer = (team, removeTeamMember, intl) => [
	{
		id: "username",
		title: intl.fm("user.label.username"),
		renderCell: teamMember => teamMember.user.username,
		sort: true
	},
	{
		id: "email",
		title: intl.fm("user.label.email"),
		renderCell: teamMember => teamMember.user.email,
		sort: true
	},
	{
		id: "leader",
		title: intl.fm("user.label.leader"),
		renderCell: teamMember => (teamMember.leader ? "\u2714" : ""),
		sort: true
	},
	{
		id: "actions",
		title: intl.fm("user.label.actions"),
		renderCell: teamMember => <Actions team={team} teamMember={teamMember} removeTeamMember={removeTeamMember} />
	}
];

const TeamMemberTable = ({ team, teamMembers, removeTeamMember, intl }) => {
	return (
		<EnhancedTable
			tableRenderer={createTableRenderer(team, removeTeamMember, intl)}
			tableData={teamMembers}
			hover
			emptyContent={<NoData text={intl.fm("team.members.noResources")} />}
		/>
	);
};

TeamMemberTable.defaultProps = { teamMembers: [] };

class ManagerTeamDetails extends React.Component {
	state = {
		form: {},
		inProgress: false,
		deleteOpen: false,
		archiveOpen: false,
		validation: { name: true }
	};

	static getDerivedStateFromProps(props, state) {
		const { form } = state;
		const { team, managerClasses } = props;
		if (!Object.keys(form).length && team) {
			return {
				form: {
					...team,
					teamClass: managerClasses.find(cl => cl.id === team.classId)
				},
				image: { name: "image" }
			};
		}
		return null;
	}

	resetForm = () => {
		this.setState((state, props) => ({
			form: {
				...props.team,
				teamClass: props.managerClasses && props.managerClasses.find(cl => cl.id === props.team.classId)
			},
			image: { name: "image" },
			validation: { name: true }
		}));
	};

	cancelDelete = () => {
		this.setState({ deleteOpen: false });
	};

	confirmDelete = () => {
		this.setState({ deleteOpen: true });
	};

	cancelArchive = () => {
		this.setState({ archiveOpen: false });
	};

	confirmArchive = () => {
		this.setState({ archiveOpen: true });
	};

	componentDidMount() {
		const { teamId, fetchTeam, fetchUsers, fetchManagerClasses } = this.props;
		fetchTeam(teamId);
		fetchUsers();
		fetchManagerClasses();
	}

	handleImageChange = file => {
		this.setState({ image: file });
	};

	handleRemoveTeam = () => {
		const { team, removeTeam, history } = this.props;
		removeTeam(team, () => history.push("/manager/teams"));
	};

	handleArchiveTeam = () => {
		const { team, archiveTeam, history } = this.props;
		this.setState({ archiveOpen: false });
		if (team) {
			archiveTeam(team, true, () => history.push("/manager/teams"));
		}
	};

	updateFieldWithValidation = fieldName => (value, valid) =>
		this.setState(state => ({
			form: { ...state.form, [fieldName]: value },
			validation: { ...state.validation, [fieldName]: valid }
		}));

	updateField = fieldName => value => this.setState(state => ({ form: { ...state.form, [fieldName]: value } }));

	handleUpdateTeam = () => {
		const { form: { teamClass, ...rest }, image } = this.state;
		const { team, updateTeam } = this.props;
		const imageData = getImageData(image);
		updateTeam(
			{
				id: team.id,
				...rest,
				classId: teamClass ? Number(teamClass.id) : null
			},
			imageData
		);
	};

	handleLeaderSelect = selectedLeader => {
		const { leader, team, updateTeamMember } = this.props;
		if (leader) {
			if (selectedLeader && selectedLeader.value === leader.value) return;

			if (selectedLeader == null) {
				updateTeamMember(leader.value, team.id, false);
			} else {
				updateTeamMember(leader.value, team.id, false, () => updateTeamMember(selectedLeader.value, team.id, true));
			}
		} else {
			updateTeamMember(selectedLeader.value, team.id, true);
		}
	};

	handleAddTeamMember = teamMember => {
		const { addTeamMember, team } = this.props;
		addTeamMember(teamMember.value, team.id);
	};

	render() {
		const { team, teamMembers, managerClasses, memberOptions, leader, leaderOptions, removeTeamMember, classes, intl } = this.props;
		const { form, image, validation } = this.state;

		if (!team) {
			return null;
		}

		const isFullValid = Object.values(validation).reduce((res, value) => res && value, true);

		const disabledDelete = teamMembers.length !== 0;
		const colorDelete = disabledDelete ? "defaultNoBackground" : "dangerNoBackground";

		const filteredClasses = team.archived ? managerClasses : managerClasses.filter(clazz => !clazz.archived).sort((a, b) => a.name.localeCompare(b.name));

		return (
			<div>
				<IconCard
					icon={InfoIcon}
					iconColor="purple"
					title={<div>{intl.fm("team.titles.general")}</div>}
					content={
						<div>
							<form className={classes.detailsForm}>
								<div>
									<ValidationInput
										disabled={team.archived}
										label={intl.fm("team.field.name")}
										value={form.name}
										onChange={this.updateFieldWithValidation("name")}
										inputClasses={classes.inputOverflow}
										validations={{
											required: true,
											minLength: 1,
											maxLength: 100
										}}
										fullWidth
									/>
									<ValidationInput
										type="text"
										value={form.description}
										disabled={team.archived}
										onChange={this.updateField("description")}
										label={intl.fm("team.field.description")}
										validations={{ maxLength: 500 }}
										inputClasses={classes.inputOverflow}
										inputProps={{
											multiline: true,
											rows: 4
										}}
										fullWidth
									/>
									<CustomSearchSelect
										displayField="name"
										placeholder={intl.fm("team.placeholder.selectClass")}
										value={form.teamClass}
										list={filteredClasses}
										onSelect={this.updateField("teamClass")}
										isDisabled={team.archived}
									/>
								</div>
								<Upload
									type="image"
									imageUrl={`/api/images/manager/teams/${team.id}`}
									handleProcessedFile={this.handleImageChange}
									file={image}
									rootClassName={classes.imageUploadForm}
								/>
							</form>
						</div>
					}
					footer={
						!team.archived ? (
							<div className={classes.actions}>
								<Button onClick={this.resetForm} color="defaultNoBackground">
									{intl.fm("common.labels.resetForm")}
								</Button>
								<Button onClick={this.handleUpdateTeam} disabled={!isFullValid} color="infoNoBackground">
									{intl.fm("common.labels.save")}
								</Button>
								<Button disabled={disabledDelete} onClick={this.confirmDelete} color={colorDelete}>
									{intl.fm("common.labels.delete")}
								</Button>
								<Button onClick={this.confirmArchive} color="warningNoBackground">
									{intl.fm("manager.teams.archive")}
								</Button>
								<WarningDialog onConfirm={this.handleRemoveTeam} onClose={this.cancelDelete} open={this.state.deleteOpen} entityName={intl.fm("team.entityName")} />
								<WarningDialog
									onConfirm={this.handleArchiveTeam}
									onClose={this.cancelArchive}
									open={this.state.archiveOpen}
									title={intl.fm("common.archiveConfirm.title")}
									message={intl.fm("manager.teams.archive.msg")} />
							</div>
						) : null
					}
				/>
				<IconCard
					icon={TeamIcon}
					iconColor="purple"
					title={
						<div className={classes.titleBox}>
							<div>{intl.fm("team.titles.users")}</div>
							<div className={classes.titleBox}>
								<CustomSearchSelect
									displayField="label"
									placeholder={intl.fm("team.placeholder.selectLeader")}
									value={leader}
									list={leaderOptions}
									additionalClasses={classes.selectBoxDistance}
									onSelect={this.handleLeaderSelect}
								/>
								<CustomSearchSelect
									displayField="label"
									placeholder={intl.fm("team.placeholder.addUser")}
									list={memberOptions}
									onSelect={this.handleAddTeamMember}
								/>
							</div>
						</div>
					}
					content={<TeamMemberTable team={team} teamMembers={teamMembers} removeTeamMember={removeTeamMember} intl={intl} />}
				/>
			</div>
		);
	}
}

const teamSelector = createSelector(
	state => state.api.resources.managerTeams.data,
	(_, id) => id,
	(teams, id) => teams.find(team => team.id === id)
);

const memberSelector = createSelector(
	state => state.api.resources.managerTeamMembers.data,
	(_, id) => id,
	(members, id) => members.filter(member => member.teamId === id).sort((mA, mB) => mA.user.username > mB.user.username)
);

const leaderSelector = createSelector(
	memberSelector,
	members => {
		const leader = members.find(member => member.leader);
		if (leader) {
			return {
				value: leader.user.userIdentifier,
				label: leader.user.username
			};
		}
		return undefined;
	}
);

const leaderOptionsSelector = createSelector(
	memberSelector,
	members =>
		members.map(member => ({
			value: member.user.userIdentifier,
			label: member.user.username
		}))
);

const memberOptionsSelector = createSelector(
	memberSelector,
	state => state.api.resources.managerUsers.data,
	(members, users) => users
		.filter(user => members.find(member => member.user.userIdentifier === user.userIdentifier) == null)
		.map(user => ({ value: user.userIdentifier, label: user.username }))
);

const mapStateToProps = (state, { match }) => {
	const teamId = Number(match.params.teamId);
	return {
		teamId,
		team: teamSelector(state, teamId),
		teamMembers: memberSelector(state, teamId),
		leader: leaderSelector(state, teamId),
		leaderOptions: leaderOptionsSelector(state, teamId),
		memberOptions: memberOptionsSelector(state, teamId),
		managerClasses: state.api.resources.managerClasses.data
	};
};

const mapDispatchToProps = {
	fetchUsers: managerApi.fetchUsers,
	fetchTeam: managerApi.fetchTeam,
	fetchManagerClasses: managerApi.fetchManagerClasses,
	updateTeam: managerApi.updateTeam,
	removeTeam: managerApi.removeTeam,
	archiveTeam: managerApi.archiveTeam,
	removeTeamMember: managerApi.removeTeamMember,
	addTeamMember: managerApi.addTeamMember,
	updateTeamMember: managerApi.updateTeamMember
};

export default compose(
	withIntl,
	withStyles(managerStyle),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(ManagerTeamDetails);

let ManagerTeamDetailsBreadCrumb = ({ team }) => <span>{team ? team.name : "Team Details"}</span>;

const mapStateToPropsBreadCrumbs = (state, ownProps) => {
	const teamId = Number(ownProps.match.params.teamId);
	return { team: state.api.resources.managerTeams.data.find(team => team && team.id === teamId) };
};

ManagerTeamDetailsBreadCrumb = connect(mapStateToPropsBreadCrumbs)(
	ManagerTeamDetailsBreadCrumb
);
export { ManagerTeamDetailsBreadCrumb };

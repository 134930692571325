import { fetchChallengeReportBase, fetchQuizReportBase, fetchTheoriesReportBase } from "./report";
import teamApi from "./teams";
import { ActionType } from "actions/ActionType";

const BASE_URL = "/api/teamleader";

const addTeam = (team, onSuccess, onFailure) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/`,
	method: "post",
	body: team,
	resource: "teamleaderTeams",
	onSuccess: (dispatch, teamDto) => {
		dispatch(teamApi.fetchTeams());
		if (onSuccess) {
			onSuccess(teamDto.id);
		}
	},
	onFailure,
});

const updateTeam = (team, onSuccess, onFailure) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${team.id}`,
	method: "put",
	body: team,
	resource: "teamleaderTeams",
	onSuccess: (dispatch, teamDto) => {
		dispatch(teamApi.fetchTeams());
		if (onSuccess) {
			onSuccess(teamDto.id);
		}
	},
	onFailure,
});

const removeTeam = (teamId, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}`,
	method: "delete",
	resource: "teamleaderTeams",
	onSuccess: dispatch => {
		dispatch(teamApi.fetchTeams());
		if (onSuccess) {
			onSuccess();
		}
	},
	successNotification: true,
});

const promoteToLeader = (teamId, userIdentifier, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}/leader/${userIdentifier}`,
	method: "put",
	resource: "teamleaderTeams",
	onSuccess: dispatch => {
		dispatch(teamApi.fetchTeams());
		if (onSuccess) {
			onSuccess();
		}
	},
	successNotification: true,
});

const removeTeamMember = (teamId, userIdentifier, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}/members/${userIdentifier}`,
	method: "delete",
	resource: "teamleaderTeams",
	onSuccess: dispatch => {
		dispatch(teamApi.fetchTeams());
		if (onSuccess) {
			onSuccess();
		}
	},
	successNotification: true,
});

const regenerateJoinToken = (teamId, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}/token`,
	method: "post",
	onSuccess: () => onSuccess && onSuccess(),
});

const fetchTeamMemberProfile = (teamId, userIdentifier) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}/members/${userIdentifier}/profile`,
	method: "get",
	resource: "teamleaderTeamMembers",
	setFieldOnObject: { key: "teamId", value: teamId },
	updatePropForObj: { idSelector: obj => obj.teamId || teamId },
});

const saveTeamMemberNotes = (teamId, userIdentifier, notes) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}/members/${userIdentifier}/notes`,
	body: notes,
	method: "put",
	resource: "teamleaderTeamMembers",
	successNotification: true,
	setFieldOnObject: { key: "teamId", value: teamId },
});

const fetchReportTeamUsers = teamId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}/report`,
	method: "get",
	resource: "reportUsers",
});

const fetchChallengeReportsByUser = (teamId, userId) => ({
	...fetchChallengeReportBase,
	url: `${BASE_URL}/teams/${teamId}/members/${userId}/reports/challenges/`,
	updatePropForObj: { idSelector: obj => `${obj.teamId}.${obj.userId}` },
});

const fetchQuizReportsByUser = (teamId, userId) => ({
	...fetchQuizReportBase,
	url: `${BASE_URL}/teams/${teamId}/members/${userId}/reports/quizzes/`,
	updatePropForObj: { idSelector: obj => `${obj.teamId}.${obj.userId}` },
});

const fetchTheoryReportsByUser = (teamId, userId) => ({
	...fetchTheoriesReportBase,
	url: `${BASE_URL}/teams/${teamId}/members/${userId}/reports/theories/`,
	updatePropForObj: { idSelector: obj => `${obj.teamId}.${obj.userId}` },
});

export default {
	addTeam,
	updateTeam,
	removeTeam,
	promoteToLeader,
	removeTeamMember,
	regenerateJoinToken,
	fetchTeamMemberProfile,
	saveTeamMemberNotes,
	fetchReportTeamUsers,
	fetchChallengeReportsByUser,
	fetchQuizReportsByUser,
	fetchTheoryReportsByUser,
};

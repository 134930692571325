export const teamInitState = {
	userTeams: {
		pending: false,
		data: []
	},
	joinTeam: {
		pending: false,
		data: []
	}
};

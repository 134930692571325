import { withIntl } from "@hlcr/ui/Intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

import challengeStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/eventUnitStyle";
import { ImageContentCard } from "components/Cards/ImageContentCard";
import ExamInfo from "components/Exam/ExamInfo";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import NoData from "components/NoData/NoData";
import QuizSolutionEditor from "components/Quiz/QuizSolutionEditor";
import SolutionComments from "components/Quiz/SolutionComments";
import { getShowInfoSetting, setShowInfoSetting } from "helper/eventUnit";
import PropTypes from "prop-types";
import React from "react";
import { withCookies } from "react-cookie";
import { compose } from "redux";
import { AssetType } from "models/Asset";

import { InfoSection } from "./InfoSection";

class Quiz extends React.Component {
	state = { displayInfo: getShowInfoSetting(this.props.cookies) };

	onSwitchDisplayInfo = () => {
		const { cookies } = this.props;
		const { displayInfo } = this.state;
		setShowInfoSetting(cookies, !displayInfo);
		this.setState({ displayInfo: !displayInfo });
	};

	componentDidMount() {
		const { fetchQuiz, fetchQuizAnswers, fetchQuizGradingInstructions, isPreview, showAllSolution, showPartialSolution, quizId } = this.props;

		fetchQuiz(quizId, true);
		if (!isPreview) fetchQuizAnswers(quizId);
		// if we come in as a participant we have no method to fetch grading instructions
		if ((isPreview || showAllSolution || showPartialSolution) && fetchQuizGradingInstructions) {
			fetchQuizGradingInstructions(quizId);
		}
	}

	render() {
		const { quiz, event, comments, isPreview, isLoading, quizClosed, gradingInstructions, quizAnswers, showAllSolution, showPartialSolution, intl, classes } = this.props;
		const { displayInfo } = this.state;

		if (isLoading && !quiz) return <LoadingSpinner />;

		return quiz ? (
			<div>
				<div className={classes.rigthField}>
					{event && <ExamInfo event={event} isPreview={isPreview} showButton />}
				</div>
				<div>
					<h3 className={classes.title}>
						{quiz.title}
						{isPreview && ` (${intl.fm("event.preview")})`}
						{isLoading && (
							<CircularProgress style={{ marginLeft: 10 }} size={24} />
						)}
					</h3>
				</div>
				<GridContainer>
					<ItemGrid xs={12} sm={12} md={6} lg={6}>
						<ImageContentCard
							entity={quiz}
							assetType={AssetType.QUIZ}
							onSwitchDisplayInfo={this.onSwitchDisplayInfo}
							displayInfo={displayInfo}
						/>
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={6} lg={6}>
						<InfoSection
							challenge={quiz}
							event={event}
							unitType={AssetType.QUIZ}
							onSwitchDisplayInfo={this.onSwitchDisplayInfo}
							displayInfo={displayInfo}
						/>
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						<QuizSolutionEditor
							quiz={quiz}
							answers={quizAnswers}
							isPreview={isPreview}
							isExamen={event?.exam}
							showAllSolution={showAllSolution}
							showPartialSolution={showPartialSolution}
							quizClosed={quizClosed}
							gradingInstructions={gradingInstructions}
						/>
					</ItemGrid>
					{comments && comments.length > 0 && (
						<ItemGrid xs={12} sm={12} md={12} lg={12}>
							<SolutionComments comments={comments} quiz={quiz} />
						</ItemGrid>
					)}
				</GridContainer>
			</div>
		) : (
			<NoData />
		);
	}
}

Quiz.propTypes = {
	classes: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	cookies: PropTypes.object.isRequired,
	quizId: PropTypes.number.isRequired,
	quiz: PropTypes.object,
	event: PropTypes.object,
	comments: PropTypes.arrayOf(PropTypes.object),
	isPreview: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	quizClosed: PropTypes.bool,
	gradingInstructions: PropTypes.arrayOf(PropTypes.object),
	quizAnswers: PropTypes.arrayOf(PropTypes.object),
	showAllSolution: PropTypes.bool,
	showPartialSolution: PropTypes.bool,
	fetchQuizGradingInstructions: PropTypes.func,
	fetchQuiz: PropTypes.func,
	fetchQuizAnswers: PropTypes.func,
};
export default compose(
	withStyles(challengeStyle),
	withIntl,
	withCookies,
)(Quiz);

export default (fileName, title, data, tableRenderer) => {
	const dataRenderer = tableRenderer.filter(renderer => renderer.renderCsv);

	const rows = [
		[ title ],
		dataRenderer.map(renderer => renderer.title),
		...data.map(value =>
			dataRenderer.map(renderer => renderer.renderCsv(value))
		)
	];
	let csvContent = "data:text/csv;charset=utf-8,";
	rows.forEach(function(rowArray) {
		const row = rowArray.join(";");
		csvContent += row + "\r\n";
	});

	let encodedUri = encodeURI(csvContent);
	encodedUri = encodedUri.replace(/#/g, "%23");
	const link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", fileName);
	link.style.display = "none";
	link.innerHTML = "Click Here to download";
	// Required for FF
	document.body && document.body.appendChild(link);
	// This will download the data file named after param
	link.click();
};

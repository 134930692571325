import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import { Link } from "react-router-dom";

const styles = {
	content: {
		color: "inherit",
		height: "100%",
		display: "flex",
		alignItems: "center"
	}
};

const CustomTableCell = ({ to, children, classes, ...props }) =>
	to ? (
		<TableCell {...props}>
			<Link to={to} className={classes.content}>
				{children}
			</Link>
		</TableCell>
	) : (
		<TableCell {...props}>{children}</TableCell>
	);

export default withStyles(styles)(CustomTableCell);

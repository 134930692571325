import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import { Button } from "@hlcr/mui/Button";
import { InputRequirementType } from "@hlcr/mui/Input/InputRequirementType";
import { MultiSelect } from "@hlcr/mui/Input/MultiSelect";
import { useInput } from "@hlcr/ui/hooks";
import { useIntl } from "@hlcr/ui/Intl";
import { createStyles, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import managerApi from "actions/manager";
import teacherApi from "actions/teacher";
import { createQueryFilter } from "components/EventFilterBar/EventFilterBar";
import ModalWindow from "components/ModalWindow/ModalWindow";
import WarningDialog from "components/ModalWindow/WarningDialog";
import inboxApi from "messaging/actions";
import { EventType } from "models/EventType";
import { RootState } from "reducers";
import { Event } from "shared/event/model/Event";
import { UserEventState } from "variables/constants";

import { MessageComposerProps } from "./MessageComposer";


interface ComposeModalProps extends MessageComposerProps {
	open: boolean,
	onClose: () => void,
}

export const ComposeModal = (props: ComposeModalProps) => {
	const intl = useIntl();
	const classes = useStyles();

	const dispatch = useDispatch();
	// const managerEventsLoading = useSelector((state: RootState) => state.api.resources.managerEvents.pending);
	const managerEvents: Event[] = useSelector((state: RootState) => state.api.resources.managerEvents.data);
	// const teacherEventsLoading = useSelector((state: RootState) => state.api.resources.teacherEvents.pending);
	const teacherEvents: Event[] = useSelector((state: RootState) => state.api.resources.teacherEvents.data);
	// const assetsLoading = useSelector((state: RootState) => state.api.resources.managerUnits.pending);
	// const assets: Asset[] = useSelector((state: RootState) => state.api.resources.managerUnits.data);
	// TODO: prepend an all option to explicitly select all events
	// const assets: Asset[] = useSelector((state: RootState) => [ { title: "All", uuid: "*", type: "CHALLENGE", categories: [] }, ...state.api.resources.managerUnits.data ]);

	const { inputProps: assetsInputProps } = useInput<string[]>([]);
	const { inputProps: eventsInputProps } = useInput<number[]>([]);
	const { inputProps: subjectInputProps } = useInput("");
	const { inputProps: messageBodyInputProps } = useInput("");
	const [ showConfirmation, setShowConfirmation ] = useState(false);

	useEffect(() => {
		if (HackingLabRole.COMPOSITE_MANAGER === props.asRole) {
			dispatch(managerApi.fetchManagerEvents());
			// dispatch(managerApi.fetchManagerUnits());
		}
		if (HackingLabRole.COMPOSITE_TEACHER === props.asRole) {
			dispatch(teacherApi.fetchTeacherEvents());
		}
	}, [ props.asRole ]);


	if (!props.open) {
		return null;
	}

	let relevantEvents: Event[] = [];

	if (HackingLabRole.COMPOSITE_MANAGER === props.asRole) {
		relevantEvents = managerEvents;
	} else if (HackingLabRole.COMPOSITE_TEACHER === props.asRole) {
		relevantEvents = teacherEvents;
	}

	relevantEvents = relevantEvents
		.filter(createQueryFilter("", [ UserEventState.RUNNING, UserEventState.UPCOMING ]))
		.filter(e => e.type !== EventType.CURRICULUM_EVENT);


	const handleSendClick = () => {
		if (eventsInputProps.value.length === 0) {
			setShowConfirmation(true);
		} else {
			handleSend();
		}
	};

	const handleSend = () => {
		dispatch(
			inboxApi.sendMessage(
				{
					eventIds: eventsInputProps.value,
					assetUuids: assetsInputProps.value,
					subject: subjectInputProps.value,
					messageBody: messageBodyInputProps.value,
				},
				props.asRole,
			),
		);
		setShowConfirmation(false);
		props.onClose();
	};

	const invalidFormState = subjectInputProps.value.length === 0 || messageBodyInputProps.value.length === 0;

	return (
		<>
			<ModalWindow
				maxWidth="md"
				open={props.open}
				onClose={props.onClose}
				title={intl.fm("messaging.compose.title")}
				actionSection={
					<div className={classes.buttons}>
						{/* Reverse order to focus on "Send" first */}
						<Button onClick={handleSendClick} color={"infoNoBackground"} disabled={invalidFormState}>
							{intl.fm("messaging.compose.send")}
						</Button>
						<Button onClick={props.onClose} color={"defaultNoBackground"}>
							{intl.fm("messaging.compose.cancel")}
						</Button>
					</div>
				}
				fullWidth={true}
			>
				<div className={classes.form}>
					<Grid container={true} spacing={4}>
						<Grid item={true} xs={12} sm={HackingLabRole.COMPOSITE_ADMIN === props.asRole ? 12 : 8} className={classes.column}>
							<TextField className={classes.subject} required={true} label={intl.fm("messaging.compose.label.subject")} {...subjectInputProps} />
							<TextField required={true} multiline={true} minRows={10} label={intl.fm("messaging.compose.label.messageBody")} {...messageBodyInputProps} />
						</Grid>
						{
							[ HackingLabRole.COMPOSITE_MANAGER, HackingLabRole.COMPOSITE_TEACHER ].includes(props.asRole) &&
							<Grid item={true} xs={12} sm={4} className={classes.column}>
								{/* {*/}
								{/*	HackingLabRole.COMPOSITE_MANAGER === props.asRole &&*/}
								{/*	<MultiSelect<String, Asset, "uuid", "title">*/}
								{/*		indexName={"uuid"}*/}
								{/*		displayName={"title"}*/}
								{/*		label={intl.fm("messaging.compose.label.assets")}*/}
								{/*		data={assets}*/}
								{/*		inputProps={assetsInputProps}*/}
								{/*		required={InputRequirementType.CONDITIONAL}*/}
								{/*	/>*/}
								{/* }*/}
								<MultiSelect
									label={intl.fm("messaging.compose.label.events")}
									data={relevantEvents}
									inputProps={eventsInputProps}
									required={InputRequirementType.OPTIONAL}
								/>
							</Grid>
						}
					</Grid>
				</div>
			</ModalWindow>
			<WarningDialog
				onConfirm={handleSend}
				onClose={() => setShowConfirmation(false)}
				open={showConfirmation}
				title={intl.fm("messaging.compose.confirmSendEveryoneTitle")}
				message={intl.fm(HackingLabRole.COMPOSITE_MANAGER === props.asRole ? "messaging.compose.confirmSendEveryoneMessageManager" : "messaging.compose.confirmSendEveryoneMessageTeacher")}
			/>
		</>

	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: { width: "100%" },
		column: { display: "flex", flexDirection: "column" },
		subject: { maxWidth: 350, marginBottom: theme.spacing(1) },
		buttons: { display: "flex", flexDirection: "row-reverse" },
	}),
);


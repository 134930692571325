import { withStyles } from "@material-ui/core/styles";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import managerApi from "actions/manager";
import managerStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/manager/managerStyle";
import { checkHasRole } from "auth/authUtils";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import StaticTokenList from "components/StaticTokenList/StaticTokenList";
import { createMemoize } from "helper/memoize";
import { withIntl } from "@hlcr/ui/Intl";
import { EventAccessibility } from "variables/constants";
import { EventType } from "models/EventType";
import ManagerAccessTokens from "views/Manager/ManagerAccessTokens";

import EventDetails from "./EventDetails";
import ManagerEventTeams from "./ManagerEventTeams";
import ManagerEventUnits from "./ManagerEventUnits";
import ManagerEventUsers from "./ManagerEventUsers";
import ManagerSubEvents from "./ManagerSubEvents";

class ManagerEventDetails extends React.Component {
	componentDidMount() {
		const {
			eventId,
			fetchManagerTeams,
			fetchManagerClasses,
			fetchManagerTeachers,
			fetchManagerStudents
		} = this.props;

		this.fetchEventData(eventId);
		fetchManagerTeams();
		fetchManagerClasses();
		fetchManagerTeachers();
		fetchManagerStudents();
	}

	componentDidUpdate(prevProps) {
		const { eventId } = this.props;
		if (prevProps.eventId !== eventId) this.fetchEventData(eventId);
	}

	render() {
		const { event, staticTokens, accessTokensLoading, updateStaticToken, archivedCurriculumEvents } = this.props;

		const showTeams = event && event.type !== EventType.CURRICULUM_EVENT;
		const showStaticTokens =
			staticTokens.length ||
			(event && event.accessibility === EventAccessibility.STATIC_TOKEN);

		return (
			<div>
				<GridContainer>
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						<EventDetails {...this.props} />
					</ItemGrid>
					{event && event.accessibility !== EventAccessibility.OPEN && (
						<>
							<ItemGrid xs={12} sm={12} md={12} lg={12}>
								<ManagerAccessTokens {...this.props} />
							</ItemGrid>
							{showStaticTokens && (
								<ItemGrid xs={12} sm={12} md={12} lg={12}>
									<StaticTokenList
										showEventName={false}
										accessTokens={staticTokens}
										updateToken={updateStaticToken}
										isLoading={accessTokensLoading}
									/>
								</ItemGrid>
							)}
						</>
					)}
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						{event && (event.type === EventType.CURRICULUM ? (
							archivedCurriculumEvents.length > 0 && checkHasRole(HackingLabRole.EVENT_ADMIN) ? (
								<>
									<ManagerSubEvents {...this.props} />
									<ManagerSubEvents {...this.props} archived={true} />
								</>
							) : (
								<ManagerSubEvents {...this.props} />
							)
						) : (
							<ManagerEventUnits {...this.props} />
						))}
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={12} lg={showTeams ? 6 : 12}>
						<ManagerEventUsers
							{...this.props}
						/>
					</ItemGrid>
					{showTeams && (
						<ItemGrid xs={12} sm={12} md={12} lg={showTeams ? 6 : 12}>
							<ManagerEventTeams {...this.props} />
						</ItemGrid>
					)}
				</GridContainer>
			</div>
		);
	}

	fetchEventData = eventId => {
		const {
			parentId,
			fetchManagerEvent,
			fetchManagerCurriculumEvents,
			fetchManagerUnits,
			fetchManagerEventUnits,
			fetchManagerEventTeams,
			fetchManagerEventUsers
		} = this.props;

		if (parentId) {
			fetchManagerEvent(parentId);
		}

		fetchManagerEvent(eventId, event => {
			if (event.type === EventType.CURRICULUM) {
				fetchManagerCurriculumEvents(eventId);
			} else {
				fetchManagerUnits();
				fetchManagerEventUnits(eventId);
			}
			fetchManagerEventUsers(eventId);
			fetchManagerEventTeams(eventId);
		});
	};
}

const filterCurriculumEvents = createMemoize((events, eventId) => events
	.filter(event => !event.archived && event.parent === eventId)
	.sort((eventA, eventB) => eventA.sortOrder - eventB.sortOrder));

const filterArchivedCurriculumEvents = createMemoize((events, eventId) => events
	.filter(event => event.archived && event.parent === eventId)
	.sort((eventA, eventB) => eventA.sortOrder - eventB.sortOrder));

const filterEventUnits = createMemoize((events, eventId) => events.filter(eventUnit => eventUnit.eventId === eventId)
	.sort((eventUnitA, eventUnitB) => eventUnitA.title < eventUnitB.title ? -1 : 1)
	.sort((eventUnitA, eventUnitB) => eventUnitA.sortOrder - eventUnitB.sortOrder));

const filterEventTeams = createMemoize((events, eventId) => events.filter(eventTeam => eventTeam.eventId === eventId).sort((teamA, teamB) => (teamA.team.name < teamB.team.name ? -1 : 1)));

const filterEventUsers = createMemoize((events, eventId) => events.filter(eventUsers => eventUsers.eventId === eventId).sort((userA, userB) => userA.user.username < userB.user.username ? -1 : 1));

const filterAccessTokens = createMemoize((accessTokens, eventId) => accessTokens.filter(token => token.eventId === eventId).sort((tokenA, tokenB) => (tokenA.createdate < tokenB.createdate ? -1 : 1)));

const sortStudents = createMemoize(students => students.sort((studentA, studentB) => studentA.username < studentB.username ? -1 : 1));

const sortTeams = createMemoize(teams => teams.sort((teamA, teamB) => (teamA.name < teamB.name ? -1 : 1)));

const getEvent = createMemoize((events, eventId) => events.find(event => event && event.id === eventId));

const filterStaticTokens = createMemoize(tokens => tokens.filter(token => token.tokenType === EventAccessibility.STATIC_TOKEN));

const mapStateToProps = (state, ownProps) => {
	const eventId = Number(ownProps.match.params.eventId);
	const parentId = Number(ownProps.match.params.parentId) || 0;

	const event = getEvent(state.api.resources.managerEvents.data, eventId);
	const accessTokens = filterAccessTokens(
		state.api.resources.accessTokens.data,
		eventId
	);

	return {
		eventId,
		parentId,
		event: event,
		curriculumEvents: filterCurriculumEvents(state.api.resources.managerEvents.data, eventId),
		archivedCurriculumEvents: filterArchivedCurriculumEvents(state.api.resources.managerEvents.data, eventId),
		units: state.api.resources.managerUnits.data,
		eventUnitsPending: state.api.resources.managerEventUnits.pending,
		eventUnits: filterEventUnits(state.api.resources.managerEventUnits.data, eventId),
		teams: sortTeams(state.api.resources.managerTeams.data),
		eventTeams: filterEventTeams(state.api.resources.managerEventTeams.data, eventId),
		managerClasses: state.api.resources.managerClasses.data,
		teachers: state.api.resources.managerTeachers.data,
		students: sortStudents(state.api.resources.managerStudents.data),
		eventUsers: filterEventUsers(state.api.resources.managerEventUsers.data, eventId),
		accessTokens,
		staticTokens: filterStaticTokens(accessTokens),
		accessTokensLoading: state.api.resources.accessTokens.pending,
		match: undefined,
		location: undefined
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			queryManagerEvents: managerApi.queryManagerEvents,
			fetchManagerEvent: managerApi.fetchManagerEvent,
			fetchManagerCurriculumEvents: managerApi.fetchManagerCurriculumEvents,
			fetchManagerUnits: managerApi.fetchManagerUnits,
			fetchManagerEventUnits: managerApi.fetchManagerEventUnits,
			fetchManagerTeams: managerApi.fetchManagerTeams,
			fetchManagerEventTeams: managerApi.fetchManagerEventTeams,
			fetchManagerClasses: managerApi.fetchManagerClasses,
			fetchManagerEventUsers: managerApi.fetchManagerEventUsers,
			fetchManagerTeachers: managerApi.fetchManagerTeachers,
			fetchManagerStudents: managerApi.fetchManagerStudents,
			moveCurriculumEvent: managerApi.moveCurriculumEvent,
			updateManagerEventUnit: managerApi.updateManagerEventUnit,
			updateManagerEvent: managerApi.updateManagerEvent,
			forcePublishRanking: managerApi.forcePublishRanking,
			restoreManagerEvent: managerApi.restoreManagerEvent,
			updateManagerCurriculumEvent: managerApi.updateManagerCurriculumEvent,
			updateManagerEventTeam: managerApi.updateManagerEventTeam,
			updateManagerEventUser: managerApi.updateManagerEventUser,
			addManagerEventUnit: managerApi.addManagerEventUnit,
			addManagerEventUser: managerApi.addManagerEventUser,
			addManagerEventTeam: managerApi.addManagerEventTeam,
			removeManagerEventUnit: managerApi.removeManagerEventUnit,
			resetManagerEventUnit: managerApi.resetManagerEventUnit,
			removeManagerEventTeam: managerApi.removeManagerEventTeam,
			removeManagerEventUser: managerApi.removeManagerEventUser,
			deleteManagerEvent: managerApi.deleteManagerEvent,
			deleteManagerCurriculumEvent: managerApi.deleteManagerCurriculumEvent,
			fetchAccessTokens: managerApi.fetchAccessTokens,
			updateStaticToken: managerApi.updateStaticToken,
			generateAccessTokens: managerApi.generateAccessTokens,
			exportAccessTokens: managerApi.exportAccessTokens
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withIntl,
	withStyles(managerStyle)
)(ManagerEventDetails);

const getEventForBreadcrumb = createMemoize((events, eventId) =>
	events.find(event => event && event.id === eventId)
);

const mapStateToPropsBreadCrumbs = (state, ownProps) => {
	const eventId = Number(ownProps.match.params.eventId);
	return {
		event: getEventForBreadcrumb(
			state.api.resources.managerEvents.data,
			eventId
		)
	};
};

let ManagerEventDetailsBreadCrumb = ({ event, intl }) => (
	<span>{event ? event.name : intl.fm("manager.eventDetails.breadCrumb")}</span>
);

ManagerEventDetailsBreadCrumb = connect(mapStateToPropsBreadCrumbs)(
	withIntl(ManagerEventDetailsBreadCrumb)
);

export { ManagerEventDetailsBreadCrumb };

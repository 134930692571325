import { dangerColor, defaultFont, successColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";

const customDateTimeInputStyle = {
	labelRoot: {
		...defaultFont,
		paddingLeft: "0",
		color: "#AAAAAA",
		fontSize: "10.5px",
		lineHeight: "1.428571429",
		fontWeight: "400",
		display: "inline-flex"
	},
	input: {
		...defaultFont,
		width: "100%",
		padding: "2px 0 0 !important",
		fontWeight: "400 !important",
		height: "36px !important",
		fontSize: "14px !important",
		lineHeight: "1.428571429 !important",
		"&[rows]": { height: "auto" },
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 }
	},
	labelRootError: { color: dangerColor },
	labelRootSuccess: { color: successColor },
	labelError: { color: dangerColor },
	feedback: {
		// position: 'absolute',
		top: "23px",
		right: "0",
		zIndex: "2",
		display: "block",
		width: "24px",
		height: "24px",
		marginLeft: -24,
		textAlign: "center",
		pointerEvents: "none"
	},
	feedbackNoLabel: {
		// top: "8px"
	},
	formControl: {
		width: "100%",
		paddingTop: 9,
		marginBottom: 17,
		position: "inherit"
	},
	adormentContainer: {
		position: "relative",
		verticalAlign: "unset",
		display: "inline-flex",
		flexDirection: "column",
		minWidth: 0
	}
};

export default customDateTimeInputStyle;

import { infoColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";
import { ASSET_DIFFICULTY_LEVELS } from "models/Asset";

const createStripes = color =>
	`linear-gradient(-45deg, ${color} 25%, transparent 25%, transparent 50%, ${color} 50%, ${color} 75%, transparent 75%, transparent 100%)`;

const rankingStyle = {
	tableSelectFormControl: { margin: "-6px 0 0 12px" },
	...Object.entries(ASSET_DIFFICULTY_LEVELS).reduce((previous, [key, value]) => ({
		...previous,
		["challengeContainer_" + key.toLowerCase()]: {
				height: "15px",
				width: "15px",
				marginRight: "3px",
				overflow: "hidden",
				border: "1px solid " + value.color,
				backgroundColor: value.color,
				borderRadius: 2}
		}),
        {}
	),
	emptyChallengeContainer: { backgroundColor: "transparent" },
	partialSolveContainer: {
		height: "100%",
		width: "100%",
		opacity: 0.8,
		background: createStripes("#fff"),
		backgroundSize: "6px 6px",
		[DARK_MODE_SELECTOR]: {
			opacity: 1,
			background: createStripes(COLOR.DARK_2),
			backgroundSize: "6px 6px"
		}
	},
	challengesBox: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "flex-end"
	},
	floatField: {
		float: "right",
		marginTop: "-10px"
	},
	pagingBar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: "1.2em",
		marginTop: 20,
		color: "#aaa"
	},
	pageLink: {
		minWidth: "1.5em",
		height: "1.5em",
		lineHeight: "1.5em",
		padding: "0 2px",
		textAlign: "center",
		margin: "0 5px",
		cursor: "pointer",
		color: infoColor,
		background: "#eee",
		borderRadius: "3px",
		fontWeight: 400,
		transition: "border .1s",
		"&:hover": {
			background: infoColor,
			color: "#fff"
		}
	},
	activePageLink: {
		background: infoColor,
		color: "#fff"
	},
	rankingTableContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch"
	}
};

export default rankingStyle;

import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import eventsApi from "actions/events";
import rankingApi from "actions/ranking";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import { createMemoize } from "helper/memoize";
import { withIntl } from "@hlcr/ui/Intl";
import { EventType } from "models/EventType";

import EventRankingCard from "../Ranking/EventRankingCard";

class HackingLabDashboard extends React.Component {
	state = {
		selectedTeamEventId: undefined,
		selectedUserEventId: undefined
	};

	componentDidMount() {
		this.props.fetchEvents([
			EventType.CURRICULUM_EVENT,
			EventType.STANDALONE_EVENT
		]);
	}

	componentDidUpdate(prevProps) {
		const { events } = this.props;
		if (events !== prevProps.events && events.length > 0) {
			this.handleTeamEventChange(events[0].id);
			this.handleUserEventChange(events[0].id);
		}
	}

	render() {
		const { teamRankingData, userRankingData, events, eventUnits } = this.props;
		const { selectedTeamEventId, selectedUserEventId } = this.state;
		const filteredTeamRankingData = teamRankingData.filter(
			rank => rank.eventId === selectedTeamEventId,
		);
		const filteredUserRankingData = userRankingData.filter(
			rank => rank.eventId === selectedUserEventId,
		);

		// we need to map the events to the same format as in the classes report
		const mappedEvents = events.map(event => ({
			eventId: event.id,
			eventName: event.name,
			mandatory: event.mandatory,
		}));

		return (
			<div>
				<GridContainer>
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						<EventRankingCard
							events={mappedEvents}
							selectedEvent={mappedEvents.find(e => e.eventId === selectedTeamEventId)}
							rankingData={filteredTeamRankingData}
							eventUnits={eventUnits.filter(e => e.eventId === selectedTeamEventId)}
							handler={this.handleTeamEventChange}
							isTeam
							icon={GroupIcon}
							color="purple"
						/>
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						<EventRankingCard
							events={mappedEvents}
							selectedEvent={mappedEvents.find(e => e.eventId === selectedUserEventId)}
							rankingData={filteredUserRankingData}
							eventUnits={eventUnits.filter(e => e.eventId === selectedUserEventId)}
							handler={this.handleUserEventChange}
							icon={PersonIcon}
							color="purple"
						/>
					</ItemGrid>
				</GridContainer>
			</div>
		);
	}

	handleTeamEventChange = eventId => {
		this.setState({ selectedTeamEventId: eventId });
		this.fetchTeamRanking(eventId);
	};

	handleUserEventChange = eventId => {
		this.setState({ selectedUserEventId: eventId });
		this.fetchUserRanking(eventId);
	};

	fetchTeamRanking(eventId) {
		const { fetchEventTeamsRanking, fetchEventUnits } = this.props;
		if (eventId) {
			fetchEventTeamsRanking(eventId);
			fetchEventUnits(eventId);
		}
	}

	fetchUserRanking(eventId) {
		const { fetchEventUsersRanking, fetchEventUnits } = this.props;
		if (eventId) {
			fetchEventUsersRanking(eventId);
			fetchEventUnits(eventId);
		}
	}
}

const filterEvents = createMemoize(events =>
	events
		.filter(
			event =>
				[
					EventType.CURRICULUM_EVENT,
					EventType.STANDALONE_EVENT,
				].includes(
					event.type
				) &&
				event.ranked &&
				event.registered &&
				!event.exam
		)
		.sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		})
		.sort((a, b) => {
			if (a.parent < b.parent) return -1;
			if (a.parent > b.parent) return 1;
			return 0;
		})
);

const mapStateToProps = (state, ownProps) => {
	return {
		teamRankingData: state.api.resources.rankingTeams.data,
		userRankingData: state.api.resources.rankingUsers.data,
		events: !ownProps.useEvent
			? filterEvents(state.api.resources.events.data)
			: [],
		eventUnits: state.api.resources.eventUnits.data,
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchEvents: eventsApi.fetchEvents,
			fetchEventUnits: eventsApi.fetchEventUnits,
			fetchEventTeamsRanking: rankingApi.fetchEventTeamsRanking,
			fetchEventUsersRanking: rankingApi.fetchEventUsersRanking
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withIntl
)(HackingLabDashboard);

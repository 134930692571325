import { DARK_MODE_SELECTOR } from "@hlcr/mui/theme/hacking-lab.theme";
import { infoColor, primaryColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { WithIntl, withIntl } from "@hlcr/ui/Intl";
import { StepIcon, StepLabel, withStyles, WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose, Dispatch } from "redux";

import eventsApi from "actions/events";
import teacherApi from "actions/teacher";
import RegularCard from "components/Cards/RegularCard";
import { getQuestionState } from "helper/quiz";
import useSessionState from "helper/useSessionState";
import { Question, QuestionAnswer, Quiz, QuizQuestionGradingInstruction } from "models/Quizzes";
import { QUESTION_ANSWER_STATES, QuestionAnswerState } from "models/SolutionState";

import QuizAnswerEditor from "./QuizAnswerEditor";
import QuizSolutionSummary from "./QuizSolutionSummary";

const defaultStepIconStyles = {
	root: {
		color: "rgba(0, 0, 0, 0.38)",
		[DARK_MODE_SELECTOR]: { color: "rgba(255, 255, 255, 0.5)" },
	},
	completed: { color: primaryColor + "!important" },
	active: { color: infoColor + "!important" },
};

interface QuizStepIconProps extends WithStyles {
	active: boolean,
}

const DefaultStepIcon = withStyles(defaultStepIconStyles)(StepIcon);

const SummaryStepIcon = withStyles(defaultStepIconStyles)((props: QuizStepIconProps) => <AssignmentIcon className={props.active ? props.classes.active : props.classes.root} />);

const RightStepIcon = withStyles({
	root: { color: QUESTION_ANSWER_STATES.RIGHT.color + "!important", opacity: 0.6 },
	active: { color: QUESTION_ANSWER_STATES.RIGHT.color + "!important", opacity: 1 },
})((props: QuizStepIconProps) => <AssignmentTurnedInIcon className={props.active ? props.classes.active : props.classes.root} />);

const WrongStepIcon = withStyles({
	root: { color: QUESTION_ANSWER_STATES.WRONG.color + "!important", opacity: 0.6 },
	active: { color: QUESTION_ANSWER_STATES.WRONG.color + "!important", opacity: 1 },
})((props: QuizStepIconProps) => <AssignmentLateIcon className={props.active ? props.classes.active : props.classes.root} />);

const SolvedStepIcon = withStyles({
	root: { color: QUESTION_ANSWER_STATES.SOLVED.color + "!important", opacity: 0.6 },
	active: { color: QUESTION_ANSWER_STATES.SOLVED.color + "!important", opacity: 1 },
})((props: QuizStepIconProps) => <AssignmentTurnedInIcon className={props.active ? props.classes.active : props.classes.root} />);

const UnsolvedStepIcon = withStyles({
	root: { color: QUESTION_ANSWER_STATES.UNSOLVED.color + "!important", opacity: 0.6 },
	active: { color: QUESTION_ANSWER_STATES.UNSOLVED.color + "!important", opacity: 1 },
})((props: QuizStepIconProps) => <AssignmentLateIcon className={props.active ? props.classes.active : props.classes.root} />);

const UngradedStepIcon = withStyles({
	root: { color: QUESTION_ANSWER_STATES.UNGRADED.color + "!important", opacity: 0.6 },
	active: { color: QUESTION_ANSWER_STATES.UNGRADED.color + "!important", opacity: 1 },
})((props: QuizStepIconProps) => <AssignmentIndIcon className={props.active ? props.classes.active : props.classes.root} />);

interface QuizSolutionEditorProps extends WithStyles, WithIntl {
	answers: QuestionAnswer[];
	quiz: Quiz;
	postFinishQuiz: Function;
	updateQuestionAnswer: Function;
	updateQuestionAnswers: Function;
	isPreview: boolean;
	isExamen: boolean;
	showAllSolution: boolean;
	showPartialSolution: boolean;
	quizClosed: boolean;
	gradingInstructions: QuizQuestionGradingInstruction[];
}

function QuizSolutionEditor(props: QuizSolutionEditorProps) {
	const { quiz, updateQuestionAnswer, answers, classes, quizClosed, postFinishQuiz, updateQuestionAnswers, intl } = props;

	const [ isFinalizeLoading, setFinalizeLoading ] = React.useState<boolean>(false);
	const [ activeStep, setActiveStep ] = React.useState(0);
	const [ temporaryQuizAnswers, setTemporaryQuizAnswers ] = useSessionState<QuestionAnswer[]>(`Quiz::SessionAnswers::${quiz.id}`, []);

	const handleNext = () => {
		handleStepChange(activeStep + 1);
	};

	const handleBack = () => {
		handleStepChange(activeStep - 1);
	};

	const handleStep = (step: number) => () => {
		handleStepChange(step);
	};

	const handleStepChange = (step: number) => {
		if (!temporaryQuizAnswers[activeStep]) {
			setActiveStep(step);
			return;
		}

		updateQuestionAnswer(quiz.id, quiz.questions[activeStep].id, temporaryQuizAnswers[activeStep], handleQuestionPersisted(activeStep));
		setActiveStep(step);
	};

	const checkFromBeginning = () => {
		setActiveStep(0);
	};

	const handleQuestionPersisted = (step: number) => () => {
		const newTemporaryAnswers = [ ...temporaryQuizAnswers ];
		delete newTemporaryAnswers[step];
		setTemporaryQuizAnswers(newTemporaryAnswers);
	};

	const handleFinalize = () => {
		setFinalizeLoading(true);

		if (temporaryQuizAnswers?.length > 0) {
			const unpersistedAnswers = temporaryQuizAnswers.filter((answer: QuestionAnswer) => answer);
			updateQuestionAnswers(quiz.id, unpersistedAnswers, doFinishQuiz, () => setFinalizeLoading(false));
		} else {
			doFinishQuiz();
		}
	};

	const doFinishQuiz = () => {
		postFinishQuiz(quiz.id, () => {
			setTemporaryQuizAnswers([]);
			setActiveStep(0);
			setFinalizeLoading(false);
		}, () => setFinalizeLoading(false));
	};

	function updateAnswer(answer: QuestionAnswer) {
		if (!answer) {
			return;
		}

		const newTemporaryAnswers = [ ...temporaryQuizAnswers ];
		newTemporaryAnswers[activeStep] = answer;

		setTemporaryQuizAnswers(newTemporaryAnswers);
	}

	const question = props.quiz.questions[activeStep];
	const quizAnswerEditorProps = {
		readOnly: props.quizClosed,
		isPreview: props.isPreview,
		question: question,
		answer: temporaryQuizAnswers?.[activeStep] || props.answers?.find(value => value.questionUuid === question?.id),
		updateAnswer,
		gradingInstruction: props.gradingInstructions.find(instruction => instruction.id === question?.id),
	};

	return (
		<RegularCard
			content={
				<>
					<Stepper alternativeLabel={true} nonLinear={true} activeStep={activeStep} className={classes.stepper}>
						{getSteps(props.quiz?.questions || [], answers, handleStep, props.gradingInstructions)}
						{!quizClosed ? (
							<Step key={props.quiz.questions.length}>
								<StepButton onClick={handleStep(props.quiz.questions.length)} optional={<Typography variant="caption">Summary</Typography>}>
									<StepLabel StepIconComponent={SummaryStepIcon} />
								</StepButton>
							</Step>
						) : null}
					</Stepper>
					{activeStep === props.quiz?.questions.length ? <QuizSolutionSummary /> : <QuizAnswerEditor {...quizAnswerEditorProps} />}
				</>
			}
			footer={
				<div className={classes.footer}>
					{activeStep < props.quiz.questions.length
						? <>
								<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
									{intl.fm("common.labels.back")}
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={handleNext}
									className={classes.button}
									disabled={quizClosed && activeStep === props.quiz.questions.length - 1}
								>
									{intl.fm("common.labels.next")}
								</Button>
							</>
						: <>
								<Button onClick={checkFromBeginning} className={classes.button}>
									{intl.fm("common.labels.check")}
								</Button>
								<Button variant="contained" color="primary" onClick={handleFinalize} className={classes.button} disabled={isFinalizeLoading}>
									{intl.fm("common.labels.finalize")}
								</Button>
								{isFinalizeLoading && <CircularProgress size={24} />}
							</>
					}
				</div>
			}
		/>
	);
}

const isAnswered = (answer: QuestionAnswer | undefined) => {
	return (answer?.multipleChoiceOptionAnswers?.length || 0) > 0 || (answer?.content?.length || 0) > 0;
};

function getSteps(questions: Question[], answers: QuestionAnswer[], handleStep: (step: number) => any, gradingInstructions: QuizQuestionGradingInstruction[]) {
	return questions.map((question, index) => {
		const stepProps: { completed?: boolean, error?: boolean } = {};
		const buttonProps: { optional?: React.ReactNode } = {};
		let stepIcon = undefined;

		const answer = answers?.find(answer => answer.questionUuid === question.id);

		if (question.required) {
			buttonProps.optional = <Typography variant="caption">Required</Typography>;
		}

		if (isAnswered(answer)) {
			stepProps.completed = true;
			if (gradingInstructions) {
				const gradingInstruction = gradingInstructions.find(instruction => instruction.id === question.id);
				if (gradingInstruction) {
					switch (getQuestionState(answer, gradingInstruction)) {
						case QuestionAnswerState.RIGHT:
							stepIcon = RightStepIcon;
							break;
						case QuestionAnswerState.WRONG:
							stepIcon = WrongStepIcon;
							break;
						case QuestionAnswerState.SOLVED:
							stepIcon = SolvedStepIcon;
							break;
						case QuestionAnswerState.UNSOLVED:
							stepIcon = UnsolvedStepIcon;
							break;
						case QuestionAnswerState.UNGRADED:
							stepIcon = UngradedStepIcon;
							break;
					}
				}
			}
		} else {
			stepProps.completed = false;
		}

		return (
			<Step key={question.id} {...stepProps}>
				<StepButton onClick={handleStep(index)} completed={stepProps.completed} {...buttonProps}>
					<StepLabel StepIconComponent={stepIcon ? stepIcon : DefaultStepIcon} />
				</StepButton>
			</Step>
		);
	});
}

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			updateQuestionAnswer: eventsApi.updateQuestionAnswer,
			updateQuestionAnswers: eventsApi.updateQuestionAnswers,
			postFinishQuiz: eventsApi.postFinishQuiz,
			gradeQuizAnswer: teacherApi.gradeQuizAnswer,
		},
		dispatch,
	);

const quizSolutionEditorStyles = {
	active: { boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)" },
	footer: { margin: "0 12px 12px" },
	button: { marginRight: "8px" },
	stepper: { background: "none" },
};

export default compose(
	withIntl,
	withStyles(quizSolutionEditorStyles),
	connect(
		null,
		mapDispatchToProps,
	),
)(QuizSolutionEditor);

import { useIntl } from "@hlcr/ui/Intl";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { fetchEvent, fetchEventWithUnits } from "actions/events";
import EventUnitProgress from "components/EventProgress/EventUnitProgress";
import { createMemoize } from "helper/memoize";
import { EventType } from "models/EventType";
import { filterEventUnits } from "models/EventUnit";
import { RootState } from "reducers";
import { EventDetailsRouteParams } from "routes/eventRoutes";
import { Event } from "shared/event/model/Event";

import { CurriculumEvents } from "./CurriculumEvents";
import EventUnits from "./EventUnits";


export const EventDetailsBreadCrumb = ({ match: { params: { eventId: eventIdParam } } }: RouteComponentProps<EventDetailsRouteParams>) => {
	const eventId = parseInt(eventIdParam);

	const intl = useIntl();
	const event: Event | undefined = useSelector((state: RootState) => !!eventId && getEvent(state.api.resources.events.data, eventId));

	if (!event?.name?.trim().length) {
		return <span>{intl.fm("manager.eventDetails.breadCrumb")}</span>;
	}

	return <span>{event.name}</span>;
};


export const EventDetails = ({ match: { params: { eventId: eventIdParam, parentId: parentIdParam } } }: RouteComponentProps<EventDetailsRouteParams>) => {
	const eventId = parseInt(eventIdParam);
	const parentId = parentIdParam?.trim().length && parseInt(parentIdParam);

	const dispatch = useDispatch();

	const isLoading = useSelector((state: RootState) => state.api.resources.events.pending || state.api.resources.eventUnits.pending);

	const units = useSelector((state: RootState) => !!eventId && filterEventUnits(state.api.resources.eventUnits.data, eventId));
	const event: Event = useSelector((state: RootState) => !!eventId && getEvent(state.api.resources.events.data, eventId));

	useEffect(() => {
		dispatch(fetchEventWithUnits(eventId));
	}, [ eventId ]);

	useEffect(() => {
		if (parentId) {
			dispatch(fetchEvent(parentId));
		}
	}, [ parentId ]);

	if (event?.type === EventType.CURRICULUM) {
		return <CurriculumEvents eventId={eventId} />;
	}

	return (
		<>
			<EventUnitProgress units={units} parentId={parentId} />
			<EventUnits
				eventId={eventId}
				parentId={parentId}
				event={event}
				units={units}
				isLoading={isLoading}
			/>
		</>
	);
};

const getEvent = createMemoize((events: Event[], eventId: number) => {
	return events.find(event => event.id === eventId);
});

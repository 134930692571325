import { withIntl } from "@hlcr/ui/Intl";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teacherApi from "actions/teacher";
import teacherStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teacherStyle";
import StaticTokenList from "components/StaticTokenList/StaticTokenList";
import { createMemoize } from "helper/memoize";
import { eventDateSorter } from "helper/sorting";
import { EventAccessibility } from "variables/constants";
import TeacherEventUnitList from "views/Teacher/TeacherEventUnitList";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";

import { MessageComposer } from "messaging/components/MessageComposer";
import moment from "moment";

class Teacher extends React.Component {
	componentDidMount() {
		const { fetchTeacherEvents, fetchAccessTokens } = this.props;
		fetchTeacherEvents(events => {
			const fetchedIds = [];
			events.forEach(event => {
				if (event.id && !fetchedIds.includes(event.id)) {
					fetchAccessTokens(event.id);
					fetchedIds.push(event.id);
				}
				if (event.parentId && !fetchedIds.includes(event.parentId)) {
					fetchAccessTokens(event.parentId);
					fetchedIds.push(event.parentId);
				}
			});
		});
	}

	render() {
		const { isLoading, accessTokens, updateStaticToken } = this.props;
		return (
			<>
				<TeacherEventUnitList {...this.props} />
				<StaticTokenList showEventName={true} classes={this.props.classes} accessTokens={accessTokens} events={this.props.events} updateToken={updateStaticToken}
				                 isLoading={isLoading} />
				<MessageComposer asRole={HackingLabRole.COMPOSITE_TEACHER} />
			</>
		);
	}
}

const sortEvents = createMemoize(events => events.sort(eventDateSorter));

const filterAccessTokens = createMemoize((events, accessTokens) => {
	const currentTime = moment();
	const eventIds = events.filter(event => {
		return moment(event.endTime).isAfter(currentTime);
	}).map(event => event.id); // we only show tokens for events that are running currently, tokens for expired events make no sense
	return accessTokens.filter(token => token.tokenType === EventAccessibility.STATIC_TOKEN && eventIds.includes(token.eventId));
});

const mapStateToProps = state => ({
	events: sortEvents(state.api.resources.teacherEvents.data),
	accessTokens: filterAccessTokens(state.api.resources.teacherEvents.data, state.api.resources.accessTokens.data),
	isLoading: state.api.resources.teacherEvents.pending || state.api.resources.accessTokens.pending
});

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchTeacherEvents: teacherApi.fetchTeacherEvents,
	fetchAccessTokens: teacherApi.fetchAccessTokens,
	updateStaticToken: teacherApi.updateStaticToken
}, dispatch);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withIntl,
	withStyles(teacherStyle)
)(Teacher);

import { ProfileInformationRequirement, UserProfileRequirements } from "models/TenantAccessPolicy";
import { UserProfile } from "models/User";

export const checkUserProfileRequirementPolicyViolation = (userProfileRequirements?: UserProfileRequirements, userProfile?: UserProfile) =>
{
	let violation = false;
	if (userProfileRequirements) {
		if (!userProfile) {
			console.error("error while checking user profile");
			return violation;
		}
		Object.entries(userProfileRequirements).forEach(([ key, req ]) => {
			if (ProfileInformationRequirement.MANDATORY === req) {
				const profileKey = Object.keys(userProfile).find(userProfileKey => userProfileKey.toLowerCase() === key.toLowerCase());
				if (!profileKey) {
					violation = true;
					return true;
				}
				const includedKey = profileKey as keyof UserProfile;
				if ((!userProfile[includedKey]?.toString().length || userProfile[includedKey]?.toString() === "UNDEFINED")) {
					violation = true;
					return true;
				}
			}
		});
	}
	return violation;
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { fetchCurriculumEvents, postEventRegistration } from "actions/events";
import { createMemoize } from "helper/memoize";
import { eventSortOrderSorter } from "helper/sorting";
import { RootState } from "reducers";
import { EventList } from "shared/event/components/EventList/EventList";
import { Event } from "shared/event/model/Event";

interface CurriculumEventsProps {
	eventId?: number;
}

export const CurriculumEvents = ({ eventId }: CurriculumEventsProps) => {
	const { push } = useHistory();
	const dispatch = useDispatch();

	const isLoading = useSelector((state: RootState) => state.api.resources.events.pending);
	const curriculumEvents = useSelector((state: RootState) => !!eventId && filterCurriculumEvents(state.api.resources.events.data, eventId));
	const event = useSelector((state: RootState) => !!eventId && getEvent(state.api.resources.events.data, eventId));

	useEffect(() => {
		if (eventId) {
			dispatch(fetchCurriculumEvents(eventId));
		}
	}, [ eventId ]);

	const register = (eventIdToRegister: number) => {
		dispatch(postEventRegistration(eventIdToRegister, (event: Event & { registered: boolean }) => {
			if (event && event.id && event.registered) {
				push(`/events/${eventId}/curriculumEvents/${event.id}`);
			}
		}));
	};

	return (
		<>
			{/* <CurriculumProgress*/}
			{/*	events={curriculumEvents}*/}
			{/*	register={register}*/}
			{/*/ >*/}
			<EventList
				socialSharing={true}
				title={event?.name ?? "Event"}
				events={curriculumEvents}
				parentEvent={event}
				register={register}
				pending={isLoading}
			/>
		</>
	);
};

const filterCurriculumEvents = createMemoize((events: Event[], parentId: number) => {
	return events.filter(event => event.parent === parentId).sort(eventSortOrderSorter);
});

const getEvent = createMemoize((events: Event[], eventId: number) => {
	return events.find(event => event.id === eventId);
});

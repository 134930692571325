import { RemoteResourceState } from "@hlcr/core/api/RemoteResource";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchMaintenanceInfoBanner } from "components/MaintenanceInfoBanner/maintenanceBannerInfo.action";
import { MaintenanceInfoBannerDto } from "components/MaintenanceInfoBanner/MaintenanceInfoBannerDto";
import { RemoteResource } from "models/RemoteResource";
import { RootState } from "reducers";
import { clearRemoteResource } from "redux/actions";

export const useMaintenanceInfoBanner = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearRemoteResource(RemoteResource.MAINTENANCE_INFO_BANNER));
		dispatch(fetchMaintenanceInfoBanner());
	}, []);

	const maintenanceInfoBanner = useSelector<RootState, RemoteResourceState<MaintenanceInfoBannerDto>>((state) => {
		return state.remoteResourceReducer.remoteResources[RemoteResource.MAINTENANCE_INFO_BANNER];
	});

	return maintenanceInfoBanner?.data;
};

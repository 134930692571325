import { formatMessage } from "@hlcr/ui/Intl";
import AssignmentIcon from "@material-ui/icons/Assignment";
import moment from "moment";
import React, { createElement } from "react";

import { Badge } from "@hlcr/mui";
import { DownloadButton, ReportButton, ReportLink, ReportTableCard } from "components/Report/ReportComponents";
import { formatDateOnly } from "helper/dateCalc";
import { calcPercentage, printPercentage } from "helper/pointPrint";
import { getSolutionSuccessStateBySolutionInfo, getSolutionSuccessStateEnum, QUIZ_SOLUTION_STATES, THEORY_SOLUTION_STATES } from "models/SolutionState";
import { ASSET_TYPES, AssetType } from "models/Asset";
import { EventSelect } from "components/CustomSelect/EventSelect";
import { withRouter } from "react-router";

class EventTableCard extends React.Component {
	classId = this.props.match?.params?.classId;
	teamId = this.props.match?.params?.teamId;
	userId = this.props.match?.params?.userId;

	render() {
		const { challengeReports, events, selectedEvent, username, onSelectEvent, onSummarize, summarized, classes, intl } = this.props;

		const csvTitle = username && selectedEvent ? `User ${username} Event ${selectedEvent.eventName} Report, ${moment().format("DD.MM.YYYY HH:mm")}` : "";
		const csvFilename = username && selectedEvent ? `User_${username}_Event_${selectedEvent.eventName}_${moment().format("DD.MM.YYYY_HH.mm")}.csv` : "";
		const tableRenderer = summarized ? this.getTableRendererSummarized() : this.getTableRendererNormal();
		const groupedByUserReports = getGroupedByUserReports(challengeReports);

		return (
			<ReportTableCard
				tableData={summarized ? groupedByUserReports : challengeReports}
				tableRenderer={tableRenderer}
				cardIcon={AssignmentIcon}
				title={
					Array.isArray(events) && events.length > 0
					&& <div>
						{intl.fm("report.table.event")}
						<EventSelect
							selectedEvent={selectedEvent}
							events={events}
							onEventChange={onSelectEvent}
							title={intl.fm("report.table.event")}
							label={intl.fm("report.table.event")}
							required={true}
						/>
						<span className={classes.titleButton}>
							<ReportButton
								label={
									summarized
										? intl.fm("report.table.challenge.unsummarize")
										: intl.fm("report.table.challenge.summarize")
								}
								onClick={onSummarize}
								noBackground
							/>
						</span>
						<DownloadButton
							tableData={summarized ? groupedByUserReports : challengeReports}
							csvTitle={csvTitle}
							csvFilename={csvFilename}
							tableRenderer={tableRenderer}
						/>
					</div>
				}
			/>
		);
	}

	getTableRendererNormal() {
		const { disableActions, intl } = this.props;

		const tableRenderer = [
			{
				id: "type",
				renderCell: cr => createElement(ASSET_TYPES[cr.type].icon),
				sort: (ca, cb) => (ca.type < cb.type ? 1 : -1)
			},
			{
				id: "event",
				title: intl.fm("report.table.challenge.header.event"),
				renderCell: cr => cr.eventName,
				renderCsv: cr => cr.eventName,
				sort: true
			},
			{
				id: "user",
				title: intl.fm("report.table.challenge.header.user"),
				renderCell: cr => cr.username,
				renderCsv: cr => cr.username,
				sort: true
			},
			{
				id: "name",
				title: intl.fm("report.table.challenge.header.name"),
				renderCell: cr => cr.title,
				renderCsv: cr => cr.title,
				sort: true
			},
			{
				id: "state",
				title: intl.fm("report.table.challenge.header.state"),
				renderCell: cr => {
					const solState = getSolutionState(cr);
					return solState && (<Badge color={solState.color}>{formatMessage(solState.title)}</Badge>);
				},
				renderCsv: cr => intl.fm(getSolutionState(cr)?.title)
			},
			{
				id: "status",
				title: intl.fm("report.table.challenge.header.status"),
				renderCell: cr => cr.type !== AssetType.THEORY && printPercentage(cr.solutionPoints, cr.maxPoints),
				renderCsv: cr => cr.type !== AssetType.THEORY && printPercentage(cr.solutionPoints, cr.maxPoints),
				sort: (a, b) => b.solutionPoints - a.solutionPoints
			},
			{
				id: "lastSolution",
				title: intl.fm("report.table.challenge.header.last_solution"),
				renderCell: getLastSolutionDate,
				renderCsv: getLastSolutionDate,
				sort: true
			}
		];
		if (!disableActions) {
			tableRenderer.push({
				id: "action",
				title: intl.fm("report.table.challenge.header.action"),
				renderCell: this.getTableActions
			});
		}
		return tableRenderer;
	}

	getTableRendererSummarized() {
		const { events, intl } = this.props;
		const tableRenderer = [
			{
				id: "user",
				title: intl.fm("report.table.challenge.header.user"),
				renderCell: cr => cr.username,
				renderCsv: cr => cr.username,
				sort: true
			}
		];

		const eventList = events.filter(event => event.eventId > 0);

		eventList.forEach(event => {
			const percentageCalculator = report =>
				this.getEventPoints(report, event.eventId);
			const percentagePrinter = report =>
				`${this.getEventPoints(report, event.eventId)}%`;

			tableRenderer.push({
				id: "eventName",
				title: event.eventName,
				renderCell: percentagePrinter,
				renderCsv: percentagePrinter,
				sort: (a, b) => percentageCalculator(b) - percentageCalculator(a)
			});
		});
		return tableRenderer;
	}

	getTableActions = cr => {
		const {
			isOwnReport,
			auditorReport,
			intl
		} = this.props;

		if (!cr.eventUnitId) return null;

		if (cr.solutionId && !isOwnReport)
			return (
				<ReportLink
					label={intl.fm("solution.name")}
					to={getSolutionLink(cr, this.userId, this.teamId, this.classId)}
				/>
			);
		else
			return (
				!auditorReport && (
					<ReportLink label={getUnitTypeName(cr, intl)} to={getUnitLink(cr)}/>
				)
			);
	};

	getEventPoints(report, eventId) {
		const eventReport = report.events ? report.events[eventId] : undefined;
		if (eventReport)
			return calcPercentage(
				eventReport.solutionPoints,
				eventReport.maxPoints,
				0
			);
		return 0;
	}
}

const getUnitLink = unit => {
	switch (unit.type) {
		case AssetType.THEORY:
			return `/events/${unit.eventId}/theory/${unit.eventUnitId}`;
		case AssetType.CHALLENGE:
			return `/events/${unit.eventId}/challenges/${unit.eventUnitId}`;
		case AssetType.QUIZ:
			return `/events/${unit.eventId}/quiz/${unit.eventUnitId}`;
		default:
			return "";
	}
};

const getSolutionLink = (unit, userId, teamId, classId) => {
	switch (unit.type) {
		case AssetType.THEORY:
			return "";
		case AssetType.CHALLENGE:
			return `/report/classes/${classId}/teams/${teamId}/users/${userId}/challenge/${unit.solutionId}`;
		case AssetType.QUIZ:
			return `/report/classes/${classId}/teams/${teamId}/users/${userId}/quiz/${unit.solutionId}`;
		default:
			return "";
	}
};

const getUnitTypeName = (unit, intl) => {
	switch (unit.type) {
		case AssetType.THEORY:
			return intl.fm("theory.name");
		case AssetType.CHALLENGE:
			return intl.fm("challenge.name");
		case AssetType.QUIZ:
			return intl.fm("quiz.name");
		default:
			return "";
	}
};

const getLastSolutionDate = cr => {
	if (cr.type === AssetType.THEORY && cr.firstVisitTime)
		return formatDateOnly(cr.firstVisitTime);
	if (cr.lastEditedTime) return formatDateOnly(cr.lastEditedTime);
	else return "";
};

const getSolutionState = cr => {
	if (cr.type === AssetType.THEORY && cr.firstVisitTime) {
		return THEORY_SOLUTION_STATES.VISITED;
	} else if (cr.type === AssetType.CHALLENGE) {
		return getSolutionSuccessStateEnum(getSolutionSuccessStateBySolutionInfo(cr.solutionState, cr.solutionPoints < cr.maxPoints, cr.solutionPoints));
	} else {
		return QUIZ_SOLUTION_STATES[cr.solutionState] ?? QUIZ_SOLUTION_STATES.UNSOLVED;
	}
};

const getGroupedByUserReports = reports => {
	const reportSummary = {};

	reports.forEach(challenge => {
		if (!reportSummary[challenge.username]) {
			reportSummary[challenge.username] = { username: challenge.username };
			reportSummary[challenge.username].events = {};
		}

		const report = reportSummary[challenge.username].events;

		if (!report[challenge.eventId]) {
			report[challenge.eventId] = {};
			report[challenge.eventId].eventName = challenge.eventName;
			report[challenge.eventId].maxPoints = Number(challenge.maxPoints) || 0;
			report[challenge.eventId].solutionPoints =
				Number(challenge.solutionPoints) || 0;
		} else {
			const event = report[challenge.eventId];
			event.maxPoints = event.maxPoints + (Number(challenge.maxPoints) || 0);
			event.solutionPoints =
				event.solutionPoints + (Number(challenge.solutionPoints) || 0);
		}
	});

	return Object.keys(reportSummary).map(key => reportSummary[key]);
};

export default withRouter(EventTableCard);

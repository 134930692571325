import { HlcrRouteComponentProps } from "@hlcr/app/model/RouteComponent";
import { isAnyResourceNotLoaded, RemoteResourceState, RemoteResourceStatus } from "@hlcr/core/api/RemoteResource";
import { useIntl } from "@hlcr/ui/Intl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import NoData from "components/NoData/NoData";
import { RemoteResource } from "models/RemoteResource";
import { RootState } from "reducers";
import { RouteDetailCard } from "routes/components/RouteDetailCard";
import { TenantAdminConfiguration } from "tenantAdmin/model/TenantAdminConfiguration";
import { fetchTenantAdminConfiguration } from "tenantAdmin/tenantAdmin.actions";
import { TENANT_ADMIN_ROUTES } from "tenantAdmin/tenantAdmin.routes";

interface TenantAdminConfigProps extends HlcrRouteComponentProps {

}

export const TenantAdminConfig = ({ summary }: TenantAdminConfigProps) => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const tenantConfigState = useSelector<RootState, RemoteResourceState<TenantAdminConfiguration>>((state: RootState) => state.remoteResourceReducer.remoteResources[RemoteResource.TENANT_ADMIN_CONFIGURATION]);

	useEffect(() => {
		if (tenantConfigState.status === RemoteResourceStatus.INITIALIZED) {
			dispatch(fetchTenantAdminConfiguration());
		}
	}, [ tenantConfigState ]);


	if (isAnyResourceNotLoaded([ tenantConfigState.status ])) {
		return <LoadingSpinner />;
	}

	const summaryTable = <Table>
		<TableBody>
			<TableRow>
				<TableCell><strong>{intl.fm("tenant.admin.config.tenantName")}</strong></TableCell>
				<TableCell>{tenantConfigState?.data?.tenantName}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell><strong>{intl.fm("tenant.admin.config.baseUrl")}</strong></TableCell>
				<TableCell>{tenantConfigState?.data?.baseUrl}</TableCell>
			</TableRow>
		</TableBody>
	</Table>;

	if (summary) {
		if (tenantConfigState.data === undefined) {
			return <NoData />;
		}

		return summaryTable;
	}

	return <RouteDetailCard
		route={TENANT_ADMIN_ROUTES.CONFIGURATION}
		onClose={TENANT_ADMIN_ROUTES.ROOT.path}
	>
		{summaryTable}
	</RouteDetailCard>;
};

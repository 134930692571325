const newEventModalStyle = {
	actions: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	},
	row: {
		display: "flex",
		"& > :not(:last-child)": { marginRight: 10 }
	},
	inputLabel: {
		display: "block",
		fontSize: "1em"
	}
};

export default newEventModalStyle;

import { Dto } from "@hlcr/core/api/RemoteResource";
import { HttpMethod } from "@hlcr/core/enum/HttpMethod";

import { fetchEvent } from "actions/events";
import { RemoteResource } from "models/RemoteResource";
import { DynamicRequestAction, RemoteResourceActionType } from "redux/actions";
import { TermAgreement } from "term/term.model";

const BASE_URL = "/api";

interface FetchEventTermsPayload extends Dto {
	eventId: number;
}

interface TermAgreementUpdatePayload extends Omit<TermAgreement, "userId"> {
	eventId: number;
}

export const fetchEventTerm: DynamicRequestAction<FetchEventTermsPayload> = (payload) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/event/${payload?.eventId}/term/`,
	resource: RemoteResource.EVENT_TERMS,
	method: HttpMethod.GET,
});

export const acceptTerm: DynamicRequestAction<TermAgreementUpdatePayload> = (payload, onBefore, onSuccess) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/term/${payload?.termId}/agreement/`,
	resource: RemoteResource.TERM_AGREEMENTS,
	method: HttpMethod.PUT,
	payload,
	successNotification: true,
	onSuccess: (dispatch) => {
		dispatch(fetchEvent(payload?.eventId));
		if (onSuccess && typeof onSuccess === "function") {
			onSuccess(dispatch);
		}
	},
});

import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import cx from "classnames";
import isFunction from "lodash/isFunction";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import NoData from "components/NoData/NoData";
import EnhancedCollapsibleTable, { COLLAPSE_CELL } from "components/Table/EnhancedCollapsibleTable";
import { formatFullDate } from "helper/dateCalc";
import useQuery from "helper/useQuery";
import { EventType } from "models/EventType";
import usePersistedState from "helper/usePersistedState";

export default ({ events, classes, isLoading, intl }) => {
	const filterQueryParameter = "filter";
	const filterQuery = useQuery().get(filterQueryParameter) ?? "";

	const persistFor = "TeacherEvents";
	const prefix = `${persistFor}::Filter`;

	const [ onlyShowEventsWithOpenSolutions, setOnlyShowEventsWithOpenSolutions ] = usePersistedState(`${prefix}::NeedsCorrection`, true, value => value === true);
	const [ eventsWithHierarchy, setEventsWithHierarchy ] = React.useState([]);
	const [ filter, setFilter ] = React.useState(filterQuery);
	const [ filterTimeoutHandle, setFilterTimeoutHandle ] = React.useState(null);
	const history = useHistory();

	const allEvents = events.map(event => {
		return {
			...event,
			isVisible: true,
		};
	});

	useEffect(() => {
		const eventsWithHierarchyTmp = allEvents.filter(event => event.type === EventType.CURRICULUM || event.type === EventType.STANDALONE_EVENT).map(event => {
			const children = allEvents.filter(child => child.type === EventType.CURRICULUM_EVENT && child.parentId === event.id);
			return {
				...event,
				events: children,
			};
		});

		// we need to call filterEvents here, because we cannot do it in the useEffect below, as this would result in an endless loop
		if (eventsWithHierarchyTmp?.length > 0) {
			filterEvents([ ...eventsWithHierarchyTmp ], filter, onlyShowEventsWithOpenSolutions).then((filteredEvents) => setEventsWithHierarchy(filteredEvents));
		}
	}, [ events ]);

	useEffect(() => {
		// console.log("filtering events");
		// console.log(onlyShowEventsWithOpenSolutions);
		filterEvents([ ...eventsWithHierarchy ], filter, onlyShowEventsWithOpenSolutions).then((filteredEvents) => setEventsWithHierarchy(filteredEvents));
	}, [ filterQuery, onlyShowEventsWithOpenSolutions, filter ]);

	useEffect(() => {
		// because it is quite slow to replace history, we only do it if the filter has not changed for a certain amount of time
		if (filter !== filterQuery) {
			if (filterTimeoutHandle) {
				clearTimeout(filterTimeoutHandle);
			}
			setFilterTimeoutHandle(setTimeout(() => {
				if (filter) {
					history.replace(`/teacher?${filterQueryParameter}=${filter}`);
				} else {
					history.replace(`/teacher`);
				}
			}, 1000));
		}
	}, [ filter ]);

	const getNextSolutionUrl = event => event.nextSolutionToCorrect ? `/teacher/events/${event.id || "undefined"}/solutions/${event.nextSolutionToCorrect || ""}` : undefined;

	const tableRenderer = [
		{
			id: COLLAPSE_CELL,
			colStyle: { width: 50 },
			renderCell: () => <></>,
		},
		{
			id: "image",
			title: intl.fm("teacher.table.name"),
			colStyle: { width: 130 },
			renderCell: (event, index, toggle) => (
				<Paper elevation={2} square className={classes.thumbPaper}>
					<ConditionalLink condition={event.type !== EventType.CURRICULUM} to={`/teacher/events/${event.id}/units`} alternative={toggle} classes={classes}>
						<img
							src={`/api/images/events/${event.id}`}
							onError={e => {
								e.target.src = "/No_image_3x4.svg";
							}}
							className={classes.thumb}
							alt={event.name}
						/>
					</ConditionalLink>
				</Paper>
			),
		},
		{
			id: "name",
			renderCell: (event, index, toggle) => (
				<div className={classes.eventDescription}>
					<ConditionalLink condition={event.type !== EventType.CURRICULUM} to={`/teacher/events/${event.id}/units`} alternative={toggle} classes={classes}>
						<div className={classes.eventTitleWrapper}>
							<span className={classes.eventTitle}>{event.name}</span>
							{event.exam ? `(${intl.fm("event.exam.title")})` : ""}
						</div>
					</ConditionalLink>
				</div>
			),
			sort: (a, b) => (a.name < b.name ? 1 : -1)
		},
		{
			id: "duration",
			title: intl.fm("teacher.table.duration"),
			renderCell: event => (
				<div className={classes.container}>
					<span className={classes.left}>Start</span>{" "}
					<span className={classes.right}>{formatFullDate(event.startTime)}</span>
					<br />
					<span className={classes.left}>End</span>{" "}
					<span className={classes.right}>{formatFullDate(event.endTime)}</span>
				</div>
			),
			sort: (a, b) => (a.startTime > b.startTime ? 1 : -1),
			colStyle: { width: 170 }
		},
		{
			id: "solutions",
			title: intl.fm("teacher.table.solutions"),
			renderCell: event => (
				<div className={classes.container}>
					<span className={classes.left}>{intl.fm("teacher.event.openSolutions")}</span>
					<span className={classes.right}>{event.submittedSolutionCount ? event.submittedSolutionCount : 0}</span>
					<br />
					<span className={cx(classes.container, classes.alignRight)}>
						{event.type !== EventType.CURRICULUM && (
							<EditButton
								targetUrl={`/teacher/events/${event.id}`}
								text={intl.fm("teacher.event.view")}
								iconWrapper={{ icon: VisibilityIcon }}
								classBtnContainer={classes.buttonContainer}
								classes={classes}
							/>
						)}
						<EditButton
							targetUrl={getNextSolutionUrl(event)}
							text={intl.fm("teacher.event.nextSolution")}
							iconWrapper={{ icon: EditIcon }}
							classBtnContainer={classes.buttonContainer}
							classes={classes}
						/>
					</span>
				</div>
			),
			sort: (a, b) => a.submittedSolutionCount < b.submittedSolutionCount ? 1 : -1,
			colStyle: { width: 145 }
		},
		{
			id: "participants",
			title: intl.fm("teacher.table.participants"),
			renderCell: event => (
				<div className={classes.container}>
					<span className={classes.left}>Registered</span>
					<span className={classes.right}>{event.participantCount ? event.participantCount : 0}</span>
					<br />
					<EditButton targetUrl={`/teacher/events/${event.id}/participants`} text={"show"} iconWrapper={{ icon: VisibilityIcon }} classes={classes} />
				</div>
			),
			sort: (a, b) => (a.participantCount < b.participantCount ? 1 : -1),
			colStyle: { width: 140 },
		}
	];

	const filterByName = (name, filter) => name.toLowerCase().includes(filter.toLowerCase());
	const filterEvents = async (events, filter, onlyShowEventsWithOpenSolutions) => {
		for (let event of events) {
			// we reset the visibility of the event
			event.isVisible = true;

			// if the event has child, we call the function recursively
			if (event.events?.length > 0) {
				event.events = await filterEvents([ ...event.events ], filter, onlyShowEventsWithOpenSolutions);
			}

			// if the switch is on, we hide the event if it has no solutions
			if (onlyShowEventsWithOpenSolutions && event.submittedSolutionCount === 0) {
				event.isVisible = false;
			}

			// if the filter is not empty, we hide the event if it does not match the filter
			if (filter?.length > 0 && !filterByName(event.name, filter)) {
				event.isVisible = false;
			}

			// finally we need to make sure that parent/children are visible if needed
			if (event.events?.length > 0) {
				if (event.isVisible) {
					// if parent is visible, we show all child events
					event.events.map(e => {
						if (!onlyShowEventsWithOpenSolutions || onlyShowEventsWithOpenSolutions && e.submittedSolutionCount > 0) {
							e.isVisible = true;
						}
					});
				} else {
					// if one of the child is visible, we show the parent
					event.isVisible = event.events.some(e => e.isVisible);
				}
			}
		}
		return events;
	};

	const getVisibleEvents = (eventsToFilter) => {
		let visibleEvents = [];
		for (let event of eventsToFilter) {
			event = { ...event }; // we clone the event
			if (event.events?.length > 0) {
				event.events = getVisibleEvents(event.events);
			}
			if (event.isVisible) {
				visibleEvents.push(event);
			}
		}
		return visibleEvents;
	};

	const onToggleOpenSolutionsSwitch = (event) => {
		setOnlyShowEventsWithOpenSolutions(event.target.checked);
	};

	const tableTitle = (
		<span>
			{intl.fm("teacher.table.title")}
			{isLoading && <CircularProgress size={18} />}
			<CustomInput
				inputProps={{
					value: filter,
					type: "inputSearch",
					onChange: (event) => {
						setFilter(event.target.value);
					},
					placeholder: intl.fm("common.labels.filter"),
				}}
				formControlProps={{ className: classes.filterInputControl }}
			/>
			<div className={classes.inlineSwitchBlock}>
				<InputLabel onClick={() => setOnlyShowEventsWithOpenSolutions(!onlyShowEventsWithOpenSolutions)} className={classes.inlineSwitchLabel}>
					{intl.fm("teacher.event.needsCorrection")}
				</InputLabel>
				<CustomSwitch checked={onlyShowEventsWithOpenSolutions} onChange={onToggleOpenSolutionsSwitch} />
			</div>
		</span>
	);

	return (
		<GridContainer>
			<ItemGrid xs={12}>
				<IconCard
					icon={AssignmentTurnedInIcon}
					iconColor="purple"
					title={tableTitle}
					content={
						<EnhancedCollapsibleTable
							isLoading={isLoading}
							tableData={getVisibleEvents(eventsWithHierarchy)}
							tableRenderer={tableRenderer}
							hover
							hasCollapseProperties={row => row.events?.length > 0}
							extractCollapseProperties={row => row.events.sort((a, b) => a.sortOrder - b.sortOrder)}
							persistSortFor={persistFor}
							emptyContent={<NoData />}
						/>
					}
				/>
			</ItemGrid>
		</GridContainer>
	);
};

const EditButton = ({ classes, targetUrl, text, iconWrapper, classBtnContainer }) => (
	<div className={classBtnContainer}>
		{targetUrl
			? (<Link to={targetUrl}><ButtonHelper iconWrapper={iconWrapper} text={text} classes={classes} /></Link>)
			: (<ButtonHelper disabled iconWrapper={iconWrapper} text={text} classes={classes} />)
		}
	</div>
);

const ConditionalLink = ({ classes, to, children, condition, alternative }) => {
	if (to && condition) {
		return (<Link to={to}>{children}</Link>);
	}
	if (alternative && isFunction(alternative)) {
		return (<div className={classes.mouseHover} onClick={() => alternative()}>{children}</div>);
	}
	return children;
};

const ButtonHelper = ({ disabled, iconWrapper, text, classes }) => (
	<Button disabled={disabled} color={disabled ? "gray" : "info"} customClass={classes.editButton}>
		{iconWrapper && <iconWrapper.icon className={classes.icon} />}
		{text && <span className={classes.text}>{text}</span>}
	</Button>
);

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import eventsApi from "actions/events";
import { isFinished } from "helper/dateCalc";
import { createMemoize } from "helper/memoize";
import Quiz from "views/Events/Quiz";

const getQuiz = createMemoize((quizzes, quizId) =>
	quizzes.find(quiz => quiz.id === quizId)
);

const getEvent = createMemoize((events, eventId) =>
	events.find(event => event.id === eventId)
);

const filterGradingInstructions = createMemoize((gradingInstructions, quizId) =>
	gradingInstructions.filter(instruction => instruction.quizId === quizId)
);

const filterComments = createMemoize((comments, quizId) =>
	comments.filter(comment => comment.quizId === quizId)
);

const filterQuizAnswers = createMemoize((answers, quizId) =>
	answers.filter(answer => answer.quizId === quizId)
);

const mapStateToProps = (state, ownProps) => {
	const {
		quizzes,
		events,
		quizComments,
		quizAnswers,
		questionGradingInstructions
	} = state.api.resources;

	const quizId = Number(ownProps.match.params.unitId);
	const eventId = Number(ownProps.match.params.eventId);

	const quiz = getQuiz(quizzes.data, quizId);
	const event = getEvent(events.data, eventId);
	const quizClosed = isFinished(event, quiz);
	const showPartialSolution = quiz && quiz.trainingMode;
	const showAllSolution =
		showPartialSolution && quiz.solution && quiz.solution.finished;

	return {
		event,
		quizId,
		quiz,
		quizClosed,
		showAllSolution,
		showPartialSolution,
		gradingInstructions: filterGradingInstructions(
			questionGradingInstructions.data,
			quizId,
		),
		comments: filterComments(quizComments.data, quizId),
		quizAnswers: filterQuizAnswers(quizAnswers.data, quizId),
		isLoading: events.pending || quizzes.pending,
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchQuiz: eventsApi.fetchQuiz,
			fetchQuizAnswers: eventsApi.fetchQuizAnswers,
			// TODO: If the participant should see if something was correct or not, we need to fetch the grading instructions.
			// There is a frontend method available, but it has no corresponding backend method (eventsApi.fetchQuizGradingInstructions)
		},
		dispatch
	);

let UserQuiz = connect(
	mapStateToProps,
	mapDispatchToProps
)(Quiz);

export default UserQuiz;

let UserQuizBreadCrumb = ({ quiz }) => <span>{quiz ? quiz.title : "Quiz"}</span>;

const mapStateToPropsBreadCrumb = (state, ownProps) => {
	const quizId = Number(ownProps.match.params.unitId);

	return { quiz: state.api.resources.quizzes.data.find(q => q.id === quizId) };
};

UserQuizBreadCrumb = connect(mapStateToPropsBreadCrumb)(UserQuizBreadCrumb);

export { UserQuizBreadCrumb };

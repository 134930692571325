import modalStyle from "../modalStyle";

const modalWindowStyles = {
	...modalStyle,
	modalHelp: {
		width: "20px",
		height: "20px",
		color: "#666",
		position: "relative",
		marginLeft: 5,
		top: "4px"
	},
	actions: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	},
	disableOverflow: { overflow: "unset" },
	content: { marginTop: 20 }
};

export default modalWindowStyles;

import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import managerApi from "actions/manager";
import managerStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/manager/managerStyle";
import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import ValidationInput from "components/CustomInput/ValidationInput";
import { Upload } from "@hlcr/mui/Upload";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import WarningDialog from "components/ModalWindow/WarningDialog";
import NoData from "components/NoData/NoData";
import { formatFullDate, getDateInputValue } from "helper/dateCalc";
import { getImageData } from "helper/manager";
import { endDateIsGreaterValidation } from "helper/validations";
import { withIntl } from "@hlcr/ui/Intl";
import ManagerClassTeachers from "views/Manager/ManagerClassTeachers";
import ManagerClassTeams from "views/Manager/ManagerClassTeams";

class ClassDetail extends React.Component {
	state = {
		managerClass: null,
		image: null,
		existsImage: false,
		deleteOpen: false,
		archiveOpen: false,
		validation: {
			name: true,
			startTime: true,
			endTime: true,
			description: true
		}
	};

	static getDerivedStateFromProps(props, state) {
		if (props.managerClass && (!state.managerClass || props.managerClass.id !== state.managerClass.id || props.existsImage !== state.existsImage)) {
			return {
				managerClass: props.managerClass,
				existsImage: props.existsImage,
				image: props.existsImage ? { name: props.intl.fm("common.labels.image") } : null
			};
		}
		return null;
	}

	headActions = props => {
		const { archiveOpen, deleteOpen, classes, intl, isFullValid, disabledDelete } = props;
		const colorDelete = disabledDelete ? "defaultNoBackground" : "dangerNoBackground";

		return (
			<div className={classes.actions}>
				<Button onClick={this.resetForm} color="defaultNoBackground">
					{intl.fm("common.labels.resetForm")}
				</Button>
				<Button onClick={this.submit} disabled={!isFullValid} color="infoNoBackground">
					{intl.fm("common.labels.save")}
				</Button>
				<Button disabled={disabledDelete} onClick={this.confirmDelete} color={colorDelete}>
					{intl.fm("common.labels.delete")}
				</Button>
				<Button onClick={this.confirmArchive} color="warningNoBackground">
					{intl.fm("manager.classes.archive")}
				</Button>
				<WarningDialog onConfirm={this.delete} onClose={this.cancelDelete} open={deleteOpen} entityName={intl.fm("class.entityName")} />
				<WarningDialog onConfirm={this.archive} onClose={this.cancelArchive} open={archiveOpen} title={intl.fm("common.archiveConfirm.title")} message={intl.fm("manager.classes.archive.msg")} />
			</div>
		);
	};

	render() {
		const { managerClass, intl, classes, teams, isArchived } = this.props;
		const { archiveOpen, deleteOpen, validation } = this.state;

		const classTeams = teams.filter(team => team.classId === managerClass?.id);
		const disabledDelete = classTeams.length !== 0;

		const isFullValid = Object.values(validation).reduce((res, value) => res && value, true);

		const actions = !isArchived ? this.headActions({ archiveOpen, isFullValid, deleteOpen, intl, classes, disabledDelete }) : null;

		return (<IconCard title={intl.fm("team.titles.general")} icon={InfoIcon} iconColor="purple" content={managerClass ? this.inputForm() : <NoData />} footer={actions} />);
	}

	submit = () => {
		const { image, managerClass } = this.state;
		const imageData = getImageData(image);
		this.props.updateManagerClass(managerClass, imageData);
	};

	cancelDelete = () => {
		this.setState({ deleteOpen: false });
	};

	confirmDelete = () => {
		this.setState({ deleteOpen: true });
	};

	cancelArchive = () => {
		this.setState({ archiveOpen: false });
	};

	confirmArchive = () => {
		this.setState({ archiveOpen: true });
	};

	delete = () => {
		const { removeClass, history } = this.props;
		const { managerClass } = this.state;
		this.setState({ deleteOpen: false });
		if (managerClass) {
			removeClass(managerClass, true, () => history.push("/manager/classes"));
		}
	};

	archive = () => {
		const { archiveClass, history } = this.props;
		const { managerClass } = this.state;
		this.setState({ archiveOpen: false });
		if (managerClass) {
			archiveClass(managerClass, true, () => history.push("/manager/classes"));
		}
	};

	resetForm = () => {
		this.setState((state, props) => ({
			managerClass: props.managerClass,
			image: { name: props.intl.fm("common.labels.image") },
			validation: {
				name: true,
				startTime: true,
				endTime: true,
				description: true
			}
		}));
	};

	updateField = fieldName => (value, valid) => {
		this.setState(state => ({
			managerClass: { ...state.managerClass, [fieldName]: value },
			validation: { ...state.validation, [fieldName]: valid }
		}));
	};

	updateFile = image => this.setState({ image });

	inputForm = () => {
		const { classes, intl, isArchived, imageUrl } = this.props;
		const { managerClass, validation, image } = this.state;

		const dateType = isArchived ? "text" : "date";

		const startTime = isArchived ? formatFullDate(managerClass.startTime) : getDateInputValue(managerClass.startTime);
		const endTime = isArchived ? formatFullDate(managerClass.endTime) : getDateInputValue(managerClass.endTime);

		const startDateLabel = isArchived ? intl.fm("common.labels.beginDate") : intl.fm("common.labels.chooseBeginDate");
		const endDateLabel = isArchived ? intl.fm("common.labels.endDate") : intl.fm("common.labels.chooseEndDate");

		return (
			<form className={classes.detailsForm}>
				<div className={classes.descriptionBox}>
					<ValidationInput
						label={intl.fm("class.field.name")}
						value={managerClass.name}
						disabled={isArchived}
						onChange={this.updateField("name")}
						inputClasses={classes.inputOverflow}
						validations={{ required: true, minLength: 1, maxLength: 100 }}
						fullWidth
					/>
					<ValidationInput
						type={dateType}
						disabled={isArchived}
						value={startTime}
						onChange={this.updateField("startTime")}
						label={startDateLabel}
						fullWidth
						validations={{ required: true }}
					/>
					<ValidationInput
						type={dateType}
						disabled={isArchived}
						value={endTime}
						onChange={this.updateField("endTime")}
						label={endDateLabel}
						fullWidth
						validations={{
							custom: endDateIsGreaterValidation(
								managerClass.startTime,
								validation.startTime
							)
						}}
					/>
					<ValidationInput
						type="text"
						value={managerClass.description}
						disabled={isArchived}
						onChange={this.updateField("description")}
						label={intl.fm("class.field.description")}
						validations={{ maxLength: 500 }}
						inputProps={{
							multiline: true,
							rows: 4
						}}
						fullWidth
					/>
				</div>
				{isArchived ? (<img src={imageUrl} alt="class" className={classes.imageUploadForm} />)
					: (<Upload type="image" handleProcessedFile={this.updateFile} file={image} imageUrl={imageUrl} rootClassName={classes.imageUploadForm} />)
				}
			</form>
		);
	};
}

class ManagerClassDetails extends React.Component {
	state = {
		imageUrl: undefined,
		existsImage: false
	};

	componentDidMount() {
		const { classId, fetchManagerTeams, fetchManagerClass, fetchManagerTeachers, fetchManagerClassTeachers, fetchClassImageUrl } = this.props;
		fetchManagerClass(classId);
		fetchManagerTeams();
		fetchManagerTeachers();
		fetchManagerClassTeachers(classId);

		const maybeImageUrl = `/api/images/manager/classes/${classId}`;
		const fallbackImageUrl = "/No_image_3x4.svg";
		fetchClassImageUrl(
			maybeImageUrl,
			() => {
				this.setState({ imageUrl: maybeImageUrl, existsImage: true });
			},
			() => {
				this.setState({ imageUrl: fallbackImageUrl, existsImage: false });
			}
		);
	}

	render() {
		const { imageUrl, existsImage } = this.state;

		return (
			<div>
				<GridContainer>
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						<ClassDetail {...this.props} imageUrl={imageUrl} existsImage={existsImage} />
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={6} lg={6}>
						<ManagerClassTeams {...this.props} />
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={6} lg={6}>
						<ManagerClassTeachers {...this.props} />
					</ItemGrid>
				</GridContainer>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const classId = Number(ownProps.match.params.classId);
	const managerClass = state.api.resources.managerClasses.data.find(cl => cl && cl.id === classId);
	return {
		classId,
		isArchived: managerClass && managerClass.archived,
		teams: state.api.resources.managerTeams.data,
		managerClass,
		classTeachers: state.api.resources.managerClassTeachers.data.filter(ct => ct && ct.classId === classId),
		teachers: state.api.resources.managerTeachers.data
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchClassImageUrl: managerApi.fetchClassImageUrl,
			fetchManagerTeams: managerApi.fetchManagerTeams,
			fetchManagerClass: managerApi.fetchManagerClass,
			fetchManagerTeachers: managerApi.fetchManagerTeachers,
			fetchManagerClassTeachers: managerApi.fetchManagerClassTeachers,
			updateManagerClass: managerApi.updateManagerClass,
			removeClass: managerApi.removeClass,
			archiveClass: managerApi.archiveClass,
			updateTeam: managerApi.updateTeam,
			addManagerClassTeacher: managerApi.addManagerClassTeacher,
			removeManagerClassTeacher: managerApi.removeManagerClassTeacher
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withIntl,
	withStyles(managerStyle)
)(ManagerClassDetails);

let ManagerClassDetailsBreadCrumb = ({ managerClass, intl }) => {
	let classLabel = intl.fm("manager.classes.details.breadCrumb");
	if (managerClass) {
		const archiveLabel = managerClass.archived ? " (" + intl.fm("common.labels.archived") + ")" : "";
		classLabel = managerClass.name + archiveLabel;
	}
	return <span>{classLabel}</span>;
};

const mapStateToPropsBreadCrumbs = (state, ownProps) => {
	const classId = Number(ownProps.match.params.classId);
	return { managerClass: state.api.resources.managerClasses.data.find(cl => cl && cl.id === classId) };
};

ManagerClassDetailsBreadCrumb = withIntl(connect(mapStateToPropsBreadCrumbs)(ManagerClassDetailsBreadCrumb));
export { ManagerClassDetailsBreadCrumb };

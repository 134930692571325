export const actionStyles = {
	button: {
		margin: "0px 2px 0px 2px",
		padding: 5,
		opacity: 1
	},
	buttonDisabled: {
		margin: "0px 2px 0px 2px",
		padding: 5,
		opacity: 0.5
	},
	icon: {
		verticalAlign: "middle",
		width: "17px",
		height: "17px",
		top: "-1px",
		position: "relative"
	}
};

export const tableSpecialHighligtingStyles = {
	disabledStyle: {
		opacity: 1,
		"& > td": { opacity: 0.5 },

		"& > td:last-child": { opacity: 1 }
	}
};

export const filterInputStyles = {
	formControl: {
		float: "right",
		marginTop: "-26px",
		marginBottom: 0
	}
};

export const floatingActionButtonStyles = {
	button: { float: "left" },
	iconPosition: { margin: -3 }
};

export default { actionStyles, filterInputStyles };

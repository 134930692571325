import { useSelector } from "react-redux";

import managerApi from "actions/manager";
import { PowerState, VirtualMachine } from "models/VirtualMachine";
import { RootState } from "reducers";
import { EventManagerSummary } from "views/Manager/EventManagerSummary";


interface VirtualMachineSummaryProps {
}

export const VirtualMachineSummary = (props: VirtualMachineSummaryProps) => {
	const loadingSelector = (state: RootState) => state.api.resources.managerVms.pending;
	const vms: VirtualMachine[] = useSelector((state: RootState) => state.api.resources.managerVms.data);

	// @formatter:off
	const marks = [
		{ value: 0, label: "Stopped" ,  quantity: vms.filter((v) => v.state === PowerState.POWERED_OFF).length },
		{ value: 1, label: "Suspended", quantity: vms.filter((v) => v.state === PowerState.SUSPENDED).length },
		{ value: 2, label: "Running",   quantity: vms.filter((v) => v.state === PowerState.POWERED_ON).length },
	];
	// @formatter:on

	return <EventManagerSummary
		marks={marks}
		stateInitializers={[ managerApi.fetchManagerEvents ]}
		loadingSelector={loadingSelector}
	/>;
};

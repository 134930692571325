import moment from "moment/moment";

export const endDateIsGreaterValidation = startTime => (value, helpText) => {
	if (
		value === "" ||
		!(value instanceof moment) ||
		(startTime && value.toISOString() > startTime)
	) {
		return true;
	}
	helpText.push("End Date must exceed start Date.");
	return false;
};

import brandingActions, { defaultBranding } from "../actions/branding";
import { ActionType } from "actions/ActionType";

export default middleware => next => async action => {
	const nextAction = next(action);

	if (action.type === ActionType.FETCH_BRANDING_CONFIG) {
		try {
			const response = await fetch("/config/branding.json");
			const json = await response.json();
			for (var key of Object.keys(defaultBranding)) {
				if (!json.hasOwnProperty(key) || json[key] === undefined) {
					json[key] = defaultBranding[key];
				}
			}
			middleware.dispatch(brandingActions.setBranding(json));
		} catch (e) {
			return middleware.dispatch(brandingActions.setBranding(defaultBranding));
		}
	}

	return nextAction;
};

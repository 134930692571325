import { ActionType } from "actions/ActionType";

const BASE_URL = "/api/user/teams";

const fetchTeams = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/`,
	method: "get",
	resource: "userTeams",
});

const fetchTeamJoinInfo = (token, onSuccess, onFailure) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/join/${token}`,
	method: "get",
	resource: "joinTeam",
	onSuccess: onSuccess,
	onFailure: onFailure,
	updatePropForObj: { idSelector: _ => token },
});

const joinTeam = (token, onSuccess, onFailure) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/join/${token}`,
	method: "post",
	resource: "userTeams",
	onSuccess: onSuccess,
	onFailure: onFailure,
	updatePropForObj: { idSelector: obj => obj.id },
});

export default {
	fetchTeams,
	fetchTeamJoinInfo,
	joinTeam,
};

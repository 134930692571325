import { HackingLabRoute } from "@hlcr/app/enum/EnumRoute";
import { useIntl } from "@hlcr/ui/Intl";
import { Button } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { Link } from "react-router-dom";

import IconCard from "components/Cards/IconCard";


interface RouteSummaryCardProps {
	route: HackingLabRoute;
}

export const RouteSummaryCard = ({ route }: RouteSummaryCardProps) => {
	const intl = useIntl();

	const title: JSX.Element = route.name
		? typeof route.name === "function"
			? route.name()
			: route.name
		: intl.fm(route.title);
	
	return <IconCard
		icon={route.icon}
		iconColor={route.iconColor}
		title={title}
		titleLink={route.path}
		content={<route.component summary={true} />}
		footer={
			<Link to={route.path}>
				<Button fullWidth={true}>
					{intl.fm("common.labels.goto")}&nbsp;<ArrowForward />
				</Button>
			</Link>
		}
	/>;
};

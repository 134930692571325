import { StyledMarkdown } from "@hlcr/ui";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useSelector } from "react-redux";

import { getQuestionState } from "helper/quiz";
import { Question, QuestionAnswer, QuestionType, QuizQuestionGradingInstruction } from "models/Quizzes";
import { QuestionAnswerState } from "models/SolutionState";
import { RootState } from "reducers";

import { LongAnswerQuestion } from "./LongAnswerQuestion";
import { MultipleChoiceQuestion } from "./MultipleChoiceQuestion";
import { ShortAnswerQuestion } from "./ShortAnswerQuestion";
import { SingleChoiceQuestion } from "./SingleChoiceQuestion";

type UpdateAnswerFn = (answer: QuestionAnswer) => void;

interface QuizAnswerEditorProps {
	question: Question;
	answer?: QuestionAnswer;
	readOnly: boolean;
	isPreview: boolean;
	updateAnswer: UpdateAnswerFn;
	gradingInstruction?: QuizQuestionGradingInstruction;
}

function QuizAnswerEditor(props: QuizAnswerEditorProps) {
	const darkMode = useSelector((state: RootState) => state.ui.darkMode);

	return (
		<React.Fragment>
			<Typography component="div">
				<StyledMarkdown source={props.question.content} darkMode={darkMode} />
				{getQuestionContent(props.question, props.updateAnswer, props.readOnly, props.isPreview, props.gradingInstruction, props.answer)}
			</Typography>
		</React.Fragment>
	);
}

function getQuestionContent(
	question: Question,
	updateAnswer: UpdateAnswerFn,
	readOnly: boolean,
	isPreview: boolean,
	gradingInstruction?: QuizQuestionGradingInstruction,
	answer?: QuestionAnswer,
) {
	const questionProps = {
		onChange: updateAnswer,
		question: question,
		answer: answer,
		disabled: readOnly,
		isPreview: isPreview,
		questionState: gradingInstruction ? getQuestionState(answer, gradingInstruction) : QuestionAnswerState.UNGRADED,
		gradingInstruction: gradingInstruction,
	};
	switch (question.type) {
		case QuestionType.MULTIPLE_CHOICE:
			return <MultipleChoiceQuestion {...questionProps} />;
		case QuestionType.SINGLE_CHOICE:
			return <SingleChoiceQuestion {...questionProps} />;
		case QuestionType.LONG_ANSWER:
			return <LongAnswerQuestion {...questionProps} />;
		case QuestionType.SHORT_ANSWER:
			return <ShortAnswerQuestion {...questionProps} />;
	}
}

export default QuizAnswerEditor;

import { useSelector } from "react-redux";

import managerApi from "actions/manager";
import { Team } from "models/Team";
import { RootState } from "reducers";
import { EventManagerSummary } from "views/Manager/EventManagerSummary";


interface TeamSummaryProps {
}

export const TeamSummary = (props: TeamSummaryProps) => {
	const loadingSelector = (state: RootState) => state.api.resources.managerTeams.pending;
	const teams: Team[] = useSelector((state: RootState) => state.api.resources.managerTeams.data);

	// @formatter:off
	const marks = [
		{ value: 0, label: "Archived", quantity: teams.filter((t) => t.archived).length },
		{ value: 1, label: "Active",   quantity: teams.filter((t) => !t.archived).length },
	];
	// @formatter:on

	return <EventManagerSummary
		marks={marks}
		stateInitializers={[ managerApi.fetchManagerTeams ]}
		loadingSelector={loadingSelector}
	/>;
};

import { EnumDetail } from "@hlcr/app/enum/EnumBase";
import { HackingLabRoute, HackingLabRouteType } from "@hlcr/app/enum/EnumRoute";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import { HelpAction, HelpRole } from "@hlcr/app/model/Help";
import { formatMessage } from "@hlcr/ui/Intl";
import { TuneOutlined } from "@material-ui/icons";
import LockIcon from "@material-ui/icons/Lock";
import MailIcon from "@material-ui/icons/Mail";
import SettingsIcon from "@material-ui/icons/Settings";


import { TenantAdminAccessPolicy } from "tenantAdmin/components/TenantAdminAccessPolicy";
import { TenantAdminConfig } from "tenantAdmin/components/TenantAdminConfig";

import { TenantAdmin } from "./components/TenantAdmin";
import { TenantAdminEmail } from "./components/TenantAdminEmail";


export enum TenantAdminRoutes {
	ROOT = "ROOT",
	CONFIGURATION = "CONFIGURATION",
	EMAIL = "EMAIL",
	ACCESS_POLICY = "ACCESS_POLICY",
}

export const TENANT_ADMIN_ROUTES: EnumDetail<TenantAdminRoutes, HackingLabRoute> = {
	[TenantAdminRoutes.ROOT]: {
		title: "tenant.admin.route.root",
		name: () => formatMessage("tenant.admin.route.root"),
		icon: SettingsIcon,
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/admin",
		component: TenantAdmin,
		hasSidebarLink: true,
		needsAnyRole: [
			HackingLabRole.TENANT_ADMIN_ACCESS_POLICY_READ,
			HackingLabRole.TENANT_CONFIGURATION_READ,
			HackingLabRole.TENANT_CONFIGURATION_EMAIL_READ,
		],
		help: {
			role: HelpRole.ADMIN,
			action: HelpAction.ROOT,
		},
	},
	[TenantAdminRoutes.CONFIGURATION]: {
		title: "tenant.admin.route.configuration",
		path: "/admin/config",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		component: TenantAdminConfig,
		icon: TuneOutlined,
		needsRole: HackingLabRole.TENANT_ADMIN_CONFIG_READ,
	},
	[TenantAdminRoutes.EMAIL]: {
		title: "tenant.admin.route.email",
		path: "/admin/email",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		component: TenantAdminEmail,
		icon: MailIcon,
		needsRole: HackingLabRole.TENANT_CONFIGURATION_EMAIL_READ,
	},
	[TenantAdminRoutes.ACCESS_POLICY]: {
		title: "tenant.admin.route.accessPolicy",
		path: "/admin/access-policy",
		type: HackingLabRouteType.COMPONENT_ROUTE,
		component: TenantAdminAccessPolicy,
		icon: LockIcon,
		needsRole: HackingLabRole.TENANT_ADMIN_ACCESS_POLICY_READ,
	},
};

import FlagIcon from "@material-ui/icons/Flag";
import InfoIcon from "@material-ui/icons/Info";
import SubjectIcon from "@material-ui/icons/Subject";
import { createStyles, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import { useIntl } from "@hlcr/ui/Intl";
import { Badge } from "@hlcr/mui";

import { grayColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import CollapseIconCard from "components/Cards/CollapseIconCard";
import Tooltip from "components/CustomTooltip/CustomTooltip";
import LevelLabel from "components/LevelLabel/LevelLabel";
import Table from "components/Table/Table";
import { CategoryIcons } from "helper/categories";
import { printPercentage } from "helper/pointPrint";
import { getSolutionOrQuizSolutionState } from "models/SolutionState";
import { fetchHelp } from "variables/helpPage";
import { AssetType } from "models/Asset";
import { EVENT_UNIT_MODES, GRADING_MODES } from "models/EventUnit";
import { createElement } from "react";

export const InfoSection = ({ challenge, event, onSwitchDisplayInfo, displayInfo, unitType, isPreview }) => {
	const intl = useIntl();
	const classes = useStyles();

	const helpUrl = useSelector((state) => state.branding.helpUrl);

	return (
		<CollapseIconCard
			icon={InfoIcon}
			title={intl.fm("challenge.properties")}
			helpLink={fetchHelp(helpUrl, "student", "challengeProperties")}
			onSwitchDisplayInfo={onSwitchDisplayInfo}
			displayInfo={displayInfo}
			content={
				<InfoSectionTable
					challenge={challenge}
					event={event}
					unitType={unitType}
					isPreview={isPreview}
					classes={classes}
					intl={intl}
				/>
			}
			sumaryContent={
				<InfoSectionSumary
					challenge={challenge}
					unitType={unitType}
					isPreview={isPreview}
					classes={classes}
					intl={intl}
				/>
			}
		/>
	);
};

const categories = challenge => (
	<div>
		<CategoryIcons categories={challenge.categories} tooltipPlacement="right" />
	</div>
);

const challengeLevel = (challenge, intl) => (
	<div>
		{challenge.level && challenge.level.name ? (
			<LevelLabel value={challenge.level.name} />
		) : (
			intl.fm("common.labels.unknown")
		)}
	</div>
);

const challengeType = (unit, classes) => {
	const grading = GRADING_MODES[unit.grading];
	return grading ? (
		<div className={classes.typeIcon}>
			{grading.isFlagBased && <Tooltip title={"Flag"} placement="right" classes={{ popper: classes.tooltip }}><FlagIcon /></Tooltip>}
			{grading.isWriteupBased && <Tooltip title={"Writeup"} placement="right" classes={{ popper: classes.tooltip }}><SubjectIcon /></Tooltip>}
		</div>
	) : null;
};

const challengeMode = (unit, intl, classes) => {
	const mode = EVENT_UNIT_MODES[unit.mode];
	return mode ? (
		<div className={classes.typeIcon}>
			<Tooltip title={intl.fm(mode.title)} placement="right" classes={{ popper: classes.tooltip }}>
				{createElement(mode.icon)}
			</Tooltip>
		</div>
	) : null;
};

const solutionStatus = (challenge, intl, classes) => {
	if (!challenge.solution) {
		return;
	}

	let solutionState = getSolutionOrQuizSolutionState(challenge.solution);

	return <div className={classes.solutionStatus}>
		<Badge color={solutionState?.color}>
			{intl.fm(solutionState?.title)}
		</Badge>
		<span className={classes.solutionPoints}>
			{printPercentage(challenge.solution.points, challenge.maxPoints)}
		</span>
	</div>;
}

const InfoSectionSumary = ({ challenge, unitType, isPreview, classes, intl }) => {
	return (
		<div className={classes.summaryBox}>
			{categories(challenge)}
			{(AssetType.CHALLENGE === unitType || AssetType.QUIZ === unitType) && challengeLevel(challenge, intl)}
			{AssetType.CHALLENGE === unitType && challengeType(challenge, classes)}
			{(AssetType.CHALLENGE === unitType || AssetType.QUIZ === unitType) && challengeMode(challenge, intl, classes)}
			{!isPreview && solutionStatus(challenge, intl, classes)}
		</div>
	);
};

const InfoSectionTable = ({ challenge, isPreview, unitType, classes, intl }) => {
	const tableRenderer = [
		{
			id: "title",
			renderCell: row => row.title
		},
		{
			id: "value",
			renderCell: row => row.value
		}
	];

	let tableData = [
		{
			title: intl.fm("event.table.categories"),
			value: categories(challenge)
		}
	];
	if (AssetType.CHALLENGE === unitType) {
		tableData = tableData.concat([
			{
				title: intl.fm("event.table.level"),
				value: challengeLevel(challenge, intl)
			},
			{
				title: intl.fm("event.table.grading"),
				value: challengeType(challenge, classes)
			},
			{
				title: intl.fm("event.table.mode"),
				value: challengeMode(challenge, intl, classes)
			}
		]);
		if (!isPreview && challenge.solution)
			tableData.push({
				title: intl.fm("solution.status"),
				value: solutionStatus(challenge, intl, classes)
			});
	} else if (AssetType.QUIZ === unitType) {
		tableData = tableData.concat([
			{
				title: intl.fm("event.table.level"),
				value: challengeLevel(challenge, intl)
			},
			{
				title: intl.fm("event.table.mode"),
				value: challengeMode(challenge, intl, classes)
			}
		]);
		if (!isPreview && challenge.solution)
			tableData.push({
				title: intl.fm("solution.status"),
				value: solutionStatus(challenge, intl, classes)
			});
	} else {
		// Nothing to add when none
	}

	return <Table tableRenderer={tableRenderer} tableData={tableData} hover />;
};

const useStyles = makeStyles((theme) => createStyles(
	({
		typeIcon: {
			color: grayColor,
			height: 24
		},
		tooltip: {
			fontSize: "1.2em",
			letterSpacing: ".05em"
		},
		summaryBox: {
			display: "flex",
			flexWrap: "wrap",
			alignItems: "center",
			justifyContent: "space-around"
		},
		solutionStatus: {
			display: "flex",
			alignItems: "center"
		},
		solutionPoints: { marginLeft: 10 }
	}))
);

import { isAnyResourceNotLoaded, RemoteResourceState, RemoteResourceStatus } from "@hlcr/core/api/RemoteResource";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchTenantAccessPolicy } from "actions/tenantAccessPolicy";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { RemoteResource } from "models/RemoteResource";
import { TenantAccessPolicy } from "models/TenantAccessPolicy";
import { UserProfile } from "models/User";
import { RootState } from "reducers";
import { fetchPersonalProfile, savePersonalProfile } from "userProfile/actions";
import { ProfileEditor } from "userProfile/components/ProfileEditor";


export const Profile = () => {
	const dispatch = useDispatch();

	const userProfileState = useSelector<RootState, RemoteResourceState<UserProfile>>((state: RootState) => state.remoteResourceReducer.remoteResources[RemoteResource.USER_PROFILE]);
	const tenantAccessPolicyState = useSelector<RootState, RemoteResourceState<TenantAccessPolicy>>(state => state.remoteResourceReducer.remoteResources[RemoteResource.TENANT_ACCESS_POLICY]);
	
	useEffect(() => {
		if (userProfileState.status === RemoteResourceStatus.INITIALIZED) {
			dispatch(fetchPersonalProfile());
		}
	}, [ userProfileState ]);

	useEffect(() => {
		if (tenantAccessPolicyState.status === RemoteResourceStatus.INITIALIZED) {
			dispatch(fetchTenantAccessPolicy());
		}
	}, [ tenantAccessPolicyState ]);

	if (isAnyResourceNotLoaded([ userProfileState.status, tenantAccessPolicyState.status ])){
		return <LoadingSpinner />;
	}

	const onSavePersonalProfile = (profile: UserProfile) => {
		dispatch(savePersonalProfile(profile));
	};

	return (
		<GridContainer>
			<ItemGrid xs={12} sm={12} md={12} lg={8}>
				<ProfileEditor
					noCard={false}
					userProfile={userProfileState.data}
					userProfileRequirements={tenantAccessPolicyState.data?.userProfileRequirements}
					onSave={onSavePersonalProfile}
				/>
			</ItemGrid>
		</GridContainer>
	);
};

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teacherApi from "../../actions/teacher";
import { filterInputStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamOverviewStyle";
import { withIntl } from "@hlcr/ui/Intl";
import { ResourceType } from "variables/constants";
import { ManagerVMs } from "../Manager/ManagerVMs";

class TeacherVMs extends ManagerVMs {
	componentDidMount() {
		const { challenge, getVms } = this.props;
		getVms(challenge.id);
	}
	render() {
		const { managerVms } = this.props;
		if (managerVms && managerVms.length > 0) {
			return super.render();
		} else return null;
	}
}

const mapStateToProps = (state, ownProps) => {
	const { challenge } = ownProps;
	const resources =
		challenge && challenge.resources
			? challenge.resources.filter(res => res.type === ResourceType.Vm)
			: [];
	return {
		resources,
		managerVms: state.api.resources.teacherVms.data.sort(
			(a, b) => a.displayName > b.displayName
		),
		isLoading: state.api.resources.teacherVms.pending
	};
};

const mapDispatchToProps = (dispatch, ownProps) =>
	bindActionCreators(
		{
			getVms: teacherApi.getVms,
			startVm: teacherApi.startVm(ownProps.challenge.id),
			stopVm: teacherApi.stopVm(ownProps.challenge.id),
			resetVm: teacherApi.resetVm(ownProps.challenge.id),
			revertVm: teacherApi.revertVm(ownProps.challenge.id)
		},
		dispatch
	);

export default compose(
	withIntl,
	withStyles(filterInputStyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(TeacherVMs);

import { HttpMethod } from "@hlcr/core/enum/HttpMethod";

import { RemoteResource } from "models/RemoteResource";
import { UserProfile } from "models/User";
import { DynamicRequestAction, RemoteResourceActionType } from "redux/actions";

const BASE_URL = "/api";

const userProfileBaseAction = {
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/user/profile`,
	resource: RemoteResource.USER_PROFILE,
};

export const fetchPersonalProfile: DynamicRequestAction = () => ({
	// ...userProfileBaseAction,
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/user/profile`,
	resource: RemoteResource.USER_PROFILE,
	method: HttpMethod.GET,
});

export const savePersonalProfile: DynamicRequestAction<UserProfile> = (payload) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/user/profile`,
	resource: RemoteResource.USER_PROFILE,
	method: HttpMethod.PUT,
	payload,
	successNotification: true,
});

import GenerateIcon from "@material-ui/icons/Cached";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import React from "react";

import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import CustomInput from "components/CustomInput/CustomInput";
import { createMemoize } from "helper/memoize";
import { EventAccessibility } from "variables/constants";
import { ShowHelp } from "views/Manager/ManagerTableCard";

const MAX_NEW_VOUCHERS = 200;

const getAccessTokenStats = createMemoize(accessTokens => {
	const staticTokens = {
			count: 0,
			unredeemed: 0,
			redeemed: 0
		},
		vouchers = {
			count: 0,
			unredeemed: 0,
			redeemed: 0
		};

	accessTokens.forEach(token => {
		const redeemed = token.redemptions || 0;
		const unredeemed = (token.maxRedemptions || 0) - redeemed;
		if (token.tokenType === EventAccessibility.STATIC_TOKEN) {
			staticTokens.count++;
			staticTokens.redeemed += redeemed;
			staticTokens.unredeemed += unredeemed;
		} else if (token.tokenType === EventAccessibility.VOUCHER) {
			vouchers.count++;
			vouchers.redeemed += redeemed;
			vouchers.unredeemed += unredeemed;
		}
	});

	return { staticTokens, vouchers };
});

export default class ManagerAccessTokens extends React.Component {
	state = {
		voucherAmount: 10,
		isLoading: false
	};

	componentDidMount() {
		const { fetchAccessTokens, eventId } = this.props;
		fetchAccessTokens(eventId);
	}

	render() {
		const { accessTokens, eventId, event, intl, classes } = this.props;
		const { voucherAmount, isLoading } = this.state;
		const { staticTokens, vouchers } = getAccessTokenStats(accessTokens);

		return (
			<IconCard
				faIcon="key"
				iconColor="purple"
				title={
					<div className={classes.titleBox}>
						<div>
							{intl.fm("manager.eventDetails.accessTokens.title")}
							<ShowHelp
								userRole="manager"
								action="manageAccessTokens"
								className={classes.helpIcon}
							/>
						</div>
						{event && event.accessibility !== EventAccessibility.OPEN && (
							<div className={classes.titleBoxButtons}>
								<a download href={`/api/events/${eventId}/accesstokens/export`}>
									<Button icon="download" size="sm" color="infoNoBackground">
										<DownloadIcon
											fontSize="small"
											style={{ marginRight: 10 }}
										/>
										All Access Tokens
									</Button>
								</a>
							</div>
						)}
					</div>
				}
				content={
					<>
						<table>
							<thead>
								<tr>
									<th />
									<th className={classes.tokenTableHeader}>Token Count</th>
									<th className={classes.tokenTableHeader}>
										Available Redemptions
									</th>
									<th className={classes.tokenTableHeader}>Redeemed</th>
								</tr>
							</thead>
							<tbody>
								<tr className={classes.tokenTableRow}>
									<th className={classes.tokenTableType}>Static Tokens</th>
									<td className={classes.tokenTableValue}>
										{staticTokens.count}
									</td>
									<td className={classes.tokenTableValue}>
										{staticTokens.unredeemed}
									</td>
									<td className={classes.tokenTableValue}>
										{staticTokens.redeemed}
									</td>
								</tr>
								<tr className={classes.tokenTableRow}>
									<th className={classes.tokenTableType}>Vouchers</th>
									<td className={classes.tokenTableValue}>{vouchers.count}</td>
									<td className={classes.tokenTableValue}>
										{vouchers.unredeemed}
									</td>
									<td className={classes.tokenTableValue}>
										{vouchers.redeemed}
									</td>
								</tr>
							</tbody>
						</table>
						{event && event.accessibility === EventAccessibility.VOUCHER && (
							<div className={classes.tokenActions}>
								<CustomInput
									labelText="New Vouchers"
									inputProps={{
										type: "number",
										value: voucherAmount,
										onChange: this.handleVoucherAmountChange
									}}
									classes={{ formControl: classes.voucherAmountInput }}
								/>
								<Button
									color="infoNoBackground"
									size="sm"
									onClick={this.generateVouchers}
									disabled={isLoading || !voucherAmount}
								>
									<GenerateIcon fontSize="small" style={{ marginRight: 10 }} />
									Generate {voucherAmount} New Vouchers
								</Button>
							</div>
						)}
					</>
				}
			/>
		);
	}

	handleVoucherAmountChange = event => {
		let value = event.currentTarget.value;
		if (value > MAX_NEW_VOUCHERS) value = MAX_NEW_VOUCHERS;
		if (value < 0) value = 0;
		this.setState({ voucherAmount: value });
	};

	generateVouchers = () => {
		const { generateAccessTokens, eventId, fetchAccessTokens } = this.props;
		const { voucherAmount } = this.state;
		this.setState({ isLoading: true });
		generateAccessTokens(eventId, voucherAmount, () => {
			fetchAccessTokens(eventId, () => this.setState({ isLoading: false }));
		});
	};
}

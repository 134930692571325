import { withIntl } from "@hlcr/ui/Intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

import eventUnitStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/eventUnitStyle";
import { ImageContentCard } from "components/Cards/ImageContentCard";
import RegularCard from "components/Cards/RegularCard";
import ExamInfo from "components/Exam/ExamInfo";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import NoData from "components/NoData/NoData";
import { getShowInfoSetting, setShowInfoSetting } from "helper/eventUnit";
import PropTypes from "prop-types";
import React from "react";
import { withCookies } from "react-cookie";
import { compose } from "redux";
import { AssetType } from "models/Asset";

import DescriptionContent from "./DescriptionContent";
import { InfoSection } from "./InfoSection";

class Theory extends React.Component {
	constructor(props) {
		super(props);
		this.state = { displayInfo: getShowInfoSetting(props.cookies) };
	}

	onSwitchDisplayInfo = () => {
		const { cookies } = this.props;
		const { displayInfo } = this.state;
		setShowInfoSetting(cookies, !displayInfo);
		this.setState({ displayInfo: !displayInfo });
	};

	componentDidMount() {
		const { fetchTheory, theoryId } = this.props;
		fetchTheory(theoryId, true);
	}

	render() {
		const { theory, event, isPreview, isLoading, intl, classes } = this.props;
		const { displayInfo } = this.state;

		if (isLoading && !theory) return <LoadingSpinner />;

		return theory ? (
			<div>
				<div className={classes.rigthField}>
					{event && <ExamInfo event={event} isPreview={isPreview} showButton />}
				</div>
				<div>
					<h3 className={classes.title}>
						{theory.title}
						{isPreview && ` (${intl.fm("event.preview")})`}
						{isLoading && (<CircularProgress style={{ marginLeft: 10 }} size={24} />)}
					</h3>
				</div>
				<GridContainer>
					<ItemGrid xs={12} sm={12} md={12} lg={8}>
						<ImageContentCard
							entity={theory}
							assetType={AssetType.THEORY}
							onSwitchDisplayInfo={this.onSwitchDisplayInfo}
							displayInfo={displayInfo}
						/>
					</ItemGrid>
					<ItemGrid xs={12} sm={8} md={6} lg={4}>
						<InfoSection
							challenge={theory}
							event={event}
							unitType={AssetType.THEORY}
							onSwitchDisplayInfo={this.onSwitchDisplayInfo}
							displayInfo={displayInfo}
						/>
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						<RegularCard content={<DescriptionContent entity={theory} intl={intl} />} />
					</ItemGrid>
				</GridContainer>
			</div>
		) : (
			<NoData />
		);
	}
}

Theory.propTypes = {
	classes: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	cookies: PropTypes.object.isRequired,
	theoryId: PropTypes.number.isRequired,
	theory: PropTypes.object,
	event: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
	fetchTheory: PropTypes.func.isRequired,
};

export default compose(withStyles(eventUnitStyle), withIntl, withCookies)(Theory);

import { IconButton, TextField, Theme, createStyles, makeStyles } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { GridColDef, GridRowData } from "@mui/x-data-grid";
import { useState } from "react";


const escapeRegExp = (value: string): string => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

interface GridToolbarQuickSearchProps {
	rows: GridRowData[],
	columns: GridColDef[],
	setFilteredRows: (rows: GridRowData[]) => void,
}
export const GridToolbarQuickSearch = ({ rows, columns, setFilteredRows }: GridToolbarQuickSearchProps) => {
	const classes = useStyles();
	const [ searchText, setSearchText ] = useState("");

	const requestSearch = (searchValue: string) => {
		setSearchText(searchValue);
		const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
		const filteredRows = rows.filter((row) => {
			return Object.keys(row).some((field) => {
				return columns.find((col) => col.field === field) && searchRegex.test(row[field].toString());
			});
		});
		setFilteredRows(filteredRows);
	};
	const onChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value);
	const clearSearch = () => requestSearch("");

	return (
		<div className={classes.root}>
			<TextField
				variant="standard"
				value={searchText}
				onChange={onChangeSearchText}
				placeholder="Search…"
				className={classes.textField}
				InputProps={{
					startAdornment: <SearchIcon fontSize="small" />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{ visibility: searchText ? "visible" : "hidden" }}
							onClick={clearSearch}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
		</div>
	);
};

const useStyles = makeStyles(
	(theme: Theme) =>
		createStyles({
			root: {
				padding: theme.spacing(0.5, 0.5, 0),
				justifyContent: "space-between",
				display: "flex",
				alignItems: "flex-start",
				flexWrap: "wrap",
			},
			textField: {
				[theme.breakpoints.down("xs")]: { width: "100%" },
				margin: theme.spacing(1, 0.5, 1.5),
				"& .MuiSvgIcon-root": { marginRight: theme.spacing(0.5) },
				"& .MuiInput-underline:before": { borderBottom: `1px solid ${theme.palette.divider}` },
			},
		}),
);


// export default function QuickFilteringGrid() {
// 	const { data } = useDemoData({
// 		dataSet: "Commodity",
// 		rowLength: 100,
// 		maxColumns: 6,
// 	});
// 	const [ searchText, setSearchText ] = React.useState("");
// 	const [ rows, setRows ] = React.useState<any[]>(data.rows);

// 	const requestSearch = (searchValue: string) => {
// 		setSearchText(searchValue);
// 		const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
// 		const filteredRows = data.rows.filter((row: any) => {
// 			return Object.keys(row).some((field: any) => {
// 				return searchRegex.test(row[field].toString());
// 			});
// 		});
// 		setRows(filteredRows);
// 	};

// 	React.useEffect(() => {
// 		setRows(data.rows);
// 	}, [ data.rows ]);

// 	return (
// 		<div style={{ height: 400, width: "100%" }}>
// 			<DataGrid
// 				components={{ Toolbar: QuickSearchToolbar }}
// 				rows={rows}
// 				columns={data.columns}
// 				componentsProps={{
// 					toolbar: {
// 						value: searchText,
// 						onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
// 							requestSearch(event.target.value),
// 						clearSearch: () => requestSearch(""),
// 					},
// 				}}
// 			/>
// 		</div>
// 	);
// }

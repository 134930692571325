import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import moment from "moment";
import PropTypes from "prop-types";
import React, { createElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getEmail } from "auth/authUtils";
import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import TableIconButton from "components/CustomButtons/TableIconButton";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import Tooltip from "components/CustomTooltip/CustomTooltip";
import ExamInfo from "components/Exam/ExamInfo";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import LevelLabel from "components/LevelLabel/LevelLabel";
import EnhancedTable from "components/Table/EnhancedTable";
import { CategoryIcons } from "helper/categories";
import { getEventUnitImageSrc, getLowQualityEventUnitImageSrc } from "helper/eventUnit";
import { getRankingLink } from "helper/links";
import { printPercentage } from "helper/pointPrint";

import ProposalModal from "../Teacher/ProposalModal";
import { ASSET_TYPES, AssetType } from "models/Asset";
import { EVENT_UNIT_MODES, GRADING_MODES } from "models/EventUnit";
import { getSolutionSuccessStateBySolution, QuizSolutionState, SolutionSuccessState } from "models/SolutionState";
import { Menu, MenuItem } from "@material-ui/core";
import { BulkGradingModal } from "grading/BulkGrading/BulkGradingModal";
import { BulkFeedbackModal } from "grading/BulkFeedback/BulkFeedbackModal";
import { formatFullDateWithShortYear } from "helper/dateCalc";

class UnitList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showSolved: this.getStoredShowSolved(),
			gradingMenuAnchor: null,
			currentSelectedUnit: null,
			isBulkGradingOpen: false,
			isBulkFeedbackOpen: false,
		};
	}

	render() {
		const { showSolved } = this.state;
		const {
			eventId,
			event,
			classes,
			isLoading,
			isPreview,
			intl,
			isModalOpen,
			closeModal,
			selectedUnit,
			onSubmitProposal,
		} = this.props;

		return (
			<div>
				<GridContainer>
					<ItemGrid xs={12}>
						<IconCard
							icon={AssignmentIcon}
							iconColor="purple"
							title={
								<div className={classes.titleCardContainer}>
									<span>
										{event.name} {isPreview && `(${intl.fm("event.preview")}) `}
										{isLoading && <CircularProgress size={18} />}
									</span>
									<div className={classes.filterField}>
										<ExamInfo event={event} isPreview={isPreview} showButton />
										<div className={classes.cardActions}>
											{event.ranked && !event.exam && (
												<Link to={getRankingLink(event)}>
													<Button
														color="infoNoBackground"
														customClass={classes.filterButton}
													>
														<FormatListNumberedIcon
															className={classes.filterButtonIcon}
														/>
													</Button>
												</Link>
											)}
											<InputLabel className={classes.showSolvedLabel}>
												<div>{intl.fm("event.table.showSolved")}</div>
												<CustomSwitch
													checked={showSolved}
													onChange={this.switchShowSolved}
												/>
											</InputLabel>
										</div>
									</div>
								</div>
							}
							content={
								<EnhancedTable
									isLoading={isLoading}
									tableData={this.getFilteredUnits()}
									tableRenderer={this.getTableRenderer()}
									persistSortFor={`UnitList::${eventId}`}
									hover
									disablePS
								/>
							}
						/>
					</ItemGrid>
				</GridContainer>
				<ProposalModal
					isModalOpen={isModalOpen}
					closeModal={closeModal}
					intl={intl}
					selectedUnit={selectedUnit}
					onSubmit={onSubmitProposal}
					minLength={5}
					email={getEmail()}
				/>
				{this.state.isBulkGradingOpen && <BulkGradingModal
					unit={this.state.currentSelectedUnit}
					onClose={() => {
						this.setState({ isBulkGradingOpen: false });
					}}
				/>}
				{this.state.isBulkFeedbackOpen && <BulkFeedbackModal
					unit={this.state.currentSelectedUnit}
					onClose={() => {
						this.setState({ isBulkFeedbackOpen: false });
					}}
				/>}
				{isPreview && this.gradingAndFeedbackMenu(intl)}
			</div>
		);
	}

	gradingAndFeedbackMenu = (intl) =>
		<Menu
			id="grading-menu"
			anchorEl={this.state.gradingMenuAnchor}
			open={Boolean(this.state.gradingMenuAnchor)}
			onClose={this.handleGradingMenuClose}
		>
			{
				this.shouldDisplaySingleAndBulkGrading() &&
				<>
					<MenuItem onClick={() => this.openSingleGrading(this.state.currentSelectedUnit)}>{intl.fm("teacher.grading.single")}</MenuItem>
					<MenuItem onClick={() => this.openBulkGrading(this.state.currentSelectedUnit)}>{intl.fm("teacher.grading.bulk")}</MenuItem>
				</>
			}
			<MenuItem onClick={() => this.openBulkFeedback(this.state.currentSelectedUnit)}>{intl.fm("teacher.feedback.bulk")}</MenuItem>
		</Menu>

	shouldDisplaySingleAndBulkGrading = () =>
		this.state.currentSelectedUnit
		&& ( this.state.currentSelectedUnit.participantCountThatNeedGrading > 0
		     || GRADING_MODES[this.state.currentSelectedUnit.grading] === GRADING_MODES.MANUAL
		);

	getShowSolvedStorageKey = () => `UnitList::${this.props.eventId}::showSolved`;

	getFilteredUnits = () => {
		const { showSolved } = this.state;
		const { units } = this.props;

		// if user wants to see all units return early
		if (showSolved) return units;

		// filter out solved challenges
		return units.filter(unit => {
			if (!unit) {
				return false;
			}

			if (unit.type === AssetType.CHALLENGE) {
				const challengeSolution = unit.solution;
				return challengeSolution === undefined || getSolutionSuccessStateBySolution(challengeSolution) !== SolutionSuccessState.COMPLETED;
			} else if (unit.type === AssetType.QUIZ) {
				const quizSolution = unit.quizSolution;
				return quizSolution === undefined || quizSolution.state !== QuizSolutionState.FULL_POINTS
			}

			return true;
		});
	};

	switchShowSolved = () =>
		this.setState(({ showSolved }) => {
			this.storeShowSolved(!showSolved);
			return { showSolved: !showSolved };
		});

	getStoredShowSolved = () => {
		const showSolvedString = localStorage.getItem(
			this.getShowSolvedStorageKey(),
		);
		if (showSolvedString === null) return true;
		return !!Number(showSolvedString);
	};

	storeShowSolved = showSolved =>
		localStorage.setItem(this.getShowSolvedStorageKey(), showSolved ? 1 : 0);

	handleGradingMenuClose = () => {
		this.setState({ gradingMenuAnchor: null });
	};

	openGrading(event, unit) {
		// if grading mode is MANUAL, we can directly jump to bulk grading, and do not need to show the menu
		if (GRADING_MODES[unit.grading] === GRADING_MODES.MANUAL) {
			this.openBulkGrading(unit);
		} else {
			this.setState({ currentSelectedUnit: unit });
			this.setState({ gradingMenuAnchor: event.currentTarget });
		}
	}

	openBulkFeedback(unit) {
		this.setState({ isBulkFeedbackOpen: true, currentSelectedUnit: unit, gradingMenuAnchor: null });
	}

	openBulkGrading(unit) {
		this.setState({ isBulkGradingOpen: true, currentSelectedUnit: unit, gradingMenuAnchor: null });
	}

	openSingleGrading(unit) {
		// we close the menu first
		this.setState({ gradingMenuAnchor: null });
		if (unit) {
			// we cannot access the history as declare variable here (window.history is a reserved keyword in js), so we use the way over this.props.history
			// noinspection JSUnresolvedVariable
			this.props.history.push(`/teacher/events/${unit.eventId}/solutions/${unit.nextSolutionToCorrect}`);
		}
	}

	getTableRenderer = () => {
		const { parentId, classes, isPreview, intl, openModal } = this.props;

		const previewColumns = isPreview
			? [
				{
					id: "modalLink",
					renderCell: unit => (
						<div>
							<TableIconButton
								onClick={openModal(unit)}
								title={intl.fm("teacher.proposal.action")}
								color="info"
								faIcon="lightbulb"
							/>
						</div>
					),
				},
				{
					id: "gradingLink",
					renderCell: unit => {
						// we only show the possibility to grade if it's set to MANUAL, or it is a mode which has a WRITEUP
						if (GRADING_MODES[unit.grading] === GRADING_MODES.MANUAL || GRADING_MODES[unit.grading]?.isWriteupBased) {
							const gradingDisabled = false
							return <Tooltip disableHoverListener={!gradingDisabled} title={intl.fm("event.table.grading.tooltip")}>
								<div>
									<TableIconButton
										disabled={gradingDisabled}
										onClick={(ev) => this.openGrading(ev, unit)}
										title={intl.fm("teacher.grading.action")}
										color={gradingDisabled ? "gray" : "info"}
										faIcon="pencil-alt"
									/>
								</div>
							</Tooltip>;
						}
					},
				},
			]
			: [
				{
					id: "points",
					title: intl.fm("event.table.points"),
					align: "right",
					sort: (a, b) => {
						const pointsA = getUnitPoints(a);
						const pointsB = getUnitPoints(b);
						const percentageA = pointsA / a.maxPoints || 0;
						const percentageB = pointsB / b.maxPoints || 0;
						return percentageB - percentageA;
					},
					renderCell: unit => {
						const points = getUnitPoints(unit);
						const percentage = printPercentage(points, unit.maxPoints);
						return (
							<>
								<div>{percentage}</div>
								<div className={classes.unitPoints}>{`${Math.round(
									points * 10,
								) / 10}/${unit.maxPoints}`}</div>
							</>
						);
					},
				},
			];

		const submittedSolutionColumn = isPreview ? [
			{
				id: "solutions",
				title: intl.fm("event.table.solutions"),
				hideBelowWidth: 600,
				renderCell: unit => {
					return unit.participantCountInTotal > 0 && (
						<Tooltip title={intl.fm("event.table.solutions.tooltip")}>
							<div className={classes.solvingProgress}>
								<span>{unit.participantCountWithSubmittedSolution} / {unit.participantCountOpenedEventUnit} / {unit.participantCountInTotal}</span>
								<LinearProgress
									classes={{
										root: classes.solvingProgressBar,
										buffer: classes.solvingProgressBarBuffer,
										dashedColorSecondary: classes.solvingProgressBarDashed,
									}}
									color="secondary"
									variant="buffer"
									value={Math.min(unit.participantCountWithSubmittedSolution / unit.participantCountInTotal * 100, 100)}
									valueBuffer={Math.min(unit.participantCountOpenedEventUnit / unit.participantCountInTotal * 100, 100)}
								/>
							</div>
						</Tooltip>
					);
				},
			},
		] : [];

		return [
			{
				id: "sortOrder",
				title: intl.fm("event.table.sortOrder"),
				sort: (a, b) => b.sortOrder - a.sortOrder,
				renderCell: (unit) => <div className={classes.sortOrder}>{unit.sortOrder + 1}</div>,
			},
			{
				id: "unitLink",
				hideBelowWidth: 1000,
				renderCell: (unit) => (
					<Link to={getUnitLink(unit, parentId, isPreview)}>
						<TableIcon title={intl.fm("asset.type." + unit.type.toLowerCase())} icon={createElement(ASSET_TYPES[unit.type].icon)} classes={classes} />
					</Link>
				),
			},
			{
				id: "image",
				hideBelowWidth: 400,
				renderCell: (unit) => (
					<Link to={getUnitLink(unit, parentId, isPreview)}>
						<Paper elevation={2} square className={classes.thumbPaper}>
							<OptimizedImage unit={unit} classes={classes} />
						</Paper>
					</Link>
				),
			},
			{
				id: "name",
				title: intl.fm("event.table.name"),
				sort: (a, b) => (a.title < b.title ? 1 : -1),
				renderCell: (unit) => (
					<Link className={classes.eventTitle} to={getUnitLink(unit, parentId, isPreview)}>
						<div className={classes.horizontal}>
							{unit.endTime && moment(unit.endTime).isBefore() && <ExpiredLabel classes={classes} intl={intl} />}
							{unit.title}
						</div>
						<FormattedStartEndTimeComponent unit={unit} classes={classes}/>
						<div className={classes.uuid}>{unit.uuid}</div>
					</Link>
				),
			},
			...submittedSolutionColumn,
			{
				id: "categories",
				title: intl.fm("event.table.categories"),
				hideBelowWidth: 600,
				renderCell: (unit) => <CategoryIcons categories={unit.categories} />,
			},
			{
				id: "level",
				title: intl.fm("event.table.level"),
				hideBelowWidth: 500,
				sort: (a, b) => {
					if (!a.level && b.level) return 1;
					if (a.level && !b.level) return -1;
					return a.level.id === b.level.id ? 0 : a.level.id < b.level.id ? 1 : -1;
				},
				renderCell: (unit) => (unit.level ? <LevelLabel value={unit.level.name} /> : null),
			},
			{
				id: "mode",
				title: intl.fm("event.table.mode"),
				hideBelowWidth: 800,
				renderCell: (unit) => {
					if (AssetType.THEORY === unit.type) {
						return null;
					}
					const mode = EVENT_UNIT_MODES[unit.mode];
					return mode ? <TableIcon title={intl.fm(mode.title)} icon={createElement(mode.icon)} classes={classes} /> : null;
				},
			},
			{
				id: "grading",
				title: intl.fm("event.table.grading"),
				hideBelowWidth: 700,
				renderCell: (unit) => {
					if (AssetType.CHALLENGE !== unit.type) {
						return null;
					}
					const grading = GRADING_MODES[unit.grading];
					return grading ? grading.icons.map((icon) => <TableIcon title={intl.fm(grading.title)} icon={createElement(icon)} classes={classes} />) : null;
				},
			},
			...previewColumns,
		];
	};
}

const ExpiredLabel = ({ intl, classes }) => (
	<div className={classes.expiredLabel}>{intl.fm("common.labels.expired")}</div>
);

const getUnitLink = (unit, parentId, isPreview) => {
	const basePath = parentId
		? `/events/${parentId}/curriculumevents/${unit.eventId}`
		: `/events/${unit.eventId}`;

	if (!isPreview) {
		switch (unit.type) {
			case AssetType.THEORY:
				return `${basePath}/theory/${unit.id}`;
			case AssetType.CHALLENGE:
				return `${basePath}/challenges/${unit.id}`;
			case AssetType.QUIZ:
				return `${basePath}/quiz/${unit.id}`;
			default:
				return "";
		}
	}
	switch (unit.type) {
		case AssetType.THEORY:
			return `/teacher/events/${unit.eventId}/units/${unit.id}/theory`;
		case AssetType.CHALLENGE:
			return `/teacher/events/${unit.eventId}/units/${unit.id}/challenge`;
		case AssetType.QUIZ:
			return `/teacher/events/${unit.eventId}/units/${unit.id}/quiz`;
		default:
			return "";
	}
};

const TableIcon = ({ icon, title, classes }) => (
	<span className={classes.typeIcon}>
		<Tooltip title={title} placement="right">
			<div className={classes.tableIconContainer}>{icon}</div>
		</Tooltip>
	</span>
);

const FormattedTime =  ({ time }) => {
	return time ? <>{formatFullDateWithShortYear(time)}</> : <>N/A</>;
};

const FormattedStartEndTimeComponent = ({ unit, classes }) => {
	if (unit.startTime || unit.endTime) {
		return (
			<div className={classes.startEndTime}>
				<FormattedTime time={unit.startTime} />
				{' - '}
				<FormattedTime time={unit.endTime} />
			</div>
		);
	}
	return <></>;
};



const getUnitPoints = unit => unit.solution ? unit.solution.points : unit.quizSolution ? unit.quizSolution.points : 0;

const OptimizedImage = ({ unit, classes }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [src, setSrc] = useState(getLowQualityEventUnitImageSrc(unit.type, unit.id));

	useEffect(() => {
		const img = new Image();
		img.src = getEventUnitImageSrc(unit.type, unit.id);
		img.onload = () => {
			setSrc(img.src);
			setIsLoaded(true);
		};
		img.onerror = () => {
			setSrc("/No_image_3x4.svg");
			setIsLoaded(true);
		};
	}, [unit.type, unit.id]);

	return (
		<img
			src={src}
			className={`${classes.thumb} ${!isLoaded ? classes.blurred : ''}`}
			alt={unit.title}
		/>
	);
};

UnitList.propTypes = {
	parentId: PropTypes.number,
	eventId: PropTypes.number.isRequired,
	event: PropTypes.object,
	units: PropTypes.arrayOf(PropTypes.object).isRequired,
	isPreview: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	openModal: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	selectedUnit: PropTypes.object,
	onSubmitProposal: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
};

export default UnitList;

import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import progressEventStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/progressEventStyle";
import Tooltip from "components/CustomTooltip/CustomTooltip";

const Progress = ({ progressHelper, classes }) => {
	const percentage = progressHelper.getSolvePercentage();
	const solutionSuccessStateEnum = progressHelper.getSuccessSolutionStateEnum();

	return (
		<div className={classes.eventContainer}>
			<Tooltip title={
				progressHelper.getTooltipContent()
			} placement="top">
				{progressHelper.getClickable(
					<div className={classes.event}
					     style={{ backgroundColor: solutionSuccessStateEnum.colorCss }}>
						{ /* Showing the progress only makes sense, if the solution is improvable */
							solutionSuccessStateEnum.showProgress && <div
								                                      className={classes.progress}
								                                      style={{
									                                      width: `${100 - percentage}%`,
									                                      backgroundColor: solutionSuccessStateEnum.colorCss,
									                                      filter: "brightness(140%)",
								                                      }}
							                                      />}
					</div>,
				)}
			</Tooltip>
		</div>
	);
};

Progress.propTypes = {
	progressHelper: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
};

export default withStyles(progressEventStyle)(Progress);

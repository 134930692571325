import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React from "react";

const tooltipClasses = {
	popper: { pointerEvents: "none" },
	tooltip: {
		fontSize: 12,
		letterSpacing: 1,
		maxWidth: "calc(100vw - 60px)",
	}
};

const CustomTooltip = React.forwardRef(({ children, ...rest }, ref) => {
	return rest.title ? <Tooltip ref={ref} {...rest} >{children}</Tooltip> : children;
});

CustomTooltip.propTypes = {
	children: PropTypes.element.isRequired,
	classes: PropTypes.object.isRequired,
	/**
	 * @ignore
	 */
	className: PropTypes.string,
	disableFocusListener: PropTypes.bool,
	disableHoverListener: PropTypes.bool,
	disableTouchListener: PropTypes.bool,
	enterDelay: PropTypes.number,
	enterTouchDelay: PropTypes.number,
	id: PropTypes.string,
	leaveDelay: PropTypes.number,
	leaveTouchDelay: PropTypes.number,
	/**
	 * Callback fired when the tooltip requests to be closed.
	 *
	 * @param {object} event The event source of the callback
	 */
	onClose: PropTypes.func,
	/**
	 * Callback fired when the tooltip requests to be open.
	 *
	 * @param {object} event The event source of the callback
	 */
	onOpen: PropTypes.func,
	open: PropTypes.bool,
	placement: PropTypes.oneOf([
		"bottom-end",
		"bottom-start",
		"bottom",
		"left-end",
		"left-start",
		"left",
		"right-end",
		"right-start",
		"right",
		"top-end",
		"top-start",
		"top"
	]),
	title: PropTypes.node
};
export default withStyles(tooltipClasses)(CustomTooltip);

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import reportApi from "../../actions/report";
import eventApi from "../../actions/events";
import eventUnitStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/eventUnitStyle";
import { withIntl } from "@hlcr/ui/Intl";
import { TeacherQuizRaw } from "../Teacher/TeacherQuiz";

const mapStateToProps = (state, ownProps) => {
	const quizSolutionId = Number(ownProps.match.params.solutionId);

	const quizSolution = state.api.resources.reportQuizSolutions.data.find(
		qs => qs.id === quizSolutionId
	);

	return {
		quizSolutionId,
		quizSolution,
		quizClosed: true,
		disabled: true,
		gradingInstructions: state.api.resources.reportQuizGradingInstructions.data.filter(
			gi => gi.quizSolutionId === quizSolutionId
		),
		comments: state.api.resources.reportQuizComments.data.filter(
			gi => gi.quizSolutionId === quizSolutionId
		),
		quizAnswers: state.api.resources.reportQuizAnswers.data.filter(
			qa => qa.quizSolutionId === quizSolutionId
		),
		isLoading:
			state.api.resources.reportQuizSolutions.pending ||
			state.api.resources.reportQuizGradingInstructions.pending ||
			state.api.resources.reportQuizAnswers.pending
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchTeacherEvent: eventApi.fetchEvent,
			fetchTeacherQuizSolution: reportApi.fetchQuizSolution,
			fetchTeacherQuizAnswers: reportApi.fetchQuizAnswers,
			fetchTeacherQuizGradingInstructions: reportApi.fetchQuizGradingInstructions,
			fetchTeacherQuizComments: reportApi.fetchQuizComments,
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(eventUnitStyle),
	withIntl
)(TeacherQuizRaw);

import { INPUT_REQUIREMENT_TYPES, InputRequirementType } from "@hlcr/mui/Input/InputRequirementType";
import { useIntl } from "@hlcr/ui/Intl";
import { FormControl, Input, InputLabel, MenuProps, Select } from "@material-ui/core";
import * as React from "react";
import { ReactElement } from "react";

export type Data<K, IndexName extends string = "id", DisplayName extends string = "name"> = {
	[I in IndexName]: K;
} & {
	[D in DisplayName]: string;
};
export type InputProps<K> = {
	value: K[];
	onChange: (event: any) => void;
};

export interface MultiSelectProps<K, T extends Data<K, I, D>, I extends string, D extends string> {
	label: string;
	data: T[];
	required?: InputRequirementType;
	inputProps: InputProps<K>;
	indexName?: I;
	displayName?: D;
	menuItems?: ReactElement[];
}

export const BasicMultiSelect = <K, T extends Data<K, I, D>, I extends string = "id", D extends string = "name">({
	label,
	data,
	inputProps,
	required = InputRequirementType.OPTIONAL,
	indexName = "id" as I,
	displayName = "name" as D,
	menuItems,
}: MultiSelectProps<K, T, I, D> & { menuItems?: ReactElement[] }) => {
	const intl = useIntl();

	return (
		<FormControl>
			<InputLabel htmlFor="event-selector">{intl.fm(INPUT_REQUIREMENT_TYPES[required]?.labelIntlId, label, { label })}</InputLabel>
			<Select
				id="event-selector"
				multiple={true}
				disabled={!(data?.length > 0)}
				required={InputRequirementType.REQUIRED == required}
				input={<Input />}
				renderValue={(selected) =>
					data
						.filter((e) => (selected as K[]).includes(e[indexName]))
						.map((e) => e[displayName])
						.join(", ")
				}
				MenuProps={HackingLabMenuProps}
				{...inputProps}
			>
				{menuItems}
			</Select>
		</FormControl>
	);
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const HackingLabMenuProps: Partial<MenuProps> = {
	variant: "menu",
	getContentAnchorEl: null,
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export const eventInitState = {
	events: {
		pending: false,
		data: []
	},
	eventJoin: {
		pending: false,
		data: []
	},
	eventUnits: {
		pending: false,
		data: []
	},
	challenges: {
		pending: false,
		data: []
	},
	steps: {
		pending: false,
		data: []
	},
	solutionComments: {
		pending: false,
		data: []
	},
	theories: {
		pending: false,
		data: []
	},
	quizzes: {
		pending: false,
		data: []
	},
	questionGradingInstructions: {
		pending: false,
		data: []
	},
	quizAnswers: {
		pending: false,
		data: []
	},
	quizComments: {
		pending: false,
		data: []
	}
};

import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import { EventReport } from "views/Report/EventReport";

import reportApi from "../../actions/report";
import reportStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/reportStyle";
import { getCurrentUsername } from "auth/authUtils";
import { withIntl } from "@hlcr/ui/Intl";

const SETTINGS_KEY = "OwnUserReport";

class OwnUserReport extends React.Component {
	componentDidMount() {
		const {
			fetchOwnUserChallengesReport,
			fetchOwnUserQuizReport,
			fetchOwnUserTheoriesReport
		} = this.props;

		fetchOwnUserChallengesReport();
		fetchOwnUserQuizReport();
		fetchOwnUserTheoriesReport();
	}

	render() {
		const { challengeReports, username, intl, classes, routeTo } = this.props;

		return (
			<EventReport
				isOwnReport
				settingsKey={SETTINGS_KEY}
				challengeReports={challengeReports}
				username={username}
				routeTo={routeTo}
				intl={intl}
				classes={classes}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const routeTo = ownProps.history.push;

	const {
		reportChallenges,
		reportQuizzes,
		reportTheories
	} = state.api.resources;

	const allreports = [
		...reportChallenges.data,
		...reportQuizzes.data,
		...reportTheories.data
	];

	const username = getCurrentUsername();
	const challengeReports = allreports.filter(
		challengeReport => challengeReport.username === username
	);

	return {
		challengeReports,
		username,
		routeTo
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchOwnUserChallengesReport: reportApi.fetchOwnUserChallengesReport,
			fetchOwnUserQuizReport: reportApi.fetchOwnUserQuizReport,
			fetchOwnUserTheoriesReport: reportApi.fetchOwnUserTheoriesReport
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(reportStyle),
	withIntl
)(OwnUserReport);

import { EnumBase, EnumDetail, EnumWithIcon } from "@hlcr/app/enum/EnumBase";
import { Sortable } from "@hlcr/app/model/Sortable";
import { primaryColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core/SvgIcon/SvgIcon";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import FlagIcon from "@material-ui/icons/Flag";
import SubjectIcon from "@material-ui/icons/Subject";
import TuneIcon from "@material-ui/icons/Tune";

import { createMemoize } from "helper/memoize";
import { eventSortOrderSorter } from "helper/sorting";

import { AssetDifficultyLevelThatShouldBeAnEnum, AssetType, FlagType } from "./Asset";

export interface EventUnit extends Sortable {
	id: number;
	uuid: string;
	title: string;
	type: AssetType;
	eventId: number;
	trainingMode: boolean;
	endTime: string;
	categories: EventUnitCategory[];
	hidden?: boolean;
	startTime: string;
}

export interface SolvableEventUnit extends EventUnit {
	maxPoints: number;
	mode: EventUnitMode;
	level: AssetDifficultyLevelThatShouldBeAnEnum;
	stepTitlesPenalty: number;
	fullPenaltyMaxGrade: number;
}

export interface EventUnitChallenge extends SolvableEventUnit {
	grading: GradingMode;
	goldNuggetType: FlagType;
	flagWeight: number;
	sections?: any[];
}

export type EventUnitChallengeId = EventUnitChallenge["id"];

export interface EventUnitTheory extends EventUnit {

}

export interface EventUnitQuiz extends SolvableEventUnit {
}

export type AnyEventUnit = EventUnitChallenge | EventUnitTheory | EventUnitQuiz;

export interface EventUnitCategory {
	name: string;
}


// GradingMode
export enum GradingMode {
	WRITEUP = "WRITEUP",
	WRITEUP_FLAG = "WRITEUP_FLAG",
	FLAG = "FLAG",
	MANUAL = "MANUAL",
	CRITERIA = "CRITERIA",
}

export function isCriteriaGradingMode(gradingMode?: string): boolean {
	return  gradingMode === GradingMode.CRITERIA;
}

interface GradingModeEnum extends EnumBase {
	isFlagBased: boolean;
	isWriteupBased: boolean;
	icons: Array<OverridableComponent<SvgIconTypeMap>>;
}

export const GRADING_MODES: EnumDetail<GradingMode, GradingModeEnum> = {
	WRITEUP: {
		isFlagBased: false,
		isWriteupBased: true,
		title: "grading.mode.writeup",
		icons: [ SubjectIcon ],
	},
	WRITEUP_FLAG: {
		isFlagBased: true,
		isWriteupBased: true,
		title: "grading.mode.writeup_flag",
		icons: [ FlagIcon, SubjectIcon ],
	},
	FLAG: {
		isFlagBased: true,
		isWriteupBased: false,
		title: "grading.mode.flag",
		icons: [ FlagIcon ],
	},
	MANUAL: {
		isFlagBased: false,
		isWriteupBased: false,
		title: "grading.mode.manual",
		icons: [ TuneIcon ],
	},
	CRITERIA: {
		isFlagBased: false,
		isWriteupBased: true,
		title: "grading.mode.criteria",
		icons: [ TuneIcon ],
	},
};


// EventUnitMode
export enum EventUnitMode {
	COMPETITION_MODE = "COMPETITION_MODE",
	TRAINING_MODE = "TRAINING_MODE",
	STEPS_MODE = "STEPS_MODE",
}

export interface EventUnitModeEnum extends EnumWithIcon {
	hasSteps: boolean;
	forChallenge: boolean;
	forQuiz: boolean;
}

export const EVENT_UNIT_MODES: EnumDetail<EventUnitMode, EventUnitModeEnum> = {
	COMPETITION_MODE: {
		hasSteps: false,
		forChallenge: true,
		forQuiz: true,
		title: "unit.mode.competition_mode",
		icon: AssignmentLateIcon,
		iconColor: primaryColor,
	},
	TRAINING_MODE: {
		hasSteps: true,
		forChallenge: true,
		forQuiz: true,
		title: "unit.mode.training_mode",
		icon: FitnessCenterIcon,
		iconColor: primaryColor,
	},
	STEPS_MODE: {
		hasSteps: true,
		forChallenge: true,
		forQuiz: false,
		title: "unit.mode.steps_mode",
		icon: AssignmentIcon,
		iconColor: primaryColor,
	},
};


export const filterEventUnits = createMemoize((eventUnits: EventUnit[], eventId: number | number[]) => {
	if (Array.isArray(eventId)) {
		return eventUnits.filter(u => eventId.includes(u.eventId)).sort(eventSortOrderSorter);
	} else {
		return eventUnits.filter(u => u.eventId === eventId).sort(eventSortOrderSorter);
	}
});

import { primaryColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { createStyles, makeStyles } from "@material-ui/core";


export const eventUnitModalStyles = createStyles({
	toggleButtonWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		marginBottom: "16px",
	},
	toggleButtonRoot: {
		"&$toggleButtonSelected, &$toggleButtonSelected:focus, &$toggleButtonSelected:hover": {
			backgroundColor: primaryColor,
			color: "#FFFFFF",
		},
	},
	toggleButtonSelected: {},
	gridItem: {
		paddingLeft: "16px",
		paddingRight: "16px",
	},
	columnWrapper: {
		display: "flex",
		flexDirection: "column",
		width: "50%",
	},
	flexRowWrapper: {
		display: "flex",
		flexDirection: "row",
	},
	flexRowText: { display: "flex" },
	flexRowGrow: {
		display: "inline-flex",
		flexGrow: 1,
	},
	inputRoot: {
		display: "flex",
		flexGrow: 1,
		marginRight: "1em",
		maxWidth: "250px",
		"&:last-child": { marginRight: "inherit" },
	},
	dynamicScoringText: {
		fontSize: "0.75rem",
		marginBottom: "20px",
		marginTop: "-10px",
		color: "#9e9e9e",
	},
});
export const useStyles = makeStyles(eventUnitModalStyles);

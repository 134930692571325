// ##############################
// // // InfoCard styles
// #############################

import { blueCardHeader, card, cardActions, cardHeader, defaultFont, grayColor, greenCardHeader, orangeCardHeader, purpleCardHeader, redCardHeader } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";

const infoCardStyle = {
	card,
	orangeCardHeader,
	greenCardHeader,
	redCardHeader,
	blueCardHeader,
	purpleCardHeader,
	cardHeader: {
		...cardHeader,
		float: "left",
		textAlign: "center"
	},
	cardContent: {
		textAlign: "right",
		paddingTop: "10px",
		padding: "15px 20px"
	},
	cardIcon: {
		paddingTop: "3px",
		paddingLeft: "4px",
		paddingRight: "5px",
		color: "#FFFFFF",
		width: "33px",
		height: "27px"
	},
	cardAvatar: { margin: "0px" },
	cardCategory: {
		marginBottom: "0",
		color: grayColor,
		margin: "0 0 10px",
		...defaultFont
	},
	cardTitle: {
		margin: "0",
		...defaultFont,
		fontSize: "1.825em"
	},
	cardTitleSmall: {
		fontSize: "65%",
		fontWeight: "400",
		lineHeight: "1",
		color: "#777"
	},
	cardActions: {
		...cardActions,
		padding: "10px 0 0 0!important"
	},
	cardStats: {
		width: "100%",
		lineHeight: "22px",
		color: grayColor,
		fontSize: "12px",
		display: "inline-block",
		margin: "0!important"
	}
};

export default infoCardStyle;

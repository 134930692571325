import { RemoteResourceState } from "@hlcr/core/api/RemoteResource";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchCriteriaGradingCatalog } from "components/Solution/criteriaGradingCatalog.action";
import { CriteriaGradingCatalogDto } from "components/Solution/CriteriaGradingCatalogDto";
import { RemoteResource } from "models/RemoteResource";
import { RootState } from "reducers";
import { clearRemoteResource } from "redux/actions";

export const useCriteriaGradingCatalog = (solutionId: number, isCriteriaGradingMode: boolean, isTeacher: boolean) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearRemoteResource(RemoteResource.GRADING_CRITERIA_CATALOG));
		if (isCriteriaGradingMode && isTeacher) {
			dispatch(fetchCriteriaGradingCatalog({ solutionId: solutionId }));
		}
	}, [ isCriteriaGradingMode, solutionId, isTeacher ]);

	const criteriaGradingCatalog = useSelector<RootState, RemoteResourceState<CriteriaGradingCatalogDto>>((state) => {
		return state.remoteResourceReducer.remoteResources[RemoteResource.GRADING_CRITERIA_CATALOG];
	});

	return criteriaGradingCatalog?.data;
};

import { HttpMethod } from "@hlcr/core/enum/HttpMethod";
import { Dispatch } from "react";

import { RemoteResource } from "models/RemoteResource";
import { TenantAccessPolicy } from "models/TenantAccessPolicy";
import { DynamicRequestAction, RemoteResourceActionType } from "redux/actions";
import { TenantAdminConfigurationEmail } from "tenantAdmin/model/TenantAdminConfiguration";


const BASE_URL = "/api/tenant/admin";

export const fetchTenantAdminEmailConfiguration: DynamicRequestAction = () => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	method: HttpMethod.GET,
	url: `${BASE_URL}/email/`,
	resource: RemoteResource.TENANT_ADMIN_CONFIGURATION_EMAIL,
});

export const updateTenantAdminEmailConfiguration: DynamicRequestAction<TenantAdminConfigurationEmail> = (payload, onSuccess, onFailure) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	method: HttpMethod.PUT,
	url: `${BASE_URL}/email/${payload!.id}`,
	resource: RemoteResource.TENANT_ADMIN_CONFIGURATION_EMAIL,
	payload,
	onSuccess,
	onFailure,
});

export const createTenantAdminEmailConfiguration: DynamicRequestAction<TenantAdminConfigurationEmail> = (payload, onSuccess, onFailure) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	method: HttpMethod.POST,
	url: `${BASE_URL}/email/`,
	resource: RemoteResource.TENANT_ADMIN_CONFIGURATION_EMAIL,
	payload,
	onSuccess,
	onFailure,
});


export const deleteTenantEmailConfiguration: DynamicRequestAction<TenantAdminConfigurationEmail> = (payload, onSuccess, onFailure) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	method: HttpMethod.DELETE,
	url: `${BASE_URL}/email/${payload!.id}`,
	resource: RemoteResource.TENANT_ADMIN_CONFIGURATION_EMAIL,
	payload,
	onSuccess,
	onFailure,
});

interface TestTenantEmailConfigurationPayload {
	email: string;
}

export const testTenantEmailConfiguration = (id: string, payload: TestTenantEmailConfigurationPayload, onSuccess?: (dispatch: Dispatch<any>) => void, onFailure?: (dispatch: Dispatch<any>) => void) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	method: HttpMethod.POST,
	url: `${BASE_URL}/email/${id}/test`,
	successNotification: "Email sent successfully",
	payload,
	onSuccess,
	onFailure,
});

export const updateTenantAdminAccessPolicyConfiguration: DynamicRequestAction<TenantAccessPolicy> = (payload, onSuccess, onFailure) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	method: HttpMethod.PUT,
	url: `${BASE_URL}/accessPolicy/`,
	resource: RemoteResource.TENANT_ACCESS_POLICY,
	payload,
	onSuccess,
	onFailure,
});


export const fetchTenantAdminConfiguration: DynamicRequestAction = () => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	method: HttpMethod.GET,
	url: `${BASE_URL}/config/`,
	resource: RemoteResource.TENANT_ADMIN_CONFIGURATION,
});

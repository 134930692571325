import { percentage } from "@hlcr/core/numeric";
import { WithIntl, withIntl } from "@hlcr/ui/Intl";
import { InputAdornment } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import ValidationInput from "components/CustomInput/ValidationInput";
import { AssetType } from "models/Asset";
import { EVENT_UNIT_MODES, EventUnitMode, EventUnitModeEnum } from "models/EventUnit";

import { useStyles } from "./eventUnitModalStyle";
import { UpdateEventUnitModeFn, UpdateFullPenaltyMaxGradeFn, UpdateStepsPenaltyFn } from "./eventUnitModalTypes";

const MIN_PERCENTAGE = 0;
const MAX_PERCENTAGE = 1;

interface EventUnitModeSectionProps extends WithIntl {
	unitType: AssetType;
	unitMode: EventUnitMode;
	stepTitlesPenalty: number;
	fullPenaltyMaxGrade: number;
	handleUnitModeChange: UpdateEventUnitModeFn;
	handleStepsPenaltyChange: UpdateStepsPenaltyFn;
	handleFullPenaltyMaxGradeChange: UpdateFullPenaltyMaxGradeFn;
}

export const EventUnitModeSection = withIntl((props: EventUnitModeSectionProps) => {
	const { unitType, unitMode, handleUnitModeChange, intl } = props;

	let content = null;
	let buttonFilter;

	switch (unitType) {
		case AssetType.QUIZ:
			buttonFilter = ([ key, mode ]: [string, EventUnitModeEnum]) => mode.forQuiz;
			break;
		case AssetType.CHALLENGE:
			buttonFilter = ([ key, mode ]: [string, EventUnitModeEnum]) => mode.forChallenge;
			content = <ChallengeContent {...props} />;
			break;
		default:
			return null;
	}

	return (<>
		<h3>{intl.fm("manager.eventDetails.units.table.mode")}</h3>
		<ToggleButtons buttonFilter={buttonFilter} unitMode={unitMode} handleUnitModeChange={handleUnitModeChange} intl={intl} />
		{content}
	</>);
});

interface ToggleButtonsProps extends WithIntl {
	unitMode: EventUnitMode;
	buttonFilter: ([ key, value ]: [string, EventUnitModeEnum]) => boolean;
	handleUnitModeChange: UpdateEventUnitModeFn;
}

const ToggleButtons = ({ unitMode, buttonFilter, handleUnitModeChange, intl }: ToggleButtonsProps) => {
	const classes = useStyles();
	return (
		<div className={classes.toggleButtonWrapper}>
			<ToggleButtonGroup value={unitMode} onChange={handleUnitModeChange} exclusive={true}>
				{Object.entries(EVENT_UNIT_MODES).filter(buttonFilter).map(([ key, eventUnitMode ]) =>
					<ToggleButton
						classes={{ root: classes.toggleButtonRoot, selected: classes.toggleButtonSelected }}
						key={key}
						value={key}
					>
						{intl.fm(eventUnitMode.title)}
					</ToggleButton>,
				)}
			</ToggleButtonGroup>
		</div>
	);
};

interface ChallengeContentProps extends WithIntl {
	unitMode: EventUnitMode;
	stepTitlesPenalty: number;
	fullPenaltyMaxGrade: number;
	handleUnitModeChange: UpdateEventUnitModeFn;
	handleStepsPenaltyChange: UpdateStepsPenaltyFn;
	handleFullPenaltyMaxGradeChange: UpdateFullPenaltyMaxGradeFn;
}

const ChallengeContent = ({
	                          unitMode,
	                          stepTitlesPenalty,
	                          fullPenaltyMaxGrade,
	                          handleUnitModeChange,
	                          handleStepsPenaltyChange,
	                          handleFullPenaltyMaxGradeChange,
	                          intl,
}: ChallengeContentProps) => {
	const classes = useStyles();
	return (<>
		<div className={classes.flexRowWrapper}>
			{unitMode === EventUnitMode.STEPS_MODE && (
				<div className={classes.columnWrapper}>
					<ValidationInput
						value={percentage(stepTitlesPenalty)}
						onChange={handleStepsPenaltyChange}
						label={intl.fm("manager.eventDetails.units.table.initialStepsPenalty")}
						inputProps={{
							min: percentage(MIN_PERCENTAGE),
							max: percentage(MAX_PERCENTAGE),
							endAdornment: <InputAdornment position="end">%</InputAdornment>,
						}}
						validations={{ verifyNumber: true }}
						formControlProps={{ className: classes.inputRoot }}
					/>
				</div>
			)}
			{unitMode !== EventUnitMode.COMPETITION_MODE && (
				<div className={classes.columnWrapper}>
					<FullPenaltyMaximumGrade unitMode={unitMode} fullPenaltyMaxGrade={fullPenaltyMaxGrade} intl={intl}
					                         handleFullPenaltyMaxGradeChange={handleFullPenaltyMaxGradeChange} />
				</div>
			)}
		</div>
		{unitMode === EventUnitMode.STEPS_MODE && (
			<StepsModeDescriptionText fullPenaltyMaxGrade={fullPenaltyMaxGrade} stepTitlesPenalty={stepTitlesPenalty} intl={intl} />
		)}
	</>);
};

interface StepsModeDescriptionTextProps extends WithIntl {
	fullPenaltyMaxGrade: number;
	stepTitlesPenalty: number;
}

const StepsModeDescriptionText = ({ fullPenaltyMaxGrade, stepTitlesPenalty, intl }: StepsModeDescriptionTextProps) => {
	const classes = useStyles();
	return (
		<div className={classes.flexRowWrapper}>
			<div className={classes.columnWrapper}>
				<span className={classes.flexRowText}>{intl.fm("manager.eventDetails.units.grading.maxGradeWithAllTitlesShown")}</span>
				<span className={classes.flexRowText}>{intl.fm("manager.eventDetails.units.grading.gradePenaltyPerStep")}</span>
				<span className={classes.flexRowText}>{intl.fm("manager.eventDetails.units.grading.maxGradeIfAllStepsViewed")}</span>
			</div>
			<div className={classes.columnWrapper}>
				<span className={classes.flexRowGrow}>{percentage(1.0 - stepTitlesPenalty)}%</span>
				<span className={classes.flexRowGrow}>
					{intl.fm(
						"manager.eventDetails.units.grading.gradePenaltyPerStepFormula",
						undefined,
						{ maxGrade: percentage(1.0 - stepTitlesPenalty), fullPenaltyMaxGrade: percentage(fullPenaltyMaxGrade) },
					)}
				</span>
				<span className={classes.flexRowGrow}>{percentage(fullPenaltyMaxGrade)}%</span>
			</div>
		</div>
	);
};

interface FullPenaltyMaxGradeProps extends WithIntl {
	unitMode: EventUnitMode,
	fullPenaltyMaxGrade: number;
	handleFullPenaltyMaxGradeChange: (value: number) => void;
}

const FullPenaltyMaximumGrade = ({ unitMode, fullPenaltyMaxGrade, intl, handleFullPenaltyMaxGradeChange }: FullPenaltyMaxGradeProps) => {
	const label = unitMode === EventUnitMode.STEPS_MODE
		? "manager.eventDetails.units.table.fullPenaltyMaximumGrade"
		: "manager.eventDetails.units.table.maximumGrade"
	;
	const classes = useStyles();
	return (
		<ValidationInput
			value={percentage(fullPenaltyMaxGrade)}
			onChange={handleFullPenaltyMaxGradeChange}
			label={intl.fm(label)}
			inputProps={{
				min: percentage(MIN_PERCENTAGE),
				max: percentage(MAX_PERCENTAGE),
				endAdornment: <InputAdornment position="end">%</InputAdornment>,
			}}
			validations={{ verifyNumber: true }}
			formControlProps={{ className: classes.inputRoot }}
		/>
	);
};

// ##############################
// // // RegularCard styles
// #############################

import { card, defaultFont } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";

const regularCardStyle = {
	card,
	cardStats: { width: "100%" },
	cardPlain: {
		background: "transparent",
		boxShadow: "none"
	},
	cardHeader: {
		padding: "15px 20px 0",
		zIndex: "3"
	},
	cardTitle: {
		...defaultFont,
		textDecoration: "none",
		marginTop: "0",
		marginBottom: "3px",
		fontSize: "1.3em",
		"& small": {
			fontWeight: "400",
			lineHeight: "1",
		}
	},
	right: { textAlign: "right" },
	left: { textAlign: "left" },
	center: { textAlign: "center" },
	cardSubtitle: {
		...defaultFont,
		fontSize: "14px",
		margin: "0 0 10px"
	},
	cardContent: {
		padding: "15px 20px !important",
		position: "relative"
	}
};

export default regularCardStyle;

import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { createRef } from "react";

import customSelectStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/customSelectStyle";

const CustomMultiSelect = ({ classes, formControlClassName, additionalClasses, label, values, displayField, displayRenderer, fullWidth, disabled, formControlProps, selection, onSelect, idField }) => {
	const menuRef = createRef();

	const handleSelect = (event) => {
		const selected = event.target.value;
		onSelect(selected);
	};

	const renderDisplay = value => {
		if (value && displayField) {
			return (
				<>
					<Checkbox checked={selection.indexOf(value[idField]) > -1} />
					<ListItemText primary={value[displayField]} />
				</>
			);
		}
		if (value && displayRenderer){
			return displayRenderer(value);
		}
		return "unknown";
	};

	return (
		<FormControl fullWidth={fullWidth} className={cx(classes.selectFormControl, formControlClassName)} {...formControlProps}>
			{label && (<InputLabel htmlFor="multi-select" className={classes.selectLabel}>{label}</InputLabel>)}
			<Select
				classes={{ ...additionalClasses, select: cx(classes.select, additionalClasses ? additionalClasses.select : null) }}
				disabled={disabled}
				id="multi-select"
				multiple
				value={selection}
				onChange={handleSelect}
				input={<Input />}
				renderValue={(selection) => selection.join(", ")}
				MenuProps={{
					className: classes.selectMenu,
					ref: menuRef
				}}
			>
				{values.map((value, key) => (
					<MenuItem key={key} value={value[idField]} classes={{ root: cx(classes.selectMenuItem, classes.multiSelectMenuItem) }}>
						{renderDisplay(value)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

CustomMultiSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	additionalClasses: PropTypes.object,
	values: PropTypes.arrayOf(PropTypes.object).isRequired,
	selection: PropTypes.arrayOf(PropTypes.string).isRequired,
	onSelect: PropTypes.func.isRequired,
	displayField: PropTypes.string,
	displayRenderer: PropTypes.func,
	idField: PropTypes.string.isRequired,
	formControlClassName: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	formControlProps: PropTypes.object,
};
export default withStyles(customSelectStyle)(CustomMultiSelect);

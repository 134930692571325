import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GroupIcon from "@material-ui/icons/Group";
import ViewIcon from "@material-ui/icons/Visibility";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { createSelector } from "reselect";

import managerApi from "actions/manager";
import { actionStyles, filterInputStyles, floatingActionButtonStyles, tableSpecialHighligtingStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamOverviewStyle";
import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import IconButton from "components/CustomButtons/IconButton";
import CustomInput from "components/CustomInput/CustomInput";
import WarningDialog from "components/ModalWindow/WarningDialog";
import NoData from "components/NoData/NoData";
import EnhancedTable from "components/Table/EnhancedTable";
import applyFilter from "helper/applyFilter";
import { withIntl } from "@hlcr/ui/Intl";

import ManagerCreateClassModal from "../ManagerCreateClassModal";

const enhanceActions = compose(withStyles(actionStyles));

const Actions = enhanceActions(({ classes, managerClass, removeClass }) => {
	const disabledDelete = managerClass.teamCount > 0 || managerClass.archived;
	const classDelete = disabledDelete ? classes.buttonDisabled : classes.button;

	return (
		<React.Fragment>
			<Link to={`/manager/classes/${managerClass.id}`}>
				<Button color="info" customClass={classes.button}>
					{managerClass.archived ? (<ViewIcon className={classes.icon} />) : (<EditIcon className={classes.icon} />)}
				</Button>
			</Link>
			{!managerClass.archived && (
				<Button onClick={() => removeClass(managerClass)} disabled={disabledDelete} color="danger" customClass={classDelete}>
					<DeleteIcon className={classes.icon} />
				</Button>
			)}
		</React.Fragment>
	);
});

const createTableRenderer = (removeClass, intl) => [
	{
		id: "name",
		title: intl.fm("manager.classes.name"),
		renderCell: managerClass => managerClass.name + (managerClass.archived ? ` (${intl.fm("common.labels.archived")})` : ""),
		sort: true
	},
	{
		id: "description",
		title: intl.fm("manager.classes.description"),
		renderCell: managerClass => managerClass.description,
		sort: true
	},
	{
		id: "teamCount",
		title: intl.fm("manager.classes.teamCount"),
		renderCell: managerClass => managerClass.teamCount,
		sort: true,
		align: "right"
	},
	{
		id: "actions",
		title: intl.fm("manager.classes.actions"),
		renderCell: managerClass => <Actions managerClass={managerClass} removeClass={removeClass} />
	}
];

const enhanceTable = compose(withStyles(tableSpecialHighligtingStyles));
const ClassesTable = enhanceTable(({ classes, managerClasses, removeClass, intl }) => (
	<EnhancedTable
		classes={classes}
		tableRenderer={createTableRenderer(removeClass, intl)}
		tableData={managerClasses}
		disabledRow={clazz => clazz.archived}
		hover
		emptyContent={<NoData text={intl.fm("manager.classes.noResources")} />}
	/>
)
);

ClassesTable.defaultProps = { managerClasses: [] };

const ClassesFilterInput = ({ onFilterInputChange, placeholder, formControlClassName }) => (
	<CustomInput inputProps={{ type: "inputSearch", onChange: onFilterInputChange, placeholder }} formControlProps={{ className: formControlClassName }} />
);

const CreateClassButton = withStyles(floatingActionButtonStyles)(
	({ onCreateClass, intl, classes }) => (
		<IconButton onClick={onCreateClass} color="primary" aria-label={intl.fm("common.labels.add")} customClass={classes.button}>
			<AddIcon className={classes.iconPosition} />
		</IconButton>
	)
);

class ManagerClasses extends React.Component {
	state = {
		filterQuery: "",
		showCreateDialog: false,
		deleteOpen: false,
		deleteClass: undefined
	};

	componentDidMount() {
		this.props.fetchManagerClasses();
		this.props.fetchManagerTeams();
	}

	onCancelClassCreation = () => {
		this.setState({ showCreateDialog: false });
	};

	onCreateClass = () => {
		this.setState({ showCreateDialog: true });
	};

	onFilterInputChange = event => {
		const { filterQuery } = this.state;
		const { value } = event.currentTarget;

		if (filterQuery !== value) {
			this.setState({ filterQuery: value });
		}
	};

	cancelDelete = () => {
		this.setState({ deleteOpen: false });
	};

	confirmDelete = deleteClass => {
		this.setState({ deleteOpen: true, deleteClass });
	};

	delete = () => {
		const { removeClass } = this.props;
		const { deleteClass } = this.state;
		this.setState({ deleteOpen: false, deleteClass: undefined });
		if (deleteClass) removeClass(deleteClass, true);
	};

	render() {
		const { managerClasses, classes, intl } = this.props;
		const { filterQuery, showCreateDialog } = this.state;

		const filteredClasses = applyFilter(managerClasses, filterQuery, [ "name", "description", "teamCount" ]);
		return (
			<div>
				<IconCard
					icon={GroupIcon}
					iconColor="purple"
					title={
						<div>
							{intl.fm("manager.classes.title")}
							<ClassesFilterInput onFilterInputChange={this.onFilterInputChange} placeholder={intl.fm("common.labels.filter")} formControlClassName={classes.formControl} />
						</div>
					}
					content={<ClassesTable managerClasses={filteredClasses} removeClass={this.confirmDelete} intl={intl} />}
				/>
				<CreateClassButton intl={intl} onCreateClass={this.onCreateClass} />
				<ManagerCreateClassModal
					open={showCreateDialog}
					onClose={this.onCancelClassCreation}
				/>
				<WarningDialog
					onConfirm={this.delete}
					onClose={this.cancelDelete}
					open={this.state.deleteOpen}
					entityName={intl.fm("class.entityName")}
				/>
			</div>
		);
	}
}

const enrichWithMemberInfos = (classes, teams) => {
	return classes.map(managerClass => {
		const classTeams = teams.filter(team => team.classId === managerClass.id);
		const teamCount = classTeams.length;

		return {
			...managerClass,
			teamCount
		};
	});
};

const classesSelector = createSelector(
	state => state.api.resources.managerClasses.data,
	state => state.api.resources.managerTeams.data,
	enrichWithMemberInfos
);

const mapStateToProps = state => {
	return {
		managerClasses: classesSelector(state),
		isLoading: state.api.resources.managerClasses.pending,
		match: undefined,
		location: undefined
	};
};

const mapDispatchToProps = {
	fetchManagerClasses: managerApi.fetchManagerClasses,
	fetchManagerTeams: managerApi.fetchManagerTeams,
	removeClass: managerApi.removeClass
};

export default compose(
	withIntl,
	withStyles(filterInputStyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(ManagerClasses);

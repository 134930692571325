export const EventJoinType = {
	TEAM_JOIN: "TEAM_JOIN",
	USER_JOIN: "USER_JOIN",
};

export const EventVisibility = {
	PUBLIC: "PUBLIC",
	PRIVATE: "PRIVATE",
};

export const UserEventState = {
	RUNNING: "RUNNING",
	UPCOMING: "UPCOMING",
	PAST: "PAST",
	ARCHIVED: "ARCHIVED",
};

export const UserEventStateSelectList = [
	{
		id: UserEventState.RUNNING,
		text: "Running Events",
	},
	{
		id: UserEventState.UPCOMING,
		text: "Upcoming Events",
	},
	{
		id: UserEventState.PAST,
		text: "Past Events",
	},
	{
		id: UserEventState.ARCHIVED,
		text: "Archived Events",
	},
];

export const VisibilitySelectList = [
	{
		id: EventVisibility.PUBLIC,
		text: "Public",
	},
	{
		id: EventVisibility.PRIVATE,
		text: "Private",
	},
];

export const EventAccessibility = {
	OPEN: "OPEN",
	STATIC_TOKEN: "STATIC_TOKEN",
	VOUCHER: "VOUCHER",
};

export const AccessibilitySelectList = [
	{
		id: EventAccessibility.OPEN,
		text: "Open",
	},
	{
		id: EventAccessibility.STATIC_TOKEN,
		text: "Static Token",
	},
	{
		id: EventAccessibility.VOUCHER,
		text: "Voucher",
	},
];

export const ResourceType = {
	Docker: "DOCKER",
	File: "FILE",
	Vm: "VM",
};

export const ResourceState = {
	STARTED: "STARTED",
	STOPPED: "STOPPED",
};

export const EventState = {
	PENDENT: "PENDENT",
	RUNNING: "RUNNING",
	FINISHED: "FINISHED",
	UNKNOWN: "UNKNOWN",
};

export const ResourceStateRequest = {
	START: "START",
	STOP: "STOP",
};

export const ChallengeState = {
	OK: "OK",
	DEVELOPING: "DEVELOPING",
	NEW: "NEW",
	BUGGY: "BUGGY",
	DECOMMISIONED: "DECOMMISSIONED",
};

import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import DarkModeIcon from "@material-ui/icons/BrightnessMedium";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import classNames from "classnames";
import cx from "classnames";
import { bool, func, object } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import { toggleDarkMode } from "actions/ui";
import headerLinksStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/headerLinksStyle";
import { doLogout } from "auth/authUtils";

const HeaderLinks = ({ classes, toggleDarkMode, isFixed, toggleFixedLayout }) => (
	<Hidden smDown>
		<Button onClick={toggleFixedLayout} color="inherit" className={cx(classes.buttonLink, classes.fluidLayoutIcon)}>
			{isFixed ? <FullscreenIcon className={classNames(classes.links, classes.leftIcon)} /> : <FullscreenExitIcon className={classNames(classes.links, classes.leftIcon)} />}
		</Button>
		<Button onClick={toggleDarkMode} color="inherit" className={classes.buttonLink}>
			<DarkModeIcon className={classNames(classes.links, classes.leftIcon)} />
		</Button>
		<Button onClick={doLogout} color="inherit" className={classes.buttonLink}>
			<ExitToAppIcon className={classNames(classes.links, classes.leftIcon)} />
			Logout
		</Button>
	</Hidden>
);

HeaderLinks.propTypes = {
	classes: object.isRequired,
	toggleDarkMode: func.isRequired,
	isFixed: bool,
	toggleFixedLayout: func
};

const mapDispatchToProps = dispatch =>
	bindActionCreators({ toggleDarkMode: toggleDarkMode }, dispatch);

export default compose(
	withStyles(headerLinksStyle),
	connect(
		null,
		mapDispatchToProps
	)
)(HeaderLinks);

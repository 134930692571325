import { EnumDetail } from "@hlcr/app/enum/EnumBase";
import { Dto } from "@hlcr/core/api/RemoteResource";
import { WithIntl } from "@hlcr/ui/Intl";

import { formatFullDate } from "helper/dateCalc";
import { createMemoize } from "helper/memoize";
import { EventType } from "models/EventType";
import { EventAccessibility, EventVisibility } from "variables/constants";

export enum ScoringMode {
	STATIC = "STATIC",
	DYNAMIC = "DYNAMIC",
}

export const SCORING_MODE: EnumDetail<ScoringMode> = {
	[ScoringMode.STATIC]: { title: "Static" },
	[ScoringMode.DYNAMIC]: { title: "Dynamic" },
};

/**
 * export const ScoringModeSelectList = [
 * 	{
 * 		id: ScoringMode.DYNAMIC,
 * 		text: "Dynamic",
 * 	},
 * 	{
 * 		id: ScoringMode.STATIC,
 * 		text: "Static",
 * 	},
 * ]
 */

export interface BaseEvent extends Dto {
	id: number;
	name: string;
	type: keyof typeof EventType;
	description: string;
	startTime: Date;
	endTime: Date;
	freezeTimeBeforeEndInMinutes?: number;
	accessibility: keyof typeof EventAccessibility;
	visibility: keyof typeof EventVisibility;
	exam: boolean;
	ranked: boolean;
	rankedShowNoPointsUsers :boolean;
	scoringMode :ScoringMode;
	dynamicScoringMaximumPoints: number;
	dynamicScoringMinimumPoints: number;
	dynamicScoringDecayFactor: number;
}

export function formatDateRange(event: BaseEvent, intl: WithIntl["intl"], parentEvent?: BaseEvent) {
	const startTime = event.startTime ?? parentEvent?.startTime;
	const endTime = event.endTime ?? parentEvent?.endTime;

	if (startTime) {
		if (endTime) {
			return intl.fm("manager.eventOverview.date.startAndEnd", undefined, { startDate: formatFullDate(startTime), endDate: formatFullDate(endTime) });
		}
		return intl.fm("manager.eventOverview.date.startOnly", undefined, { startDate: formatFullDate(startTime) });
	}

	if (endTime) {
		return intl.fm("manager.eventOverview.date.endOnly", undefined, { endDate: formatFullDate(endTime) });
	}
	return intl.fm("manager.eventOverview.date.open");
}

export const getEvent = createMemoize((events: BaseEvent[], eventId: number) => {
	return events.find(event => event.id === eventId);
});

import { BasicMultiSelect, Data, MultiSelectProps } from "@hlcr/mui/Input/BasicMultiSelect";
import { InputRequirementType } from "@hlcr/mui/Input/InputRequirementType";
import { useIntl } from "@hlcr/ui/Intl";
import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";


// TODO: check that default property value of index and displayName match the explicitly provided type of I and D
export const MultiSelectWithSelectAll = <K, T extends Data<K, I, D>, I extends string = "id", D extends string = "name">(
	{ label, data, inputProps, required = InputRequirementType.OPTIONAL, indexName = "id" as I, displayName = "name" as D }: MultiSelectProps<K, T, I, D>,
) => {
	const intl = useIntl();
	const classes = useStyles();

	const onSelectChange = (events: any) => {
		// the select all options has been clicked if the value contains -1
		if (events.target.value.find((value :K) => typeof(value) === "number" && value === -1)) {
			if (inputProps.value.length >= data.length) {
				// already all are selected, we deselect all
				events.target.value = [];
			} else {
				// not all are selected, we select all
				events.target.value = data.map((item) => item[indexName]);
			}
		}

		// we call the default handle option for value changes afterwards
		inputProps.onChange(events);
	};

	const selectAll =
		<MenuItem key={-1} value={-1} style={{ borderBottom: "1px solid lightgrey" }}>
			<Checkbox checked={data.length === inputProps.value?.length} />
			<ListItemText primary={"---  " + intl.fm("common.labels.selectAll") + "  ---"} />
		</MenuItem>;

	const items = data.map((d) => (
		<MenuItem key={d[indexName]} value={d[indexName]}>
			<Checkbox checked={inputProps.value.indexOf(d[indexName]) > -1} />
			<ListItemText
				primary={d[displayName]}
				primaryTypographyProps={{ className: classes.listItem }} />
		</MenuItem>
	));

	const menuItems = data.length > 0 ? [ selectAll, ...items ] : items;
	const basicMultiSelectInputProps = { value: inputProps.value, onChange: onSelectChange };

	return <BasicMultiSelect
		label={label}
		data={data}
		inputProps={basicMultiSelectInputProps}
		required={required}
		indexName={indexName}
		displayName={displayName}
		menuItems={menuItems}
	/>;
};

const useStyles = makeStyles({ listItem: { whiteSpace: "normal" } });

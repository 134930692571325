import { PaletteColorKey } from "@hlcr/mui/theme/hacking-lab.theme";
import * as React from "react";


import { useBadgeStyles } from "./style";

interface BadgeProps {
	color?: PaletteColorKey;
}

export const Badge: React.FC<BadgeProps> = ({ color, children }) => {
	const classes = useBadgeStyles(color);
	return <span className={classes(color).badge}>{children}</span>;
};

import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import { useIntl } from "@hlcr/ui/Intl";
import { createStyles, Fab, makeStyles, Theme } from "@material-ui/core";
import { CreateOutlined } from "@material-ui/icons";
import * as React from "react";

import { ComposeModal } from "messaging/components/ComposeModal";


export interface MessageComposerProps {
	asRole: HackingLabRole;
}

export const MessageComposer = (props: MessageComposerProps) => {
	const intl = useIntl();
	const classes = useStyles();

	const [ modalOpen, setModalOpen ] = React.useState(false);

	const closeModal = () => setModalOpen(false);
	const openModal = () => setModalOpen(true);

	return (
		<>
			<Fab variant="extended" className={classes.fab} onClick={openModal}>
				<CreateOutlined />
				{intl.fm("messaging.inbox.composeNew")}
			</Fab>
			<ComposeModal open={modalOpen} onClose={closeModal} {...props} />
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		fab: {
			position: "absolute",
			bottom: theme.spacing(5),
			right: theme.spacing(2),
		},
	}),
);

import { withStyles } from "@material-ui/core/styles";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import PropTypes from "prop-types";
import React from "react";

import noDataStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/noDataStyle";

const NoData = ({ text = "no data", Icon = CloudOffIcon, classes }) => (
	<div className={classes.container}>
		<Icon className={classes.icon} />
		<div className={classes.text}>{text}</div>
	</div>
);

NoData.propTypes = {
	classes: PropTypes.object.isRequired,
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node 
	]),
	icon: PropTypes.func
};

export default withStyles(noDataStyle)(NoData);

/* eslint-disable */
if (!String.prototype.startsWith) {
	String.prototype.startsWith = function(search, pos) {
		return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search
	}
}

if (!Array.prototype.find) {
	Object.defineProperty(Array.prototype, 'find', {
		value(predicate, thisArg) {
			if (this == null) {
				throw new TypeError('"this" is null or not defined')
			}

			const o = Object(this)

			const len = o.length >>> 0

			if (typeof predicate !== 'function') {
				throw new TypeError('predicate must be a function')
			}

			for (let k = 0; k < len; k++) {
				const kValue = o[k]
				if (predicate.call(thisArg, kValue, k, o)) {
					return kValue
				}
			}

			return undefined
		},
		configurable: true,
		writable: true
	})
}

const isNaNNumber = num => typeof num === 'number' && isNaN(num)

if (!Array.prototype.includes) {
	Object.defineProperty(Array.prototype, 'includes', {
		value(searchElement, fromIndex) {
			if (this == null) {
				throw new TypeError('"this" is null or not defined')
			}

			const o = Object(this)

			const len = o.length >>> 0

			if (len === 0) {
				return false
			}

			const n = fromIndex | 0

			let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

			function sameValueZero(x, y) {
				return x === y || (isNaNNumber(x) && isNaNNumber(y))
			}

			while (k < len) {
				if (sameValueZero(o[k], searchElement)) {
					return true
				}
				k++
			}

			return false
		}
	})
}
/* eslint-enable */

import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teamsApi from "actions/teams";
import { Accordion } from "@hlcr/mui";
import { Button } from "@hlcr/mui/Button";
import ModalWindow from "components/ModalWindow/ModalWindow";
import Table from "components/Table/Table";
import { withIntl } from "@hlcr/ui/Intl";
import { fetchHelp } from "variables/helpPage";

const JoinTeamModal = ({ open, onClose, classes, intl, helpUrl, joinTeam, token, team }) => {
	const handleJoin = () => {
		joinTeam(token, onClose, onClose);
	};

	return open && team && (
		<ModalWindow
			open={open}
			onClose={onClose}
			title={intl.fm("team.titles.join")}
			helpLink={fetchHelp(helpUrl, "teams", "joinTeam")}
			actionSection={
				<div className={classes.actions}>
					<Button onClick={onClose} color="defaultNoBackground">
						{intl.fm("common.labels.cancel")}
					</Button>
					<Button onClick={handleJoin} color="infoNoBackground">
						{intl.fm("common.labels.join")}
					</Button>
				</div>
			}
			fullWidth
		>
			<div style={{ marginBottom: "20px" }}>
				<Table tableRenderer={[
					{ id: "title", renderCell: row => row.title },
					{ id: "value", renderCell: row => row.value }
				]} tableData={[
					{
						title: intl.fm("team.label.name"),
						value: team.name
					},
					{
						title: intl.fm("team.label.description"),
						value: team.description
					}
				]} hover />
			</div>

			<Accordion
				dense={true}
				sections={[
					{
						title: (<span>{intl.fm("team.label.membercount")}</span>),
						content: <Table tableRenderer={[
							{ id: "username", renderCell: member => member.user.username },
							{ id: "email", renderCell: member => member.user.email },
							{ id: "leader", renderCell: member => member.leader ? intl.fm("team.label.leader") : "" }
						]}
						tableData={team.members}
						hover={true} />
					}
				]} />
		</ModalWindow>
	);
};
export default compose(
	withStyles({
		actions: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between"
		}
	}),
	withIntl,
	connect(
		state => ({
			helpUrl: state.branding.helpUrl,
			team: state.api.resources.joinTeam.data.length > 0 ? state.api.resources.joinTeam.data[0] : undefined
		}),
		dispatch =>
			bindActionCreators({ joinTeam: teamsApi.joinTeam, }, dispatch)
	)
)(JoinTeamModal);

import { formatMessage } from "@hlcr/ui/Intl";
import { EventState } from "./constants";

export const EventStateDesc = (eventState, intl) => {
	const formatter = intl ? intl.fm : formatMessage;
	switch (eventState) {
		case EventState.PENDENT:
			return formatter("event.state.pendent");
		case EventState.RUNNING:
			return formatter("event.state.running");
		case EventState.FINISHED:
			return formatter("event.state.finished");
		default:
			return eventState;
	}
};

import { withStyles } from "@material-ui/core/styles";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import PropTypes from "prop-types";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators, compose } from "redux";

import managerApi from "actions/manager";
import addExistingEventModalStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/addExistingEventModalStyle";
import TableIconButton from "components/CustomButtons/TableIconButton";
import CustomInput from "components/CustomInput/CustomInput";
import ModalWindow from "components/ModalWindow/ModalWindow";
import NoData from "components/NoData/NoData";
import EnhancedTable from "components/Table/EnhancedTable";
import { withIntl } from "@hlcr/ui/Intl";
import { EventType } from "models/EventType";

class AddExistingEventModal extends React.Component {
	state = {
		query: "",
		events: []
	};

	focusRef = createRef();

	componentDidUpdate(prevProps) {
		if (!prevProps.isOpen && this.props.isOpen) {
			setTimeout(() => {
				if (this.focusRef.current) this.focusRef.current.focus();
			}, 200);
		}
	}

	render() {
		const { isOpen, intl, classes, handleClose } = this.props;
		const { query, events } = this.state;

		return (
			isOpen && (
				<ModalWindow
					title={intl.fm(
						"manager.eventDetails.eventQuery.addExistingEventTitle"
					)}
					open={isOpen}
					onClose={handleClose}
					classes={{ content: classes.content, modal: classes.modal }}
					fullWidth
				>
					<CustomInput
						inputProps={{
							type: "inputSearch",
							value: query,
							onChange: event => {
								const value = event.target.value;
								this.setState({ query: value });
								this.debounceQuery(value);
							},
							placeholder: intl.fm(
								"manager.eventDetails.eventQuery.queryPlaceholder"
							),
							inputRef: this.focusRef
						}}
						helpText={intl.fm("manager.eventDetails.eventQuery.minCharQuery")}
						classes={{
							formControl: classes.filterControl,
							input: classes.filterInput
						}}
					/>
					<EnhancedTable
						tableRenderer={this.tableRenderer}
						tableData={events}
						hover
						emptyContent={
							<NoData
								text={intl.fm("manager.eventDetails.eventQuery.noData")}
							/>
						}
						classes={{ tableResponsive: classes.table }}
						disablePS
					/>
				</ModalWindow>
			)
		);
	}

	debounceQuery = AwesomeDebouncePromise(query => {
		const { excludedEventIds } = this.props;
		if (query.length >= 3)
			this.props.queryManagerEvents(
				query,
				[
					EventType.CURRICULUM_EVENT,
					EventType.STANDALONE_EVENT
				],
				events => {
					this.setState({ events: events.filter(event => !excludedEventIds?.includes(event.id) && event.name.toLowerCase().includes(query.toLowerCase())) });				}
			);
		else this.setState({ events: [] });
	}, 300);

	tableRenderer = [
		{
			id: "image",
			title: this.props.intl.fm("manager.eventDetails.eventQuery.results"),
			renderCell: event => (
				<div
					style={{ backgroundImage: `url(/api/images/manager/events/${event.id})` }}
					className={this.props.classes.tableImage}
				/>
			)
		},
		{
			id: "name",
			renderCell: event => (
				<div className={this.props.classes.descContainer}>
					<div className={this.props.classes.descTitle}>{event.name}</div>
					<div className={this.props.classes.descSubtitle}>
						{event.parentName || "Standalone Event"}
					</div>
				</div>
			)
		},
		{
			id: "actions",
			align: "right",
			renderCell: event => (
				<>
					<Link
						to={
							event.type === EventType.CURRICULUM_EVENT
								? `/manager/events/${event.parent}/curriculumevents/${event.id}`
								: `/manager/events/${event.id}`
						}
						target="_blank"
					>
						<TableIconButton
							title={this.props.intl.fm("common.labels.openExternal")}
							faIcon="external-link-alt"
						/>
					</Link>
					<TableIconButton
						title={this.props.intl.fm("common.labels.cloneAndAdd")}
						color="info"
						faIcon="clone"
						onClick={() =>
							this.props.cloneEventIntoCurriculum(
								event.id,
								this.props.match.params.eventId,
								this.props.onAdded
							)
						}
					/>
				</>
			)
		}
	];
}

AddExistingEventModal.propTypes = {
	classes: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	onAdded: PropTypes.func.isRequired,
	queryManagerEvents: PropTypes.func.isRequired,
	excludedEventIds: PropTypes.arrayOf(PropTypes.number)
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			queryManagerEvents: managerApi.queryManagerEvents,
			cloneEventIntoCurriculum: managerApi.cloneEventIntoCurriculum
		},
		dispatch
	);

export default compose(
	connect(
		undefined,
		mapDispatchToProps
	),
	withRouter,
	withIntl,
	withStyles(addExistingEventModalStyle)
)(AddExistingEventModal);

import guid from "../helper/guid";
import { ActionType } from "actions/ActionType";

const BASE_URL = "/api";

const fetchUserInfo = onSuccess => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/user/myself/`,
	method: "get",
	onSuccess: (_, result) => {
		if (onSuccess) {
			onSuccess(result);
		}
	},
	onFailure: (_, result) => {}
});

const resendMailVerification = callback => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/user/resendEmailVerification/`,
	method: "get",
	onSuccess: () => {
		if (callback) {
			callback(true);
		}
	},
	onFailure: (_, result) => {
		if (callback) {
			callback(false);
		}
	}
});

export default {
	fetchUserInfo,
	resendMailVerification
};

import { grayColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";

import tableStyle from "./tableStyle";

const enhancedTableStyle = {
	...tableStyle,
	table: {
		...tableStyle.table,
		overflow: undefined
	},
	tableResponsive: { minHeight: "0.1%" },
	filterButton: {
		margin: 0,
		padding: 5,
		boxShadow: "none",
		"&:hover": {
			color: "white",
			backgroundColor: grayColor
		}
	},
	disabledStyle: { opacity: 0.5 },
	pagingBar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	pagingEllipsis: { fontWeight: 400 },
	jumpButtonContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	}
};

export default enhancedTableStyle;

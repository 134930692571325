import { Button as MuiButton, ButtonClassKey, ButtonProps as MuiButtonProps } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import cx from "classnames";
import * as React from "react";

import { ButtonColor, ButtonSize, useButtonStyles } from "./style";

interface ButtonProps extends Omit<MuiButtonProps, "classes" | "color" | "size"> {
	color?: ButtonColor;
	round?: boolean;
	fullWidth?: boolean;
	disabled?: boolean;
	customClass?: string;
	wd?: boolean;// make the button's min width to 160px
	justIcon?: boolean;// make the button smaller
	right?: boolean;// button will float right
	size?: ButtonSize;
	customClasses?: Partial<ClassNameMap<ButtonClassKey>>;
}

const ButtonRef: <T, P = {}>(render: React.ForwardRefRenderFunction<T, P>) => React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<T>> = React.forwardRef;

export const Button = ButtonRef<HTMLButtonElement, ButtonProps>(({
	color,
	round,
	children,
	fullWidth,
	disabled,
	customClass,
	right,
	justIcon,
	size,
	wd,
	customClasses,
	...rest
}, ref) => {
	const classes = useButtonStyles();
	const btnClasses = cx(classes.button, {
		...(color && color !== "gray" && { [classes[color]]: color }),
		[classes.round]: round,
		[classes.fullWidth]: fullWidth,
		[classes.disabled]: disabled,
		...(customClass && { [customClass]: customClass }),
		[classes.right]: right,
		[classes.justIcon]: justIcon,
		[classes.wd]: wd,
		...(size && { [classes[size]]: size }),
	});
	return (
		<MuiButton ref={ref} {...rest} className={btnClasses} classes={customClasses}>
			{children}
		</MuiButton>
	);
});

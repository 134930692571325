import { Sortable } from "@hlcr/app/model/Sortable";

import { Event } from "shared/event/model/Event";

export const eventDateSorter = (a: Event, b: Event) => {
	if (a.startTime < b.startTime) return -1;
	if (a.startTime > b.startTime) return 1;
	if (a.endTime < b.endTime) return -1;
	if (a.endTime > b.endTime) return 1;
	return a.sortOrder - b.sortOrder;
};

export const eventSortOrderSorter = (a: Sortable, b: Sortable) => {
	return a.sortOrder - b.sortOrder;
};

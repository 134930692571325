import { EnumBase, EnumDetail } from "@hlcr/app/enum/EnumBase";
import { Enum } from "@hlcr/core/enum";
import { EASE_TRANSITION, primaryBoxShadow } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { useIntl } from "@hlcr/ui/Intl";
import { createStyles, makeStyles, MenuItem, SelectClassKey, StandardProps, Theme } from "@material-ui/core";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { SelectProps } from "@material-ui/core/Select/Select";
import cx from "classnames";
import React, { useRef } from "react";

import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";
import guid from "helper/guid";

export interface LegacyMenuItem {
	id: string | number;
	text?: string;
	name?: string;
}

export type CustomSelectClassKey =
	| SelectClassKey
	| "selectFormControl"
	| "selectLabel"
	| "selectMenu"
	| "selectMenuItem"
	| "selectMenuItemSelected";

// TODO: get rid of legacy menu items
export interface CustomSelectProps<T extends EnumDetail<Enum, SelectEnum> = EnumDetail<Enum, SelectEnum>> extends StandardProps<SelectProps, CustomSelectClassKey, "native" | "value" | "required" | "fullWidth" | "MenuProps"> {
	menuItems: T | LegacyMenuItem[];
	value?: "" | keyof T | LegacyMenuItem["id"];
	// deprecated
	renderObjectValue?: (value: unknown) => React.ReactNode;
	required?: boolean;
	fullWidth?: boolean;
	formControlProps?: Omit<FormControlProps, "fullWidth" | "required" | "error">;
}

export interface SelectEnum extends EnumBase {
	name?: string;
}

export const CustomSelect = <T extends EnumDetail<Enum, SelectEnum>>(
	{
		menuItems,
		value = "",
		renderObjectValue,
		required = false,
		fullWidth = true,
		formControlProps,
		...selectProps
	}: CustomSelectProps<T>,
) => {
	const menuRef = useRef();
	const formControlUuid = guid();
	const classes = useStyles();

	const intl = useIntl();

	let parsedMenuItems: EnumDetail<Enum, SelectEnum> | T;
	let undefinedTitle = "input.select.undefined";

	if (Array.isArray(menuItems)) {
		parsedMenuItems = menuItems.reduce((prev, current) => ({ ...prev, [current.id]: { title: current.text } }), {});
	} else {
		parsedMenuItems = menuItems;

		if (parsedMenuItems.UNDEFINED) {
			undefinedTitle = parsedMenuItems.UNDEFINED.title;
			delete parsedMenuItems.UNDEFINED;
		}

	}

	return (
		<FormControl
			{...formControlProps}
			className={cx(classes.selectFormControl, formControlProps?.className)}
			fullWidth={fullWidth}
			required={required}
			error={required && value === ""}
		>
			{selectProps.label && <InputLabel htmlFor={formControlUuid}>{selectProps.label}</InputLabel>}
			<Select
				{...selectProps}
				className={cx(classes.select, selectProps.classes?.select)}
				value={value}
				// renderValue={renderLegacyValue}
				required={required}
				fullWidth={fullWidth}
				MenuProps={{
					className: classes.selectMenu,
					ref: menuRef,
				}}
			>
				{
					!required
					&& <MenuItem key={-1} value={""} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}>
						<em>{intl.fm(undefinedTitle)}</em>
					</MenuItem>
				}
				{
					Object.entries(parsedMenuItems).map(([ id, value ], index) => {
						let menuItemChildNode;

						if (selectProps.renderValue) {
							menuItemChildNode = selectProps.renderValue(id);
						} else {
							const enumValue = value as SelectEnum;
							menuItemChildNode = enumValue.title ? intl.fm(enumValue.title, enumValue.title) : enumValue.name;
						}

						return <MenuItem
							key={index}
							value={id}
							classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
						>
							{menuItemChildNode}
						</MenuItem>;
					})
				}
			</Select>
		</FormControl>
	);
};

const useStyles = makeStyles<Theme, {}, CustomSelectClassKey>((theme: Theme) => createStyles({
	root: {},
	select: {
		padding: "12px 24px 7px 0",
		fontSize: ".75rem",
		// fontWeight: "400",
		lineHeight: "1.42857",
		textDecoration: "none",
		textTransform: "uppercase",
		color: "#3C4858",
		letterSpacing: "0",
		"&:focus": { backgroundColor: "transparent" },
		"&[aria-owns] + input + svg": { transform: "rotate(180deg)" },
		"& + input + svg": { transition: EASE_TRANSITION },
		transition: "color 0.1s ease-out",
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 },
	},
	filled: {},
	outlined: {},
	selectMenu: {
		"& > div": {
			overflowX: "hidden",
			overflowY: "auto",
			"& > ul": {
				border: "0",
				padding: "5px 0",
				margin: "0",
				boxShadow: "none",
				minWidth: "100%",
				borderRadius: "4px",
				boxSizing: "border-box",
				display: "block",
				fontSize: "14px",
				textAlign: "left",
				listStyle: "none",
				backgroundColor: "#fff",
				backgroundClip: "padding-box",
				[DARK_MODE_SELECTOR]: { backgroundColor: COLOR.DARK_2 },
			},
		},
		"& $selectPaper $selectMenuItemSelectedMultiple": { backgroundColor: "inherit" },
		"& > div + div": { maxHeight: "266px !important" },
	},
	disabled: {},
	icon: {},
	iconOpen: {},
	iconFilled: {},
	iconOutlined: {},
	selectFormControl: {
		margin: "8px 1px 17px 0px",
		"& > div": {
			"&:before": {
				borderBottomWidth: "1px !important",
				borderBottomColor: "#D2D2D2 !important",
			},
		},
		minWidth: 170,
	},
	selectLabel: {
		fontSize: "12px",
		textTransform: "uppercase",
		color: "#AAAAAA !important",
		top: "5px",
	},
	selectMenuItem: {
		fontSize: "13px",
		padding: "10px 20px",
		margin: "2px 5px",
		borderRadius: "2px",
		transition: "all 150ms linear",
		display: "block",
		clear: "both",
		// fontWeight: "400",
		height: "auto",
		whiteSpace: "nowrap",
		color: "#333",
		paddingRight: "30px",
		"&:hover": {
			backgroundColor: theme.palette.primary.main,
			color: "#FFFFFF",
			...primaryBoxShadow,
		},
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 },
	},
	// multiSelectMenuItem: { display: "flex" },
	selectMenuItemSelected: {
		backgroundColor: theme.palette.primary.main + "!important",
		color: "#FFFFFF",
	},
	selectMenuItemSelectedMultiple: {
		backgroundColor: "transparent !important",
		"&:hover": {
			backgroundColor: theme.palette.primary.main + "!important",
			color: "#FFFFFF",
			...primaryBoxShadow,
			"&:after": { color: "#FFFFFF" },
		},
		"&:after": {
			top: "16px",
			right: "12px",
			width: "12px",
			height: "5px",
			transform: "rotate(-45deg)",
			opacity: "1",
			color: "#3c4858",
			position: "absolute",
			content: "''",
			transition: "opacity 90ms cubic-bezier(0,0,.2,.1)",
		},
	},
	selectPaper: {
		boxSizing: "border-box",
		borderRadius: "4px",
		padding: "0",
		minWidth: "100%",
		display: "block",
		border: "0",
		boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
		backgroundClip: "padding-box",
		margin: "2px 0 0",
		fontSize: "14px",
		textAlign: "left",
		listStyle: "none",
		backgroundColor: "transparent",
		maxHeight: "266px",
	},
	// selectSearchBox: {
	// 	minWidth: "250px",
	// 	[DARK_MODE_SELECTOR]: { backgroundColor: COLOR.DARK_1 },
	// },
}));

import IconButton from "@material-ui/core/IconButton";
import Snack from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import snackbarStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/snackbarStyle";

const getHorizontalAnchor = place => {
	if (place.indexOf("l") !== -1) {
		return "left";
	} else if (place.indexOf("c") !== -1) {
		return "center";
	} else return "right";
};

function Snackbar({
	classes,
	message,
	color,
	close,
	icon: Icon,
	place,
	open,
	closeNotification
}) {
	let action = [];
	const messageClasses = cx(classes.wordWrap, { [classes.iconMessage]: Icon !== undefined });
	if (close !== undefined) {
		action = [
			<IconButton
				className={classes.iconButton}
				key="close"
				aria-label="Close"
				color="inherit"
				onClick={() => closeNotification()}
			>
				<Close className={classes.close} />
			</IconButton>
		];
	}
	const iconClasses = cx({
		[classes.icon]: classes.icon,
		[classes.infoIcon]: color === "info",
		[classes.successIcon]: color === "success",
		[classes.warningIcon]: color === "warning",
		[classes.dangerIcon]: color === "danger",
		[classes.primaryIcon]: color === "primary",
		[classes.roseIcon]: color === "rose"
	});
	return (
		<Snack
			classes={{
				anchorOriginTopCenter: classes.top20,
				anchorOriginTopRight: classes.top40,
				anchorOriginTopLeft: classes.top40
			}}
			anchorOrigin={{
				vertical: place.indexOf("t") === -1 ? "bottom" : "top",
				horizontal: getHorizontalAnchor(place)
			}}
			open={open}
			message={
				<div>
					{Icon !== undefined ? <Icon className={iconClasses} /> : null}
					<span className={messageClasses}>{message}</span>
				</div>
			}
			action={action}
			ContentProps={{
				classes: {
					root: classes.root + " " + classes[color],
					message: classes.message
				}
			}}
		/>
	);
}

Snackbar.defaultProps = { color: "info" };

Snackbar.propTypes = {
	classes: PropTypes.object.isRequired,
	message: PropTypes.node.isRequired,
	color: PropTypes.oneOf([
		"info",
		"success",
		"warning",
		"danger",
		"primary",
		"rose"
	]),
	close: PropTypes.bool,
	place: PropTypes.oneOf([
		"tl",
		"tr",
		"tc",
		"br",
		"bl",
		"bc" 
	]),
	open: PropTypes.bool
};

export default withStyles(snackbarStyle)(Snackbar);

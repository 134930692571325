import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import navPillsStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/navPillsStyle.jsx";
import cx from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import PropTypes from "prop-types";
import React from "react";
import SwipeableViews from "react-swipeable-views";

class NavPills extends React.Component {
	render() {
		const {
			classes,
			tabs,
			direction,
			color,
			horizontal,
			alignCenter,
			tabLinks,
			active,
			onChange,
			hideTabs,
		} = this.props;

		const flexContainerClasses = cx(classes.flexContainer, { [classes.horizontalDisplay]: horizontal !== undefined });

		const tabButtons = (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "flex-end",
				}}
			>
				<Tabs
					style={hideTabs ? { display: "none" } : {}}
					classes={{
						root: classes.root,
						fixed: classes.fixed,
						flexContainer: flexContainerClasses,
						indicator: classes.displayNone,
					}}
					value={active}
					onChange={this.handleChange}
					centered={alignCenter}
				>
					{tabs.map((prop, key) => {
						const icon = {};
						if (prop.tabIcon !== undefined) {
							icon["icon"] = <prop.tabIcon className={classes.tabIcon} />;
						}
						const pillsClasses = cx(classes.pills, classes.labelContainer, classes.label, {
							[classes.horizontalPills]: horizontal !== undefined,
							[classes.pillsWithIcons]: prop.tabIcon !== undefined,
						});

						return (
							<Tab
								label={prop.tabButton}
								key={key}
								{...icon}
								classes={{
									root: pillsClasses,
									selected: classes[color],
								}}
							/>
						);
					})}
				</Tabs>
				{tabLinks.map((link, key) => (
					<Tab
						label={link.text}
						key={key}
						icon={<link.icon className={classes.tabIcon} />}
						onClick={() => {
							onChange(link.openTab);
							link.onClicked();
						}}
						classes={{
							root: cx(classes.pills, classes.labelContainer, classes.label, {
								[classes.horizontalPills]: horizontal !== undefined,
								[classes.pillsWithIcons]: link.icon !== undefined,
							}),
							selected: classes[color],
						}}
					/>
				))}
			</div>
		);

		const tabContent = (
			<div className={classes.contentWrapper}>
				<SwipeableViews
					axis={direction === "rtl" ? "x-reverse" : "x"}
					index={active}
					onChangeIndex={this.handleChangeIndex}
				>
					{tabs.map((prop, key) => {
						return (
							<div
								className={cx(classes.tabContent, { [classes.displayNone]: active !== key })}
								key={key}
							>
								{prop.tabContent}
							</div>
						);
					})}
				</SwipeableViews>
			</div>
		);

		return horizontal !== undefined ? (
			<GridContainer>
				<ItemGrid {...horizontal.tabsGrid}>{tabButtons}</ItemGrid>
				<ItemGrid {...horizontal.contentGrid}>{tabContent}</ItemGrid>
			</GridContainer>
		) : (
			<div>
				{tabButtons}
				{tabContent}
			</div>
		);
	}

	handleChange = (event, active) => {
		this.props.onChange(active);
	};

	handleChangeIndex = index => {
		this.props.onChange(index);
	};
}

NavPills.defaultProps = {
	active: 0,
	color: "primary",
};

NavPills.propTypes = {
	classes: PropTypes.object.isRequired,
	// index of the default active pill
	active: PropTypes.number,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			tabButton: PropTypes.node,
			tabIcon: PropTypes.object,
			tabContent: PropTypes.node,
		}),
	).isRequired,
	tabLinks: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			icon: PropTypes.object,
			openTab: PropTypes.number.isRequired,
			onClicked: PropTypes.func,
		}),
	),
	color: PropTypes.oneOf([
		"primary",
		"warning",
		"danger",
		"success",
		"info",
		"rose",
	]),
	direction: PropTypes.string,
	horizontal: PropTypes.shape({
		tabsGrid: PropTypes.object,
		contentGrid: PropTypes.object,
	}),
	alignCenter: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	hideTabs: PropTypes.bool,
};

export default withStyles(navPillsStyle)(NavPills);

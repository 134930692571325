import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import eventsApi from "actions/events";
import { getShowInfoSetting } from "helper/eventUnit";
import { createMemoize } from "helper/memoize";
import { ChallengeOverview } from "views/Events/Challenge";

const getChallenge = createMemoize((challenges, challengeId) => challenges.find(challenge => challenge.id === challengeId));

const getSectionIds = createMemoize(challenge => (challenge && challenge.sections && challenge.sections.map(s => s.id)) || []);

const getEvent = createMemoize((events, challenge) => challenge ? events.find(event => event.id === challenge.eventId) : undefined);

const filterSteps = createMemoize((steps, sectionIds) => steps.data.filter(s => sectionIds.some(id => id === s.sectionId)).sort((a, b) => a.sortOrder - b.sortOrder));

const filterSolutionComments = createMemoize((solutionComments, solutionId) => solutionId ? solutionComments.data.filter(s => s.solutionId === solutionId) : []);

const mapStateToProps = (state, ownProps) => {
	const { events, challenges, steps, solutionComments } = state.api.resources;

	const challengeId = Number(ownProps.match.params.unitId);
	const challenge = getChallenge(challenges.data, challengeId);
	const sectionIds = getSectionIds(challenge);
	const solutionId = challenge && challenge.solution ? challenge.solution.id : undefined;

	return {
		challengeId,
		challenge,
		event: getEvent(events.data, challenge),
		steps: filterSteps(steps, sectionIds),
		solutionComments: filterSolutionComments(solutionComments, solutionId),
		displayInfo: getShowInfoSetting(ownProps.cookies),
		stepsLoading: steps.pending,
		isLoading:
			events.pending ||
			challenges.pending ||
			steps.pending ||
			solutionComments.pending
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchChallenge: eventsApi.fetchChallenge,
			fetchSteps: eventsApi.fetchSteps,
			fetchStep: eventsApi.fetchStep,
			fetchStepTitles: eventsApi.fetchStepTitles,
			postSolutionComment: eventsApi.postSolutionComment
		},
		dispatch
	);

const UserChallenge = compose(
	withCookies,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(ChallengeOverview);

export default UserChallenge;

let UserChallengeBreadCrumb = ({ challenge }) => <span>{challenge ? challenge.title : "Challenge"}</span>;

const mapStateToPropsBreadCrumb = (state, ownProps) => {
	const challengeId = Number(ownProps.match.params.unitId);

	return { challenge: state.api.resources.challenges.data.find(c => c.id === challengeId) };
};

UserChallengeBreadCrumb = connect(mapStateToPropsBreadCrumb)(UserChallengeBreadCrumb);
export { UserChallengeBreadCrumb };

import { withStyles } from "@material-ui/core/styles";
import { func, object, oneOf, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { compose } from "redux";

import { dangerColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { Button } from "@hlcr/mui/Button";
import CustomInput from "components/CustomInput/CustomInput";
import ModalWindow from "components/ModalWindow/ModalWindow";
import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";
import { withIntl } from "@hlcr/ui/Intl";

export const TenaciousAction = {
	ARCHIVE: "ARCHIVE",
	RESET: "RESET",
	DELETE: "DELETE"
};

const TenaciousWarning = ({ onConfirm, onClose, classes, intl, title, messageLocalizationId, entity, action, entityIdentifier }) => {
	const [ expected, setExpected ] = useState(undefined);
	const [ value, setValue ] = useState("");

	useEffect(() => {
		if (entity) {
			let actionText = "";
			if (action === TenaciousAction.ARCHIVE) {
				actionText = intl.fm("common.labels.archive");
			}
			if (action === TenaciousAction.DELETE) {
				actionText = intl.fm("common.labels.delete");
			}
			if (action === TenaciousAction.RESET) {
				actionText = intl.fm("common.labels.reset");
			}
			setExpected(`${actionText}: ${entityIdentifier(entity)}`);
		}
	}, [ action, entity, entityIdentifier, intl ]);

	const isValidInput = () => {
		return !!value && value === expected;
	};

	const handleClose = () => {
		setValue("");
		onClose();
	};

	const handleConfirm = () => {
		if (isValidInput()) {
			if (entity) {
				onConfirm(entity);
			} else {
				onConfirm();
			}
			setValue("");
		}
	};

	const warningText = () => {
		const identifier = entityIdentifier(entity);
		if(messageLocalizationId){
			return intl.fm(messageLocalizationId, null, { identifier });
		}
		if (action === TenaciousAction.DELETE) {
			return intl.fm("tenacious.warning.message.delete", null, { identifier });
		}
		if (action === TenaciousAction.RESET) {
			return intl.fm("tenacious.warning.message.reset", null, { identifier });
		}
		if (action === TenaciousAction.ARCHIVE) {
			return intl.fm("tenacious.warning.message.archive", null, { identifier });
		}
	};

	if (!entity) {
		return null;
	}
	return (
		<ModalWindow
			open={!!entity}
			onClose={handleClose}
			title={title ? title : intl.fm("common.confirm.title")}
			actionSection={
				<div className={classes.actions}>
					<Button onClick={handleClose} color="defaultNoBackground">
						{intl.fm("common.labels.cancel")}
					</Button>
					<Button onClick={handleConfirm} color="dangerNoBackground" disabled={!isValidInput()}>
						{intl.fm("common.labels.confirm")}
					</Button>
				</div>
			}
		>
			<p className={classes.dangerText}><strong>{warningText()}</strong></p>
			<p>{intl.fm("tenacious.warning.message.prevent")}</p>
			<p>{intl.fm("tenacious.warning.message.pleaseType")} <span className={classes.marked}>{expected}</span> {intl.fm("tenacious.warning.message.toProceed")}</p>
			<CustomInput
				inputProps={{
					placeholder: expected,
					onChange: e => setValue(e.target.value),
					value: value,
				}}
				formControlProps={{ className: classes.inputControl, fullWidth: true }}
			/>
		</ModalWindow>
	);
};

TenaciousWarning.propTypes = {
	classes: object.isRequired,
	intl: object.isRequired,
	onClose: func.isRequired,
	onConfirm: func.isRequired,
	title: string,
	messageLocalizationId: string,
	entity: object,
	entityIdentifier: func.isRequired,
	action: oneOf(Object.values(TenaciousAction)).isRequired
};

export default compose(
	withStyles({
		actions: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between"
		},
		inputControl: {
			paddingTop: 0,
			marginBottom: 0,
			fontSize: "initial"
		},
		marked: {
			fontFamily: "Menlo, Monaco, Consolas, \"Courier New\", monospace",
			fontWeight: "bolder",
			borderRadius: "4px",
			padding: "2px 4px",
			backgroundColor: "#f2f2f2",
			[DARK_MODE_SELECTOR]: { backgroundColor: COLOR.DARK_1 }
		},
		dangerText: { color: dangerColor }
	}),
	withIntl
)(TenaciousWarning);

import { ActionType } from "actions/ActionType";

const BASE_URL = "/api/ranking";

const fetchEventUsersRanking = eventId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/individuals/?eventIds=${eventId}`,
	method: "get",
	setFieldOnObject: { key: "eventId", value: eventId },
	resource: "rankingUsers",
	updatePropForArray: { key: "eventId", value: eventId },
});

const fetchEventTeamsRanking = eventId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/?eventIds=${eventId}`,
	method: "get",
	setFieldOnObject: { key: "eventId", value: eventId },
	resource: "rankingTeams",
	updatePropForArray: { key: "eventId", value: eventId },
});

export default {
	fetchEventUsersRanking,
	fetchEventTeamsRanking,
};

import { withStyles } from "@material-ui/core/styles";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import EditIcon from "@material-ui/icons/Edit";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";
import React from "react";
import { FormattedRelativeTime } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teacherApi from "actions/teacher";
import eventUnitStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/eventUnitStyle";
import { Badge } from "@hlcr/mui";
import IconCard from "components/Cards/IconCard";
import Tooltip from "components/CustomTooltip/CustomTooltip";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import LevelLabel from "components/LevelLabel/LevelLabel";
import NoData from "components/NoData/NoData";
import QuizSolutionEditor from "components/Quiz/QuizSolutionEditor";
import SolutionComments from "components/Quiz/SolutionComments";
import BasicCommentModal from "components/Solution/BasicCommentModal";
import Table from "components/Table/Table";
import { CategoryIcons } from "helper/categories";
import { tryFormatShort } from "helper/dateCalc";
import { printPercentage } from "helper/pointPrint";
import { withIntl } from "@hlcr/ui/Intl";
import { QUIZ_SOLUTION_STATES } from "models/SolutionState";

class TeacherQuizRaw extends React.Component {
	state = { isModalOpen: false, points: 0 };

	componentDidUpdate({ quizSolution }, state) {
		if (quizSolution && quizSolution.points !== state.points) {
			this.setState({points: quizSolution.points});
		}
	}

	componentDidMount() {
		const { eventId, fetchTeacherEvent, fetchTeacherQuizSolution, fetchTeacherQuizAnswers, fetchTeacherQuizGradingInstructions, fetchTeacherQuizComments, quizSolutionId } = this.props;

		fetchTeacherEvent(eventId, true);
		fetchTeacherQuizSolution(quizSolutionId);
		fetchTeacherQuizAnswers(quizSolutionId);
		fetchTeacherQuizGradingInstructions(quizSolutionId);
		fetchTeacherQuizComments(quizSolutionId);
	}

	render() {
		const { isModalOpen } = this.state;
		const { event, quizSolution, gradingInstructions, quizAnswers, quizClosed, showAllSolution, disabled, comments } = this.props;
		if (quizSolution) {
			let quiz = quizSolution.quiz;
			quiz.questions = gradingInstructions;

			return (
				<div>
					<h3>{quiz.title}</h3>
					<GridContainer>
						<ItemGrid xs={12} sm={12} md={6} lg={6}>
							<InfoSection {...this.props} quizSolution={{ ...quizSolution, points: this.state.points }} />
						</ItemGrid>
						<ItemGrid xs={12} sm={12} md={12} lg={12}>
							<QuizSolutionEditor
								quiz={quiz}
								answers={quizAnswers}
								isPreview={false}
								isExamen={event?.exam}
								showAllSolution={showAllSolution}
								showPartialSolution={false}
								quizClosed={quizClosed}
								gradingInstructions={gradingInstructions}
							/>
						</ItemGrid>
						<ItemGrid xs={12} sm={12} md={12} lg={12}>
							<SolutionComments
								addAction={
									!disabled && [
										{
											icon: <EditIcon />,
											name: "Edit Solution",
											style: { background: "yellow" },
											handleClick: this.openModal
										}
									]
								}
								comments={comments}
								quiz={quiz}
							/>
						</ItemGrid>
					</GridContainer>
					<BasicCommentModal
						open={isModalOpen}
						onClose={this.closeModal}
						onSubmit={this.submitComment}
						points={quizSolution.points}
						maxPoints={quiz.maxPoints}
					/>
				</div>
			);
		} else {
			return <NoData />;
		}
	}

	openModal = () => {
		this.setState({ isModalOpen: true });
	};

	closeModal = (newPoints) =>  {
		this.setState((prev) =>({ isModalOpen: false, points: newPoints ?? prev.points }));
	};

	submitComment = (comment, points, onSuccess, onFailure) => {
		const { quizSolutionId, postTeacherQuizComment } = this.props;
		const commentDto = {
			quizSolutionId,
			comment,
			points
		};
		postTeacherQuizComment(commentDto, quizSolutionId, onSuccess, onFailure);
	};
}

TeacherQuizRaw.propTypes = {
	classes: PropTypes.object.isRequired,
	quizSolutionId: PropTypes.number.isRequired
};

const InfoSection = ({ quizSolution, event, classes, intl }) => {
	const quiz = quizSolution.quiz;
	const tableRenderer = [
		{
			id: "title",
			renderCell: row => row.title
		},
		{
			id: "value",
			renderCell: row => row.value
		}
	];

	const tableData = [
		{
			title: intl.fm("event.table.categories"),
			value: <CategoryIcons categories={quiz.categories} />
		},
		{
			title: intl.fm("event.table.level"),
			value: quiz.level && quiz.level.name ? (<LevelLabel value={quiz.level.name} />) : (intl.fm("common.labels.unknown"))
		},
		{
			title: intl.fm("event.table.mode"),
			value: (
				<span className={classes.typeIcon}>
					<Tooltip
						title={quiz.trainingMode ? intl.fm("challenge.mode.training") : intl.fm("challenge.mode.test")}
						placement="right"
						classes={{ popper: classes.tooltip }}
					>
						{quiz.trainingMode ? (<FitnessCenterIcon className={classes.useFlagIcon} />) : (<AssignmentLateIcon className={classes.useFlagIcon} />)}
					</Tooltip>
				</span>
			)
		},
		{
			title: intl.fm("solution.status"),
			value: (
				<div className={classes.solutionStatus}>
					<Badge color={QUIZ_SOLUTION_STATES[quizSolution.state]?.color}>
						{intl.fm((QUIZ_SOLUTION_STATES[quizSolution.state] || QUIZ_SOLUTION_STATES.UNSOLVED).title)}
					</Badge>
					<span className={classes.solutionPoints}>
						{printPercentage(quizSolution.points, quiz.maxPoints)}
					</span>
				</div>
			)
		}
	];
	quiz.endTime &&
	tableData.push({
		title: intl.fm("quiz.info.endTime"),
		value: (
			<div>
				{tryFormatShort(quiz.endTime)}, {intl.fm("quiz.info.finishTime")}:{" "}
				<FormattedRelativeTime value={new Date(quiz.endTime) - Date.now()} updateInterval={1000} />
			</div>
		)
	});

	return (
		<IconCard
			title={intl.fm("challenge.properties")}
			icon={InfoIcon}
			content={<Table tableRenderer={tableRenderer} tableData={tableData} hover />}
		/>
	);
};

const mapStateToProps = (state, ownProps) => {
	const quizSolutionId = Number(ownProps.match.params.solutionId);
	const quizSolution = state.api.resources.teacherQuizSolutions.data.find(qs => qs.id === quizSolutionId);
	const eventId = Number(ownProps.match.params.eventId);

	return {
		eventId,
		quizSolutionId,
		quizSolution,
		isLoading: state.api.resources.teacherQuizSolutions.pending || state.api.resources.teacherQuizGradingInstructions.pending || state.api.resources.teacherQuizAnswers.pending,
		quizClosed: true,
		event: state.api.resources.events.data.filter(event => event.id === eventId),
		gradingInstructions: state.api.resources.teacherQuizGradingInstructions.data.filter(gi => gi.quizSolutionId === quizSolutionId),
		comments: state.api.resources.teacherQuizComments.data.filter(gi => gi.quizSolutionId === quizSolutionId),
		quizAnswers: state.api.resources.teacherQuizAnswers.data.filter(qa => qa.quizSolutionId === quizSolutionId)
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchTeacherEvent: teacherApi.fetchTeacherEvent,
			fetchTeacherQuizSolution: teacherApi.fetchTeacherQuizSolution,
			fetchTeacherQuizAnswers: teacherApi.fetchTeacherQuizAnswers,
			fetchTeacherQuizGradingInstructions: teacherApi.fetchTeacherQuizGradingInstructions,
			fetchTeacherQuizComments: teacherApi.fetchTeacherQuizComments,
			postTeacherQuizComment: teacherApi.postTeacherQuizComment
		},
		dispatch
	);

export const TeacherQuiz = compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(eventUnitStyle),
	withIntl
)(TeacherQuizRaw);

let TeacherQuizBreadCrumb = ({ quiz }) => (
	<span>{quiz ? quiz.title : "Quiz"} Solution</span>
);

const mapStateToPropsBreadCrumb = (state, ownProps) => {
	const quizId = Number(ownProps.match.params.solutionId);

	return { quiz: state.api.resources.quizzes.data.find(q => q.id === quizId) };
};

TeacherQuizBreadCrumb = connect(mapStateToPropsBreadCrumb)(
	TeacherQuizBreadCrumb
);

export { TeacherQuizBreadCrumb, TeacherQuizRaw };

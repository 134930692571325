import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/icons/Menu";
import cx from "classnames";
import { array, bool, func, object, oneOf } from "prop-types";
import React from "react";

import headerStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import BreadCrumbs from "layouts/BreadCrumbs";
import HeaderLinks from "layouts/HeaderLinks";

function Header({ classes, color, rtlActive, routes, location, handleDrawerToggle, isFixed, toggleFixedLayout }) {
	function makeBrand() {
		let name = "???";
		routes.some(route => {
			if (location.pathname.startsWith(route.path)) {
				name = route.name;
				return true;
			}
			return false;
		});
		return name;
	}

	const appBarClasses = cx(classes.appBar, { [classes[color]]: color });
	return (
		<AppBar className={appBarClasses}>
			<Toolbar className={classes.container}>
				<Hidden mdUp>
					<IconButton className={classes.appResponsive} color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
						<Menu />
					</IconButton>
				</Hidden>
				<div className={classes.breadcrumbsContainer}>
					<BreadCrumbs
						className={classes.breadcrumbs}
						linkClassName={classes.breadcrumbLink}
						alternative={
							<Button href="#" className={classes.title}>
								{makeBrand()}
							</Button>
						}
					/>
				</div>
				<Hidden smDown implementation="css">
					<HeaderLinks rtlActive={rtlActive} isFixed={isFixed} toggleFixedLayout={toggleFixedLayout} />
				</Hidden>
			</Toolbar>
		</AppBar>
	);
}

Header.propTypes = {
	classes: object.isRequired,
	color: oneOf([
		"primary",
		"info",
		"success",
		"warning",
		"danger"
	]),
	rtlActive: bool,
	routes: array,
	handleDrawerToggle: func,
	location: object,
	isFixed: bool.isRequired,
	toggleFixedLayout: func.isRequired
};

export default withStyles(headerStyle)(Header);

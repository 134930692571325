import { defaultFont } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";

const headerLinksStyle = theme => ({
	linkText: {
		zIndex: "4",
		...defaultFont,
		fontSize: "14px",
		margin: "0!important"
	},
	buttonLink: {
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			marginLeft: "30px",
			width: "auto"
		}
	},
	links: {
		width: "20px",
		height: "20px",
		zIndex: "4",
		[theme.breakpoints.down("sm")]: {
			display: "block",
			width: "30px",
			height: "30px",
			color: "inherit",
			opacity: "0.8",
			marginRight: "16px",
			marginLeft: "-5px"
		}
	},
	leftIcon: { marginRight: theme.spacing() },
	fluidLayoutIcon: { [theme.breakpoints.down("md")]: { display: "none" } }
});

export default headerLinksStyle;

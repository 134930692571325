import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import hiddenContentSliderStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/hiddenContentSliderStyle";
import { Button } from "@hlcr/mui/Button";


class HiddenContentSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isDisplayed: false };
	}
	handleSwitch = () => {
		this.setState(state => ({ isDisplayed: !state.isDisplayed }));
	};

	render() {
		const { children, openLabel, closeLabel, classes } = this.props;
		const { isDisplayed } = this.state;

		return (
			<div
				className={cx(classes.container, {
					[classes.contentHidden]: !isDisplayed,
					[classes.contentShown]: isDisplayed
				})}
			>
				<Button
					color="infoNoBackground"
					customClass={classes.sideButton}
					onClick={this.handleSwitch}
				>
					{isDisplayed ? (
						<div className={classes.btnWrapper}>{closeLabel}</div>
					) : (
						<div className={classes.btnWrapper}>{openLabel}</div>
					)}
				</Button>
				<div className={classes.contentWrapper}>{isDisplayed && children}</div>
			</div>
		);
	}
}
HiddenContentSlider.propTypes = {
	classes: PropTypes.object.isRequired,
	openLabel: PropTypes.string.isRequired,
	closeLabel: PropTypes.string.isRequired
};

export default withStyles(hiddenContentSliderStyle)(HiddenContentSlider);

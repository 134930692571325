import { formatMessage } from "@hlcr/ui/Intl";

export const Events = () => formatMessage("navigation.entries.events");
export const Teacher = () => formatMessage("navigation.entries.teacher");
export const Participants = () => formatMessage("navigation.entries.participants");
export const Report = () => formatMessage("navigation.entries.report");
export const ClassesReport = () => formatMessage("navigation.entries.classesReport");
export const ClassReport = () => formatMessage("navigation.entries.classReport");
export const TeamsReport = () => formatMessage("navigation.entries.teamsReport");
export const UsersReport = () => formatMessage("navigation.entries.usersReport");
export const UserReport = () => formatMessage("navigation.entries.userReport");
export const Manager = () => formatMessage("navigation.entries.manager");
export const Profile = () => formatMessage("navigation.entries.user.profile");
export const Preview = () => formatMessage("event.preview");

import { makeStyles } from "@material-ui/core";

export const progressStyle = {
	events: {
		display: "flex",
		flexWrap: "wrap",
		marginLeft: "-5px",
	},
};
// @ts-ignore I have no f**** idea why this css in js shit has a type mismatch here...
export const useProgressStyle = makeStyles(progressStyle);

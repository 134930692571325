import { percent, percentage, roundToDecimals } from "@hlcr/core/numeric";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { compose } from "redux";

import solutionStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/solutionStyle";
import { Button } from "@hlcr/mui/Button";
import CustomInput from "components/CustomInput/CustomInput";
import ModalWindow from "components/ModalWindow/ModalWindow";
import PointSlider from "components/Solution/PointSlider";
import { withIntl } from "@hlcr/ui/Intl";
import "rc-slider/assets/index.css";

class BasicCommentModal extends React.Component {
	state = {
		isModalOpen: false,
		commentText: "",
		writeupGrade: 0,
		submitting: false
	};

	clearFormState = { commentText: "" };

	render() {
		const { disabled, onClose, maxPoints, classes, intl } = this.props;
		const { isModalOpen, submitting, writeupGrade, commentText } = this.state;
		return (
			<ModalWindow
				open={isModalOpen}
				onClose={onClose}
				title={intl.fm("challenge.comment.newSolution.titleTeacher", null, { what: intl.fm("teacher.grading.grade") })}
				actionSection={
					<div className={classes.actions}>
						<Button
							disabled={disabled}
							onClick={this.clearForm}
							color="defaultNoBackground"
						>
							{submitting && false ? (
								<CircularProgress size={15} />
							) : (
								intl.fm("common.labels.clearForm")
							)}
						</Button>
						<Button
							disabled={disabled}
							onClick={this.submit}
							color="infoNoBackground"
						>
							{submitting ? (
								<CircularProgress size={15} />
							) : (
								intl.fm("common.labels.submit")
							)}
						</Button>
					</div>
				}
				fullWidth
			>
				<form>
					<h4>{intl.fm("teacher.solution.grading.title")}</h4>

					<PointSlider
						value={percentage(writeupGrade)}
						maxPoints={maxPoints}
						onChange={this.updateSolutionState}
						flagPoints={0}
						writeupWeight={1}
						classes={classes}
						disabled={submitting}
					/>
					<CustomInput
						labelText={intl.fm("teacher.solution.feedbackInstr")}
						helpText={intl.fm("solution.history.maxCommentLength")}
						formControlProps={{ fullWidth: true }}
						inputProps={{
							multiline: true,
							rows: 10,
							value: commentText,
							onChange: this.updateCommentText,
							disabled: submitting
						}}
					/>
				</form>
			</ModalWindow>
		);
	}

	updateCommentText = event =>
		this.setState({ commentText: event.currentTarget.value });

	clearForm = () => this.setState(this.clearFormState);

	updateSolutionState = value => this.setState({ writeupGrade: percent(value) });

	startSubmit = () => this.setState({ submitting: true });

	stopSubmit = () => this.setState({ submitting: false });

	stopSubmitAndClearForm = () =>
		this.setState({ submitting: false, ...this.clearFormState });

	static getDerivedStateFromProps(nextProps, prevState) {
		const { points, maxPoints, open } = nextProps;
		const { isModalOpen } = prevState;
		if (!isModalOpen && open) {
			const grade = roundToDecimals(points / maxPoints, 2);
			return { writeupGrade: grade, isModalOpen: true };
		}
		return { isModalOpen: open };
	}

	submit = () => {
		const { onSubmit, onClose, maxPoints } = this.props;
		const { commentText, writeupGrade } = this.state;

		this.startSubmit();

		const points = roundToDecimals(writeupGrade * maxPoints, 0);

		onSubmit(
			commentText,
			points,
			() => {
				onClose(points);
				this.stopSubmitAndClearForm();
			},
			() => this.stopSubmit()
		);
	};
}

export default compose(
	withIntl,
	withStyles(solutionStyle)
)(BasicCommentModal);

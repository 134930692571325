import cx from "classnames";
import React from "react";

import { getCurrentUsername } from "auth/authUtils";
import Tooltip from "components/CustomTooltip/CustomTooltip";
import NoData from "components/NoData/NoData";
import EnhancedTable from "components/Table/EnhancedTable";
import { printDuration } from "helper/dateCalc";
import { ASSET_DIFFICULTY_LEVELS } from "models/Asset";

const domainFilterRegex = /(.+)@[A-Z0-9.-]+\.[A-Z]+$/i;

class EventRankingTable extends React.Component {

	render() {
		const { tableData, isTeam, intl, classes, solvableEventUnits } = this.props;

		const tableRenderer = [
			{
				id: "rank",
				title: intl.fm("ranking.userRanking.rank"),
				renderCell: rank =>
					rank.username === getCurrentUsername() ? (
						<b>{rank.rank}</b>
					) : (
						rank.rank
					)
			},
			{
				id: "score",
				title: intl.fm("ranking.userRanking.score"),
				renderCell: rank =>
					rank.username === getCurrentUsername()
						? (<b>{rank.points}<br /><small>{printDuration(rank.offsetInSeconds, intl)}</small></b>)
						: (<>{rank.points}<br /><small>{printDuration(rank.offsetInSeconds, intl)}</small></>)
			},
			{
				id: "name",
				title: intl.fm("ranking.userRanking.name"),
				renderCell: rank =>
					rank.username ? (
						rank.username === getCurrentUsername() ? (
							<b>{rank.username.replace(domainFilterRegex, "$1")}</b>
						) : (
							rank.username.replace(domainFilterRegex, "$1")
						)
					) : (
						rank.teamName
					)
			},
			{
				id: "solved",
				title: intl.fm("ranking.userRanking.solvedChallenges"),
				renderCell: rank => (
					<div className={classes.challengesBox}>
						{solvableEventUnits?.map((unit, index) => {
							const rankChallenge = rank.challenges.find(c => c.id === unit.id);
							const challengePoints = rankChallenge?.points ?? 0;
							const levelTitle = unit.level?.name ? intl.fm(ASSET_DIFFICULTY_LEVELS[unit.level.name.toUpperCase()].title) : "";
							return (
								<Tooltip
									key={index}
									placement="left-start"
									title={`${challengePoints} / ${unit.maxPoints} ${levelTitle}, ${unit.title}`}
								>
									<div
										style={{
											height: (challengePoints || unit.maxPoints) / 5,
											minHeight: 10,
										}}
										className={cx(classes["challengeContainer_" + unit.level.name.toLowerCase()], { [classes.emptyChallengeContainer]: challengePoints === 0 })}
									>
										{rankChallenge?.consolidatedGrade > 0 &&
										 rankChallenge?.consolidatedGrade < 1 && (
											 <div className={classes.partialSolveContainer} />
										 )}
									</div>
								</Tooltip>
							);
						})}
					</div>
				)
			}
		];

		let jumpToMyRanking;

		if (!isTeam) {
			tableRenderer.splice(3, 0, {
				id: "userLevel",
				title: intl.fm("ranking.userRanking.userLevel"),
				renderCell: rank => rank.userLevel
			});
			jumpToMyRanking = {
				label: intl.fm("ranking.userRanking.gotoMyRanking"),
				findPredicate: rank => rank.username === getCurrentUsername()
			};
		}

		return (
			<div className={classes.rankingTableContainer}>
				<EnhancedTable
					tableRenderer={tableRenderer}
					tableData={tableData}
					hover
					emptyContent={<NoData text={intl.fm("ranking.table.noData")} />}
					paginate={20}
					jumpButton={jumpToMyRanking}
				/>
			</div>
		);
	}
}

export default EventRankingTable;

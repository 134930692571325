import { withStyles } from "@material-ui/core/styles";
import GroupIcon from "@material-ui/icons/Group";
import SchoolIcon from "@material-ui/icons/School";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import InfoCard from "components/Cards/InfoCard";
import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import { DownloadButton, ReportLink, ReportTableCard } from "components/Report/ReportComponents";

import reportApi from "../../actions/report";
import reportStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/reportStyle";
import { printPercentage } from "helper/pointPrint";
import { withIntl } from "@hlcr/ui/Intl";
import { getEventState } from "variables/constantsHelpers";
import { EventStateDesc } from "variables/constantsLocalization";

class ClassesReport extends React.Component {
	componentDidMount() {
		const {
			fetchClasses,
		} = this.props;

		fetchClasses();
	}

	render() {
		const { classReports } = this.props;

		return (
			<GridContainer>
				<ItemGrid xs={12} sm={6} md={6} lg={3}>
					{classReports && <NumberOfClassesInfo {...this.props} />}
				</ItemGrid>
				<ItemGrid xs={12} sm={6} md={6} lg={3}>
					{classReports && <NumberOfTeachers {...this.props} />}
				</ItemGrid>

				<ItemGrid xs={12} sm={12} md={12} lg={12}>
					{classReports && <ClassesTableCard {...this.props} />}
				</ItemGrid>
			</GridContainer>
		);
	}
}

const ClassesTableCard = props => {
	const { intl, classReports, classes } = props;
	const getPoints = classReport =>
		printPercentage(
			classReport.mandatoryFullPointSolutionCount,
			classReport.mandatoryUserChallengeCount
		);
	const tableRenderer = [
		{
			id: "name",
			title: intl.fm("report.table.classes.header.name"),
			renderCell: classReport => classReport.className,
			renderCsv: classReport => classReport.className,
			sort: true
		},
		{
			id: "teams",
			title: intl.fm("report.table.classes.header.teams"),
			renderCell: classReport => classReport.teamCount,
			renderCsv: classReport => classReport.teamCount,
			sort: true
		},
		{
			id: "users",
			title: intl.fm("report.table.classes.header.users"),
			renderCell: classReport => classReport.userCount,
			renderCsv: classReport => classReport.userCount,
			sort: true
		},
		{
			id: "status",
			title: intl.fm("report.table.classes.header.status"),
			renderCell: getPoints,
			renderCsv: getPoints,
			sort: true
		},
		{
			id: "running",
			title: intl.fm("report.table.classes.header.running"),
			renderCell: classReport =>
				EventStateDesc(
					getEventState(classReport.classStartTime, classReport.classEndTime)
				),
			renderCsv: classReport =>
				EventStateDesc(
					getEventState(classReport.classStartTime, classReport.classEndTime),
					intl
				),
			sort: true
		},
		{
			id: "action",
			title: intl.fm("report.table.classes.header.action"),
			renderCell: classReport => (
				<ReportLink
					label={intl.fm("report.table.teams")}
					to={`/report/classes/${classReport.classId}`}
				/>
			)
		}
	];

	const csvTitle = `Classes ${moment().format("DD.MM.YYYY HH:mm")}`;
	const csvFilename = `ClassesOverview_${moment().format(
		"DD.MM.YYYY_HH.mm"
	)}.csv`;

	return (
		<ReportTableCard
			tableData={classReports}
			title={
				<div>
					{intl.fm("report.table.classes")}
					<span className={classes.titleButton}>
						<ReportLink
							label={intl.fm("report.table.classes.teamsFromAllClasses")}
							to={"/report/classes/0"}
							noBackground
						/>
					</span>
					<DownloadButton
						tableData={classReports}
						csvTitle={csvTitle}
						csvFilename={csvFilename}
						tableRenderer={tableRenderer}
					/>
				</div>
			}
			color="purple"
			cardIcon={GroupIcon}
			tableRenderer={tableRenderer}
		/>
	);
};

const NumberOfTeachers = ({ classReports, intl }) => {
	const nrOfTeachers = Array.isArray(classReports)
		? classReports.reduce(
			(sum, classReport) => sum + classReport.teacherCount,
			0
		  )
		: 0;

	return (
		<InfoCard
			icon={SchoolIcon}
			iconColor="purple"
			title={intl.fm("report.card.title.teachers")}
			description={nrOfTeachers}
		/>
	);
};

const NumberOfClassesInfo = ({ classReports, intl }) => {
	const nrOfClasses = Array.isArray(classReports) ? classReports.length : 0;
	const nrOfMembers = Array.isArray(classReports)
		? classReports.reduce((sum, classReport) => sum + classReport.userCount, 0)
		: 0;

	return (
		<InfoCard
			icon={GroupIcon}
			iconColor="purple"
			title={intl.fm("report.card.title.classes")}
			description={`${nrOfClasses}`}
			footer={`${nrOfMembers} ${intl.fm("report.card.title.members")}`}
		/>
	);
};

const mapStateToProps = state => {
	return { classReports: state.api.resources.reportClasses.data };
};

const mapDispatchToProps = dispatch =>
	bindActionCreators({ fetchClasses: reportApi.fetchClasses }, dispatch);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(reportStyle),
	withIntl
)(ClassesReport);

import { percent } from "@hlcr/core/numeric";
import { Button } from "@hlcr/mui/Button";
import { WithIntl, withIntl } from "@hlcr/ui/Intl";
import { Divider, Grid, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import * as React from "react";

import ValidationInput from "components/CustomInput/ValidationInput";
import LevelLabel from "components/LevelLabel/LevelLabel";
import ModalWindow from "components/ModalWindow/ModalWindow";
import { getDateInputValue } from "helper/dateCalc";
import { ASSET_DIFFICULTY_LEVELS, ASSET_TYPES, AssetDifficultyLevel, AssetType, FLAG_TYPES } from "models/Asset";
import { AnyEventUnit, EventUnit, EventUnitChallenge, EventUnitMode, GradingMode, SolvableEventUnit } from "models/EventUnit";

import { EventUnitGradingSection } from "./EventUnitGradingSection";
import { eventUnitModalStyles } from "./eventUnitModalStyle";
import { UpdateEventGradingModeFn, UpdateEventUnitModeFn, UpdateFlagWeightFn, UpdateFullPenaltyMaxGradeFn, UpdateMaxPointsFn, UpdateStepsPenaltyFn } from "./eventUnitModalTypes";
import { EventUnitModeSection } from "./EventUnitModeSection";

/* ****************************************************************************
 * NOTE: this is a class component on purpose: it's much more readable.
 **************************************************************************** */

const MIN_WEIGHT = 0;
const MAX_WEIGHT = 1;
const MIN_POINTS_VALUE = 1;
const INITIAL_UNIT_MODE_STATE = EventUnitMode.COMPETITION_MODE;
const INITIAL_GRADING_MODE_STATE = GradingMode.WRITEUP;
const DEFAULT_STEP_TITLES_PENALTY = 0.15;
const DEFAULT_FULL_PENALTY_MAX_GRADE = 0.5;
const DEFAULT_FLAG_WEIGHT = 0.5;

interface EventUnitModalProps extends WithStyles, WithIntl {
	unit: EventUnit;
	event: any;
	onClose: () => any;
	onSave: (entity?: AnyEventUnit) => void;
}

interface EventUnitModalState {
	unitMode: EventUnitMode;
	stepTitlesPenalty: number;
	fullPenaltyMaxGrade: number;
	gradingMode: GradingMode;
	flagWeight: number;
	maxPoints: number;
	startTime?: string;
	endTime?: string;
}

export const EventUnitModal = withStyles(eventUnitModalStyles)(withIntl(class extends React.Component<EventUnitModalProps, EventUnitModalState> {
	displayName = "EventUnitModal";

	constructor(props: EventUnitModalProps) {
		super(props);
		this.state = this.initializeStateFromUnit();
		this.bindMethods();
	}

	componentDidUpdate(prevProps: EventUnitModalProps) {
		if (prevProps.unit?.id !== this.props.unit?.id) {
			this.setState(this.initializeStateFromUnit());
		}
	}

	render() {
		const { unit, onClose, intl, classes, event } = this.props;
		if (!unit) {
			return null;
		}

		let assetDifficultyLevel;
		let isFlagBased;

		if (AssetType.THEORY !== unit.type) {
			assetDifficultyLevel = (unit as SolvableEventUnit)?.level.name.toUpperCase() as AssetDifficultyLevel;
		}

		if (AssetType.CHALLENGE === unit.type) {
			isFlagBased = FLAG_TYPES[(unit as EventUnitChallenge).goldNuggetType]?.isFlagBased;

		}
		return (
			<ModalWindow
				open={!!unit}
				onClose={onClose}
				title={
					<span>
						{`${intl.fm(ASSET_TYPES[unit.type].title)}: ${unit.title} `}
						{assetDifficultyLevel && <LevelLabel value={intl.fm(ASSET_DIFFICULTY_LEVELS[assetDifficultyLevel].title)} />}
					</span>}
				actionSection={
					<div>
						<Button onClick={onClose} color="defaultNoBackground">
							{intl.fm("common.labels.cancel")}
						</Button>
						<Button onClick={this.handleSave} color="primaryNoBackground">
							{intl.fm("common.labels.save")}
						</Button>
					</div>
				}
				fullWidth={true}
				maxWidth="lg"
				disableOverflow={true}
			>
				<Grid container={true} spacing={5}>
					<Grid item={true} xs={12} className={classes.gridItem}>
						<h3>{intl.fm("manager.eventDetails.units.dateLimit")}</h3>
						<div className={classes.flexRowWrapper}>
							<ValidationInput
								type="date"
								value={getDateInputValue(this.state.startTime)}
								onChange={this.handleStartTimeChange}
								label={intl.fm("common.labels.chooseBeginDate")}
								validations={{ custom: this.validateStartTime }}
								rootClass={classes.inputRoot}
							/>
							<ValidationInput
								type="date"
								value={getDateInputValue(this.state.endTime)}
								onChange={this.handleEndTimeChange}
								label={intl.fm("common.labels.chooseEndDate")}
								validations={{ custom: this.validateEndTime }}
								rootClass={classes.inputRoot}
							/>
						</div>
					</Grid>
					{unit.type !== AssetType.THEORY && (<>
						<Grid item={true} lg={6} xs={12} className={classes.gridItem}>
							<EventUnitGradingSection
								unitType={unit.type}
								gradingMode={this.state.gradingMode}
								scoringMode={event.scoringMode}
								assetIsFlagBased={isFlagBased}
								maxPoints={this.state.maxPoints}
								flagWeight={this.state.flagWeight}
								handleGradingModeChange={this.handleGradingModeChange}
								handleMaxPointsChange={this.handleMaxPointsChange}
								handleFlagWeightChange={this.handleFlagWeightChange}
							/>
						</Grid>
						<Divider orientation="vertical" flexItem style={{ marginLeft: "-1px" }} />
					</>)}
					<Grid item={true} lg={unit.type === AssetType.THEORY ? 12 : 6} xs={12} className={classes.gridItem} style={{ minHeight: 300 }}>
						<EventUnitModeSection
							unitType={unit.type}
							unitMode={this.state.unitMode}
							stepTitlesPenalty={this.state.stepTitlesPenalty}
							fullPenaltyMaxGrade={this.state.fullPenaltyMaxGrade}
							handleUnitModeChange={this.handleUnitModeChange}
							handleStepsPenaltyChange={this.handleStepsPenaltyChange}
							handleFullPenaltyMaxGradeChange={this.handleFullPenaltyMaxGradeChange}
						/>
					</Grid>
				</Grid>
			</ModalWindow>
		);
	}

	handleSave = () => {
		const { unit } = this.props;
		const { startTime, endTime, unitMode, gradingMode, stepTitlesPenalty, fullPenaltyMaxGrade, flagWeight, maxPoints } = this.state;
		this.props.onSave({
			...unit,
			startTime: startTime ?? unit.startTime,
			endTime: endTime ?? unit.endTime,
			mode: unitMode,
			grading: gradingMode,
			stepTitlesPenalty: unitMode === EventUnitMode.STEPS_MODE ? stepTitlesPenalty : undefined,
			fullPenaltyMaxGrade: unitMode !== EventUnitMode.COMPETITION_MODE ? fullPenaltyMaxGrade : undefined,
			flagWeight: gradingMode !== GradingMode.WRITEUP ? flagWeight : 0,
			maxPoints,
		});
	};

	validateStartTime = (value: any, helpText: any) => {
		if (!moment.isMoment(value) || value.isSameOrAfter(this.props.event.startTime)) {
			return true;
		}
		helpText.push(this.props.intl.fm("manager.eventDetails.units.startMustExceedEventStart"));
		return false;
	};

	validateEndTime = (value: string, helpText: any) => {
		if (!moment.isMoment(value) || ((!this.state.startTime || value.isAfter(this.state.startTime)) && value.isAfter(this.props.event.startTime))) {
			return true;
		}
		helpText.push(this.props.intl.fm("manager.eventDetails.units.endMustExceedStart"));
		return false;
	};

	handleStartTimeChange = (value?: string) => {
		this.setState({ startTime: value });
	};

	handleEndTimeChange = (value?: string) => {
		this.setState({ endTime: value });
	};

	handleUnitModeChange: UpdateEventUnitModeFn = (event: React.MouseEvent<HTMLElement>, newUnitMode: EventUnitMode | null) => {
		this.setState({ unitMode: newUnitMode ?? INITIAL_UNIT_MODE_STATE });
	};

	handleStepsPenaltyChange: UpdateStepsPenaltyFn = (value: number) => {
		this.setState({ stepTitlesPenalty: this.percentageValue(value) });
	};

	handleFullPenaltyMaxGradeChange: UpdateFullPenaltyMaxGradeFn = (value: number) => {
		this.setState({ fullPenaltyMaxGrade: this.percentageValue(value) });
	};

	handleGradingModeChange: UpdateEventGradingModeFn = (event: React.MouseEvent<HTMLElement>, newGradingMode: GradingMode | null) => {
		this.setState({ gradingMode: newGradingMode ?? INITIAL_GRADING_MODE_STATE });
	};

	handleMaxPointsChange: UpdateMaxPointsFn = (value: number) => {
		this.setState({ maxPoints: this.pointsValue(value) });
	};

	handleFlagWeightChange: UpdateFlagWeightFn = (value: number) => {
		this.setState({ flagWeight: this.percentageValue(value) });
	};

	private initializeStateFromUnit = (): EventUnitModalState => {
		const { unit } = this.props;

		let maxPoints = 0;

		if (!unit) {
			return {
				unitMode: INITIAL_UNIT_MODE_STATE,
				stepTitlesPenalty: DEFAULT_STEP_TITLES_PENALTY,
				fullPenaltyMaxGrade: DEFAULT_FULL_PENALTY_MAX_GRADE,
				gradingMode: INITIAL_GRADING_MODE_STATE,
				flagWeight: DEFAULT_FLAG_WEIGHT,
				maxPoints: maxPoints,
			};
		}

		let unitMode;
		let stepTitlesPenalty;
		let fullPenaltyMaxGrade;
		let assetDifficultyLevel;
		let gradingMode;
		let flagWeight;

		if (AssetType.THEORY !== unit.type) {
			const solvableUnit = unit as SolvableEventUnit;
			assetDifficultyLevel = solvableUnit.level.name.toUpperCase() as AssetDifficultyLevel;
			const initialPoints = assetDifficultyLevel && ASSET_DIFFICULTY_LEVELS[assetDifficultyLevel]?.initialPoints || 0;

			unitMode = solvableUnit.mode;
			stepTitlesPenalty = solvableUnit.stepTitlesPenalty;
			fullPenaltyMaxGrade = solvableUnit.fullPenaltyMaxGrade;
			maxPoints = solvableUnit.maxPoints ?? initialPoints;

			if (AssetType.CHALLENGE === unit.type) {
				const challengeUnit = unit as EventUnitChallenge;
				gradingMode = challengeUnit.grading ?? INITIAL_GRADING_MODE_STATE;
				flagWeight = challengeUnit.flagWeight ?? DEFAULT_FLAG_WEIGHT;
			}
		}

		return {
			unitMode: unitMode ?? INITIAL_UNIT_MODE_STATE,
			stepTitlesPenalty: stepTitlesPenalty ?? DEFAULT_STEP_TITLES_PENALTY,
			fullPenaltyMaxGrade: fullPenaltyMaxGrade ?? DEFAULT_FULL_PENALTY_MAX_GRADE,
			gradingMode: gradingMode ?? INITIAL_GRADING_MODE_STATE,
			flagWeight: flagWeight ?? DEFAULT_FLAG_WEIGHT,
			maxPoints: maxPoints,
			startTime: unit?.startTime,
			endTime: unit?.endTime,
		};
	};

	private bindMethods = () => {
		this.handleSave = this.handleSave.bind(this);
		this.validateStartTime = this.validateStartTime.bind(this);
		this.validateEndTime = this.validateEndTime.bind(this);
		this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
		this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
		this.handleUnitModeChange = this.handleUnitModeChange.bind(this);
		this.handleStepsPenaltyChange = this.handleStepsPenaltyChange.bind(this);
		this.handleFullPenaltyMaxGradeChange = this.handleFullPenaltyMaxGradeChange.bind(this);
		this.handleGradingModeChange = this.handleGradingModeChange.bind(this);
		this.handleMaxPointsChange = this.handleMaxPointsChange.bind(this);
		this.handleFlagWeightChange = this.handleFlagWeightChange.bind(this);
	};

	private pointsValue = (input: any) => {
		// allow hex inputs like 0xB to be 11
		const value = Number.parseInt(input);

		if (isNaN(value) || value < MIN_POINTS_VALUE) {
			return MIN_POINTS_VALUE;
		}
		return value;
	};

	private percentageValue = (input: any): number => {
		return percent(input, { fallback: MIN_WEIGHT, min: MIN_WEIGHT, max: MAX_WEIGHT });
	};
}));

import React from "react";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teacherApi from "actions/teacher";
import { getShowInfoSetting } from "helper/eventUnit";
import { createMemoize } from "helper/memoize";
import { ChallengeOverview } from "views/Events/Challenge";

const getChallenge = createMemoize((challenges, challengeId) =>
	challenges.find(challenge => challenge.id === challengeId)
);

const getSectionIds = createMemoize(
	challenge =>
		(challenge && challenge.sections && challenge.sections.map(s => s.id)) || []
);

const getEvent = createMemoize((events, challenge) =>
	challenge ? events.find(event => event.id === challenge.eventId) : undefined
);

const filterSteps = createMemoize((steps, sectionIds) =>
	steps.data.filter(s => sectionIds.some(id => id === s.sectionId))
);

const getGradingInstruction = createMemoize(
	(gradingInstructions, challengeId) =>
		gradingInstructions.find(instruction => challengeId === instruction.id)
);

const mapStateToProps = (state, ownProps) => {
	const {
		teacherEvents,
		teacherChallenges,
		teacherSteps,
		teacherGradingInstruction
	} = state.api.resources;

	const challengeId = Number(ownProps.match.params.unitId);
	const challenge = getChallenge(teacherChallenges.data, challengeId);
	const sectionIds = getSectionIds(challenge);

	return {
		isPreview: true,
		challengeId,
		challenge,
		event: getEvent(teacherEvents.data),
		steps: filterSteps(teacherSteps, sectionIds),
		gradingInstruction: getGradingInstruction(
			teacherGradingInstruction.data,
			challengeId
		),
		displayInfo: getShowInfoSetting(ownProps.cookies),
		isLoading:
			teacherEvents.pending || teacherChallenges.pending || teacherSteps.pending
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchChallenge: teacherApi.fetchTeacherChallenge,
			fetchTeacherGradingInstructions:
				teacherApi.fetchTeacherGradingInstructions
		},
		dispatch
	);

const TeacherChallengePreview = compose(
	withCookies,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(ChallengeOverview);

export default TeacherChallengePreview;

let TeacherChallengePreviewBreadCrumb = ({ challenge }) => (
	<span>{challenge ? challenge.title : "Challenge"}</span>
);

const mapStateToPropsBreadCrumb = (state, ownProps) => {
	const challengeId = Number(ownProps.match.params.unitId);

	return {
		challenge: state.api.resources.teacherChallenges.data.find(
			c => c.id === challengeId
		)
	};
};

TeacherChallengePreviewBreadCrumb = connect(mapStateToPropsBreadCrumb)(
	TeacherChallengePreviewBreadCrumb
);

export { TeacherChallengePreviewBreadCrumb };

import { useSelector } from "react-redux";

import managerApi from "actions/manager";
import { User } from "models/User";
import { RootState } from "reducers";
import { EventManagerSummary } from "views/Manager/EventManagerSummary";


interface UserSummaryProps {
}

export const UserSummary = (props: UserSummaryProps) => {
	const loadingSelector = (state: RootState) => state.api.resources.managerTeachers.pending || state.api.resources.managerStudents.pending;
	const teachers: User[] = useSelector((state: RootState) => state.api.resources.managerTeachers.data);
	const students: User[] = useSelector((state: RootState) => state.api.resources.managerStudents.data);

	// @formatter:off
	const marks = [
		{ value: 0, label: "Teachers" ,  quantity: teachers.length },
		{ value: 1, label: "Students" ,  quantity: students.length },
	];
	// @formatter:on

	return <EventManagerSummary
		marks={marks}
		stateInitializers={[ managerApi.fetchManagerTeachers, managerApi.fetchManagerStudents ]}
		loadingSelector={loadingSelector}
	/>;
};

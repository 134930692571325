const addExistingModalStyle = {
	modal: { height: "100%" },
	content: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	filterControl: { padding: 0 },
	filterInput: { width: 300 },
	table: { width: "100%" },
	tableImage: {
		width: 111,
		height: 66,
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat"
	},
	descContainer: {
		display: "flex",
		flexDirection: "column"
	},
	descTitle: {
		fontSize: "1.2em",
		fontWeight: 400
	},
	descSubtitle: {
		fontSize: "0.9em",
		opacity: 0.8
	}
};

export default addExistingModalStyle;

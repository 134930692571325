import { RemoteResourceState, RemoteResourceStatus } from "@hlcr/core/api/RemoteResource";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RemoteResource } from "models/RemoteResource";
import { UserProfile } from "models/User";
import { RootState } from "reducers";
import { fetchPersonalProfile } from "userProfile/actions";


export function useUserProfile() {
	const dispatch = useDispatch();
	const userProfileState = useSelector<RootState, RemoteResourceState<UserProfile>>((state: RootState) => state.remoteResourceReducer.remoteResources[RemoteResource.USER_PROFILE]);
	useEffect(() => {
		if (userProfileState.status === RemoteResourceStatus.INITIALIZED) {
			dispatch(fetchPersonalProfile());
		}
	}, [ userProfileState ]);
	return userProfileState.data;
}

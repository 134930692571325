export const rankingInitState = {
	rankingUsers: {
		pending: false,
		data: []
	},
	rankingTeams: {
		pending: false,
		data: []
	}
};

import { BasicMultiSelect, Data, MultiSelectProps } from "@hlcr/mui/Input/BasicMultiSelect";
import { InputRequirementType } from "@hlcr/mui/Input/InputRequirementType";
import { useIntl } from "@hlcr/ui/Intl";
import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import * as React from "react";


// TODO: check that default property value of index and displayName match the explicitly provided type of I and D
export const MultiSelect = <K, T extends Data<K, I, D>, I extends string = "id", D extends string = "name">(
	{ label, data, inputProps, required = InputRequirementType.OPTIONAL, indexName = "id" as I, displayName = "name" as D }: MultiSelectProps<K, T, I, D>,
) => {
	const intl = useIntl();

	const menuItems = data.map((d) => (
		<MenuItem key={d[indexName]} value={d[indexName]}>
			<Checkbox checked={inputProps.value.indexOf(d[indexName]) > -1} />
			<ListItemText primary={d[displayName]} />
		</MenuItem>
	));

	return <BasicMultiSelect
		label={label}
		data={data}
		inputProps={inputProps}
		required={required}
		indexName={indexName}
		displayName={displayName}
		menuItems={menuItems}
	/>;
};

export default {
	"common.input.required": "{label} *",
	"common.input.required.conditional": "{label} (*)",
	"common.input.required.optional": "{label}",

	"input.select.undefined": "Undefined",

	"gender.male": "Male",
	"gender.female": "Female",
	"gender.diverse": "Diverse",

	"user.profile.tenantPolicyViolated": "Your profile is missing some mandatory information in order to access this instance of Hacking-Lab Cyber Range.",
	"common.labels.accessPolicyViolated": "Access Denied",
	"common.labels.editProfile": "Edit Profile",
	"common.labels.reviewPrivacyPolicy": "Open Privacy Policy",


	"common.notYetImplemented": "This feature is not yet implemented",
	"common.labels.add": "Add",
	"common.labels.edit": "Edit",
	"common.labels.cloneAndAdd": "Clone and Add",
	"common.labels.openExternal": "Open in new Tab",
	"common.labels.action": "Action",
	"common.labels.actions": "Actions",
	"common.labels.goto": "Go to",
	"common.labels.redeem": "Redeem",
	"common.labels.register": "Register",
	"common.labels.comingSoon": "Coming Soon",
	"common.labels.finished": "Finished",
	"common.labels.startsIn": "Starts in: {time}",
	"common.labels.unknown": "UNKNOWN",
	"common.labels.filter": "Filter",
	"common.labels.all": "All",
	"common.labels.submit": "Submit",
	"common.labels.submitAndNext": "Submit and next",
	"common.labels.submitAndBack": "Submit and back",
	"common.labels.cancel": "Cancel",
	"common.labels.clearForm": "Clear form",
	"common.labels.resetForm": "Reset form",
	"common.labels.save": "Save",
	"common.labels.delete": "Delete",
	"common.labels.reset": "Reset",
	"common.labels.confirm": "Confirm",
	"common.labels.disabled": "Disabled",
	"common.labels.restore": "Restore",
	"common.labels.archived": "Archived",
	"common.labels.chooseBeginDate": "Begin date",
	"common.labels.chooseEndDate": "End date",
	"common.labels.freezeTime": "Ranking Freeze (Before end date in minutes)",
	"common.labels.publishRanking": "Force Publish Ranking",
	"common.labels.publishRankingInfo": "Updates the scores on the ranking, regardless of freeze time.",
	"common.labels.beginDate": "Begin date",
	"common.labels.endDate": "End date",
	"common.labels.image": "Image",
	"common.labels.next": "Next",
	"common.labels.back": "Back",
	"common.labels.check": "Check",
	"common.labels.finalize": "Finalize",
	"common.labels.collapseSidebar": "Collapse Sidebar",
	"common.labels.expired": "expired",
	"common.labels.notRunning": "not running",
	"common.labels.token": "Token",
	"common.labels.join": "Join",
	"common.labels.export": "Export",
	"common.labels.decline": "Decline",
	"common.labels.accept": "Accept",
	"common.labels.close": "Close",
	"common.labels.selectAll": "Select All",
	"common.labels.yes": "Yes",
	"common.labels.no": "No",

	"common.confirm.title": "Confirm",
	"common.archiveConfirm.title": "Confirm Archiving",
	"common.restoreConfirm.title": "Confirm restore",
	"common.deleteConfirm.title": "Confirm delete",
	"common.deleteConfirm.message_something": "Are your sure you want to delete {something}?",
	"common.deleteConfirm.message": "Are your sure you want to delete the selected {entityName}?",
	"common.restoreConfirm.message": "Are your sure you want to restore {entityName}?",

	"common.duration.second": "{value, plural, =0 {} =1 {1 second} other {# seconds}}",
	"common.duration.minute": "{value, plural, =0 {} =1 {1 minute} other {# minutes}}",
	"common.duration.hour": "{value, plural, =0 {} =1 {1 hour} other {# hours}}",
	"common.duration.day": "{value, plural, =0 {} =1 {1 day} other {# days}}",
	"common.duration.month": "{value, plural, =0 {} =1 {1 month} other {# months}}",

	"navigation.entries.events": "Events",
	"navigation.entries.teacher": "Teacher",
	"navigation.entries.participants": "Participants",
	"navigation.entries.administration": "Administration",
	"navigation.entries.report": "Personal Report",
	"navigation.entries.classesReport": "Classes Report",
	"navigation.entries.classReport": "Class Report",
	"navigation.entries.teamsReport": "Teams Report",
	"navigation.entries.usersReport": "Users Report",
	"navigation.entries.userReport": "User Report",
	"navigation.entries.manager": "Manager",
	"navigation.entries.eventManagement": "Event Management",
	"navigation.entries.teamsManagement": "Teams Management",
	"navigation.entries.vmsManagement": "VM Management",
	"navigation.entries.usersManagement": "Users Management",
	"navigation.entries.classesManagement": "Classes Management",
	"navigation.entries.global-chat": "Global Chat",
	"navigation.entries.help": "Help",
	"navigation.entries.user.account": "Account",
	"navigation.entries.user.profile": "Profile",
	"navigation.entries.user.logout": "Logout",
	"navigation.entries.user.inbox": "Inbox",

	"asset.difficulty.novice": "Novice",
	"asset.difficulty.easy": "Easy",
	"asset.difficulty.medium": "Medium",
	"asset.difficulty.hard": "Hard",
	"asset.difficulty.leet": "Leet",

	"messaging.inbox.composeNew": "New Message",
	"messaging.compose.send": "Send",
	"messaging.compose.cancel": "Cancel",

	"messaging.compose.title": "New Message",
	"messaging.compose.label.subject": "Subject",
	"messaging.compose.label.assets": "Linked Assets",
	"messaging.compose.label.events": "Linked Events",
	"messaging.compose.label.messageBody": "Message",
	"messaging.compose.confirmSendEveryoneTitle": "Send to everyone?",
	"messaging.compose.confirmSendEveryoneMessageTeacher": "You are about to send this message to all users participating in any event you are teaching.",
	"messaging.compose.confirmSendEveryoneMessageManager": "You are about to send this message to all users participating in any active event.",

	"events.nodata": "No events here",
	"events.redeem.accessToken": "Enter Access Code",
	"events.redeem.modal.title": "Redeem Code {code}",
	"events.redeem.modal.eventImage.alt": "Event title image",
	"events.redeem.modal.message.invalid": "This code seems to be invalid or already redeemed.",
	"events.redeem.modal.message.support": "If you think this is a mistake, please contact ",
	"events.redeem.modal.input.explanation": "Enter your event access code below.",
	"events.redeem.modal.input.label": "Event Access Code",
	"events.redeem.modal.success": "Thank you for joining the event!",
	"events.redeem.modal.alreadyJoined": "You have already joined the event.",

	"event.join.selectModal.title": "How do you want to join?",
	"event.join.selectModal.joinUser": "Join as a user:",
	"event.join.selectModal.joinTeam": "Join as a team:",

	"event.preview": "Preview",
	"event.table.showSolved": "Show Solved",
	"event.table.sortOrder": "#",
	"event.table.name": "Name",
	"event.table.categories": "Categories",
	"event.table.level": "Level",
	"event.table.grading": "Grading",
	"event.table.points": "Points",
	"event.table.mode": "Mode",
	"event.table.solutions": "Solving Progress",
	"event.table.solutions.tooltip": "Submitted Solution / Opened Unit / Total Participants",
	"event.table.grading.tooltip": "No open solutions",

	"event.ranking.title": "Event Ranking",
	"event.ranking.open": "Open Ranking",
	"event.ranking.close": "Close Ranking",

	"tenacious.warning.message.event.delete": "You are about to remove {identifier} including all solutions handed in by the students.",
	"tenacious.warning.message.delete": "You are about to remove {identifier} including all solutions handed in by the students. Once removed, it CANNOT be restored! Are you ABSOLUTELY sure?",
	"tenacious.warning.message.archive": "You are about to archive {identifier} including all solutions handed in by the students. Once archived, it CANNOT be restored! Are you ABSOLUTELY sure?",
	"tenacious.warning.message.reset": "You are about to reset {identifier}. This will remove all solutions handed in by the students. Once reset, it CANNOT be restored! Are you ABSOLUTELY sure?",
	"tenacious.warning.message.prevent": "To prevent accidental actions we ask you to confirm your intention.",
	"tenacious.warning.message.pleaseType": "Please type",
	"tenacious.warning.message.toProceed": "to proceed or close this modal to cancel.",

	"theory.name": "Theory",

	"tenant.admin.route.root": "Tenant Admin",
	"tenant.admin.route.email": "Email Configuration",
	"tenant.admin.route.configuration": "General Configuration",
	"tenant.admin.route.accessPolicy": "Access Policies",

	"tenant.admin.config.tenantName": "Tenant Name",
	"tenant.admin.config.baseUrl": "Base URL",

	"tenant.admin.email.sendTestMail": "Send Test Mail...",
	"tenant.admin.email.moveUp": "Move up",
	"tenant.admin.email.moveDown": "Move down",

	"tenant.admin.email.testEmailTitle": "Send Test Mail",
	"tenant.admin.email.testEmailRecipientAddress": "Recipient E-Mail",

	"tenant.admin.email.editTitle": "Edit Email Configuration",
	"tenant.admin.email.summary": "Summary",

	"tenant.admin.email.sortOrder": "Priority",
	"tenant.admin.email.disabled": "Disabled",
	"tenant.admin.email.host": "Host",
	"tenant.admin.email.port": "Port",
	"tenant.admin.email.from": "From",
	"tenant.admin.email.fromDisplayName": "From Display Name",
	"tenant.admin.email.replyTo": "Reply To",
	"tenant.admin.email.replyToDisplayName": "Reply To Display Name",
	"tenant.admin.email.envelopeFrom": "Envelope From",
	"tenant.admin.email.enableSsl": "Enable SSL",
	"tenant.admin.email.enableStartTls": "Enable StartTLS",
	"tenant.admin.email.authenticationType": "Auth",
	"tenant.admin.email.username": "Username",
	"tenant.admin.email.password": "Password",
	"tenant.admin.email.token": "Token",

	"smtp.auth.type.none": "None",
	"smtp.auth.type.basicAuth": "Basic Auth",
	"smtp.auth.type.xoauth2": "XOAuth2",

	"asset.type.challenge": "Challenge",
	"asset.type.theory": "Theory",
	"asset.type.quiz": "Quiz",

	"challenge.name": "Challenge",
	"solution.status": "Solution Status",
	"solution.name": "Solution",
	"challenge.titles.event": "Event",
	"challenge.titles.category": "Category",
	"challenge.titles.resources": "Resources",
	"challenge.titles.description": "Task",
	"challenge.titles.solution": "Solution History",
	"challenge.level.unknown": "UNKNOWN LEVEL",
	"challenge.nodata.content": "no data",
	"challenge.nodata.resources": "no resources",
	"challenge.nodata.properties": "no properties",
	"challenge.nodata.description": "no description yet",
	"challenge.description.step": "Step",
	"challenge.comment.accepted": "Accepted",
	"challenge.comment.submitted": "Submitted",
	"challenge.comment.reviewed": "Reviewed",
	"challenge.comment.newSolution.title": "Submit new Solution",
	"challenge.comment.newSolution.titleTeacher": "Submit new {what}",

	"challenge.mode.training": "Training",
	"challenge.mode.test": "Test",

	"challenge.properties": "Properties",

	"challenge.resource.start": "Start",
	"challenge.resource.stop": "stop",
	"challenge.resource.download": "Download",
	"challenge.resource.open": "Open",
	"challenge.resource.showProps": "Show properties",
	"challenge.resource.expiresOn": "Expires on",
	"challenge.resource.expiresIn": "Expires in",
	"challenge.resource.expired": "expired",

	"challenge.steps_mode.loadSteps": "Load Steps",
	"challenge.steps_mode.loadingSteps": "Loading Steps",
	"challenge.steps_mode.acceptPenalty": "Accept Penalty",
	"challenge.steps_mode.sectionPenaltyMessage": "Do you want to load the steps for this section? This will cost you {penalty} of the max. possible points!",
	"challenge.steps_mode.stepPenaltyMessage": "Do you want to load this step? This will cost you {penalty} of the max. possible points!",

	"quiz.name": "Quiz",
	"quiz.info.endTime": "End time",
	"quiz.info.finishTime": "Finish",
	"quiz.info.solved": "Solved",
	"quiz.shortAnswer.label": "Your answer",
	"quiz.shortAnswer.possibleAnswers": "Possible answers",
	"quiz.longAnswer.label": "Your answer",
	"quiz.longAnswer.gradingInstruction": "Grading Instruction",
	"quiz.comments": "Comments and Grading",
	"quiz.finish": "Finish Quiz",
	"quiz.next.question": "Next Question",
	"quiz.summary.end": "You have reached the end of this quiz.",
	"quiz.summary.check": "Feel free to check your answers or hand in your solutions.",

	"quiz.answerGrader.title": "Grade Answer",
	"quiz.answerGrader.right": "Right",
	"quiz.answerGrader.wrong": "Wrong",

	"teacher.table.title": "Events Overview",
	"teacher.table.name": "Event",
	"teacher.table.duration": "Duration",
	"teacher.table.solutions": "Solutions",
	"teacher.table.participants": "Participants",
	"teacher.table.events": "Events",
	"teacher.table.units": "Units",

	"teacher.solutions.noResources": "No resources",
	"teacher.event.noResources": "No resources or none matching filters",
	"teacher.event.needsCorrection": "Only Open Solutions",
	"teacher.event.nextSolution": "Next",
	"teacher.event.view": "View",
	"teacher.event.preview": "Preview",
	"teacher.participants.userProfile": "Profiles",
	"teacher.participants.userProgress": "Progress",
	"teacher.participants.submitted": "Submitted",
	"teacher.participants.totalPoints": "Total Points",
	"teacher.participants.examDuration": "Exam Duration",

	"teacher.event.notFetched": "Event not yet available",
	"teacher.event.solutions.title": "Solutions",
	"teacher.event.users": "User",
	"teacher.event.openSolutions": "Open Solutions",
	"teacher.event.autoReload": "Reload solutions every {seconds} seconds",
	"teacher.event.eventStatus": "Event Status",
	"teacher.event.solution.newComment.title": "Submit new grading",

	"teacher.feedback.bulk": "Bulk Feedback",
	"teacher.feedback.bulk.modal.title": "Bulk Feedback for Unit \"{unit}\"",

	"teacher.grading.grade": "grading",
	"teacher.grading.comment": "comment",
	"teacher.grading.action": "Grade Solutions",
	"teacher.grading.single": "Single Grading",
	"teacher.grading.bulk": "Bulk Grading",
	"teacher.grading.bulk.modal.title": "Bulk Grading for Unit \"{unit}\"",
	"teacher.grading.bulk.modal.select.users": "Individuals",
	"teacher.grading.bulk.modal.select.teams": "Teams",

	"teacher.proposal.action": "Propose Changes",
	"teacher.proposal.inputLabel": "Propose changes here",
	"teacher.proposal.msg": "Your proposal was successfully sent",

	"user.label.username": "Username",
	"user.label.users": "Users",
	"user.label.email": "Email",
	"user.label.phone": "Phone No",
	"user.label.leader": "Leader",
	"user.label.actions": "Actions",
	"user.label.gender": "Gender",
	"user.label.birthday": "Birthday",
	"user.label.nationality": "Nationality",
	"user.label.organization": "Affiliation",
	"user.label.skills": "Skills",
	"user.label.emailDigest": "Receive notifications by email",
	"user.profile.info": "User Info",
	"user.profile.edit": "Edit Profile",
	"user.noUsers": "No Users to display",

	"user.inbox.title": "Inbox",
	"user.inbox.noMessageSelected": "No message selected",
	"user.inbox.header.sender": "Sender",
	"user.inbox.header.receivedDateTime": "Date",
	"user.inbox.header.subject": "Subject",
	"user.inbox.header.actions": "Actions",
	"user.inbox.selectedMessages": "Message(s) selected",
	"user.inbox.newGradingSubject": "New Grading available",
	"user.inbox.newGradingBody": "You received a new grading on a challenge you solved.",
	"user.inbox.newSolutionSubject": "New Solution available",
	"user.inbox.newSolutionBody": "You received a new solution which is ready to be graded by you.",
	"user.inbox.markAll.question": "Do you want to mark all messages as read, or only the selected ones?",
	"user.inbox.markAll.labelYes": "Mark all",
	"user.inbox.markAll.labelNo": "Only selected",
	"user.inbox.deleteAll.question": "Do you want to delete all messages, or only the selected ones?",
	"user.inbox.deleteAll.labelYes": "Delete all",
	"user.inbox.deleteAll.labelNo": "Only selected",

	"teamOverview.table.memberNotes": "TeamNotes",
	"teamOverview.selectTeam": "Select Team",

	"team.titles.overview": "Teams",
	"team.titles.general": "General",
	"team.titles.users": "Users",
	"team.titles.memberToken": "Member Token",
	"team.titles.join": "Join Team?",
	"team.noResources": "No teams to display",
	"team.label.name": "Name",
	"team.label.description": "Description",
	"team.label.class": "Class",
	"team.label.membercount": "Members",
	"team.label.member": "Member",
	"team.label.leader": "Leader",
	"team.label.actions": "Actions",
	"team.label.regenerate": "Regenerate",
	"team.label.join": "Join",
	"team.placeholder.selectLeader": "Select Leader",
	"team.placeholder.selectClass": "Select Class",
	"team.placeholder.addUser": "Add User",
	"team.members.noResources": "No team members available",
	"team.members.promoteLeader": "Promote Leader",
	"team.members.promoteLeader.warning": "There can only be one leader. Are you sure you want to hand over the leadership?",
	"team.redeemMemberToken": "Redeem Member Token",

	"participant.teacherNotes": "Teacher Notes",
	"teamMember.teamNotes": "Team Notes",
	"teamMember.notes": "Notes",

	"event.state.pendent": "Upcoming",
	"event.state.running": "Running",
	"event.state.finished": "Past",

	"flag.type.static": "Static Flag",
	"flag.type.dynamic": "Dynamic Flag",
	"flag.type.none": "No Flag",
	"grading.mode.writeup": "Writeup",
	"grading.mode.flag": "Flag",
	"grading.mode.writeup_flag": "Writeup + Flag",
	"grading.mode.manual": "Manual",
	"grading.mode.criteria": "Criteria",
	"unit.mode.training_mode": "Training",
	"unit.mode.steps_mode": "Optional Steps",
	"unit.mode.competition_mode": "Competition",

	"solution.state.unsolved": "Unsolved",
	"solution.state.initialised": "Initialised",
	"solution.state.submitted": "Submitted",
	"solution.state.waiting_for_teacher": "Waiting for Teacher",
	"solution.state.rejected": "Rejected",
	"solution.state.partial_points": "Partial Points",
	"solution.state.full_points": "Full Points",
	"solution.state.graded": "Graded",
	"solution.state.visited": "Visited",
	"solution.state.waiting_for_participant": "Waiting for Participant",
	"solution.state.needs_examination": "Needs Examination",
	"solution.state.pending_coexamination_by_others": "Coexamination By Others",

	"solution_success.state.correct": "Completed",
	"solution_success.state.partially_correct": "Improvable",
	"solution_success.state.rejected": "Rejected",
	"solution_success.state.pending": "Pending",
	"solution_success.state.undefined": "Not solved yet",

	"solution_comment.state.submitted.flag": "Flag Submitted",
	"solution_comment.state.submitted.writeup": "Writeup Submitted",
	"solution_comment.state.submitted.flag_and_writeup": "Flag & Writeup Submitted",
	"solution_comment.state.commented": "Commented",
	"solution_comment.state.flag.rejected": "Flag Rejected",
	"solution_comment.state.flag.accepted": "Flag Accepted",
	"solution_comment.state.writeup.rejected": "Writeup Rejected",
	"solution_comment.state.writeup.partial_points": "Partial Points for Writeup",
	"solution_comment.state.writeup.full_points": "Full Points for Writeup",
	"solution_comment.snapshot_information": "Current Solution State",

	"quizSolution.state.corrected": "Corrected",
	"quizSolution.state.in_progress": "In progress",
	"quizSolution.state.manually_corrected": "Manually corrected",

	"solution.history.solutionInstr": "Explain your solution here (Markdown supported)",
	"solution.history.maxCommentLength": "maximum of 2000 chars ({usedCharCount} used)",
	"solution.history.goldNugget.helper": "Write down the Flag or Codeword here",
	"solution.history.markdown.disable": "Disable Markdown",

	"solution.gradinginstruction.title": "Grading Instructions",
	"solution.gradinginstruction.userFlag": "Valid Flags",
	"solution.gradinginstruction.open": "Open Grading Instructions",
	"solution.gradinginstruction.close": "Close Grading Instructions",

	"teacher.event.solution.name": "Name",
	"teacher.event.solution.title": "Title",
	"teacher.event.solution.status": "Status",
	"teacher.event.solution.times": "Times",
	"teacher.event.solution.percent": "Pct",
	"teacher.event.solution.points": "Points",
	"teacher.event.solution.date": "Date",

	"teacher.event.solution.action.grade": "View / Grade",
	"teacher.event.quizsolution.action.reopen": "Reopen Quiz",

	"teacher.solution.title": "Solution from",
	"teacher.solution.grading.title": "Teacher grading",
	"teacher.solution.grading.addGrade": "Add Grading",
	"teacher.solution.latestWriteup": "Latest Writeup",
	"teacher.solution.missingPointsData": "Missing points data",
	"teacher.solution.noTeacherComment": "No teacher commented yet",
	"teacher.solution.noUserComment": "No user comment yet",
	"teacher.solution.lastUpdate": "Last update: {date}",
	"teacher.solution.lastGradingUpdate": "Last grade update: {date}",
	"teacher.solution.lastUserSolution": "Last user solution: {date}",
	"teacher.solution.previewChallenge": "Open challenge in new tab",

	"teacher.solution.feedbackInstr": "Your feedback goes here (Markdown supported)",
	"teacher.solution.feedback.title": "Feedback",
	"teacher.solution.grading.flagPoints": "{flagPoints} (Flag)",
	"teacher.solution.grading.writeupPoints": "{writeupPoints} (Writeup)",
	"teacher.solution.grading.totalLabel": "Total: ",
	"teacher.solution.grading.ofMaxPoints": " of {maxPoints} Points",
	"teacher.solution.grading.bulk.modal.title": "Challenge: {challengeName} (Bulk Grading)",
	"teacher.solution.points.flag": "Flag ({flagPoints}/{maxFlagPoints})",
	"teacher.solution.points.writeup": "Writeup ({writeupPoints}/{maxWriteupPoints})",
	"teacher.solution.points.stepsPenalty": "Steps Penalty ({penalty}%)",
	"teacher.solution.points.total": "{points}/{maxPoints}",

	"teacher.solution.grading.acceptWithoutComment": "Accept without comment",
	"teacher.solution.grading.rejectWithoutComment": "Reject without comment",

	"report.card.title.user": "User",
	"report.card.title.events": "Events",
	"report.card.title.challenges": "Challenges",
	"report.card.title.solutions": "Solutions",
	"report.card.title.class": "Class",
	"report.card.title.status": "Status",
	"report.card.title.classes": "Classes",
	"report.card.title.teams": "Teams",
	"report.card.title.team": "Team",
	"report.card.title.teachers": "Teachers",
	"report.card.title.members": "Members",

	"report.card.description.teachers": "teachers",

	"report.table.classes": "Classes",
	"report.table.classes.teamsFromAllClasses": "Teams From All Classes",
	"report.table.classes.teamsFromClass": "Users From All {className} Teams",
	"report.table.classes.header.name": "Name",
	"report.table.classes.header.teams": "Teams",
	"report.table.classes.header.users": "Users",
	"report.table.classes.header.status": "Status",
	"report.table.classes.header.running": "Running",
	"report.table.classes.header.action": "Action",
	"report.table.classes.allClasses": "All Classes",

	"report.table.teams": "Teams",
	"report.table.teams.header.name": "Name",
	"report.table.teams.header.class": "Class",
	"report.table.teams.header.members": "Members",
	"report.table.teams.header.events": "Events",
	"report.table.teams.header.units": "Units",
	"report.table.teams.header.status": "Status",
	"report.table.teams.header.action": "Action",

	"report.table.users": "Users",
	"report.table.users.header.name": "Name",
	"report.table.users.header.team": "Team",
	"report.table.users.header.leader": "Leader",
	"report.table.users.header.solutions": "Solutions",
	"report.table.users.header.status": "Status",
	"report.table.users.header.action": "Action",

	"report.table.event": "Event",
	"report.table.challenge.header.event": "Event",
	"report.table.challenge.header.user": "User",
	"report.table.challenge.header.name": "Name",
	"report.table.challenge.header.state": "State",
	"report.table.challenge.header.status": "Status",
	"report.table.challenge.header.last_solution": "Last Solution",
	"report.table.challenge.header.action": "Action",
	"report.table.challenge.summarize": "Summarize by User and Event",
	"report.table.challenge.unsummarize": "Unsummarize",

	"report.table.allEvents": "All Events",
	"report.table.noData": "No Report Data available",

	"report.card.events.mandatory": "mandatory",

	"manager.eventOverview.searchPlaceholder": "Filter Events",
	"manager.eventOverview.showPassedEvents": "Show Past Events",
	"manager.eventOverview.showArchivedEvents": "Show Archived Events",
	"manager.eventOverview.editEvent": "Edit",
	"manager.eventOverview.newEvent.title": "Create new Event",
	"manager.eventOverview.newCurriculum.title": "Create new Curriculum",
	"manager.eventOverview.newEvent.copyImage": "Copy existing Image",
	"manager.eventOverview.date.startOnly": "Starting {startDate}",
	"manager.eventOverview.date.endOnly": "Ends {endDate}",
	"manager.eventOverview.date.startAndEnd": "{startDate} - {endDate}",
	"manager.eventOverview.date.open": "Open End",

	"manager.eventDetails.title": "Manage Event Details",
	"manager.eventDetails.breadCrumb": "Event Details",
	"manager.eventDetails.event.title": "Event",

	"manager.table.noData": "No Data yet",

	"manager.eventDetails.units.title": "Units",
	"manager.eventDetails.units.published": "Published",
	"manager.eventDetails.units.add": "Add Units",
	"manager.eventDetails.units.table.name": "Name",
	"manager.eventDetails.units.table.visibility": "Published",
	"manager.eventDetails.units.table.summary": "Summary",
	"manager.eventDetails.units.table.autocheck": "Autocheck",
	"manager.eventDetails.units.table.grading": "Grading",
	"manager.eventDetails.units.table.mode": "Mode",
	"manager.eventDetails.units.table.flagWeight": "Flag weight",
	"manager.eventDetails.units.table.maximumGrade": "Maximum Grade",
	"manager.eventDetails.units.table.flagWeightPercentage": "Flag weight",
	"manager.eventDetails.units.table.initialStepsPenalty": "Steps title penalty",
	"manager.eventDetails.units.table.fullPenaltyMaximumGrade": "Maximum grade after all steps viewed",
	"manager.eventDetails.units.table.trainingMode": "Training Mode",
	"manager.eventDetails.units.table.level": "Level",
	"manager.eventDetails.units.table.maxPoints": "Max Points",
	"manager.eventDetails.units.table.maxPoints.dynamic": "Automatically set in dynamic scoring mode",
	"manager.eventDetails.units.table.maxPoints.explanation.static": "You can adjust the maximum points based on the difficulty.",
	"manager.eventDetails.units.table.maxPoints.explanation.dynamic": "In dynamic scoring mode, all challenges are weighted equally and the maximum possible points are calculated based on the percentage of participants who were able to solve a challenge. Points range from 75 - 500 and are automatically adjusted for new solutions or participants.",
	"manager.eventDetails.units.table.published": "Published",
	"manager.eventDetails.units.table.actions": "Actions",
	"manager.eventDetails.units.overview.stepTitlesPenalty": "Titles",
	"manager.eventDetails.units.overview.fullPenaltyMaximumGrade": "All steps",
	"manager.eventDetails.units.overview.maxGrade": "Max grade",
	"manager.eventDetails.units.addChallenge": "Add Challenge...",
	"manager.eventDetails.units.addQuiz": "Add Quiz...",
	"manager.eventDetails.units.addTheory": "Add Theory...",
	"manager.eventDetails.units.dateLimit": "Date Limit",
	"manager.eventDetails.units.changeDate": "Change date",
	"manager.eventDetails.units.unsetDates": "Unset dates",
	"manager.eventDetails.units.startMustExceedEventStart": "Unit start must exceed event start.",
	"manager.eventDetails.units.endMustExceedStart": "Unit end must exceed start.",

	"manager.eventDetails.units.grading.maxGradeWithAllTitlesShown": "Maximum grade with all step titles viewed",
	"manager.eventDetails.units.grading.gradePenaltyPerStep": "Penalty per step viewed",
	"manager.eventDetails.units.grading.gradePenaltyPerStepFormula": "({maxGrade}% - {fullPenaltyMaxGrade}%) / # sections / # steps",
	"manager.eventDetails.units.grading.maxGradeIfAllStepsViewed": "Maximum grade if all steps viewed",
	"manager.eventDetails.units.grading.pointsForCorrectFlag": "Points for correct flag",
	"manager.eventDetails.units.grading.maxPointsWriteup": "Maximum points awarded for writeup",

	"manager.eventDetails.curriculumEvents.table.name": "Name",
	"manager.eventDetails.curriculumEvents.table.startTime": "Start",
	"manager.eventDetails.curriculumEvents.table.endTime": "End",
	"manager.eventDetails.curriculumEvents.title": "Events",
	"manager.eventDetails.curriculumEvents.archived.title": "Archived Events",
	"manager.eventDetails.curriculumEvents.addEvent": "Add Event",

	"manager.eventDetails.eventQuery.addExistingEventTitle": "Clone Existing Event",
	"manager.eventDetails.eventQuery.queryPlaceholder": "Search Events",
	"manager.eventDetails.eventQuery.minCharQuery": "type at least 3 characters",
	"manager.eventDetails.eventQuery.table.name": "Event",
	"manager.eventDetails.eventQuery.noData": "No events - specify query above",
	"manager.eventDetails.eventQuery.results": "Results",

	"manager.eventDetails.teams.title": "Teams",
	"manager.eventDetails.teams.table.name": "Name",
	"manager.eventDetails.teams.table.class": "Class",
	"manager.eventDetails.teams.table.mandatory": "Mandatory",
	"manager.eventDetails.teams.table.actions": "Actions",
	"manager.eventDetails.teams.add": "Add Team...",

	"manager.eventDetails.users.title": "Users",
	"manager.eventDetails.users.table.name": "Name",
	"manager.eventDetails.users.table.teacher": "Teacher",
	"manager.eventDetails.users.table.registered": "Registered",
	"manager.eventDetails.users.table.actions": "Actions",
	"manager.eventDetails.users.addTeacher": "Add Teacher...",
	"manager.eventDetails.users.addStudent": "Add Student...",
	"manager.eventDetails.users.teacher.overwriteWarning": "Change will apply to all child events",

	"manager.eventDetails.dialog.confirmation.message.scoring": "You made changes on the scoring mode of an already running event. This can affect the ranking. Do you want to save the changes?",
	"manager.eventDetails.dialog.confirmation.yes": "Save Changes",
	"manager.eventDetails.dialog.confirmation.no": "Discard Changes",
	"manager.eventDetails.dialog.confirmation.message.leave": "You have unsaved changes in this event, are you sure you want to leave?",

	"manager.eventDetails.accessTokens.title": "Access Tokens",

	"manager.addUnits.addButton": "{count, plural, =0 {Select Units} =1 {Add 1 Unit} other {Add # Units}}",
	"manager.addUnits.queryPlaceholder": "Filter Units",
	"manager.addUnits.queryHelp": "Search Title, Type, Level & Category",

	"manager.addUnits.table.noData": "No units for this query",
	"manager.addUnits.table.title": "Title",
	"manager.addUnits.table.level": "Level",
	"manager.addUnits.table.category": "Category",

	"staticTokenTable.title": "Static Tokens",
	"staticTokenTable.createDate": "Create Date",
	"staticTokenTable.redemptions": "Event",
	"staticTokenTable.availableRedemptions": "Available",
	"staticTokenTable.tokenValue": "Token",
	"staticTokenTable.edit": "Edit",
	"staticTokenTable.copy": "Copy",
	"staticTokenTable.copySuccess": "Token copied to clipboard.",

	"teacher.entityName": "Teacher",
	"team.entityName": "Team",
	"user.entityName": "User",

	"class.entityName": "Class",
	"class.field.name": "Name",
	"class.field.description": "Description",

	"event.entityName": "Event",
	"event.field.name": "Title",
	"event.field.description": "Description",
	"event.field.published": "Published",
	"event.field.disableStudentCommunication": "Disable student communication",
	"event.field.label.examiners": "Set the number of examiners",
	"event.field.examiners": "Examiners",
	"event.field.ranked": "Enable internal Ranking",
	"event.field.rankedShowNoPointsUsers": "Ranking: Show users with no points",
	"event.field.scoringMode": "Scoring Mode",
	"event.field.scoringMode.settings": "Scoring Mode Settings",
	"event.field.scoringMode.settings.button.simulate": "Simulate",
	"event.field.scoringMode.settings.button.keep": "Keep changes",
	"event.field.scoringMode.settings.button.discard": "Discard changes",
	"event.field.scoringMode.settings.titleSettings": "Settings",
	"event.field.scoringMode.settings.titleSimulation": "Simulation",
	"event.field.scoringMode.settings.dynamicScoringMaximumPoints": "Maximum Points",
	"event.field.scoringMode.settings.dynamicScoringMinimumPoints": "Minimum Points",
	"event.field.scoringMode.settings.dynamicScoringDecayFactor": "Decay Factor",
	"event.field.scoringMode.settings.estimatedParticipantCount": "Expected number of participants",
	"event.field.scoringMode.settings.chart.x": "Number of Solvers",
	"event.field.scoringMode.settings.chart.y": "Awarded Points",
	"event.field.scoringMode.settings.chart.scores": "Scores",
	"event.field.scoringMode.settings.chart.max": "Maximum Points",
	"event.field.scoringMode.settings.chart.min": "Minimum Points",
	"event.field.scoringMode.settings.tooltip.curriculum": "Scoring Mode cannot be set on a Curriculum. Please set it on the child events, containing the challenges.",
	"event.field.accessibility": "Accessibility",
	"event.field.visibility": "Visibility",
	"event.field.timeLimitInSeconds": "Exam duration in seconds",
	"event.exam.startExam": "Start Now",
	"event.exam.running": "Running Exam",
	"event.exam.ended": "Exam ended",
	"event.exam.title": "Exam",
	"event.exam.noTimeLimit": "no time limit",
	"event.notStartedYet": "not started yet",
	"event.notStartedYetText":
		"This event has not started yet. It will start: {startDate}.",
	"event.exam.introduction": "Please start the exam when you're ready.",
	"event.exam.startExamDuration": "You will have {duration} to solve it.",
	"event.exam.startExamNoDuration": "The Event closes at {endDate}.",
	"event.exam.finish": "Finish Exam",
	"event.exam.finishWarning":
		"Are you sure you want to finish the exam? You will not be able to submit solutions anymore!",
	"event.exam.timeTaken": "Time taken",
	"event.privacyPolicy.validFrom": "Valid from",
	"event.privacyPolicy.title": "Privacy Policy",
	"event.privacyPolicy.reviewMode.intro": "Why is there no \"Accept\" button?",
	"event.privacyPolicy.reviewMode.alreadyAccepted": "You have already accepted the privacy policy.",
	"event.privacyPolicy.reviewMode.policyViolated": "You have no access to this event.",
	"event.privacyPolicy.reviewMode.notRunning": "Event is not running (yet).",
	"event.progress.tooltip.label.unitTitle": "Unit Name:",
	"event.progress.tooltip.label.eventTitle": "Event Name:",
	"event.progress.tooltip.label.status": "Solution Status:",
	"event.progress.tooltip.label.percentage": "Received Points:",
	"event.progress.tooltip.label.progress": "Solved Units:",

	"manager.vms.title": "Virtual Machines",
	"manager.vms.name": "Name",
	"manager.vms.state": "State",
	"manager.vms.actions": "Actions",
	"manager.vms.noResources": "No VMs to display",
	"manager.vms.actions.start": "Start VM",
	"manager.vms.actions.stop": "Stop VM",
	"manager.vms.actions.reset": "Reset VM",
	"manager.vms.actions.revert": "Revert VM to Snapshot",

	"manager.classes.title": "Classes",
	"manager.classes.create": "Create Class",
	"manager.classes.details.breadCrumb": "Class details",
	"manager.classes.noResources": "No classes to display",
	"manager.classes.name": "Name",
	"manager.classes.description": "Description",
	"manager.classes.teamCount": "Teams",
	"manager.classes.actions": "Actions",
	"manager.classes.archive": "Archive Class",
	"manager.classes.archive.msg": "Are you sure you want to archive this Class? This will end all events of the class, and set the solutions to read-only.",

	"manager.classes.teams.title": "Teams",
	"manager.classes.teams.table.name": "Name",
	"manager.classes.teams.table.description": "Description",
	"manager.classes.teams.table.actions": "Actions",
	"manager.classes.teams.add": "Add Team...",

	"manager.classes.teachers.title": "Teachers",
	"manager.classes.teachers.table.name": "Name",
	"manager.classes.teachers.table.actions": "Actions",
	"manager.classes.teachers.add": "Add Teacher...",

	"team.field.name": "Name",
	"team.field.description": "Description",
	"manager.teams.create": "Create Team",
	"manager.teams.edit": "Edit Team",
	"manager.teams.archive": "Archive Team",
	"manager.teams.archive.msg": "Are you sure you want to archive this Team?",

	"ranking.noData": "No Data",
	"ranking.users.title": "User Ranking",
	"ranking.teams.title": "Team Ranking",
	"ranking.event": "Event",
	"ranking.table.title": "Table",
	"ranking.table.noData": "No Ranking yet",
	"ranking.chart.noData": "No Chart yet",
	"ranking.chart.title": "Chart",
	"ranking.chart.show": "Show Chart",
	"ranking.chart.hide": "Hide Chart",
	"ranking.userRanking.rank": "Rank",
	"ranking.userRanking.score": "Score",
	"ranking.userRanking.name": "Name",
	"ranking.userRanking.solvedChallenges": "Solved Tasks",
	"ranking.userRanking.userLevel": "Level",
	"ranking.userRanking.gotoMyRanking": "Go To My Result",

	"": "",
};

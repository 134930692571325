import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import InputLabel from "@material-ui/core/InputLabel";
import InfoIcon from "@material-ui/icons/Info";
import moment from "moment/moment";
import * as React from "react";

import { checkHasRole } from "auth/authUtils";
import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import ValidationInput from "components/CustomInput/ValidationInput";
import { CustomSelect } from "components/CustomSelect/CustomSelect";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import { Upload } from "@hlcr/mui/Upload";
import WarningDialog from "components/ModalWindow/WarningDialog";
import TenaciousWarning, { TenaciousAction } from "components/ModalWindow/TenaciousWarning";
import NoData from "components/NoData/NoData";
import { getDateInputValue } from "helper/dateCalc";
import { getImageData } from "helper/manager";
import { EventType } from "models/EventType";
import { AccessibilitySelectList, VisibilitySelectList } from "variables/constants";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import { SCORING_MODE, ScoringMode } from "shared/event/model/BaseEvent";
import SettingsIcon from "@material-ui/icons/Settings";
import { Popover, Tooltip } from "@material-ui/core";
import DynamicScoringSimulationChart from "views/Manager/DynamicScoring/DynamicScoringSimulationChart";
import { ConfirmationDialog } from "dialog/ConfirmationDialog";
import { Prompt } from "react-router";

export default class EventDetails extends React.Component {
	state = {
		event: null,
		eventOriginal: null,
		image: null,
		restoreOpen: false,
		deleteOpen: false,
		scoringModeSettingsOpen: false,
		scoringModeSettingsSimulateParticipantCount: 20,
		scoringModeSettingsTriggerRedraw: 0,
		saveConfirmationDialogOpen: false,
		dynamicScoringMaximumPointsTemp: null,
		dynamicScoringMinimumPointsTemp: null,
		dynamicScoringDecayFactorTemp: null,
		validation: {
			name: true,
			startTime: true,
			endTime: true,
			description: true,
			timeLimitInSeconds: true,
		},
	};

	scoringModeSettingsRef = React.createRef();

	static getDerivedStateFromProps(props, state) {
		if (props.event && (!state.event || props.event.id !== state.event.id)) {
			return {
				event: props.event,
				eventOriginal: JSON.parse(JSON.stringify(props.event)), // we keep a copy of the original event to check if the user has changed sensitive fields
				image: { name: "image" },
			};
		}
		return null;
	}

	getEventIsModified = () => {
		return JSON.stringify(this.state.event) !== JSON.stringify(this.state.eventOriginal);
	};

	render() {
		const { event, intl, classes } = this.props;
		const { validation, saveConfirmationDialogOpen, leaveConfirmationDialogOpen } = this.state;
		const isFullValid = Object.values(validation).reduce((res, value) => res && value, true);

		const isArchived = checkHasRole(HackingLabRole.EVENT_RESTORE) && event && event.archived;

		if (event?.participantCount > 2) {
			this.setState({ scoringModeSettingsSimulateParticipantCount: event.participantCount });
		}

		if (event && (this.state.dynamicScoringMaximumPointsTemp === null || this.state.dynamicScoringMinimumPointsTemp === null || this.state.dynamicScoringDecayFactorTemp === null)) {
			this.setState({
				dynamicScoringMaximumPointsTemp: event.dynamicScoringMaximumPoints,
				dynamicScoringMinimumPointsTemp: event.dynamicScoringMinimumPoints,
				dynamicScoringDecayFactorTemp: event.dynamicScoringDecayFactor,
			});
		}

		return (
			<IconCard
				title={<span>{intl.fm("team.titles.general")}</span>}
				icon={InfoIcon}
				iconColor="purple"
				content={event ? this.inputForm() : <NoData />}
				footer={
					<div className={classes.actions}>
						<Button onClick={this.resetForm} color="defaultNoBackground">
							{intl.fm("common.labels.resetForm")}
						</Button>
						<Button onClick={this.submit} color="infoNoBackground" disabled={!isFullValid}>
							{intl.fm("common.labels.save")}
						</Button>
						<Button onClick={this.confirmDelete} color="dangerNoBackground">
							{intl.fm("common.labels.delete")}
						</Button>
						{isArchived && (
							<Button onClick={this.confirmRestore} color="infoNoBackground">
								{intl.fm("common.labels.restore")}
							</Button>
						)}
						<Button hidden={true} onClick={this.exportEvent} color={"defaultNoBackground"}>
							{intl.fm("common.labels.export")}
						</Button>
						<TenaciousWarning
							action={TenaciousAction.DELETE}
							onConfirm={this.delete}
							onClose={this.cancelDelete}
							entity={this.markedForDeletion()}
							entityIdentifier={(entity) => `${entity.name}`}
							messageLocalizationId="tenacious.warning.message.event.delete"
						/>
						{isArchived && (
							<WarningDialog
								title={intl.fm("common.restoreConfirm.title")}
								message={intl.fm("common.restoreConfirm.message", null, { entityName: event.name })}
								onConfirm={this.restore}
								onClose={this.cancelRestore}
								open={this.state.restoreOpen}
								entityName={intl.fm("event.entityName")}
							/>
						)}
						{saveConfirmationDialogOpen && (
							<ConfirmationDialog
								message={intl.fm("manager.eventDetails.dialog.confirmation.message.scoring")}
								onClose={(hasClickedYes) => this.onSaveConfirmed(hasClickedYes)}
								yesButtonLabel={intl.fm("manager.eventDetails.dialog.confirmation.yes")}
								noButtonLabel={intl.fm("manager.eventDetails.dialog.confirmation.no")}
							></ConfirmationDialog>
						)}
						<Prompt when={this.getEventIsModified()} message={intl.fm("manager.eventDetails.dialog.confirmation.message.leave")} />
					</div>
				}
			/>
		);
	}

	submit = () => {
		const { event } = this.state;
		const eventWithTimeLimit = {
			...event,
			timeLimitInSeconds: event.exam ? event.timeLimitInSeconds : null,
		};

		const currentTime = new Date();
		const eventStartTime = new Date(eventWithTimeLimit.startTime);

		if (
			currentTime > eventStartTime &&
			(eventWithTimeLimit.dynamicScoringMaximumPoints !== this.state.eventOriginal.dynamicScoringMaximumPoints ||
				eventWithTimeLimit.dynamicScoringMinimumPoints !== this.state.eventOriginal.dynamicScoringMinimumPoints ||
				eventWithTimeLimit.dynamicScoringDecayFactor !== this.state.eventOriginal.dynamicScoringDecayFactor)
		) {
			// if we need additional confirmation, we just show the dialog, the save is handled in the callback
			this.setState({ saveConfirmationDialogOpen: true });
		} else {
			// if no confirmation is needed, we save the stuff directly
			this.saveEventChanges();
		}
	};

	saveEventChanges = () => {
		const { image, event } = this.state;
		const { updateManagerEvent, updateManagerCurriculumEvent, parentId } = this.props;

		const imageData = getImageData(image);
		const eventWithTimeLimit = {
			...event,
			timeLimitInSeconds: event.exam ? event.timeLimitInSeconds : null,
		};

		if (parentId) {
			updateManagerCurriculumEvent(eventWithTimeLimit, imageData);
		} else {
			updateManagerEvent(eventWithTimeLimit, imageData);
		}

		this.setState({ eventOriginal: JSON.parse(JSON.stringify(event)) });
	};

	onSaveConfirmed = (hasClickedYes) => {
		// This is the callback function of the save confirmation dialog
		if (hasClickedYes) {
			// if the user has clicked yes, we save the stuff
			this.saveEventChanges();
		}

		this.setState({ saveConfirmationDialogOpen: false });
	};

	exportEvent = () => {
		const {
			event: { id: eventId },
		} = this.state;
		window.open(`/api/events/${eventId}/export/`, "_blank");
	};

	restore = () => {
		const { restoreManagerEvent } = this.props;
		const { event } = this.state;
		this.setState({ restoreOpen: false });
		if (event) restoreManagerEvent(event);
	};

	publishRanking = () => {
		const { forcePublishRanking } = this.props;
		console.log(this.state.event);
		forcePublishRanking(this.state.event.id);
	};

	cancelRestore = () => {
		this.setState({ restoreOpen: false });
	};

	confirmRestore = () => {
		this.setState({ restoreOpen: true });
	};

	cancelDelete = () => {
		this.setState({ deleteOpen: false });
	};

	confirmDelete = () => {
		this.setState({ deleteOpen: true });
	};

	markedForDeletion = () => {
		const { deleteOpen, event } = this.state;
		if (deleteOpen) {
			return event;
		}
		return undefined;
	};

	delete = () => {
		const { deleteManagerEvent, history } = this.props;
		const { event } = this.state;
		this.setState({ deleteOpen: false });
		if (event)
			deleteManagerEvent(event, () => {
				if (event.type === EventType.CURRICULUM_EVENT) {
					history.push(`/manager/events/${event.parent}`);
				} else {
					history.push("/manager/events");
				}
			});
	};

	resetForm = () => {
		this.setState((_, props) => ({
			event: props.event,
			image: { name: "image" },
		}));
	};

	updateField = (fieldName) => (value, valid) => {
		this.setState((state) => ({
			event: { ...state.event, [fieldName]: value },
			validation: { ...state.validation, [fieldName]: valid },
		}));
	};

	updateFile = (image) => this.setState({ image });

	endDateIsGreater = (value, helpText) => {
		const { event, validation } = this.state;
		if (value === "" || !(value instanceof moment) || (validation.startTime && value.toISOString() > event.startTime)) {
			return true;
		}
		helpText.push("End Date must exceed start Date.");
		return false;
	};

	examinatorCountValidation = (count, helpText) => {
		const valid = count > 0 && count < 10;
		if (!valid) {
			helpText.push("Invalid number of examiners");
		}
		return valid;
	};

	switchIsExam = (event) => {
		const exam = event.target.checked;
		this.setState((state) => ({ event: { ...state.event, exam } }));
	};

	updateVisibility = (event) => this.setState((prevState) => ({ event: { ...prevState.event, visibility: event.target.value } }));

	updateAccessibility = (event) =>
		this.setState((prevState) => ({
			event: {
				...prevState.event,
				accessibility: event.target.value,
			},
		}));

	updateScoringMode = (event) => {
		// as we set the scoring mode to undefined for curriculum events, we need to check if the value is set
		if (event.target.value) {
			this.setState((prevState) => ({ event: { ...prevState.event, scoringMode: event.target.value } }));
		}
	};

	switchRanked = () =>
		this.setState((prevState) => ({
			event: {
				...prevState.event,
				ranked: !prevState.event.ranked,
			},
		}));

	switchRankedShowNoPointsUsers = () =>
		this.setState((prevState) => ({
			event: {
				...prevState.event,
				rankedShowNoPointsUsers: !prevState.event.rankedShowNoPointsUsers,
			},
		}));

	switchPublished = () =>
		this.setState((prevState) => ({
			event: {
				...prevState.event,
				published: !prevState.event.published,
			},
		}));
	toggleDisableStudentCommunication = () =>
		this.setState((prevState) => ({
			event: {
				...prevState.event,
				studentCommunicationDisabled: !prevState.event.studentCommunicationDisabled,
			},
		}));

	onCloseScoringModeSettings = (keepChanges) => {
		// Based on the decision to keep changes, we overwrite the temp fields with the previous or vice versa
		if (keepChanges) {
			this.setState({
				event: {
					...this.state.event,
					dynamicScoringMaximumPoints: this.state.dynamicScoringMaximumPointsTemp,
					dynamicScoringMinimumPoints: this.state.dynamicScoringMinimumPointsTemp,
					dynamicScoringDecayFactor: this.state.dynamicScoringDecayFactorTemp,
				},
			});
		} else {
			this.setState({
				dynamicScoringMaximumPointsTemp: this.state.event.dynamicScoringMaximumPoints,
				dynamicScoringMinimumPointsTemp: this.state.event.dynamicScoringMinimumPoints,
				dynamicScoringDecayFactorTemp: this.state.event.dynamicScoringDecayFactor,
			});
		}

		this.setState({ scoringModeSettingsOpen: false });
	};

	inputForm = () => {
		const { classes, intl } = this.props;
		const { event, image, dynamicScoringMaximumPointsTemp, dynamicScoringMinimumPointsTemp, dynamicScoringDecayFactorTemp } = this.state;

		const startTime = getDateInputValue(event.startTime);
		const endTime = getDateInputValue(event.endTime);

		const timeLimitValue = event.exam && event.timeLimitInSeconds !== undefined ? event.timeLimitInSeconds : "";

		return (
			<form className={classes.detailsForm}>
				<div className={classes.descriptionBox}>
					<ValidationInput label={intl.fm("event.field.name")} value={event.name} onChange={this.updateField("name")} inputClasses={classes.inputOverflow} validations={{ required: true, minLength: 1, maxLength: 100 }} />
					<ValidationInput type="date" value={startTime} onChange={this.updateField("startTime")} label={intl.fm("common.labels.chooseBeginDate")} validations={{ required: true }} />
					<ValidationInput type="date" value={endTime} onChange={this.updateField("endTime")} label={intl.fm("common.labels.chooseEndDate")} validations={{ custom: this.endDateIsGreater }} />
					{event.type !== EventType.CURRICULUM && (
						<>
							<ValidationInput
								type="number"
								value={event.freezeTimeBeforeEndInMinutes}
								onChange={this.updateField("freezeTimeBeforeEndInMinutes")}
								label={intl.fm("common.labels.freezeTime")}
								validations={{ required: false }}
								fullWidth={true}
							/>
							<CustomTooltip title={intl.fm("common.labels.publishRankingInfo")}>
								<Button disabled={!event.freezeTimeBeforeEndInMinutes} style={{ padding: 0, marginTop: 0, marginBottom: "30px" }} color="infoNoBackground" onClick={this.publishRanking}>
									{intl.fm("common.labels.publishRanking")}
								</Button>
							</CustomTooltip>
						</>
					)}
					<div>
						<CustomSelect
							required={true}
							label={intl.fm("event.field.visibility")}
							value={event.visibility}
							menuItems={VisibilitySelectList}
							idField="id"
							displayField="text"
							onChange={this.updateVisibility}
							disabled={event.type === EventType.CURRICULUM_EVENT}
						/>
					</div>
					<div>
						<CustomSelect
							required={true}
							label={intl.fm("event.field.accessibility")}
							value={event.accessibility}
							menuItems={AccessibilitySelectList}
							idField="id"
							displayField="text"
							onChange={this.updateAccessibility}
							disabled={event.type === EventType.CURRICULUM_EVENT}
						/>
					</div>
					<div>
						<div className={classes.scoringMode}>
							<CustomSelect
								disabled={event.type === EventType.CURRICULUM}
								required={event.type !== EventType.CURRICULUM}
								label={intl.fm("event.field.scoringMode")}
								value={event.type === EventType.CURRICULUM ? undefined : event.scoringMode}
								menuItems={SCORING_MODE}
								idField="id"
								displayField="text"
								onChange={this.updateScoringMode}
							/>
							{event.type === EventType.CURRICULUM && (
								<Tooltip style={{ marginLeft: 10, marginTop: 10 }} title={intl.fm("event.field.scoringMode.settings.tooltip.curriculum")} enterDelay={10}>
									<InfoIcon fontSize="small" />
								</Tooltip>
							)}
							{event.type !== EventType.CURRICULUM && (
								<Button
									style={{ display: event.scoringMode === ScoringMode.DYNAMIC ? "unset" : "none" }}
									ref={this.scoringModeSettingsRef}
									title={intl.fm("event.field.scoringMode.settings")}
									onClick={() => this.setState({ scoringModeSettingsOpen: true })}
									color="defaultNoBackground"
								>
									<SettingsIcon fontSize="small" />
								</Button>
							)}
						</div>
						<Popover
							id="scoring-mode-settings"
							className={classes.scoringModeSettings}
							anchorEl={this.scoringModeSettingsRef.current}
							open={this.state.scoringModeSettingsOpen}
							onClose={() => this.onCloseScoringModeSettings(false)}
							disableScrollLock={true}
							anchorOrigin={{
								vertical: "center",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "center",
								horizontal: "left",
							}}
						>
							<div className={classes.scoringModeSettingsContentWrapper}>
								<div className={classes.scoringModeSettingsContent}>
									<h3>{intl.fm("event.field.scoringMode.settings.titleSettings")}</h3>
									<ValidationInput
										type="number"
										label={intl.fm("event.field.scoringMode.settings.dynamicScoringMaximumPoints")}
										value={dynamicScoringMaximumPointsTemp}
										onChange={(newValue) => this.setState({ dynamicScoringMaximumPointsTemp: newValue })}
										validation={{ required: true, min: 0, verifyNumber: true }}
									/>
									<ValidationInput
										type="number"
										label={intl.fm("event.field.scoringMode.settings.dynamicScoringMinimumPoints")}
										value={dynamicScoringMinimumPointsTemp}
										onChange={(newValue) => this.setState({ dynamicScoringMinimumPointsTemp: newValue })}
										validation={{ required: true, min: 0, verifyNumber: true }}
									/>
									<ValidationInput
										type="number"
										label={intl.fm("event.field.scoringMode.settings.dynamicScoringDecayFactor")}
										value={dynamicScoringDecayFactorTemp}
										onChange={(newValue) => this.setState({ dynamicScoringDecayFactorTemp: newValue })}
										validation={{ required: true, min: 1, max: 20, verifyNumber: true }}
									/>
									<div className={classes.scoringModeSettingsButtons}>
										<Button color="default" onClick={() => this.onCloseScoringModeSettings(false)}>
											{intl.fm("event.field.scoringMode.settings.button.discard")}
										</Button>
										<Button color="info" onClick={() => this.onCloseScoringModeSettings(true)}>
											{intl.fm("event.field.scoringMode.settings.button.keep")}
										</Button>
									</div>
									<h3>{intl.fm("event.field.scoringMode.settings.titleSimulation")}</h3>
									<ValidationInput
										label={intl.fm("event.field.scoringMode.settings.estimatedParticipantCount")}
										value={this.state.scoringModeSettingsSimulateParticipantCount}
										onChange={(value) => this.setState({ scoringModeSettingsSimulateParticipantCount: value })}
										validation={{ min: 2, verifyNumber: true }}
									/>
									<Button color="default" onClick={() => this.setState({ scoringModeSettingsTriggerRedraw: this.state.scoringModeSettingsTriggerRedraw + 1 })}>
										{intl.fm("event.field.scoringMode.settings.button.simulate")}
									</Button>
								</div>
								<div className={classes.scoringModeSettingsSimulation}>
									<DynamicScoringSimulationChart
										dynamicScoringMinimumPoints={dynamicScoringMinimumPointsTemp}
										dynamicScoringMaximumPoints={dynamicScoringMaximumPointsTemp}
										dynamicScoringDecayFactor={dynamicScoringDecayFactorTemp}
										simulateParticipantCount={this.state.scoringModeSettingsSimulateParticipantCount}
										triggerRedraw={this.state.scoringModeSettingsTriggerRedraw}
									/>
								</div>
							</div>
						</Popover>
					</div>
					<ValidationInput
						label={intl.fm("event.field.description")}
						value={event.description}
						onChange={this.updateField("description")}
						inputClasses={classes.inputOverflow}
						validations={{ required: true, minLength: 1, maxLength: 500 }}
						inputProps={{
							multiline: true,
							rows: 4,
						}}
						fullWidth
					/>
					<div>
						<InputLabel className={classes.inputLabel}>{intl.fm("event.field.published")}</InputLabel>
						<CustomSwitch checked={event.published} onChange={this.switchPublished} />
					</div>
					<div>
						<InputLabel className={classes.inputLabel}>{intl.fm("event.field.disableStudentCommunication")}</InputLabel>
						<CustomSwitch checked={event.studentCommunicationDisabled} onChange={this.toggleDisableStudentCommunication} />
					</div>
					<div>
						<InputLabel className={classes.inputLabel}>{intl.fm("event.field.label.examiners")}</InputLabel>
						<ValidationInput
							label={intl.fm("event.field.examiners")}
							value={event.examiners === 0 ? 1 : event.examiners}
							onChange={this.updateField("examiners")}
							inputClasses={classes.inputOverflow}
							validations={{ custom: this.examinatorCountValidation }}
							fullWidth
						/>
					</div>
					{event.type !== EventType.CURRICULUM && (
						<>
							<div>
								<InputLabel className={classes.inputLabel}>{intl.fm("event.field.ranked")}</InputLabel>
								<CustomSwitch checked={event.ranked} onChange={this.switchRanked} />
							</div>
							<div>
								<InputLabel className={classes.inputLabel}>{intl.fm("event.field.rankedShowNoPointsUsers")}</InputLabel>
								<CustomSwitch checked={event.rankedShowNoPointsUsers} onChange={this.switchRankedShowNoPointsUsers} />
							</div>
						</>
					)}
					{event.type !== EventType.CURRICULUM && (
						<div>
							<InputLabel className={classes.inputLabel}>{intl.fm("event.field.timeLimitInSeconds")}</InputLabel>
							<CustomSwitch checked={event.exam} onChange={this.switchIsExam} />
							<ValidationInput disabled={!event.exam} value={timeLimitValue} type="number" onChange={this.updateField("timeLimitInSeconds")} validations={{}} />
						</div>
					)}
				</div>
				<Upload type="image" handleProcessedFile={this.updateFile} file={image} imageUrl={`/api/images/manager/events/${event.id}`} rootClassName={classes.imageUploadForm} />
			</form>
		);
	};
}

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { Button } from "@hlcr/mui/Button";
import CustomInput from "components/CustomInput/CustomInput";
import ModalWindow from "components/ModalWindow/ModalWindow";
import { fetchHelp } from "variables/helpPage";

const emptyState = {
	eventId: 0,
	token: "",
	maxRedemptions: 0
};

class StaticTokenModal extends React.Component {
	state = { ...emptyState };

	static getDerivedStateFromProps(props, state) {
		if (!props.isOpen) return emptyState;

		if (props.eventId !== state.eventId)
			return {
				eventId: props.eventId,
				token: props.token,
				maxRedemptions: props.maxRedemptions
			};

		return null;
	}

	render() {
		const { intl, isOpen, handleClose, helpUrl } = this.props;
		const { token, maxRedemptions } = this.state;

		return isOpen ? (
			<ModalWindow
				open={isOpen}
				onClose={handleClose}
				title="Static Token"
				helpLink={fetchHelp(helpUrl, "manager", "createEvent")}
				actionSection={
					<div>
						<Button onClick={this.clearForm} color="defaultNoBackground">
							{intl.fm("common.labels.clearForm")}
						</Button>
						<Button onClick={this.submit} color="infoNoBackground">
							{intl.fm("common.labels.submit")}
						</Button>
					</div>
				}
				fullWidth
			>
				<CustomInput
					labelText="Token"
					inputProps={{
						value: token,
						onChange: this.setToken,
						style: { width: 400 }
					}}
				/>
				<CustomInput
					labelText="Max Redemptions"
					inputProps={{
						value: maxRedemptions,
						onChange: this.setMaxRedemptions,
						style: { width: 400 }
					}}
				/>
			</ModalWindow>
		) : null;
	}

	setToken = event => this.setState({ token: event.currentTarget.value });
	setMaxRedemptions = event =>
		this.setState({ maxRedemptions: event.currentTarget.value });

	clearForm = () => {
		const { token, maxRedemptions } = this.props;
		this.setState({ token, maxRedemptions });
	};

	submit = () => {
		const { token, maxRedemptions } = this.state;
		const { tokenId, updateToken, eventId, handleClose } = this.props;

		updateToken(eventId, tokenId, token, maxRedemptions, () => {
			handleClose();
		});
	};
}

StaticTokenModal.propTypes = {
	intl: PropTypes.object.isRequired,
	eventId: PropTypes.number,
	token: PropTypes.string,
	tokenId: PropTypes.number,
	maxRedemptions: PropTypes.number,
	updateToken: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
	helpUrl: PropTypes.string.isRequired
};

const mapStateToProps = state => ({ helpUrl: state.branding.helpUrl });

export default connect(mapStateToProps)(StaticTokenModal);

import { EnumDetail } from "@hlcr/app/enum/EnumBase";


export enum SmtpAuthenticationType {
	NONE="NONE",
	BASIC_AUTH="BASIC_AUTH",
	XOAUTH2="XOAUTH2",
}

export const SMTP_AUTHENTICATION_TYPE: EnumDetail<SmtpAuthenticationType> = {
	[SmtpAuthenticationType.NONE]: { title: "smtp.auth.type.none" },
	[SmtpAuthenticationType.BASIC_AUTH]: { title: "smtp.auth.type.basicAuth" },
	[SmtpAuthenticationType.XOAUTH2]: { title: "smtp.auth.type.xoauth2" },
};


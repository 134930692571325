import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import moment from "moment/moment";
import React from "react";

import CustomDateTimeInput from "components/CustomInput/CustomDateTimeInput";
import CustomInput from "components/CustomInput/CustomInput";

export const INIT = "INIT";
export const INVALID = "INVALID";
export const VALID = "VALID";
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const numberRegex = /^[0-9]+$/;

const styles = {
	hiddenNumberSpinner: {
		width: 200,
		"-moz-appearance": "textfield",
		"&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
			"-webkit-appearance": "none",
			display: "none",
			margin: 0
		}
	}
};

class ValidationInput extends React.Component {
	state = { state: INIT, helpText: [] };

	onChange = event => {
		const { validations, onChange, type } = this.props;
		const value = type === "date" ? event || "" : event.currentTarget.value;
		const helpText = [];
		const isValid = this.checkAllValidations(value, validations, helpText, type);

		this.setState({ state: isValid ? VALID : INVALID, helpText });

		if (type === "date") this.onChangeDate(value, isValid);
		else onChange(value, isValid);
	};

	checkAllValidations(value, validations, helpText, type) {
		let isValid = true;
		isValid = this.required(value, validations?.["required"], helpText) && isValid;
		isValid =
			this.minLength(value, validations?.["minLength"], helpText) && isValid;
		isValid =
			this.maxLength(value, validations?.["maxLength"], helpText) && isValid;
		isValid = this.date(value, type, helpText) && isValid;
		isValid = this.verifyNumber(value, type, helpText) && isValid;
		isValid =
			(!validations?.custom || validations.custom(value, helpText)) && isValid;
		return isValid;
	}

	onChangeDate = (date, isValid) =>
		this.props.onChange(
			date instanceof moment ? date.toISOString() : date,
			isValid
		);

	static getDerivedStateFromProps(nextProps, oldState) {
		if (
			nextProps.disabled ||
			(oldState.state === INIT && nextProps.initState)
		) {
			return { state: nextProps.initState || INIT, helpText: "" };
		}


		return null;
	}


	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.validateOnLoad && this.state.state === INIT) {
			const helpText = [];
			const isValid = this.checkAllValidations(this.props.value, this.props.validations, helpText, this.props.type);
			this.setState({ state: isValid ? VALID : INVALID, helpText });
		}
	}

	render() {
		const {
			type,
			label,
			value,
			fullWidth,
			formControlProps,
			disabled,
			rootClass,
			validations,
			inputProps,
			dateOnly,
			classes
		} = this.props;
		const { state, helpText } = this.state;

		if (type === "date") {
			return (
				<CustomDateTimeInput
					value={value}
					onChange={this.onChange}
					label={label}
					disabled={disabled}
					rootClass={rootClass}
					error={state === INVALID}
					success={state === VALID}
					dateOnly={dateOnly}
					required={validations?.["required"]}
					helpText={helpText.length > 0 ? helpText.join(" ") : undefined}
				/>
			);
		}
		const inputClasses = cx({ [classes.hiddenNumberSpinner]: type === "number" });
		const inputType = type === "number" ? "text" : type;

		return (
			<CustomInput
				labelText={label}
				classes={rootClass}
				inputProps={{
					onChange: this.onChange,
					type: inputType,
					value,
					inputClasses,
					disabled,
					...inputProps
				}}
				error={state === INVALID}
				success={state === VALID}
				formControlProps={{
					required: validations?.["required"] || false,
					fullWidth,
					...formControlProps
				}}
				helpText={helpText.length > 0 ? helpText.join(", ") : undefined}
			/>
		);
	}

	minLength(value, length, helpText) {
		if (length == null || value.length >= length) {
			return true;
		}
		helpText.push("not long enough");
		return false;
	}

	maxLength(value, length, helpText) {
		if (length == null || !value || value.length <= length) {
			return true;
		}
		helpText.push("too long");
		return false;
	}

	required(value, isRequired, helpText) {
		if (!isRequired || (value !== "" && value != null)) {
			return true;
		}
		helpText.push("required");
		return false;
	}

	date(value, type, helpText) {
		if (type !== "date" || value === "" || value instanceof moment) {
			return true;
		}
		helpText.push("must be date");
		return false;
	}

	verifyEmail(value) {
		if (emailRegex.test(value)) {
			return true;
		}
		return false;
	}

	compare(string1, string2) {
		if (string1 === string2) {
			return true;
		}
		return false;
	}

	verifyNumber(value, type, helpText) {
		if (type !== "number" || value === "" || numberRegex.test(value)) {
			return true;
		}
		helpText.push("must be number");
		return false;
	}

	verifyUrl(value) {
		try {
			new URL(value);
			return true;
		} catch (_) {
			return false;
		}
	}
}
export default withStyles(styles)(ValidationInput);

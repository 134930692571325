import * as React from "react";
import { ChangeEvent } from "react";

export const useInput = <T>(initialValue: T)  => {
	const [ value, setValue ] = React.useState(initialValue);

	return {
		value,
		setValue,
		reset: () => setValue(initialValue),
		inputProps: {
			value,
			onChange: (event: ChangeEvent<any>) => {
				setValue(event.target.value);
			},
		},
	};
};

import { percent, percentage } from "@hlcr/core/numeric";
import { useIntl } from "@hlcr/ui/Intl";
import InputLabel from "@material-ui/core/InputLabel";
import React from "react";

import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import PointSlider from "components/Solution/PointSlider";
import styles from "grading/PointSliderForm/pointSliderForm.module.scss";

export interface PointSliderFormProps {
	maxPoints :number;
	flagPoints :number;
	writeUpWeight :number;
	writeUpGrade: number;
	disabled? :boolean;
	isAddGradeActive :boolean;
	onWriteUpGradeChange :(value :number) => void;
	onIsAddGradeActiveChange :(isActive :boolean) => void;
}

export const PointSliderForm = ({ disabled = false, ...rest } :PointSliderFormProps) => {
	const intl = useIntl();
	const isDisabled = disabled || !rest.isAddGradeActive;

	return (
		<>
			<InputLabel className={styles.addGradeSwitch}>
				{intl.fm("teacher.solution.grading.addGrade")}
				<CustomSwitch checked={rest.isAddGradeActive} onChange={(event :any) => rest.onIsAddGradeActiveChange(event.target.checked)} />
			</InputLabel>
			<PointSlider
				value={percentage(rest.writeUpGrade)}
				maxPoints={rest.maxPoints}
				flagPoints={rest.flagPoints}
				writeupWeight={rest.writeUpWeight}
				onChange={(value :number) => rest.onWriteUpGradeChange(percent(value))}
				disabled={isDisabled}
			/>
		</>
	);
};

import { withStyles } from "@material-ui/core/styles";
import React from "react";

import levelLabelStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/levelLabelStyle";
import levelColors from "../../helper/levelColors";

const LevelLabel = ({ value, classes }) => {
	const levelName = value ? value.toLowerCase() : "unknown";
	return (
		<div
			className={classes.root}
			style={{ backgroundColor: levelColors[levelName] }}
		>
			{levelName}
		</div>
	);
};

export default withStyles(levelLabelStyle)(LevelLabel);

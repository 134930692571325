import { DARK_MODE_SELECTOR } from "@hlcr/mui/theme/hacking-lab.theme";
import { createStyles, makeStyles } from "@material-ui/core";

export const HlcrMarkdownStyle = createStyles({
	root: { maxWidth: 880 },
	inlineCode: {
		padding: "2px 4px",
		// fontSize: "small",
		fontSize: "90%",
		color: "#c7254e",
		backgroundColor: "#f9f2f4",
		borderRadius: "4px",

		[DARK_MODE_SELECTOR]: {
			color: "#abb2bf",
			backgroundColor: "#282c34",
		},
	},
});

export const useHlcrMarkdownStyles = makeStyles(HlcrMarkdownStyle);

import DeleteIcon from "@material-ui/icons/Delete";
import GroupIcon from "@material-ui/icons/Group";
import * as React from "react";

import { Button } from "@hlcr/mui/Button";
import CustomSearchSelect from "components/CustomSelect/CustomSearchSelect";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import WarningDialog from "components/ModalWindow/WarningDialog";

import ManagerTableCard, { ShowHelp } from "./ManagerTableCard";

export default class ManagerEventTeams extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pendingIds: [],
			deleteEntity: undefined
		};
	}

	onSelectTeam = team => {
		const { addManagerEventTeam, eventId } = this.props;
		const eventTeam = {
			teamId: team.id,
			mandatory: false,
			eventId
		};
		addManagerEventTeam(eventTeam);
	};

	changeMandatory = eventTeam => () => {
		const updatedEventTeam = {
			teamId: eventTeam.team.id,
			mandatory: !eventTeam.mandatory,
			eventId: this.props.eventId
		};
		this.props.updateManagerEventTeam(updatedEventTeam, () => this.setPendingTeam(eventTeam.team.id), () => this.resetPendingTeam(eventTeam.team.id));
	};

	cancelDelete = () => {
		this.setState({ deleteEntity: undefined });
	};

	confirmDelete = eventTeam => () => {
		this.setState({ deleteEntity: eventTeam });
	};

	onRemove = eventTeam => () => {
		this.setState({ deleteEntity: undefined });
		this.props.removeManagerEventTeam(eventTeam, () => this.setPendingTeam(eventTeam.id), () => this.resetPendingTeam(eventTeam.id));
	};

	setPendingTeam = id => {
		this.setState(state => ({ pendingIds: [ ...state.pendingIds, id ] }));
	};

	resetPendingTeam = id => {
		this.setState(state => ({ pendingIds: state.pendingIds.filter(val => val !== id) }));
	};

	disabledTeam = eventTeam => eventTeam.team.archived || this.state.pendingIds.includes(eventTeam.team.id);

	render() {
		const { eventTeams, teams, managerClasses, intl, classes } = this.props;

		const tableRenderer = [
			{
				id: "name",
				title: intl.fm("manager.eventDetails.teams.table.name"),
				renderCell: eventTeam => eventTeam.team.name + (eventTeam.team.archived ? ` (${intl.fm("common.labels.archived")})` : ""),
				sort: true
			},
			{
				id: "class",
				title: intl.fm("manager.eventDetails.teams.table.class"),
				renderCell: eventTeam => {
					const managerClass = managerClasses.find(mc => mc.id === eventTeam.team.classId);
					return managerClass ? managerClass.name : "";
				},
				sort: true
			},
			{
				id: "mandatory",
				title: intl.fm("manager.eventDetails.teams.table.mandatory"),
				renderCell: eventTeam => <CustomSwitch disabled={this.disabledTeam(eventTeam)} checked={eventTeam.mandatory} onChange={this.changeMandatory(eventTeam)} />,
				sort: (a, b) => a.mandatory === b.mandatory ? 0 : !a.mandatory && b.mandatory ? 1 : -1
			},
			{
				id: "actions",
				title: intl.fm("manager.eventDetails.teams.table.actions"),
				renderCell: eventTeam => (
					<Button color="danger" customClass={classes.editButton} onClick={this.confirmDelete(eventTeam)}>
						<DeleteIcon className={classes.icon} />
					</Button>
				)
			}
		];

		const filteredTeams = teams.filter(team => !team.archived && !eventTeams.find(eventTeam => eventTeam.team.id === team.id));

		return (
			<ManagerTableCard
				tableData={eventTeams}
				title={
					<div className={classes.titleBox}>
						<span>
							{intl.fm("manager.eventDetails.teams.title")}
							<ShowHelp userRole="manager" action="manageEventTeams" className={classes.helpIcon} />
						</span>

						<CustomSearchSelect
							list={filteredTeams}
							value={null}
							onSelect={this.onSelectTeam}
							displayField="name"
							placeholder={intl.fm("manager.eventDetails.teams.add")}
						/>
						<WarningDialog
							onConfirm={this.onRemove(this.state.deleteEntity)}
							onClose={this.cancelDelete}
							entity={this.state.deleteEntity}
							entityName={intl.fm("team.entityName")} />
					</div>
				}
				specialHighlightingStyles={true}
				tableRenderer={tableRenderer}
				disabledRow={eventTeam => eventTeam.team.archived}
				cardIcon={GroupIcon}
				color="purple"
			/>
		);
	}
}

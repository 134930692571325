import { HackingLabRoute } from "@hlcr/app/enum/EnumRoute";
import { useIntl } from "@hlcr/ui/Intl";
import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import IconCard from "components/Cards/IconCard";


interface RouteSummaryCardProps {
	route: HackingLabRoute;
	onCreate?: (() => void) | string | boolean;
	onSave?: (() => void) | string | boolean;
	onClose?: (() => void) | string | boolean;
	onDelete?: (() => void) | string | boolean;
}

export const RouteDetailCard = ({ route, onCreate, onSave, onClose, onDelete, children }: React.PropsWithChildren<RouteSummaryCardProps>) => {
	const intl = useIntl();

	const title: JSX.Element = route.name
		? typeof route.name === "function"
			? route.name()
			: route.name
		: intl.fm(route.title);

	const getButtonByPathOrFunction = (label: string, pathOrFunction?: (() => void) | string | boolean) => {
		if (typeof pathOrFunction === "boolean" || !pathOrFunction) {
			return;
		}
		if (pathOrFunction instanceof Function) {
			return <Button onClick={pathOrFunction}>{intl.fm(label)}</Button>;
		} else {
			return <Link to={pathOrFunction}><Button>{intl.fm(label)}</Button></Link>;
		}
	};

	return <IconCard
		icon={route.icon}
		iconColor={route.iconColor}
		title={title}
		titleLink={route.path}
		content={children}
		footer={
			<>
				{getButtonByPathOrFunction("common.labels.add", onCreate)}
				{getButtonByPathOrFunction("common.labels.save", onSave)}
				{getButtonByPathOrFunction("common.labels.delete", onDelete)}
				{getButtonByPathOrFunction("common.labels.close", onClose)}
			</>
		}
	/>;
};


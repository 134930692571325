import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";

import RegularCard from "components/Cards/RegularCard";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import { StyledMarkdown } from "@hlcr/ui";
import TimeLineItem from "components/Timeline/TimeLineItem";
import { formatFullLongDate } from "helper/dateCalc";
import { printPercentage } from "helper/pointPrint";
import { withIntl } from "@hlcr/ui/Intl";
import { getSolutionCommentState } from "models/SolutionState";
import { useSelector } from "react-redux";

const SolutionComments = ({ comments, quiz, addAction, intl, classes }) => {
	const [ disableMarkdown, setDisableMarkdown ] = useState(false);
	const darkMode = useSelector((state) => state.ui.darkMode);

	comments.sort((a, b) => (a.creationTime < b.creationTime ? 1 : -1));
	const Elements = comments.map(c => {

		const solutionCommentState = getSolutionCommentState(c);

		const commentEntry = {
			title: intl.fm(solutionCommentState?.title),
			titleColor: solutionCommentState?.color,
			titleMeta:
				solutionCommentState.isAnswer
					? printPercentage(c.points, quiz.maxPoints, "")
					: "",
			body: disableMarkdown ? <pre>{c.comment}</pre> : <StyledMarkdown source={c.comment} darkMode={darkMode} />,
			footerTitle: formatFullLongDate(
				c.creationTime,
				intl.fm("common.labels.unknown")
			),
			footerMeta: c.user && c.user.username
		};
		return <TimeLineItem comment={commentEntry} noArrows simple key={c.id} />;
	});

	if (addAction)
		Elements.splice(
			0,
			0,
			<TimeLineItem
				comment={{
					badgeIcon: EditIcon,
					isDial: true
				}}
				addAction={addAction}
				noArrows
				simple
				key={-3}
			/>
		);
	return (
		<RegularCard
			customCardClasses={classes.higherCardStyle}
			cardTitle={intl.fm("quiz.comments")}
			cardSubtitle={
				<InputLabel className={classes.historyMarkdownLabel}>
					{intl.fm("solution.history.markdown.disable")}
					<CustomSwitch checked={disableMarkdown} onChange={() => setDisableMarkdown(!disableMarkdown)} />
				</InputLabel>}
			content={Elements}
		/>
	);
};

export default withStyles({
	historyMarkdownLabel: {
		marginLeft: "auto",
		marginTop: -15,
		width: "fit-content",
		fontSize: "1em"
	},
	higherCardStyle: { minHeight: 175 }
})(withIntl(SolutionComments));

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import HelpIcon from "@material-ui/icons/Help";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import modalWindowStyles from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/modalWindowStyles";

const ModalWindow = ({
	actionSection,
	classes,
	children,
	open,
	onClose,
	title,
	fullWidth,
	maxWidth,
	closeProtected,
	disableOverflow,
	helpLink
}) => (
	<Dialog
		open={open}
		keepMounted
		fullWidth={fullWidth}
		maxWidth={maxWidth || "sm"}
		disableEscapeKeyDown={closeProtected}
		onClose={onClose}
		classes={{
			paper: cx({
				[classes.disableOverflow]: disableOverflow,
				[classes.modal]: true,
			})
		}}
	>
		<DialogTitle disableTypography className={classes.modalHeader}>
			<IconButton
				className={classes.modalCloseButton}
				key="close"
				color="inherit"
				onClick={onClose}
			>
				<CloseIcon className={classes.modalClose} />
			</IconButton>
			<h4 className={classes.modalTitle}>
				{title}
				{helpLink ? (
					<a href={helpLink} target="_blank" rel="noopener noreferrer">
						<HelpIcon className={classes.modalHelp} />
					</a>
				) : null}
			</h4>
		</DialogTitle>
		<DialogContent
			className={cx(classes.content, { [classes.disableOverflow]: disableOverflow })}
		>
			{children}
		</DialogContent>
		{actionSection && <DialogActions>{actionSection}</DialogActions>}
	</Dialog>
);

ModalWindow.defaultProps = { closeProtected: true };

ModalWindow.propTypes = {
	actionSection: PropTypes.node,
	classes: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.string
	]).isRequired,
	fullWidth: PropTypes.bool,
	maxWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	closeProtected: PropTypes.bool,
	disableOverflow: PropTypes.bool,
	helpLink: PropTypes.string
};

export default withStyles(modalWindowStyles)(ModalWindow);

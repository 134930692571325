import { StyledMarkdown } from "@hlcr/ui";
import { useIntl } from "@hlcr/ui/Intl";
import { Card, CardContent, CardHeader, createStyles, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import { BuildOutlined, CommentOutlined, EventOutlined } from "@material-ui/icons";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link, LinkProps } from "react-router-dom";


import NoData from "components/NoData/NoData";
import { formatFullDate } from "helper/dateCalc";
import { getSubjectOfMessage, isLinkedSolutionComment, isLinkedUnit, MESSAGE_TYPES, MessageLink, MessageReceived, MessageType } from "messaging/model";
import { RootState } from "reducers";


interface InboxMessageDetailProps {
	message?: MessageReceived;
}

function ListItemLink(props: LinkProps) {
	return <ListItem button component={Link} {...props} />;
}

const getPathOfLink = (messageLink: MessageLink) => {
	const teacherPathPrefix = "/teacher";
	const eventSegment = `/events/${messageLink.eventId}`;

	let unitSegment = "";
	let teacherUnitSegment = "";
	let solutionSegment = "";
	let solutionCommentSegment = "";

	if (isLinkedUnit(messageLink)) {
		unitSegment = `/challenges/${messageLink.unitId}`;
		teacherUnitSegment = `/units/${messageLink.unitId}/challenge`;

		if (isLinkedSolutionComment(messageLink)) {
			solutionSegment = `/solutions/${messageLink.solutionId}`;
			solutionCommentSegment = `#comment=${messageLink.solutionCommentId}`;
		}
	}

	if (messageLink.teacher) {
		if (isLinkedSolutionComment(messageLink)) {
			return `${teacherPathPrefix}${eventSegment}${solutionSegment}`;
		}
		if (isLinkedUnit(messageLink)) {
			return `${teacherPathPrefix}${eventSegment}${teacherUnitSegment}`;
		}
		return `${teacherPathPrefix}${eventSegment}`;
	}

	return `${eventSegment}${unitSegment}${solutionCommentSegment}`;
};

const getIconOfLink = (messageLink: MessageLink) => {
	if (isLinkedSolutionComment(messageLink)) {
		return <CommentOutlined />;
	}
	if (isLinkedUnit(messageLink)) {
		return <BuildOutlined />;
	}
	return <EventOutlined />;
};


export const InboxMessageDetail = ({ message }: InboxMessageDetailProps) => {
	const intl = useIntl();
	const classes = useStyles();

	const darkMode = useSelector((state: RootState) => state.ui.darkMode);

	if (message == undefined) {
		return <NoData text={intl.fm("user.inbox.noMessageSelected")} />;
	}

	const getBodyOfMessage = () => {
		if (MessageType.DEFAULT === message.messageType) {
			return message.messageBody;
		}

		return `${intl.fm(MESSAGE_TYPES[message.messageType].messageBody)}\n\n${message.messageBody || ""}`;
	};

	return (
		<Card>
			<CardHeader
				title={getSubjectOfMessage(message, intl)}
				subheader={formatFullDate(Date.parse(message.receivedDateTime))}
			/>
			<CardContent>
				<StyledMarkdown source={getBodyOfMessage()} darkMode={darkMode} />
			</CardContent>
			{
				message.links.length > 0 &&

				<CardContent className={classes.detailsContent}>
					<List className={classes.links}>
						{message.links.map((messageLink, index) => (
							<ListItemLink to={getPathOfLink(messageLink)} key={index}>
								<ListItemIcon>
									{getIconOfLink(messageLink)}
								</ListItemIcon>
								<ListItemText primary={`${messageLink.title}`} />
							</ListItemLink>
						))}
					</List>
				</CardContent>
			}
		</Card>
	);
};

const useStyles = makeStyles(() => createStyles(
	(
		{
			links: {
				width: "100%",
				fontSize: "0.7rem",
			},
			detailsContent: { padding: "0px" },
		}
	)),
);

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import teacherApi from "actions/teacher";
import { createMemoize } from "helper/memoize";
import Quiz from "views/Events/Quiz";

const getQuiz = createMemoize((quizzes, quizId) =>
	quizzes.find(quiz => quiz.id === quizId)
);

const getEvent = createMemoize((events, eventId) =>
	events.find(event => event.id === eventId)
);

const filterGradingInstructions = createMemoize((gradingInstructions, quizId) =>
	gradingInstructions.filter(instruction => instruction.quizId === quizId)
);

const mapStateToProps = (state, ownProps) => {
	const {
		teacherQuizzes,
		teacherEvents,
		questionGradingInstructions
	} = state.api.resources;

	const quizId = Number(ownProps.match.params.unitId);
	const eventId = Number(ownProps.match.params.eventId);

	const quiz = getQuiz(teacherQuizzes.data, quizId);
	const event = getEvent(teacherEvents.data, eventId);
	const showPartialSolution = quiz && quiz.trainingMode;

	return {
		isPreview: true,
		quizClosed: true,
		showAllSolution: true,
		event,
		quizId,
		quiz,
		showPartialSolution,
		gradingInstructions: filterGradingInstructions(
			questionGradingInstructions.data,
			quizId
		),
		isLoading: teacherEvents.pending || teacherQuizzes.pending
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchQuiz: teacherApi.fetchTeacherQuiz,
			fetchQuizGradingInstructions: teacherApi.fetchQuizGradingInstructions
		},
		dispatch
	);

let TeacherQuizPreview = connect(
	mapStateToProps,
	mapDispatchToProps
)(Quiz);

export default TeacherQuizPreview;

let TeacherQuizPreviewBreadCrumb = ({ quiz }) => (
	<span>{quiz ? quiz.title : "Quiz"}</span>
);

const mapStateToPropsBreadCrumb = (state, ownProps) => {
	const quizId = Number(ownProps.match.params.unitId);

	return { quiz: state.api.resources.quizzes.data.find(q => q.id === quizId) };
};

TeacherQuizPreviewBreadCrumb = connect(mapStateToPropsBreadCrumb)(
	TeacherQuizPreviewBreadCrumb
);

export { TeacherQuizPreviewBreadCrumb };

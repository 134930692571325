import { infoColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";

const hiddenContentSliderStyle = {
	container: {
		display: "flex",
		minHeight: "400px",
		alignSelf: "stretch",
		overflow: "hidden",
		marginLeft: "5px"
	},
	sideButton: {
		height: "auto",
		width: 33,
		padding: 10,
		margin: 10,
		border: "1px solid " + infoColor,
		"&:hover": {
			background: infoColor,
			color: "#fff"
		}
	},
	btnWrapper: {
		display: "flex",
		alignItems: "center",
		transform: "rotate(90deg)"
	},
	contentHidden: {
		minWidth: "45px",
		flexShrink: 10
	},
	contentShown: { width: "50%" },
	contentWrapper: {
		marginLeft: "10px",
		wordBreak: "break-all"
	}
};

export default hiddenContentSliderStyle;

import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ContentCopyIcon from "@material-ui/icons/FileCopyOutlined";
import cx from "classnames";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import managerApi from "actions/manager";
import managerEventsStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/manager/managerEventsStyle";
import { checkHasRole } from "auth/authUtils";
import CommonEventCard from "components/Cards/CommonEventCard";
import { Button } from "@hlcr/mui/Button";
import IconButton from "components/CustomButtons/IconButton";
import EventFilterBar, { createQueryFilter } from "components/EventFilterBar/EventFilterBar";
import FloatingActions from "components/FloatingActions/FloatingActions";
import NewEventModal from "components/NewEventModal/NewEventModal";
import { createMemoize } from "helper/memoize";
import { eventDateSorter } from "helper/sorting";
import usePersistedState from "helper/usePersistedState";
import { withIntl } from "@hlcr/ui/Intl";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import { UserEventState } from "variables/constants";
import { formatDateRange } from "shared/event/model/BaseEvent";
import { EventType } from "models/EventType";

const ManagerEvents = ({ fetchManagerEvents, events, intl, classes }) => {
	const persistFor = "ManagerEvents";
	const prefix = `${persistFor}::Filter`;
	const [ showCreateOptions, setShowCreatOptions ] = useState(false);
	const [ isModalOpen, setModalOpen ] = useState(false);
	const [ modalEventType, setModalEventType ] = useState(null);
	const [ modalEventTemplate, setModalEventTemplate ] = useState(null);
	const [ query, setQuery ] = useState("");
	const [ eventFilterSelection, setEventFilterSelection ] = usePersistedState(`${prefix}::EventState`, [ UserEventState.RUNNING, UserEventState.UPCOMING ]);

	useEffect(() => {
		fetchManagerEvents();
	}, [ fetchManagerEvents ]);

	const onCopyEvent = eventId => {
		const event = Object.assign({}, events.find(event => event.id === eventId));
		setModalEventTemplate(event);
		setModalEventType(event.type);
		setModalOpen(true);
	};

	const handlePlusClick = () => {
		setShowCreatOptions(!showCreateOptions);
	};

	const openCurriculumDialog = () => openCreateDialog(EventType.CURRICULUM);

	const openStandaloneEventDialog = () => openCreateDialog(EventType.STANDALONE_EVENT);

	const openCreateDialog = type => {
		setShowCreatOptions(true);
		setModalEventType(type);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalEventTemplate(null);
		setModalOpen(false);
	};

	return (
		<div>
			<EventFilterBar
				query={query}
				onQueryChange={event => setQuery(event.target.value)}
				selection={eventFilterSelection}
				onSelectionChange={setEventFilterSelection}
				includeArchived={checkHasRole(HackingLabRole.EVENT_ADMIN)}
			/>
			<div className={classes.cardGridContainer}>
				{events && events.filter(createQueryFilter(query, eventFilterSelection)).map(
					(event) => event?.id && (
						<div className={classes.cardContainer} key={event.id}>
							<CommonEventCard
								event={event}
								image={`/api/images/manager/events/${event.id}`}
								actions={[
									{
										to: `/manager/events/${event.id}`,
										label: intl.fm("manager.eventOverview.editEvent")
									},
									{
										onClick: onCopyEvent,
										label: <ContentCopyIcon />
									}
								]}
								overlayActions={[
									{
										to: `/manager/events/${event.id}`,
										icon: EditIcon
									},
									{
										onClick: onCopyEvent,
										icon: ContentCopyIcon
									}
								]}
								subTitle={formatDateRange(event, intl)}
								contentText={
									event.archived ? intl.fm("common.labels.archived") : ""
								}
								archived={event.archived}
							/>
						</div>
					)
				)}
			</div>
			<NewEventModal
				eventType={modalEventType}
				eventTemplate={modalEventTemplate}
				isOpen={isModalOpen}
				handleClose={handleModalClose}
			/>
			<FloatingActions>
				<div
					className={cx(classes.smartButtons, { [classes.hide]: !showCreateOptions })}
				>
					<Button
						onClick={openCurriculumDialog}
						color="info"
						customClass={classes.createButton}
					>
						<AddIcon fontSize="small" className={classes.createIcon} />
						Curriculum
					</Button>
					<Button
						onClick={openStandaloneEventDialog}
						color="info"
						customClass={classes.createButton}
					>
						<AddIcon fontSize="small" className={classes.createIcon} />
						Event
					</Button>
				</div>
				<IconButton
					onClick={handlePlusClick}
					color="primary"
					aria-label={intl.fm("common.labels.add")}
					customClass={classes.newButton}
				>
					<AddIcon className={classes.iconPosition} />
				</IconButton>
			</FloatingActions>
		</div>
	);
};

const filterEvents = createMemoize(events => events.filter(event => event.type !== EventType.CURRICULUM_EVENT).sort(eventDateSorter));

const mapStateToProps = state => ({
	events: filterEvents(state.api.resources.managerEvents.data),
	pending: state.api.resources.managerEvents.pending,
	location: undefined,
	match: undefined
});

const mapDispatchToProps = dispatch => bindActionCreators({ fetchManagerEvents: managerApi.fetchManagerEvents }, dispatch);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withIntl,
	withStyles(managerEventsStyle)
)(ManagerEvents);

export const inboxInitState = {
	userInboxMessages: {
		pending: false,
		data: [],
	},
	unreadMessageCount: {
		pending: false,
		data: 0,
	},
};

import { StyledMarkdown } from "@hlcr/ui";
import { useIntl } from "@hlcr/ui/Intl";
import { createStyles, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import NoData from "components/NoData/NoData";
import { getEventUnitImageSrc } from "helper/eventUnit";
import { ASSET_TYPES, AssetType } from "models/Asset";
import { RootState } from "reducers";

import CollapseIconCard from "./CollapseIconCard";

interface Entity {
	id: number;
	title: string;
	content?: string;
}

interface ImageContentCardProps {
	entity: Entity;
	assetType: AssetType;
	onSwitchDisplayInfo: () => void;
	displayInfo: string;
	helpLink?: string;
}

export const ImageContentCard = ({ entity, assetType, onSwitchDisplayInfo, displayInfo, helpLink }: ImageContentCardProps) => {
	const intl = useIntl();
	const classes = useStyles();

	const darkMode = useSelector((state: RootState) => state.ui.darkMode);

	const textContent = entity.content ? (
		<StyledMarkdown source={entity.content} darkMode={darkMode} />
	) : (
		<NoData text={intl.fm("challenge.nodata.content")} />
	);

	return (
		<CollapseIconCard
			icon={ASSET_TYPES[assetType].icon}
			title={intl.fm(ASSET_TYPES[assetType].title)}
			helpLink={helpLink}
			onSwitchDisplayInfo={onSwitchDisplayInfo}
			displayInfo={displayInfo}
			content={
				<div className={classes.imageContainer}>
					<img
						className={classes.image}
						src={getEventUnitImageSrc(assetType, entity.id)}
						alt={entity.title}
					/>
					{textContent}
				</div>
			}
			sumaryContent={textContent}
		/>
	);
};

const useStyles = makeStyles((theme) => createStyles(
	({
		imageContainer: { overflow: "hidden" },
		image: {
			borderRadius: 6,
			overflow: "hidden",
			width: "100%",
			maxWidth: 400,
			maxHeight: 240,
			marginBottom: 20,
			objectFit: "cover",
		},
	})),
);

import { EnumBase, EnumDetail } from "@hlcr/app/enum/EnumBase";

export enum Country {
	AF = "AF",
	AX = "AX",
	AL = "AL",
	DZ = "DZ",
	AS = "AS",
	AD = "AD",
	AO = "AO",
	AI = "AI",
	AQ = "AQ",
	AG = "AG",
	AR = "AR",
	AM = "AM",
	AW = "AW",
	AU = "AU",
	AT = "AT",
	AZ = "AZ",
	BS = "BS",
	BH = "BH",
	BD = "BD",
	BB = "BB",
	BY = "BY",
	BE = "BE",
	BZ = "BZ",
	BJ = "BJ",
	BM = "BM",
	BT = "BT",
	BO = "BO",
	BA = "BA",
	BW = "BW",
	BV = "BV",
	BR = "BR",
	IO = "IO",
	VG = "VG",
	BN = "BN",
	BG = "BG",
	BF = "BF",
	BI = "BI",
	KH = "KH",
	CM = "CM",
	CA = "CA",
	CV = "CV",
	KY = "KY",
	CF = "CF",
	TD = "TD",
	CL = "CL",
	CN = "CN",
	CX = "CX",
	CC = "CC",
	CO = "CO",
	KM = "KM",
	CG = "CG",
	CD = "CD",
	CK = "CK",
	CR = "CR",
	CI = "CI",
	HR = "HR",
	CU = "CU",
	CW = "CW",
	CY = "CY",
	CZ = "CZ",
	DK = "DK",
	DJ = "DJ",
	DM = "DM",
	DO = "DO",
	EC = "EC",
	EG = "EG",
	SV = "SV",
	GQ = "GQ",
	ER = "ER",
	EE = "EE",
	ET = "ET",
	FK = "FK",
	FO = "FO",
	FJ = "FJ",
	FI = "FI",
	FR = "FR",
	GF = "GF",
	PF = "PF",
	TF = "TF",
	GA = "GA",
	GM = "GM",
	GE = "GE",
	DE = "DE",
	GH = "GH",
	GI = "GI",
	GR = "GR",
	GL = "GL",
	GD = "GD",
	GP = "GP",
	GU = "GU",
	GT = "GT",
	GG = "GG",
	GW = "GW",
	GN = "GN",
	GY = "GY",
	HT = "HT",
	HM = "HM",
	VA = "VA",
	HN = "HN",
	HK = "HK",
	HU = "HU",
	IS = "IS",
	IN = "IN",
	ID = "ID",
	IR = "IR",
	IQ = "IQ",
	IE = "IE",
	IM = "IM",
	IL = "IL",
	IT = "IT",
	JM = "JM",
	JP = "JP",
	JE = "JE",
	JO = "JO",
	KZ = "KZ",
	KE = "KE",
	KI = "KI",
	KP = "KP",
	KR = "KR",
	XK = "XK",
	KW = "KW",
	KG = "KG",
	LA = "LA",
	LV = "LV",
	LB = "LB",
	LS = "LS",
	LR = "LR",
	LY = "LY",
	LI = "LI",
	LT = "LT",
	LU = "LU",
	MO = "MO",
	MK = "MK",
	MG = "MG",
	MW = "MW",
	MY = "MY",
	MV = "MV",
	ML = "ML",
	MT = "MT",
	MH = "MH",
	MQ = "MQ",
	MR = "MR",
	MU = "MU",
	YT = "YT",
	MX = "MX",
	FM = "FM",
	MD = "MD",
	MC = "MC",
	MN = "MN",
	ME = "ME",
	MS = "MS",
	MA = "MA",
	MZ = "MZ",
	MM = "MM",
	NA = "NA",
	NR = "NR",
	NP = "NP",
	NL = "NL",
	NC = "NC",
	NZ = "NZ",
	NI = "NI",
	NE = "NE",
	NG = "NG",
	NU = "NU",
	NF = "NF",
	MP = "MP",
	NO = "NO",
	OM = "OM",
	PK = "PK",
	PW = "PW",
	PS = "PS",
	PA = "PA",
	PG = "PG",
	PY = "PY",
	PE = "PE",
	PH = "PH",
	PN = "PN",
	PL = "PL",
	PT = "PT",
	PR = "PR",
	QA = "QA",
	RE = "RE",
	RO = "RO",
	RU = "RU",
	RW = "RW",
	BL = "BL",
	SH = "SH",
	KN = "KN",
	LC = "LC",
	MF = "MF",
	PM = "PM",
	VC = "VC",
	WS = "WS",
	SM = "SM",
	ST = "ST",
	SA = "SA",
	SN = "SN",
	RS = "RS",
	SC = "SC",
	SL = "SL",
	SG = "SG",
	SX = "SX",
	SK = "SK",
	SI = "SI",
	SB = "SB",
	SO = "SO",
	ZA = "ZA",
	GS = "GS",
	SS = "SS",
	ES = "ES",
	LK = "LK",
	SD = "SD",
	SR = "SR",
	SJ = "SJ",
	SZ = "SZ",
	SE = "SE",
	CH = "CH",
	SY = "SY",
	TW = "TW",
	TJ = "TJ",
	TH = "TH",
	TL = "TL",
	TG = "TG",
	TK = "TK",
	TO = "TO",
	TT = "TT",
	TN = "TN",
	TR = "TR",
	TM = "TM",
	TC = "TC",
	TV = "TV",
	UG = "UG",
	UA = "UA",
	AE = "AE",
	GB = "GB",
	TZ = "TZ",
	US = "US",
	UY = "UY",
	VI = "VI",
	UZ = "UZ",
	VU = "VU",
	VE = "VE",
	VN = "VN",
	WF = "WF",
	EH = "EH",
	YE = "YE",
	ZM = "ZM",
	ZW = "ZW",
}

export interface CountryEnum extends EnumBase {
	key: string;
	icon: string;
	suggested?: boolean;
	code: string;
}

export const COUNTRIES: EnumDetail<Country, CountryEnum> = {
	AF: { key: "af", title: "Afghanistan", icon: "🇦🇫", code: "93" },
	AX: { key: "ax", title: "Alland Islands", icon: "🇦🇽", code: "358" },
	AL: { key: "al", title: "Albania", icon: "🇦🇱", code: "355" },
	DZ: { key: "dz", title: "Algeria", icon: "🇩🇿", code: "213" },
	AS: { key: "as", title: "American Samoa", icon: "🇦🇸", code: "1-684" },
	AD: { key: "ad", title: "Andorra", icon: "🇦🇩", code: "376" },
	AO: { key: "ao", title: "Angola", icon: "🇦🇴", code: "244" },
	AI: { key: "ai", title: "Anguilla", icon: "🇦🇮", code: "1-264" },
	AQ: { key: "aq", title: "Antarctica", icon: "🇦🇶", code: "672" },
	AG: { key: "ag", title: "Antigua and Barbuda", icon: "🇦🇬", code: "1-268" },
	AR: { key: "ar", title: "Argentina", icon: "🇦🇷", code: "54" },
	AM: { key: "am", title: "Armenia", icon: "🇦🇲", code: "374" },
	AW: { key: "aw", title: "Aruba", icon: "🇦🇼", code: "297" },
	AU: { key: "au", title: "Australia", icon: "🇦🇺", code: "61" },
	AT: { key: "at", title: "Austria", icon: "🇦🇹", code: "43" },
	AZ: { key: "az", title: "Azerbaijan", icon: "🇦🇿", code: "994" },
	BS: { key: "bs", title: "Bahamas", icon: "🇧🇸", code: "1-242" },
	BH: { key: "bh", title: "Bahrain", icon: "🇧🇭", code: "973" },
	BD: { key: "bd", title: "Bangladesh", icon: "🇧🇩", code: "880" },
	BB: { key: "bb", title: "Barbados", icon: "🇧🇧", code: "1-246" },
	BY: { key: "by", title: "Belarus", icon: "🇧🇾", code: "375" },
	BE: { key: "be", title: "Belgium", icon: "🇧🇪", code: "32" },
	BZ: { key: "bz", title: "Belize", icon: "🇧🇿", code: "501" },
	BJ: { key: "bj", title: "Benin", icon: "🇧🇯", code: "229" },
	BM: { key: "bm", title: "Bermuda", icon: "🇧🇲", code: "1-441" },
	BT: { key: "bt", title: "Bhutan", icon: "🇧🇹", code: "975" },
	BO: { key: "bo", title: "Bolivia", icon: "🇧🇴", code: "591" },
	BA: { key: "ba", title: "Bosnia and Herzegovina", icon: "🇧🇦", code: "387" },
	BW: { key: "bw", title: "Botswana", icon: "🇧🇼", code: "267" },
	BV: { key: "bv", title: "Bouvet Island", icon: "🇧🇻", code: "47" },
	BR: { key: "br", title: "Brazil", icon: "🇧🇷", code: "55" },
	IO: { key: "io", title: "British Indian Ocean Territory", icon: "🇮🇴", code: "246" },
	VG: { key: "vg", title: "British Virgin Islands", icon: "🇻🇬", code: "1-284" },
	BN: { key: "bn", title: "Brunei Darussalam", icon: "🇧🇳", code: "673" },
	BG: { key: "bg", title: "Bulgaria", icon: "🇧🇬", code: "359" },
	BF: { key: "bf", title: "Burkina Faso", icon: "🇧🇫", code: "226" },
	BI: { key: "bi", title: "Burundi", icon: "🇧🇮", code: "257" },
	KH: { key: "kh", title: "Cambodia", icon: "🇰🇭", code: "855" },
	CM: { key: "cm", title: "Cameroon", icon: "🇨🇲", code: "237" },
	CA: { key: "ca", title: "Canada", icon: "🇨🇦", code: "1" },
	CV: { key: "cv", title: "Cape Verde", icon: "🇨🇻", code: "238" },
	KY: { key: "ky", title: "Cayman Islands", icon: "🇰🇾", code: "1-345" },
	CF: { key: "cf", title: "Central African Republic", icon: "🇨🇫", code: "236" },
	TD: { key: "td", title: "Chad", icon: "🇹🇩", code: "235" },
	CL: { key: "cl", title: "Chile", icon: "🇨🇱", code: "56" },
	CN: { key: "cn", title: "China", icon: "🇨🇳", code: "86" },
	CX: { key: "cx", title: "Christmas Island", icon: "🇨🇽", code: "61" },
	CC: { key: "cc", title: "Cocos (Keeling) Islands", icon: "🇨🇨", code: "61" },
	CO: { key: "co", title: "Colombia", icon: "🇨🇴", code: "57" },
	KM: { key: "km", title: "Comoros", icon: "🇰🇲", code: "269" },
	CG: { key: "cg", title: "Congo, Democratic Republic of the", icon: "🇨🇩", code: "243" },
	CD: { key: "cd", title: "Congo, Republic of the", icon: "🇨🇬", code: "242" },
	CK: { key: "ck", title: "Cook Islands", icon: "🇨🇰", code: "682" },
	CR: { key: "cr", title: "Costa Rica", icon: "🇨🇷", code: "506" },
	CI: { key: "ci", title: "Cote d'Ivoire", icon: "🇨🇮", code: "225" },
	HR: { key: "hr", title: "Croatia", icon: "🇭🇷", code: "385" },
	CU: { key: "cu", title: "Cuba", icon: "🇨🇺", code: "53" },
	CW: { key: "cw", title: "Curacao", icon: "🇨🇼", code: "599" },
	CY: { key: "cy", title: "Cyprus", icon: "🇨🇾", code: "357" },
	CZ: { key: "cz", title: "Czech Republic", icon: "🇨🇿", code: "420" },
	DK: { key: "dk", title: "Denmark", icon: "🇩🇰", code: "45" },
	DJ: { key: "dj", title: "Djibouti", icon: "🇩🇯", code: "253" },
	DM: { key: "dm", title: "Dominica", icon: "🇩🇲", code: "1-767" },
	DO: { key: "do", title: "Dominican Republic", icon: "🇩🇴", code: "1-809" },
	EC: { key: "ec", title: "Ecuador", icon: "🇪🇨", code: "593" },
	EG: { key: "eg", title: "Egypt", icon: "🇪🇬", code: "20" },
	SV: { key: "sv", title: "El Salvador", icon: "🇸🇻", code: "503" },
	GQ: { key: "gq", title: "Equatorial Guinea", icon: "🇬🇶", code: "240" },
	ER: { key: "er", title: "Eritrea", icon: "🇪🇷", code: "291" },
	EE: { key: "ee", title: "Estonia", icon: "🇪🇪", code: "372" },
	ET: { key: "et", title: "Ethiopia", icon: "🇪🇹", code: "251" },
	FK: { key: "fk", title: "Falkland Islands (Malvinas)", icon: "🇫🇰", code: "500" },
	FO: { key: "fo", title: "Faroe Islands", icon: "🇫🇴", code: "298" },
	FJ: { key: "fj", title: "Fiji", icon: "🇫🇯", code: "679" },
	FI: { key: "fi", title: "Finland", icon: "🇫🇮", code: "358" },
	FR: { key: "fr", title: "France", icon: "🇫🇷", code: "33" },
	GF: { key: "gf", title: "French Guiana", icon: "🇬🇫", code: "594" },
	PF: { key: "pf", title: "French Polynesia", icon: "🇵🇫", code: "689" },
	TF: { key: "tf", title: "French Southern Territories", icon: "🇹🇫", code: "262" },
	GA: { key: "ga", title: "Gabon", icon: "🇬🇦", code: "241" },
	GM: { key: "gm", title: "Gambia", icon: "🇬🇲", code: "220" },
	GE: { key: "ge", title: "Georgia", icon: "🇬🇪", code: "995" },
	DE: { key: "de", title: "Germany", icon: "🇩🇪", code: "49" },
	GH: { key: "gh", title: "Ghana", icon: "🇬🇭", code: "233" },
	GI: { key: "gi", title: "Gibraltar", icon: "🇬🇮", code: "350" },
	GR: { key: "gr", title: "Greece", icon: "🇬🇷", code: "30" },
	GL: { key: "gl", title: "Greenland", icon: "🇬🇱", code: "299" },
	GD: { key: "gd", title: "Grenada", icon: "🇬🇩", code: "1-473" },
	GP: { key: "gp", title: "Guadeloupe", icon: "🇬🇵", code: "590" },
	GU: { key: "gu", title: "Guam", icon: "🇬🇺", code: "1-671" },
	GT: { key: "gt", title: "Guatemala", icon: "🇬🇹", code: "502" },
	GG: { key: "gg", title: "Guernsey", icon: "🇬🇬", code: "44" },
	GW: { key: "gw", title: "Guinea-Bissau", icon: "🇬🇼", code: "245" },
	GN: { key: "gn", title: "Guinea", icon: "🇬🇳", code: "224" },
	GY: { key: "gy", title: "Guyana", icon: "🇬🇾", code: "592" },
	HT: { key: "ht", title: "Haiti", icon: "🇭🇹", code: "509" },
	HM: { key: "hm", title: "Heard Island and McDonald Islands", icon: "🇭🇲", code: "672" },
	VA: { key: "va", title: "Holy See (Vatican City State)", icon: "🇻🇦", code: "379" },
	HN: { key: "hn", title: "Honduras", icon: "🇭🇳", code: "504" },
	HK: { key: "hk", title: "Hong Kong", icon: "🇭🇰", code: "852" },
	HU: { key: "hu", title: "Hungary", icon: "🇭🇺", code: "36" },
	IS: { key: "is", title: "Iceland", icon: "🇮🇸", code: "354" },
	IN: { key: "in", title: "India", icon: "🇮🇳", code: "91" },
	ID: { key: "id", title: "Indonesia", icon: "🇮🇩", code: "62" },
	IR: { key: "ir", title: "Iran, Islamic Republic of", icon: "🇮🇷", code: "98" },
	IQ: { key: "iq", title: "Iraq", icon: "🇮🇶", code: "964" },
	IE: { key: "ie", title: "Ireland", icon: "🇮🇪", code: "353" },
	IM: { key: "im", title: "Isle of Man", icon: "🇮🇲", code: "44" },
	IL: { key: "il", title: "Israel", icon: "🇮🇱", code: "972" },
	IT: { key: "it", title: "Italy", icon: "🇮🇹", code: "39" },
	JM: { key: "jm", title: "Jamaica", icon: "🇯🇲", code: "1-876" },
	JP: { key: "jp", title: "Japan", icon: "🇯🇵", code: "81" },
	JE: { key: "je", title: "Jersey", icon: "🇯🇪", code: "44" },
	JO: { key: "jo", title: "Jordan", icon: "🇯🇴", code: "962" },
	KZ: { key: "kz", title: "Kazakhstan", icon: "🇰🇿", code: "7" },
	KE: { key: "ke", title: "Kenya", icon: "🇰🇪", code: "254" },
	KI: { key: "ki", title: "Kiribati", icon: "🇰🇮", code: "686" },
	KP: { key: "kp", title: "Korea, Democratic People's Republic of", icon: "🇰🇵", code: "850" },
	KR: { key: "kr", title: "Korea, Republic of", icon: "🇰🇷", code: "82" },
	XK: { key: "xk", title: "Kosovo", icon: "🇽🇰", code: "383" },
	KW: { key: "kw", title: "Kuwait", icon: "🇰🇼", code: "965" },
	KG: { key: "kg", title: "Kyrgyzstan", icon: "🇰🇬", code: "996" },
	LA: { key: "la", title: "Lao People's Democratic Republic", icon: "🇱🇦", code: "856" },
	LV: { key: "lv", title: "Latvia", icon: "🇱🇻", code: "371" },
	LB: { key: "lb", title: "Lebanon", icon: "🇱🇧", code: "961" },
	LS: { key: "ls", title: "Lesotho", icon: "🇱🇸", code: "266" },
	LR: { key: "lr", title: "Liberia", icon: "🇱🇷", code: "231" },
	LY: { key: "ly", title: "Libya", icon: "🇱🇾", code: "218" },
	LI: { key: "li", title: "Liechtenstein", icon: "🇱🇮", code: "423" },
	LT: { key: "lt", title: "Lithuania", icon: "🇱🇹", code: "370" },
	LU: { key: "lu", title: "Luxembourg", icon: "🇱🇺", code: "352" },
	MO: { key: "mo", title: "Macao", icon: "🇲🇴", code: "853" },
	MK: { key: "mk", title: "Macedonia, the Former Yugoslav Republic of", icon: "🇲🇰", code: "389" },
	MG: { key: "mg", title: "Madagascar", icon: "🇲🇬", code: "261" },
	MW: { key: "mw", title: "Malawi", icon: "🇲🇼", code: "265" },
	MY: { key: "my", title: "Malaysia", icon: "🇲🇾", code: "60" },
	MV: { key: "mv", title: "Maldives", icon: "🇲🇻", code: "960" },
	ML: { key: "ml", title: "Mali", icon: "🇲🇱", code: "223" },
	MT: { key: "mt", title: "Malta", icon: "🇲🇹", code: "356" },
	MH: { key: "mh", title: "Marshall Islands", icon: "🇲🇭", code: "692" },
	MQ: { key: "mq", title: "Martinique", icon: "🇲🇶", code: "596" },
	MR: { key: "mr", title: "Mauritania", icon: "🇲🇷", code: "222" },
	MU: { key: "mu", title: "Mauritius", icon: "🇲🇺", code: "230" },
	YT: { key: "yt", title: "Mayotte", icon: "🇾🇹", code: "262" },
	MX: { key: "mx", title: "Mexico", icon: "🇲🇽", code: "52" },
	FM: { key: "fm", title: "Micronesia, Federated States of", icon: "🇫🇲", code: "691" },
	MD: { key: "md", title: "Moldova, Republic of", icon: "🇲🇩", code: "373" },
	MC: { key: "mc", title: "Monaco", icon: "🇲🇨", code: "377" },
	MN: { key: "mn", title: "Mongolia", icon: "🇲🇳", code: "976" },
	ME: { key: "me", title: "Montenegro", icon: "🇲🇪", code: "382" },
	MS: { key: "ms", title: "Montserrat", icon: "🇲🇸", code: "1-664" },
	MA: { key: "ma", title: "Morocco", icon: "🇲🇦", code: "212" },
	MZ: { key: "mz", title: "Mozambique", icon: "🇲🇿", code: "258" },
	MM: { key: "mm", title: "Myanmar", icon: "🇲🇲", code: "95" },
	NA: { key: "na", title: "Namibia", icon: "🇳🇦", code: "264" },
	NR: { key: "nr", title: "Nauru", icon: "🇳🇷", code: "674" },
	NP: { key: "np", title: "Nepal", icon: "🇳🇵", code: "977" },
	NL: { key: "nl", title: "Netherlands", icon: "🇳🇱", code: "31" },
	NC: { key: "nc", title: "New Caledonia", icon: "🇳🇨", code: "687" },
	NZ: { key: "nz", title: "New Zealand", icon: "🇳🇿", code: "64" },
	NI: { key: "ni", title: "Nicaragua", icon: "🇳🇮", code: "505" },
	NE: { key: "ne", title: "Niger", icon: "🇳🇪", code: "227" },
	NG: { key: "ng", title: "Nigeria", icon: "🇳🇬", code: "234" },
	NU: { key: "nu", title: "Niue", icon: "🇳🇺", code: "683" },
	NF: { key: "nf", title: "Norfolk Island", icon: "🇳🇫", code: "672" },
	MP: { key: "mp", title: "Northern Mariana Islands", icon: "🇲🇵", code: "1-670" },
	NO: { key: "no", title: "Norway", icon: "🇳🇴", code: "47" },
	OM: { key: "om", title: "Oman", icon: "🇴🇲", code: "968" },
	PK: { key: "pk", title: "Pakistan", icon: "🇵🇰", code: "92" },
	PW: { key: "pw", title: "Palau", icon: "🇵🇼", code: "680" },
	PS: { key: "ps", title: "Palestine, State of", icon: "🇵🇸", code: "970" },
	PA: { key: "pa", title: "Panama", icon: "🇵🇦", code: "507" },
	PG: { key: "pg", title: "Papua New Guinea", icon: "🇵🇬", code: "675" },
	PY: { key: "py", title: "Paraguay", icon: "🇵🇾", code: "595" },
	PE: { key: "pe", title: "Peru", icon: "🇵🇪", code: "51" },
	PH: { key: "ph", title: "Philippines", icon: "🇵🇭", code: "63" },
	PN: { key: "pn", title: "Pitcairn", icon: "🇵🇳", code: "870" },
	PL: { key: "pl", title: "Poland", icon: "🇵🇱", code: "48" },
	PT: { key: "pt", title: "Portugal", icon: "🇵🇹", code: "351" },
	PR: { key: "pr", title: "Puerto Rico", icon: "🇵🇷", code: "1" },
	QA: { key: "qa", title: "Qatar", icon: "🇶🇦", code: "974" },
	RE: { key: "re", title: "Reunion", icon: "🇷🇪", code: "262" },
	RO: { key: "ro", title: "Romania", icon: "🇷🇴", code: "40" },
	RU: { key: "ru", title: "Russian Federation", icon: "🇷🇺", code: "7" },
	RW: { key: "rw", title: "Rwanda", icon: "🇷🇼", code: "250" },
	BL: { key: "bl", title: "Saint Barthelemy", icon: "🇧🇱", code: "590" },
	SH: { key: "sh", title: "Saint Helena", icon: "🇸🇭", code: "290" },
	KN: { key: "kn", title: "Saint Kitts and Nevis", icon: "🇰🇳", code: "1-869" },
	LC: { key: "lc", title: "Saint Lucia", icon: "🇱🇨", code: "1-758" },
	MF: { key: "mf", title: "Saint Martin (French part)", icon: "🇲🇫", code: "590" },
	PM: { key: "pm", title: "Saint Pierre and Miquelon", icon: "🇵🇲", code: "508" },
	VC: { key: "vc", title: "Saint Vincent and the Grenadines", icon: "🇻🇨", code: "1-784" },
	WS: { key: "ws", title: "Samoa", icon: "🇼🇸", code: "685" },
	SM: { key: "sm", title: "San Marino", icon: "🇸🇲", code: "378" },
	ST: { key: "st", title: "Sao Tome and Principe", icon: "🇸🇹", code: "239" },
	SA: { key: "sa", title: "Saudi Arabia", icon: "🇸🇦", code: "966" },
	SN: { key: "sn", title: "Senegal", icon: "🇸🇳", code: "221" },
	RS: { key: "rs", title: "Serbia", icon: "🇷🇸", code: "381" },
	SC: { key: "sc", title: "Seychelles", icon: "🇸🇨", code: "248" },
	SL: { key: "sl", title: "Sierra Leone", icon: "🇸🇱", code: "232" },
	SG: { key: "sg", title: "Singapore", icon: "🇸🇬", code: "65" },
	SX: { key: "sx", title: "Sint Maarten (Dutch part)", icon: "🇸🇽", code: "1-721" },
	SK: { key: "sk", title: "Slovakia", icon: "🇸🇰", code: "421" },
	SI: { key: "si", title: "Slovenia", icon: "🇸🇮", code: "386" },
	SB: { key: "sb", title: "Solomon Islands", icon: "🇸🇧", code: "677" },
	SO: { key: "so", title: "Somalia", icon: "🇸🇴", code: "252" },
	ZA: { key: "za", title: "South Africa", icon: "🇿🇦", code: "27" },
	GS: { key: "gs", title: "South Georgia and the South Sandwich Islands", icon: "🇬🇸", code: "500" },
	SS: { key: "ss", title: "South Sudan", icon: "🇸🇸", code: "211" },
	ES: { key: "es", title: "Spain", icon: "🇪🇸", code: "34" },
	LK: { key: "lk", title: "Sri Lanka", icon: "🇱🇰", code: "94" },
	SD: { key: "sd", title: "Sudan", icon: "🇸🇩", code: "249" },
	SR: { key: "sr", title: "Suriname", icon: "🇸🇷", code: "597" },
	SJ: { key: "sj", title: "Svalbard and Jan Mayen", icon: "🇸🇯", code: "47" },
	SZ: { key: "sz", title: "Swaziland", icon: "🇸🇿", code: "268" },
	SE: { key: "se", title: "Sweden", icon: "🇸🇪", code: "46" },
	CH: { key: "ch", title: "Switzerland", icon: "🇨🇭", code: "41" },
	SY: { key: "sy", title: "Syrian Arab Republic", icon: "🇸🇾", code: "963" },
	TW: { key: "tw", title: "Taiwan, Province of China", icon: "🇹🇼", code: "886" },
	TJ: { key: "tj", title: "Tajikistan", icon: "🇹🇯", code: "992" },
	TH: { key: "th", title: "Thailand", icon: "🇹🇭", code: "66" },
	TL: { key: "tl", title: "Timor-Leste", icon: "🇹🇱", code: "670" },
	TG: { key: "tg", title: "Togo", icon: "🇹🇬", code: "228" },
	TK: { key: "tk", title: "Tokelau", icon: "🇹🇰", code: "690" },
	TO: { key: "to", title: "Tonga", icon: "🇹🇴", code: "676" },
	TT: { key: "tt", title: "Trinidad and Tobago", icon: "🇹🇹", code: "1-868" },
	TN: { key: "tn", title: "Tunisia", icon: "🇹🇳", code: "216" },
	TR: { key: "tr", title: "Turkey", icon: "🇹🇷", code: "90" },
	TM: { key: "tm", title: "Turkmenistan", icon: "🇹🇲", code: "993" },
	TC: { key: "tc", title: "Turks and Caicos Islands", icon: "🇹🇨", code: "1-649" },
	TV: { key: "tv", title: "Tuvalu", icon: "🇹🇻", code: "688" },
	UG: { key: "ug", title: "Uganda", icon: "🇺🇬", code: "256" },
	UA: { key: "ua", title: "Ukraine", icon: "🇺🇦", code: "380" },
	AE: { key: "ae", title: "United Arab Emirates", icon: "🇦🇪", code: "971" },
	GB: { key: "gb", title: "United Kingdom", icon: "🇬🇧", code: "44" },
	TZ: { key: "tz", title: "United Republic of Tanzania", icon: "🇹🇿", code: "255" },
	US: { key: "us", title: "United States", icon: "🇺🇲", code: "1" },
	UY: { key: "uy", title: "Uruguay", icon: "🇺🇾", code: "598" },
	VI: { key: "vi", title: "US Virgin Islands", icon: "🇻🇮", code: "1-340" },
	UZ: { key: "uz", title: "Uzbekistan", icon: "🇺🇿", code: "998" },
	VU: { key: "vu", title: "Vanuatu", icon: "🇻🇺", code: "678" },
	VE: { key: "ve", title: "Venezuela", icon: "🇻🇪", code: "58" },
	VN: { key: "vn", title: "Vietnam", icon: "🇻🇳", code: "84" },
	WF: { key: "wf", title: "Wallis and Futuna", icon: "🇼🇫", code: "681" },
	EH: { key: "eh", title: "Western Sahara", icon: "🇪🇭", code: "212" },
	YE: { key: "ye", title: "Yemen", icon: "🇾🇪", code: "967" },
	ZM: { key: "zm", title: "Zambia", icon: "🇿🇲", code: "260" },
	ZW: { key: "zw", title: "Zimbabwe", icon: "🇿🇼", code: "263" },
};

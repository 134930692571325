import { useState } from "react";

/**
 * usePersistedState tries to emulate the behaviour of the useState hook but enhances it by persisting its value to the localStorage of the browser.
 * Theoretically this allows to share state between different independent components. As this is not tested comprehensively, using it that way is at your own risk.
 *
 * Because of the nature of the localStorage, values need to be persisted as strings. UsePersistedState uses JSON.stringify and JSON.parse to write to and read from the localStorage.
 * You can supply a normalize function if you need to transform your state after it is read from the localStorage.
 *
 * @param key Preferably a unique key under which the value is persisted in the browsers localStorage. There are no checks of the keys uniqueness. It is your task to ensure this.
 * @param defaultValue Default value for when there has not been persisted any state to the browser yet.
 * @param normalizeFunc A function the value is applied to when it is read from the localStorage.
 * @returns {[*, function(*=): void]}
 */
const usePersistedState = (key: string, defaultValue?: any, normalizeFunc?: (data: any) => string) => {
	if(!key || key.length === 0){
		throw new Error(`Illegal key for persisted state: '${key}'`);
	}

	const getPersistedState = () => {
		const storedData = localStorage.getItem(`${key}`);
		if (storedData === null) {
			return defaultValue;
		}

		const persistedValue = JSON.parse(storedData);
		if (normalizeFunc) {
			return normalizeFunc(persistedValue);
		}
		return persistedValue;
	};

	const setPersistedState = (value: any) => {
		setHookValue(value);
		return localStorage.setItem(`${key}`, JSON.stringify(value));
	};

	const [ hookValue, setHookValue ] = useState(getPersistedState());
	return [ hookValue, setPersistedState ];
};
export default usePersistedState;

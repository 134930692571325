const newEventModalStyle = {
	modal: { height: "100%" },
	actions: {
		display: "flex",
		alignItems: "flex-end"
	},
	levelFilter: {
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch"
	},
	filterControl: {
		width: "100%",
		paddingTop: 0,
		marginBottom: 0,
		fontSize: "initial"
	},
	typeIcon: {
		display: "flex",
		opacity: 0.4
	}
};

export default newEventModalStyle;

import { Action } from "redux";

import { ActionType } from "actions/ActionType";


export default (state = { darkMode: initialDarkMode() }, action: Action<ActionType>) => {
	if (action.type === ActionType.TOGGLE_DARK_MODE) {
		return { darkMode: !state.darkMode };
	}
	return state;
};

function initialDarkMode() {
	const stored = localStorage.getItem("darkMode");
	if (stored === null) {
		return !!window.matchMedia("(prefers-color-scheme: dark)")?.matches;
	}
	return JSON.parse(stored) as boolean;
}

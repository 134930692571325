import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import { HlcrRouteComponentProps } from "@hlcr/app/model/RouteComponent";
import { Dto, isAnyResourceNotLoaded, RemoteResourceState, RemoteResourceStatus } from "@hlcr/core/api/RemoteResource";
import { onSelectChange, onSwitchChange, onValueChange } from "@hlcr/mui/Input/inputValueChange";
import { useIntl } from "@hlcr/ui/Intl";
import { IconButton, Switch, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { ArrowDownwardOutlined, ArrowUpwardOutlined, DeleteOutlined, EditOutlined, SendOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { DataGrid, GridCellParams, GridColDef, GridRowParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkHasRole } from "auth/authUtils";
import ValidationInput from "components/CustomInput/ValidationInput";
import { CustomSelect } from "components/CustomSelect/CustomSelect";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import NoData from "components/NoData/NoData";
import { RemoteResource } from "models/RemoteResource";
import { UserProfile } from "models/User";
import { RootState } from "reducers";
import { RouteDetailCard } from "routes/components/RouteDetailCard";
import { HlcrDialog, HlcrDialogActionType } from "tenantAdmin/components/HlcrDialog";
import { SMTP_AUTHENTICATION_TYPE, SmtpAuthenticationType } from "tenantAdmin/model/enum/SmtpAuthenticationType";
import { TenantAdminConfigurationEmail } from "tenantAdmin/model/TenantAdminConfiguration";
import {
	createTenantAdminEmailConfiguration,
	deleteTenantEmailConfiguration,
	fetchTenantAdminEmailConfiguration,
	testTenantEmailConfiguration,
	updateTenantAdminEmailConfiguration,
} from "tenantAdmin/tenantAdmin.actions";
import { TENANT_ADMIN_ROUTES } from "tenantAdmin/tenantAdmin.routes";
import { fetchPersonalProfile } from "userProfile/actions";


interface GridColumn<T extends Dto> extends Omit<GridColDef, "field"> {
	field: keyof T | "";
}

type GridColumns<T extends Dto> = Array<GridColumn<T>>;

interface TenantAdminEmailProps extends HlcrRouteComponentProps {

}

export const TenantAdminEmail = ({ summary }: TenantAdminEmailProps) => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const tenantEmailConfigurationState = useSelector<RootState, RemoteResourceState<TenantAdminConfigurationEmail[]>>((state: RootState) => state.remoteResourceReducer.remoteResources[RemoteResource.TENANT_ADMIN_CONFIGURATION_EMAIL]);

	const userProfileState = useSelector<RootState, RemoteResourceState<UserProfile>>((state: RootState) => state.remoteResourceReducer.remoteResources[RemoteResource.USER_PROFILE]);

	const [ selectedConfig, setSelectedConfig ] = useState<TenantAdminConfigurationEmail>();
	const [ testEmailConfiguration, setTestEmailConfiguration ] = useState<TenantAdminConfigurationEmail>();
	const [ testMailRecipientAddress, setTestMailRecipientAddress ] = useState("");

	useEffect(() => {
		if (tenantEmailConfigurationState.status === RemoteResourceStatus.INITIALIZED) {
			dispatch(fetchTenantAdminEmailConfiguration());
		}
	}, [ tenantEmailConfigurationState ]);

	useEffect(() => {
		if (userProfileState.status === RemoteResourceStatus.INITIALIZED) {
			dispatch(fetchPersonalProfile());
		}

		if (userProfileState.status === RemoteResourceStatus.LOADED) {
			setTestMailRecipientAddress(userProfileState.data?.email || "");
		}
	}, [ userProfileState ]);

	if (isAnyResourceNotLoaded([ tenantEmailConfigurationState.status ])) {
		return <LoadingSpinner />;
	}

	if (summary) {
		if (!tenantEmailConfigurationState.data?.length) {
			return <NoData />;
		}

		return <Table>
			<TableHead>
				<TableRow>
					<TableCell>{intl.fm("tenant.admin.email.sortOrder")}</TableCell>
					<TableCell>{intl.fm("tenant.admin.email.summary")}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{
					tenantEmailConfigurationState.data
						.filter(c => !c.disabled)
						.map((row, index) => (
							<TableRow key={index}>
								<TableCell>{1 + row.sortOrder}</TableCell>
								<TableCell>{`${row.host}:${row.port} (${row.from})`}</TableCell>
							</TableRow>
						))
				}
			</TableBody>
		</Table>;
	}


	const handleOnSave = () => {
		if (selectedConfig) {
			if (selectedConfig.id !== undefined) {
				dispatch(updateTenantAdminEmailConfiguration(selectedConfig, handleOnClose));
			} else {
				dispatch(createTenantAdminEmailConfiguration(selectedConfig, handleOnClose));
			}
		}
	};

	const handleOnCreate = () => {
		if (checkHasRole(HackingLabRole.TENANT_CONFIGURATION_EMAIL_CREATE)) {
			setSelectedConfig({
				disabled: false,
				from: "",
				host: "",
				port: 25,
				sortOrder: tenantEmailConfigurationState.data?.length || 0,
				authenticationType: SmtpAuthenticationType.NONE,
			});
		}
	};

	const sendTestMail = () => {
		if (testEmailConfiguration?.id && testMailRecipientAddress) {
			dispatch(testTenantEmailConfiguration(testEmailConfiguration?.id, { email: testMailRecipientAddress }));
		}
	};

	const handleDelete = (config: TenantAdminConfigurationEmail) => {
		if (config.id) {
			dispatch(deleteTenantEmailConfiguration(config));
		}
	};

	const handleEdit = (config: TenantAdminConfigurationEmail) => {
		if (checkHasRole(HackingLabRole.TENANT_CONFIGURATION_EMAIL_UPDATE)) {
			setSelectedConfig(config);
		}
	};

	const moveDownHandler = (config: TenantAdminConfigurationEmail) => () => {
		if (!tenantEmailConfigurationState.data?.length || config.sortOrder >= tenantEmailConfigurationState.data?.length - 1) {
			return;
		}

		const otherConfig = tenantEmailConfigurationState.data.find(value => value.sortOrder === config.sortOrder + 1);
		if (!otherConfig) {
			return;
		}

		otherConfig.sortOrder = -1;
		config.sortOrder += 1;

		dispatch(updateTenantAdminEmailConfiguration(otherConfig, () => {
			dispatch(updateTenantAdminEmailConfiguration(config, () => {
				otherConfig.sortOrder = config.sortOrder - 1;
				dispatch(updateTenantAdminEmailConfiguration(otherConfig));
			}));
		}));
	};

	const moveUpHandler = (config: TenantAdminConfigurationEmail) => () => {
		if (!tenantEmailConfigurationState.data?.length || config.sortOrder <= 0) {
			return;
		}

		const otherConfig = tenantEmailConfigurationState.data.find(value => value.sortOrder === config.sortOrder - 1);
		if (!otherConfig) {
			return;
		}

		otherConfig.sortOrder = -1;
		config.sortOrder -= 1;

		dispatch(updateTenantAdminEmailConfiguration(otherConfig, () => {
			dispatch(updateTenantAdminEmailConfiguration(config, () => {
				otherConfig.sortOrder = config.sortOrder + 1;
				dispatch(updateTenantAdminEmailConfiguration(otherConfig));
			}));
		}));
	};

	const renderActionsCell = (params: GridCellParams) => {
		const row = params.row as TenantAdminConfigurationEmail;

		const handleSingleEdit = () => handleEdit(row);
		const handleSingleDelete = () => handleDelete(row);
		const handleSendTestMail = () => setTestEmailConfiguration(row);

		return <>
			{
				checkHasRole(HackingLabRole.TENANT_CONFIGURATION_EMAIL_UPDATE) &&
				<>
					<Tooltip title={intl.fm("tenant.admin.email.moveUp")}>
						<IconButton onClick={moveUpHandler(row)} disabled={row.sortOrder === 0}>
							<ArrowUpwardOutlined />
						</IconButton>
					</Tooltip>
					<Tooltip title={intl.fm("tenant.admin.email.moveDown")}>
						<IconButton onClick={moveDownHandler(row)} disabled={row.sortOrder + 1 === (tenantEmailConfigurationState.data?.length || 1)}>
							<ArrowDownwardOutlined />
						</IconButton>
					</Tooltip>
					<Tooltip title={intl.fm("common.labels.edit")}>
						<IconButton onClick={handleSingleEdit}>
							<EditOutlined />
						</IconButton>
					</Tooltip>
				</>
			}
			{
				checkHasRole(HackingLabRole.TENANT_CONFIGURATION_EMAIL_DELETE) &&
				<Tooltip title={intl.fm("common.labels.delete")}>
					<IconButton onClick={handleSingleDelete}>
						<DeleteOutlined />
					</IconButton>
				</Tooltip>
			}
			{
				checkHasRole(HackingLabRole.TENANT_CONFIGURATION_EMAIL_TEST) &&
				<Tooltip title={intl.fm("tenant.admin.email.sendTestMail")}>
					<IconButton onClick={handleSendTestMail}>
						<SendOutlined />
					</IconButton>
				</Tooltip>
			}
		</>;
	};

	const handleOnRowDoubleClick = (params: GridRowParams) => {
		handleEdit(params.row as TenantAdminConfigurationEmail);
	};

	const getActionsCellWidth = (numberOfActions: number) => {
		const ACTION_ICON_BUTTON_WIDTH = 48;
		const CELL_PADDING = 20;

		return numberOfActions * ACTION_ICON_BUTTON_WIDTH + CELL_PADDING;
	};

	const columns: GridColumns<TenantAdminConfigurationEmail> = [
		{
			field: "sortOrder",
			headerName: intl.fm("tenant.admin.email.sortOrder"),
			width: 80,
			sortable: false,
			valueFormatter: params => 1 + (params.value as TenantAdminConfigurationEmail["sortOrder"] || 0),
			type: "number",
			align: "right",
		},
		{
			field: "host",
			headerName: intl.fm("tenant.admin.email.host"),
			width: 160,
			flex: 1,
			sortable: false,
		},
		{
			field: "port",
			headerName: intl.fm("tenant.admin.email.port"),
			width: 80,
			sortable: false,
		},
		{
			field: "from",
			headerName: intl.fm("tenant.admin.email.from"),
			width: 120,
			flex: 1,
			sortable: false,
		},
		{
			field: "fromDisplayName",
			headerName: intl.fm("tenant.admin.email.fromDisplayName"),
			width: 100,
			flex: 1,
			sortable: false,
		},
		{
			field: "envelopeFrom",
			headerName: intl.fm("tenant.admin.email.envelopeFrom"),
			width: 120,
			flex: 1,
			sortable: false,
		},
		{
			field: "authenticationType",
			headerName: intl.fm("tenant.admin.email.authenticationType"),
			width: 90,
			valueFormatter: params => intl.fm(SMTP_AUTHENTICATION_TYPE[params.value as SmtpAuthenticationType].title),
			sortable: false,
		},
		{
			field: "",
			headerName: intl.fm("common.labels.actions"),
			width: getActionsCellWidth(5),
			renderCell: renderActionsCell,
			sortable: false,

		},
	];

	const handleOnClose = () => {
		setSelectedConfig(undefined);
	};

	return <RouteDetailCard
		route={TENANT_ADMIN_ROUTES.EMAIL}
		onClose={TENANT_ADMIN_ROUTES.ROOT.path}
		onCreate={checkHasRole(HackingLabRole.TENANT_CONFIGURATION_EMAIL_CREATE) && handleOnCreate}
	>
		{
			selectedConfig && <HlcrDialog
				title={"tenant.admin.email.editTitle"}
				cancelAction={handleOnClose}
				saveAction={handleOnSave}
			>
				<>
					<ValidationInput
						label={intl.fm("tenant.admin.email.host")}
						value={selectedConfig.host}
						required={true}
						onChange={onValueChange("host", selectedConfig, setSelectedConfig)}
						validations={{ required: true }}
					/><br />
					<ValidationInput
						type={"number"}
						label={intl.fm("tenant.admin.email.port")}
						value={selectedConfig.port}
						required={true}
						onChange={onValueChange("port", selectedConfig, setSelectedConfig)}
						validations={{ required: true }}
					/><br />
					<ValidationInput
						type={"string"}
						label={intl.fm("tenant.admin.email.from")}
						value={selectedConfig.from}
						required={true}
						onChange={onValueChange("from", selectedConfig, setSelectedConfig)}
						validations={{ required: true }}
					/><br />
					<ValidationInput
						type={"string"}
						label={intl.fm("tenant.admin.email.fromDisplayName")}
						value={selectedConfig.fromDisplayName}
						onChange={onValueChange("fromDisplayName", selectedConfig, setSelectedConfig)}
					/><br />
					<ValidationInput
						type={"string"}
						label={intl.fm("tenant.admin.email.replyTo")}
						value={selectedConfig.replyTo}
						onChange={onValueChange("replyTo", selectedConfig, setSelectedConfig)}
					/><br />
					<ValidationInput
						type={"string"}
						label={intl.fm("tenant.admin.email.replyToDisplayName")}
						value={selectedConfig.replyToDisplayName}
						onChange={onValueChange("replyToDisplayName", selectedConfig, setSelectedConfig)}
					/><br />
					<ValidationInput
						type={"string"}
						label={intl.fm("tenant.admin.email.envelopeFrom")}
						value={selectedConfig.envelopeFrom}
						onChange={onValueChange("envelopeFrom", selectedConfig, setSelectedConfig)}
					/><br />
					<InputLabel htmlFor={"enableSsl"}>{intl.fm("tenant.admin.email.enableSsl")}</InputLabel>
					<Switch
						id={"enableSsl"}
						checked={selectedConfig.enableSsl}
						onChange={onSwitchChange("enableSsl", selectedConfig, setSelectedConfig)}
						required={true}
					/>
					<br />
					<InputLabel htmlFor={"enableStartTls"}>{intl.fm("tenant.admin.email.enableStartTls")}</InputLabel>
					<Switch
						id={"enableStartTls"}
						checked={selectedConfig.enableStartTls}
						onChange={onSwitchChange("enableStartTls", selectedConfig, setSelectedConfig)}
						required={true}
					/>
					<br />
					<CustomSelect
						label={intl.fm("tenant.admin.email.authenticationType")}
						menuItems={SMTP_AUTHENTICATION_TYPE}
						defaultValue={SmtpAuthenticationType.NONE}
						value={selectedConfig.authenticationType}
						required={true}
						onChange={onSelectChange("authenticationType", selectedConfig, setSelectedConfig)}
					/>
					{
						selectedConfig.authenticationType === SmtpAuthenticationType.BASIC_AUTH &&
						<>
							<h3>{intl.fm(SMTP_AUTHENTICATION_TYPE.BASIC_AUTH.title)}</h3>
							<ValidationInput
								type={"string"}
								label={intl.fm("tenant.admin.email.username")}
								value={selectedConfig.username}
								onChange={onValueChange("username", selectedConfig, setSelectedConfig)}
							/><br />
							<ValidationInput
								type={"password"}
								label={intl.fm("tenant.admin.email.password")}
								value={selectedConfig.password}
								onChange={onValueChange("password", selectedConfig, setSelectedConfig)}
							/>
						</>
					}
					{
						selectedConfig.authenticationType === SmtpAuthenticationType.XOAUTH2 &&
						<>
							<h3>{intl.fm(SMTP_AUTHENTICATION_TYPE.XOAUTH2.title)}</h3>
							<Alert severity={"error"}>{intl.fm("common.notYetImplemented")}</Alert>
						</>
					}
				</>
			</HlcrDialog>
		}
		{
			testEmailConfiguration && <HlcrDialog
				title={"tenant.admin.email.testEmailTitle"}
				actions={{
					[HlcrDialogActionType.CancelAction]: {
						title: "common.labels.cancel",
						onClick: () => setTestEmailConfiguration(undefined),
						color: "default",
					},
					[HlcrDialogActionType.SaveAction]: {
						title: "tenant.admin.email.testEmailTitle",
						onClick: () => sendTestMail(),
						color: "primary",
					},
				}}
			>
				<ValidationInput
					label={intl.fm("tenant.admin.email.testEmailRecipientAddress")}
					value={testMailRecipientAddress}
					required={true}
					onChange={(value: string) => setTestMailRecipientAddress(value)}
					validations={{ required: true }}
				/>
			</HlcrDialog>
		}
		<div style={{ height: 400 }}>
			<DataGrid
				sortModel={[ { field: "sortOrder", sort: "asc" } ]}
				columns={columns}
				rows={tenantEmailConfigurationState.data || []}
				pagination={undefined}
				disableColumnFilter={true}
				checkboxSelection={false}
				disableSelectionOnClick={true}
				disableColumnMenu={true}
				onRowDoubleClick={handleOnRowDoubleClick}
			/>
		</div>
	</RouteDetailCard>;
};

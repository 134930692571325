import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators, compose } from "redux";

import managerApi from "actions/manager";
import { Button } from "@hlcr/mui/Button";
import ValidationInput from "components/CustomInput/ValidationInput";
import { CustomSelect } from "components/CustomSelect/CustomSelect";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import { Upload } from "@hlcr/mui/Upload";
import ModalWindow from "components/ModalWindow/ModalWindow";
import { getDateInputValue } from "helper/dateCalc";
import { endDateIsGreaterValidation } from "helper/validations";
import { fetchHelp } from "variables/helpPage";

import newEventModalStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/newEventModalStyle";
import { withIntl } from "@hlcr/ui/Intl";
import { AccessibilitySelectList, EventAccessibility, EventVisibility, VisibilitySelectList } from "variables/constants";
import { EventType } from "models/EventType";

const emptyEvent = {
	name: "",
	description: "",
	startTime: "",
	endTime: "",
	image: "",
	visibility: EventVisibility.PUBLIC,
	accessibility: EventAccessibility.OPEN,
	ranked: false,
	published: false
};

const emptyState = {
	event: emptyEvent,
	file: null,
	validation: {
		name: true,
		startTime: true,
		endTime: true,
		description: true
	}
};

class NewEventModal extends React.Component {
	state = { ...emptyState };

	focusRef = createRef();

	static getDerivedStateFromProps(props, state) {
		if (!props.isOpen) return { ...emptyState };
		if (props.eventTemplate && props.eventTemplate.id !== state.event.id)
			return { event: props.eventTemplate };
		return null;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.isOpen && this.props.isOpen) {
			setTimeout(() => {
				if (this.focusRef.current) this.focusRef.current.focus();
			}, 200);
		}
	}

	render() {
		const {
			intl,
			classes,
			isOpen,
			eventType,
			handleClose,
			helpUrl
		} = this.props;
		const { file, event, validation } = this.state;
		const isFullValid = Object.values(validation).reduce(
			(res, value) => res && value,
			true
		);
		const startTime = getDateInputValue(event.startTime);
		const endTime = getDateInputValue(event.endTime);

		return (
			isOpen && (
				<ModalWindow
					open={isOpen}
					onClose={handleClose}
					title={
						eventType === EventType.CURRICULUM
							? intl.fm("manager.eventOverview.newCurriculum.title")
							: intl.fm("manager.eventOverview.newEvent.title")
					}
					helpLink={fetchHelp(helpUrl, "manager", "createEvent")}
					actionSection={
						<div className={classes.actions}>
							<Button onClick={this.clearForm} color="defaultNoBackground">
								{intl.fm("common.labels.clearForm")}
							</Button>
							<Button
								disabled={!isFullValid}
								onClick={this.submit}
								color="infoNoBackground"
							>
								{intl.fm("common.labels.submit")}
							</Button>
						</div>
					}
					fullWidth
				>
					<form>
						<ValidationInput
							label={intl.fm("event.field.name")}
							value={event.name}
							onChange={this.updateField("name")}
							validations={{ required: true, minLength: 1, maxLength: 100 }}
							fullWidth
							inputProps={{ inputRef: this.focusRef }}
						/>
						<div className={classes.row}>
							<ValidationInput
								type="date"
								value={startTime}
								onChange={this.updateField("startTime")}
								label={intl.fm("common.labels.chooseBeginDate")}
								validations={{ required: true }}
							/>
							<ValidationInput
								type="date"
								value={endTime}
								onChange={this.updateField("endTime")}
								label={intl.fm("common.labels.chooseEndDate")}
								validations={{
									custom: endDateIsGreaterValidation(
										event.startTime,
										validation.startTime
									)
								}}
							/>
						</div>
						{eventType !== EventType.CURRICULUM_EVENT && (
							<div className={classes.row}>
								<CustomSelect
									required={true}
									label={intl.fm("event.field.visibility")}
									value={event.visibility}
									menuItems={VisibilitySelectList}
									idField="id"
									displayField="text"
									onChange={this.updateVisibility}
								/>
								<CustomSelect
									required={true}
									label={intl.fm("event.field.accessibility")}
									value={event.accessibility}
									menuItems={AccessibilitySelectList}
									idField="id"
									displayField="text"
									onChange={this.updateAccessibility}
								/>
							</div>
						)}
						<ValidationInput
							type="text"
							value={event.description}
							onChange={this.updateField("description")}
							label={intl.fm("event.field.description")}
							validations={{ required: true, minLength: 1, maxLength: 500 }}
							inputProps={{
								multiline: true,
								rows: 4
							}}
							fullWidth
						/>
						{eventType !== EventType.CURRICULUM && (
							<div>
								<InputLabel className={classes.inputLabel}>
									{intl.fm("event.field.ranked")}
								</InputLabel>
								<CustomSwitch checked={event.ranked} onChange={this.switchRanked} />
							</div>
						)}
						<Upload type="image" handleProcessedFile={this.updateFile} file={file} />
					</form>
				</ModalWindow>
			)
		);
	}

	submit = () => {
		const { event, file } = this.state;
		const {
			parentId,
			addEvent,
			addCurriculumEvent,
			cloneEvent,
			eventType,
			onSuccess
		} = this.props;

		const eventWithType = { ...event, type: eventType, parent: parentId };
		const image = file ? file.byteData : undefined;

		if (eventType === EventType.CURRICULUM_EVENT) {
			addCurriculumEvent(eventWithType, parentId, image, onSuccess);
		} else if (event.id) {
			cloneEvent(eventWithType, image, this.redirectToEditPage);
		} else {
			addEvent(eventWithType, image, this.redirectToEditPage);
		}
	};

	clearForm = () =>
		this.setState(prevState => ({
			event: { ...emptyEvent, type: prevState.eventType },
			file: null
		}));

	updateField = fieldName => (value, valid) => {
		this.setState(state => ({
			event: { ...state.event, [fieldName]: value },
			validation: { ...state.validation, [fieldName]: valid }
		}));
	};

	updateFile = file => this.setState({ file });

	switchRanked = () =>
		this.setState(prevState => ({
			event: {
				...prevState.event,
				ranked: !prevState.event.ranked
			}
		}));

	updateVisibility = event =>
		this.setState(prevState => ({ event: { ...prevState.event, visibility: event.target.value } }));

	updateAccessibility = event =>
		this.setState(prevState => ({
			event: {
				...prevState.event,
				accessibility: event.target.value
			}
		}));

	redirectToEditPage = eventId => {
		const { history } = this.props;
		this.setState({ isModalOpen: false });

		// DIRTY HACK: Fix the issue, where the manager event details page tries to load the image before it is there.
		// I couldn't find a better solution, without re-writing the whole manager event details page.
		setTimeout(() => history.push(`/manager/events/${eventId}`), 500);
	};
}

NewEventModal.propTypes = {
	helpUrl: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	parentId: PropTypes.number,
	eventType: PropTypes.string,
	eventTemplate: PropTypes.object,
	isOpen: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	addEvent: PropTypes.func.isRequired,
	addCurriculumEvent: PropTypes.func.isRequired,
	cloneEvent: PropTypes.func.isRequired,
	onSuccess: PropTypes.func
};

const mapStateToProps = state => ({ helpUrl: state.branding.helpUrl });

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			addEvent: managerApi.addEvent,
			addCurriculumEvent: managerApi.addCurriculumEvent,
			cloneEvent: managerApi.cloneEvent
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withRouter,
	withIntl,
	withStyles(newEventModalStyle)
)(NewEventModal);

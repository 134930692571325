import { formatMessage } from "@hlcr/ui/Intl";
import { Tooltip } from "@material-ui/core";
import { Badge } from "@hlcr/mui";
import EditIcon from "@material-ui/icons/Edit";
import ReopenIcon from "@material-ui/icons/LockOpen";
import cx from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, useMemo } from "react";
import { Link } from "react-router-dom";

import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import CustomInput from "components/CustomInput/CustomInput";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import NoData from "components/NoData/NoData";
import EnhancedTable, { DIRECTION } from "components/Table/EnhancedTable";
import { formatDateOnly } from "helper/dateCalc";
import { getSolutionOrQuizSolutionState, SolutionState } from "models/SolutionState";
import { ASSET_TYPES } from "models/Asset";
import { filterCoexaminationStatiIfNotCoexamination, computeSolutionStatiForCoexamination } from "views/Teacher/CoexaminationExtensionFunctions";
import { useUserProfile } from "hooks/userprofile";

class TeacherSolutionsCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: localStorage.getItem(this.getLocalStorageItemKey()) ?? "",
			filteredSolutions: filterSolutions(localStorage.getItem(this.getLocalStorageItemKey()), props.solutions, props.isChallenge),
		};
	}

	componentDidUpdate(prevProps) {
		const { filter } = this.state;
		const { solutions, isChallenge } = this.props;

		if (prevProps.solutions !== solutions) {
			const filteredSolutions = filterSolutions(filter, solutions, isChallenge);
			this.setState({ filteredSolutions });
		}
	}

	render() {
		const { filter, filteredSolutions } = this.state;
		const { eventId, event, isPending, isChallenge, intl, classes, reopenQuizSolution, solutionStates, gradings } = this.props;

		return (
			<IconCard
				title={
					<div>
						{intl.fm(isChallenge ? ASSET_TYPES.CHALLENGE.title : ASSET_TYPES.QUIZ.title)} {intl.fm("teacher.event.solutions.title")}
						<div className={classes.filterField}>
							<CustomInput
								inputProps={{
									type: "inputSearch",
									value: filter,
									onChange: this.updateFilter,
									placeholder: intl.fm("common.labels.filter"),
								}}
								formControlProps={{ classes: { root: cx(classes.filterFieldObj, classes.filterFieldInput) } }}
							/>
						</div>
					</div>
				}
				icon={isChallenge ? ASSET_TYPES.CHALLENGE.icon : ASSET_TYPES.QUIZ.icon}
				iconColor="purple"
				content={
					isPending ? (
						<LoadingSpinner />
					) : (
						<SolutionsTable
							solutions={filteredSolutions}
							gradings={gradings}
							eventId={eventId}
							event={event}
							enityName={isChallenge ? "challenge" : "quiz"}
							isChallenge={isChallenge}
							intl={intl}
							classes={classes}
							reopenQuizSolution={reopenQuizSolution}
							solutionStates={solutionStates}
						/>
					)
				}
			/>
		);
	}

	updateFilter = (event) => {
		const { solutions, isChallenge } = this.props;
		const filter = event.currentTarget.value;
		const filteredSolutions = filterSolutions(filter, solutions, isChallenge);
		localStorage.setItem(this.getLocalStorageItemKey(), filter);
		this.setState({ filter, filteredSolutions });
	};

	getLocalStorageItemKey() {
		const { isChallenge, eventId } = this.props;
		return `TeacherSolutions::${isChallenge ? "challenge" : "quiz"}::${eventId}::filter`;
	}
}


const filterSolutions = (filter, solutions, isChallenge) => {
	if (!filter) {
		if (isChallenge) {
			return solutions;
		}
		return solutions.filter(solution => solution.finished); // XXX: server sends us unfinished quizzes...
	}
	const entityName = isChallenge ? "challenge" : "quiz";
	return solutions.filter(solution =>
		   (isChallenge || solution.finished) // XXX: server sends us unfinished quizzes...
		&& (   solution[entityName]?.title.toLowerCase().includes(filter.toLowerCase())
		    || solution.user?.username?.toLowerCase().includes(filter.toLowerCase())
		    || solution.user?.firstName?.toLowerCase().includes(filter.toLowerCase())
		    || solution.user?.lastName?.toLowerCase().includes(filter.toLowerCase())
		   )
	);
};

const getSolutionUrl = (isChallenge, eventId, id) => isChallenge ? `/teacher/events/${eventId}/solutions/${id}` : `/teacher/events/${eventId}/quizSolutions/${id}`;

const SolutionsTable = ({ solutions, gradings, eventId, event, enityName, isChallenge, intl, classes, reopenQuizSolution, solutionStates }) => {
	const EditButton = () => (
		<Tooltip title={intl.fm("teacher.event.solution.action.grade")} aria-label="grade">
			<Button color="info" customClass={classes.editButton}>
				<EditIcon className={classes.icon} />
			</Button>
		</Tooltip>
	);

	const tableRenderer = useMemo(() => {
		const challengeColumns = isChallenge ? [
			{
				id: "date",
				title: intl.fm("teacher.event.solution.date"),
				renderCell: solution =>
					solution.lastEdited ? formatDateOnly(solution.lastEdited) : "",
				sort: sortByLastEdited
			}
		] : [];

		const getReopenButton = (quizSolutionId) =>
			quizSolutionId && (
				<Tooltip title={intl.fm("teacher.event.quizsolution.action.reopen")} aria-label="reopen">
					<Button color="warning" customClass={classes.editButton} onClick={() => reopenQuizSolution?.(quizSolutionId)}>
						<ReopenIcon className={classes.icon} />
					</Button>
				</Tooltip>
			);

		const displayName = (solution) => (solution.user.firstName || solution.user.lastName) ? `${solution.user.firstName || '?'} ${solution.user.lastName || '?'} (${solution.user.username})` : solution.user.username;

		return [
			{
				id: "name",
				title: intl.fm("teacher.event.solution.name"),
				renderCell: displayName,
				sort: true
			},
			{
				id: "title",
				title: intl.fm("teacher.event.solution.title"),
				renderCell: solution =>
					solution[enityName] && solution[enityName].title,
				sort: true
			},
			{
				id: "status",
				title: intl.fm("teacher.event.solution.status"),
				renderCell: solution => <Badge color={getSolutionOrQuizSolutionState(solution)?.color ?? "secondary"}>{formatMessage(getSolutionOrQuizSolutionState(solution)?.title ?? "")}</Badge>,
				filter: {
					name: "status",
					func: (solution, value) => value === undefined || solution.state === value,
					dialog: callback => (
						<div className={classes.filterBox}>
							<Button onClick={callback()} color="primary">
								{intl.fm("common.labels.all")}
							</Button>
							{Object.entries(solutionStates)
								.filter(([ key, _ ]) => key !== SolutionState.INITIALISED)
								.filter(([ key, _ ]) => filterCoexaminationStatiIfNotCoexamination(event, key))
								.map(([ key, state ]) => (
									<Button
										onClick={callback(key)}
										color={state.color}
										key={key}
									>
										{formatMessage(state.title)}
									</Button>
								))}
						</div>
					)
				}
			},
			{
				id: "percent",
				title: intl.fm("teacher.event.solution.points"),
				align: "right",
				renderCell: solution => solution[enityName] ? `${solution.points} / ${solution[enityName].maxPoints}` : "",
				sort: (a, b) => b.points - a.points
			},
			...challengeColumns,
			{
				id: "editButton",
				renderCell: solution => (
					<React.Fragment>
						<Link to={getSolutionUrl(isChallenge, eventId, solution.id)}>
							<EditButton />
						</Link>
						{getReopenButton(!isChallenge && solution.id)}
					</React.Fragment>
				)
			}
		];
	}, [ eventId, enityName, isChallenge, intl, classes, reopenQuizSolution ]);

	const isDataAvailable = Array.isArray(solutions) && solutions.length > 0;
	const userprofile = useUserProfile()
	return (
		<EnhancedTable
			tableRenderer={isDataAvailable ? tableRenderer : []}
			tableData={computeSolutionStatiForCoexamination(solutions, event, gradings, userprofile.id)}
			defaultSortColumnId="date"
			defaultSortDirection={DIRECTION.DESC}
			emptyContent={<NoData text={intl.fm("teacher.event.noResources")} />}
			persistSortFor={`TeacherSolutions::${enityName}::${eventId}`}
			paginate={50}
			hover
		/>
	);
};

const sortByLastEdited = (a, b) => {
	if (a.lastEdited === b.lastEdited) return 0;
	if (!a.lastEdited) return 1;
	if (!b.lastEdited) return -1;
	return moment(a.lastEdited).isAfter(b.lastEdited) ? -1 : 1;
};

TeacherSolutionsCard.propTypes = {
	eventId: PropTypes.number.isRequired,
	event: PropTypes.object.isRequired,
	solutions: PropTypes.array.isRequired,
	isPending: PropTypes.bool,
	isChallenge: PropTypes.bool,
	intl: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	reopenQuizSolution: PropTypes.func,
};

export default TeacherSolutionsCard;

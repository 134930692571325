import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import React, { Fragment } from "react";

import Tooltip from "components/CustomTooltip/CustomTooltip";

import Categories from "../assets/img/categories";

const styles = {
	categoryImage: {
		width: 36,
		height: 36
	},
	spacer: { marginRight: 6 }
};

let CategoryIcons = ({ categories, classes, tooltipPlacement = "top" }) => (
	<Fragment>
		{categories.map((c, index) => {
			const imageSrc = categoryImages[c.name];
			return imageSrc ? (
				<Tooltip
					title={<span>{c.name.toUpperCase().replace("_", " ")}</span>}
					placement={tooltipPlacement}
					key={index}
				>
					<img
						src={imageSrc}
						alt={c.name}
						className={cx(classes.categoryImage, { [classes.spacer]: index !== categories.length - 1 })}
					/>
				</Tooltip>
			) : (
				<span
					className={cx({ [classes.spacer]: index !== categories.length - 1 })}
					key={index}
				>
					{c.name.toUpperCase().replace("_", " ")}
				</span>
			);
		})}
	</Fragment>
);

CategoryIcons = withStyles(styles)(CategoryIcons);

export { CategoryIcons };

const categoryImages = {
	web_security: Categories.WebSecurity,
	exploitation: Categories.Exploitation,
	crypto: Categories.Crypto,
	reverse_engineering: Categories.ReverseEngineering,
	forensic: Categories.Forensic,
	penetration_testing: Categories.PenetrationTesting,
	network_security: Categories.NetworkSecurity,
	database_security: Categories.DatabaseSecurity,
	malware: Categories.Malware,
	programming: Categories.Programming,
	linux: Categories.Linux,
	windows: Categories.Windows,
	osx: Categories.Osx,
	android: Categories.Android,
	ios: Categories.Ios,
	wireless: Categories.Wireless,
	phone: Categories.Phone,
	defense: Categories.Defense,
	fun: Categories.Fun,
	social_engineering: Categories.SocialEngineering,
	opensource_intelligence: Categories.OpenSourceIntelligence
};

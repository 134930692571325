import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import React from "react";

const style = {
	grid: {
		margin: "0 -15px",
		width: "calc(100% + 30px)"
	}
};

function GridContainer({ classes, children, className, ...rest }) {
	return (
		<Grid container {...rest} className={cx(classes.grid, className)}>
			{children}
		</Grid>
	);
}

export default withStyles(style)(GridContainer);

export default {
	thumbPaper: {
		display: "inline-block",
		borderRadius: 3
	},
	thumb: {
		height: 72,
		width: 110,
		objectFit: "cover",
		borderRadius: 3
	},
	editButton: {
		margin: "5px 2px",
		padding: "5px",
		whiteSpace: "normal"
	},
	icon: {
		verticalAlign: "middle",
		width: "17px",
		height: "17px",
		top: "-1px",
		position: "relative",
	},
	eventDescription: {
		height: "100%",
		maxWidth: "40vw",
		"& a": { color: "inherit" },
	},
	eventTitleWrapper: {
		height: "100%",
		display: "flex",
		alignItems: "center",
	},
	eventTitle: {
		fontSize: "1.2em",
		fontWeight: 400,
	},
	parentTitle: {
		fontSize: "0.9em",
		opacity: "0.8",
	},
	container: {
		display: "inline-block",
		"& > div ~ div": { paddingLeft: 5 },
		width: "100%"
	},
	alignRight: { textAlign: "right" },
	right: {
		float: "right",
		marginLeft: 5
	},
	left: {
		float: "left",
		marginRight: 5
	},
	inlineSwitchBlock: {
		display: "inline-block",
		float: "right",
		marginRight: 5,
	},
	inlineSwitchLabel: {
		display: "inline",
		fontSize: "0.9rem",
	},
	mouseHover: {
		height: "100%",
		cursor: "pointer",
	},
	buttonContainer: { display: "inline-block" },
	text: { marginLeft: 5 },
	filterInputControl: {
		float: "right",
		marginTop: "-22px",
		marginBottom: 0,
	},
	tokenValue: {
		fontFamily: "\"Consolas\", monospace",
		whiteSpace: "nowrap",
	}
};

export default {
	"common.input.required": "{label} *",
	"common.input.required.conditional": "{label} (*)",
	"common.input.required.optional": "{label}",

	"input.select.undefined": "Keine Anagabe",

	"gender.male": "Männlich",
	"gender.female": "Weiblich",
	"gender.diverse": "Divers",

	"user.profile.tenantPolicyViolated": "Deinem Profil fehlen einige Angaben, damit du diese Hacking-Lab Cyber Range nutzen kannst.",

	"common.notYetImplemented": "Dieses Feature is noch nicht implementiert",
	"common.labels.goto": "Go to",
	"common.labels.export": "Export",

	"navigation.entries.events": "Events",
	"navigation.entries.teacher": "Teacher",
	"navigation.entries.participants": "Participants",
	"navigation.entries.administration": "Administration",
	"navigation.entries.report": "Personal Report",
	"navigation.entries.classesReport": "Classes Report",
	"navigation.entries.classReport": "Class Report",
	"navigation.entries.teamsReport": "Teams Report",
	"navigation.entries.usersReport": "Users Report",
	"navigation.entries.userReport": "User Report",
	"navigation.entries.manager": "Manager",
	"navigation.entries.eventManagement": "Event Management",
	"navigation.entries.teamsManagement": "Teams Management",
	"navigation.entries.vmsManagement": "VM Management",
	"navigation.entries.usersManagement": "Users Management",
	"navigation.entries.classesManagement": "Classes Management",
	"navigation.entries.help": "Help",
	"navigation.entries.user.account": "Account",
	"navigation.entries.user.profile": "Profile",
	"navigation.entries.user.logout": "Logout",
	"navigation.entries.user.inbox": "Nachrichten",

	"asset.difficulty.novice": "Novice",
	"asset.difficulty.easy": "Easy",
	"asset.difficulty.medium": "Medium",
	"asset.difficulty.hard": "Hard",
	"asset.difficulty.leet": "Leet",

	"messaging.inbox.composeNew": "Neue Mitteilung",
	"messaging.compose.send": "Senden",
	"messaging.compose.cancel": "Abbrechen",

	"messaging.compose.title": "Neue Mitteilung",
	"messaging.compose.label.subject": "Betreff",
	"messaging.compose.label.assets": "Betroffene Assets",
	"messaging.compose.label.events": "Betroffene Events",
	"messaging.compose.label.messageBody": "Mitteilung",
	"messaging.compose.confirmSendEveryoneTitle": "An alle senden?",
	"messaging.compose.confirmSendEveryoneMessageTeacher": "Du bist im Begriff, diese Nachricht an alle Benutzer zu senden, die an einer Veranstaltung teilnehmen, bei der du als Lehrer eingetragen bist.",
	"messaging.compose.confirmSendEveryoneMessageManager": "Du bist im Begriff, diese Nachricht an alle Benutzer zu senden, die an einer aktiven Veranstaltung teilnehmen.",

	"solution.history.markdown.disable": "Kein Markdown verwenden",
	"solution.history.solutionInstr": "Beschreibe deine Lösung (Markdown erlaubt)",

	"solution.state.answered": "Beantwortet",

	"solution.gradinginstruction.userFlag": "Valide Flags",

	"teacher.event.solution.action.grade": "Anschauen / bewerten",
	"teacher.event.solution.status": "Status",
	"teacher.event.solution.times": "Zeiten",
	"teacher.event.quizsolution.action.reopen": "Zur Bearbeitung freigeben",

	"teacher.grading.grade": "Bewertung",
	"teacher.grading.comment": "Kommentar",

	"challenge.comment.newSolution.titleTeacher": "{what} abgeben",

	"teacher.solution.noTeacherComment": "Keine Bewertung abgegeben",
	"teacher.solution.noUserComment": "Keine Lösung eingereicht",
	"teacher.solution.grading.title": "Lehrer Bewertung",
	"teacher.solution.grading.acceptWithoutComment": "Accept without comment",
	"teacher.solution.grading.rejectWithoutComment": "Reject without comment",
	"teacher.solution.grading.addGrade": "Bewertung hinzufügen",

	"teacher.solution.lastUpdate": "Letzte Änderung: {date}",
	"teacher.solution.lastGradingUpdate": "Letzte Bewertung: {date}",
	"teacher.solution.lastUserSolution": "Letzte Lösung: {date}",
	"teacher.solution.feedbackInstr": "Kommentar zum Writeup (Markdown erlaubt)",
	"teacher.solution.previewChallenge": "Challenge in neuem Tab öffnen",

	"manager.eventDetails.units.table.flagWeightPercentage": "Flag Gewichtung in % (0 - 100)",
	"manager.eventDetails.users.teacher.overwriteWarning": "Änderungen gelten für alle untergeordneten events",

	"manager.eventOverview.date.startOnly": "Ab {startTime}",
	"manager.eventOverview.date.endOnly": "Bis {endTime}",
	"manager.eventOverview.date.startAndEnd": "{startTime} - {endTime}",
	"manager.eventOverview.date.open": "Uneingeschränkt",

	"teacher.solution.grading.flagPoints": "{flagPoints} (Flag)",
	"teacher.solution.grading.writeupPoints": "{writeupPoints} (Writeup)",
	"teacher.solution.grading.totalLabel": "Total: ",
	"teacher.solution.grading.ofMaxPoints": " von {maxPoints} Punkten",
	"teacher.solution.points.flag": "Flag ({flagPoints}/{maxFlagPoints})",
	"teacher.solution.points.writeup": "Writeup ({writeupPoints}/{maxWriteupPoints})",
	"teacher.solution.points.total": "{points}/{maxPoints}",

	"flag.type.static": "Statisches Flag",
	"flag.type.dynamic": "Dynamisches Flag",
	"flag.type.none": "Kein Flag",

	"user.label.emailDigest": "Mitteilungen als E-Mail erhalten",

	"user.inbox.title": "Postfach",
	"user.inbox.noMessageSelected": "Keine Nachricht ausgewählt",
	"user.inbox.header.sender": "Absender",
	"user.inbox.header.receivedDateTime": "Datum",
	"user.inbox.header.subject": "Betreff",
	"user.inbox.header.actions": "Aktionen",
	"user.inbox.selectedMessages": "Nachricht(en) ausgewählt",
	"user.inbox.newGradingSubject": "Neue Bewertung verfügbar",
	"user.inbox.newGradingBody": "Eine von dir gelöste Challenge wurde bewertet.",
	"user.inbox.newSolutionSubject": "Neue Lösung verfügbar",
	"user.inbox.newSolutionBody": "Es gibt eine neue Lösung welche von dir bewertet werden kann.",
	"user.inbox.messageLinks": "Verknüpfungen",
};

import { HackingLabRoute, HackingLabRouteType } from "@hlcr/app/enum/EnumRoute";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import DashboardIcon from "@material-ui/icons/Dashboard";

import About from "components/About/About";
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy";
import { EVENT_MANAGER_ROUTES } from "eventManager/eventManager.routes";
import { Inbox } from "messaging/components/Inbox";
import { eventRoutes } from "routes/eventRoutes";
import { reportRoutes } from "routes/reportRoutes";
import { teacherRoutes } from "routes/teacherRoutes";
import { teamRoutes } from "routes/teamRoutes";
import { TENANT_ADMIN_ROUTES } from "tenantAdmin/tenantAdmin.routes";
import { Profile } from "userProfile/components/Profile";
import HackingLabDashboard from "views/Dashboard/HackingLabDashboard";


export const PROFILE_PATH = "/profile";

export const dashboardRoutes: HackingLabRoute[] = [
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/inbox",
		component: Inbox,
		needsRole: HackingLabRole.COMPOSITE_USER,
		breadcrumb: "Inbox",
		help: { role: "user", action: "pageInbox" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: PROFILE_PATH,
		component: Profile,
		needsRole: HackingLabRole.COMPOSITE_USER,
		breadcrumb: "Profile",
		help: { role: "user", action: "pageProfile" },
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/dashboard",
		name: "Dashboard",
		icon: DashboardIcon,
		component: HackingLabDashboard,
		needsRole: HackingLabRole.COMPOSITE_USER,
		hasSidebarLink: true,
		breadcrumb: "Dashboard",
		help: { role: "user", action: "pageDashboard" },
	},
	...eventRoutes,
	...teamRoutes,
	...teacherRoutes,
	...Object.values(EVENT_MANAGER_ROUTES),
	...Object.values(TENANT_ADMIN_ROUTES),
	...reportRoutes,
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/privacy",
		component: PrivacyPolicy,
		breadcrumb: "Privacy Policy",
	},
	{
		type: HackingLabRouteType.COMPONENT_ROUTE,
		path: "/about",
		component: About,
		breadcrumb: "About",
	},
	{
		type: HackingLabRouteType.REDIRECT_ROUTE,
		from: "/",
		to: "/events",
		name: "",
		roleMapping: {
			[HackingLabRole.COMPOSITE_MANAGER]: "/manager",
			[HackingLabRole.COMPOSITE_REPORTS]: "/report/classes",
			[HackingLabRole.COMPOSITE_TEACHER]: "/teacher",
			[HackingLabRole.COMPOSITE_USER]: "/events",
		},
	},
];

import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import managerApi from "actions/manager";
import { filterInputStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamOverviewStyle";
import IconCard from "components/Cards/IconCard";
import TableIconButton from "components/CustomButtons/TableIconButton";
import CustomInput from "components/CustomInput/CustomInput";
import NoData from "components/NoData/NoData";
import EnhancedTable from "components/Table/EnhancedTable";
import applyFilter from "helper/applyFilter";
import { withIntl } from "@hlcr/ui/Intl";

import { ResourcePropertiesModal } from "../Events/Resources";

const Actions = ({
	intl,
	vm,
	onStartVm,
	onStopVm,
	onResetVm,
	onRevertVm,
	isDisabled
}) => (
	<React.Fragment>
		<TableIconButton
			onClick={() => {
				onStartVm(vm);
			}}
			disabled={isDisabled(vm) || vm.state === "POWERED_ON"}
			title={intl.fm("manager.vms.actions.start")}
			color="info"
			faIcon="play"
		/>
		<TableIconButton
			onClick={() => {
				onStopVm(vm);
			}}
			disabled={isDisabled(vm) || vm.state === "POWERED_OFF"}
			title={intl.fm("manager.vms.actions.stop")}
			color="info"
			faIcon="stop"
		/>
		<TableIconButton
			onClick={() => {
				onResetVm(vm);
			}}
			disabled={isDisabled(vm) || vm.state === "POWERED_OFF"}
			title={intl.fm("manager.vms.actions.reset")}
			color="info"
			faIcon="redo-alt"
		/>
		{vm.revertToSnapshot && (
			<TableIconButton
				onClick={() => {
					onRevertVm(vm);
				}}
				disabled={isDisabled(vm)}
				title={intl.fm("manager.vms.actions.revert")}
				color="warning"
				faIcon="exchange-alt"
			/>
		)}
	</React.Fragment>
);

const createTableRenderer = (
	intl,
	onStartVm,
	onStopVm,
	onResetVm,
	onRevertVm,
	openResourceModal,
	isDisabled
) => [
	{
		id: "props",
		renderCell: resource => {
			const disabled =
				!resource.userProperties ||
				(Object.keys(resource.userProperties).length === 0 &&
					resource.userProperties.constructor === Object);
			return (
				<TableIconButton
					disabled={disabled}
					onClick={openResourceModal(resource)}
					color={disabled ? "defaultNoBackground" : "info"}
					size="xs"
					faIcon="desktop"
				/>
			);
		},
		toolTip: () => intl.fm("challenge.resource.showProps")
	},
	{
		id: "vmsName",
		title: intl.fm("manager.vms.name"),
		renderCell: vm => vm.displayName,
		sort: true
	},
	{
		id: "vmsState",
		title: intl.fm("manager.vms.state"),
		renderCell: vm => vm.state,
		sort: true
	},
	{
		id: "vmsActions",
		title: intl.fm("manager.vms.actions"),
		renderCell: vm => (
			<Actions
				intl={intl}
				vm={vm}
				onStartVm={onStartVm}
				onStopVm={onStopVm}
				onResetVm={onResetVm}
				onRevertVm={onRevertVm}
				isDisabled={isDisabled}
			/>
		)
	}
];

const VmTable = ({
	managerVms,
	onStartVm,
	onStopVm,
	onResetVm,
	onRevertVm,
	openResourceModal,
	intl,
	isDisabled
}) => {
	return (
		<EnhancedTable
			tableRenderer={createTableRenderer(
				intl,
				onStartVm,
				onStopVm,
				onResetVm,
				onRevertVm,
				openResourceModal,
				isDisabled
			)}
			tableData={managerVms}
			hover
			emptyContent={<NoData text={intl.fm("manager.vms.noResources")} />}
		/>
	);
};

VmTable.defaultProps = { managerVms: [] };

const FilterInput = ({
	onFilterInputChange,
	placeholder,
	formControlClassName
}) => (
	<CustomInput
		inputProps={{
			type: "inputSearch",
			onChange: onFilterInputChange,
			placeholder
		}}
		formControlProps={{ className: formControlClassName }}
	/>
);

class ManagerVMs extends React.Component {
	state = {
		filterQuery: "",
		pendingIds: [],
		isModalOpen: false,
		selectedResource: null
	};

	componentDidMount() {
		this.props.getALlVms();
	}

	onStartVm = vm =>
		this.props.startVm(
			vm,
			() => this.setPendingUnit(vm.uuid),
			() => this.resetPendingUnit(vm.uuid)
		);

	onStopVm = vm =>
		this.props.stopVm(
			vm,
			() => this.setPendingUnit(vm.uuid),
			() => this.resetPendingUnit(vm.uuid)
		);

	onResetVm = vm =>
		this.props.resetVm(
			vm,
			() => this.setPendingUnit(vm.uuid),
			() => this.resetPendingUnit(vm.uuid)
		);

	onRevertVm = vm =>
		this.props.revertVm(
			vm,
			() => this.setPendingUnit(vm.uuid),
			() => this.resetPendingUnit(vm.uuid)
		);

	setPendingUnit = uuid => {
		this.setState(state => ({
			pendingIds: [
				...state.pendingIds,
				uuid 
			]
		}));
	};

	resetPendingUnit = id => {
		this.setState(state => ({ pendingIds: state.pendingIds.filter(val => val !== id) }));
	};

	disabledVm = vm =>
		vm.state === "UNKNOWN" || this.state.pendingIds.includes(vm.uuid);

	openResourceModal = resource => () => {
		this.setState({ isModalOpen: true, selectedResource: resource });
	};

	closeModal = () => {
		this.setState({ isModalOpen: false, selectedResource: null });
	};

	onFilterInputChange = event => {
		const { filterQuery } = this.state;
		const { value } = event.currentTarget;

		if (filterQuery !== value)
			this.setState({ filterQuery: value });
	};

	render() {
		const { managerVms, classes, intl } = this.props;
		const { filterQuery, selectedResource, isModalOpen } = this.state;

		const filteredVms = applyFilter(managerVms, filterQuery, [
			"displayName",
			"state"
		]);
		return (
			<div>
				<IconCard
					faIcon="server"
					iconColor="purple"
					title={
						<div>
							{intl.fm("manager.vms.title")}
							<FilterInput
								onFilterInputChange={this.onFilterInputChange}
								placeholder={intl.fm("common.labels.filter")}
								formControlClassName={classes.formControl}
							/>
						</div>
					}
					content={
						<VmTable
							onStartVm={this.onStartVm}
							onStopVm={this.onStopVm}
							onResetVm={this.onResetVm}
							onRevertVm={this.onRevertVm}
							isDisabled={this.disabledVm}
							openResourceModal={this.openResourceModal}
							managerVms={filteredVms}
							intl={intl}
						/>
					}
				/>
				<ResourcePropertiesModal
					properties={selectedResource && selectedResource.userProperties}
					intl={intl}
					isOpen={isModalOpen}
					onClose={this.closeModal}
				/>
			</div>
		);
	}
}

export { ManagerVMs };

const mapStateToProps = state => {
	return {
		managerVms: state.api.resources.managerVms.data.sort(
			(a, b) => a.displayName > b.displayName
		),
		isLoading: state.api.resources.managerVms.pending,
		match: undefined,
		location: undefined
	};
};

const mapDispatchToProps = {
	getALlVms: managerApi.getALlVms,
	startVm: managerApi.startVm,
	stopVm: managerApi.stopVm,
	resetVm: managerApi.resetVm,
	revertVm: managerApi.revertVm
};

export default compose(
	withIntl,
	withStyles(filterInputStyles),
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(ManagerVMs);

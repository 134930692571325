const equals = (a, b) => {
	if (!a || !b || a.length !== b.length) return false;
	for (let i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;
	return true;
};

export const createMemoize = func => {
	let result;
	let params;

	return (...nextParams) => {
		if (equals(nextParams, params)) return result;

		params = nextParams;
		result = func(...nextParams);

		return result;
	};
};

import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@material-ui/icons/Person";
import moment from "moment";
import * as React from "react";

import { Button } from "@hlcr/mui/Button";
import CustomSearchSelect from "components/CustomSelect/CustomSearchSelect";
import WarningDialog from "components/ModalWindow/WarningDialog";
import { formatDateOnly } from "helper/dateCalc";

import ManagerTableCard, { ShowHelp } from "./ManagerTableCard";
import { Checkbox, Tooltip } from "@material-ui/core";
import { EventType } from "models/EventType";

export default class ManagerEventUsers extends React.Component {
	state = {
		pendingIds: [],
		deleteEntity: undefined,
	};

	onSelectUser = user => {
		const { addManagerEventUser, eventId } = this.props;
		const eventUserRequest = {
			userIdentifier: user.userIdentifier,
			teacher: !!user.isTeacher,
			eventId,
		};
		addManagerEventUser(eventUserRequest);
	};

	changeTeacher = eventUser => () => {
		const { updateManagerEventUser, eventId } = this.props;
		const updatedEventUser = {
			userIdentifier: eventUser.user.userIdentifier,
			teacher: !eventUser.teaching,
			eventId,
		};
		updateManagerEventUser(
			updatedEventUser,
			() => this.setPending(eventUser.user.userIdentifier),
			() => this.resetPending(eventUser.user.userIdentifier),
		);
	};

	cancelDelete = () => {
		this.setState({ deleteEntity: undefined });
	};

	confirmDelete = eventUser => () => {
		this.setState({ deleteEntity: eventUser });
	};

	onRemove = eventUser => () => {
		this.setState({ deleteEntity: undefined });
		this.props.removeManagerEventUser(
			eventUser,
			() => this.setPending(eventUser.user.userIdentifier),
			() => this.resetPending(eventUser.user.userIdentifier),
		);
	};

	setPending = id => {
		this.setState(state => ({
			pendingIds: [
				...state.pendingIds,
				id,
			],
		}));
	};

	resetPending = id => {
		this.setState(state => ({ pendingIds: state.pendingIds.filter(val => val !== id) }));
	};

	hasPendingChange = eventUser =>
		this.state.pendingIds.includes(eventUser.user.userIdentifier);

	render() {
		const {
			event,
			eventUsers,
			students,
			teachers,
			intl,
			classes,
		} = this.props;

		const tableRenderer = [
			{
				id: "name",
				title: intl.fm("manager.eventDetails.users.table.name"),
				renderCell: eventUser => eventUser.user.username,
				sort: true,
			},
			{
				id: "teacher",
				title: intl.fm("manager.eventDetails.users.table.teacher"),
				renderCell: eventUser =>
					(teachers.find(
						teacher => teacher.userIdentifier === eventUser.user.userIdentifier,
					) || eventUser.teaching) ? (
						<Tooltip
							title={event?.type === EventType.CURRICULUM ? intl.fm("manager.eventDetails.users.teacher.overwriteWarning") : ""}
						>
							<Checkbox
								disabled={this.hasPendingChange(eventUser)}
								checked={eventUser.teaching && eventUser.teachingAllChildEvents}
								indeterminate={eventUser.teaching && !eventUser.teachingAllChildEvents}
								onChange={this.changeTeacher(eventUser)}
							/>
						</Tooltip>
					) : (
						""
					),
				sort: (a, b) =>
					a.teaching === b.teaching ? 0 : !a.teaching && b.teaching ? 1 : -1,
			},
			{
				id: "registered",
				title: intl.fm("manager.eventDetails.users.table.registered"),
				renderCell: eventUser => formatDateOnly(eventUser.registrationTime),
				sort: (a, b) =>
					moment(a.registrationTime).isBefore(moment(b.registrationTime))
						? 1
						: -1,
			},
			{
				id: "actions",
				title: intl.fm("manager.eventDetails.users.table.actions"),
				renderCell: eventUser => (
					<Button
						color="danger"
						customClass={classes.editButton}
						disabled={this.hasPendingChange(eventUser)}
						onClick={this.confirmDelete(eventUser)}
					>
						<DeleteIcon className={classes.icon} />
					</Button>
				),
			},
		];

		const filteredTeachers = teachers.filter(
			user =>
				!eventUsers.find(
					eventUser => eventUser.user.userIdentifier === user.userIdentifier,
				),
		);
		const filteredStudents = students.filter(
			user =>
				!eventUsers.find(
					eventUser => eventUser.user.userIdentifier === user.userIdentifier,
				),
		);

		return (
			<ManagerTableCard
				tableData={eventUsers}
				title={
					<div className={classes.titleBox}>
						<div>
							{intl.fm("manager.eventDetails.users.title")}{" "}
							<ShowHelp
								userRole="manager"
								action="manageEventUsers"
								className={classes.helpIcon}
							/>
						</div>
						<div className={classes.titleBox}>
							<CustomSearchSelect
								list={filteredTeachers}
								value={null}
								onSelect={this.onSelectUser}
								displayField="username"
								additionalClasses={classes.selectBoxDistance}
								placeholder={intl.fm("manager.eventDetails.users.addTeacher")}
							/>
							{
								event && event.type !== EventType.CURRICULUM_EVENT &&
								<CustomSearchSelect
									list={filteredStudents}
									value={null}
									onSelect={this.onSelectUser}
									displayField="username"
									placeholder={intl.fm("manager.eventDetails.users.addStudent")}
								/>
							}
						</div>
						<WarningDialog
							onConfirm={this.onRemove(this.state.deleteEntity)}
							onClose={this.cancelDelete}
							entity={this.state.deleteEntity}
							entityName={intl.fm("user.entityName")}
						/>
					</div>
				}
				tableRenderer={tableRenderer}
				cardIcon={PersonIcon}
				color="purple"
				paginate={50}
			/>
		);
	}
}

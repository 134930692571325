export enum ActionType {
	API_REQUEST = "API_REQUEST",
	API_RESULT = "API_RESULT",
	API_REMOVE = "API_REMOVE",
	SAVE_AUTH_TOKEN = "SAVE_AUTH_TOKEN",
	ADD_NOTIFICATION = "ADD_NOTIFICATION",
	REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION",
	FETCH_BRANDING_CONFIG = "FETCH_BRANDING_CONFIG",
	SET_BRANDING_CONFIG = "SET_BRANDING_CONFIG",
	TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE",
}

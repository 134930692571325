import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import cx from "classnames";
import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import customDateTimeInputStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/customDateTimeInputStyle";
import { getDateInputValue } from "helper/dateCalc";


const DATE_FORMAT = "dd.MM.yyyy";
const TIME_FORMAT = "HH:mm";
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
const CustomDateTimeInput = (
	{
		value,
		onChange,
		label,
		error,
		success,
		helpText,
		rootClass,
		fullWidth,
		required,
		classes,
		dateOnly,
	},
) => {
	const labelClasses = cx(classes.labelRoot, {
		[classes.labelRootError]: error,
		[classes.labelRootSuccess]: success && !error,
	});

	const getValue = () => value?.toDate(); // `value` is of `moment`
	const setValue = (value) => onChange(getDateInputValue(value));
	const ref = React.createRef();
	return (
		<div className={rootClass}>
			<FormControl
				error={error}
				classes={{ root: classes.formControl }}
				fullWidth={fullWidth}
				required={required}
			>
				<FormLabel className={labelClasses}>{label}</FormLabel>
				<DatePicker
					selected={getValue()}
					onChange={setValue}
					customInput={<RenderInput label={label} success={success} error={error} classes={classes} />}
					showTimeSelect={!dateOnly}
					timeIntervals={15} // interval in time picker
					timeFormat={TIME_FORMAT} // format in time picker
					calendarStartDay={1} // start on Monday
					dateFormat={dateOnly ? DATE_FORMAT : DATE_TIME_FORMAT}
					ref={ref}
					showMonthDropdown={true}
					showYearDropdown={true}
				/>
				<div className={classes.adormentContainer}>
					{helpText !== undefined && (
						<FormHelperText>{helpText}</FormHelperText>
					)}
				</div>
			</FormControl>
		</div>
	);
};

const RenderInput = React.forwardRef((props, ref) => {
	const { label, error, success, classes, ...inputProps } = props;

	const successClasses = cx(classes.feedback, classes.labelRootSuccess, { [classes.feedbackNoLabel]: label === undefined });
	const errorClasses = cx(classes.feedback, classes.labelRootError, { [classes.feedbackNoLabel]: label === undefined });
	return (
		<Input
			{...inputProps}
			endAdornment={
				error
					? (<Clear className={errorClasses} />)
					: (success && <Check className={successClasses} />)
			}
			ref={ref}
		/>
	);
});


export default withStyles(customDateTimeInputStyle)(CustomDateTimeInput);

import { getDevelopmentToken } from "@hlcr/app/model/HackingLabRole";
import { keycloak } from "@hlcr/ui/auth";

import "chartist/dist/chartist.js";

import "@hlcr/mui/theme/hacking-lab.scss";
import "helper/icons";
import "polyfills.js";
import { createApp } from "App";
import { isLocalServerEnvironment, isProductionEnvironment } from "ProcessEnvironment";

// lazily load app if authenticated
keycloak.init(() => createApp(), !isProductionEnvironment() && isLocalServerEnvironment() ? getDevelopmentToken() : undefined);


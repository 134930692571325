import { CustomSelectProps } from "components/CustomSelect/CustomSelect";
import { ObjectSelect } from "components/CustomSelect/ObjectSelect";

export interface EventSelectProps extends Omit<CustomSelectProps, "value" | "onChange" | "menuItems"> {
	selectedEvent?: ChallengeReportEvent;
	events: ChallengeReportEvent[];
	onEventChange: (eventId?: ChallengeReportEvent["eventId"]) => void;
}

export interface ChallengeReportEvent {
	eventId: number;
	eventName: string;
	mandatory: boolean;
}

export const EventSelect = ({ selectedEvent, events, onEventChange }: EventSelectProps) => {

	const handleOnChange = (eventId: string | number) => {
		if (eventId) {
			onEventChange(eventId as number);
		}
	};

	return <ObjectSelect
		selectedObject={selectedEvent}
		objects={events as any[]}
		onObjectSelect={handleOnChange}
		title={"Event"}
		displayField={"eventName"}
		idField={"eventId"}
		required={true}
	/>;
};

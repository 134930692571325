import DeleteIcon from "@material-ui/icons/Delete";
import SchoolIcon from "@material-ui/icons/Person";
import * as React from "react";

import { Button } from "@hlcr/mui/Button";
import CustomSearchSelect from "components/CustomSelect/CustomSearchSelect";
import WarningDialog from "components/ModalWindow/WarningDialog";

import ManagerTableCard from "./ManagerTableCard";

export default class ManagerClassTeachers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pendingIds: [],
			deleteEntity: undefined
		};
	}

	onSelectUser = user => {
		const { addManagerClassTeacher, classId } = this.props;
		const classTeacherRequest = {
			userIdentifier: user.userIdentifier,
			classId
		};
		addManagerClassTeacher(classTeacherRequest);
	};

	cancelDelete = () => {
		this.setState({ deleteEntity: undefined });
	};

	confirmDelete = user => () => {
		this.setState({ deleteEntity: user });
	};

	onRemove = user => () => {
		this.setState({ deleteEntity: undefined });
		const { removeManagerClassTeacher, classId } = this.props;
		const classTeacherRequest = {
			userIdentifier: user.userIdentifier,
			classId
		};

		removeManagerClassTeacher(
			classTeacherRequest,
			() => this.setPending(user.userIdentifier),
			() => this.resetPending(user.userIdentifier)
		);
	};

	setPending = id => {
		this.setState(state => ({
			pendingIds: [
				...state.pendingIds,
				id 
			]
		}));
	};

	resetPending = id => {
		this.setState(state => ({ pendingIds: state.pendingIds.filter(val => val !== id) }));
	};

	disabledUser = user => this.state.pendingIds.includes(user.userIdentifier);

	render() {
		const { classTeachers, teachers, intl, classes, isArchived } = this.props;

		const tableRenderer = [
			{
				id: "name",
				title: intl.fm("manager.classes.teachers.table.name"),
				renderCell: user => user.username,
				sort: true
			}
		];

		if (!isArchived) {
			tableRenderer.push({
				id: "actions",
				title: intl.fm("manager.classes.teachers.table.actions"),
				renderCell: user => (
					<Button
						color="danger"
						customClass={classes.editButton}
						disabled={this.disabledUser(user)}
						onClick={this.confirmDelete(user)}
					>
						<DeleteIcon className={classes.icon} />
					</Button>
				)
			});
		}

		const filteredTeachers = teachers.filter(
			user =>
				!classTeachers.find(ct => ct.userIdentifier === user.userIdentifier)
		);

		return (
			<ManagerTableCard
				tableData={classTeachers}
				title={
					<div className={classes.titleBox}>
						<span>{intl.fm("manager.classes.teachers.title")}</span>
						<div className={classes.titleBox}>
							{!isArchived ? (
								<CustomSearchSelect
									list={filteredTeachers}
									value={null}
									onSelect={this.onSelectUser}
									displayField="username"
									placeholder={intl.fm("manager.classes.teachers.add")}
								/>
							) : null}
						</div>
						<WarningDialog
							onConfirm={this.onRemove(this.state.deleteEntity)}
							onClose={this.cancelDelete}
							entity={this.state.deleteEntity}
							entityName={intl.fm("teacher.entityName")}
						/>
					</div>
				}
				tableRenderer={tableRenderer}
				cardIcon={SchoolIcon}
				color="purple"
			/>
		);
	}
}

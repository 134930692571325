import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { COLOR_GRAY } from "@hlcr/mui/theme/hacking-lab.theme";


import { primaryColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import radioGroupStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/radioGroupStyle";

const styles = {
	...radioGroupStyle,
	root: {
		color: primaryColor,
		"&$checked": { color: primaryColor },
	},
	checked: {},
	disabled: { color: primaryColor + "70 !important" }
};

const CustomRadio = ({
	                     value,
	                     options,
	                     onChange,
	                     disabled,
	                     valueGetter,
	                     labelGetter,
	                     classes,
                     }) => (
	<RadioGroup
		aria-label="Gender"
		name="gender1"
		value={value}
		onChange={onChange}
		disabled={disabled}
	>
		{options.map((option, i) => {
			let mergedCssClasses = Object.assign({}, {
				checked: classes.checked,
				root: classes.root,
				disabled: classes.disabled,
			}, option.customRadioClasses);

			return (
				<div style={{ display: "flex" }}>
					<FormControlLabel
						key={i}
						disabled={disabled}
						value={valueGetter ? valueGetter(option) : option.value}
						control={
							<Radio classes={mergedCssClasses} />
						}
						label={labelGetter ? labelGetter(option) : option.label}
						classes={{ label: option.labelStyle }}
					/>
					{
						option.elementSuffix && <span style={{ alignSelf: "center", color: COLOR_GRAY }}>{option.elementSuffix}</span>
					}
				</div>
			);
		})
		}
	</RadioGroup>
);
CustomRadio.propTypes = {
	classes: PropTypes.object.isRequired,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	valueGetter: PropTypes.func,
	labelGetter: PropTypes.func
};

export default withStyles(styles)(CustomRadio);

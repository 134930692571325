import { withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "@hlcr/mui/Button";
import { withIntl } from "@hlcr/ui/Intl";
import { QUESTION_ANSWER_STATES, QuestionAnswerState } from "models/SolutionState";

const AnswerGrader = ({ questionState, setRight, setWrong, classes, intl }) => {
	return (
		<div>
			<div className={classes.title}>{intl.fm("quiz.answerGrader.title")}</div>
			<Button
				onClick={setRight}
				color={QuestionAnswerState.RIGHT === questionState ? `${QUESTION_ANSWER_STATES.RIGHT.buttonColorActive}` : `${QUESTION_ANSWER_STATES.RIGHT.buttonColorInactive}`}
				customClass={cx(classes.button, classes.leftButton)}
				disabled={QuestionAnswerState.UNGRADED !== questionState}
			>
				<DoneIcon className={classes.icon} />
				{intl.fm("quiz.answerGrader.right")}
			</Button>
			<Button
				onClick={setWrong}
				color={QuestionAnswerState.WRONG === questionState ? `${QUESTION_ANSWER_STATES.WRONG.buttonColorActive}` : `${QUESTION_ANSWER_STATES.WRONG.buttonColorInactive}`}
				customClass={classes.button}
				disabled={QuestionAnswerState.UNGRADED !== questionState}
			>
				<ClearIcon className={classes.icon} />
				{intl.fm("quiz.answerGrader.wrong")}
			</Button>
		</div>
	);
};

AnswerGrader.propTypes = {
	questionState: PropTypes.object.isRequired,
	setRight: PropTypes.func.isRequired,
	setWrong: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired
};

const styles = {
	title: { fontWeight: 400 },
	button: {
		padding: "5px 8px 5px 2px",
		marginTop: 5,
		marginBottom: 0
	},
	leftButton: { marginRight: 15 },
	icon: { marginRight: 5 }
};

export default withStyles(styles)(withIntl(AnswerGrader));

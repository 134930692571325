import * as React from "react";
import { RouteComponentProps } from "react-router";

import GridContainer from "components/Grid/GridContainer";
import ItemGrid from "components/Grid/ItemGrid";
import { InboxMessageDetail } from "messaging/components/InboxMessageDetail";
import { InboxMessageList } from "messaging/components/InboxMessageList";
import { MessageReceived } from "messaging/model";


interface InboxProps {
}

export const Inbox = (props: RouteComponentProps<InboxProps>) => {

	const [ selectedMessage, setSelectedMessage ] = React.useState<MessageReceived>();

	return (
		<>
			<GridContainer>
				<ItemGrid xs={12} sm={12} md={4} lg={6}>
					<InboxMessageList
						setSelectedMessage={setSelectedMessage}
					/>
				</ItemGrid>
				<ItemGrid xs={12} sm={12} md={8} lg={6}>
					<InboxMessageDetail
						message={selectedMessage} />
				</ItemGrid>
			</GridContainer>
		</>
	);
};

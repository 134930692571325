import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import cx from "classnames";
import React from "react";
import { compose } from "redux";

import { Button } from "@hlcr/mui/Button";

import IconCard from "./IconCard";

const style = {
	cardCollapseButton: {
		flex: 0,
		justifyContent: "flex-end",
		padding: "0 15px",
		margin: 0
	}
};

class CollapseIconCard extends React.Component {
	render() {
		const {
			icon,
			title,
			helpLink,
			content,
			sumaryContent,
			onSwitchDisplayInfo,
			displayInfo,
			classes
		} = this.props;
		return (
			<IconCard
				icon={icon}
				title={
					<React.Fragment>
						{title}
						{onSwitchDisplayInfo && (
							<Button
								color="defaultNoBackground"
								onClick={onSwitchDisplayInfo}
								customClass={cx(classes.cardCollapseButton)}
							>
								{displayInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
							</Button>
						)}
					</React.Fragment>
				}
				helpLink={helpLink}
				content={
					<React.Fragment>
						<Collapse
							timeout={{ enter: 1000, exit: 400 }}
							in={displayInfo || !onSwitchDisplayInfo}
						>
							{content}
						</Collapse>
						<Collapse
							timeout={{ enter: 200, exit: 800 }}
							in={onSwitchDisplayInfo && !displayInfo}
						>
							{sumaryContent}
						</Collapse>
					</React.Fragment>
				}
			/>
		);
	}
}

export default compose(withStyles(style))(CollapseIconCard);

import { WithIntl, withIntl } from "@hlcr/ui/Intl";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { compose } from "redux";


interface QuizSolutionSummaryProps extends WithIntl {
}

const QuizSolutionSummary = compose<React.ComponentType>(withIntl)(
	(props: QuizSolutionSummaryProps) => {
		return (
			<Typography>
				{props.intl.fm("quiz.summary.end")}
				<br />
				{props.intl.fm("quiz.summary.check")}
			</Typography>
		);
	},
);

export default QuizSolutionSummary;

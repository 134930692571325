import { RemoteResourceState, RemoteResourceStatus } from "@hlcr/core/api/RemoteResource";
import { Button } from "@hlcr/mui/Button";
import { StyledMarkdown } from "@hlcr/ui";
import { useIntl } from "@hlcr/ui/Intl";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalWindow from "components/ModalWindow/ModalWindow";
import { formatDateOnly } from "helper/dateCalc";
import { RemoteResource } from "models/RemoteResource";
import { RootState } from "reducers";
import { acceptTerm, fetchEventTerm } from "term/components/termModal/termModal.action";
import styles from "term/components/termModal/termModal.module.scss";
import { EventTerm } from "term/term.model";

export enum TermReviewMode {
	NO_REASON,
	ALREADY_ACCEPTED,
	POLICY_VIOLATED,
	NOT_RUNNING,
}

// TODO: move to modal window props file (after ModalWindow to Typescript)
interface ModalWindowProps {
	open: boolean;
	onClose: () => void;
	title: string;
	helpLink?: string;
	actionSection?: JSX.Element;
	fullWidth?: boolean;
	reviewMode?: TermReviewMode;
}

interface TermModalProps extends Omit<ModalWindowProps, "actionSection" | "title"> {
	eventId?: number;
}

export const TermModal = ({ eventId, ...modalProps }: TermModalProps) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [ eventTerm, setEventTerm ] = useState<EventTerm>();

	const eventTermState = useSelector<RootState, RemoteResourceState<EventTerm[]>>(state => {
		return state.remoteResourceReducer.remoteResources[RemoteResource.EVENT_TERMS];
	});

	useEffect(() => {
		if (eventTermState.status === RemoteResourceStatus.LOADED) {
			setEventTerm(eventTermState?.data?.find((term: EventTerm) => term.eventId === eventId));
		}
	}, [ eventTermState ]);

	useEffect(() => {
		if (eventTermState.status === RemoteResourceStatus.INITIALIZED ||
			eventTermState.status === RemoteResourceStatus.LOADED && !eventTermState?.data?.find(eventTerm => eventTerm.eventId === eventId)) {

			if (eventId && eventId !== eventTerm?.eventId) {
				dispatch(fetchEventTerm({ eventId }));
			}
		}
	}, [ eventId, eventTermState ]);

	if (!eventTerm) {
		return null;
	}

	const accept = (accept: boolean) => () => eventTerm.id && dispatch(
		acceptTerm(
			{ termId: eventTerm.id, accepted: accept, eventId: eventTerm.eventId },
			undefined,
			modalProps.onClose,
		),
	);

	const actionSection = <div className={styles.actions}>
		<Button onClick={accept(false)} color="defaultNoBackground">
			{intl.fm("common.labels.decline")}
		</Button>
		<Button onClick={accept(true)} color="infoNoBackground">
			{intl.fm("common.labels.accept")}
		</Button>
	</div>;

	const actionSectionReview = <div className={styles.actions}>
		<Button onClick={modalProps.onClose} color="infoNoBackground">
			{intl.fm("common.labels.close")}
		</Button>
	</div>;

	return (
		<ModalWindow
			title={intl.fm("event.privacyPolicy.title")}
			actionSection={modalProps.reviewMode ? actionSectionReview : actionSection}
			{...modalProps}
		>
			<p className={styles.validFrom}>{intl.fm("event.privacyPolicy.validFrom")} {formatDateOnly(eventTerm.validFrom)}</p>
			<StyledMarkdown
				source={eventTerm.content}
			/>
			{
				modalProps.reviewMode &&
				<div className={styles.reviewReason}>
					<p>
						{intl.fm("event.privacyPolicy.reviewMode.intro")}
						&nbsp;
						{modalProps.reviewMode === TermReviewMode.ALREADY_ACCEPTED && intl.fm("event.privacyPolicy.reviewMode.alreadyAccepted")}
						{modalProps.reviewMode === TermReviewMode.POLICY_VIOLATED && intl.fm("event.privacyPolicy.reviewMode.policyViolated")}
						{modalProps.reviewMode === TermReviewMode.NOT_RUNNING && intl.fm("event.privacyPolicy.reviewMode.notRunning")}
					</p>
				</div>
			}
		</ModalWindow>
	);
};

import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { primaryColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { DARK_MODE_SELECTOR } from "helper/darkMode";

const styles = {
	switchBase: {
		color: primaryColor,
		"&$checked": {
			color: primaryColor,
			"& + $colorBar": { backgroundColor: primaryColor }
		}
	},
	colorBar: {},
	checked: {},
	icon: {
		border: "1px solid",
		borderColor: primaryColor,
		boxShadow: "0 1px 3px 1px rgba(0, 0, 0, 0.4)",
		color: "#FFFFFF !important",
		transform: "translateX(-4px)!important",
		[DARK_MODE_SELECTOR]: { color: `${primaryColor} !important` }
	},
	iconChecked: {
		borderColor: primaryColor,
		transform: "translateX(4px)!important"
	}
};

const CustomSwitch = ({ checked, onChange, disabled, classes, className }) => (
	<Switch
		checked={checked}
		onChange={onChange}
		disabled={disabled}
		classes={{
			switchBase: cx(classes.switchBase, className),
			checked: classes.checked,
			track: classes.colorBar,
			thumb: classes.icon,
		}}
	/>
);

CustomSwitch.propTypes = {
	classes: PropTypes.object.isRequired,
	checked: PropTypes.bool.isRequired,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	className: PropTypes.string
};

export default withStyles(styles)(CustomSwitch);

import { withStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import EventIcon from "@material-ui/icons/Event";
import PersonIcon from "@material-ui/icons/Person";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teacherApi from "actions/teacher";
import teacherEventStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teacherEventStyle";
import IconCard from "components/Cards/IconCard";
import InfoCard from "components/Cards/InfoCard";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { formatDateOnly, formatTimeOnly } from "helper/dateCalc";
import { createMemoize } from "helper/memoize";
import { withIntl } from "@hlcr/ui/Intl";
import { EventAccessibility } from "variables/constants";
import { getEventState } from "variables/constantsHelpers";
import { EventStateDesc } from "variables/constantsLocalization";
import TeacherSolutionsCard from "views/Teacher/TeacherSolutionsCard";
import { QuizSolutionStates, SolutionStates } from "models/SolutionState";

const RELOAD_INTERVAL = 20;

class TeacherEvent extends React.Component {
	state = { autoReload: false };

	autoReloadTimer = null;

	componentDidMount() {
		const {
			eventId,
			fetchTeacherEvent,
			fetchTeacherSolutions,
			fetchGradings,
			fetchTeacherQuizSolutions
		} = this.props;
		fetchTeacherEvent(eventId);
		fetchTeacherSolutions(eventId);
		fetchGradings(eventId);
		fetchTeacherQuizSolutions(eventId);
	}

	componentWillUnmount() {
		if (this.autoReloadTimer) clearInterval(this.autoReloadTimer);
	}

	render() {
		const { autoReload } = this.state;
		const { solutions, gradings, solutionsPending, quizSolutions, quizSolutionsPending, event, eventId, isLoading, intl, classes, reopenQuizSolution } = this.props;
		if (!event)
			return isLoading ? (
				<LoadingSpinner />
			) : (
				<h3>{intl.fm("teacher.event.notFetched")}</h3>
			);

		const documentTitle = `${intl.fm("teacher.event.openSolutions")}: ${
			event.submittedSolutionCount
		}`;

		return (
			<>
				<Helmet>
					<title>{documentTitle}</title>
				</Helmet>
				<GridContainer>
					<ItemGrid xs={12} sm={12} md={12} lg={4}>
						<EventStatus {...this.props} />
					</ItemGrid>
					<ItemGrid xs={12} sm={6} md={4} lg={4}>
						<OpenSolutions
							{...this.props}
							checked={autoReload}
							handleClick={this.switchAutoReload}
						/>
					</ItemGrid>
					<ItemGrid xs={12} sm={6} md={4} lg={4}>
						<Users nrOfUsers={event.participantCount} {...this.props} />
					</ItemGrid>
					{event.accessability === EventAccessibility.STATIC_TOKEN && (
						<ItemGrid xs={12} sm={12} md={12} lg={12}>
							<StaticToken intl={intl} classes={classes} />
						</ItemGrid>
					)}
					{solutions && <ItemGrid xs={12} sm={12} md={12} lg={12} xl={quizSolutions && solutions ? 6 : 12}>
						<TeacherSolutionsCard
							isChallenge
							solutions={solutions}
							gradings={gradings}
							event={event}
							eventId={eventId}
							intl={intl}
							classes={classes}
							isPending={solutionsPending}
							solutionStates={SolutionStates}
						/>
					</ItemGrid>}
					{quizSolutions && <ItemGrid xs={12} sm={12} md={12} lg={12} xl={quizSolutions && solutions ? 6 : 12}>
						<TeacherSolutionsCard
							solutions={quizSolutions}
							gradings={gradings}
							event={event}
							eventId={eventId}
							intl={intl}
							classes={classes}
							reopenQuizSolution={reopenQuizSolution}
							isPending={quizSolutionsPending}
							solutionStates={QuizSolutionStates}
						/>
					</ItemGrid>}
				</GridContainer>
			</>
		);
	}

	switchAutoReload = () => {
		const { fetchTeacherEvent, fetchTeacherSolutions, fetchGradings, fetchTeacherQuizSolutions, eventId } = this.props;

		this.setState(
			({ autoReload }) => ({ autoReload: !autoReload }),
			() => {
				if (this.state.autoReload) {
					this.autoReloadTimer = setInterval(() => {
						fetchTeacherEvent(eventId);
						fetchTeacherSolutions(eventId);
						fetchGradings(eventId);
						fetchTeacherQuizSolutions(eventId);
					}, RELOAD_INTERVAL * 1000);
				} else {
					if (this.autoReloadTimer) clearInterval(this.autoReloadTimer);
				}
			}
		);
	};
}

TeacherEvent.propTypes = {
	eventId: PropTypes.number.isRequired,
	event: PropTypes.object,
	fetchTeacherEvent: PropTypes.func.isRequired,
	fetchTeacherSolutions: PropTypes.func.isRequired,
	fetchGradings: PropTypes.func.isRequired,
	fetchTeacherQuizSolutions: PropTypes.func.isRequired,
	solutions: PropTypes.array.isRequired,
	gradings: PropTypes.array.isRequired,
	solutionsPending: PropTypes.bool,
	quizSolutions: PropTypes.array.isRequired,
	quizSolutionsPending: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	intl: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	reopenQuizSolution: PropTypes.func.isRequired,
};

const StaticToken = () => (
	<IconCard title="Static Token" faIcon="key" iconColor="purple" content="AT" />
);

const Users = ({ nrOfUsers, intl }) => (
	<InfoCard
		icon={PersonIcon}
		iconColor="purple"
		title={intl.fm("teacher.event.users")}
		description={nrOfUsers}
	/>
);

const OpenSolutions = ({ event, intl, classes, checked, handleClick }) => (
	<InfoCard
		icon={AssignmentTurnedInIcon}
		iconColor={event.submittedSolutionCount && event.submittedSolutionCount > 0 ? "orange" : "purple"}
		title={intl.fm("teacher.event.openSolutions")}
		description={event.submittedSolutionCount}
		footer={
			<div className={classes.openSolutionsFooter}>
				<CustomSwitch
					checked={checked}
					onChange={handleClick}
				/>
				<div onClick={handleClick} className={classes.autoReloadLabel}>
					{intl.fm("teacher.event.autoReload", null, { seconds: RELOAD_INTERVAL })}
				</div>
			</div>
		}
	/>
);

const EventStatus = ({ event, intl, classes }) => (
	<InfoCard
		icon={AccessTimeIcon}
		iconColor="green"
		title={intl.fm("teacher.event.eventStatus")}
		description={EventStateDesc(getEventState(event.startTime, event.endTime))}
		footer={
			<div className={classes.infoCardFooter}>
				<div>
					<EventIcon className={classes.cardStatsIcon} />
					{formatDateOnly(event.startTime)} - {formatDateOnly(event.endTime)}
					<AccessTimeIcon className={classes.cardStatsIcon} />
					{formatTimeOnly(event.startTime)} - {formatTimeOnly(event.endTime)}
				</div>
			</div>
		}
	/>
);

const getEvent = createMemoize((events, eventId) => events.find(event => event.id === eventId));

const filterSolutions = createMemoize((solutions, eventId) => solutions.filter(solution => solution.eventId === eventId));

const filterQuizSolutions = createMemoize((solutions, eventId) => solutions.filter(solution => solution.eventId === eventId));

const mapStateToProps = (state, ownProps) => {
	const eventId = Number(ownProps.match.params.eventId);
	return {
		eventId,
		event: getEvent(state.api.resources.teacherEvents.data, eventId),
		solutions: filterSolutions(state.api.resources.teacherSolutions.data, eventId),
		gradings: state.api.resources.gradings.data,
		solutionsPending: state.api.resources.teacherSolutions.pending,
		quizSolutions: filterQuizSolutions(state.api.resources.teacherQuizSolutions.data, eventId),
		quizSolutionsPending: state.api.resources.teacherQuizSolutions.pending,
		isLoading: state.api.resources.teacherEvents.pending || state.api.resources.teacherSolutions.pending || state.api.resources.teacherQuizSolutions.pending
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchTeacherEvent: teacherApi.fetchTeacherEvent,
			fetchTeacherSolutions: teacherApi.fetchTeacherSolutions,
			fetchGradings: teacherApi.fetchGradings,
			fetchTeacherQuizSolutions: teacherApi.fetchTeacherQuizSolutions,
			reopenQuizSolution: teacherApi.reopenQuizSolution,
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(teacherEventStyle),
	withIntl
)(TeacherEvent);

let TeacherEventBreadCrumb = ({ event }) => (<span>{event ? event.name : "Event"}</span>);

const mapStateToPropsBreadCrumbs = (state, ownProps) => {
	const eventId = Number(ownProps.match.params.eventId);
	return { event: state.api.resources.teacherEvents.data.find(e => e.id === eventId) };
};

TeacherEventBreadCrumb = connect(mapStateToPropsBreadCrumbs)(TeacherEventBreadCrumb);
export { TeacherEventBreadCrumb };

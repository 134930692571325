import eventCardGridStyle from "../../eventCardGridStyle";

const managerEventsStyle = {
	...eventCardGridStyle,
	label: {
		cursor: "pointer",
		paddingLeft: "0",
		color: "#AAAAAA",
		fontSize: "10.5px",
		lineHeight: "1.428571429",
		fontWeight: "400",
		display: "inline-flex"
	},
	iconPosition: { margin: -3 },
	newButton: { margin: 0 },
	hide: {
		opacity: 0,
		width: 0,
		height: 0,
		overflow: "hidden"
	},
	smartButtons: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		transition: "all .3s ease"
	},
	createButton: {
		paddingLeft: 15,
		paddingRight: 15
	},
	createIcon: { marginRight: 5 }
};

export default managerEventsStyle;

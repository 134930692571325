import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teamleaderApi from "actions/teamleader";
import teamsApi from "actions/teams";
import { Button } from "@hlcr/mui/Button";
import CustomInput from "components/CustomInput/CustomInput";
import ModalWindow from "components/ModalWindow/ModalWindow";
import { withIntl } from "@hlcr/ui/Intl";
import { fetchHelp } from "variables/helpPage";

const TokenModal = ({ classes, intl, onClose, helpUrl, open, token, teamId, regenerateToken, fetchTeams }) => {
	const textInputRef = useRef(null);
	const [ isLoading, setLoading ] = useState(false);

	const regenerate = () => {
		setLoading(true);
		regenerateToken(teamId, () => {
			fetchTeams();
			setLoading(false);
		});
	};

	const copyToClipboard = () => {
		if (textInputRef?.current) {
			textInputRef.current.select();
			document.execCommand("copy");
			textInputRef.current.blur();
		}
	};

	return (
		<ModalWindow
			open={open}
			onClose={onClose}
			title={intl.fm("team.titles.memberToken")}
			helpLink={fetchHelp(helpUrl, "teams", "memberToken")}
			actionSection={
				<div className={classes.actions}>
					{isLoading && <CircularProgress size={18} />}
					<Button onClick={() => regenerate()} color="infoNoBackground">
						{intl.fm("team.label.regenerate")}
					</Button>
				</div>
			}
			fullWidth
		>
			<CustomInput
				inputProps={{
					inputRef: textInputRef,
					placeholder: "Access Token",
					value: token,
					readOnly: true
				}}
				formControlProps={{ className: classes.tokenInput }}
			/>
			<Button onClick={() => copyToClipboard()} color="infoNoBackground" customClass={classes.copyButton}>
				<CopyIcon fontSize="small" />
			</Button>
		</ModalWindow>
	);
};
export default compose(
	withStyles({
		actions: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between"
		},
		regenerateButton: { margin: "0 0 0 10px" },
		copyButton: {
			padding: "10px 10px 5px",
			margin: "0 5px 0 0"
		},
		tokenInput: {
			paddingTop: 0,
			margin: 0,
			width: 300,
			"@media (max-width: 900px)": { width: "auto" }
		}
	}),
	withIntl,
	connect(
		(state, { teamId }) => ({
			helpUrl: state.branding.helpUrl,
			token: state.api.resources.userTeams.data.find(team => team.id === teamId)?.memberToken
		}),
		dispatch =>
			bindActionCreators({
				regenerateToken: teamleaderApi.regenerateJoinToken,
				fetchTeams: teamsApi.fetchTeams,
			}, dispatch)
	)
)(TokenModal);

const BASE_URL = "/api";

export interface DynamicScoringSimulationRequest {
	decayFactor: number;
	minimumPoints: number;
	maximumPoints: number;
	participantCount: number; // this is the maximum number of participants that will be simulated
}

export interface DynamicScoringSimulationDataPoint {
	solvingCount: number;
	points: number
}

// I do not want to use the new redux logic from the project,
// as the data is re-calculated on every request and does not have an ID or something
export const fetchDynamicScoringPreview = async (payload: DynamicScoringSimulationRequest): Promise<DynamicScoringSimulationDataPoint[]> => {
	if (!payload) {
		console.error("fetchDynamicScoringPreview: payload is required");
		return [];
	}

	const params = new URLSearchParams({
		decayFactor: payload.decayFactor.toString(),
		minimumPoints: payload.minimumPoints.toString(),
		maximumPoints: payload.maximumPoints.toString(),
		participantCount: payload.participantCount.toString(),
	});

	const response = await fetch(`${BASE_URL}/events/dynamic_scoring_preview/?${params.toString()}`);
	if (!response.ok) {
		console.error(`fetchDynamicScoringPreview: ${response.status} ${response.statusText}`);
		return [];
	}

	return await response.json();
};

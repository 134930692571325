import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import reportApi from "../../actions/report";
import reportStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/reportStyle";
import { withIntl } from "@hlcr/ui/Intl";
import { EventReport } from "./EventReport";

class UserReport extends React.Component {
	componentDidMount() {
		const {
			teamId,
			fetchUserChallengeReportsForTeam,
			fetchUserQuizReportsForTeam,
			fetchUserTheoriesReportsForTeam,
			fetchTeamUsers,
			fetchClasses,
			fetchAllTeams
		} = this.props;

		fetchTeamUsers(teamId);
		fetchUserChallengeReportsForTeam(teamId);
		fetchUserQuizReportsForTeam(teamId);
		fetchUserTheoriesReportsForTeam(teamId);
		fetchClasses();
		fetchAllTeams();
	}

	render() {
		const {
			userId,
			teamId,
			classId,
			challengeReports,
			userReportOptions,
			userReport,
			username,
			intl,
			classes,
			routeTo
		} = this.props;

		const settingsKey = `UserReport::${teamId}`;

		return (
			<EventReport
				auditorReport
				settingsKey={settingsKey}
				userId={userId}
				teamId={teamId}
				classId={classId}
				challengeReports={challengeReports}
				userReportOptions={userReportOptions}
				userReport={userReport}
				username={username}
				routeTo={routeTo}
				intl={intl}
				classes={classes}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const userId = Number(ownProps.match.params.userId);
	const teamId = Number(ownProps.match.params.teamId);
	const classId = Number(ownProps.match.params.classId);

	const routeTo = ownProps.history.push;

	const {
		reportUsers,
		reportChallenges,
		reportQuizzes,
		reportTheories
	} = state.api.resources;

	const allreports = [
		...reportChallenges.data,
		...reportQuizzes.data,
		...reportTheories.data
	];

	let username;
	let challengeReports;
	let userReport = null;
	const allUsersReport = reportUsers.data.length
		? reportUsers.data.reduce(
			(r1, r2) => ({
				userId: 0,
				username: "All Users",
				classId: r1.classId,
				mandatoryFullPointSolutionCount:
						r1.mandatoryFullPointSolutionCount +
						r2.mandatoryFullPointSolutionCount,
				mandatoryUserChallengeCount:
						r1.mandatoryUserChallengeCount + r2.mandatoryUserChallengeCount
			}),
			{
				mandatoryFullPointSolutionCount: 0,
				mandatoryUserChallengeCount: 0
			}
		  )
		: {
			userId: 0,
			username: "No Users",
			mandatoryFullPointSolutionCount: 0,
			mandatoryUserChallengeCount: 0
		  };

	const userReportOptions = [
		allUsersReport,
		...reportUsers.data.filter(ur => ur.teamId === teamId)
	];

	if (userId === 0) {
		challengeReports = allreports.filter(
			challengeReport => challengeReport.teamId === teamId
		);
		userReport = allUsersReport;
		username = "All Users";
	} else {
		userReport = reportUsers.data.find(ur => ur.userId === userId);
		username = userReport ? userReport.username : "unknown";
		challengeReports = allreports.filter(
			challengeReport => challengeReport.userId === userId
		);
	}

	return {
		challengeReports,
		userReportOptions,
		userReport,
		username,
		userId,
		teamId,
		classId,
		routeTo
	};
};
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchUserChallengeReportsForTeam:
				reportApi.fetchUserChallengeReportsForTeam,
			fetchUserQuizReportsForTeam: reportApi.fetchUserQuizReportsForTeam,
			fetchUserTheoriesReportsForTeam:
				reportApi.fetchUserTheoriesReportsForTeam,
			fetchTeamUsers: reportApi.fetchTeamUsers,
			fetchClasses: reportApi.fetchClasses,
			fetchAllTeams: reportApi.fetchAllTeams
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(reportStyle),
	withIntl
)(UserReport);

let UserReportBreadCrumb = ({ userReport }) => (
	<span>{userReport ? userReport.username : "All Users"}</span>
);

const mapStateToPropsBreadCrumbs = (state, ownProps) => {
	const userId = Number(ownProps.match.params.userId);
	return {
		userReport: state.api.resources.reportUsers.data.find(
			userReport => userReport.userId === userId
		)
	};
};

UserReportBreadCrumb = connect(mapStateToPropsBreadCrumbs)(UserReportBreadCrumb);

export { UserReportBreadCrumb };

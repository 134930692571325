import { withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import cx from "classnames";
import React, { createElement } from "react";

import managerStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/manager/managerStyle";
import { checkHasRole } from "auth/authUtils";
import AddUnitsModal from "components/AddUnitsModal/AddUnitsModal";
import { Button } from "@hlcr/mui/Button";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import Tooltip from "components/CustomTooltip/CustomTooltip";
import LevelLabel from "components/LevelLabel/LevelLabel";
import { EventUnitModal } from "components/ModalWindow/EventUnitModal";
import TenaciousWarning, { TenaciousAction } from "components/ModalWindow/TenaciousWarning";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import { EventUnitSummary } from "views/Manager/EventUnitSummary";

import ManagerTableCard, { ShowHelp } from "./ManagerTableCard";
import { ASSET_DIFFICULTY_LEVELS, ASSET_TYPES, AssetType } from "models/Asset";

class ManagerEventUnits extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pendingIds: [],
			editEntity: undefined,
			deleteEntity: undefined,
			resetEntity: undefined,
			isUnitsModalOpen: false
		};
	}

	render() {
		const { isUnitsModalOpen } = this.state;
		const { eventUnits, units, event, intl, classes } = this.props;

		const published = eventUnits.filter(unit => unit.hidden === false).length;
		const total = eventUnits.length;

		return (
			<ManagerTableCard
				tableData={eventUnits}
				title={
					<div className={classes.titleBox}>
						<span>
							{intl.fm("manager.eventDetails.units.title")}
							<ShowHelp userRole="manager" action="manageEventUnits" className={classes.helpIcon} />
							<span className={classes.publishedTitle}>{`${intl.fm("manager.eventDetails.units.published")} ${published}/${total}`}</span>
						</span>
						<div className={classes.titleBox}>
							<Button size="sm" color="infoNoBackground" customClass={classes.subEventButton} onClick={this.openAddDialog}>
								<AddIcon fontSize="small" />
								<span className={classes.subEventButtonText}>{intl.fm("manager.eventDetails.units.add")}</span>
							</Button>
						</div>
						<TenaciousWarning
							action={TenaciousAction.DELETE}
							onConfirm={this.handleRemove}
							onClose={this.cancelDelete}
							entity={this.state.deleteEntity}
							entityIdentifier={entity => `${entity.type} ${entity.title}`}
						/>
						<TenaciousWarning
							action={TenaciousAction.RESET}
							onConfirm={this.handleReset}
							onClose={this.cancelReset}
							entity={this.state.resetEntity}
							entityIdentifier={entity => `${entity.type} ${entity.title}`}
						/>
						<EventUnitModal
							onSave={this.handleEdit}
							onClose={this.cancelEdit}
							unit={this.state.editEntity}
							event={event}
						/>
						<AddUnitsModal
							units={units}
							addedUnitUuids={eventUnits.map(unit => unit.uuid)}
							isOpen={isUnitsModalOpen}
							handleClose={this.closeAddDialog}
							addUnit={this.addUnit}
						/>
					</div>
				}
				tableRenderer={this.tableRenderer(eventUnits, intl, classes)}
				cardIcon={AssignmentIcon}
				color="purple"
			/>
		);
	}

	openAddDialog = () => this.setState({ isUnitsModalOpen: true });

	closeAddDialog = () => this.setState({ isUnitsModalOpen: false });

	changeFlag = (eventUnit, field) => () => {
		this.updateValue(eventUnit, field, !eventUnit[field]);
	};

	updateValue = (eventUnit, field, value, maxPoints, hideNotification) => {
		const updatedEventUnit = Object.assign({}, eventUnit, {
			[field]: value,
			[field !== "maxPoints" && maxPoints ? "maxPoints" : undefined]: maxPoints
		});
		this.saveEventUnit(updatedEventUnit, hideNotification);
	};

	saveEventUnit = (eventUnit, hideNotification) => {
		this.props.updateManagerEventUnit(eventUnit, () => this.setPendingUnit(eventUnit.id), () => this.resetPendingUnit(eventUnit.id), hideNotification);
	};

	setPendingUnit = id => {
		this.setState(state => ({ pendingIds: [ ...state.pendingIds, id ] }));
	};

	resetPendingUnit = id => {
		this.setState(state => ({ pendingIds: state.pendingIds.filter(val => val !== id) }));
	};

	moveUp = eventUnit => () => {
		const { eventUnits } = this.props;
		if (eventUnit.sortOrder === 0) return;

		this.updateValue(eventUnit, "sortOrder", eventUnit.sortOrder - 1);

		const moveDownUnit = eventUnits.find(eventUnitDown => eventUnitDown.sortOrder === eventUnit.sortOrder - 1);

		if (moveDownUnit) {
			this.updateValue(moveDownUnit, "sortOrder", eventUnit.sortOrder);
		}
	};

	moveDown = eventUnit => () => {
		const { eventUnits } = this.props;
		if (eventUnit.sortOrder === eventUnits.length - 1) return;

		this.updateValue(eventUnit, "sortOrder", eventUnit.sortOrder + 1);

		const moveUpUnit = eventUnits.find(eventUnitDown => eventUnitDown.sortOrder === eventUnit.sortOrder + 1);

		if (moveUpUnit) {
			this.updateValue(moveUpUnit, "sortOrder", eventUnit.sortOrder);
		}
	};

	// XXX: Does it make sense to set default maxPoints in frontend?
	getMaxPoints(unit) {
		const maxPoints = unit?.level?.name && ASSET_DIFFICULTY_LEVELS[unit.level.name.toUpperCase()].initialPoints;
		if (isNaN(maxPoints)) {
			return 0;
		} else {
			return maxPoints;
		}
	}

	addUnit = (unit, onDone) => {
		const { addManagerEventUnit, eventId, eventUnits } = this.props;
		const eventUnit = {
			eventId,
			type: unit.type,
			uuid: unit.uuid,
			goldNuggetType: undefined,
			startTime: "",
			endTime: "",
			maxPoints: this.getMaxPoints(unit),
			sortOrder: eventUnits ? eventUnits.length : 0
		};
		addManagerEventUnit(eventUnit, onDone);
	};

	cancelDelete = () => {
		this.setState({ deleteEntity: undefined });
	};

	markForDeletion = eventUnit => () => {
		this.setState({ deleteEntity: eventUnit });
	};

	handleRemove = eventUnit => {
		const { eventUnits } = this.props;
		this.setState({ deleteEntity: undefined });
		this.props.removeManagerEventUnit(
			eventUnit,
			() => this.setPendingUnit(eventUnit.id),
			() => this.resetPendingUnit(eventUnit.id),
			() => eventUnits
				.filter(eventUnitOrder => eventUnitOrder.sortOrder > eventUnit.sortOrder)
				.forEach(eventUnitOrder =>
					this.updateValue(
						eventUnitOrder,
						"sortOrder",
						eventUnitOrder.sortOrder - 1,
						undefined,
						false
					)
				)
		);
	};

	cancelEdit = () => {
		this.setState({ editEntity: undefined });
	};

	markForEdit = eventUnit => () => {
		this.setState({ editEntity: eventUnit });
	};

	handleEdit = eventUnit => {
		this.saveEventUnit(eventUnit);
		this.cancelEdit();
	};

	cancelReset = () => {
		this.setState({ resetEntity: undefined });
	};

	markForReset = eventUnit => () => {
		this.setState({ resetEntity: eventUnit });
	};

	handleReset = eventUnit => {
		this.setState({ resetEntity: undefined });
		this.props.resetManagerEventUnit(eventUnit);
	};

	disabledUnit = eventUnit => this.state.pendingIds.includes(eventUnit.id);

	tableRenderer = (eventUnits, intl, classes) => {
		const sortOrders = eventUnits.map(event => event.sortOrder);
		const minSortOrder = Math.min(...sortOrders);
		const maxSortOrder = Math.max(...sortOrders);
		return [
			{
				id: "type",
				renderCell: eventUnit => (
					<span className={classes.typeIcon}>
						<Tooltip
							title={`${intl.fm(ASSET_TYPES[eventUnit.type].title)}`}
							placement="right"
							classes={{ popper: classes.tooltip }}
						>
							{createElement(ASSET_TYPES[eventUnit.type].icon)}
						</Tooltip>
					</span>
				)
			},
			{
				id: "name",
				title: intl.fm("manager.eventDetails.units.table.name"),
				renderCell: eventUnit => (
					<div>
						{eventUnit.title}
						<br />
						<span className={classes.uuid}>{eventUnit.uuid}</span>
					</div>
				)
			},
			{
				id: "level",
				title: intl.fm("manager.eventDetails.units.table.level"),
				renderCell: eventUnit => eventUnit.level ? <LevelLabel value={eventUnit.level.name} /> : null
			},
			{
				id: "visibility",
				title: intl.fm("manager.eventDetails.units.table.visibility"),
				renderCell: eventUnit => (
					<CustomSwitch
						disabled={this.disabledUnit(eventUnit)}
						checked={!eventUnit.hidden}
						onChange={this.changeFlag(eventUnit, "hidden")}
					/>
				)
			},
			{
				id: "summary",
				title: intl.fm("manager.eventDetails.units.table.summary"),
				renderCell: eventUnit => <EventUnitSummary unit={eventUnit} />
			},
			{
				id: "actions",
				title: intl.fm("manager.eventDetails.units.table.actions"),
				renderCell: eventUnit => (
					<div>
						{eventUnit.sortOrder !== minSortOrder ? (
							<Button color="info" customClass={classes.editButton} disabled={this.disabledUnit(eventUnit)} onClick={this.moveUp(eventUnit)}>
								<ArrowUpwardIcon className={classes.icon} />
							</Button>
						) : (
							<div className={classes.sortOrderSpacing} />
						)}
						{eventUnit.sortOrder !== maxSortOrder && (
							<Button color="info" customClass={classes.editButton} disabled={this.disabledUnit(eventUnit)} onClick={this.moveDown(eventUnit)}>
								<ArrowDownwardIcon className={classes.icon} />
							</Button>
						)}
						<Button color="info" customClass={cx(classes.editButton, classes.floatRight)} disabled={this.disabledUnit(eventUnit)} onClick={this.markForEdit(eventUnit)}>
							<EditIcon className={classes.icon} />
						</Button>
						<Button color="danger" customClass={cx(classes.editButton, classes.floatRight)} disabled={this.disabledUnit(eventUnit)} onClick={this.markForDeletion(eventUnit)}>
							<DeleteIcon className={classes.icon} />
						</Button>
						{checkHasRole(HackingLabRole.EVENT_UNIT_RESET) && (
							AssetType.THEORY !== eventUnit.type ? (
								<Button color="danger" customClass={cx(classes.editButton, classes.floatRight)} disabled={this.disabledUnit(eventUnit)} onClick={this.markForReset(eventUnit)}>
									<RotateLeftIcon className={classes.icon} />
								</Button>
							) : (
								<div className={cx(classes.sortOrderSpacing, classes.floatRight)} />
							)
						)}
					</div>
				)
			}
		];
	};
}
export default withStyles(managerStyle)(ManagerEventUnits);

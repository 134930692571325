export default {
	eventContainer: {
		width: "30px",
		marginBottom: "10px",
		marginLeft: "5px",
	},
	event: {
		width: 30,
		height: 30,
		overflow: "hidden",
		color: "white",
		borderRadius: 4,
		marginRight: 5,
		cursor: "pointer",
		"&:hover": {
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 5px -5px rgba(201, 232, 203)"
		}
	},
	eventUntouched: {
		background: "#ccc",
		"&:hover": {
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 5px -5px rgba(204, 204, 204, 1)"
		}
	},
	eventActive: {
		background: "#b2e6ec",
		"&:hover": {
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 5px -5px rgba(178, 230, 236, 1)"
		}
	},
	eventPartial: {
		background: "#ffecb3",
		"&:hover": {
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 5px -5px rgba(178, 230, 236, 1)"
		}
	},
	eventDone: {
		background: "#4caf50",
		"&:hover": {
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 5px -5px rgba(178, 230, 236, 1)"
		}
	},
	progress: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		borderRadius: 2,
		marginLeft: "auto",
	},
	arrow: {
		position: "absolute",
		height: 0,
		width: 0,
		top: "35px",
		left: "5px",
		borderTop: 0,
		borderRight: "10px solid transparent",
		borderBottom: "10px solid #00acc1",
		borderLeft: "10px solid transparent",
		borderRadius: "10px"
	}
};

import { EnumBase, EnumDetail } from "@hlcr/app/enum/EnumBase";

export enum InputRequirementType {
	REQUIRED = "REQUIRED",
	CONDITIONAL = "CONDITIONAL",
	OPTIONAL = "OPTIONAL",
}

interface InputRequirementTypeEnum extends EnumBase {
	labelIntlId: string;
}

export const INPUT_REQUIREMENT_TYPES: EnumDetail<InputRequirementType, InputRequirementTypeEnum> = {
	REQUIRED: { title: "common.input.required", labelIntlId: "common.input.required" },
	CONDITIONAL: { title: "common.input.required.conditional", labelIntlId: "common.input.required.conditional" },
	OPTIONAL: { title: "input.requirement.type.optional", labelIntlId: "common.input.required.optional" },
};

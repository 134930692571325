export const actionStyles = {
	button: {
		margin: "0px 2px 0px 2px",
		padding: 5
	},
	icon: {
		verticalAlign: "middle",
		width: "17px",
		height: "17px",
		top: "-1px",
		position: "relative"
	}
};

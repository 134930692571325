import React from "react";

const PrivacyPolicy = () => (
	<div>
		<h1>Privacy Policy</h1>
		<p>
			Effective as of November 5th, 2019, Compass Security Network Computing AG,
			(“CSNC”) and its subsidiaries have updated our Privacy Policy (“Policy”).
		</p>
		<h2>1 Introduction</h2>
		<p>
			This Policy details our commitment to protecting the privacy of
			individuals who visit our Websites (“Website Visitors”), who register to
			use the products and services which we market for subscription (available
			at www.compass-security.com (the “Service(s)”, or who attend or register
			to attend sponsored events or other events at which the CSNC Group
			participates (“Attendees”). For the purposes of this Policy, the term,
			“Websites”, shall refer collectively to{" "}
			<a
				href="https://www.compass-security.com"
				target="_blank"
				rel="noopener noreferrer"
			>
				www.compass-security.com
			</a>{" "}
			as well as the other websites that the CSNC operates and that link to this
			Policy.
		</p>
		<h2>2 Scope Of This Policy</h2>
		<p>
			In addition to the Websites that link to this Policy, this Policy applies
			to the following:
		</p>
		<ul>
			<li>Compass Security Group Websites: *.compass-security.com</li>
			<li>
				The Hacking Lab (*.hacking-lab.com / *.hacking-lab-ctf.com /
				*.vuln.land)
			</li>
			<li>Filebox Solution: *.filebox-solution.com</li>
			<li>
				CSNC group Mobile Applications ("Apps"): HackyEaster / ConFoxy, Filebox
				Client and other Apps provided by CSNC
			</li>
		</ul>
		<p>
			In this Policy, personal information means information relating to an
			identified or identifiable natural person. An identifiable person is one
			who can be identified, directly or indirectly, in particular by reference
			to an identifier such as a name, an identification number, location data,
			and online identifier or to one or more factors specific to his/her
			physical, physiological, genetic, mental, economic, cultural or social
			identity. The use of information collected through our Service shall be
			limited to the purpose of providing the Service for which the Subscribers
			has engaged. Our Websites may contain links to other websites and the
			information practices and the content of such other websites are governed
			by the privacy statements of such other websites. We encourage you to
			review the privacy statements of any such other websites to understand
			their information practices. Account Information (as defined below) and
			other information we collect in connection with your registration or
			authentication into our Services (as defined below) is covered by this
			Policy. The security and privacy practices, including how we protect,
			collect, and use electronic data, text, messages, communications or other
			materials submitted to and stored within the Services by You (“Service
			Data”), are detailed by this Privacy Policy. If a Subscription Agreement,
			or other applicable agreement/contract between you and any member of the
			CSNC Group relating to your access to and use of a specific Service
			(collectively referred to as the “Service Agreement”) has been agreed
			upon, this Service Agreement will overrule this Privacy Policy.
		</p>
		<h2>3 Information That You Provide To Us</h2>
		<h4>Account and Registration Information:</h4>
		<p>
			We ask for and may collect personal information about you such as your
			name, address, phone number, email address, gender, nationality,
			organization and birthdate information.
		</p>
		<p>
			We refer to any information described above as “Account Information” for
			the purposes of this Policy. By voluntarily providing us with Account
			Information, you represent that you are the owner of such personal data or
			otherwise have the requisite consent to provide it to us.
		</p>
		<h4>Other Submissions:</h4>
		<p>
			We ask for and may collect personal information from you when you submit
			web forms on our Websites or as you use interactive features of the
			Websites, including, participation in surveys, contests, promotions,
			sweepstakes, requesting customer support, or otherwise communicating with
			us.
		</p>
		<h4>Attendee Information:</h4>
		<p>
			We ask for and may collect personal information such as your name,
			address, phone number and email address when you register for or attend a
			sponsored event or other events at which any member of the CSNC Group
			participates.
		</p>
		<h4>Mobile Application:</h4>
		<p>
			When you download and use our Services, we automatically collect
			information on the type of device you use, and operating system version.
			These data is collected by our Apps (in addition to the "Account
			Information"):
		</p>
		<ul>
			<li>Hacky-Easter: Score, Solutions</li>
			<li>ConFoxy: Photos and comments</li>
			<li>
				Filebox Client: File content as exchanged by the FileBox owners and
				communication partners
			</li>
		</ul>
		<h2>4 Information That We Collect From You on our Websites</h2>
		<h4>Cookies and Other Tracking Technologies:</h4>
		<p>
			We may use cookies and other information gathering technologies for a
			variety of purposes. These technologies may provide us with personal
			information, information about devices and networks you utilize to access
			our Websites, and other information regarding your interactions with our
			Websites.
		</p>
		<h4>Analytics:</h4>
		<p>
			We collect analytics information when you use the Websites to help us
			improve them:
		</p>
		<ul>
			<li>
				www.compass-security.com and blog.compass-security.com use Matomo/Piwik
				in anonymized mode.
			</li>
			<li>
				Hacking-Lab uses Hacking-Lab Analytics based on browser fingerprinting
				technologies for fraud detection. Hacking-Lab stores IP-addresses,
				nicknames, usage times, browser and operating system properties.
			</li>
		</ul>
		<h4>Logs:</h4>
		<p>
			As is true with most websites and services delivered over the Internet, we
			gather certain information and store it in log files when you interact
			with our Websites and Services. This information includes internet
			protocol (IP) addresses as well as browser type, internet service
			provider, URLs of referring/exit pages, operating system, date/time stamp,
			information you search for, locale and language preferences,
			identification numbers associated with your devices, your mobile carrier,
			and system configuration information.
		</p>
		<h2>5 Information Collected From Other Sources</h2>
		<h4>Social Media Buttons:</h4>
		<p>
			Our Websites provides a link to social media websites like Facebook,
			GitHub, Google or Twitter. Your interactions with these features are
			governed by the privacy statement of the companies that provide them.
		</p>
		<h4>Social Media Logins:</h4>
		<p>
			Hacking-Lab allows using authentication providers from GitHub, Google and
			Twitter. Hacking-Lab will receive the account information from the service
			authentication provider like user ID, username und email-Address. This
			information will be stored in the Hacking-Lab database like the "account
			and registration information" described in chapter 3 in the policy.
		</p>
		<p>
			Social Media authentication providers may collect your IP address, the
			page you are visiting on our site, and may set cookies to enable the this
			functionality.
		</p>
		<h2>6 How We Use Information That We Collect</h2>
		<h4>General Uses:</h4>
		<p>
			We may use the information we collect about you (including personal
			information, to the extent applicable) for a variety of purposes,
			including to (a) provide, operate, maintain, improve, and promote the
			Services; (b) enable you to access and use the Services; (c) process and
			complete transactions, and send you related information, including
			purchase confirmations and invoices or job advertisements; (d) send
			transactional messages, including responses to your comments, questions,
			and requests; provide customer service and support; and send you technical
			notices, updates, security alerts, and support and administrative
			messages; (e) process and deliver contest entries and rewards; (f) monitor
			and analyze trends, usage, and activities in connection with the Websites
			and Services and for marketing or advertising purposes; (g) investigate
			and prevent fraudulent transactions, unauthorized access to the Services,
			and other illegal activities; and (h) for other purposes for which we
			obtain your consent.
		</p>
		<p>
			If we send you promotional communications, such as providing you with
			information about products and services, features, surveys, newsletters,
			offers, promotions, contests, and events; and provide other news or
			information about us and our partners, we will ask you for your explicit
			consent (dual opt-in or similar method) before we will do so. You can
			opt-out of receiving marketing communications from us by contacting us at
			privacy@compass-security.com or following the unsubscribe instructions
			included in our marketing communications.
		</p>
		<h4>Legal Basis for Processing:</h4>
		<p>
			We collect personal information from you only where: (a) we have your
			consent to do so, (b) where we need the personal information to perform a
			contract with you (e.g. to deliver the CSNC Services you have requested),
			or (c) where the processing is in our or a third party’s legitimate
			interests based on the GDPR. In some cases, we may also have a legal
			obligation to collect personal information from you.
		</p>
		<p>
			<b>
				Where we rely on your consent to process the personal information, you
				have the right to withdraw or decline your consent at any time.
			</b>{" "}
			Please note that this does not affect the lawfulness of the processing
			based on consent before its withdrawal.
		</p>
		<p>
			If we ask you to provide personal information to comply with a legal
			requirement or to perform a contract with you, we will make this clear at
			the relevant time and advise you whether the provision of your personal
			information is mandatory or not (as well as of the possible consequences
			if you do not provide your personal information). Similarly, if we collect
			and use your personal information in reliance on our (or a third party’s)
			legitimate interests which are not already described in this Notice, we
			will make clear to you at the relevant time what those legitimate
			interests are.
		</p>
		<p>
			If you have any questions about or need further information concerning the
			legal basis on which we collect and use your personal information, please
			contact us using the contact details provided further below in Section 16.
		</p>
		<h2>7 Sharing Of Information Collected</h2>
		<h4>Third-Party Service Providers:</h4>
		<p>
			We host some of our webservers at Metanet (Metanet, Josefstrasse 218,
			CH-8005 Zürich) and HSR (HSR Hochschule für Technik, Oberseestrasse 10,
			8640 Rapperswil-Jona).
		</p>
		<h4>Google Analytics:</h4>
		<p>
			We use Google Analytics, a web analysis service of Google Ireland Limited
			("Google") which is based on cookies. We may use this service on the basis
			of our legitimate interests (i.e. interest in the analysis, optimisation
			and efficient operation of our online offer in the sense of Art. 6 Para. 1
			lit. f. of the GDPR).
		</p>

		<p>
			We act in accordance with the GDPR principles. The information generated
			by the cookie about the use of the online offer by the user is usually
			transferred to a Google server in the USA and stored there. Detailed
			information on the data collected can be found here: "Information for
			visitors of websites and apps that are analysed with the help of Google
			Analytics":
			<a href="https://support.google.com/analytics/answer/6004245?hl=de&ref_topic=2919631">
				{" "}
				see this link.
			</a>
		</p>
		<p>
			Google is certified under the Privacy Shield Agreement and thus offers a
			guarantee of compliance with European and Swiss data protection laws (
			<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">
				see this link
			</a>
			).
		</p>

		<p>
			Google will use this information on our behalf to evaluate the use of our
			online services by users, to compile reports on activities within this
			online service and to provide us with other services related to the use of
			this online service and the Internet. Pseudonymous user profiles can be
			created from the processed data.
		</p>

		<p>
			We use Google Analytics to display ads placed by Google and its partners
			within advertising services only to users who have shown an interest in
			our online services or who have certain characteristics (e.g. interests in
			certain topics or products determined on the basis of the websites
			visited) which we transmit to Google (so-called "remarketing" or "Google
			Analytics Audiences"). With the help of remarketing audiences, we would
			also like to ensure that our ads correspond to the potential interest of
			the users and do not appear annoying. We use Google Analytics only with IP
			anonymization enabled. This means that the IP address of the user is
			shortened by Google within member states of the European Union or in other
			contracting states of the Agreement in the European Economic Area and
			Switzerland. Only in exceptional cases is the full IP address transmitted
			to a Google server in the USA and shortened there.
		</p>
		<p>
			Your data from Google Analytics will be stored for a maximum of 14 months.
			The IP address transmitted by the user's browser is not merged with other
			Google data.
		</p>

		<p>
			Users may refuse the use of cookies by selecting the appropriate settings
			on their browser, they may refuse the processing of data about them by
			Google, by downloading and installing the browser plugin
			<a href="https://support.google.com/analytics/answer/181881?hl=de&ref_topic=2919631">
				{" "}
				available on
			</a>
			or by clicking here to set the opt-out cookie for our domains (see "Scope
			of this Policy").
		</p>

		<p>
			Information about Google Analytics and the settings to protect your
			privacy can be found
			<a href="https://support.google.com/analytics/topic/2919631?hl=de&ref_topic=1008008">
				{" "}
				here.
			</a>
			For more information about Google's use of data and about setting and
			objection options, please visit the following Google
			<a href="https://www.google.com/intl/de/policies/privacy/partners">
				{" "}
				webpages.
			</a>{" "}
			("Use of data by Google when using the websites or apps of our partners"),
			https://policies.google.com/technologies/ads ("Use of data for advertising
			purposes"), https://adssettings.google.com/authenticated ("Manage
			information Google uses to display advertising"). Google Products Privacy
			Policy Guide:
			<a href="https://policies.google.com/technologies/product-privacy?hl=en">
				{" "}
				Link
			</a>
		</p>

		<h4>
			Compliance with Laws and Law Enforcement Requests; Protection of Our
			Rights:
		</h4>
		<p>
			In certain situations, we may be required to disclose personal information
			in response to lawful requests by public authorities, including to meet
			law enforcement requirements. We may disclose personal information to
			respond to subpoenas, court orders, or legal process, or to establish or
			exercise our legal rights or defend against legal claims. We may also
			share such information if we believe it is necessary in order to
			investigate, prevent, or take action regarding illegal activities,
			suspected fraud, situations involving potential threats to the physical
			safety of any person, violations of our Service Agreement, or as otherwise
			required by law.
		</p>
		<h4>Community Forums:</h4>
		<p>
			The Websites may offer publicly accessible blogs, community forums,
			comments sections, discussion forums, or other interactive features
			(“Interactive Areas”). You should be aware that any information that you
			post in an Interactive Area might be read, collected, and used by others
			who access it. To request removal of your personal information from an
			Interactive Area, contact us at privacy@compass-security.com. In some
			cases, we may not be able to remove your personal information, in which
			case we will let you know if we are unable to do so and why.
		</p>
		<h4>CSNC Group Sharing:</h4>
		<p>
			We may share information, including personal information, with any member
			of the CSNC Group.
		</p>
		<h4>With Your Consent:</h4>
		<p>
			We may also share personal information with third parties when we have
			your consent to do so.
		</p>
		<h2>8 International Transfer Of Information Collected</h2>
		<p>
			CSNC is a Switzerland based, global company. We store personal information
			about Website Visitors and Subscribers in Switzerland or in a member of
			the EU or in Canada. To facilitate our global operations, we may transfer
			and access such personal information from around the world, including from
			other countries in which the CSNC Group has operations for the purposes
			described in this Policy. We may also transfer your personal information
			to our third party subprocessors as in section 7, who may be located in a
			different country to you. However, we only process your personal data in
			countries which are part of the EU or apply the same level of data
			protection as defined in the GDPR.
		</p>
		<p>
			Whenever CSNC shares personal information with a CSNC entity it will do so
			on the basis of its <b>CSNC Binding Data Protection Rules</b> which
			establish adequate protection of such personal information and are legally
			binding on the CSNC Group.
		</p>
		<p>
			If you are visiting our Websites please note that you are agreeing to the
			transfer of your personal information to the jurisdictions in which we
			operate. By providing your personal information, you consent to any
			transfer and processing in accordance with this Policy.
		</p>
		<h2>9 Communications Preferences</h2>
		<p>
			We offer those who provide personal contact information a means to choose
			how we use the information provided. You may manage your receipt of
			marketing and non- transactional communications by clicking on the
			“unsubscribe” link located on the bottom of our marketing emails or you
			may send a request to{" "}
			<a href="mailto:privacy@compass-security.com">
				privacy@compass-security.com
			</a>
			.
		</p>
		<h2>10 How Long We Retain Your Personal Information:</h2>
		<p>
			We will retain your personal information for as long as is needed to
			fulfil the purposes outlined in this Privacy Policy, unless a longer
			retention period is required or permitted by law (such as tax, accounting
			or other legal requirements). When we have no ongoing legitimate business
			need to process your personal information, we will delete it. If this is
			not possible (for example, because your personal information has been
			stored in backup archives), then we will securely store your personal
			information and isolate it from any further processing until deletion is
			possible.
		</p>
		<h2>11 Your Privacy Rights</h2>
		<p>
			<b>
				The security of your personal information is important to us. We follow
				the GDPR standards to protect the personal information submitted to us.
				If you have any questions about the security of your personal
				information, you can contact us at{" "}
				<a href="mailto:privacy@compass-security.com">
					privacy@compass-security.com
				</a>
				.
			</b>
		</p>
		<p>
			Upon request we will provide you with information about whether we hold,
			or process personal information about you. To request this information
			please contact us{" "}
			<a href="mailto:privacy@compass-security.com">
				privacy@compass-security.com
			</a>
			.
		</p>
		<p>In addition, you will have the following rights:</p>
		<li>
			<b>Right of erasure:</b> You have a right to erasure of personal
			information that we hold about you – for example, if it is no longer
			necessary in relation to the purposes for which it was originally
			collected. Please note, however, that we may need to retain certain
			information for record keeping purposes, to complete transactions or to
			comply with our legal obligations.
		</li>
		<li>
			<b>Right to object to processing:</b> You have the right to request that
			CSNC stop processing your personal information and/or to stop sending you
			marketing communications.
		</li>
		<li>
			<b>Right to restrict processing:</b> You have the right to request that we
			restrict processing of your personal information in certain circumstances
			(for example, where you believe that the personal information we hold
			about you is inaccurate or unlawfully held).
		</li>
		<li>
			<b>Right to data portability:</b> In certain circumstances, you may have
			the right to be provided with your personal information in a commonly used
			format and to request that we transfer the personal information to another
			data controller without hindrance.
		</li>

		<p>
			To make a request to have personal information maintained by us returned
			to you or removed, please email us. Requests to access, change, or remove
			your information will be handled within thirty (30) days.
		</p>
		<p>
			If you would like to exercise such rights, please contact us at the
			contact details in Section 16 below. We will consider your request in
			accordance with applicable laws. To protect your privacy and security, we
			may take steps to verify your identity before complying with the request.
		</p>
		<p>
			You also have the right to complain to a data protection authority about
			our collection and use of your personal information. For more information,
			please contact your local data protection authority.
		</p>
		<h2>12 Children’s Personal Information</h2>
		<p>
			We do not knowingly collect any personal information from children. We
			encourage parents and legal guardians to monitor their children’s Internet
			usage and to help enforce this Policy by instructing their children never
			to provide personal information through the Websites or Services without
			their permission. If you have reason to believe that a child under the age
			of 14 has provided personal information to us through the Websites or
			Services, please contact us at{" "}
			<a href="mailto:privacy@compass-security.com">
				privacy@compass-security.com
			</a>{" "}
			and we will use commercially reasonable efforts to delete that
			information.
		</p>
		<h2>13 Business Transactions</h2>
		<p>
			We may assign or transfer this Policy, as well as your account and related
			information and data, including any personal information, to any person or
			entity that acquires all or substantially all of our business, stock or
			assets, or with whom we merge.
		</p>
		<h2>14 Supplemental Terms and Conditions for Certain Regions</h2>
		<h4>Canada:</h4>
		<p>
			Personal information (as the term is defined in the Personal Information
			Protection and Electronic Documents Act of Canada (“PIPEDA”)) will be
			collected, stored, used and/or processed by the CSNC Group in compliance
			with the CSNC Group’s obligations under PIPEDA.
		</p>
		<h2>15 Changes To This Policy</h2>
		<p>
			If there are any material changes to this Policy, you will be notified by
			our posting of a prominent notice on the Websites prior to the change
			becoming effective. Based on the severity and impact on your personal
			rights, your consent might be required for certain changes. In this case,
			we will notify you and ask for your consent.
		</p>
		<p>
			In cases of minor changes which do not have an impact on your privacy, we
			will announce the changes on the websites. We encourage you to
			periodically review this page for the latest information on our privacy
			practices. Your continued use of the Websites or the Services constitutes
			your agreement to be bound by such changes to this Policy. Your only
			remedy, if you do not accept the terms of this Policy, is to discontinue
			use of the Websites and the Services.
		</p>
		<h2>16 Contact Us</h2>
		<p>
			If you have questions regarding this Policy or about the CSNC Group’s
			privacy practices, please contact us by email at{" "}
			<a href="mailto:privacy@compass-security.com">
				privacy@compass-security.com
			</a>
			, or at:
		</p>
		<p>
			<div>Compass Security Network Computing</div>
			<div>AG Werkstrasse 20</div>
			<div>CH-8645 Jona</div>
			<div>
				<a href="mailto:privacy@compass-security.com">
					privacy@compass-security.com
				</a>
			</div>
			<div>Phone: +41 55 214 41 60</div>
		</p>
		<h2>17 Choice of Law/Forum Selection</h2>
		<p>
			This Privacy Policy shall be subject to and construed in accordance with
			Swiss law.
		</p>
		<p>
			The exclusive place of jurisdiction for disputes arising from the Use of
			Terms is Rapperswil-Jona, Canton of St Gallen (Switzerland).
		</p>
		<p>English Version Controls:</p>
		<p>
			English translations of this Policy are provided for convenience only. In the event of any ambiguity or conflict between translations,
			the <a href="https://compass-security.com/de/rechtliches/datenschutz" target="_blank" rel="noopener noreferrer">German version</a> is authoritative.
		</p>
	</div>
);

export default PrivacyPolicy;

import { useProgressStyle } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/progressStyle";
import { useIntl } from "@hlcr/ui/Intl";
import { useSelector } from "react-redux";

import IconCard from "components/Cards/IconCard";
import { getEventProgressHelper } from "components/EventProgress/ProgressHelper";
import { SolvableEventUnit } from "models/EventUnit";
import { RootState } from "reducers";
import { fetchHelp } from "variables/helpPage";

import Progress from "./Progress";

interface EventProgressProps {
	units: SolvableEventUnit[];
	parentId?: number;
}

const EventUnitProgress = ({ units, parentId }: EventProgressProps) => {
	const helpUrl = useSelector((state: RootState) => state.branding.helpUrl);
	const classes = useProgressStyle();
	const intl = useIntl();

	return !units?.length ? null : (
		<IconCard
			title="Progress"
			helpLink={fetchHelp(helpUrl, "student", "curriculumProgress")}
			faIcon="check"
			content={
				<div className={classes.events}>
					{units.map(unit => {
						const progressHelper = getEventProgressHelper(intl, unit, parentId);
						return <Progress progressHelper={progressHelper} key={unit.id} />;
					})}
				</div>
			}
			fluidWidth
		/>
	);
};

export default EventUnitProgress;

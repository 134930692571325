import { EventType } from "models/EventType";

import guid from "../helper/guid";
import { createSuccessMessage } from "middleware/api";
import { removeApiSuccess } from "./api";
import { ActionType } from "actions/ActionType";

const BASE_URL = "/api";

const queryManagerEvents = (query, types, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events?query=${query}&type=${types.join()}`,
	method: "get",
	onSuccess: (_, result) => {
		if (onSuccess) {
			onSuccess(result);
		}
	}
});

const cloneEventIntoCurriculum = (eventId, curriculumId, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/curricula/${curriculumId}/events/`,
	method: "post",
	body: { id: eventId },
	onSuccess: (_, result) => {
		if (onSuccess) {
			onSuccess(result);
		}
	}
});

const fetchClassImageUrl = (maybeImageUrl, onSuccess, onFailure) => ({
	type: ActionType.API_REQUEST,
	url: maybeImageUrl,
	method: "get",
	onSuccess,
	onFailure,
	suppressNotification: true
});

const fetchManagerCurriculumEvents = eventId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/curriculumevents/`,
	method: "get",
	resource: "managerEvents",
	updatePropForArray: {
		key: "parent",
		value: eventId
	}
});

const fetchManagerEvent = (eventId, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}`,
	method: "get",
	resource: "managerEvents",
	onSuccess: (_, result) => {
		if (onSuccess) {
			onSuccess(result);
		}
	}
});

const fetchManagerEvents = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events?type=${EventType.CURRICULUM},${EventType.STANDALONE_EVENT}`,
	method: "get",
	resource: "managerEvents",
	updatePropForArray: {
		key: "parent",
		value: undefined
	}
});

const addEvent = (event, image, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/`,
	method: "post",
	body: event,
	resource: "managerEvents",
	onSuccess: (dispatch, result) => {
		if (result && result.id && image) {
			dispatch(uploadManagerImage(image, "events", result.id, onSuccess));
		} else {
			onSuccess && onSuccess(result.id);
		}
	}
});

const addCurriculumEvent = (event, parentId, image, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${parentId}/curriculumevents/`,
	method: "post",
	body: event,
	onSuccess: (dispatch, result) => {
		if (result && result.id && image) {
			dispatch(uploadManagerImage(image, "events", result.id, onSuccess));
		} else {
			onSuccess && onSuccess(result.id);
		}
	}
});

const cloneEvent = (event, image, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events?cloneFrom=${event.id}`,
	method: "post",
	body: event,
	resource: "managerEvents",
	onSuccess: (dispatch, result) => {
		if (result?.id && image) {
			dispatch(uploadManagerImage(image, "events", result.id, onSuccess));
		} else {
			onSuccess && onSuccess(result.id);
		}
	}
});

const updateManagerEvent = (event, image) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${event.id || "undefined"}`,
	method: "put",
	body: event,
	resource: "managerEvents",
	onSuccess: dispatch => {
		if (event && event.id && image) {
			dispatch(uploadManagerImage(image, "events", event.id, undefined, true));
		} else if (image === null && event && event.id) {
			dispatch(removeManagerImage("events", event.id, undefined, true));
		} else {
			createSuccessMessage(dispatch, true);
		}
	}
});

const forcePublishRanking = (eventId) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/publish_ranking/`,
	method: "put",
	successNotification: "Ranking published successfully"
});

const restoreManagerEvent = (event) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${event.id || "undefined"}/restore/`,
	method: "put",
	body: event,
	resource: "managerEvents",
	successNotification: "Restored successfully"
});

const uploadManagerImage = (
	image,
	entityType,
	id,
	onSuccess,
	successNotification
) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/images/manager/${entityType}/${id}`,
	nonJsonBody: image,
	method: "put",
	onSuccess: () => onSuccess && onSuccess(id),
	successNotification
});

const removeManagerImage = (
	entityType,
	id,
	onSuccess,
	successNotification
) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/images/manager/${entityType}/${id}`,
	method: "delete",
	onSuccess: onSuccess,
	successNotification
});

const deleteManagerEvent = (event, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${event.id || "undefined"}`,
	method: "delete",
	entity: event,
	resource: "managerEvents",
	onSuccess: onSuccess,
	successNotification: "Removed successfully"
});

const deleteManagerCurriculumEvent = (event, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${event.id}`,
	method: "delete",
	entity: event,
	resource: "managerEvents",
	onSuccess: onDone,
	onFailure: onDone,
	successNotification: "Removed successfully"
});

const fetchManagerUnits = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/eventunits/`,
	method: "get",
	resource: "managerUnits"
});

const fetchManagerEventUnits = (eventId, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/units/`,
	method: "get",
	resource: "managerEventUnits",
	updatePropForArray: {
		key: "eventId",
		value: eventId
	},
	onSuccess
});

const addManagerEventUnit = (eventUnit, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventUnit.eventId}/units/`,
	method: "post",
	body: eventUnit,
	resource: "managerEventUnits",
	onSuccess: onDone,
	onFailure: onDone
});

const updateManagerCurriculumEvent = (
	event,
	image,
	onBefore,
	onDone,
	successNotification
) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${event.id}`,
	method: "put",
	body: event,
	resource: "managerEvents",
	onSuccess: dispatch => {
		if (event && event.id && image) {
			dispatch(uploadManagerImage(image, "events", event.id, undefined, false));
		} else if (image === null && event && event.id) {
			dispatch(removeManagerImage("events", event.id, undefined, false));
		} else {
			createSuccessMessage(dispatch, true);
		}
		if (onDone) onDone();
	},
	onFailure: onDone,
	onBefore: onBefore,
	successNotification:
		successNotification !== undefined ? successNotification : true
});

const moveCurriculumEvent = (
	parentId,
	curriculumEventId,
	direction,
	onDone
) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${parentId}/curriculumevents/${curriculumEventId}/sort`,
	method: "post",
	body: { move: direction },
	resource: "managerEvents",
	updatePropForArray: {
		key: "parent",
		value: parentId
	},
	onSuccess: onDone,
	onFailure: onDone,
	successNotification: "Moved successfully"
});

const updateManagerEventUnit = (
	eventUnit,
	onBefore,
	onDone,
	successNotification
) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventUnit.eventId}/units/${eventUnit.type}/${eventUnit.id}`,
	method: "put",
	body: eventUnit,
	resource: "managerEventUnits",
	updatePropForObj: { idSelector: dto => dto.uuid },
	onSuccess: onDone,
	onFailure: onDone,
	onBefore: onBefore,
	successNotification:
		successNotification !== undefined ? successNotification : true
});

const removeManagerEventUnit = (eventUnit, onBefore, onDone, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventUnit.eventId}/units/${eventUnit.type}/${eventUnit.id}`,
	method: "delete",
	entity: eventUnit,
	resource: "managerEventUnits",
	updatePropForObj: { idSelector: dto => dto.uuid },
	onSuccess: () => {
		if (onDone) onDone();
		if (onSuccess) onSuccess();
	},
	onFailure: onDone,
	onBefore: onBefore,
	successNotification: true
});

const resetManagerEventUnit = (eventUnit) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventUnit.eventId}/units/${eventUnit.type}/${eventUnit.id}/reset/`,
	method: "put",
	successNotification: "Reset successfully"
});

const fetchUsers = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/users/`,
	method: "get",
	resource: "managerUsers"
});

const fetchManagerTeamsWithMembers = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/`,
	method: "get",
	resource: "managerTeams",
	onSuccess: (dispatch, teams) => {
		teams.length &&
			teams.forEach(team => {
				dispatch(fetchTeamMembers(team.id));
			});
	}
});

const fetchManagerTeams = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/`,
	method: "get",
	resource: "managerTeams"
});

const fetchTeam = id => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${id}/`,
	method: "get",
	resource: "managerTeams",
	onSuccess: (dispatch, team) => {
		dispatch(fetchTeamMembers(team.id));
	}
});

const fetchTeamMembers = teamId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}/members/`,
	method: "get",
	resource: "managerTeamMembers",
	updatePropForArray: {
		key: "teamId",
		value: teamId
	},
	setFieldOnObject: {
		key: "teamId",
		value: teamId
	}
});

const addTeam = (team, image, onSuccess, onFailure) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/`,
	method: "post",
	body: team,
	resource: "managerTeams",
	onSuccess: (dispatch, teamDto) => {
		if (image && teamDto && teamDto.id) {
			dispatch(uploadManagerImage(image, "teams", teamDto.id, onSuccess));
		} else {
			onSuccess && onSuccess(teamDto.id);
		}
	},
	onFailure
});

const updateTeam = (team, image, onBefore) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${team.id}`,
	method: "put",
	body: team,
	resource: "managerTeams",
	onSuccess: (dispatch, teamDto) => {
		if (teamDto.id && image) {
			dispatch(uploadManagerImage(image, "teams", team.id, undefined, true));
		} else if (teamDto.id && image === null) {
			dispatch(removeManagerImage("teams", teamDto.id, undefined, true));
		} else {
			createSuccessMessage(dispatch, true);
		}
	},
	onBefore: onBefore
});

const removeTeam = (team, onSuccess, successNotification) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${team.id}`,
	method: "delete",
	resource: "managerTeams",
	entity: team,
	onSuccess: dispatch => {
		dispatch(
			removeApiSuccess(
				"managerTeamMembers",
				{ teamId: team.id },
				{ idSelector: teamMember => teamMember.teamId }
			)
		);
		if (onSuccess) onSuccess();
	},
	successNotification
});

const archiveTeam = (team, successNotification, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${team.id}/archive`,
	entity: team,
	method: "put",
	resource: "managerTeams",
	successNotification,
	onSuccess: onDone
});

const removeTeamMember = (teamId, userIdentifier, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}/members/${userIdentifier}`,
	method: "delete",
	resource: "managerTeamMembers",
	entity: { teamId, user: { userIdentifier } },
	updatePropForObj: { idSelector: member => `${member.teamId}.${member.user.userIdentifier}` },
	onSuccess
});

const addTeamMember = (userIdentifier, teamId, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}/members/`,
	method: "post",
	body: { userIdentifier, teamId },
	updatePropForObj: { idSelector: member => `${member.teamId}.${member.user.userIdentifier}` },
	resource: "managerTeamMembers",
	onSuccess: (dispatch, teamMember) => onSuccess && onSuccess(teamMember)
});

const updateTeamMember = (userIdentifier, teamId, leader, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teams/${teamId}/members/${userIdentifier}`,
	method: "put",
	body: {
		userIdentifier,
		teamId,
		leader
	},
	updatePropForObj: { idSelector: member => `${member.teamId}.${member.user.userIdentifier}` },
	resource: "managerTeamMembers",
	onSuccess: (_, teamMember) => onSuccess && onSuccess(teamMember)
});

const fetchManagerEventTeams = eventId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/teams/`,
	method: "get",
	resource: "managerEventTeams",
	updatePropForArray: {
		key: "eventId",
		value: eventId
	}
});

const addManagerEventTeam = eventTeam => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventTeam.eventId}/teams/`,
	method: "post",
	body: eventTeam,
	resource: "managerEventTeams",
	updatePropForObj: { idSelector: dto => `${dto.eventId}.${dto.team.id}` }
});

const updateManagerEventTeam = (eventTeam, onBefore, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventTeam.eventId}/teams/${eventTeam.teamId}`,
	method: "put",
	body: eventTeam,
	resource: "managerEventTeams",
	updatePropForObj: { idSelector: dto => `${dto.eventId}.${dto.team.id}` },
	onSuccess: onDone,
	onFailure: onDone,
	onBefore: onBefore
});

const removeManagerEventTeam = (eventTeam, onBefore, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventTeam.eventId}/teams/${eventTeam.team.id}`,
	method: "delete",
	entity: eventTeam,
	resource: "managerEventTeams",
	updatePropForObj: { idSelector: dto => `${dto.eventId}.${dto.team.id}` },
	onSuccess: onDone,
	onFailure: onDone,
	onBefore: onBefore
});

const fetchManagerUsers = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/users/`,
	method: "get",
	resource: "managerUsers",
	updatePropForObj: { idSelector: dto => dto.userIdentifier }
});

const fetchManagerTeachers = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/teachers/`,
	method: "get",
	resource: "managerTeachers",
	setFieldOnObject: { key: "isTeacher", value: true },
	updatePropForObj: { idSelector: dto => dto.userIdentifier }
});

const fetchManagerStudents = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/students/`,
	method: "get",
	resource: "managerStudents",
	updatePropForObj: { idSelector: dto => dto.userIdentifier }
});

const fetchManagerEventUsers = eventId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/users/`,
	method: "get",
	resource: "managerEventUsers"
});

const addManagerEventUser = eventUser => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventUser.eventId}/users/`,
	method: "post",
	body: eventUser,
	resource: "managerEventUsers",
	updatePropForObj: { idSelector: dto => `${dto.eventId}.${dto.user.userIdentifier}` }
});

const updateManagerEventUser = (eventUser, onBefore, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventUser.eventId}/users/${eventUser.userIdentifier}`,
	method: "put",
	body: eventUser,
	resource: "managerEventUsers",
	updatePropForObj: { idSelector: dto => `${dto.eventId}.${dto.user.userIdentifier}` },
	onSuccess: onDone,
	onFailure: onDone,
	onBefore: onBefore
});

const removeManagerEventUser = (eventUser, onBefore, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventUser.eventId}/users/${eventUser.user.userIdentifier}`,
	method: "delete",
	entity: eventUser,
	resource: "managerEventUsers",
	updatePropForObj: { idSelector: dto => `${dto.eventId}.${dto.user.userIdentifier}` },
	onSuccess: onDone,
	onFailure: onDone,
	onBefore: onBefore
});

const fetchManagerClasses = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/`,
	method: "get",
	resource: "managerClasses"
});

const fetchManagerClass = classId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/${classId}`,
	method: "get",
	resource: "managerClasses"
});

const removeClass = (managerClass, successNotification, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/${managerClass.id}`,
	entity: managerClass,
	method: "delete",
	resource: "managerClasses",
	successNotification,
	onSuccess: onDone
});

const archiveClass = (managerClass, successNotification, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/${managerClass.id}/archive`,
	entity: managerClass,
	method: "put",
	resource: "managerClasses",
	successNotification,
	onSuccess: onDone
});

const addClass = (managerClass, image, onSuccess) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/`,
	body: managerClass,
	method: "post",
	resource: "managerClasses",
	onSuccess: (dispatch, result) => {
		if (result && result.id && image) {
			dispatch(uploadManagerImage(image, "classes", result.id, onSuccess));
		} else {
			onSuccess && onSuccess(result.id);
		}
	}
});

const updateManagerClass = (managerClass, image) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/${managerClass.id || "undefined"}`,
	method: "put",
	body: managerClass,
	resource: "managerClasses",
	onSuccess: dispatch => {
		if (managerClass && managerClass.id && image) {
			dispatch(
				uploadManagerImage(image, "classes", managerClass.id, undefined, true)
			);
		} else if (image === null && managerClass && managerClass.id) {
			dispatch(removeManagerImage("classes", managerClass.id, undefined, true));
		} else {
			createSuccessMessage(dispatch, true);
		}
	}
});

const fetchManagerClassTeachers = classId => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/${classId}/teachers/`,
	method: "get",
	resource: "managerClassTeachers",
	updatePropForArray: {
		key: "classId",
		value: classId
	},
	setFieldOnObject: { key: "classId", value: classId }
});

const addManagerClassTeacher = classTeacher => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/${classTeacher.classId}/teachers/`,
	method: "post",
	body: classTeacher,
	resource: "managerClassTeachers",
	setFieldOnObject: { key: "classId", value: classTeacher.classId },
	updatePropForObj: { idSelector: dto => `${dto.classId}.${dto.userIdentifier}` },
	successNotification: true
});

const removeManagerClassTeacher = (classTeacher, onBefore, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/classes/${classTeacher.classId}/teachers/${classTeacher.userIdentifier}`,
	method: "delete",
	entity: classTeacher,
	resource: "managerClassTeachers",
	updatePropForObj: { idSelector: dto => `${dto.classId}.${dto.userIdentifier}` },
	onSuccess: onDone,
	onFailure: onDone,
	onBefore: onBefore,
	successNotification: true
});

const fetchAccessTokens = (eventId, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/accesstokens/`,
	method: "get",
	resource: "accessTokens",
	updatePropForArray: {
		key: "eventId",
		value: eventId
	},
	onSuccess: onDone,
	onFailure: onDone
});

const generateAccessTokens = (eventId, amount, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/accesstokens/`,
	method: "post",
	body: { amount },
	onSuccess: onDone,
	onFailure: onDone,
	successNotification: "New Access Tokens generated"
});

const updateStaticToken = (
	eventId,
	tokenId,
	token,
	maxRedemptions,
	onSuccess
) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/events/${eventId}/accesstokens/${tokenId}`,
	method: "PUT",
	body: { token, maxRedemptions },
	resource: "accessTokens",
	updatePropForObj: { idSelector: token => `${token.id}.${token.eventId}` },
	onSuccess: onSuccess
});

const getALlVms = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/vms/manager/`,
	method: "get",
	resource: "managerVms"
});

const getVmState = (vm, onDone) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/vms/manager/${vm.uuid}/state`,
	method: "get",
	resource: "managerVms",
	updatePropForObj: { idSelector: dto => dto.uuid },
	updateField: { key: "state", entityId: vm.uuid },
	onFailure: onDone,
	onSuccess: onDone
});

const vmAction = (vm, onBefore, onDone) => ({
	type: ActionType.API_REQUEST,
	body: { resourceUuid: vm.uuid },
	method: "post",
	onSuccess: dispatch => dispatch(getVmState(vm, onDone)),
	onBefore: onBefore,
	onFailure: onDone
});

const startVm = (vm, onBefore, onDone) => ({
	...vmAction(vm, onBefore, onDone),
	url: `${BASE_URL}/vms/manager/${vm.uuid}/start`
});

const stopVm = (vm, onBefore, onDone) => ({
	...vmAction(vm, onBefore, onDone),
	url: `${BASE_URL}/vms/manager/${vm.uuid}/stop`
});

const resetVm = (vm, onBefore, onDone) => ({
	...vmAction(vm, onBefore, onDone),
	url: `${BASE_URL}/vms/manager/${vm.uuid}/reset`
});

const revertVm = (vm, onBefore, onDone) => ({
	...vmAction(vm, onBefore, onDone),
	url: `${BASE_URL}/vms/manager/${vm.uuid}/revert`
});

const fetchUserProfile = userIdentifier => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/users/${userIdentifier}/profile`,
	method: "get",
	resource: "managerProfiles",
	updatePropForObj: { idSelector: dto => dto.userIdentifier },
	setFieldOnObject: { key: "guid", value: guid() }
});

const updateUserProfile = (userIdentifier, profile) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/users/${userIdentifier}/profile`,
	method: "put",
	body: profile,
	successNotification: true
});

export default {
	cloneEventIntoCurriculum,
	queryManagerEvents,
	fetchClassImageUrl,
	fetchManagerCurriculumEvents,
	fetchManagerEvent,
	fetchManagerEvents,
	addEvent,
	addCurriculumEvent,
	cloneEvent,
	updateManagerEvent,
	forcePublishRanking,
	restoreManagerEvent,
	uploadManagerImage,
	removeManagerImage,
	deleteManagerEvent,
	deleteManagerCurriculumEvent,
	fetchManagerUnits,
	fetchManagerEventUnits,
	addManagerEventUnit,
	moveCurriculumEvent,
	updateManagerEventUnit,
	removeManagerEventUnit,
	updateManagerCurriculumEvent,
	fetchUsers,
	fetchManagerTeamsWithMembers,
	fetchManagerTeams,
	fetchTeam,
	fetchTeamMembers,
	addTeam,
	updateTeam,
	removeTeam,
	archiveTeam,
	removeTeamMember,
	addTeamMember,
	updateTeamMember,
	fetchManagerEventTeams,
	addManagerEventTeam,
	updateManagerEventTeam,
	removeManagerEventTeam,
	resetManagerEventUnit,
	fetchManagerUsers,
	fetchManagerTeachers,
	fetchManagerStudents,
	fetchManagerEventUsers,
	addManagerEventUser,
	updateManagerEventUser,
	removeManagerEventUser,
	fetchManagerClasses,
	fetchManagerClass,
	removeClass,
	archiveClass,
	addClass,
	updateManagerClass,
	fetchManagerClassTeachers,
	addManagerClassTeacher,
	removeManagerClassTeacher,
	fetchAccessTokens,
	generateAccessTokens,
	updateStaticToken,
	getALlVms,
	startVm,
	stopVm,
	resetVm,
	revertVm,
	fetchUserProfile,
	updateUserProfile
};

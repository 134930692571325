import { useIntl } from "@hlcr/ui/Intl";
import { ChartOptions } from "chart.js";
// eslint-disable-next-line import/no-named-as-default -- is needed that ranking project builds, it don't know why it event compiles this code...
import Chart from "chart.js/auto"; // this one is actually needed for chart.js to register its stuff, event if typescript thinks it's wrong
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { DynamicScoringSimulationDataPoint, fetchDynamicScoringPreview } from "views/Manager/DynamicScoring/dynamicScoringSimulationChart.action";

interface DynamicScoringSimulationChartProps {
	dynamicScoringMinimumPoints: number;
	dynamicScoringMaximumPoints: number;
	dynamicScoringDecayFactor: number;
	simulateParticipantCount: number;
	triggerRedraw: number; // just increase this number by +1 to trigger a redraw
}

const DynamicScoringSimulationChart: React.FC<DynamicScoringSimulationChartProps> = ({ dynamicScoringMaximumPoints, dynamicScoringMinimumPoints, dynamicScoringDecayFactor, simulateParticipantCount, triggerRedraw }) => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null);
	const darkMode = useSelector((state) => (state as any).ui.darkMode);
	const intl = useIntl();

	const [data, setData] = useState<DynamicScoringSimulationDataPoint[]>([]);
	const [chartMin, setChartMin] = useState<number>(0);
	const [chartMax, setChartMax] = useState<number>(0);

	// ensure that stuff is really a number
	dynamicScoringMaximumPoints = Number(dynamicScoringMaximumPoints);
	dynamicScoringMinimumPoints = Number(dynamicScoringMinimumPoints);
	dynamicScoringDecayFactor = Number(dynamicScoringDecayFactor);
	simulateParticipantCount = Number(simulateParticipantCount);

	// We extend the range a bit to make the min/max lines not sticking to bottom/top
	useEffect(() => {
		setChartMin(dynamicScoringMinimumPoints - dynamicScoringMinimumPoints * 0.1);
	}, [dynamicScoringMinimumPoints]);
	useEffect(() => {
		setChartMax(dynamicScoringMaximumPoints + dynamicScoringMaximumPoints * 0.1);
	}, [dynamicScoringMaximumPoints]);

	// Fetch the data from the API without Redux, as this makes no sense in this case
	useEffect(() => {
		fetchDynamicScoringPreview({
			decayFactor: dynamicScoringDecayFactor,
			minimumPoints: dynamicScoringMinimumPoints,
			maximumPoints: dynamicScoringMaximumPoints,
			participantCount: simulateParticipantCount,
		}).then((data) => setData(data));
	}, [triggerRedraw]);

	// Draw/redraw chart based on changes on data object
	useEffect(() => {
		const ctx = canvasRef.current?.getContext("2d");
		if (!ctx) return;

		const options: ChartOptions = {
			responsive: true,
			scales: {
				x: {
					title: {
						display: true,
						text: intl.fm("event.field.scoringMode.settings.chart.x"),
						color: darkMode ? "#AAAAAA" : undefined,
					},
					grid: darkMode ? { color: "#888888" } : undefined,
					ticks: darkMode ? { color: "#AAAAAA" } : undefined,
				},
				y: {
					title: {
						display: true,
						text: intl.fm("event.field.scoringMode.settings.chart.y"),
						color: darkMode ? "#AAAAAA" : undefined,
					},
					grid: darkMode ? { color: "#888888" } : undefined,
					ticks: darkMode ? { color: "#AAAAAA" } : undefined,
					min: chartMin,
					max: chartMax,
				},
			},
			plugins: { legend: { display: false } },
		};

		const chart = new Chart(ctx, {
			type: "line",
			data: {
				labels: data.map((dataPoint) => dataPoint.solvingCount),
				datasets: [
					{
						label: intl.fm("event.field.scoringMode.settings.chart.scores"),
						data: data.map((dataPoint) => dataPoint.points),
						fill: false,
						backgroundColor: "#00bcd4",
						borderColor: "#00bcd4",
					},
					{
						label: intl.fm("event.field.scoringMode.settings.chart.max"),
						data: Array.from({ length: data.length }, () => dynamicScoringMaximumPoints),
						borderColor: "#ff9800",
						borderDash: [10, 5],
						borderWidth: 1,
						fill: false,
						pointRadius: 0,
					},
					{
						label: intl.fm("event.field.scoringMode.settings.chart.min"),
						data: Array.from({ length: data.length }, () => dynamicScoringMinimumPoints),
						borderColor: "#ff9800",
						borderDash: [10, 5],
						borderWidth: 1,
						fill: false,
						pointRadius: 0,
					},
				],
			},
			options: options,
		});

		// Cleanup the chart to prevent memory leaks
		return () => {
			chart.destroy();
		};
	}, [darkMode, data]);

	return <canvas ref={canvasRef} />;
};

export default DynamicScoringSimulationChart;

import { dangerColor, defaultFont, grayColor, infoColor, primaryColor, roseColor, successColor, warningColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";

const tableStyle = {
	warning: { color: warningColor },
	primary: { color: primaryColor },
	danger: { color: dangerColor },
	success: { color: successColor },
	info: { color: infoColor },
	rose: { color: roseColor },
	gray: { color: grayColor },
	right: { textAlign: "right" },
	table: {
		marginBottom: "0",
		height: "100%",
		width: "100%",
		maxWidth: "100%",
		backgroundColor: "transparent",
		borderSpacing: "0",
		borderCollapse: "collapse",
		overflow: "auto"
	},
	tableHeadFontSize: { fontSize: "1.25em !important" },
	tableHeadCell: {
		color: "rgba(0, 0, 0, 0.87)",
		border: "none !important",
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 }
	},
	tableCell: {
		...defaultFont,
		lineHeight: "1.42857143",
		padding: "12px 8px",
		verticalAlign: "middle",
		fontSize: "1em",
		borderBottom: "none",
		borderTop: "1px solid #ddd",
		position: "relative",
		[DARK_MODE_SELECTOR]: { borderColor: COLOR.DARK_BORDER }
	},
	tableCellTotal: {
		fontWeight: "500",
		fontSize: "1.25em",
		paddingTop: "14px",
		textAlign: "right"
	},
	tableCellAmount: {
		fontSize: "26px",
		fontWeight: "300",
		marginTop: "5px",
		textAlign: "right"
	},
	tableResponsive: {
		minHeight: "0.1%",
		overflowX: "hidden",
		overflowY: "hidden",
		width: "100%"
	},
	tableStripedRow: { backgroundColor: "#f9f9f9" },
	tableRowHover: {
		"&:hover": {
			backgroundColor: "#f5f5f5",
			[DARK_MODE_SELECTOR]: { backgroundColor: COLOR.DARK_1 }
		}
	},
	warningRow: {
		backgroundColor: "#fcf8e3",
		"&:hover": { backgroundColor: "#faf2cc" }
	},
	dangerRow: {
		backgroundColor: "#f2dede",
		"&:hover": { backgroundColor: "#ebcccc" }
	},
	successRow: {
		backgroundColor: "#dff0d8",
		"&:hover": { backgroundColor: "#d0e9c6" }
	},
	infoRow: {
		backgroundColor: "#d9edf7",
		"&:hover": { backgroundColor: "#c4e3f3" }
	}
};

export default tableStyle;

import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import AttachmentIcon from "@material-ui/icons/Attachment";
import BuildIcon from "@material-ui/icons/Build";
// FileDownload needs to be changed (not cloud...)
import FileDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import ComputerIcon from "@material-ui/icons/Computer";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import IconCard from "components/Cards/IconCard";
import { Button } from "@hlcr/mui/Button";
import Tooltip from "components/CustomTooltip/CustomTooltip";
import ModalWindow from "components/ModalWindow/ModalWindow";
import NoData from "components/NoData/NoData";
import Table from "components/Table/Table";

import eventsApi from "../../actions/events";
import resourcesStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/resourcesStyle";
import { isFinished } from "helper/dateCalc";
import { withIntl } from "@hlcr/ui/Intl";
import { ResourceState, ResourceStateRequest, ResourceType } from "variables/constants";
import { fetchHelp } from "variables/helpPage";
import { checkHasRole } from "auth/authUtils";
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";

class Resources extends React.Component {
	state = {
		isModalOpen: false,
		selectedResource: null,
		pendingIds: [],
		currentTime: moment(),
	};

	interval = setInterval(() => this.setState({ currentTime: moment() }), 1000);

	openResourceModal = resource => {
		this.setState({ isModalOpen: true, selectedResource: resource });
	};

	closeModal = () => {
		this.setState({ isModalOpen: false, selectedResource: null });
	};

	responseAction = (resource, newTab) => {
		switch (resource.type) {
			case ResourceType.Docker:
				if (resource.state === ResourceState.STARTED) {
					this.openResourceModal(resource);
				} else {
					this.setState({ selectedResource: resource });
				}
				break;
			case ResourceType.Vm:
				this.openResourceModal(resource);
				break;
			default:
		}
	};

	handleResourceTrigger = (challengeId, resource, newTab) => {
		const { updateResourceState } = this.props;
		let requestState = ResourceStateRequest.START;
		let onSuccess = null;
		switch (resource.type) {
			case ResourceType.File:
				onSuccess = res => this.responseAction(res, newTab);
				break;
			case ResourceType.Docker:
				if (resource.state !== ResourceState.STOPPED) {
					requestState = ResourceStateRequest.STOP;
				}
				onSuccess = res => this.responseAction(res);
				break;
			case ResourceType.Vm:
				onSuccess = res => this.responseAction(res);
				break;
			default:
		}
		updateResourceState(
			challengeId,
			requestState,
			resource.id,
			onSuccess,
			() => {
				this.setState(state => ({
					pendingIds: [
						...state.pendingIds,
						resource.id,
					],
				}));
			},
			() => {
				this.setState(state => ({ pendingIds: [ ...state.pendingIds.filter(value => value !== resource.id) ] }));
			},
		);
	};

	getResIcon = (type, classes) => {
		switch (type) {
			case ResourceType.Docker:
				return <CloudQueueIcon className={classes.resourceIcon} />;
			case ResourceType.File:
				return <AttachmentIcon className={classes.resourceIcon} />;
			case ResourceType.Vm:
				return <ComputerIcon className={classes.resourceIcon} />;
			default:
				return <BuildIcon className={classes.resourceIcon} />;
		}
	};

	getTableRenderer() {
		const { challenge, event, intl, classes } = this.props;
		const { pendingIds } = this.state;
		return [
			{
				id: "props",
				renderCell: resource => {
					const properties = this.fetchProperties(resource);
					const disabled =
						!resource.properties ||
						(Object.keys(properties).length === 0 &&
						 resource.properties.constructor === Object);
					return (
						<Button
							disabled={disabled}
							onClick={() => this.openResourceModal(resource)}
							customClass={classes.resourceButton}
							color={disabled ? "defaultNoBackground" : "infoNoBackground"}
							size="xs"
						>
							{this.getResIcon(resource.type, classes)}
						</Button>
					);
				},
				toolTip: () => intl.fm("challenge.resource.showProps"),
			},
			{
				id: "expiration",
				renderCell: resource => {
					if (
						resource.expirationTime &&
						resource.type === ResourceType.Docker
					) {
						const remainingMs = Math.max(
							moment(resource.expirationTime).diff(this.state.currentTime),
							0,
						);
						const remainingTimeLabel = remainingMs > 0 ? Math.floor(moment.duration(remainingMs).asHours()) + moment.utc(remainingMs).format(":mm:ss") : intl.fm(
							"challenge.resource.expired");
						return (
							<Tooltip
								placement="right"
								title={`${intl.fm("challenge.resource.expiresOn")} ${moment
									.utc(resource.expirationTime)
									.local()
									.format("DD.MM.YYYY HH:mm")}`}
							>
									<span>
										<div>{intl.fm("challenge.resource.expiresIn")}</div>
										<div className={classes.resourceProperties}>
											{remainingTimeLabel}
										</div>
									</span>
							</Tooltip>
						);
					}
				},
			},
			{
				id: "resourceProperties",
				renderCell: resource => {
					return (
						<div>
							<div>{resource.displayName}</div>
							{resource.state && resource.type === ResourceType.Docker && (
								<div className={classes.resourceProperties}>
									{resource.state}
								</div>
							)}
						</div>
					);
				},
			},
			{
				id: "resourceLink",
				renderCell: resource => (
					<div className={classes.resourceActionLinks}>
						<ResourceLink
							challengeId={challenge.id}
							resource={resource}
							classes={classes}
							intl={intl}
							handleResourceTrigger={this.handleResourceTrigger}
							pending={pendingIds.some(id => id === resource.id)}
							disabled={isFinished(event, challenge) && !checkHasRole(HackingLabRole.COMPOSITE_TEACHER)}
						/>
					</div>
				),
			},
		];
	}

	createDownloadBtn = resource => () => {
		const { challenge, event, classes } = this.props;

		return (
			<DownloadButton
				challengeId={challenge.id}
				resource={resource}
				classes={classes}
				handleResourceTrigger={this.handleResourceTrigger}
				disabled={isFinished(event, challenge) && !checkHasRole(HackingLabRole.COMPOSITE_TEACHER)}
			/>
		);
	};

	createStartStopBtn = resource => () => {
		const { challenge, event, classes } = this.props;

		return (
			<StartStopButton
				challengeId={challenge.id}
				resource={resource}
				classes={classes}
				handleResourceTrigger={this.handleResourceTrigger}
				disabled={isFinished(event, challenge) && !checkHasRole(HackingLabRole.COMPOSITE_TEACHER)}
			/>
		);
	};

	createOpenBtn = resource => () => {
		const { challenge, event, classes } = this.props;

		return (
			<OpenLinkButton
				challengeId={challenge.id}
				resource={resource}
				classes={classes}
				handleResourceTrigger={this.handleResourceTrigger}
				disabled={isFinished(event, challenge) && !checkHasRole(HackingLabRole.COMPOSITE_TEACHER)}
			/>
		);
	};

	fetchProperties(res) {
		const { intl, dcServiceDomain } = this.props;
		const { name, type, properties } = res;

		switch (type) {
			case ResourceType.Docker:
				const openLabel = intl.fm("challenge.resource.open");
				const startStopLabel =
					res.state === ResourceState.STARTED
						? intl.fm("challenge.resource.stop")
						: intl.fm("challenge.resource.start");

				const propsWithOpen = res.hyperlink
					? { [openLabel]: this.createOpenBtn(res), ...properties }
					: properties;
				return {
					[startStopLabel]: this.createStartStopBtn(res),
					...propsWithOpen,
				};

			case ResourceType.File:
				const downloadLabel = intl.fm("challenge.resource.download");
				return { [downloadLabel]: this.createDownloadBtn(res), ...properties };

			case ResourceType.Vm:
				const vmUrl = `${name}.vm.${dcServiceDomain}`.toLowerCase();
				return { hostname: vmUrl, ...properties };

			default:
				return properties;
		}
	}

	render() {
		const { challenge, intl, helpUrl } = this.props;
		const { selectedResource, isModalOpen } = this.state;

		const tableData =
			challenge.resources && challenge.resources.length
				? challenge.resources
					.sort((a, b) => (a.name < b.name ? -1 : 1))
					.sort((a, b) => (a.type < b.type ? -1 : 1))
				: undefined;

		return (
			challenge && (
				<React.Fragment>
					<IconCard
						icon={OpenInNewIcon}
						title={intl.fm("challenge.titles.resources")}
						iconColor="purple"
						helpLink={fetchHelp(helpUrl, "student", "challengeResources")}
						content={
							tableData ? (
								<Table
									tableRenderer={this.getTableRenderer()}
									tableData={tableData}
									hover
								/>
							) : (
								<NoData text={intl.fm("challenge.nodata.resources")} />
							)
						}
					/>
					{selectedResource && (
						<ResourcePropertiesModal
							properties={this.fetchProperties(selectedResource)}
							intl={intl}
							isOpen={isModalOpen}
							onClose={this.closeModal}
						/>
					)}
				</React.Fragment>
			)
		);
	}
}

Resources.propTypes = { challenge: PropTypes.object };

const openInNewTab = url => {
	const win = window.open(url, "_blank");
	win.focus();
};

const DownloadButton = (
	{
		disabled,
		resource,
		classes,
	},
) => {
	return (
		<Button
			href={resource.hyperlink}
			download={true}
			customClass={classes.resourceButton}
			color="infoNoBackground"
			size="xs"
			disabled={disabled}
		>
			<FileDownloadIcon />
		</Button>
	);
};

const StartStopButton = React.forwardRef(({
	                                          disabled,
	                                          resource,
	                                          challengeId,
	                                          handleResourceTrigger,
	                                          classes,
                                          }, ref) => {
	return (
		<Button
			ref={ref}
			customClass={classes.resourceButton}
			color="infoNoBackground"
			size="xs"
			disabled={disabled}
			onClick={event => {
				event.preventDefault();
				handleResourceTrigger(challengeId, resource);
			}}
		>
			{resource.state === ResourceState.STARTED ? (
				<StopIcon />
			) : (
				<PlayArrowIcon />
			)}
		</Button>
	);
});

const OpenLinkButton = React.forwardRef(({
	                                         disabled,
	                                         resource,
	                                         challengeId,
	                                         handleResourceTrigger,
	                                         classes,
                                         }, ref) => {
	return (
		<Button
			ref={ref}
			customClass={classes.resourceButton}
			color="infoNoBackground"
			size="xs"
			disabled={disabled}
			onClick={event => {
				event.preventDefault();
				if (resource.type !== ResourceType.Vm) openInNewTab(resource.hyperlink);
				else handleResourceTrigger(challengeId, resource);
			}}
		>
			<OpenInNewIcon />
		</Button>
	);
});

const ResourceLink = props => {
	const { resource, pending, intl, classes } = props;
	const showOpenLink =
		resource.type === ResourceType.Vm ||
		(resource.type === ResourceType.Docker && resource.hyperlink);
	const showStartStopLink = resource.type === ResourceType.Docker;
	const showDownloadLink = resource.type === ResourceType.File;

	if (pending)
		return (
			<div className={classes.resourceSpinner}>
				<CircularProgress size={18} />
			</div>
		);

	return (
		<React.Fragment>
			{showDownloadLink && (
				<Tooltip
					placement="left"
					title={intl.fm("challenge.resource.download")}
					disableFocusListener
				>
					<DownloadButton {...props} />
				</Tooltip>
			)}{" "}
			{showStartStopLink && (
				<Tooltip
					placement="left"
					title={
						resource.state === ResourceState.STARTED
							? intl.fm("challenge.resource.stop")
							: intl.fm("challenge.resource.start")
					}
					disableFocusListener
				>
					<StartStopButton {...props} />
				</Tooltip>
			)}{" "}
			{showOpenLink && (
				<Tooltip
					placement="left"
					title={intl.fm("challenge.resource.open")}
					disableFocusListener
				>
					<OpenLinkButton {...props} />
				</Tooltip>
			)}
		</React.Fragment>
	);
};

const getSpace = dept => {
	let space = "";
	for (let i = 0; i < dept; i++) space += " ";
	return space;
};

const printObject = (obj, dept = 0) => {
	let result = "";
	for (const key in obj) {
		if (typeof obj[key] === "object") {
			result += getSpace(dept) + key + ":\n";
			result += printObject(obj[key], dept + 2);
		} else {
			result += getSpace(dept) + key + ": " + obj[key] + "\n";
		}
	}
	return result;
};
const getPropertiesData = (properties, intl) => {
	if (
		properties &&
		typeof properties === "object" &&
		properties.constructor === Object &&
		Object.keys(properties).length
	) {
		return Object.keys(properties).map(key => {
			let value;

			switch (typeof properties[key]) {
				case "object":
					value = <pre>{printObject(properties[key])}</pre>;
					break;

				case "function":
					value = properties[key]();
					break;

				default:
					value = properties[key];
			}

			return [
				key,
				value,
			];
		});
	} else return [ [ <NoData text={intl.fm("challenge.nodata.properties")} /> ] ];
};

export const ResourcePropertiesModal = ({
	                                        properties,
	                                        intl,
	                                        isOpen,
	                                        onClose,
                                        }) => (
	<ModalWindow
		open={isOpen}
		onClose={onClose}
		title={"Resource Properties"}
		fullWidth
		closeProtected={false}
	>
		<Table tableData={getPropertiesData(properties, intl)} hover />
	</ModalWindow>
);

const mapStateToProps = state => ({
	helpUrl: state.branding.helpUrl,
	dcServiceDomain: state.branding.dcServiceDomain,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{ updateResourceState: eventsApi.updateResourceState },
		dispatch,
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(resourcesStyle),
	withIntl,
)(Resources);

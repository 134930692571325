import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators, compose } from "redux";

import { Button } from "@hlcr/mui/Button";
import CustomInput from "components/CustomInput/CustomInput";
import ValidationInput from "components/CustomInput/ValidationInput";
import CustomSearchSelect from "components/CustomSelect/CustomSearchSelect";
import { Upload } from "@hlcr/mui/Upload";
import ModalWindow from "components/ModalWindow/ModalWindow";

import managerApi from "../../../actions/manager";
import { formStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamCreateStyles";
import { getImageData } from "helper/manager";
import { withIntl } from "@hlcr/ui/Intl";
import { fetchHelp } from "variables/helpPage";


const initialForm = {
	name: "",
	description: "",
	teamClass: undefined
};

class ManagerCreateTeamModal extends Component {
	state = {
		form: initialForm,
		image: undefined,
		validation: { name: false }
	};

	handleFormReset = () => {
		this.setState({
			form: initialForm,
			image: undefined,
			validation: { name: false }
		});
	};

	createInputChangeHandler = fieldName => event => {
		const value = event.currentTarget.value;
		this.updateField(fieldName, value);
	};

	handleImageChange = file => {
		this.setState({ image: file });
	};

	redirectToEditPage = teamId => {
		const { history } = this.props;
		history.push(`/manager/teams/${teamId}`);
	};

	handleSubmit = () => {
		const { addTeam } = this.props;
		const {
			form: { name, description, teamClass },
			image
		} = this.state;

		const team = {
			id: 0,
			classId: teamClass && Number(teamClass.id),
			name,
			description
		};
		const imageBytes = getImageData(image);
		addTeam(team, imageBytes, this.redirectToEditPage);
	};

	updateFieldWithValidation = fieldName => (value, valid) =>
		this.setState(state => ({
			form: { ...state.form, [fieldName]: value },
			validation: { ...state.validation, [fieldName]: valid }
		}));

	updateField = fieldName => value =>
		this.setState(state => ({ form: { ...state.form, [fieldName]: value } }));

	handleClose = () => {
		const { onClose } = this.props;
		this.handleFormReset();
		onClose();
	};

	render() {
		const { open, managerClasses, intl, classes, helpUrl } = this.props;
		const { form, image, validation } = this.state;
		const isFullValid = Object.values(validation).reduce(
			(res, value) => res && value,
			true
		);
		return (
			<ModalWindow
				open={open}
				onClose={this.handleClose}
				title={intl.fm("manager.teams.create")}
				helpLink={fetchHelp(helpUrl, "manager", "createTeam")}
				actionSection={
					<div className={classes.actions}>
						<Button onClick={this.handleFormReset} color="defaultNoBackground">
							{intl.fm("common.labels.clearForm")}
						</Button>
						<Button
							onClick={this.handleSubmit}
							disabled={!isFullValid}
							color="infoNoBackground"
						>
							{intl.fm("common.labels.submit")}
						</Button>
					</div>
				}
				fullWidth
			>
				<form>
					<ValidationInput
						label={intl.fm("team.field.name")}
						value={form.name}
						onChange={this.updateFieldWithValidation("name")}
						inputClasses={classes.inputOverflow}
						validations={{ required: true, minLength: 1, maxLength: 100 }}
						fullWidth
					/>
					<CustomSearchSelect
						displayField="name"
						placeholder={intl.fm("team.placeholder.selectClass")}
						value={form.teamClass}
						list={managerClasses}
						onSelect={this.updateField("teamClass")}
					/>

					<CustomInput
						labelText={intl.fm("team.field.description")}
						formControlProps={{ fullWidth: true }}
						inputProps={{
							multiline: true,
							rows: 4,
							value: form.description,
							onChange: event =>
								this.updateField("description")(event.target.value)
						}}
					/>
					<Upload type="image" handleProcessedFile={this.handleImageChange} file={image} />
				</form>
			</ModalWindow>
		);
	}
}

const mapStateToProps = state => ({
	managerClasses: state.api.resources.managerClasses.data,
	helpUrl: state.branding.helpUrl
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{ addTeam: managerApi.addTeam },
		dispatch
	);

export default compose(
	withStyles(formStyles),
	withRouter,
	withIntl,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(ManagerCreateTeamModal);

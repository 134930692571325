// ##############################
// // // IconCard styles
// #############################

import { blueCardHeader, card, cardHeader, greenCardHeader, orangeCardHeader, purpleCardHeader, redCardHeader, roseCardHeader } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";

const iconCardStyle = {
	card,
	orangeCardHeader,
	greenCardHeader,
	redCardHeader,
	blueCardHeader,
	purpleCardHeader,
	roseCardHeader,
	cardPlain: {
		background: "transparent",
		boxShadow: "none"
	},
	cardHeader: {
		...cardHeader,
		float: "left"
	},
	cardContent: {
		padding: "15px 20px",
		position: "relative"
	},
	cardAvatar: {
		display: "flex",
		alignItems: "center",
		margin: "0px",
		height: 33
	},
	cardIcon: {
		paddingTop: "3px",
		paddingLeft: "4px",
		paddingRight: "5px",
		color: "#FFFFFF",
		width: "33px",
		height: "27px"
	},
	cardFaIcon: {
		color: "#FFFFFF",
		width: "33px !important",
		height: "24px"
	},
	cardTitle: {
		paddingBottom: "15px",
		marginTop: "0",
		marginBottom: "3px",
		color: "#3C4858",
		textDecoration: "none",
		[DARK_MODE_SELECTOR]: { color: COLOR.LIGHT_TEXT_1 },
		/* we don't want the link styling color (violet) on a tags in title */
		"& a": {
			color: "unset !important",
		},
	},
	cardCategory: {
		color: "#999999",
		fontSize: "14px",
		fontWeight: "400",
		lineHeight: "1",
		marginBottom: "0"
	},
	cardFooter: {
		margin: "0 20px 10px",
		paddingTop: "10px",
		borderTop: "1px solid #eeeeee",
		[DARK_MODE_SELECTOR]: { borderColor: COLOR.DARK_BORDER }
	},
	helpIcon: {
		width: "20px",
		height: "20px",
		color: "#666",
		position: "relative",
		marginLeft: 5,
		top: "3px"
	},
	fluidWidth: { width: "initial" }
};

export default iconCardStyle;

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teacherApi from "actions/teacher";
import { withIntl } from "@hlcr/ui/Intl";
import ProfileNotesEditor from "views/Teams/ProfileNotesEditor";

class ParticipantProfile extends Component {
	componentDidMount() {
		const {
			fetchEventParticipant,
			fetchTeacherEvent,
			eventId,
			userId
		} = this.props;
		fetchEventParticipant(eventId, userId);
		fetchTeacherEvent(eventId);
	}

	onSave = notes => {
		const { updateParticipantNote, eventId, userId } = this.props;
		updateParticipantNote(eventId, userId, notes);
	};

	render() {
		const { intl, member } = this.props;
		return (
			<ProfileNotesEditor
				onSubmit={this.onSave}
				notesField="teacherNotes"
				notesTitle={intl.fm("participant.teacherNotes")}
				member={member}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const eventId = Number(ownProps.match.params.eventId);
	const userId = Number(ownProps.match.params.userId);
	const member = state.api.resources.teacherEventParticipants.data.find(
		p => p.eventId === eventId && p.profile.id === userId
	);
	return { eventId, userId, member };
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchEventParticipant: teacherApi.fetchEventParticipant,
			fetchTeacherEvent: teacherApi.fetchTeacherEvent,
			updateParticipantNote: teacherApi.updateParticipantNote
		},
		dispatch
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withIntl
)(ParticipantProfile);

import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import teamleaderApi from "actions/teamleader";
import { formStyles } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/views/teamCreateStyles";
import { Button } from "@hlcr/mui/Button";
import ValidationInput from "components/CustomInput/ValidationInput";
import ModalWindow from "components/ModalWindow/ModalWindow";
import { withIntl } from "@hlcr/ui/Intl";
import { fetchHelp } from "variables/helpPage";

const EditTeamModal = ({ classes, intl, addTeam, updateTeam, onClose, helpUrl, open, team }) => {
	const [ name, setName ] = useState("");
	const [ description, setDescription ] = useState("");
	const [ editMode, setEditMode ] = useState(false);
	const [ isNameValid, setNameValid ] = useState(false);
	const [ isDescriptionValid, setDescriptionValid ] = useState(true);

	useEffect(() => {
		if (team) {
			setName(team.name);
			setNameValid(true);
			setDescription(team.description);
			setDescriptionValid(true);
			setEditMode(true);
		}
	}, [ team ]);

	const handleFormReset = () => {
		setName("");
		setDescription("");
		setEditMode(false);
		setNameValid(false);
		setDescriptionValid(false);
	};

	const handleClose = () => {
		handleFormReset();
		onClose();
	};

	const handleSubmit = () => {
		if (editMode) {
			updateTeam(Object.assign(team, { name, description }), handleClose);
		} else {
			addTeam({ id: 0, name, description }, handleClose);
		}
	};

	const updateNameWithValidation = (value, valid) => {
		setName(value);
		setNameValid(valid);
	};

	const updateDescriptionWithValidation = (value, valid) => {
		setDescription(value);
		setDescriptionValid(valid);
	};

	return (
		<ModalWindow
			open={open}
			onClose={handleClose}
			title={editMode ? intl.fm("manager.teams.edit") : intl.fm("manager.teams.create")}
			helpLink={fetchHelp(helpUrl, "teams", "createTeam")}
			actionSection={
				<div className={classes.actions}>
					<Button onClick={handleFormReset} color="defaultNoBackground">
						{intl.fm("common.labels.clearForm")}
					</Button>
					<Button onClick={handleSubmit} disabled={!isNameValid || !isDescriptionValid} color="infoNoBackground">
						{intl.fm("common.labels.submit")}
					</Button>
				</div>
			}
			fullWidth
		>
			<form>
				<ValidationInput
					label={intl.fm("team.field.name")}
					value={name}
					onChange={updateNameWithValidation}
					inputClasses={classes.inputOverflow}
					validations={{ required: true, minLength: 1, maxLength: 100 }}
					fullWidth
				/>
				<ValidationInput
					label={intl.fm("team.field.description")}
					value={description}
					onChange={updateDescriptionWithValidation}
					inputClasses={classes.inputOverflow}
					validations={{ maxLength: 500 }}
					inputProps={{
						multiline: true,
						rows: 4,
					}}
					fullWidth
				/>
			</form>
		</ModalWindow>
	);
};

export default compose(
	withStyles(formStyles),
	withIntl,
	connect(
		state => ({ helpUrl: state.branding.helpUrl }),
		dispatch =>
			bindActionCreators(
				{
					addTeam: teamleaderApi.addTeam,
					updateTeam: teamleaderApi.updateTeam
				},
				dispatch
			)
	)
)(EditTeamModal);

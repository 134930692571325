import ReactMarkdown, { uriTransformer } from "react-markdown";
import { ReactBaseProps, ReactMarkdownProps } from "react-markdown/src/ast-to-react";
import SyntaxHighlighter from "react-syntax-highlighter";
// @ts-ignore -- module is missing the appropriate types
import { stackoverflowDark, stackoverflowLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
// @ts-ignore -- no types available for remark-behead
import behead from "remark-behead";
import remarkGfm from "remark-gfm";

import { useHlcrMarkdownStyles } from "./style";

interface StyledMarkdownProps {
	source: string;
	darkMode?: boolean;
	headingOffset?: number;
	linkTarget?: "_blank" | "_self";
}

export const StyledMarkdown = ({ source, darkMode, headingOffset, linkTarget = "_blank", ...restProps }: StyledMarkdownProps) => {
	const classes = useHlcrMarkdownStyles();

	const hlUriTransformer = (uri: string): string => {
		if (!uri) {
			return "";
		}

		const defaultUri = uriTransformer(uri);
		if (!defaultUri?.startsWith("media/") &&
			!defaultUri?.startsWith("/challenges") &&
			!defaultUri?.startsWith("/theories") &&
			!defaultUri?.startsWith("/quizzes")) {
			return defaultUri;
		}

		return "/api/" + defaultUri.replace(/^\//, "");
	};


	const extractYoutubeVideoIdFromUrl = (url: string) => {
		if (url.includes("youtube.com") || url.includes("youtu.be")) {
			const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
			const match = url.match(regExp);
			return (match && match[7].length == 11) ? match[7] : false;
		}
		return false;
	};

	const HlcrImageComponent = ({ node, src, ...restProps }: ReactBaseProps & ReactMarkdownProps & { src?: string }) => {
		const isVideoFile = /\.(mp4|webm|ogv|avi)$/;

		if (!src) {
			return null;
		}

		const youtubeVideoId = extractYoutubeVideoIdFromUrl(src);

		if (youtubeVideoId) {
			// A YouTube link was found, so we embed the video (in privacy enhanced mode)
			const youtubeEmbedSrc = `https://www.youtube-nocookie.com/embed/${youtubeVideoId}`;

			return (
				<iframe style={{ maxWidth: "100%" }} width="560" height="315" src={youtubeEmbedSrc} title="YouTube video player" frameBorder="0"
				        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
			);
		}


		if (!(isVideoFile.test(src))) {
			return <img src={src} {...restProps} style={{ maxWidth: "100%" }} />;
		}

		return (
			<video src={src} controls={true} style={{ maxWidth: "100%" }}>
				<span>
					{"[Your browser doesn't support HTML5 video. Here is a "}
					<a href={src} target="_blank" rel="noopener noreferrer">
					link to the video
					</a>{" "}
					instead.]
				</span>
			</video>
		);
	};

	const HlcrCodeComponent = ({ node, inline, className, children, ...restProps }: ReactBaseProps & ReactMarkdownProps & { inline?: boolean }) => {
		const classes = useHlcrMarkdownStyles();
		const syntaxMatch = /language-(\w+)/.exec(className || "");

		if (inline || !syntaxMatch) {
			return <code className={classes.inlineCode} {...restProps}>{children}</code>;
		}

		return <SyntaxHighlighter
			style={darkMode ? stackoverflowDark : stackoverflowLight}
			language={syntaxMatch[1]}
			PreTag={"div"}
			{...restProps}
		>{String(children).replace(/\n$/, "")}</SyntaxHighlighter>;
	};

	return (
		<ReactMarkdown
			remarkPlugins={[ remarkGfm, [ behead, { depth: headingOffset } ] ]}
			className={classes.root}
			transformImageUri={hlUriTransformer}
			transformLinkUri={hlUriTransformer}
			linkTarget={linkTarget}
			components={{
				img: HlcrImageComponent,
				code: HlcrCodeComponent,
			}}
			{...restProps}
		>
			{source}
		</ReactMarkdown>
	);
};

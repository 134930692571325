export default {
	container: {
		display: "flex",
		alignItems: "center"
	},
	item: {
		display: "flex",
		alignItems: "center"
	},
	separator: {
		color: "#bbb",
		fontWeight: 600
	},
	helpIcon: {
		position: "relative",
		top: "4px",
		margin: "8px",
		color: "#666"
	}
};

import { COLOR, DARK_MODE_SELECTOR } from "helper/darkMode";

const modalStyle = {
	modal: {
		borderRadius: "6px",
		[DARK_MODE_SELECTOR]: { background: COLOR.DARK_2 }
	},
	modalHeader: {
		borderBottom: "none",
		paddingTop: "24px",
		paddingRight: "24px",
		paddingBottom: "0",
		paddingLeft: "24px",
		minHeight: "16.43px"
	},
	modalTitle: {
		margin: "0",
		lineHeight: "1.42857143",
		wordBreak: "break-all",
	},
	modalCloseButton: {
		color: "#999999",
		marginTop: "-12px",
		WebkitAppearance: "none",
		padding: "0",
		cursor: "pointer",
		background: "0 0",
		border: "0",
		fontSize: "inherit",
		opacity: ".9",
		textShadow: "none",
		fontWeight: "700",
		lineHeight: "1",
		float: "right"
	},
	modalClose: {
		width: "16px",
		height: "16px"
	},
	modalBody: {
		paddingTop: "24px",
		paddingRight: "24px",
		paddingBottom: "16px",
		paddingLeft: "24px",
		position: "relative"
	},
	modalFooter: {
		padding: "15px",
		textAlign: "right",
		paddingTop: "0",
		margin: "0"
	},
	modalFooterCenter: {
		marginLeft: "auto",
		marginRight: "auto"
	},
	instructionNoticeModal: { marginBottom: "25px" },
	imageNoticeModal: { maxWidth: "150px" },
	modalSmall: { width: "300px" },
	modalSmallBody: { paddingTop: "0" },
	modalSmallFooterFirstButton: {
		margin: "0",
		paddingLeft: "16px",
		paddingRight: "16px",
		width: "auto"
	},
	modalSmallFooterSecondButton: {
		marginBottom: "0",
		marginLeft: "5px"
	}
};

export default modalStyle;

const eventUnitStyle = {
	infoCardFooter: {
		display: "flex",
		justifyContent: "space-between"
	},
	solutionModalActions: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	},
	imageCard: { padding: "0!important" },
	imageContainer: {
		overflow: "hidden",
		marginBottom: 20
	},
	image: {
		borderRadius: 6,
		overflow: "hidden",
		maxWidth: 400,
		maxHeight: 240
	},
	tooltip: {
		fontSize: "1.2em",
		letterSpacing: ".05em"
	},
	rigthField: { float: "right" },
	title: {
		fontWeight: 500,
		color: "#888",
		fontSize: "1.6em",
		marginTop: 0,
		marginBottom: 5
	},
	cardCollapseButton: {
		flex: 0,
		justifyContent: "flex-end",
		padding: "0 15px",
		margin: 0
	},
	solutionStatus: {
		display: "flex",
		alignItems: "center"
	},
	solutionPoints: { marginLeft: 10 },
	flagInputContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%"
	},
	flagInputCard: { marginBottom: 0 },
	flagInputCardContent: { display: "flex" },
	monoSpaced: { fontFamily: "Menlo, Monaco, Consolas, \"Courier New\", monospace" },
	historyMarkdownLabel: {
		fontSize: ".9em",
		marginLeft: "auto",
		marginTop: -15,
		width: "fit-content"
	}
};

export default eventUnitStyle;

import { StyledMarkdown } from "@hlcr/ui";
import { useIntl } from "@hlcr/ui/Intl";
import React from "react";

import NoData from "components/NoData/NoData";
import HiddenContentSlider from "components/Slider/HiddenContentSlider";
import { UserFlag, UserFlagsBox } from "components/Solution/UserFlagsBox";
import { useDarkMode } from "hooks/darkMode";

export const GradingInstructions = (
	{ userFlags, gradingInstruction }:
	{ userFlags: UserFlag[], gradingInstruction: any }) => {
	const intl = useIntl();
	const darkMode = useDarkMode();
	return (
		<HiddenContentSlider openLabel={intl.fm("solution.gradinginstruction.open")} closeLabel={intl.fm("solution.gradinginstruction.close")}>
			<h4>{intl.fm("solution.gradinginstruction.title")}</h4>
			<UserFlagsBox flags={userFlags} />
			{gradingInstruction ? <StyledMarkdown source={gradingInstruction.content} darkMode={darkMode} /> : <NoData />}
		</HiddenContentSlider>
	);
};

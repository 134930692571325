import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";

import { fetchTeacherEvent, fetchTeacherEventUnits } from "actions/teacher";
import { filterEventUnits } from "models/EventUnit";
import { RootState } from "reducers";
import { TeacherEventDetailsRouteParams } from "routes/teacherRoutes";
import { getEvent } from "shared/event/model/BaseEvent";
import { Event } from "shared/event/model/Event";
import { TeacherEventUnit } from "shared/event/model/TeacherEventUnit";
import EventUnits from "views/Events/EventUnits";

export const TeacherEventDetails = ({ match: { params: { eventId: eventIdParam, parentId: parentIdParam } } }: RouteComponentProps<TeacherEventDetailsRouteParams>) => {
	const eventId = parseInt(eventIdParam);
	const parentId = parentIdParam?.trim().length && parseInt(parentIdParam);

	const dispatch = useDispatch();
	const history = useHistory();

	const isLoading = useSelector((state: RootState) => state.api.resources.teacherEvents.pending || state.api.resources.teacherEventUnits.pending);

	const units :TeacherEventUnit[] = useSelector((state: RootState) => !!eventId && filterEventUnits(state.api.resources.teacherEventUnits.data, eventId));
	const event: Event = useSelector((state: RootState) => !!eventId && getEvent(state.api.resources.teacherEvents.data, eventId));

	useEffect(() => {
		dispatch(fetchTeacherEvent(eventId));
		dispatch(fetchTeacherEventUnits(eventId));
	}, [ eventId ]);

	useEffect(() => {
		if (parentId) {
			fetchTeacherEvent(parentId);
		}
	}, [ parentId ]);

	return (
		<EventUnits
			isPreview
			eventId={eventId}
			parentId={parentId}
			event={event}
			units={units}
			isLoading={isLoading}
			history={history}
		/>
	);
};

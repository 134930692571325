import { HttpMethod } from "@hlcr/core/enum/HttpMethod";

import { RemoteResource } from "models/RemoteResource";
import { RemoteResourceActionType, DynamicRequestAction } from "redux/actions";


const BASE_URL = "/api/tenant/accessPolicy";

export const fetchTenantAccessPolicy: DynamicRequestAction = () => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	method: HttpMethod.GET,
	url: `${BASE_URL}/`,
	resource: RemoteResource.TENANT_ACCESS_POLICY,
});



import { ProcessedFile } from "@hlcr/mui/Upload";

import { QuizSummaryDto } from "models/Quizzes";

import { QuizSolutionState, SolutionState } from "./SolutionState";
import { User, UserSummary } from "./User";

export interface SolutionCommentBase {
	comment: string;
	attachment: ProcessedFile;
	grade?: number;
}

export interface SolutionComment extends SolutionCommentBase {
	flag?: string;
	writeup?: string;
	flagGrade?: number;
	writeupGrade?: number;
	creationTime: string;
	user: User;
	solutionStateSnapshot?: SolutionState;
}

export const isCommentFromTeacher = (solutionComment: SolutionComment) => {
	return solutionComment.comment?.length || solutionComment.flagGrade !== undefined || solutionComment.writeupGrade !== undefined;
};

export interface TeacherSolutionCommentSubmission {
	grade?: number;
	comment?: string;
}

export interface SolutionCommentSubmission {
	flag?: string;
	writeup?: string;
	attachment?: any;
}

export interface Solution {
	id?: number;
	points: number;
	improvable?: boolean;
	state?: SolutionState;
}

export interface QuizSolutionDto {
	id: number;
	state?: QuizSolutionState;
	points: number;
	finished: boolean;
	user: UserSummary;
	quiz: QuizSummaryDto;
	eventId: number;
}

import { ActionType } from "actions/ActionType";

export default middleware => next => async action => {
	const nextAction = next(action);

	if (action.type === ActionType.TOGGLE_DARK_MODE) {
		localStorage.setItem("darkMode", middleware.getState().ui.darkMode);
	}

	return nextAction;
};

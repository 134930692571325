import { TableContainer, TextField, Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

import { CriteriaGradingCatalogItemDto } from "components/Solution/CriteriaGradingCatalogDto";

export const CriteriaGradingComponent = ({
	criteriaList,
	setCriteriaList,
}: {
	criteriaList: CriteriaGradingCatalogItemDto[];
	setCriteriaList: (criteria: CriteriaGradingCatalogItemDto[]) => void;
}) => {
	const updateCriteriaByIndex = (index: number, criteria: CriteriaGradingCatalogItemDto) => {
		const newCriteriaList = [ ...criteriaList.slice(0, index), criteria, ...criteriaList.slice(index + 1) ];
		setCriteriaList(newCriteriaList);
	};

	const criteriaTableRows = criteriaList?.map((c, index) => {
		return <Row index={index} criteriaGradingCatalogItem={c} onChangeCriteria={(index: number, criteria: CriteriaGradingCatalogItemDto) => updateCriteriaByIndex(index, criteria)} key={index} />;
	});

	return (
		<>
			<TableContainer>
				<Table>
					<Header />
					<TableBody>{criteriaTableRows}</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

const Header = () => (
	<TableHead>
		<TableRow>
			<TableCell>Criteria</TableCell>
			<TableCell>Points</TableCell>
			<TableCell>True</TableCell>
			<TableCell>False</TableCell>
			<TableCell>Feedback</TableCell>
		</TableRow>
	</TableHead>
);

const Row = ({
	index,
	criteriaGradingCatalogItem,
	onChangeCriteria,
}: {
	index: number;
	criteriaGradingCatalogItem: CriteriaGradingCatalogItemDto;
	onChangeCriteria: (index: number, criteria: CriteriaGradingCatalogItemDto) => void;
}) => {
	const changeFulfilledOnCriteria = (fulfilled: boolean) =>
		onChangeCriteria(index, {
			id: criteriaGradingCatalogItem.id,
			gradingCatalogItemDescriptorsId: criteriaGradingCatalogItem.gradingCatalogItemDescriptorsId,
			name: criteriaGradingCatalogItem.name,
			description: criteriaGradingCatalogItem.description,
			comment: criteriaGradingCatalogItem.comment,
			acronym: criteriaGradingCatalogItem.acronym,
			points: criteriaGradingCatalogItem.points,
			fulfilled: fulfilled,
		});
	const onChangeFulfilled = (event: React.ChangeEvent<HTMLInputElement>) => changeFulfilledOnCriteria(event.target.checked);
	const onChangeNotFulfilled = (event: React.ChangeEvent<HTMLInputElement>) => changeFulfilledOnCriteria(!event.target.checked);
	const onChangeComment = (event: React.ChangeEvent<HTMLInputElement>) =>
		onChangeCriteria(index, {
			id: criteriaGradingCatalogItem.id,
			gradingCatalogItemDescriptorsId: criteriaGradingCatalogItem.gradingCatalogItemDescriptorsId,
			name: criteriaGradingCatalogItem.name,
			description: criteriaGradingCatalogItem.description,
			comment: event.target.value,
			acronym: criteriaGradingCatalogItem.acronym,
			points: criteriaGradingCatalogItem.points,
			fulfilled: criteriaGradingCatalogItem.fulfilled,
		});

	return <RowComponents
		criteriaGradingCatalogItem={criteriaGradingCatalogItem}
		onChangeComment={onChangeComment}
		onChangeFulfilled={onChangeFulfilled}
		onChangeNotFulfilled={onChangeNotFulfilled}
	/>;
};

const RowComponents = ({
	criteriaGradingCatalogItem,
	onChangeFulfilled,
	onChangeNotFulfilled,
	onChangeComment,
}: {
	criteriaGradingCatalogItem: CriteriaGradingCatalogItemDto;
	onChangeFulfilled: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeNotFulfilled: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeComment: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
	return (
		<TableRow>
			<TableCell>
				<Typography variant="subtitle1" gutterBottom>
					{criteriaGradingCatalogItem.name} ({criteriaGradingCatalogItem.acronym})
				</Typography>
				<Typography variant="body1">{criteriaGradingCatalogItem.description}</Typography>
			</TableCell>
			<TableCell>
				<Typography variant="body1" style={{ textAlign: "center" }}>{criteriaGradingCatalogItem.points}</Typography>
			</TableCell>
			<TableCell>
				<Radio checked={criteriaGradingCatalogItem.fulfilled != undefined && criteriaGradingCatalogItem.fulfilled} onChange={onChangeFulfilled} />
			</TableCell>
			<TableCell>
				<Radio checked={criteriaGradingCatalogItem.fulfilled != undefined && !criteriaGradingCatalogItem.fulfilled} onChange={onChangeNotFulfilled} />
			</TableCell>
			<TableCell>
				<TextField label="Comment" variant="standard" multiline style={{ width: "30vw" }} value={criteriaGradingCatalogItem.comment} onChange={onChangeComment} />
			</TableCell>
		</TableRow>
	);
};

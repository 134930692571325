import { EnumBase, EnumDetail, EnumWithColor, EnumWithIcon } from "@hlcr/app/enum/EnumBase";
import BuildIcon from "@material-ui/icons/Build";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import SchoolIcon from "@material-ui/icons/School";


export interface GradingInstruction {
	content: string;
}

export interface Asset {
	uuid: string;
	type: AssetType;
	title: string;
	categories: AssetCategory[];
	sections?: Section[];
	// TODO: complete
}

export interface AssetCategory {
	id?: number;
	name: string;
}

export interface Section {
	id: string;
	content?: string;
	steps?: Step[];
}

export interface Step {
	content?: string;
}

// AssetType
export enum AssetType {
	CHALLENGE = "CHALLENGE",
	THEORY = "THEORY",
	QUIZ = "QUIZ",
}

export const ASSET_TYPES: EnumDetail<AssetType, EnumWithIcon> = {
	CHALLENGE: {
		title: "asset.type.challenge",
		icon: BuildIcon,
	},
	THEORY: {
		title: "asset.type.theory",
		icon: SchoolIcon,
	},
	QUIZ: {
		title: "asset.type.quiz",
		icon: FormatListBulletedIcon,
	},
};


// FlagType
export enum FlagType {
	STATIC = "STATIC",
	DYNAMIC = "DYNAMIC",
	NONE = "NONE",
}

interface FlagTypeEnum extends EnumBase {
	isFlagBased: boolean;
}

export const FLAG_TYPES: EnumDetail<FlagType, FlagTypeEnum> = {
	STATIC: {
		isFlagBased: true,
		title: "flag.type.static",
	},
	DYNAMIC: {
		isFlagBased: true,
		title: "flag.type.dynamic",
	},
	NONE: {
		isFlagBased: false,
		title: "flag.type.none",
	},
};


// AssetDifficultyLevel
export enum AssetDifficultyLevel {
	NOVICE = "NOVICE",
	EASY = "EASY",
	MEDIUM = "MEDIUM",
	HARD = "HARD",
	LEET = "LEET",
}

// TODO: refactor asset levels to be an enum in the backend, then remove this pseudo type because "id" is not used
export interface AssetDifficultyLevelThatShouldBeAnEnum {
	id: number;
	name: `${Lowercase<keyof typeof AssetDifficultyLevel>}`;
}

interface AssetDifficultyLevelEnum extends EnumWithColor {
	initialPoints: number;
}

export const ASSET_DIFFICULTY_LEVELS: EnumDetail<AssetDifficultyLevel, AssetDifficultyLevelEnum> = {
	[AssetDifficultyLevel.NOVICE]: {
		title: "asset.difficulty.novice",
		initialPoints: 50,
		color: "var(--hl-level-color-novice)",
	},
	[AssetDifficultyLevel.EASY]: {
		title: "asset.difficulty.easy",
		initialPoints: 100,
		color: "var(--hl-level-color-easy)",
	},
	[AssetDifficultyLevel.MEDIUM]: {
		title: "asset.difficulty.medium",
		initialPoints: 200,
		color: "var(--hl-level-color-medium)",
	},
	[AssetDifficultyLevel.HARD]: {
		title: "asset.difficulty.hard",
		initialPoints: 300,
		color: "var(--hl-level-color-hard)",
	},
	[AssetDifficultyLevel.LEET]: {
		title: "asset.difficulty.leet",
		initialPoints: 400,
		color: "var(--hl-level-color-leet)",
	},
};

import { Optional } from "@hlcr/core/types";
import * as React from "react";
import { FormattedMessage, injectIntl, IntlShape, useIntl as useReactIntl } from "react-intl";

import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/de";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/de";


export type FmSignature<T> = (id: string, defaultMessage?: string, values?: Record<string, React.ReactNode>) => T;
export interface IntlWithFm extends IntlShape {
	fm: FmSignature<any>;
}
export interface WithIntl {
	intl: IntlWithFm;
}


export const formatMessage: FmSignature<React.ReactElement> = (id, defaultMessage, values) => {
	return (
		<FormattedMessage {...enhanceProps(id, defaultMessage, values)} />
	);
};

export function withIntl<T extends WithIntl>(WrappedComponent: React.ComponentType<T>) {
	return injectIntl<"intl", T>((props) => {
		const myIntl = enhance({ ...props.intl });
		return <WrappedComponent {...props} intl={myIntl} />;
	});
}

const enhanceProps: FmSignature<{[P in keyof Parameters<FmSignature<any>> extends string ? P : never]: Parameters<FmSignature<any>>[P]}> = (id, defaultMessage, values) => ({
	id,
	defaultMessage, // : defaultMessage ?? enMessages[id],
	values,
});

export function useIntl() {
	return enhance(useReactIntl());
}

function enhance(reactIntl: Optional<IntlWithFm, "fm">): IntlWithFm {
	reactIntl.fm = (id, defaultMessage, values) =>
		reactIntl.formatMessage(
			{ id, defaultMessage /* : defaultMessage ?? enMessages[id] */ },
			values,
		);
	return reactIntl as IntlWithFm;
}

import { grayColor, infoColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";

import timelineStyle from "./timelineStyle";

const solutionTimelineStyle = theme => {
	const baseStyle = timelineStyle(theme);
	return {
		...baseStyle,
		timelineBadge: {
			...baseStyle.timelineBadge,
			color: "#eee"
		},
		timelineBadgeAction: {
			cursor: "pointer",
			marginLeft: -27
		},
		timelineAttachment: {
			display: "inline-flex",
			alignItems: "center",
			marginTop: 6,
			color: grayColor,
			fontWeight: 400,
			"&:hover": {
				color: infoColor,
				textDecoration: "underline"
			}
		},
		timelineAttachmentIcon: {
			color: infoColor,
			marginRight: 5
		},
		titleMeta: {
			fontSize: ".8em",
			marginLeft: 6,
			color: "#999"
		},
		timelineSimpleBadge: { left: "90%" },

		gray: {
			backgroundColor: grayColor,
			boxShadow:
				"0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 156, 156, 0.4)"
		},

		actionButtonSpacing: { height: 60 },
		timelinePanel: {
			...baseStyle.timelinePanel,
			background: "#eee"
		},
		timelinePanelArrows: {
			"&:before": {
				...baseStyle.timelinePanelArrows["&:before"],
				borderLeft: "15px solid #eee",
				borderRight: "0 solid #eee"
			},
			"&:after": {
				...baseStyle.timelinePanelArrows["&:after"],
				borderLeft: "14px solid #eee",
				borderRight: "0 solid #eee"
			}
		},
		timelinePanelInverted: {
			[theme.breakpoints.up("sm")]: {
				...baseStyle.timelinePanelInverted[theme.breakpoints.up("sm")],
				backgroundColor: "#eee"
			}
		},
		timelineFooterMeta: {
			fontSize: ".8em",
			marginLeft: 6,
			color: "#999"
		}
	};
};

export default solutionTimelineStyle;

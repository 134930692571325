import { SelectProps } from "@material-ui/core/Select/Select";

import { CustomSelect, CustomSelectProps } from "components/CustomSelect/CustomSelect";
import { isNumber } from "helper/numbers";


export interface ObjectSelectProps<T> extends Omit<CustomSelectProps, "value" | "onChange" | "menuItems">{
	selectedObject?: T;
	objects: T[];
	onObjectSelect: (objectId: string | number) => void;
	idField: keyof T;
	displayField: keyof T;
	required?: boolean;
}

export const ObjectSelect = <T extends {}>({ selectedObject, objects, onObjectSelect, idField, displayField, ...customSelectProps }: ObjectSelectProps<T>) => {

	const objectIds = objects?.map(object => ({ id: `${object[idField]}` }));

	const handleOnChange: SelectProps["onChange"] = (changeEvent) => {
		if (changeEvent.target.value) {
			if (isNumber(changeEvent.target.value)) {
				onObjectSelect(parseInt(`${changeEvent.target.value}`));
			} else {
				onObjectSelect(`${changeEvent.target.value}`);
			}
		}
	};

	const renderObjectValue = (value: unknown) => {
		const object = objects?.find(object => `${object[idField]}` === `${value}`);

		if (!object) {
			return `Invalid Selection "${value}"`;
		}

		return object[displayField];
	};

	const selectedValue = selectedObject?.[idField] ?? "";

	return <CustomSelect
		{...customSelectProps}
		menuItems={objectIds}
		value={`${selectedValue}`}
		renderValue={renderObjectValue}
		onChange={handleOnChange}
	/>;
};

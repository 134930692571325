import { useSelector } from "react-redux";

import managerApi from "actions/manager";
import { createQueryFilter } from "components/EventFilterBar/EventFilterBar";
import { EventType } from "models/EventType";
import { RootState } from "reducers";
import { Event } from "shared/event/model/Event";
import { UserEventState } from "variables/constants";
import { EventManagerSummary } from "views/Manager/EventManagerSummary";

export const EventSummary = () => {
	const loadingSelector = (state: RootState) => state.api.resources.managerEvents.pending;
	const events: Event[] = useSelector((state: RootState) => state.api.resources.managerEvents.data);
	const topLevelEvents = events.filter(e => e.type !== EventType.CURRICULUM_EVENT);

	// @formatter:off
	const marks = [
		{ value: 0, label: "Archived", quantity: topLevelEvents.filter(createQueryFilter("", UserEventState.ARCHIVED)).length },
		{ value: 1, label: "Past",     quantity: topLevelEvents.filter(createQueryFilter("", UserEventState.PAST)).length },
		{ value: 2, label: "Current",  quantity: topLevelEvents.filter(createQueryFilter("", UserEventState.RUNNING)).length },
		{ value: 3, label: "Upcoming", quantity: topLevelEvents.filter(createQueryFilter("", UserEventState.UPCOMING)).length },
	];
	// @formatter:on

	return <EventManagerSummary
		marks={marks}
		stateInitializers={[ managerApi.fetchManagerEvents ]}
		loadingSelector={loadingSelector}
	/>;
};

// material-ui components
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import regularCardStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/regularCardStyle";

function RegularCard({
	classes,
	plainCard,
	cardTitle,
	cardSubtitle,
	content,
	titleAlign,
	customCardClasses,
	customCardContentClasses,
	contentAlign,
	subtitleAlign,
	customCardTitleClasses,
	footer
}) {
	const cardClasses = cx(classes.card, {
		[classes.cardPlain]: plainCard,
		[customCardClasses]: customCardClasses !== undefined
	});
	const titleClasses = cx(
		classes.cardTitle,
		classes[titleAlign],
		customCardTitleClasses
	);
	const subHeaderClasses = cx(classes.cardSubtitle, classes[subtitleAlign]);
	return (
		<Card className={cardClasses}>
			{cardTitle !== undefined || cardSubtitle !== undefined ? (
				<CardHeader
					classes={{
						root: classes.cardHeader,
						title: titleClasses,
						subheader: subHeaderClasses
					}}
					title={cardTitle}
					subheader={cardSubtitle}
				/>
			) : null}
			<CardContent
				className={cx(
					classes.cardContent,
					customCardContentClasses,
					classes[contentAlign]
				)}
			>
				{content}
			</CardContent>
			{footer && (
				<CardActions className={classes.cardActions}>
					<div className={classes.cardStats}>{footer}</div>
				</CardActions>
			)}
		</Card>
	);
}

RegularCard.propTypes = {
	classes: PropTypes.object.isRequired,
	customCardClasses: PropTypes.string,
	customCardContentClasses: PropTypes.string,
	customCardTitleClasses: PropTypes.string,
	plainCard: PropTypes.bool,
	cardTitle: PropTypes.node,
	cardSubtitle: PropTypes.node,
	footer: PropTypes.node,
	content: PropTypes.node,
	titleAlign: PropTypes.oneOf([
		"right",
		"left",
		"center" 
	]),
	contentAlign: PropTypes.oneOf([
		"right",
		"left",
		"center" 
	]),
	subtitleAlign: PropTypes.oneOf([
		"right",
		"left",
		"center" 
	])
};

export default withStyles(regularCardStyle)(RegularCard);

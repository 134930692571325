import {
	COLOR_DANGER,
	COLOR_GRAY,
	COLOR_INFO,
	COLOR_PRIMARY,
	COLOR_ROSE,
	COLOR_SUCCESS,
	COLOR_WARNING,
	CSS_HOVER_FOCUS_PSEUDO_CLASSES,
	CSS_SELF_HOVER_FOCUS_PSEUDO_CLASSES,
} from "@hlcr/mui/theme/hacking-lab.theme";
import { makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";


const hoverFocusNoBackground = (color: string) => ({
	[CSS_SELF_HOVER_FOCUS_PSEUDO_CLASSES]: {
		color,
		boxShadow: "none",
		backgroundColor: "transparent",
	},
});

const normalButton = (hexColor: string) => {
	return {
		backgroundColor: hexColor,
		color: "#fff",
		boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.25)",
		[CSS_HOVER_FOCUS_PSEUDO_CLASSES]: {
			backgroundColor: hexColor,
			color: "#fff",
			boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.3)",
		},
	};
};

const buttonColor = {
	primary: { ...normalButton(COLOR_PRIMARY) },
	info: { ...normalButton(COLOR_INFO) },
	success: { ...normalButton(COLOR_SUCCESS) },
	warning: { ...normalButton(COLOR_WARNING) },
	danger: { ...normalButton(COLOR_DANGER) },
	rose: { ...normalButton(COLOR_ROSE) },
	defaultNoBackground: { ...hoverFocusNoBackground(COLOR_GRAY) },
	primaryNoBackground: { ...hoverFocusNoBackground(COLOR_PRIMARY) },
	infoNoBackground: { ...hoverFocusNoBackground(COLOR_INFO) },
	successNoBackground: { ...hoverFocusNoBackground(COLOR_SUCCESS) },
	warningNoBackground: { ...hoverFocusNoBackground(COLOR_WARNING) },
	dangerNoBackground: { ...hoverFocusNoBackground(COLOR_DANGER) },
	roseNoBackground: { ...hoverFocusNoBackground(COLOR_ROSE) },
	white: {
		[CSS_SELF_HOVER_FOCUS_PSEUDO_CLASSES]: {
			backgroundColor: "#FFFFFF",
			color: COLOR_GRAY,
		},
	},
	simple: {
		[CSS_SELF_HOVER_FOCUS_PSEUDO_CLASSES]: {
			backgroundColor: "transparent",
			color: COLOR_GRAY,
			boxShadow: "none",
		},
	},
	transparent: {
		[CSS_SELF_HOVER_FOCUS_PSEUDO_CLASSES]: {
			color: "inherit",
			background: "transparent",
			boxShadow: "none",
		},
	},
	twitter: { ...normalButton("#55acee") },
	twitterNoBackground: { ...hoverFocusNoBackground("#55acee") },
	facebook: { ...normalButton("#3b5998") },
	facebookNoBackground: { ...hoverFocusNoBackground("#3b5998") },
	google: { ...normalButton("#dd4b39") },
	googleNoBackground: { ...hoverFocusNoBackground("#dd4b39") },
	linkedin: { ...normalButton("#0976b4") },
	linkedinNoBackground: { ...hoverFocusNoBackground("#0976b4") },
	pinterest: { ...normalButton("#cc2127") },
	pinterestNoBackground: { ...hoverFocusNoBackground("#cc2127") },
	youtube: { ...normalButton("#e52d27") },
	youtubeNoBackground: { ...hoverFocusNoBackground("#e52d27") },
	tumblr: { ...normalButton("#35465c") },
	tumblrNoBackground: { ...hoverFocusNoBackground("#35465c") },
	github: { ...normalButton("#333333") },
	githubNoBackground: { ...hoverFocusNoBackground("#333333") },
	behance: { ...normalButton("#1769ff") },
	behanceNoBackground: { ...hoverFocusNoBackground("#1769ff") },
	dribbble: { ...normalButton("#ea4c89") },
	dribbbleNoBackground: { ...hoverFocusNoBackground("#ea4c89") },
	reddit: { ...normalButton("#ff4500") },
	redditNoBackground: { ...hoverFocusNoBackground("#ff4500") },
};
export type ButtonColor = keyof typeof buttonColor | "gray";

const buttonSize = {
	lg: {
		fontSize: "14px",
		padding: "18px 36px",
	},
	sm: {
		padding: "5px 20px",
		fontSize: "11px",
	},
	xs: {
		padding: "4px 15px",
		fontSize: "10px",
	},
};
export type ButtonSize = keyof typeof buttonSize;

export const buttonStyle = createStyles({
	button: {
		...normalButton(COLOR_GRAY),
		minWidth: "auto",
		minHeight: "auto",
		border: "none",
		borderRadius: "3px",
		position: "relative",
		padding: "12px 30px",
		margin: "10px 1px",
		fontSize: "12px",
		fontWeight: 400,
		textTransform: "uppercase",
		letterSpacing: "0",
		willChange: "box-shadow, transform",
		transition: "box-shadow 0.15s ease-out, background-color 0.15s ease-out",
		lineHeight: "1.3",
		textAlign: "center",
		whiteSpace: "nowrap",
		verticalAlign: "middle",
		touchAction: "manipulation",
		cursor: "pointer",
	},
	fullWidth: { width: "100%" },
	round: { borderRadius: "30px" },
	disabled: {
		opacity: "0.65",
		pointerEvents: "none",
	},
	right: { float: "right" },
	justIcon: {
		minWidth: "10px",
		paddingLeft: "12px",
		paddingRight: "12px",
		fontSize: "20px",
	},
	wd: { minWidth: "160px" },
	...buttonColor,
	...buttonSize,
});

export const useButtonStyles = makeStyles(buttonStyle);

import { percentage } from "@hlcr/core/numeric";


import { Accordion } from "@hlcr/mui";
import { Button } from "@hlcr/mui/Button";
import { StyledMarkdown } from "@hlcr/ui";
import { withIntl } from "@hlcr/ui/Intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import WarningDialog from "components/ModalWindow/WarningDialog";
import NoData from "components/NoData/NoData";
import { EventUnitMode } from "models/EventUnit";
import { arrayOf, bool, func, number, object } from "prop-types";
import * as React from "react";
import { useSelector } from "react-redux";
import { compose } from "redux";

const DescriptionContent = ({
	                            isChallenge = false,
	                            entity,
	                            steps = [],
	                            fetchStep,
	                            fetchStepTitles,
	                            stepsLoading,
	                            intl,
	                            classes,
	                            penaltyPerSection = 0,
	                            penaltyPerSectionSteps = 0,
                            }) => {
	const [ sectionToLoad, setSectionToLoad ] = React.useState(undefined);

	const onConfirmTitlesForSectionLoad = section => () => {
		fetchStepTitles(entity.id, section.id, () => setSectionToLoad(undefined))
	}

	return entity?.sections?.length > 0 ? (<>
		<WarningDialog
			entity={sectionToLoad}
			onConfirm={onConfirmTitlesForSectionLoad(sectionToLoad)}
			onClose={() => setSectionToLoad(undefined)}
			title={intl.fm("challenge.steps_mode.acceptPenalty")}
			message={intl.fm("challenge.steps_mode.sectionPenaltyMessage", null, { penalty: `${percentage(penaltyPerSection + Number.EPSILON)}%` })}
		/>
		{entity.sections.map(section => {
			const sectionSteps = steps.filter(s => s.sectionId === section.id);
			return (
				<div key={section.id}>
					<Section
						entity={entity}
						section={section}
						steps={sectionSteps}
						fetchStep={fetchStep}
						penaltyPerSectionSteps={penaltyPerSectionSteps}
						intl={intl}
					/>
					{isChallenge && sectionSteps?.length < 1 && !stepsLoading && entity.mode === EventUnitMode.STEPS_MODE && section.hasSteps && (
						<Button variant="contained" onClick={() => setSectionToLoad(section)} color="info">{intl.fm("challenge.steps_mode.loadSteps")}</Button>
					)}
					{stepsLoading && steps.length === 0 && (
						<>
							<span className={classes.loadingSteps}>{intl.fm("challenge.steps_mode.loadingSteps")}</span>
							<CircularProgress size={18} />
						</>
					)}
				</div>
			);
		})}
	</>) : (<NoData text={intl.fm("challenge.nodata.description")} />);
};

const Section = ({ entity, section, steps = [], fetchStep, penaltyPerSectionSteps = 0, intl }) => {
	const darkMode = useSelector((state) => state.ui.darkMode);

	const [ stepToLoad, setStepToLoad ] = React.useState(undefined);

	const penaltyPerStep = steps?.length > 0 && penaltyPerSectionSteps > 0 && penaltyPerSectionSteps / steps.length;

	const onConfirmStepLoad = step => () => {
		fetchStep(entity.id, step.sectionId, step.id, () => setStepToLoad(undefined))
	}

	return (<>
		<WarningDialog
			entity={stepToLoad}
			onConfirm={onConfirmStepLoad(stepToLoad)}
			onClose={() => setStepToLoad(undefined)}
			title={intl.fm("challenge.steps_mode.acceptPenalty")}
			message={intl.fm("challenge.steps_mode.stepPenaltyMessage", null, { penalty: `${percentage(penaltyPerStep)}%` })}
		/>
		<StyledMarkdown source={section.content} key={section.id} darkMode={darkMode} />
		{steps.length > 0 ? steps.map((step, i) => (
			<Accordion
				sections={[
					{
						title: <span>{intl.fm("challenge.description.step")} {i + 1}{step.title && `: ${step.title}`}</span>,
						content: <StyledMarkdown source={step.content} darkMode={darkMode} />,
						onClick: step.content ? undefined : () => setStepToLoad(step),
					},
				]}
				overrideActiveSectionIndex={entity.mode === EventUnitMode.STEPS_MODE && !step.content ? -1 : undefined}
				key={i}
			/>
		)) : null}
	</>);
};

DescriptionContent.propTypes = {
	isChallenge: bool,
	entity: object,
	steps: arrayOf(object),
	stepsLoading: bool,
	fetchStep: func,
	fetchStepTitles: func,
	fetchSteps: func,
	intl: object.isRequired,
	classes: object.isRequired,
	penaltyPerSection: number,
	penaltyPerSectionSteps: number,
};

const style = {
	loadingSteps: {
		fontSize: 18,
		fontWeight: 500,
		color: "#999",
		marginRight: 10,
	},
	stepSpinner: { marginLeft: 10 },
};
export default compose(withIntl, withStyles(style))(DescriptionContent);

import { StyledMarkdown } from "@hlcr/ui";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";

import { useDarkMode } from "hooks/darkMode";


export const TimeLineComment = ( { comment, disableMarkdown }: { comment: string, disableMarkdown: boolean } ) => {
	const darkMode = useDarkMode();
	const classes = useStyles();
	const [ expanded, setExpanded ] = useState<boolean>(false);
	const elementList: JSX.Element[] = [];
	const COMMENT_LENGTH = 300;
	const expansionText = expanded ? "show less" : "show more";
	const shouldTrimComment = comment.length > COMMENT_LENGTH && !expanded;
	const adjustedComment = shouldTrimComment ? comment.substring(0, COMMENT_LENGTH).trimEnd()  + "..." : comment;

	disableMarkdown
		? elementList.push(<pre>{adjustedComment}</pre>)
		: elementList.push(<StyledMarkdown source={adjustedComment} darkMode={darkMode} />);

	if (comment.length > COMMENT_LENGTH) {
		disableMarkdown
			? elementList.push(<div onClick={() => setExpanded(!expanded)} className={classes.commentExpansion}>
				<pre>{expansionText}</pre>
			</div>)
			: elementList.push(<div onClick={() => setExpanded(!expanded)} className={classes.commentExpansion}><StyledMarkdown source={expansionText} darkMode={darkMode} />
			</div>);
	}
	return <>{elementList}</>;
};

const useStyles = makeStyles(
	(theme: Theme) => createStyles(
		{
			commentExpansion: {
				"&:hover": { textDecoration: "underline" },
				color: theme.palette.secondary.main,
			},

		},
	),
);

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

import { Button } from "@hlcr/mui/Button";
import Tooltip from "components/CustomTooltip/CustomTooltip";

const styles = {
	button: {
		margin: "0px 2px 0px 2px",
		width: 27,
		height: 27,
		padding: 5
	},
	icon: {}
};

const TableIconButton = ({
	title,
	faIcon,
	disabled,
	onClick,
	color,
	children,
	classes
}) => (
	<Tooltip title={title} placement="left">
		<Button
			onClick={onClick}
			color={color}
			disabled={disabled}
			customClass={classes.button}
		>
			{faIcon && <FontAwesomeIcon className={classes.icon} icon={faIcon} />}
			{children}
		</Button>
	</Tooltip>
);
export default withStyles(styles)(TableIconButton);

const helpLinks = {
	student: {
		submitChallenge: "student/#solution-submission",
		challengeResources: "student/#challenge-resources",
		challengeProperties: "student/#challenge-properties",

		curriculumProgress: "student/#curriculum-progress",

		pageDashboard: "student/#dashboard",
		pageEvents: "student/#event-overview",
		pageChallenge: "student/#challenge-view",
		pageTheory: "student/#theory-view",
		pageQuiz: "student/#quiz-view",
		pageRanking: "student/#rankings",
		pageEventDetails: "student/#event-view",
		pageProfile: "student/#profile-view",
		pageOwnReport: "student/#personal-report",

		joinEvent: "student/#join-a-private-event"
	},

	teacher: {
		correct: "teacher/#solution-list",

		pageEvents: "teacher/#event-overview",
		pageEventDetails: "teacher/#event-details",
		pageChallengeSolution: "teacher/#solution-view",
		pageQuizSolution: "teacher/#quiz-solution-view"
	},

	teams: {
		overview: "teamleader/",
		createTeam: "teamleader/#create-team",
		joinTeam: "teamleader/#join-team",
		memberToken: "teamleader/#token-generation",
		userProfile: "teamleader/#user-profile",
		userSolutions: "teamleader/#user-solutions"
	},

	manager: {
		createTeam: "manager/#team-creation",
		createClass: "manager/#class-creation",
		createEvent: "manager/#event-creation",
		manageEventTeams: "manager/#team-and-user-assignment",
		manageEventUnits: "manager/#event-units",
		manageEventUsers: "manager/#team-and-user-assignment",
		manageAccessTokens: "manager/#access-tokens",

		pageEventManagement: "manager/#event-management",
		pageEventDetails: "manager/#event-details",
		pageTeamManagement: "manager/#teams-management",
		pageTeamDetails: "manager/#team-details",
		pageClassManagement: "manager/#classes-management",
		pageClassDetails: "manager/#class-details",

		manageCurriculumEvents: "manager/#curriculum-events",

		addUnits: "manager/#add-units"
	},

	auditor: {
		pageClassesReport: "auditor/#classes-report",
		pageClassReport: "auditor/#class-report",
		pageTeamReport: "auditor/#team-report",
		pageUserReport: "auditor/#user-report"
	}
};

export const fetchHelp = (helpUrl, role, key) => {
	if (!role || !key) return helpUrl;

	const helpRole = helpLinks[role];
	if (!helpRole) return helpUrl;

	const subPath = helpRole[key];
	return subPath ? helpUrl + subPath : helpUrl;
};

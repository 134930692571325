import { IntlWithFm } from "@hlcr/ui/Intl";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

import { AssetType } from "models/Asset";
import { SolvableEventUnit } from "models/EventUnit";
import { QuizSolutionDto, Solution } from "models/Solution";
import {
	getSolutionSuccessStateByQuizSolution,
	getSolutionSuccessStateBySolution,
	getSolutionSuccessStateByTheory,
	getSolutionSuccessStateEnum,
	SolutionSuccessState,
	SolutionSuccessStateEnum,
} from "models/SolutionState";
import { UserCurriculumEvent } from "shared/event/model/UserCurriculumEvent";

/**
 * Returns the correct ProgressHelper for the given unit type
 *
 * @param intl
 * @param unit
 * @param parentId
 * @returns EventProgressHelperBase
 */
export const getEventProgressHelper = (intl: IntlWithFm, unit: SolvableEventUnit, parentId?: number): EventProgressHelperBase => {
	switch (unit.type) {
		case AssetType.CHALLENGE:
			return new ChallengeProgressHelper(intl, unit, parentId);
		case AssetType.QUIZ:
			return new QuizProgressHelper(intl, unit, parentId);
		case AssetType.THEORY:
			return new TheoryProgressHelper(intl, unit, parentId);
		default:
			throw new Error(`No ProgressHelper for assetType ${unit.type}`);
	}
};

export const getCurriculumProgressHelper = (intl: IntlWithFm, event: UserCurriculumEvent, handleRegister?: (eventId: number) => void): CurriculumProgressHelper => {
	return new CurriculumProgressHelper(intl, event, handleRegister);
};

abstract class EventProgressHelperBase {
	protected readonly intl: IntlWithFm;
	protected solutionSuccessStateEnum ?: SolutionSuccessStateEnum; // we cache the solutionSuccessState, because it is used multiple times and complicated to evaluate

	protected constructor(intl: IntlWithFm) {
		this.intl = intl;
	}

	abstract getUnitLink(): string

	abstract getSolvePercentage(): number | null

	getSuccessSolutionStateEnum(): SolutionSuccessStateEnum | undefined {
		if (this.solutionSuccessStateEnum === undefined) {
			this.solutionSuccessStateEnum = this.getSuccessSolutionStateEnumInternal();
		}
		return this.solutionSuccessStateEnum;
	}

	protected abstract getSuccessSolutionStateEnumInternal(): SolutionSuccessStateEnum | undefined

	abstract getTooltipContent(): ReactElement;

	abstract getClickable(children: ReactElement): ReactElement;
}

abstract class EventUnitProgressHelperBase extends EventProgressHelperBase {
	protected readonly unit: SolvableEventUnit;
	protected readonly parentId?: number;

	constructor(intl: IntlWithFm, unit: SolvableEventUnit, parentId?: number) {
		super(intl);
		this.unit = unit;
		this.parentId = parentId;
	}

	getTooltipContent(): ReactElement {
		const percentage = this.getSolvePercentage();
		const solutionSuccessStateEnum = this.getSuccessSolutionStateEnum();
		return <table>
			<tbody>
				<tr>
					<td>{this.intl.fm("event.progress.tooltip.label.unitTitle")}</td>
					<td style={{ paddingLeft: 20 }}>{this.unit.title}</td>
				</tr>
				{solutionSuccessStateEnum && <tr>
					<td>{this.intl.fm("event.progress.tooltip.label.status")}</td>
					<td style={{ paddingLeft: 20 }}>{this.intl.fm(solutionSuccessStateEnum.title)}</td>
				</tr>}
				{percentage !== null && <tr>
					<td>{this.intl.fm("event.progress.tooltip.label.percentage")}</td>
					<td style={{ paddingLeft: 20 }}>{percentage}%</td>
				</tr>}
			</tbody>
		</table>;
	}

	getClickable(children: ReactElement): ReactElement {
		// we need the div wrapper, because tooltips are not working with direct child <Link>
		return <div>
			<Link to={this.getUnitLink()}>{children}</Link>
		</div>;
	}

	protected getBasePath() {
		return this.parentId
			? `/events/${this.parentId}/curriculumevents/${this.unit.eventId}`
			: `/events/${this.unit.eventId}`;
	}
}

export class ChallengeProgressHelper extends EventUnitProgressHelperBase {
	getSolvePercentage(): number | null {
		const solution = this.getSolution();
		return solution ? (100 / this.unit.maxPoints) * solution.points : 0;
	}

	getUnitLink(): string {
		return `${this.getBasePath()}/challenges/${this.unit.id}`;
	}

	protected getSuccessSolutionStateEnumInternal(): SolutionSuccessStateEnum | undefined {
		const solution = this.getSolution();
		return getSolutionSuccessStateEnum(getSolutionSuccessStateBySolution(solution));
	}

	private getSolution(): Solution | undefined {
		return ((this.unit as any)?.solution as Solution);
	}
}

export class QuizProgressHelper extends EventUnitProgressHelperBase {
	getSolvePercentage(): number | null {
		const quizSolution = this.getQuizSolution();
		return quizSolution ? (100 / this.unit.maxPoints) * quizSolution.points : 0;
	}

	protected getSuccessSolutionStateEnumInternal(): SolutionSuccessStateEnum | undefined {
		const quizSolution = this.getQuizSolution();
		return getSolutionSuccessStateEnum(getSolutionSuccessStateByQuizSolution(quizSolution));
	}

	getUnitLink(): string {
		return `${this.getBasePath()}/quiz/${this.unit.eventId}`;
	}

	private getQuizSolution(): QuizSolutionDto | undefined {
		return ((this.unit as any)?.quizSolution as QuizSolutionDto);
	}
}

export class TheoryProgressHelper extends EventUnitProgressHelperBase {
	getSolvePercentage(): number | null {
		return this.hasViewedTheory() ? 100 : null; // we return null here, to hide the Tooltip hint about the awarded percentage
	}

	protected getSuccessSolutionStateEnumInternal(): SolutionSuccessStateEnum | undefined {
		return getSolutionSuccessStateEnum(getSolutionSuccessStateByTheory(this.hasViewedTheory()));
	}

	getUnitLink(): string {
		return `${this.getBasePath()}/theory/${this.unit.eventId}`;
	}

	private hasViewedTheory(): boolean {
		return (this.unit as any)?.hasViewedTheory;
	}
}

export class CurriculumProgressHelper extends EventProgressHelperBase {
	protected readonly event: UserCurriculumEvent;

	protected handleRegister?: (eventId: number) => void;

	constructor(intl: IntlWithFm, event: UserCurriculumEvent, handleRegister?: (eventId: number) => void) {
		super(intl);
		this.event = event;
		this.handleRegister = handleRegister;
	}

	getSolvePercentage(): number | null {
		return this.event.unitsCount && this.event.unitsWithSolutionCount
			? (100 / this.event.unitsCount) * this.event.unitsWithSolutionCount
			: 0;
	}

	getTooltipContent(): ReactElement {
		return <table>
			<tbody>
				<tr>
					<td>{this.intl.fm("event.progress.tooltip.label.eventTitle")}</td>
					<td style={{ paddingLeft: 20 }}>{this.event.name}</td>
				</tr>
				{this.solutionSuccessStateEnum && <tr>
					<td>{this.intl.fm("event.progress.tooltip.label.status")}</td>
					<td style={{ paddingLeft: 20 }}>{this.intl.fm(this.solutionSuccessStateEnum.title)}</td>
				</tr>}
				<tr>
					<td>{this.intl.fm("event.progress.tooltip.label.progress")}</td>
					<td style={{ paddingLeft: 20 }}>{this.event.unitsWithSolutionCount} / {this.event.unitsCount}</td>
				</tr>
			</tbody>
		</table>;
	}

	getUnitLink(): string {
		return `/events/${this.event.parent}/curriculumevents/${this.event.id}`;
	}

	protected getSuccessSolutionStateEnumInternal(): SolutionSuccessStateEnum | undefined {
		// get the success state of all units
		let successStates = this.event.units?.map(unit => {
			return getEventProgressHelper(this.intl, unit, this.event.id).getSuccessSolutionStateEnum();
		});

		// we order the states from the units by importance and show the state with the highest priority on the curriculum event
		successStates = successStates?.sort((a, b) => (b?.importance ?? 0) - (a?.importance ?? 0));

		// if we have no child units, we just show the undefined state (grey)
		return successStates?.length > 0 ? successStates[0] : getSolutionSuccessStateEnum(SolutionSuccessState.UNDEFINED);
	}

	getClickable(children: ReactElement): ReactElement {
		return this.event.registered ? (
			<Link to={this.getUnitLink()}>{children}</Link>
		) : (
			<div onClick={() => this.handleRegister ? this.handleRegister(this.event.id) : undefined}>{children}</div>
		);
	}
}

const reportStyle = {
	textAlignLeft: { textAlign: "left" },
	topInfoCards: {
		display: "flex",
		marginTop: 16,
		"&>div": { margin: "5px" }
	},
	infoCardFooter: {
		display: "flex",
		justifyContent: "space-between"
	},
	cardStatsIcon: {
		position: "relative",
		margin: "0 6px",
		top: "4px",
		width: "16px",
		height: "16px"
	},
	filterField: {
		float: "right",
		marginTop: "-22px"
	},
	filterBox: {
		display: "flex",
		flexDirection: "column"
	},
	editButton: {
		margin: 0,
		padding: 5
	},
	reportLink: { marginRight: 5 },
	icon: {
		verticalAlign: "middle",
		width: "17px",
		height: "17px",
		top: "-1px",
		position: "relative"
	},
	label: {
		display: "inline-block",
		marginLeft: 6,
		marginRight: 3
	},
	tableSelectFormControl: { margin: "-6px 0 0 12px" },
	titleButton: { marginLeft: 12 }
};

export default reportStyle;

import { dangerColor } from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react";
import { ProcessedFile } from "@hlcr/mui/Upload";
import { useIntl } from "@hlcr/ui/Intl";
import { TextField } from "@material-ui/core";
import FlagIcon from "@material-ui/icons/Flag";
import * as React from "react";

import { WriteUpForm } from "grading/WriteUpForm/WriteUpForm";

export const SolutionSubmissionForm = ({
	isFlagBased,
	isWriteupBased,
	writeup,
	file,
	flag,
	isTeacher,
	submitting,
	classes,
	handleWriteupChange,
	handleOnFileChange,
	handleFlagTextChange,
	hasErrors,
}: {
	isWriteupBased: boolean;
	isFlagBased: boolean;
	writeup: string;
	file: ProcessedFile;
	flag: string;
	isTeacher: boolean;
	submitting: boolean;
	classes: any;
	handleWriteupChange: (writeup: string) => void;
	handleOnFileChange: (file: ProcessedFile | undefined) => void;
	handleFlagTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	hasErrors: boolean;
}) => {
	const intl = useIntl();
	return (
		<form onSubmit={(e) => e.preventDefault()}>
			{isFlagBased && (
				<div style={{ display: "flex", alignItems: "center" }}>
					<FlagIcon style={{ color: dangerColor, marginBottom: 20, marginRight: 10 }} />
					<TextField
						variant="filled"
						error={hasErrors}
						label={intl.fm("grading.mode.flag")}
						helperText={intl.fm("solution.history.goldNugget.helper")}
						onChange={handleFlagTextChange}
						disabled={submitting}
						value={flag}
						fullWidth
						margin="dense"
						inputProps={{ className: classes.monoSpaced }}
					/>
				</div>
			)}
			{isWriteupBased && (
				<WriteUpForm writeUpText={writeup} writeUpFile={file} isTeacher={isTeacher} isSubmitting={submitting} onWriteUpChange={handleWriteupChange} onFileChange={handleOnFileChange} hasError={hasErrors} />
			)}
		</form>
	);
};

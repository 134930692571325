
export interface Event {
	id: number;
	name: string;
	examiners: number;
}

export const coexaminationEnabled = (event: Event): boolean => {
	return event.examiners > 1;
};

import { CountrySelect } from "@hlcr/mui/CountrySelect";
import { onAutoCompleteChange, onSelectChange, onSwitchChange, onValueChange } from "@hlcr/mui/Input/inputValueChange";
import { useIntl } from "@hlcr/ui/Intl";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import InfoIcon from "@material-ui/icons/Info";
import { Alert } from "@material-ui/lab";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";

import IconCard from "components/Cards/IconCard";
import ValidationInput from "components/CustomInput/ValidationInput";
import { CustomSelect } from "components/CustomSelect/CustomSelect";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import NoData from "components/NoData/NoData";
import { checkUserProfileRequirementPolicyViolation } from "helper/policy";
import { ProfileInformationRequirement, UserProfileRequirements } from "models/TenantAccessPolicy";
import { GENDERS, UserProfile } from "models/User";


interface ProfileEditorProps {
	// Do not render the Form inside a Card Component.
	noCard: boolean;
	userProfile?: UserProfile;
	onSave: (userProfile: UserProfile) => void;
	userProfileRequirements?: UserProfileRequirements;
}

export const ProfileEditor = ({ noCard, userProfile, onSave, userProfileRequirements }: ProfileEditorProps) => {
	const intl = useIntl();
	const classes = useStyles();

	const [ profile, setProfile ] = useState(userProfile);
	const [ tenantAccessPolicyViolated, setTenantAccessPolicyViolated ] = useState(false);

	useEffect(() => {
		setProfile(userProfile);
	}, [ userProfile ]);

	useEffect(() => {
		setTenantAccessPolicyViolated(checkUserProfileRequirementPolicyViolation(userProfileRequirements, userProfile));
	}, [ userProfileRequirements, userProfile ]);

	if (!profile) {
		return <NoData />;
	}

	const birthday = profile.birthday ? moment(profile.birthday) : null;

	const resetForm = () => setProfile(userProfile);

	const onSubmit = () => {
		onSave(profile);
	};

	const inputFields = (
		<div className={classes.descriptionBox}>
			{tenantAccessPolicyViolated && <Alert severity="error">{intl.fm("user.profile.tenantPolicyViolated")}</Alert>}
			{
				userProfileRequirements?.GENDER !== ProfileInformationRequirement.HIDDEN
				&& <CustomSelect
					menuItems={GENDERS}
					value={profile.gender}
					required={userProfileRequirements?.GENDER === ProfileInformationRequirement.MANDATORY}
					onChange={onSelectChange("gender", profile, setProfile)}
					label={intl.fm("user.label.gender")}
					title={intl.fm("user.label.gender")}
				/>
			}
			{
				userProfileRequirements?.BIRTHDATE !== ProfileInformationRequirement.HIDDEN
				&& <ValidationInput
					dateOnly={true}
					label={intl.fm("user.label.birthday")}
					type="date"
					value={birthday}
					required={userProfileRequirements?.BIRTHDATE === ProfileInformationRequirement.MANDATORY}
					onChange={onValueChange("birthday", profile, setProfile)}
					validations={{
						required: userProfileRequirements?.BIRTHDATE === ProfileInformationRequirement.MANDATORY,
						custom: isInThePast,
					}}
					validateOnLoad={tenantAccessPolicyViolated}
				/>
			}
			{
				userProfileRequirements?.NATIONALITY !== ProfileInformationRequirement.HIDDEN
				&& <CountrySelect
					label={intl.fm("user.label.nationality")}
					value={profile.nationality}
					required={userProfileRequirements?.NATIONALITY === ProfileInformationRequirement.MANDATORY}
					onChange={onAutoCompleteChange("nationality", profile, setProfile)}
					validations={{ maxLength: 2, required: userProfileRequirements?.NATIONALITY === ProfileInformationRequirement.MANDATORY }}
					validateOnLoad={tenantAccessPolicyViolated}
				/>
			}
			{
				userProfileRequirements?.AFFILIATION !== ProfileInformationRequirement.HIDDEN
				&& <ValidationInput
					label={intl.fm("user.label.organization")}
					value={profile.organization}
					required={userProfileRequirements?.AFFILIATION === ProfileInformationRequirement.MANDATORY}
					onChange={onValueChange("organization", profile, setProfile)}
					validations={{ maxLength: 100, required: userProfileRequirements?.AFFILIATION === ProfileInformationRequirement.MANDATORY }}
					fullWidth
					validateOnLoad={tenantAccessPolicyViolated}
				/>
			}
			{
				userProfileRequirements?.SKILLS !== ProfileInformationRequirement.HIDDEN
				&& <ValidationInput
					inputProps={{
						multiline: true,
						rows: 3,
					}}
					label={intl.fm("user.label.skills")}
					value={profile.skills}
					required={userProfileRequirements?.SKILLS === ProfileInformationRequirement.MANDATORY}
					onChange={onValueChange("skills", profile, setProfile)}
					validations={{ maxLength: 200, required: userProfileRequirements?.SKILLS === ProfileInformationRequirement.MANDATORY }}
					fullWidth
					validateOnLoad={tenantAccessPolicyViolated}
				/>
			}
			<>
				<div>
					<InputLabel>
						{intl.fm("user.label.emailDigest")}
					</InputLabel>
					<CustomSwitch
						checked={profile.receiveEmailNotifications ?? true}
						onChange={onSwitchChange("receiveEmailNotifications", profile, setProfile)}
					/>
				</div>
			</>
		</div>
	);

	const actions = (
		<div>
			<Button onClick={resetForm} color={"default"}>
				{intl.fm("common.labels.resetForm")}
			</Button>
			<Button onClick={onSubmit} color={"secondary"}>
				{intl.fm("common.labels.save")}
			</Button>
		</div>
	);

	if (noCard) {
		return (
			<React.Fragment>
				{inputFields}
				{actions}
			</React.Fragment>
		);
	}

	return (
		<IconCard
			title={
				<div className={classes.title}>
					{intl.fm("user.profile.info")}
					<div className={classes.level}>
						<span className={classes.levelLabel}>LEVEL</span>
						<span className={classes.userLevel}>{profile.userLevel}</span>
						<span className={classes.levelLabel}>POINTS</span>
						<span className={classes.points}>{profile.points}</span>
					</div>
				</div>
			}
			icon={InfoIcon}
			content={inputFields}
			footer={actions}
		/>
	);

};

const isInThePast = (value: moment.Moment, helptext: string) =>
{
	const now = moment();
	return value && value.isBefore(now);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles(
		{
			descriptionBox: { margin: "0 8px" },
			title: {
				display: "flex",
				justifyContent: "space-between",
				flexWrap: "wrap",
			},
			level: { marginBottom: 20 },
			levelLabel: {
				fontSize: ".7em",
				opacity: 0.5,
				paddingRight: 5,
			},
			userLevel: {
				paddingRight: 15,
				fontWeight: 400,
				textTransform: "uppercase",
			},
			points: { fontWeight: 400 },
		},
	),
);

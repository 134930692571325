import { ErrorResult, RemoteResourceStateData } from "@hlcr/core/api/RemoteResource";
import { HttpMethod } from "@hlcr/core/enum/HttpMethod";
import { HttpStatusCode } from "@hlcr/core/enum/HttpStatusCode";
import { Dispatch, MiddlewareAPI } from "redux";

import { addNotification, removeNotification } from "actions/api";
import { getCookieValue } from "auth/authUtils";
import guid from "helper/guid";
import { MessageType } from "models/Message";
import { RemoteResourceAction, RemoteResourceActionType, RemoteResourceRequestAction, removeRemoteResource, saveRemoteResource } from "redux/actions";


document.cookie = `CSRF-TOKEN=${guid()};path=/;`;

export const remoteResourceMiddleware = (middleware: MiddlewareAPI) => (next: Dispatch) => async (action: RemoteResourceAction) => {
	const nextAction = next(action);

	if (action.type === RemoteResourceActionType.REMOTE_RESOURCE_REQUEST) {
		if (action.onBefore) {
			action.onBefore(middleware.dispatch);
		}

		let result: RemoteResourceStateData | undefined;
		let response: Response | undefined;

		try {
			response = await doFetch(action);
			result = await getResult(response);
			// checkRemove(response, action, middleware);
		} catch (e) {
			// TODO: show message on failed request
		} finally {
			if (response) {
				saveResult(action, middleware, response, result);
			}
		}
		finishHandleResponse(action, middleware, response, result);
	}

	return nextAction;
};

const doFetch = async (action: RemoteResourceRequestAction) => {
	const init: RequestInit = {
		method: action.method,
		body: JSON.stringify(action.payload),
		headers: createHeaders(),
		credentials: "same-origin",
	};
	return await fetch(`${action.url}`, init);
};

const getResult = async (response: Response) => {
	return response.status !== 204 && response.status !== 201
		? await response.json() as RemoteResourceStateData
		: undefined;
};

const saveResult = (action: RemoteResourceRequestAction, middleware: MiddlewareAPI, response: Response, result?: RemoteResourceStateData) => {
	if (action.resource) {
		if (response.status === HttpStatusCode.NO_CONTENT && action.method === HttpMethod.DELETE && action.payload){
			middleware.dispatch(
				removeRemoteResource(
					action.resource,
					action.payload,
				),
			);
		} else if (response.status === HttpStatusCode.NO_CONTENT) {
			// TODO: never send no_content except on delete.
			middleware.dispatch(
				saveRemoteResource(
					action.resource,
					response.ok ? action.payload : undefined,
				),
			);
		} else {
			middleware.dispatch(
				saveRemoteResource(
					action.resource,
					response.ok ? result : undefined,
				),
			);
		}
	}
};

const finishHandleResponse = (action: RemoteResourceRequestAction, middleware: MiddlewareAPI, response?: Response, result?: RemoteResourceStateData) => {
	if (response?.ok) {
		if (action.onSuccess) {
			action.onSuccess(middleware.dispatch, result);
		}
		createSuccessMessage(
			middleware.dispatch,
			action.method,
			action.successNotification,
		);
	} else {
		const errorResult = result as ErrorResult;
		if (action.onFailure) action.onFailure(middleware.dispatch);

		const snackId = guid();

		if (!action.suppressErrorNotification) {
			middleware.dispatch(
				addNotification(snackId, buildMessage(response, errorResult)),
			);
			window.setTimeout(
				() => middleware.dispatch(removeNotification(snackId)),
				6000,
			);
		}
	}
};

const buildMessage = (response?: ResponseInit, result?: ErrorResult) => {
	if (response?.status === HttpStatusCode.FORBIDDEN) {
		return `Request Forbidden ${result?.message && `: ${result.message}`}`;
	}

	if (response?.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
		return "Oops, something went wrong on our side!";
	}

	if (result?.message) {
		return result.message;
	} else {
		return `${response?.statusText || "API error"}`;
	}
};

const createHeaders = () => {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");
	headers.append("X-CSRF-TOKEN", getCookieValue("CSRF-TOKEN"));
	return headers;
};

const getGenericSuccessMessage = (method?: HttpMethod) => {
	switch (method) {
		case HttpMethod.GET:
			return "Received successfully";
		case HttpMethod.POST:
			return "Created successfully";
		case HttpMethod.DELETE:
			return "Deleted successfully";
		case HttpMethod.PUT:
			return "Updated successfully";
		default:
			return "Request successful";
	}
};

export const createSuccessMessage = (dispatch: Dispatch, method?: HttpMethod, successNotification?: string | boolean) => {
	if (successNotification) {
		const snackId = guid();
		dispatch(
			addNotification(
				snackId,
				typeof successNotification === "string"
					? successNotification
					: getGenericSuccessMessage(method),
				MessageType.SUCCESS,
			),
		);
		window.setTimeout(() => dispatch(removeNotification(snackId)), 4000);
	}
};

import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const LoadingSpinner = ({ classes }) => (
	<div className={classes.container}>
		<span className={classes.text}>Loading data</span>{" "}
		<CircularProgress size={24} />
	</div>
);

const style = {
	container: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: 24,
		fontWeight: 400,
		color: "#888"
	},
	text: { marginRight: 10 }
};

export default withStyles(style)(LoadingSpinner);

import { percentage } from "@hlcr/core/numeric";

const getMessage = message => (typeof message !== "undefined" ? message : "0%");

export const printPercentage = (points, maxPoints, message) => {
	return !isNaN(points) && !isNaN(maxPoints) && maxPoints !== 0
		? `${percentage(points / maxPoints)}%`
		: getMessage(message);
};
export const calcPercentage = (points, maxPoints, fallback) => {
	return maxPoints !== 0 ? percentage(points / maxPoints, { fallback }) : fallback;
};

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import PackageJson from "../../../package.json";
const version = PackageJson.version;

import aboutApi from "actions/about";

class About extends React.Component {
	constructor(props) {
		super(props);
		this.state = { backendVersion: {} };
	}

	componentDidMount() {
		this.props.fetchBackendVersion(result => {
			if (result) {
				this.setState({ backendVersion: result });
			}
		});
	}

	getReleaseNotesLink() {
		const { helpUrl } = this.props;
		return (
			<a
				href={helpUrl + "about/release-notes/"}
				target="_blank"
				rel="noopener noreferrer"
			>
				Release Notes
			</a>);
	}

	getLicensesLink() {
		const { helpUrl } = this.props;
		return (
			<a
				href={helpUrl + "about/licenses/"}
				target="_blank"
				rel="noopener noreferrer"
			>
				Licenses
			</a>);
	}

	getVersionString() {
		const { backendVersion } = this.state;
		return `Frontend: ${version}${backendVersion && backendVersion.app ? `, Backend: ${backendVersion.app.version}` : ""}`;
	}

	render() {
		return (
			<div>
				<h1>Hacking-Lab</h1>
				<p><strong>{this.getVersionString()}</strong> - {this.getReleaseNotesLink()}, {this.getLicensesLink()}</p>
				<p>Hacking-Lab is an online ethical hacking, computer network and security challenge platform, dedicated to finding and educating cyber security talents.
					Furthermore, Hacking-Lab is
					providing the CTF and mission style challenges for the European Cyber Security Challenge with Austria, Germany, Switzerland, UK, Spain, Romania and provides
					free OWASP TOP 10
					online security labs. Hacking-Labs’ goal is to raise awareness towards increased education and ethics in information security through a series of cyber
					competitions that encompass
					forensics, cryptography, reverse-engineering, ethical hacking and defense. One key initiative for Hacking-Lab is to foster an environment that creates cyber
					protection through
					education.</p>
				<h2>Compass Security</h2>
				<p>Hacking-Lab is a service by Hacking-Lab AG, a Swiss subsidiary of <a href="https://www.compass-security.com">Compass Security AG</a>.</p>
				<p>Compass Security is a well renowned European company specializing in penetration testing, incident response, digital forensics, and security trainings. Our
					research and community
					contributions regularly gain international recognition. Our employees have presented at the highly regarded security conferences, such as Black Hat Las Vegas
					and Microsoft's invite
					only conference, called Blue Hat, in Redmond. With Hacking-Lab, the flagship project of Compass Security, a comprehensive attack/defense CTF system is provided
					to run the European
					Cyber Security Challenge. Hacking-Lab is licensed to numerous universities worldwide for educational purposes, with its aims of building young cyber talents as
					well as encouraging
					them to pursue a career in cyber security. Build your skills and join us for exciting challenges.</p>
				<h2>Contact</h2>
				<p>General Questions: <a href="mailto:info@hacking-lab.com">info@hacking-lab.com</a></p>
				<p>Support: <a href="mailto:support@hacking-lab.com">support@hacking-lab.com</a></p>
			</div>
		);
	}
}


About.propTypes = {
	helpUrl: PropTypes.string.isRequired,
	fetchBackendVersion: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ helpUrl: state.branding.helpUrl });

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{ fetchBackendVersion: aboutApi.fetchBackendVersion },
		dispatch,
	);

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(About);

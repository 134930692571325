
import { HlcrRouteComponentProps } from "@hlcr/app/model/RouteComponent";

interface TenantAdminAccessPolicyProps extends HlcrRouteComponentProps {

}
export const TenantAdminAccessPolicy = ({ summary }: TenantAdminAccessPolicyProps) => {
	if (summary) {
		return <div>TenantAdminAccessPolicySummary</div>;
	}

	return <div>TenantAdminAccessPolicy</div>;
};

import { withStyles } from "@material-ui/core/styles";
import React from "react";

import timelineStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/solutionTimelineStyle.jsx";
import TimeLineItem from "components/Timeline/TimeLineItem";

const TimeLineListItem = props => {
	return (
		<li className={props.classes.item}>
			<TimeLineItem {...props} />
		</li>
	);
};

export default withStyles(timelineStyle)(TimeLineListItem);

import NotificationsIcon from "@material-ui/icons/Notifications";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Snackbar from "components/Snackbar/Snackbar";

import { removeNotification as removeNotificationAction } from "../../actions/api";
import { MessageType } from "models/Message";

class SnackbarSpawner extends React.Component {
	render() {
		const { notifications, removeNotification } = this.props;
		return (
			<Fragment>
				{notifications.map(n => (
					<Snackbar
						key={n.id}
						place="tc"
						color={n.messageType === MessageType.SUCCESS ? "success" : "danger"}
						icon={NotificationsIcon}
						message={n.message}
						closeNotification={() => removeNotification(n.id)}
						open={true}
						close
					/>
				))}
			</Fragment>
		);
	}
}

SnackbarSpawner.propTypes = {
	notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
	removeNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ notifications: state.api.notifications });

const mapDispatchToProps = dispatch =>
	bindActionCreators({ removeNotification: removeNotificationAction }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SnackbarSpawner);

import { StyledMarkdown } from "@hlcr/ui";
import { createStyles, makeStyles } from "@material-ui/core";
import { ChangeEvent } from "react";
import { useSelector } from "react-redux";

import CustomCheckbox from "components/CustomSwitch/CustomCheckbox";
import { AnswerBaseProps } from "components/Quiz/AnswerBaseProps";
import { getCorrectState, isOptionRight } from "helper/quiz";
import { MultipleChoiceOptionsAnswer, QuestionAnswer, QuestionType } from "models/Quizzes";
import { QUESTION_ANSWER_STATES, QuestionAnswerState } from "models/SolutionState";
import { RootState } from "reducers";

interface MultipleChoiceQuestionProps extends AnswerBaseProps {
}

export const MultipleChoiceQuestion = ({ question, disabled, gradingInstruction, isPreview, onChange, answer }: MultipleChoiceQuestionProps) => {
	const classes = useStyles();
	const darkMode = useSelector((state: RootState) => state.ui.darkMode);

	const handleOnCheckboxChange = (id: string) => (event: ChangeEvent<HTMLInputElement>) => {
		const checked = event.target.checked;

		let selectedOptions: MultipleChoiceOptionsAnswer[];
		if (checked) {
			selectedOptions = [
				...(answer?.multipleChoiceOptionAnswers || []),
				{ selectedOptionUuid: id },
			];
		} else {
			selectedOptions = answer?.multipleChoiceOptionAnswers?.filter(selectedOption => selectedOption.selectedOptionUuid !== id) || [];
		}

		const updatedAnswer: QuestionAnswer = {
			...answer,
			type: QuestionType.MULTIPLE_CHOICE,
			questionUuid: question.id,
			multipleChoiceOptionAnswers: selectedOptions,
		};

		onChange(updatedAnswer);
	};

	return (
		<div className={classes.answerContainer}>
			{question.multipleChoiceOptions && question.multipleChoiceOptions.map((option, i) => {
				let optionChecked = answer?.multipleChoiceOptionAnswers
					? !!answer.multipleChoiceOptionAnswers.find(selectedOption => selectedOption.selectedOptionUuid === option.id)
					: false;
				let checkboxClasses = undefined;
				let elementSuffix = undefined;

				if (gradingInstruction) {
					let optionState = QuestionAnswerState.RIGHT;
					const isOptionCorrect = isOptionRight(option, gradingInstruction);
					elementSuffix = isOptionCorrect ? "✓" : "✗";
					if (isPreview) {
						const solution = gradingInstruction?.multipleChoiceOptions?.find(gia => gia.id === option.id);
						optionChecked = !!solution?.correct;
					} else {
						optionState = getCorrectState(isOptionCorrect);
					}
					checkboxClasses = optionState === QuestionAnswerState.RIGHT
						? { root: classes.rightRoot, checked: classes.rightRoot, disabled: classes.rightDisabled }
						: { root: classes.wrongRoot, checked: classes.wrongRoot, disabled: classes.wrongDisabled };
				}

				return (
					<CustomCheckbox
						key={i}
						disabled={disabled}
						onChange={handleOnCheckboxChange(option.id)}
						checked={optionChecked}
						label={<StyledMarkdown source={option.content} darkMode={darkMode} />}
						labelStyle={classes.label}
						classes={checkboxClasses}
						elementSuffix={elementSuffix}
					/>
				);
			})}
		</div>
	);
};

const useStyles = makeStyles(
	() => createStyles(
		({
			wrongRoot: { color: QUESTION_ANSWER_STATES.WRONG.color + " !important" },
			wrongDisabled: { color: QUESTION_ANSWER_STATES.WRONG.color + " !important", opacity: 0.7 },
			rightRoot: { color: QUESTION_ANSWER_STATES.RIGHT.color + " !important" },
			rightDisabled: { color: QUESTION_ANSWER_STATES.RIGHT.color + " !important", opacity: 0.7 },
			answerContainer: {
				display: "flex",
				flexDirection: "column",
			},
			label: { "& > div > p": { margin: 0 } },
		}),
	),
);

import { HackingLabRole } from "@hlcr/app/model/HackingLabRole";
import { Page } from "@hlcr/core";

import { ActionType } from "actions/ActionType";

import { MessageReceived, MessageSent } from "./model";

const BASE_URL = "/api";

const fetchUnreadMessages = () => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/user/inbox/unread`,
	method: "get",
	resource: "unreadMessageCount",
});


const fetchInboxMessages = (page: number, size: number, onSuccess?: (inboxMessagePage: Page<MessageReceived>) => void) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/user/inbox/messages?page=${page}&size=${size}`,
	method: "get",
	onSuccess: (_: any, result: Page<MessageReceived>) => onSuccess?.(result),
});

const archiveMessages = (messageIds: Array<string | number>, onSuccess: () => void) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/user/inbox/messages/archive`,
	method: "put",
	body: messageIds,
	onSuccess,
});

const markMessagesAsRead = (messageIds: Array<string | number>, onSuccess: () => void) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/user/inbox/messages/markAsRead`,
	method: "put",
	body: messageIds,
	onSuccess,
});

const markMessagesAsUnread = (messageIds: Array<string | number>, onSuccess: () => void) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/user/inbox/messages/markAsUnread`,
	method: "put",
	body: messageIds,
	onSuccess,
});

const sendMessage = (message: Partial<MessageSent>, asRole?: HackingLabRole) => ({
	type: ActionType.API_REQUEST,
	url: `${BASE_URL}/messages${asRole && `?asRole=${asRole.toUpperCase()}`}`,
	method: "post",
	body: message,
	successNotification: true,
});

export default {
	fetchUnreadMessages,
	fetchInboxMessages,
	archiveMessages,
	markMessagesAsRead,
	markMessagesAsUnread,
	sendMessage,
};

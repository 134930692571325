import { withStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Snackbar from "components/Snackbar/Snackbar";

const CERT_HINT_DISMISSED_KEY = "certHintDismissed";

class CertHint extends React.Component {
	state = { dismissed: true // localStorage.getItem(CERT_HINT_DISMISSED_KEY) === 'true' || false
	};

	render() {
		const { classes } = this.props;
		const { dismissed } = this.state;
		return (
			<Snackbar
				place="br"
				color="primary"
				icon={LockIcon}
				message={
					<div className={classes.message}>
						<a
							href="https://github.com/Hacking-Lab/vuln.land"
							className={classes.link}
							target="_blank"
							rel="noopener noreferrer"
						>
							Click here
						</a>{" "}
						to get HL 2.0 intermediate and root certification authority files.{" "}
						<span
							onClick={this.dismissHint}
							className={cx(classes.link, classes.dismiss)}
						>
							DISMISS
						</span>
					</div>
				}
				closeNotification={this.dismissHint}
				open={!dismissed}
				close
			/>
		);
	}

	dismissHint = () => {
		localStorage.setItem(CERT_HINT_DISMISSED_KEY, true);
		this.setState({ dismissed: true });
	};
}

CertHint.propTypes = { classes: PropTypes.object.isRequired };

const style = {
	message: {
		fontSize: "1.1em",
		lineHeight: "1.4em"
	},
	link: {
		cursor: "pointer",
		color: "#fff",
		borderBottom: "1px dotted #fff",
		fontWeight: 500,
		"&:hover,&:active,&:focus": {
			color: "#fff",
			background: "rgba(255, 255, 255, .2)"
		}
	},
	dismiss: {
		fontSize: "0.7em",
		opacity: 0.8
	}
};

export default withStyles(style)(CertHint);
